import React from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import Banner from '../Components/PrivacyPolicy/Banner'
// import JoinOurTeam from './JoinOurTeam'
import Footer from '../Components/NavBarFooter/Footer'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'

function PrivacyPolicy() {
  return (
    <>
    <div className='container'>
      <NavBar/>
      <Banner/>
      </div>
      <JoinOurTeam/>
      <div className="container">
      <Footer/>
    </div>
    </>
  )
}

export default PrivacyPolicy
