import React, { useEffect, useRef, useState } from "react";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos";
import MultiItemCarousel from "../PhotograypyDetailPage/MultiItemCarousel";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "react-photo-album/rows.css";
import { RowsPhotoAlbum } from "react-photo-album";
import "yet-another-react-lightbox/plugins/counter.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
function PhotoVideo({ data }) {
  const [index, setIndex] = React.useState(-1);
  AOS.init({
    duration: 800, // Animation duration
    easing: "ease-in-out", // Easing option
    once: false, // Whether animation should only happen once
  });
  const [video, setVideo] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(
    data?.portfolio?.video[0]?.url
  );
  const videoRef = useRef(null);

  const handleVideoClick = (url) => {
    setCurrentVideo(url);
  };
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [currentVideo]);
  useEffect(() => {
    if (data?.portfolio?.video?.length > 0) {
      setCurrentVideo(data?.portfolio?.video[0]?.url);
    } else if (data?.portfolio?.links?.length > 0) {
      setCurrentVideo(data?.portfolio?.links[0]?.url);
    } else {
      setCurrentVideo("");
    }
  }, [data, video]);
  useEffect(() => {
    if (data?.portfolio?.images?.length > 0 && (data?.portfolio?.video?.length > 0)) {
      setVideo(false)
    }
    else if ((data?.portfolio?.video?.length > 0)) {
      setVideo(true)
    }
    else {
      setVideo(false)
    }
  }, [data])
  const isYouTubeLink = (url) => {
    return url?.includes("youtube.com") || url?.includes("youtu.be");
  };
  const renderVideo = (url) => {
    if (isYouTubeLink(url)) {
      // Handle YouTube link
      const embedUrl = url?.includes("youtube.com")
        ? url?.replace("watch?v=", "embed/")
        : url?.replace("youtu.be/", "youtube.com/embed/");
      return (
        <iframe
          key={url} // Ensure the iframe is uniquely identified
          width="100%"
          height="550px"
          style={{borderRadius:"24px"}}
          src={embedUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    } else {
      // Handle other video links (assume MP4 for simplicity)
      return (
        <video
          ref={videoRef}
          autoPlay
          key={url}
          width="100%"
          height="550px"
          controls
        >
          <source src={url} type="video/mp4" />
        </video>
      );
    }
  };
  const [showFullText, setShowFullText] = useState(false);
  const breakpoints = [3840, 1920, 1080, 640, 384, 256, 128];
  function assetLink(url, width) {
    return `${url}?w=${width}&q=75`;
  }
  const array = data?.portfolio?.images?.map((item) => ({
    asset: item?.url,
    width: 3840,
    height: 3300,
  }));
  const slides = array?.map(({ asset, width, height }) => ({
    src: assetLink(asset, width),

    width,
    height,
    srcSet: breakpoints?.map((breakpoint) => ({
      src: assetLink(asset, breakpoint),
      width: breakpoint,
      height: Math.round((height / width) * breakpoint),
    })),
  }));
  const array1 = data?.portfolio?.images?.map((item) => ({
    asset: item?.preview_url,
    width: 3840,
    height: 3300,
  }));

  const slides1 = array1?.map(({ asset, width, height }) => ({
    src: assetLink(asset, width),

    width,
    height,
    srcSet: breakpoints?.map((breakpoint) => ({
      src: assetLink(asset, breakpoint),
      width: breakpoint,
      height: Math.round((height / width) * breakpoint),
    })),
  }));
  
  return (
    <div className="row photovideo Studio_detail_page mb-5">
      {/* ================================= paragraph ================= */}
      <div className="row m-0 new_studio_row">
        <div className="col-12 d-flex justify-content-center p-0">
          <h4 className="studio_head about_studio_head " style={{ margin: "0.4rem 0 1.6rem", padding: "12px 0" }}>About Studio</h4>
        </div>
        <div className="col-12">
          <div
            dangerouslySetInnerHTML={{
              __html: showFullText
                ? data?.description
                : `${data?.description?.slice(0, 650)}...`,
            }}
            className="Ser_para" style={{ fontSize: '14px', lineHeight: '24px' }}
          ></div>
          {data?.description?.length > 650 && (
            <>
              <hr className="mb-0" style={{ width: "90%", margin: "1.2rem auto" }} />

              <div className="d-flex justify-content-center">
                <button
                  className="ser_read "
                  onClick={() => setShowFullText(!showFullText)}
                >
                  {showFullText ? "Read Less" : "Read More"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center">
        <h4 className="studio_head" style={{ margin: '3.3rem 0 1.5rem', padding: "12px 0" }}>Amenities</h4>
      </div>
      <div className="col-12 ">
        <ul className="main_ul">
          {data?.studio_amenities?.map((item) => {
            return (
              <li>
                <IoMdCheckmarkCircleOutline /> {item?.amenity}
              </li>
            );
          })}
        </ul>
      </div>

      {
        currentVideo || data?.portfolio?.images?.length > 0 ?
          <>
            <div className="col-12 d-flex justify-content-center ">
              <h4 className="studio_head mb-0" style={{ marginTop: '2.8rem', padding: "12px 0" }}>Our portfolio</h4>
            </div>

            {
              currentVideo && (data?.portfolio?.images?.length > 0) ?

                <div className="col-12 text-center mt-3">
                  <button
                    onClick={() => setVideo(false)}
                    className={`phovid_btn ${video ? "phovid_btn_light" : "phovid_btn_dark"
                      }`}
                  >
                    PHOTOS
                  </button>
                  <button
                    onClick={() => setVideo(true)}
                    className={`phovid_btn ${video ? "phovid_btn_dark" : "phovid_btn_light"
                      }`}
                  >
                    VIDEO
                  </button>
                </div>
                : ""
            }

            {
              video ? (
                <div className="col-12" style={!(data?.portfolio?.images?.length > 0) ? { marginTop: "2.3rem" } : {}}>
                  <div className="row" >

                    <div className="col-9 m-auto " >
                      <div className="port_video">

                      {currentVideo ? (
                        renderVideo(currentVideo)
                      ) : (
                        ""
                      )}
                      </div>
                    </div>
                    <div className="col-9 m-auto">
                      <MultiItemCarousel
                        handleVideoClick={handleVideoClick}
                        image={data?.portfolio?.links}
                      />
                    </div>

                  </div>
                </div>
              ) : (
                <>

                  <div className="col-12 ser_phot " style={!currentVideo ? { marginTop: "2.3rem" } : {}}>
                    {data?.portfolio?.images?.length > 0 ? (
                      <>
                        <RowsPhotoAlbum

                          photos={slides1 ? slides1 : []}




                          // photos={slides ? slides : []}
                          spacing={20}  // Spacing between images
                          padding={0}  // Padding around each image.
                          targetRowHeight={180}
                          rowConstraints={{ minPhotos: 1, maxPhotos: 5, singleRowMaxHeight: 250 }}
                          onClick={({ index: current }) => setIndex(current)}
                        />




                        <Lightbox
                          plugins={[Counter, Fullscreen, Slideshow, Zoom, Thumbnails]}
                          index={index}
                          slides={slides ? slides : []}
                          open={index >= 0}
                          close={() => setIndex(-1)}
                        />
                      </>
                    ) : (


                      ""
                    )}
                  </div>



                </>
              )}
          </>
          : ""
      }




    </div>
  );
}

export default PhotoVideo;
