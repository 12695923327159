import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import StudioDetail from "./StudioDetail";
import { FiCheckCircle } from "react-icons/fi";
import { useAuth } from "../../Context/ContextProvider";
import studiAdd from "../../asset/icons/add_atudio.png";
function AddStudio() {
  const { getStudio, delStudio, studioData } = useAuth();
  useEffect(() => {
    getStudio();
    // eslint-disable-next-line
  }, []);
  const [show, setShow] = useState(false);
  const [image, setimage] = useState("");
  const [data, setData] = useState({
    image: "",
    name: "",
    description: "",
    additional_information:"",
    address: "",
    city_id: "",
    only_inquiry:0,
    state_id: "",
    amenities: [],
  });
  const handleEdit = (item) => {
    let array = [];
    // eslint-disable-next-line
    item?.studio_amenities?.map((items) => {
      array.push(items.amenity);
    });
    let obj = {
      name: item.name,
      description: item?.description,
      additional_information:item?.additional_information,
      amenities: array,
      state_id: item?.state_id,
      city_id: item?.city_id,
      address: item?.address,
      only_inquiry:item?.only_inquiry?item.only_inquiry:0,
      id: item.studio_id,
      sub_service_ids: item?.sub_service_ids,
    };
    let image = item?.image?.split("/");
    let imageName = image[image?.length - 1];
    setimage(imageName);
    setData(obj);
    setShow(true);
  };
  return (
    <>
      <div className="new_order_nav ">
        <div>
          <h4 className="d-flex align-items-center">
            <img
              src={studiAdd}
              alt=""
              style={{ marginRight: "11px", width: "22px", height: "19px" }}
            />
            Add Studio{" "}
          </h4>
        </div>
      </div>
      <div
        style={{
          height: "85vh",
          background: "white",
          boxShadow: "0px 0px 4px 0 rgba(0, 0, 0, 0.25)",
          marginRight: "12px",
          paddingLeft:"10px",
        }}
      >
        <div className="row">
          <div className="col-lg-10 col-12 ">
            <h1
              style={{
                fontSize: "24px",
                fontWeight: "500",
                lineHeight: "28px",
                color: "#494949",
                padding: "13px 11px 0 11px",
                margin: "0",
              }}
            >
              My Studios
            </h1>
            <p className="comm_para" style={{ margin: "0 11px" }}>
              Add your studios with detailed and impressive descriptions where
              clients can hire your studio spaces. This description will be
              shown on the service detail page on the client side and can impact
              your orders.
            </p>
          </div>
          <div className="col-lg-2 col-12  d-flex align-items-start justify-content-center mt-3">
            <button
              onClick={() => {
                setShow(true);
                setData({
                  image: "",
                  name: "",
                  description: "",
                  additional_information:"",
                  address: "",
                  city_id: "",
                  only_inquiry:0,
                  state_id: "",
                  amenities: [],
                });
              }}
              style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                padding: "7px 20px",
                color: "white",
                background: "#494949",
                borderColor: "transparent",
                outline: "none",
                borderRadius: "4px",
              }}
            >
              Add Studio
            </button>
          </div>
        </div>
        <div>
          <div
            className="row p-0 m-0 addStudio_main"
            style={{ height: "70vh", overflow: "scroll" }}
          >
            {studioData?.map((item) => {
              return (
                <div className="col-lg-4 col-md-6 col-12 my-2">
                  <div className="studio_card" style={{ height: "60vh" }}>
                    <img
                      style={{ height: "100%", width: "100%" }}
                      src={`${item.image}`}
                      alt=""
                    />

                    <div className="studio_content">
                      {/* <div dangerouslySetInnerHTML={{ __html: item.description}} className="studio_para">
                      </div> */}
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                        className="studio_para color_text"
                        style={{
                          flexGrow: 1,
                          maxHeight: "calc(52vh - 90px)", // Ensure it does not exceed available height minus the footer height
                          overflowY: "auto",
                        }}
                      ></div>
                      <div className="studio_list">
                        <p>Amenities</p>
                        <ul className="Stud_amenty">
                          {item?.studio_amenities?.map((items) => {
                            return (
                              <li style={{color:"white"}}>
                                <FiCheckCircle className="me-2" />
                                {items?.amenity}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="studio_card_photo">
                      <div className="studio_name m-0" >
                        <div>
                          <h1>{item.name}</h1>
                          <p>{item.address}</p>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div
                            onClick={() => handleEdit(item)}
                            className="studi_icon"
                          >
                            <MdEdit />
                          </div>
                          <div
                            onClick={() => delStudio(item?.studio_id)}
                            className="studi_icon"
                          >
                            <RiDeleteBin6Line />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <StudioDetail
          image={image}
          setImage={setimage}
          data={data}
          setData={setData}
          show={show}
          setShow={setShow}
        />
      </div>
    </>
  );
}

export default AddStudio;
