import React, { useEffect, useState } from "react";
import confirmOrder from "../../../asset/icons/confirmOrder.png";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useAuth } from "../../../Context/ContextProvider";
import { GrAttachment } from "react-icons/gr";
function SubmitDataLink({ show, setShow, order, orderServiceId, pagination }) {
  const { setOrderLinkData, getOrderLinkData, orderLinkData, setOrderLinkDatas } = useAuth()
  const [data, setData] = useState({
    title: "",
    description: "",
    link: "",
    type: "sample",
    status: 1,
  })
  useEffect(() => {
    getOrderLinkData(orderServiceId)
    // eslint-disable-next-line
  }, [])
  const [fieldErrors, setFieldErrors] = useState()
  function validateData(data) {
    let errors = {};
    if (!data.title) {
      errors.title = "Title is required.";
    }
    if (!data.link) {
      errors.link = "Link is required.";
    }
    else if (!validateUrl(data.link)) {
      errors.link = "Invalid URL!";
    }
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  }
  const validateUrl = (url) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)' + // protocol (required)
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))' + // domain name or IP address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return urlPattern.test(url);
  };
  const [await1, setAwait1] = useState(false)
  const downloadFile = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName; // The name you want to give the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAllFiles = (attachments) => {
    attachments.forEach((attachment) => {
      downloadFile(attachment.url, attachment.file_name);
    });
  };
  const getStatusClass = (status) => {
    switch (status) {
      case "Confirmed":
        return "status-confirmed";

      case "Awaiting Data":
        return "status-awaiting";

      case "Data Ready For Review":
        return "status-revision";

      case "Await For Revision":
        return "status-revision";

      case "Await for Original Data":
        return "status-original-submitted";

      case "Ready For Complete":
        return "status-confirmed";
      case "Approved Data":
        return "status-confir";
      case "Partially Ready For Complete":
        return "status-confir";
      case "Client Reviewing":
        return "status-reviewing";
      case "Completed":
        return "status-original-submitted";

      case "Partially Completed":
        return "status-original-submitted";
      case "Required Original Data":
        return "status-original-requested";
      case "Revision Requested":
        return "status-revision";
      default:
        return "";
    }
  };
  return (
    <div>
      <div className="new_order_nav">
        <div>
          <h4 className="d-flex align-items-center">
            {" "}
            <img
              src={confirmOrder}
              alt=""
              style={{ marginRight: "11px", width: "22px", height: "19px" }}
            />
            Order ID 235487
          </h4>
        </div>
        <div
          className="new_order_nav_btn"
          onClick={() => {
            setShow({ ...show, await_data: false })
            setOrderLinkDatas()
          }}
        >
          <button className="btn_11" style={{ borderRadius: "4px" }}>
            back <IoIosArrowRoundBack />{" "}
          </button>
        </div>
      </div>
      <div style={{ overflowY: "auto" }} className="table_box">
        {orderLinkData?.orderServiceStatus?.status_name !== "Client Reviewing" && orderLinkData?.orderServiceStatus?.status_name !== "Completed" && orderLinkData?.orderServiceStatus?.status_name !== "Ready For Complete" && <>{
          orderLinkData?.orderServiceStatus?.status_name === "Revision Requested" || orderLinkData?.orderServiceStatus?.status_name === "Required Original Data" ?
            <>
              {
                await1 ?
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div className="water">
                          <p className="water_p">Add {orderLinkData?.orderServiceStatus?.status_name === "Revision Requested" ? "watermarked sample" : "original"} Data Link</p>
                          <p className="water_time">12 June 2024. 06:52 A. M.</p>
                        </div>
                        <p className="water_con">
                          Please provide the URL for the {orderLinkData?.orderServiceStatus?.status_name === "Revision Requested" ? "watermarked sample" : "original"} data.
                        </p>
                      </div>
                      <div className="col-lg-6 col-12">
                        <label htmlFor="" className="new_order_label">
                          {orderLinkData?.orderServiceStatus?.status_name === "Revision Requested" ? "Sample" : "Original"} Data Title
                        </label>
                        <div className="new_order_inps" style={{ position: "relative" }}>
                          <input
                            type="text"
                            name=""
                            onChange={(e) => { setData({ ...data, title: e.target.value }) }}
                            id=""
                            placeholder={` ${orderLinkData?.orderServiceStatus?.status_name === "Revision Requested" ? "Sample" : "Original"} Data Title`}
                          />
                          {fieldErrors?.title && <span className="error_text" style={{ top: "100%", position: "absolute" }}>{fieldErrors?.title}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <label htmlFor="" className="new_order_label">
                          {orderLinkData?.orderServiceStatus?.status_name === "Revision Requested" ? "Sample" : "Original"} Data Link
                        </label>
                        <div className="new_order_inps" style={{ position: "relative" }}>
                          <input
                            type="text"
                            name=""
                            onChange={(e) => { setData({ ...data, link: e.target.value }) }}
                            id=""
                            placeholder="e.g. Dropbox, Google Drive"
                          />
                          {fieldErrors?.link && <span className="error_text" style={{ top: "100%", position: "absolute" }}>{fieldErrors?.link}</span>}
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <label htmlFor="" className="new_order_label">
                          Description
                        </label>
                        <div className="new_order_inps" style={{ height: "150px" }}>
                          <textarea onChange={(e) => { setData({ ...data, description: e.target.value }) }} name="" id="" placeholder="Write here" style={{ resize: "none", background: "transparent", borderColor: "transparent", width: "100%", outline: "none", color: "#667085" }}></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <button onClick={() => {
                          if (orderLinkData?.orderServiceStatus?.status_name === "Revision Requested" || orderLinkData?.orderServiceStatus?.status_name === "Awaiting Data") {
                            if (validateData(data)) {
                              setOrderLinkData({ ...data, order_id: order?.id, order_service_id: orderServiceId, type: "sample" }, pagination)
                              setShow({ ...show, await_data: false })
                              console.log(orderServiceId);
                            }
                          }
                          else {
                            if (validateData(data)) {
                              setOrderLinkData({ ...data, order_id: order?.id, order_service_id: orderServiceId, type: "original" }, pagination)
                              setShow({ ...show, await_data: false })
                              console.log(orderServiceId);
                            }
                          }
                        }} className="water_btn">
                          Submit
                        </button>
                        <button onClick={() => setAwait1(false)} className="water_btn_discard ms-3">
                          Discard
                        </button>
                      </div>
                    </div>
                  </>
                  :
                  <div className="d-flex justify-content-end mb-2">
                    <button onClick={() => setAwait1(true)} className="water_btn mt-0">
                      Reply
                    </button>
                  </div>
              }
            </>
            :
            <>
              <div className="row">
                <div className="col-12">
                  <div className="water">
                    <p className="water_p">Add Watermarked Sample Data Link</p>
                    <p className="water_time">12 June 2024. 06:52 A. M.</p>
                  </div>
                  <p className="water_con">
                    Please provide the URL for the watermarked sample data.
                  </p>
                </div>
                <div className="col-lg-6 col-12">
                  <label htmlFor="" className="new_order_label">
                    Sample Data Title
                  </label>
                  <div className="new_order_inps" style={{ position: "relative" }}>
                    <input
                      type="text"
                      name=""
                      onChange={(e) => { setData({ ...data, title: e.target.value }) }}
                      id=""
                      placeholder="Sample Data Title"
                    />
                    {fieldErrors?.title && <span className="error_text" style={{ top: "100%", position: "absolute" }}>{fieldErrors?.title}</span>}
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <label htmlFor="" className="new_order_label">
                    Watermarked Sample Data Link
                  </label>
                  <div className="new_order_inps" style={{ position: "relative" }}>
                    <input
                      type="text"
                      name=""
                      onChange={(e) => { setData({ ...data, link: e.target.value }) }}
                      id=""
                      placeholder="e.g. Dropbox, Google Drive"
                    />
                    {fieldErrors?.link && <span className="error_text" style={{ top: "100%", position: "absolute" }}>{fieldErrors?.link}</span>}
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="" className="new_order_label">
                    Description
                  </label>
                  <div className="new_order_inps" style={{ height: "150px" }}>
                    <textarea onChange={(e) => { setData({ ...data, description: e.target.value }) }} name="" id="" placeholder="Write here" style={{ resize: "none", background: "transparent", borderColor: "transparent", width: "100%", outline: "none", color: "#667085" }}></textarea>
                  </div>
                </div>
                <div className="col-12">
                  <button onClick={() => {
                    if (orderLinkData?.orderServiceStatus?.status_name === "Revision Requested" || orderLinkData?.orderServiceStatus?.status_name === "Awaiting Data") {
                      if (validateData(data)) {
                        setOrderLinkData({ ...data, order_id: order?.id, order_service_id: orderServiceId, type: "sample" }, pagination)
                        setShow({ ...show, await_data: false })
                        console.log(orderServiceId);
                      }
                    }
                    else {
                      if (validateData(data)) {
                        setOrderLinkData({ ...data, order_id: order?.id, order_service_id: orderServiceId, type: "original" }, pagination)
                        setShow({ ...show, await_data: false })
                        console.log(orderServiceId);

                      }
                    }
                  }} className="water_btn">
                    Submit
                  </button>
                </div>
              </div>
            </>
        }</>
        }
        {orderLinkData?.data?.map((item) => {
          return (
            <div style={{ background: "#ECECEB", marginTop: "10px", borderRadius: "10px", padding: "1rem" }} className="row">
              <div className="col-12">
                <div className="water">
                  <p className="water_p">{item?.type==="original"?"Original":"Watermarked Sample"} Data Link</p>
                  <p className="water_time">12 June 2024. 06:52 A. M.</p>
                </div>
              </div>
              <div className="col-lg-3 col-12 my-2">
                <p className="new_order_label m-0">{item?.type==="original"?"Original":"Sample"} Data Title</p>
                <p className="sample_p">{item?.title}</p>
              </div>
              <div className="col-lg-3 col-12 my-2">
                <p className="new_order_label m-0">{item?.type==="original"?"Original":"Watermarked Sample"} Data Link</p>
                <p className="sample_p">{item?.link}</p>
              </div>
              <div className="col-12">
                <div className="new_order_label">{item?.type==="original"?"Original":"Sample"} Data Description</div>
                <p className="sample_p">
                  {item?.description}
                </p>
                <hr />
              </div>
              <div className="client_feed">
                <div className="col-12 mb-2">
                  <div className="water">
                    <p className="water_p d-flex align-items-center">
                      Client Feedback{" "}
                      <span
                        className={`${getStatusClass(orderLinkData.orderServiceStatus?.status_name)} ms-3`}
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        {orderLinkData.orderServiceStatus?.status_name}{" "}
                      </span>
                    </p>
                    <p className="water_time">13 June 2024. 06:52 A. M.</p>
                  </div>
                </div>
                {item?.client_comment || item?.attachments?.length > 0 ? <div className="col-12">
                  <div className="feedback">
                    <div className="row">
                      <div className="col-12">
                        <p className="client_rev">
                          Hello <br />
                          {item?.client_comment}
                        </p>
                        <hr />
                        <div className="attach_file">
                          <p className="file_link">
                            <GrAttachment className="me-2" />
                            Attach file {item?.attachments?.length}
                          </p>
                          {item?.attachments?.length ? <button style={{ background: "#494949", fontSize: "15px", fontWeight: "600", color: "white", border: "none", padding: ".3rem 1rem", borderRadius: "5px" }} onClick={() => downloadAllFiles(item?.attachments)}>Download All Files</button> : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> : <>
                  {orderLinkData?.orderServiceStatus?.status_name === "Client Reviewing" ? <p>Please Wating For client Feedback</p> : <p>Please Send the Original Data without watermarked Your client requested original data</p>}
                </>}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default SubmitDataLink;
