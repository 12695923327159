
import React, { useEffect } from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider,StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import {  FaCalendarAlt, } from 'react-icons/fa'
import list_talent_bag from "../../asset/icons/list_talent_bag.png";
import { Slider } from "@mui/material";
import { useAuth } from "../../Context/ContextProvider";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  py: 1,
  outline:"none"
};
function TalentMUI({service_id}) {
  const {getCity,getSubCategry,subCategory,cityData,stateData,getvendorList}=useAuth()
  useEffect(()=>{

    handleOpen()
  },[])
  const parms=useParams()
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [filterData,setFilterData]=useState({
      start_service:"",
      end_service:"",
      slots:[],
      price:{
        min:"0",
        max:"4000"
      },
      subServiceIds:[],
      city_id:[],
      state_id:[]
    })

    useEffect(()=>{
      if(service_id)
    
      getSubCategry(service_id)
       // eslint-disable-next-line
    },[service_id])

    const [show1,setshow1]=useState(false)
     // eslint-disable-next-line
    const [data, setData]=useState()
  const [selectedOptions, setSelectedOptions] = useState([]);
    useEffect(()=>{
      // data?.sub_service_ids?.map((item)=>{
        
      let getData = subCategory?.filter((item) => {
        return data?.sub_service_ids?.includes(item.id);
      });
      setSelectedOptions(getData);
 // eslint-disable-next-line
    },[])
    const handleSelectOption = (value) => {
      const selectedId = parseInt(value);
      const selectedOption = subCategory?.find(option => option.id === selectedId);
      if (!selectedOptions.some(option => option.id === selectedId)) {
        setSelectedOptions([...selectedOptions, selectedOption]);
        setFilterData({ ...filterData,subServiceIds: [...selectedOptions, selectedOption].map(option => option.id) });
      }
    };
  
    const handleRemoveOption = (optionId) => {
      const updatedOptions = selectedOptions.filter(option => option.id !== optionId);
      setSelectedOptions(updatedOptions);
      setFilterData({ ...filterData,subServiceIds:updatedOptions.map(option => option.id)})
    };
    const [value, setValue1] = React.useState([0, 4000]);

  const handleChange = (event, newValue) => {
    setValue1(newValue);
    setFilterData({...filterData,price:{...filterData?.price,min:newValue[0],max:newValue[1]}})
  };
 // eslint-disable-next-line
  const [time, setTime] = useState([
    { start_time: "00:00", end_time: "01:00" },
    { start_time: "01:00", end_time: "02:00" },
    { start_time: "02:00", end_time: "03:00" },
    { start_time: "03:00", end_time: "04:00" },
    { start_time: "04:00", end_time: "05:00" },
    { start_time: "05:00", end_time: "06:00" },
    { start_time: "06:00", end_time: "07:00" },
    { start_time: "07:00", end_time: "08:00" },
    { start_time: "08:00", end_time: "09:00" },
    { start_time: "09:00", end_time: "10:00" },
    { start_time: "10:00", end_time: "11:00" },
    { start_time: "11:00", end_time: "12:00" },
    { start_time: "12:00", end_time: "13:00" },
    { start_time: "13:00", end_time: "14:00" },
    { start_time: "14:00", end_time: "15:00" },
    { start_time: "15:00", end_time: "16:00" },
    { start_time: "16:00", end_time: "17:00" },
    { start_time: "17:00", end_time: "18:00" },
    { start_time: "18:00", end_time: "19:00" },
    { start_time: "19:00", end_time: "20:00" },
    { start_time: "20:00", end_time: "21:00" },
    { start_time: "21:00", end_time: "22:00" },
    { start_time: "22:00", end_time: "23:00" },
    { start_time: "23:00", end_time: "24:00" }
  ]);
  // ============================time select ==================
  const [selectedTimes, setSelectedTimes] = useState([]);
function handleClick(timeItem) {
  const isInSlots = filterData?.slots?.some(slot => slot.start_time === timeItem.start_time && slot.end_time === timeItem.end_time);
  
  let updatedSlots;
  if (isInSlots) {
    updatedSlots = filterData?.slots?.filter(slot => !(slot.start_time === timeItem.start_time && slot.end_time === timeItem.end_time));
  } else {
    updatedSlots = [...filterData.slots, timeItem];
  }

  setFilterData({
    ...filterData,
    slots: updatedSlots
  });



  setSelectedTimes(updatedSlots);




}

const[tabValue,setValue]=useState(0)

// ===================== Date =======================


const handleDateChange = (date) => {
  const adjustedDate = dayjs(date[0]).format('YYYY-MM-DD');
  const adjustedDate1 = dayjs(date[1]).format('YYYY-MM-DD');

  setFilterData({...filterData,start_service:adjustedDate,end_service:adjustedDate1})
}
// ===================== select state and city =======================
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  function convertTo12HourFormat(time24) {
    const [hours24, minutes24] = time24.split(':').map(Number);
    let hours12 = hours24 % 12 || 12; // Convert 0 to 12
    const ampm = hours24 < 12 ? 'AM' : 'PM';
    return `${hours12?.toString().padStart(2, '0')}:${minutes24?.toString().padStart(2, '0')} ${ampm}`;
  }
  const disablePastDates = (date) => {
    return date.isBefore(dayjs(), 'day');
  };
  return (
    <>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{...style, borderRadius:"12px"}}>
        <div className="row p-3 m-0">
            <div className="col-12">
            <div className="row pb-3 photo d-flex align-items-center "> 
          <div className="col-lg-6 col-12  d-flex justify-content-start align-items-center">
         
            <h4 className="new_order_head p-0 my-0 ">Browse Service Providers In UK</h4>
          
          </div>
          <div className="col-lg-6 col-12">
            <div className="talent_side_bar ">
            <h6 onClick={()=>setValue(0)} className={`${tabValue===0?"comm_color":"comm_color_light"} d-flex align-items-center mx-3`}  style={{cursor:"pointer" , marginBottom:'0'}}>
            <FaCalendarAlt  style={{fontSize:"13px",marginRight:"8px"}}/>By Availability
            </h6>
            <h6 onClick={()=>setValue(2)} className={`${tabValue===2?"comm_color":"comm_color_light"} d-flex align-items-center`} style={{cursor:"pointer" , marginBottom:'0'}}>
            <img src={list_talent_bag} alt="" style={{fontSize:"13px" , marginRight:"8px"}}/>
            By Service
            </h6>
            </div>
         
          </div>
        </div>



            
        
        <div className="tal_mui">
          <div className="commm_list_height">
          {tabValue===0&&<div className="avaiabilty_tel">

<div className="row me-0 m-0">
    <div className='col-lg-6 col-12 p-0 '>
  <div className="talent_con talent_con122  ">
  <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[

          'StaticDateRangePicker',
        ]}
      >


      </DemoContainer>
    </LocalizationProvider>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
<DemoContainer components={['DateRangeCalendar', 'DateRangeCalendar']}>
<DemoItem >
<StaticDateRangePicker
       shouldDisableDate={disablePastDates}
            onChange={handleDateChange}
            renderInput={(startProps, endProps) => (
              <>
                <input ref={startProps.inputRef} {...startProps.inputProps} />
                <input ref={endProps.inputRef} {...endProps.inputProps} />
              </>
            )}
          />
        </DemoItem>

</DemoContainer>
</LocalizationProvider>
  </div>
   

    </div>

    <div className="col-lg-6 col-12 pe-0">
      <div className="list_talent_time">
        <div className="row m-0">


          {

              time.map((item, id)=>{
                return(
                  <div key={id} className={`col-lg-6 col-12 p-0 pb-2 new-time_slot ${id%2===0?"pe-1":"ps-1"} `}>
            <div className={`list_talent_time_inner ${filterData?.slots?.includes(item)?"time_active":""}`} style={{whiteSpace:"nowrap",fontSize:"15px"}} onClick={()=>handleClick(item)}  >
            {convertTo12HourFormat(item?.start_time) } to {convertTo12HourFormat(item?.end_time)}
            </div>
          </div>
                )
              })

          }
          
          
        </div>
      </div>
    </div>
    </div>
</div>}
{tabValue===2&&
  <div className="row me-0">
 <h6 >Filter By Service</h6>
 
  <div className=" col-12 mb-3 pe-0">
    <div className=" lsi_inp ">
      <label htmlFor="list_srch">
      <img src={list_talent_bag} alt="" style={{fontSize:"15px" , marginRight:"10px"}}/>
      </label>
     <div onClick={()=>{
setshow1(!show1)
}} style={{width:"100%",position:"relative"}}>
<div>
<span>Search Service</span>
{/* {selectedOptions?.length===0&&<span>Search Service</span>} */}

</div>

{show1&&<ul style={{position:"absolute",marginTop:"12px",width:"100%",zIndex:"99", height:"25vh", overflow:"scroll"}} className='custom_ul'>
  {subCategory?.map(option => (
    <li key={option.id} onClick={()=>{
      
      handleSelectOption(option.id)
      setshow1(true)
    }}>{option.name}</li>
  ))}
</ul>}
{/* <IoIosArrowDown style={{position:"absolute",right:"0%",top:"5px",fontSize:"13px"}}  /> */}
      </div>
    </div>
    <div style={{marginTop:"10px", display:'flex', flexWrap:"wrap", alignItems:"center"}}>
    {selectedOptions?.map((selectedOption) => (
  <span  key={selectedOption?.id} style={{ marginRight: '5px',background:"#494949",color:"white",padding:"0rem 0rem 0rem 1rem",borderRadius:"5px",fontSize:"13px",fontWeight:"500",letterSpacing:"1px",marginBottom:"10px"}}>
    {selectedOption?.name}
    <button style={{background:"none",border:"none",color:"white",marginLeft:"0px", padding:".5rem 1rem"}} onClick={() => handleRemoveOption(selectedOption.id)}>x</button>
  </span>
))}
    </div>
  <hr/>
  
  </div>
<div className="row  ms-0 p-0">
          <h6 >Filter By Location</h6>
          <div className="col-lg-6 col-12 mb-3 pe-0">
            <div className="comm_select lsi_select">
              <select name="" id=""  onChange={(e)=>{
                 const selectedOption = e.target.options[e.target.selectedIndex];
                 setSelectedState(selectedOption.text);
                setFilterData({...filterData,state_id:[parseInt(e.target.value)]})
                getCity(e.target.value)

                
                }}>
              <option  hidden>State</option>
                {stateData?.map((item)=>{
                  return(
                    <option  value={item?.id}>{item?.name}</option>
                    
                  )
                })}
              </select>
            </div>
          </div>
          <div className="col-lg-6 col-12 mb-3 pe-0 ">
            <div className="comm_select lsi_select">
              <select name="" id=""  onChange={(e)=>{
                setFilterData({...filterData,city_id:[parseInt(e.target.value)]});
                const selectedCityOption = e.target.options[e.target.selectedIndex];
                 setSelectedCity(selectedCityOption.text);
                }}>
              <option  hidden>City</option>

              {cityData?.map((item)=>{
                  return(
                    <option value={item?.id}>{item?.name}</option>
                  )
                })}
              </select>
            </div>
          </div>
        <hr style={{width:"98%" , marginLeft:'12px'}}/>
        <div className="col-12 mb-3 pe-0 d-flex justify-content-between">
        <h6 >Filter by Price Range</h6>
        <h6 style={{color:"#494949",fontSize:"15px",fontWeight:"700"}}>£{value[0]} - £{value[1]}</h6>
        </div>
        <div className="col-12 mb-3 mx-2 pe-3">
        <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        max={4000}
        onChange={handleChange}
        valueLabelDisplay="auto"
        />
        {/* </div> */}
        </div>
        </div>

</div>}

          </div>
          


            <div className="row me-0">
                
            <div className="col-12 pt-3">
            <div className="talent_mui_footer">
                    <p>
                    
                       {/* <span>Exploring vendors available on <b>{formatDate(dateOnly)}</b> </span>      */}
                       {filterData?.start_service ? 
  <span>Exploring vendors available on <b>{filterData?.start_service?.toString()}</b>.{" "}</span> 
  : 
  null
}   

{/* ================================ time select -======================== */}
                       {selectedTimes?.length > 0 && (
    <span> Available time slots on this date: 
      {selectedTimes?.map((time, index) => (
        <b key={index}> {convertTo12HourFormat(time?.start_time) } to {convertTo12HourFormat(time?.end_time)}
         {index === selectedTimes?.length - 2 ? ' and ' : index < selectedTimes?.length - 2 ? ', ' : ''}
        </b>
      ))}
    </span>
  )}

  {/* ======================= by amnity====================== */}
                        {selectedOptions.length>0 &&(
                            <span> serving {" "}
                        {selectedOptions?.map((selectedOption, idx) => (
                        <span  key={selectedOption.id}>
                        <b>{ selectedOption.name}and {" "}
                         </b>
   
                          </span>
                            ))}    services, </span>)
                        }


{/* ============================= by location ===================== */}

{
  selectedCity && selectedState&&(
<span>
        at a location within the <span><b>{selectedCity || 'city'}</b></span> of <span><b>{selectedState || 'State'}</b>.</span>.
      </span>
  )
}


                    </p>
                  </div>
            </div>
        </div>
        </div>

                


            </div>
        </div>
   <footer>
   <div className="d-flex justify-content-center align-items-center mb-1">
    <button className="mx-4 mt-3 mb-3" style={{padding:".5rem 3rem",background:"#494949",color:"white",border:"none",borderRadius:"5px", width:"100%"}} onClick={()=>{
      handleClose()
      getvendorList(parms.id,filterData)}}>Filter</button>

        </div>
   </footer>
      </Box>
    </Modal>
  </>
  )
}

export default TalentMUI
