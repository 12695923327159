import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  LocalizationProvider,
  StaticDateRangePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import React, { useEffect, useRef, useState } from "react";
import { FaRegCalendarAlt, FaSortAmountUpAlt } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import list_talent_bag from "../../asset/icons/list_talent_bag.png";
import list_avail_arrow from "../../asset/icons/list_avail_arrow.png";
import { Slider } from "@mui/material";
import { useAuth } from "../../Context/ContextProvider";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

function TalentavailabilityStudio({
  show,
  setShow,
  service_id,
  setFilterData,
  filterData,
  value,
  setValue1,
  celedate,
  setDate,
}) {
  const {
    getCity,
    amintyData,
    getSubCategry,
    subCategory,
    cityData,
    stateData,
    getstudioList,
  } = useAuth();

  const [show1, setshow1] = useState(false);
  const parms = useParams();
  useEffect(() => {
    if (service_id)
      getSubCategry(service_id);
     // eslint-disable-next-line
  }, [service_id]);
  // eslint-disable-next-line
  const [data, setData] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {

    let getData = subCategory?.filter((item) => {
      return data?.sub_service_ids?.includes(item.id);
    });
    setSelectedOptions(getData);
// eslint-disable-next-line
  }, []);

  const handleSelectOption = (value) => {

    const selectedId = parseInt(value);
    const selectedOption = filteredResults?.find(
      (option) => option.id === selectedId
    );

    if (!selectedOptions.some((option) => option.id === selectedId)) {
      setSelectedOptions([...selectedOptions, selectedOption]);
      setFilterData({
        ...filterData,
        amenityIds: [...selectedOptions, selectedOption].map(
          (option) => option.id
        ),
      });
    }
  };

  const handleRemoveOption = (optionId) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option.id !== optionId
    );
    setSelectedOptions(updatedOptions);
    setFilterData({
      ...filterData,
      amenityIds: updatedOptions.map((option) => option.id),
    });
  };
  const [inputValue, setInputValue] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    const filtered = amintyData?.amenities?.filter((item) =>
      item.amenity.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredResults(filtered);
  };
// eslint-disable-next-line
  const [time, setTime] = useState([
    { start_time: "00:00", end_time: "01:00" },
    { start_time: "01:00", end_time: "02:00" },
    { start_time: "02:00", end_time: "03:00" },
    { start_time: "03:00", end_time: "04:00" },
    { start_time: "04:00", end_time: "05:00" },
    { start_time: "05:00", end_time: "06:00" },
    { start_time: "06:00", end_time: "07:00" },
    { start_time: "07:00", end_time: "08:00" },
    { start_time: "08:00", end_time: "09:00" },
    { start_time: "09:00", end_time: "10:00" },
    { start_time: "10:00", end_time: "11:00" },
    { start_time: "11:00", end_time: "12:00" },
    { start_time: "12:00", end_time: "13:00" },
    { start_time: "13:00", end_time: "14:00" },
    { start_time: "14:00", end_time: "15:00" },
    { start_time: "15:00", end_time: "16:00" },
    { start_time: "16:00", end_time: "17:00" },
    { start_time: "17:00", end_time: "18:00" },
    { start_time: "18:00", end_time: "19:00" },
    { start_time: "19:00", end_time: "20:00" },
    { start_time: "20:00", end_time: "21:00" },
    { start_time: "21:00", end_time: "22:00" },
    { start_time: "22:00", end_time: "23:00" },
    { start_time: "23:00", end_time: "24:00" },
  ]);
  // eslint-disable-next-line
  const [view, setView] = useState([]);
  function handleClick(timeItem) {
    const isInSlots = filterData?.slots?.some(
      (slot) =>
        slot.start_time === timeItem.start_time &&
        slot.end_time === timeItem.end_time
    );

    let updatedSlots;
    if (isInSlots) {
      updatedSlots = filterData?.slots?.filter(
        (slot) =>
          !(
            slot.start_time === timeItem.start_time &&
            slot.end_time === timeItem.end_time
          )
      );
    } else {
      updatedSlots = [...filterData.slots, timeItem];
    }

    setFilterData({
      ...filterData,
      slots: updatedSlots,
    });
  }
  // const [value, setValue1] = React.useState([0, 500]);
  const handleChange = (event, newValue) => {
    setValue1(newValue);
    setFilterData({
      ...filterData,
      price: { ...filterData?.price, min: newValue[0], max: newValue[1] },
    });
  };
  // const [celedate, setDate] = useState();
  const handleDateChange = (date) => {
    setDate(date);
    const adjustedDate = dayjs(date[0]).format("YYYY-MM-DD");
    const adjustedDate1 = dayjs(date[1]).format("YYYY-MM-DD");

    setFilterData({
      ...filterData,
      start_service: adjustedDate,
      end_service: adjustedDate1,
    });
  };

  const [filter1, setFilter1] = useState(0);
  function convertTo12HourFormat(time24) {
    const [hours24, minutes24] = time24.split(":").map(Number);
    let hours12 = hours24 % 12 || 12; // Convert 0 to 12
    const ampm = hours24 < 12 ? "AM" : "PM";
    return `${hours12?.toString().padStart(2, "0")}:${minutes24
      ?.toString()
      .padStart(2, "0")} ${ampm}`;
  }
  const disablePastDates = (date) => {
    return date.isBefore(dayjs(), "day");
  };

  const [active, setActive] = useState(false);
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setActive(true);
      }, 400);
    } else {
      setActive(false);
    }
  }, [show]);
  const sidebarRef = useRef(null);

  useEffect(() => {
    // Function to handle click outside of sidebar
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setShow(false);
        setActive(false);
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShow, setActive]);
  return (
    <div
      className={`new_order_editor ${show ? "active_3" : ""}`}
      style={{ background: `${active ? "rgba(0, 0, 0, 0.628)" : "none"}` }}
    >
      <div
        className={`side_bar_example `}
        ref={sidebarRef}
        style={{ width: "56%" }}
      >
        <div className="row photo d-flex align-items-center ">
          <div className="col-lg-9 col-12 d-flex justify-content-start align-items-center">
            <button
              className="phoypgraphy"
              onClick={() => {
                setShow(false);
                setActive(false);
              }}
            >
              <img src={list_avail_arrow} alt="" />
            </button>
            <h4 className="new_order_head pe-3 my-0 mx-2">
              Browse Studio Providers In UK
            </h4>
          </div>
          <div className="col-lg-3 col-12 justify-content-end d-flex">
          <button
              className="new_phoypgraphy"
              onClick={() => {
                setShow(false);
                setActive(false);
              }}
            >
              <img src={list_avail_arrow} alt="" />
            </button>
            <h6
              onClick={() => {
                setFilterData({
                  start_service: "",
                  end_service: "",
                  slots: [],
                  price: {
                    min: "0",
                    max: "4000",
                  },
                  amenityIds: [],
                  city_id: [],
                  state_id: [],
                });
                setValue1([0, 4000]);
                setDate();
                setSelectedOptions([]);
              }}
              className={`order_id d-flex justify-content-end align-items-center ${
                view.length > 0 ? "comm_color" : ""
              } pt-2`}
              style={{ cursor: "pointer" }}
            >
              Clear
            </h6>
            <button
                  className="m-lg-3 mx-3 my-2"
                  style={{
                    padding: ".5rem 1rem",
                    background: "#494949",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    setShow(false)
                    getstudioList(parms.id, filterData)}}
                >
                   Filter
                </button>
          </div>
        </div>
        <hr style={{ margin: "0", borderColor: "#E1E2E9" }} />

        <div className="row d-flex justify-content-center ">
          <div className="col-lg-9 col-10  ">
            <div className="filter_avail d-flex justify-content-between mt-2">
              <a
                onClick={() => setFilter1(0)}
                className={`${filter1 === 0 ? "fil_dark" : "fil_light"}`}
                href="#availbiliy"
              >
                Filter by Availability
              </a>
              <a
                onClick={() => setFilter1(1)}
                className={`${filter1 === 1 ? "fil_dark" : "fil_light"}`}
                href="#location"
              >
                Filter by Location
              </a>
              <a
                onClick={() => setFilter1(2)}
                className={`${filter1 === 2 ? "fil_dark" : "fil_light"}`}
                href="#services"
              >
                Filter by Amenity
              </a>
              <a
                onClick={() => setFilter1(3)}
                className={`${filter1 === 3 ? "fil_dark" : "fil_light"}`}
                href="#price"
              >
                Filter by Price Range
              </a>
            </div>
          </div>
        </div>

        <div className="px-4 p-2 ">
          <div id="availbiliy" className="row d-flex align-items-center ">
            <div className="col-12 p-0 d-flex align-items-center">
              <FaRegCalendarAlt />
              <span
                className="ps-3"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "#494949",
                }}
              >
                Filter by Availability
              </span>
            </div>
          </div>

          <div className="row me-0 avaiabilty_tel">
            <div className="col-lg-6 col-12 p-0">
              <div className="talent_con  ">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["StaticDateRangePicker"]}
                  ></DemoContainer>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DateRangeCalendar", "DateRangeCalendar"]}
                  >
                    <DemoItem>
                      <StaticDateRangePicker
                        value={celedate}
                        onChange={handleDateChange}
                        shouldDisableDate={disablePastDates}
                        renderInput={(startProps, endProps) => (
                          <>
                            <input
                              ref={startProps.inputRef}
                              {...startProps.inputProps}
                            />
                            <input
                              ref={endProps.inputRef}
                              {...endProps.inputProps}
                            />
                          </>
                        )}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>

            <div className="col-lg-6 col-12 p-0">
              <div className="list_talent_time">
                <div className="row m-0">
                  {time.map((item, id) => {
                    return (
                      <div key={id} className="col-lg-6 col-12 pb-3 pe-lg-0 ">
                        <div
                          className={`list_talent_time_inner new_list_inner_time ${
                            filterData?.slots?.includes(item)
                              ? "time_active"
                              : ""
                          }`}
                          style={{ whiteSpace: "nowrap", fontSize: "15px" }}
                          onClick={() => handleClick(item)}
                        >
                          {convertTo12HourFormat(item?.start_time)} to{" "}
                          {convertTo12HourFormat(item?.end_time)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#7C7C7C" }} />
          <div id="location" className="row me-0">
            <div className="col-12 my-3 mt-2">
              <h3 className="heading_list">
                <FiMapPin style={{ fontSize: "15px", marginRight: "10px" }} />
                Filter by Location
              </h3>
            </div>
            <div className="col-lg-6 col-12 mb-2 pe-0 ">
              <div className=" lsi_select">
                <label htmlFor="avail_state">State</label>
                <div className="comm_select">
                  <select
                    name=""
                    value={filterData?.state_id?.length?filterData?.state_id[0]:""}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        state_id: [parseInt(e.target.value)],
                      });
                      getCity(e.target.value);
                    }}
                    id="avail_state"
                  >
                    <option hidden>State</option>
                    {stateData?.map((item) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 mb-2 ps-lg-4 pe-0">
              <div className=" lsi_select">
                <label htmlFor="avail_city">City</label>
                <div className="comm_select">
                  <select
                   value={filterData?.city_id?.length?filterData?.city_id[0]:""}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        city_id: [parseInt(e.target.value)],
                      });
                    }}
                    name=""
                    id="avail_city"
                  >
                    <option hidden>City</option>

                    {cityData?.map((item) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ borderColor: "#7C7C7C" }} />

          <div className="row me-0">
            <div id="services" className="col-12 my-3">
              <h3 className="heading_list">
                <img
                  src={list_talent_bag}
                  alt=""
                  style={{ fontSize: "15px", marginRight: "10px" }}
                />
                Filter by Amenity
              </h3>
            </div>

            <div className=" col-12 mb-3 pe-0">
              <div className=" lsi_inp ">
                <label htmlFor="list_srch">
                  <img
                    src={list_talent_bag}
                    alt=""
                    style={{ fontSize: "15px", marginRight: "10px" }}
                  />
                </label>
                <div
                  onClick={() => {
                    setshow1(!show1);
                  }}
                  style={{ width: "100%", position: "relative" }}
                >
                  <div>
                    {/* {selectedOptions?.length===0&&<span>Search Service</span>} */}
                    <input
                      type="text"
                      value={inputValue}
                      onChange={handleInputChange}
                      placeholder="Search Amenity"
                      style={{ border: "none", width: "100%", outline: "none" }}
                    />
                  </div>

                  {show1 && (
                    <ul
                      style={{
                        position: "absolute",
                        marginTop: "12px",
                        width: "100%",
                        zIndex: "99",
                      }}
                      className="custom_ul"
                    >
                      {filteredResults?.map((option) => (
                        <li
                          key={option.id}
                          onClick={() => {
                            handleSelectOption(option?.id);
                            setshow1(true);
                          }}
                        >
                          {option?.amenity}
                        </li>
                      ))}
                    </ul>
                  )}
                  {/* <IoIosArrowDown style={{position:"absolute",right:"0%",top:"5px",fontSize:"13px"}}  /> */}
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {selectedOptions?.map((selectedOption) => (
                  <span
                    style={{
                      border: "1px solid",
                      padding: ".3rem .5rem",
                      color: "white",
                      background: "#494949",
                      borderRadius: "5px",
                    }}
                    className="d-flex justify-content-center align-items-center me-2 mb-3"
                    key={selectedOption.id}
                  >
                    {selectedOption.amenity}
                    <button
                      onClick={() => handleRemoveOption(selectedOption.id)}
                      style={{
                        padding: "0",
                        fontSize: "12px",
                        background: "none",
                        margin: "0",
                        color: "white",
                      }}
                      className="mx-2"
                    >
                      x
                    </button>
                  </span>
                ))}
              </div>

              <hr style={{ borderColor: "#7C7C7C" }} />
              <div
                id="price"
                className="col-12 mb-3 pe-0 d-flex justify-content-between"
              >
                <h6
                  style={{
                    color: "#494949",
                    fontSize: "20px",
                    fontWeight: "400",
                    lineHeight: "21px",
                    display: "flex",
                    alignItems: "center",
                  
                  }}
                >
                  <FaSortAmountUpAlt style={{ marginRight: "8px" , fontSize:'20px' }} />
                  Filter by Price Range
                </h6>
                <h6 className="text-end mt-2 new_price_width"
                  style={{
                    color: "#494949",
                    fontSize: "20px",
                    fontWeight: "700",
                  }}
                >
                  £{value[0]} - £{value[1]}
                </h6>
              </div>
              <div className="col-12 mb-3 mx-2 pe-0 pricing_range">
                <Slider
                  getAriaLabel={() => "Temperature range"}
                 
                  value={value}
                  max={4000}
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                />
                {/* </div> */}
              </div>
              {/* <div className="d-flex justify-content-end align-items-center">
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TalentavailabilityStudio;
