import React, { useState } from "react";
import img1 from "../../asset/dummy/dummy1.webp";
import { Link, useParams } from "react-router-dom";
import { IoSearch } from "react-icons/io5";
import { Menu, MenuItem, Typography } from "@mui/material";
import { TiStarFullOutline } from "react-icons/ti";
import { VscSettings } from "react-icons/vsc";
import { ImStarEmpty, ImStarFull, ImLocation2 } from "react-icons/im";
import { FiCheckCircle } from "react-icons/fi";
import { useAuth } from "../../Context/ContextProvider";
import { FaStar } from "react-icons/fa6";
import { PiArrowElbowDownLeftBold } from "react-icons/pi";
import { RxCross1, RxCross2, RxCrossCircled } from "react-icons/rx";
import { FaCalendarAlt } from "react-icons/fa";
import TalentavailabilityStudio from "./Talent_availabilityStudio";
import TalentMUIStudio from "./TalentMUIStudio";
function STudioBanner({ data, service_id }) {
  const { web_url, getstudioList, loadingPage } = useAuth();
  
  const parms = useParams();
  const [value, setValue1] = React.useState([0, 4000]);
  const [celedate, setDate] = useState();
  const [filterData, setFilterData] = useState({
    start_service: "",
    end_service: "",
    slots: [],
    price: {
      min: "0",
      max: "4000",
    },
    amenityIds: [],
    city_id: "",
    state_id:[],
  });
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [show, setShow] = useState(false);
  const [views, setViews] = useState(false);
  return (
    <div
      className="our_portfolio list_talent studio_spaces mb-5"
      style={{ marginTop: "2rem" }}
    >
      <button className=" studio_spaces_btn my-2">STUDIO SPACES</button>
      <h1
        style={{ fontSize: "24px", fontWeight: "500", lineHeight: "32px" }}
        className="text-center mt-1"
      >
        Our agency represents a variety of studio spaces
        <br />
        throughout the UK{" "}
      </h1>
      <div
        style={{ width: "100%" }}
        className="row m-0 d-flex justify-content-center align-items-center py-2 mb-3 "
      >
        <div
          className="col-lg-4 col-12 p-0 d-flex align-items-center  "
          style={{ height: "3vh", position: "relative" }}
        >
          <div
            style={{ position: "absolute", height: "100%" }}
            className={`search_list_tal ${
              views ? "comm_opacity" : "comm_opacity_zero"
            }`}
          >
            <input
              onChange={(e) => {
                setTimeout(() => {
                  getstudioList(parms?.id, {
                    ...filterData,
                    name: e.target.value,
                  });
                }, 1000);
              }}
              type="text"
              value={filterData?.name}
            />
            <div className="d-flex align-items-center">
              <PiArrowElbowDownLeftBold />
              <RxCross1
                onClick={() => setViews(false)}
                className="ms-2"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <IoSearch
            onClick={() => setViews(true)}
            className={`${views ? "comm_opacity_zero" : "comm_opacity"}`}
            style={{ cursor: "pointer", position: "absolute" }}
          />
        </div>

        <div className="col-lg-8 col-12 fiter_main_box  d-flex justify-content-end p-0  ">
          <div className="d-flex  align-items-center">
          <div className="filt">
              {filterData?.end_service || filterData?.start_service ? (
                <span className="fiter_main_box_span">
                  Filter by Date
                  <RxCross2
                    onClick={() =>
                      setFilterData({...filterData, start_service: "", end_service: "" })
                    }
                  />
                </span>
              ) : (
                ""
              )}

              {filterData?.slots?.length? (
                <span className="fiter_main_box_span">
                  Filter by Time
                  <RxCross2 onClick={() =>
                      setFilterData({...filterData,slots: []})
                    } />
                </span>
              ) : (
                ""
              )}

              {filterData?.state_id && filterData?.city_id ? (
                <span className="fiter_main_box_span">
                  Filter by Location
                  <RxCross2 onClick={() =>
                      setFilterData({...filterData, city_id: "",
                        state_id: []})
                    }  />
                </span>
              ) : (
                ""
              )}

              {filterData?.amenityIds?.length ? (
                <span className="fiter_main_box_span">
                  Filter by Amenities
                  <RxCross2 onClick={() =>
                      setFilterData({...filterData,  amenityIds: []})
                    } />
                </span>
              ) : (
                ""
              )}

              {filterData?.price?.min > 0 || filterData?.price?.max < 500 ? (
                <span className="fiter_main_box_span">
                  Filter by Price
                  <RxCross2 onClick={() =>
                      setFilterData({
                        ...filterData,
                           
                          price: {
                        min: "0",
                        max: "500",
                      },
                    },
                  )
                    } />
                </span>
              ) : (
                ""
              )}
            </div>

            {(filterData?.end_service ||
            filterData?.start_service ||
            filterData?.slots?.length ||
            (filterData?.state_id && filterData?.city_id) ||
            filterData?.amenityIds?.length ||
            filterData?.price?.min > 0 ||
            filterData?.price?.max < 4000) ? (
              <span
                onClick={() => {
                  setFilterData({
                    start_service: "",
                    end_service: "",
                    slots: [],
                    price: {
                      min: "0",
                      max: "4000",
                    },
                    amenityIds: [],
                    city_id: "",
                    state_id: [],
                  });
                  getstudioList(parms?.id)
                  setValue1([0, 4000])
                  setDate()
                }}
                style={{ fontSize: "13px", cursor: "pointer",color:"#494949"}}
              >
                <span className="mx-2">|</span> Clear All Filter
                <RxCrossCircled
                  style={{ fontSize: "13px", marginLeft: ".5rem" }}
                />
              </span>
            ) : (
              ""
            )}
            <span
              onClick={handleClick1}
              style={{ fontSize: "13px", cursor: "pointer", color: "#494949" }}
            >
              {" "}
              <span className="mx-2">|</span> All
              <VscSettings style={{ fontSize: "13px", marginLeft: ".5rem" }} />
            </span>
            <span
              onClick={handleClick2}
              style={{ fontSize: "13px", cursor: "pointer", color: "#494949" }}
            >
              {" "}
              <span className="mx-2">|</span>Rating{" "}
              <TiStarFullOutline
                style={{ fontSize: "13px", marginLeft: ".5rem" }}
              />
            </span>
            <span
              onClick={() => setShow(true)}
              style={{ fontSize: "13px", cursor: "pointer", color: "#494949" }}
            >
              {" "}
              <span className="mx-2">|</span>
              <span style={{ display: "inline-flex", alignItems: "center" }}>
                Availability{" "}
                <FaCalendarAlt
                  style={{ fontSize: "13px", marginLeft: ".5rem" }}
                />
              </span>
            </span>

            <TalentavailabilityStudio
              service_id={service_id}
              show={show}
              setShow={setShow}
              setFilterData={setFilterData}
              filterData={filterData}
              setValue1={setValue1}
              value={value}
              celedate={celedate}
              setDate={setDate}
            />
          </div>
        </div>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl1}
          style={{ marginLeft: "-75px", marginTop: ".2rem" }}
          open={open1}
          onClose={handleClose1}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            style={{ borderBottom: "1px solid lightgray" }}
            onClick={() => {
              getstudioList(parms?.id, {
                sort: {
                  name: "best",
                  order: "desc",
                },
              });
              handleClose1();
            }}
          >
            {/* <Link style={{color:'gray',textDecoration:"none"}} to={'/client/my-order'}> */}
            <Typography style={{ color: "gray" }} textAlign="center">
              Best of the Week
            </Typography>
            {/* </Link> */}
          </MenuItem>
          <MenuItem
            style={{ borderBottom: "1px solid lightgray" }}
            onClick={() => {
              getstudioList(parms?.id, {
                sort: {
                  name: "price",
                  order: "asc",
                },
              });
              handleClose1();
            }}
          >
            {/* <Link style={{color:'gray',textDecoration:"none"}} to={'/client/my-order'}> */}
            <Typography style={{ color: "gray" }} textAlign="center">
              Price, low to high
            </Typography>
            {/* </Link> */}
          </MenuItem>
          <MenuItem
            style={{ borderBottom: "1px solid lightgray" }}
            onClick={() => {
              getstudioList(parms?.id, {
                sort: {
                  name: "price",
                  order: "desc",
                },
              });
              handleClose1();
            }}
          >
            {/* <Link style={{color:'gray',textDecoration:"none"}} to={'/client/my-order'}> */}
            <Typography style={{ color: "gray" }} textAlign="center">
              Price, high to low
            </Typography>
            {/* </Link> */}
          </MenuItem>
          <MenuItem
            style={{ borderBottom: "1px solid lightgray" }}
            onClick={() => {
              getstudioList(parms?.id, {
                sort: {
                  name: "date",
                  order: "desc",
                },
              });
              handleClose1();
            }}
          >
            {/* <Link style={{color:'gray',textDecoration:"none"}} to={'/client/my-order'}> */}
            <Typography style={{ color: "gray" }} textAlign="center">
              Date, old to new
            </Typography>
            {/* </Link> */}
          </MenuItem>
          <MenuItem
            onClick={() => {
              getstudioList(parms?.id, {
                sort: {
                  name: "date",
                  order: "asc",
                },
              });
              handleClose1();
            }}
          >
            {/* <Link style={{color:'gray',textDecoration:"none"}} to={'/client/my-order'}> */}
            <Typography style={{ color: "gray" }} textAlign="center">
              Date, new to old
            </Typography>
            {/* </Link> */}
          </MenuItem>
        </Menu>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl2}
          style={{ marginLeft: "-95px", marginTop: ".2rem" }}
          open={open2}
          onClose={handleClose2}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              getstudioList(parms?.id, { ...filterData, rating: 4 });
              handleClose2();
            }}
          >
            <ImStarFull
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarFull
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarFull
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarFull
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarEmpty
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <span style={{ fontSize: "13px", marginLeft: "5px" }}>& Up</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              getstudioList(parms?.id, { ...filterData, rating: 3 });
              handleClose2();
            }}
          >
            <ImStarFull
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarFull
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarFull
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarEmpty
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarEmpty
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <span style={{ fontSize: "13px", marginLeft: "5px" }}>& Up</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              getstudioList(parms?.id, { ...filterData, rating: 2 });
              handleClose2();
            }}
          >
            <ImStarFull
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarFull
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarEmpty
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarEmpty
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarEmpty
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <span style={{ fontSize: "13px", marginLeft: "5px" }}>& Up</span>
          </MenuItem>
          <MenuItem
            onClick={() => {
              getstudioList(parms?.id, { ...filterData, rating: 1 });
              handleClose2();
            }}
          >
            <ImStarFull
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarEmpty
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarEmpty
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarEmpty
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <ImStarEmpty
              style={{ fontSize: "22px", color: "#FAAF40", marginLeft: "5px" }}
            />
            <span style={{ fontSize: "13px", marginLeft: "5px" }}>& Up</span>
          </MenuItem>
        </Menu>
      </div>
      <div className="w-100">
        <div className="grid">
          {loadingPage ? (
            <>
              <p style={{ height: "300px" }} className="placeholder-glow">
                <span className="placeholder col-12 h-100"></span>
              </p>
              <p style={{ height: "300px" }} className="placeholder-glow">
                <span className="placeholder col-12 h-100"></span>
              </p>
              <p style={{ height: "300px" }} className="placeholder-glow">
                <span className="placeholder col-12 h-100"></span>
              </p>
              <p style={{ height: "300px" }} className="placeholder-glow">
                <span className="placeholder col-12 h-100"></span>
              </p>
              <p style={{ height: "300px" }} className="placeholder-glow">
                <span className="placeholder col-12 h-100"></span>
              </p>
            </>
          ) : (
            data?.map((item) => {
              return (
                <div className="card p-0">
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "#494949",
                      width: "100%",
                    }}
                    to={`${web_url}studio-profile/${item.slug}`}
                  >
                    <div
                      style={{ height: "231px", position: "relative" }}
                      className="img_vendor"
                    >
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={item?.studio_image ? item?.studio_image : img1}
                        alt="..."
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "0",
                          background: "#F1A73C",
                          color: "#FFFFFF",
                          top: "0",
                          padding: ".1rem 1rem",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        £ {item?.price}
                      </span>
                      <div
                        className="list_talent_review d-flex justify-content-between w-100 px-2 "
                        style={{ position: "absolute", bottom: "0" }}
                      >
                        <div className="d-flex  align-items-center">
                          <div>
                            <FaStar
                              className="d-flex  align-items-center"
                              style={{ fontSize: "14px", color: "#F1A73C" }}
                            />
                          </div>
                          <div
                            className="inco_text"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              lineHeight: "24px",
                              color: "#FFFFFF",
                            }}
                          >
                            {item?.rating?item?.rating:"5.0"}
                          </div>
                        </div>

                        <div className="d-flex  align-items-center">
                          <div>
                            <ImLocation2
                              className="d-flex  align-items-center"
                              style={{ fontSize: "12px", color: "#FFFFFF" }}
                            />
                          </div>
                          <div
                            className="inco_text"
                            style={{
                              fontSize: "12px",
                              fontWeight: "700",
                              lineHeight: "24px",
                              color: "#FFFFFF",
                            }}
                          >
                            Calif,Manchester
                          </div>
                        </div>
                      </div>
                      <div className="over_lay">
                        <div className="list_data">
                          <ul>
                            {item?.amenities?.map((itemss) => {
                              return (
                                <li>
                                  <FiCheckCircle
                                    style={{ marginRight: ".5rem" }}
                                  />
                                  {itemss}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            height: "22%",
                           
                            background: "#000000c8",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <button style={{ fontSize:"10px"}}>View Detail</button>
                        </div>
                      </div>
                    </div>
                    <h5
                      style={{
                        textTransform: "uppercase",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                      className="my-2"
                    >
                      {item?.name}
                    </h5>
                  </Link>
                </div>
              );
            })
          )}
        </div>
        {data?.length === 0 && (
          <h5 className="text-center">NO Studio Available!</h5>
        )}
      </div>
      {data?.length > 0 && <TalentMUIStudio show={show} setShow={setShow} />}
    </div>
  );
}

export default STudioBanner;
