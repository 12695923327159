import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import img from '../asset/Group.png'
import { Link } from 'react-router-dom';
import {useNavigate} from 'react-router-dom'
import { useAuth } from '../Context/ContextProvider';
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
export default function ClientForgetPassword() {
  const nanvigate=useNavigate()
  const {web_url}=useAuth()
  const handleSubmit = (event) => {
    event.preventDefault();
    nanvigate(`${web_url}/client/dashbord`)
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main"  style={{background:"#ECECEC"}} sx={{ height: '100vh' }}>
        <div className='row' >
            <div style={{background:"white"}} className='col-lg-6 col-md-6 col-12 p-5'>
            <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
         <Grid  container>
                <Grid item>
                  <Link to={`${web_url}clinet-sigup`}>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            <Typography component="h1" variant="h5" className='my-5'>
             Forget Password
            </Typography>
            <Box style={{width:"100%"}} component="form"  onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                type='email'
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{background:"#ECECEC"}}
              />
              <div className='d-flex justify-content-between align-items-center'>
     
                         <Link to={`${web_url}clinet-signin`} variant="body2">
                    Sign In ?
                  </Link>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2,background:"#494949" }}
              >
                Send Email
              </Button>
            </Box>
          </Box>
            </div>
            <div  className='col-lg-6 col-md-6 col-12 p-5'>
            <img className='w-100' style={{  filter:" grayscale(100%)"}} src={img} alt='...'/>
            </div>
        </div>
        <CssBaseline />
      </Grid>
    </ThemeProvider>
  );
}