import React, { useEffect, useState } from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import img from '../../src/asset/visa-cards1.png'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IoCheckmarkCircleSharp, IoCloseCircleSharp } from 'react-icons/io5';
import { useAuth } from '../Context/ContextProvider';
import { Menu, MenuItem, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import PaymentForm from './From';
import { FaCaretDown } from 'react-icons/fa6';

function Cheackout({ publicKey }) {
  const [data, setdata] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact: "",
    location: "",
    vendor_id: "",
    cart_key: "",
    existing_address: true,
    terms_and_conditions:false,
    payment_mode: "stripe",
    service_date: "2024-05-30",
    country_id: 230,
    state_id: ""
  })
  const vendor_data = JSON.parse(localStorage.getItem('vendor_data'))
  const { getClientTex, clientTex, getCart, handleClick, checkSlot, setError, removeCart, getCity, cityData, stateData, cartData, web_url } = useAuth()
  useEffect(() => {
    getClientTex(230)
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    const clinet = vendor_data?.data?.user
    if (vendor_data?.data?.token && vendor_data?.data.role === "client") {
      getCart()
      getCity(clinet?.state_id)
      setdata({
        first_name: clinet?.name,
        last_name: "",
        email: clinet?.email,
        contact: clinet?.phone_number,
        state_id: clinet?.state_id,
        city_id: clinet?.city_id,
        location: clinet?.address,
        service_date: new Date(),
        payment_mode: "stripe",
        existing_address: true,
        terms_and_conditions:false,
        debug: "1",
        country_id: 230,
      })
    }
    // eslint-disable-next-line
  }, [])
  const [errorMessage, setFieldErrors] = useState("");
  const [slots, setsots] = useState()
  useEffect(() => {
    let array = []
    // eslint-disable-next-line
    cartData?.data?.cart?.map((item) => {
      array.push({
        id: item?.slot?.id,
        service_date: item?.serviceDate
      })
    })
    setsots(array)
  }, [cartData])
  // eslint-disable-next-line
  const navigate = useNavigate()

  const isCheckoutDisabled = (cart) => {
    if (!cart || cart.length === 0) {
      return true;
    }

    const currentTime = new Date();
    const hasExpiredItems = cart.some(item => {
      const expiredAt = item.expired_at ? new Date(item.expired_at) : null;
      return expiredAt && currentTime > expiredAt;
    });

    return hasExpiredItems;
  };
  const validateStep = async () => {
    const isAnyReserved = await checkSlot(slots);
    let errors = {

    };

    if (isCheckoutDisabled(cartData?.data?.cart)) {
      errors.cart = "Location is required.";
      handleClick();
      setError({
        message: "Please remove the expired item from the cart!",
        color: "error"
      });

    }
    if (isAnyReserved) {
      handleClick();
      errors.cart = "Location is required.";
      setError({
        message: "Some slots are reserved. Please remove the reserved slots from cart.",
        color: "error"
      });
    }
    if (!data.first_name) {
      errors.name = "First Name is required.";
    }

    if (!data.last_name) {
      errors.last_name = "Last Name is required.";
    }
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!data.contact) {
      errors.contact = "Contact is required.";
    }
    if (!data.state_id) {
      errors.state_id = "State is required.";
    }
    if (!data.city_id) {
      errors.city_id = "City is required.";
    }
    if (!data.location) {
      errors.location = "Location is required.";
    }
    if (!data.terms_and_conditions) {
      errors.terms_and_conditions = "Terms & Condition is required.";
    }


    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const [check, setCheck] = useState(false)
  const [anchorElUser, setAnchorElUser] = React.useState(null);


  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  console.log(errorMessage);
  
  return (
    <div className="container upload">
      <NavBar />
      <div className="row">
        <div className="col-lg-8 col-12 my-2">
          <div className="figma_left">
            <h2>
              Contact Detail
            </h2>
            <div className="row">
              <div className="col-lg-6 col-12 my-3">
                <label htmlFor="" className="new_order_label ">First Name</label>
                <div className=" upload_title mt-1">
                  <input value={data?.first_name} onChange={(e) => setdata({ ...data, first_name: e.target.value })} type="text" placeholder="First Name" name="first_name" required />
                </div>
                {errorMessage.first_name && <span className="error_text" style={{ position: "absolute" }}>First Name is required</span>}
              </div>
              <div className="col-lg-6 col-12 my-3">
                <label htmlFor="" className="new_order_label ">Last Name</label>
                <div className=" upload_title mt-1">
                  <input value={data?.last_name} onChange={(e) => setdata({ ...data, last_name: e.target.value })} type="text" placeholder="Last Name" name="last_name" required />
                </div>
                {errorMessage.last_name && <span className="error_text" style={{ position: "absolute" }}>Last Name is required</span>}
              </div>
              <div className="col-lg-6 col-12 my-3">
                <label htmlFor="" className="new_order_label ">Email</label>
                <div className=" upload_title mt-1">
                  <input value={data?.email} onChange={(e) => setdata({ ...data, email: e.target.value })} type="email" placeholder="Email" name="email" required />
                </div>
                {errorMessage.email && <span className="error_text" style={{ position: "absolute" }}>Eamil is required</span>}
              </div>
              <div className="col-lg-6 col-12 my-3">
                <label htmlFor="" className="new_order_label ">Contact</label>
                <div className=" upload_title p-0 mt-1">
                  {/* <input value={data?.contact} onChange={(e) => setdata({ ...data, contact: e.target.value })} type="text"  placeholder="e.g 7700 900886" name="contact" required /> */}



                  <div className='profile_contacts ps-0' style={{ padding: "10px" }}>
                    <img style={{ width: "30px", height: "20px" }} src='https://flagcdn.com/w320/gb.png' alt='...' />
                    <span className='mx-2'>+44</span>
                  </div>
                  <input value={data?.contact} onChange={(e) => setdata({ ...data, contact: e.target.value })}
                    type="text"
                    maxLength={11}
                    placeholder="e.g 7700 900886"
                    pattern="[0-9]{4} [0-9]{6}"
                    className="inp"
                    name="contact"
                  />




                </div>
                {errorMessage.contact && <span className="error_text" style={{ position: "absolute" }}>Contact is required</span>}
              </div>

              <div className="radi">
                <div>
                  <label><input type="radio" checked={data?.existing_address ? true : false} onChange={() => {
                    const clinet = vendor_data?.data?.user
                    setdata({
                      ...data, existing_address: true,
                      state_id: clinet?.state_id,
                      city_id: clinet?.city_id,
                      location: clinet?.address,
                    })
                  }} /><span className='mx-2'>Existing Addresses</span></label>
                </div>
                <div className='me-2'>
                  <label><input type="radio" checked={data?.existing_address ? false : true} onChange={() => {
                    setdata({
                      ...data, existing_address: false,
                      location: "",
                      state_id: "",
                      city_id: ""

                    })

                  }} /><span className='mx-2'>New Address</span> </label>
                </div>
              </div>
              <div className=" col-12 my-3">
                <label htmlFor="" className="new_order_label ">Address</label>
                <div className=" upload_title mt-1">
                  <input value={data?.location} type="text" onChange={(e) => setdata({ ...data, location: e.target.value })} placeholder="114, st #f, fgt, Cardiff, Wales" name="address" required />
                </div>
                {errorMessage.location && <span className="error_text" style={{ position: "absolute" }}>Address is required</span>}
              </div>
              <div className="col-lg-6 col-12 my-3">
                <label htmlFor="" className="new_order_label ">State</label>
                <div className=" upload_title mt-1">
                  <select value={data?.state_id} onChange={(e) => {
                    setdata({ ...data, state_id: e.target.value })
                    getCity(e.target.value)
                  }} name="state" id="state" className='p-1' required>
                    <option hidden>State</option>
                    {stateData?.map((item) => {
                      return (
                        <option value={item?.id}>{item?.name}</option>
                      )
                    })}
                  </select>
                </div>
                {errorMessage.state_id && <span className="error_text" style={{ position: "absolute" }}>State is required</span>}
              </div>
              <div className="col-lg-6 col-12 my-3">
                <label htmlFor="" className="new_order_label ">City</label>
                <div className=" upload_title mt-1">
                  <select value={data?.city_id} onChange={(e) => setdata({ ...data, city_id: e.target.value })} name="city" id="city" required className='p-1'>
                    <option hidden>City</option>
                    {cityData?.map((item) => {
                      return (
                        <option value={item?.id}>{item?.name}</option>
                      )
                    })}
                  </select>
                </div>
                {errorMessage.city_id && <span className="error_text" style={{ position: "absolute" }}>City is required</span>}
              </div>


            </div>
            <div className="row">
              <div className="col-12 my-3 ">
                <h2 className='mb-4'>
                  Payment Selection
                </h2>
                <div className="radi">
                  <div className='visa_card'>
                    <label><input type="radio" checked={check ? false : true} onChange={() => setCheck(false)} /><img src={img} alt="" style={{ width: "100%" }} /></label>
                  </div>
                  {/* <div className='visa_card ms-4'>
                    <label><input type="radio" checked={check ? true : false} onChange={() => setCheck(true)} /><img src={img1} alt="" /></label>
                  </div> */}
                </div>
              </div>
              <div className="col-12 my-3">
                <label htmlFor="" className="new_order_label ">Please provide us with a brief description of your booking here so we can best accommodate your needs.</label>
                <div className="upload_title mt-1">
                  <textarea name="" id="" rows={5} cols={30} style={{ backgroundColor: 'transparent', border: 'none', resize: "none", color: '#494949', fontSize: '17px', fontWeight: 600, padding: 0, width: "100%", outline: "none" }} placeholder='Write here'></textarea>
                </div>
              </div>
            </div>

            <div className="row">
              <div style={{position:"relative"}} className="col-12">
                <div className="checkBtn ps-0">
                  <input checked={data?.terms_and_conditions?true:false} onChange={(e)=>setdata({...data,terms_and_conditions:e.target.checked})} type="checkbox" id="checkbotton"
                    style={{ fontSize: '20px' }} />
                  <label className='mx-2' htmlFor="checkbotton">I agree to the <span style={{ color: "#0075FF" }}>terms &amp; condition
                    <span className='error_text'>*</span>
                  </span></label>

                </div>
                {errorMessage.terms_and_conditions && <span className="error_text" style={{ position: "absolute" ,bottom:"-10px"}}>Terms & Condition is required</span>}


              </div>
              <PaymentForm publicKey={publicKey} order={validateStep} data={data} setData={setdata} cartData={cartData} />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-12 my-2">
          <div className="figma_right">
            <h3 style={{ fontSize: "25px" }}>
              Order Summary
            </h3>
            {cartData?.data?.cart?.map((item) => {
              return (
                <Accordion className='my-2'>
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{ background: "#EDEDED" }}
                  >
                    <div className='product_card w-100'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <IoCloseCircleSharp onClick={() => removeCart(item?.cart_id)} style={{ position: "absolute", top: "-8px", left: "-8px", fontSize: "20px", zIndex: "99" }} />
                        <h5>{item?.plan?.type === "studio" ? item?.plan?.studio?.name : item?.plan?.vendor_sub_service?.sub_service?.name}</h5>
                        <ExpandMoreIcon />
                      </div>
                      <div className="date_product">
                        <div className='d-flex justify-content-between align-items-center'>
                          <div style={{ width: "90%" }} className='d-flex justify-content-between'>
                            {item?.plan?.booking_service_type==="hourly_slots"&&<p>{item?.serviceDate} {item?.slot?.start_time} to {item?.slot?.end_time}</p>}
                            {item?.plan?.booking_service_type==="item_quantity"&&<p>{item?.quantity>9?`0${item?.quantity}`:item?.quantity} Items ({item?.quantity}X £{item?.plan?.price_set})</p>}
                          </div>
                          <span style={{ background: "#494949", color: "white", borderRadius: "5px", padding: ".2rem" }}>£{item?.plan?.booking_service_type==="hourly_slots"?item?.plan?.price_set:item?.plan?.price_set*item?.quantity}</span>

                        </div>
                        <p>Vender : {item?.vendor?.name}</p>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{ background: "#EDEDED" }}>
                    <ul className='checkout_ul'>

                      {item?.plan?.offers?.map((items) => {
                        if (items !== null) {

                          return (
                            <>
                              <li><IoCheckmarkCircleSharp className='icons' />{items}</li>
                            </>

                          )
                        }
                        return null; // Explici

                      })}




                    </ul>
                  </AccordionDetails>
                </Accordion>
              )
            })}

            <div>
              <Link to={`${web_url}order-summry`}>
                <button className='ordr_summ_btn my-2' style={{ width: "100%", borderRadius: "5px" }}>
                  Service Selection Summary
                </button>
              </Link>
              <button className='ordr_summ_btn my-2' style={{ width: "100%", borderRadius: "5px" }} onClick={handleOpenUserMenu}>Add More Services <FaCaretDown className='mx-3 ' /></button>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}

              >

                <MenuItem style={{ width: "205px" }} onClick={() => {
                  handleCloseUserMenu()
                  navigate(`${web_url}our-service`)
                }}>
                  <Typography textAlign="center">Photography Services</Typography>


                </MenuItem>
                <hr style={{ borderColor: "#d4d7db", margin: "5px 0" }} />
                <MenuItem style={{ width: "205px" }} onClick={() => {
                  handleCloseUserMenu()
                  navigate(`${web_url}our-studio`)
                }}>
                  <Typography textAlign="center">Studio Spaces</Typography>
                </MenuItem>
                <hr style={{ borderColor: "#d4d7db", margin: "5px 0" }} />
                <MenuItem style={{ width: "205px" }} onClick={() => {
                  handleCloseUserMenu()
                  navigate(`${web_url}explor-telent`)
                }}>
                  <Typography textAlign="center">Market Place</Typography>
                </MenuItem>
              </Menu>
            </div>

            <hr />
            <div className="footer_check">
              <div className="total">
                <h4>
                  Total Services
                </h4>
                <p className="num me-0">{cartData?.data?.cart?.length}</p>
              </div>
              <div className="total">
                <h4>
                  Subtotal
                </h4>
                <h4>
                  £{Math.floor(cartData?.data?.total?.subtotal)}
                </h4>
              </div>
              <div className="total">
                <h4>
                  VAT({Math.floor(clientTex?.tax)}%)
                </h4>
                <h4>
                  £{Math.round((cartData?.data?.total?.subtotal * clientTex?.tax) / 100)}
                </h4>
              </div>

              <div className="total">
                <h4>
                  Total Amount
                </h4>
                <h4 style={{ color: '#B9444B' }}>
                  £{cartData?.data?.total?.subtotal + (cartData?.data?.total?.subtotal * clientTex?.tax) / 100}
                </h4>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <Elements stripe={stripePromise} options={options}>
      <From setClientSecret={setClientSecret} clientSecret={clientSecret}/>
    </Elements> */}

    </div>
  )
}
export default Cheackout

