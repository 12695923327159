import React, {  useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/ContextProvider";
import { Box, Modal } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import hire_talent from "../../asset/icons/hire_talent.png";
import hire_talent_hover from "../../asset/icons/hire_talent_hover.png";
import studio from "../../asset/icons/studio_space.png";
import studio_hover from "../../asset/icons/studio_space_hover.png";
import EnquiryModalHome from "./EnquiryModalHome";
function JoinOurTeam() {
  const { webSettingData,web_url, home} = useAuth();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 530,
    bgcolor: "background.paper",
    boxShadow: 24,

    borderRadius: "10px",
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [imageSrc, setImageSrc] = useState(hire_talent);

  const handleMouseEnter = () => {
   
    setImageSrc(hire_talent_hover); // Change to the hover image
  };

  const handleMouseLeave = () => {
  
    setImageSrc(hire_talent); // Revert to the original image
  };
  const [imageSrc1, setImageSrc1] = useState(studio);

  const handleMouseEnter1 = () => {
   
    setImageSrc1(studio_hover); // Change to the hover image
  };

  const handleMouseLeave1 = () => {
  
    setImageSrc1(studio); // Revert to the original image
  };
  return (
    <div className="join_our_team my-5 mt-0 mb-0 text-center">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <button className="join_team_title my-3">join our team</button>
            {home[0]?.sections[4]?.description ? (
              <>
                <h3
                  className="joinTeam_heading"
                  dangerouslySetInnerHTML={{
                    __html:
                      home[0]?.sections[4]?.description.split("</h1>")[0] +
                      "</h1>",
                  }}
                ></h3>
                <p
                  className="joinTeam_para"
                  dangerouslySetInnerHTML={{
                    __html: home[0]?.sections[4]?.description
                      .split("</h1>")[1]
                      ?.trim(),
                  }}
                >
                  {/* Our marketplace serves as a central online hub where all types of photography service providers and studios can register to showcase their work and services. Clients can easily explore profiles, portfolios, and book services from a variety of providers, all in one convenient location. Now we have simplified the process of booking photography services for clients and vendors by providing an intuitive and efficient booking platform. */}
                </p>
              </>
            ) : (
              <h3 className="joinTeam_heading">
                Are you Photographer, Artist, Model, Makeup Artist or
                <br /> Art Director?
              </h3>
            )}

            <div className="mt-4 mb-3 pb-2 join_team_btn">
              <Link to={`${web_url}join-our-team`}>
                <button className="btn_join_2  mx-3">join our hub</button>
              </Link>

             {webSettingData?.data?.is_marketplace? <button onClick={() => handleOpen()} className=" btn_join_2">
                Explore All Talent
              </button>:""}

              
                <button onClick={() => handleOpen1()} className=" btn_join_2">Enquire us</button>
             
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="row">
            <div className="col-12">
              <div className="explore_talent">
                <div className="explore_talent_heading">
                  <h3>Choose your Business</h3>
                  <div className="cross" onClick={handleClose}>
                    <RxCross2 />
                  </div>
                </div>
                <hr className="m-0" />
                <div className="talent_space">
                  <Link to={`${web_url}explor-telent`}>
                    <button className="hire-btn"  onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                      <img
                        src={imageSrc}
                       
                        className="mb-4 pb-1 hire_img"
                        alt=""
                      />
                      <br />
                      Hire Talent
                    </button>
                  </Link>
                  <Link to={`${web_url}studio-list/studio`}>
                    <button  onMouseEnter={handleMouseEnter1}
                        onMouseLeave={handleMouseLeave1}>
                      <img src={imageSrc1} className="mb-4 pb-1" alt="" />
                      <br />
                      Studio Spaces
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <EnquiryModalHome open3={open1} handleClose3={handleClose1}  />
      
    </div>
  );
}

export default JoinOurTeam;
