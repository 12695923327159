import React, { useEffect } from 'react'
import { FaArrowLeft, FaArrowRight, FaCircleDot } from 'react-icons/fa6'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { useAuth } from '../../Context/ContextProvider'
import Discard from './Discard'

function Step3({onNext,onPrevious,data, setData, fieldErrors}) {
  const {TermsandPrivacy,terms}=useAuth()
  console.log("term,",terms)
  useEffect(()=>{
    TermsandPrivacy("marketplace-vendors-registration-policy")
    // eslint-disable-next-line
  },[])
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  return (
    <div className="container">
    <div style={{ boxShadow: "none",padding:"1rem 0 0" }} className="row main mt-3">
      <div className="col-lg-3 col-12 ps-1">
      <div className="side_basic">
            <div className="basic_info">
              <div className="row p-0">

                <div style={{position:"relative"}} className='col-2 p-0'>
                <div className="check">
                    <span>
                      <IoIosCheckmarkCircle style={{ fontSize: "25px", color: "#53B73A" }} />
                    </span>
                  </div>
                  <div style={{ position: "absolute", height: "32px", left: "15px", border: "1px solid lightgray", top: "50%" }}></div>
                </div>
                <div className='col-10'>

                  <h5>Basic Information</h5>
                  <p>Get Started Here</p>
      
                </div>
                <div style={{position:"relative"}} className='col-2 p-0'>
                <div className="check">
                    <span>
                      <IoIosCheckmarkCircle style={{ fontSize: "25px", color: "#53B73A" }} />
                    </span>
                  </div>
                  <div style={{ position: "absolute", height: "32px", left: "15px", border: "1px solid lightgray", top: "50%" }}></div>
                </div>
                <div className='col-10'>

                  <h5>Professional Information</h5>
                  <p>Showcase your skills.</p>
      
                </div>
                <div style={{position:"relative"}} className='col-2 p-0'>
                <div className="check">
                    <span>
                    <FaCircleDot  style={{fontSize:"25px",color:"#29B3FF"}} />
           
                    </span>
                  </div>
                  <div style={{ position: "absolute", height: "32px", left: "15px", border: "1px solid lightgray", top: "50%" }}></div>
                </div>
                <div className='col-10'>

                  <h5>Terms & Conditions</h5>
                  <p>Read before joining.</p>
      
                </div>
                <div className='col-2 p-0'>
                <div className="check">
                    <span>
                    <div>
                        <div></div>
                      </div>
                    </span>
                  </div>
                </div>
                <div className='col-10'>

                  <h5>HUrrah ! </h5>
                  <p>Welcome aboard!</p>
      
                </div>
    
              </div>
             
            </div>
          </div>
      </div>
      <div className="col-lg-9 col-12 mt-lg-0 mt-5 pe-1">
        <div className="side_basic p-3">
          <div className="heading1">
            <h3 style={{fontSize:"18px",fontWeight:"700", color:"#494949", padding:"10px 17px"}}>
            Terms & Conditions
            </h3>
          </div>
          <div className="row">
            <div className=" col-12">
              <div dangerouslySetInnerHTML={{ __html:terms?.length?terms[0]?.description:""}} className="term">
       
              </div>
            </div>
            <div className="col-12">
              <div className="checkBtn">
                <input onChange={(e)=>setData({...data,terms_and_conditions:e.target.checked})} type="checkbox" id="checkbotton" checked={data?.terms_and_conditions} style={{fontSize: '20px'}} /> 
                <label className='mx-2' htmlFor="checkbotton">I agree to terms &amp; condition</label>
              </div>
              {fieldErrors.terms_and_conditions && <span className="error_text" style={{position:"absolute", marginLeft:"25px"}}>{fieldErrors?.terms_and_conditions}</span>}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="btn_last">
                        <button className="btn   
                       me-3 discard-btn" onClick={()=>handleOpen1()}
                        style={{ boxShadow: '8px 8px 24px 0 rgba(2, 2, 70, 0.15)' ,backgroundColor: '#BAB4B4', borderColor:"transparent", color:"white", padding:"7px 35px"}} >Discard</button>

                      <button onClick={onPrevious} className="btn btn-light   mx-2" style={{ backgroundColor: '#e6e6e6', color: '#494949', boxShadow: '0px 0px 12px 0px rgba(78, 78, 78, 0.42)', border: 'transparent' ,padding:"7px 35px"}}><span><FaArrowLeft className='me-2'/></span>Back </button>


                     


                      <button onClick={onNext} className="btn btn-secondary  
                       ms-2 continue-btn" style={{ backgroundColor: '#494949', boxShadow: '8px 8px 24px 0 rgba(2, 2, 70, 0.15)', color:"white" }}>Continue <span><FaArrowRight className='ms-2' /></span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Discard open1={open1} setOpen1={setOpen1}/>
    </div>
  </div>
  
  )
}

export default Step3