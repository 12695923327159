import React, { useEffect, useState } from "react";
import { AiFillNotification } from "react-icons/ai";
import { FaRegEye } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { IoIosNotifications } from "react-icons/io";

import { useAuth } from "../../Context/ContextProvider";
import NewOrderEdit from "./NewOrderEdit";
import RevisionRequest from "./OrderStatus/RevisionRequest";
import SubmitDataLink from "./OrderStatus/SubmitDataLink";
import bel from "../../asset/icons/bel.png"
import { FaRegBell } from "react-icons/fa6";


function NotificationVendor() {
  const { notificationData, updateNotification, getNotifcation, buttonLoding, setOrderLinkDatas } = useAuth()


  console.log("notificationData", notificationData)
  const [open, setOpen] = React.useState(false);
  const [show, setshow] = useState(false);

  const [page, setPage] = useState({ page: 1, per_page: "10" });
  const [show1, setshow1] = useState({
    await_data: false,
  });
  const [orderIds, setOrderIds] = useState({
    order_id: "",
    order_service_id: "",
    orderStatusName: ''
  })
  useEffect(() => {
    setOrderLinkDatas([])
    getNotifcation(page)
  }, [page])
  // Utility to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(date);
  };

  // Utility to calculate "time ago"
  const timeAgo = (dateString) => {
    const now = new Date();
    const past = new Date(dateString);
    const diffInSeconds = Math.floor((now - past) / 1000);

    const minutes = Math.floor(diffInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 1) return 'just now';
    if (minutes < 60) return `${minutes}m `;
    if (hours < 24) return `${hours}h `;
    return `${days}d `;
  };

  const [data, setData] = useState({})
  const handleScroll = () => {
    if(notificationData?.count>notificationData?.notifications?.length){
        if (
          window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || buttonLoding
        ) return;
        let pages=page?.page
        pages =pages+1
        setPage({ page: pages, per_page: "10" });
        console.log(pages);

    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [buttonLoding]);
  console.log("notificationData",notificationData);
  return (
    <div>
      <div className="new_order_nav">
        <div>
          <h4 className="d-flex align-items-center">
            {" "}
            <IoIosNotifications className="me-2" />
            Notification
          </h4>
        </div>
      </div>
      
   
            <>
        {!show1?.await_data &&
          <div className='table_box' style={{ borderRadius: "0px", padding: "1rem" }}>
            <h3 style={{ padding: "5px 19px " }}>Notification</h3>
 {
      notificationData?.notifications?.length !== 0?
     
            <div className='notication_rowMian ' style={{ padding: "0px 30px " }}>
              {notificationData?.notifications?.map((item) => {
                return (<>

                  {item?.is_read === 0 ?
                    <div style={{ background: "#F4F4F4", borderBottom: "1px solid lightgray", cursor: "pointer" }} className='row py-2'>
                      <div onClick={() => {
                        if (item?.orderStatus?.status_name !== "Completed" && item?.orderStatus?.status_name !== "Awaiting Data" && item?.orderStatus?.status_name !== "Confirmed" && item?.orderStatus?.status_name !== "Pending") {
                          if (item?.orderStatus?.status_name === "Ready For Complete" && item?.service_type === "studio") {
                            updateNotification(item?.notification_id)
                            setOrderIds({
                              order_id: item?.order_id,
                              order_service_id: item?.order_service_id,
                            })
                          }
                          else {
                            updateNotification(item?.notification_id)
                            setOrderIds({
                              order_id: item?.order_id,
                              order_service_id: item?.order_service_id,
                            })
                            setData(item?.order)
                            setshow1({ ...show1, await_data: true })
                          }
                        }
                        else {
                          updateNotification(item?.notification_id)
                          setData(item?.order)
                          setshow(true)
                        }
                      }} className='col-10 d-flex align-items-center'>
                        <div>
                          <GoDotFill style={{ color: "#0C9ED2" }} />
                          <AiFillNotification style={{ fontSize: "35px", color: "#545468" }} />
                        </div>
                        <div className='mx-2'>

                          <p className='m-0' style={{ fontWeight: "300", fontSize: "14px" }}>{item?.orderStatus?.status_name}</p>
                          <p className='m-0' style={{ fontWeight: "600", fontSize: "14px", color: "#545468" }}>Order ID {item?.order_number} is {item?.orderStatus?.status_name} by “{item?.sender_name}” at {item?.created_at?.split("T")[0]}</p>
                        </div>
                      </div>
                      <div className='col-2  d-flex align-items-end flex-column'>
                        <h5 style={{ fontSize: "13px", fontWeight: "600", color: "#545468" }}>{timeAgo(item?.created_at)+" ago"}</h5>
                        <button style={{ borderRadius: "5px", fontSize: "10px", fontWeight: "bold", color: "#7C7C7C", background: "#ECECEC" }} className='view_button' onClick={() => {
                          setData(item?.order)
                          setshow(true)
                          }}>View <FaRegEye style={{ fontSize: "15px" }} /></button>
                      </div>
                    </div>
                    :
                    <div onClick={() => {
                      if (item?.orderStatus?.status_name !== "Completed" && item?.orderStatus?.status_name !== "Awaiting Data" && item?.orderStatus?.status_name !== "Confirmed" && item?.orderStatus?.status_name !== "Pending") {
                        setOrderIds({
                          order_id: item?.order_id,
                          order_service_id: item?.order_service_id,
                        })
                        setData(item?.order)
                        setshow1({ ...show1, await_data: true })
                      }
                      else {
                        setData(item?.order)
                        setshow(true)
                      }
                    }} style={{ background: "white", borderBottom: "1px solid lightgray", cursor: "pointer" }} className='row py-2'>
                      <div className='col-10 d-flex align-items-center'>
                        <div className=''>
                        <GoDotFill style={{ color: "#ada8a8" }} />
                          <AiFillNotification style={{ fontSize: "35px", color: "#545468" }} />
                        </div>
                        <div className='mx-2'>

                          <p className='m-0' style={{ fontWeight: "300", fontSize: "14px" }}>{item?.orderStatus?.status_name}</p>
                          <p className='m-0' style={{ fontWeight: "600", fontSize: "14px", color: "#545468" }}>Order ID {item?.order_number} is {item?.orderStatus?.status_name} by “{item?.sender_name}” at {item?.created_at?.split("T")[0]}</p>
                        </div>
                      </div>
                      <div className='col-2  d-flex align-items-end flex-column'>
                        <h5 style={{ fontSize: "13px", fontWeight: "600", color: "#545468" }}>{timeAgo(item?.created_at)+" ago"+' ago'}</h5>
                        <button style={{ borderRadius: "5px", fontSize: "10px", fontWeight: "bold", color: "#7C7C7C", background: "#ECECEC" }} className='view_button' onClick={() => {
                          setshow(true)
                          setData(item?.order)
                        }}>View <FaRegEye style={{ fontSize: "15px" }} /></button>
                      </div>
                    </div>}</>)
              })}

              {buttonLoding && <div className='d-flex justify-content-center align-items-center my-3'><div className="spinner-border text-dark" role="status">

              </div> <p className='m-2'>Loading...</p></div>}
            </div>
            :
            <div className="col-12 noNoti">
           <FaRegBell />
              <h4>
                No Notification Yet
              </h4>
              <p>
                Stay tuned! Notifications about your activity will <br/> show up here.
              </p>
            </div>
    }
            {/* <OrderDetail show={show} setshow={setshow} /> */}
          </div>}
      </>





      <NewOrderEdit show={show} setshow={setshow} data={data} />
      {show1?.await_data && (
        <SubmitDataLink
          order={data}
          show={show1}
          setShow={setshow1}
          orderServiceId={orderIds?.order_service_id}
        />
      )}
    </div>





  );
}

export default NotificationVendor;
