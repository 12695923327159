import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import NewOrder from "../Pages/NewOrder";
import logo from "../../asset/logo (1).png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { BsFillBoxSeamFill } from "react-icons/bs";

import { IoPricetags, IoSettings} from "react-icons/io5";
import CompleteOrder from "../Pages/CompleteOrder";
import ConfirmOrder from "../Pages/ConfirmOrder";
import RejectOrder from "../Pages/RejectOrder";
import VendorPorfolio from "../Pages/VendorPorfolio";
import ProfileSetting from "../Pages/ProfileSetting";
import Priceing from "../Pages/Priceing";
import ServiceSetting from "../Pages/ServiceSetting";
import Availability from "../Pages/Availability";
import Payment from "../Pages/Payment";
import { MdDashboard, MdKeyboardArrowDown, MdMedicalServices } from "react-icons/md";
import { FaBriefcase } from "react-icons/fa6";

import sersetting from "../../asset/icons/services_setting.png";
import studi from "../../asset/icons/studios.png";
import studiSetting from "../../asset/icons/studio_setting.png";
import studiAdd from "../../asset/icons/add_atudio.png";
import cancelOrder from "../../asset/icons/cancelOrder.png";
import completeOrder from "../../asset/icons/completeOrder.png";
import confirmOrder from "../../asset/icons/confirmOrder.png";
import newOrder from "../../asset/icons/newOrder.png";
import availab from "../../asset/icons/availability.png";
import portfol from "../../asset/icons/portfolio.png";
import payme from "../../asset/icons/payment.png";
import profilesett from "../../asset/icons/profilesett.png";
import dummy from '../../asset/dummy/dummy2.webp'
import accounSett from "../../asset/icons/accounSett.png";
import AccountSetting from "../Pages/AccountSetting";
import AddStudio from "../Pages/AddStudio";
import AddServices from "../Pages/AddServices";
import StudioSetting from "../Pages/StudioSetting";
import DashboardBody from "../Pages/DashboardBody";
import { useAuth } from "../../Context/ContextProvider";
import CustomizedSnackbars from "../../Components/NavBarFooter/CustomizedSnackbars";
import { Badge } from "@mui/material";
import { styled } from '@mui/material/styles';
import { FaRegQuestionCircle } from "react-icons/fa";
import Enquiry from "../Pages/Enquiry";
import Notification from "./Notification";
import NotificationVendor from "../Pages/NotificationVendor";
import { IoIosNotifications } from "react-icons/io";
const drawerWidth = 240;
export default function SideBar() {
  const navigate = useNavigate();
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));
  const {web_url}=useAuth()
  const [permission,setPermission]=React.useState([])
  const vendor_data=JSON.parse(localStorage.getItem('vendor_data'))
  React.useEffect(()=>{
    if(vendor_data){

      if(vendor_data?.data?.service_type==="both" || vendor_data?.data?.role==="vendor-admin"){
        setPermission(["photography","studio"])
      }
      else{
        setPermission([vendor_data?.data?.service_type])
      }
      
    }
    // eslint-disable-next-line
  },[])
  // eslint-disable-next-line
  const [role,setRole]=React.useState(vendor_data?.data?.role)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="vendor_menu">
  <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{
          paddingLeft: "250px",
          paddingRight: "2rem",
          background: "#E4E6E6",
          boxShadow:" 0px 1px 4px 0px #0000001A"

        }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
          disableGutters
        >
          <h4 style={{ fontSize:"24px", fontWeight:"600", lineHeight:"28.8px", color:"#2E2E30", margin:"0", marginLeft:"10px"}}>Welcome Jhon!</h4>
          <Box sx={{ flexGrow: 0 }}>
              <Notification/>
              <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open? 'true' : undefined}
          >
            <Avatar src={vendor_data?.data?.user?.avatar?vendor_data?.data?.user?.avatar:dummy} />

            <MdKeyboardArrowDown className="ms-1"/>
          </IconButton>
        </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <div className='d-flex '>
            <div >
            <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
        style={{width:"42px",height:"42px"}}
      >
        <Avatar style={{width:"105%",height:"105%"}}  src={vendor_data?.data?.user?.avatar?vendor_data?.data?.user?.avatar:dummy} />
      </StyledBadge>
            </div>
            <div className='mx-2'>
              <h6 style={{margin:"0",fontWeight:"600"}}>{vendor_data?.data?.user?.name}</h6>
              <p style={{margin:"0"}}>{vendor_data?.data?.user?.email}</p>
            </div>
          </div>


        </MenuItem>

        <Divider />
        <MenuItem onClick={handleClose}>
      <Link className='comm_a' style={{textDecoration:"none"}} to={`${web_url}${role==="vendor-admin"||role==="vendor"?"vendor":""}/dashboard`}>
         Dashboard
      </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <Link className='comm_a' style={{textDecoration:"none"}} to={`${web_url}${role==="vendor-admin"||role==="vendor"?"vendor":""}/profile-setting`}>
          Profile Settings

        </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <Link className='comm_a' style={{textDecoration:"none"}} to={`${web_url}${role==="vendor-admin"||role==="vendor"?"vendor":""}/password-setting`}>

 
          Change Password
        </Link>
        </MenuItem>
    <hr style={{margin:"0 1rem"}}/>
        <MenuItem onClick={()=>{
            localStorage.removeItem("vendor_data")
            localStorage.removeItem("availbilty")
            navigate(`${web_url}`)
         handleClose() 
        }} className='comm_a logOut_nav' >
 
          Logout
        </MenuItem>
      </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <div
          className="sideBar"
          style={{ background: "#E4E6E6", height: "100%" }}
        >
          <div className=" m-1" style={{padding:".7rem 4rem 0.7rem 18px"}}>
            <Link to={`${web_url}`}>
              <img className="w-100" src={logo} alt="logo" />
            </Link>
          </div>
          {/* <Divider /> */}


   {/* =============================================== Accordian Div =========================        */}
          <div className="accordian_div" style={{padding:" 11px 0 11px 5px", width:"250px" }}>
          <ul className="side_ul mx-2">
          <li>
              <Link to={`${web_url}vendor/dashboard`} className="link_sidebar" >
              <MdDashboard  style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                Dashboard
              </Link>{" "}
            </li>
           
            
          </ul>
       {/* ================================= Fisrt accordaian Order==============================      */}
            <Accordion
              style={{ background: "none", border: "none", boxShadow: "none" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  margin: "0",
                  color: "gray",
                  
                }}
              >
                <div style={{display:"flex", alignItems:'center'}}>
                <BsFillBoxSeamFill style={{color:"#494949",marginRight:"11px", width:"16px", height:"13.95px"}}  />{" "} 
                <span style={{ fontWeight: "500", fontSize: "14px", color:"#494949", lineHeight:"24px" }}>
                  Orders
                </span>
                </div>
                
              </AccordionSummary>
              <AccordionDetails>
                <ul className="side_ul mb-2">
                  <li>
                    <Link to={`${web_url}vendor/new-order`} className="link_sidebar" style={{fontSize:"14px", fontWeight:"400",display:"flex", alignItems:'center'}}>
                    <img src={newOrder} alt="" style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                      New Orders
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to={`${web_url}vendor/confirmed-order`}
                      className="link_sidebar"
                     style={{fontSize:"14px", fontWeight:"400",display:"flex", alignItems:'center'}}>
                      <img src={confirmOrder} alt="" style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                      Comfirmed Orders
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to={`${web_url}vendor/completed-order`}
                      className="link_sidebar"
                     style={{fontSize:"14px", fontWeight:"400",display:"flex", alignItems:'center'}}>
                   <img src={completeOrder} alt="" style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                      Completed Orders
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to={`${web_url}vendor/reject-order`} className="link_sidebar" style={{fontSize:"14px", fontWeight:"400",display:"flex", alignItems:'center'}}>
                    <img src={cancelOrder} alt="" style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                    Cancelled Orders
                    </Link>{" "}
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
      {/* ================================= Second accordaian Services==============================      */}
           {role==="vendor-admin"?<>
            {permission?.includes("photography")&&<Accordion
              style={{ background: "none", border: "none", boxShadow: "none" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  margin: "0",
                  color: "gray",
                }}
              >
                <div style={{display:"flex", alignItems:'center'}}>

               
                <FaBriefcase style={{color:"#494949",marginRight:"11px", width:"16px", height:"13.95px"}} />{" "}
                <span style={{ fontWeight: "500", fontSize: "14px", color:"#494949", lineHeight:"24px" }}>Services
                </span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="side_ul mb-2">
                  <li>
                    <Link  to={`${web_url}vendor/service-setting`} className="link_sidebar" style={{fontSize:"14px", fontWeight:"400",display:"flex", alignItems:'center'}}>
                      <MdMedicalServices  style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                      Add Services
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to={`${web_url}vendor/add-services`}
                      className="link_sidebar"
                      style={{fontSize:"14px", fontWeight:"400",display:"flex", alignItems:'center'}}
                    >
                      <img src={sersetting} alt="" style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                     
                      Service Setting
                    </Link>{" "}
                  </li>
                  
                </ul>
              </AccordionDetails>
            </Accordion>}
           </>:<ul className="side_ul mx-2">
        {permission?.includes("photography")&&   <li>
                    <Link  to={`${web_url}vendor/service-setting`} className="link_sidebar" style={{fontSize:"14px", fontWeight:"400",display:"flex", alignItems:'center'}}>
                      <MdMedicalServices  style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                      Services
                    </Link>{" "}
                  </li>}
            </ul>}
          {/* ================================= third accordaian Studio==============================      */}
          { role==="vendor-admin"?<>
            {permission?.includes("studio")&&<Accordion
              style={{ background: "none", border: "none", boxShadow: "none" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  margin: "0",
                  color: "gray",
                }}
              >
                <div style={{ display:"flex", alignItems:"center"}}>

               
                <img src={studi} alt=""style={{marginRight:"11px", width:"16px", height:"13.95px"}}  />{" "}
                <span style={{ fontWeight: "500", fontSize: "14px", color:"#494949", lineHeight:"24px",  }}>
                Studios
                </span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="side_ul mb-2">
                  <li>
                    <Link to={`${web_url}vendor/add-studio`} className="link_sidebar"style={{fontSize:"14px", fontWeight:"400",display:"flex", alignItems:"center"}}>
                    <img src={studiAdd} alt=""style={{marginRight:"11px", width:"16px", height:"13.95px"}}  />
                      Studios
                    </Link>{" "}
                  </li>
                  <li>
                    <Link
                      to={`${web_url}vendor/studio-setting`}
                      className="link_sidebar"
                    style={{fontSize:"14px", fontWeight:"400",display:"flex", alignItems:"center"}}>
                      <img src={studiSetting} alt=""style={{marginRight:"11px", width:"16px", height:"13.95px"}}  />
                      Studio Setting
                    </Link>{" "}
                  </li>
                
                </ul>
              </AccordionDetails>
            </Accordion>}
          </>:<ul className="side_ul mx-2">
          {permission?.includes("studio")&&<li>
                    <Link to={`${web_url}vendor/add-studio`} className="link_sidebar"style={{fontSize:"14px", fontWeight:"400",display:"flex", alignItems:"center"}}>
                    <img src={studiAdd} alt=""style={{marginRight:"11px", width:"16px", height:"13.95px"}}  />
                      Add Studios
                    </Link>{" "}
                  </li>}
          </ul>}
           
          <ul className="side_ul mx-2">
          <li>
              <Link to={`${web_url}vendor/pricing-setting`} className="link_sidebar" style={{display:"flex", alignItems:"center"}}>
              <IoPricetags style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                Packages
              </Link>{" "}
            </li>
            <li>
              <Link to={`${web_url}vendor/slot-setting`} className="link_sidebar" style={{display:"flex", alignItems:"center"}}>
              <img src={availab} alt="" style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                Availability
              </Link>{" "}
            </li>
       { role==="vendor-admin" &&    <li>
              <Link to={`${web_url}vendor/enquiry`} className="link_sidebar" style={{display:"flex", alignItems:"center"}}>
              <FaRegQuestionCircle style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                Enquiry
              </Link>{" "}
            </li>}
            <li>
              <Link to={`${web_url}vendor/portfolio-setting`} className="link_sidebar" style={{display:"flex", alignItems:"center"}}>
              <img src={portfol} alt="" style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                Portfolio
              </Link>{" "}
            </li>
            
           {role==="vendor"&& <li>
              <Link to={`${web_url}vendor/payments`} className="link_sidebar" style={{display:"flex", alignItems:"center"}}>
              <img src={payme} alt="" style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                Payment
              </Link>{" "}
            </li>}
   
            <li>
              <Link to={`${web_url}vendor/notification`} className="link_sidebar" style={{display:"flex", alignItems:"center"}}>

              <div className="side_noti">

              <StyledBadge
  overlap="circular"
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  variant="dot"
>
<IoIosNotifications />

</StyledBadge>
              </div>
                Notification
              </Link>{" "}
            </li>
   
            
          </ul>
                  
          <Accordion
              style={{ background: "none", border: "none", boxShadow: "none" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  margin: "0",
                  color: "gray",
                }}
              >
                <div style={{display:"flex", alignItems:"center"}}>

                 <IoSettings  style={{marginRight:"11px", width:"16px", height:"13.95px",  color:"#494949" }}/>
               {" "}
                <span style={{ fontWeight: "500", fontSize: "14px", color:"#494949", lineHeight:"24px" }}>
                Setting
                </span>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="side_ul mb-2">
                <li>
              <Link to={`${web_url}vendor/profile-setting`} className="link_sidebar">
              <img src={profilesett} alt="" style={{marginRight:"11px", width:"16px", height:"13.95px"}} />
                Profile Setting
              </Link>{" "}
            </li>
                  <li>
                    <Link
                      to={`${web_url}vendor/password-setting`}
                      className="link_sidebar"
                    >
                      <img src={accounSett} alt=""style={{marginRight:"11px", width:"16px", height:"13.95px"}}  />
                      Account Setting
                    </Link>{" "}
                  </li>
                
                </ul>
              </AccordionDetails>
            </Accordion>
          </div>

        </div>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <div
          
          className="dashbord_main"
        >
          <Routes>
            <Route path="/new-order" element={<NewOrder />} />
            <Route path="/completed-order" element={<CompleteOrder />} />
            <Route path="/confirmed-order" element={<ConfirmOrder />} />
            <Route path="/reject-order" element={<RejectOrder />} />
            <Route path="/portfolio-setting" element={<VendorPorfolio permission={permission} />} />
            <Route path="/profile-setting" element={<ProfileSetting />} />
            <Route path="/pricing-setting" element={<Priceing permission={permission} />} />
            <Route path="/service-setting" element={<ServiceSetting />} />
            <Route path="/slot-setting" element={<Availability  permission={permission}/>} />
            <Route path="/payments" element={<Payment />} />
            <Route path="/password-setting" element={<AccountSetting />} />
            <Route path="/add-studio" element={<AddStudio />} />
            <Route path="/add-services" element={<AddServices />} />
            <Route path="/studio-setting" element={<StudioSetting />} />
            <Route path="/dashboard" element={<DashboardBody />} />
            <Route path="/enquiry" element={<Enquiry />} />
            <Route path="/notification" element={<NotificationVendor />} />
          </Routes>
        </div>
      </Box>
      <CustomizedSnackbars/>
    </Box>
    </div>
  
  );
}
