import React, { useEffect, useState } from 'react'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6'
import { useAuth } from '../../Context/ContextProvider'

function ChangePassword() {
  const {buttonLoding}=useAuth()
    const [show, setshow] = useState(true)
    const [show2, setshow2] = useState(true)
    const [show1, setshow1] = useState(true)
    const [data,setData] =useState({
      current_password:"",
      password:"",
      password_confirmation:""
    })
    const [fieldErrors, setFieldErrors] = useState({})
    const validateStep = () => {
      let errors = {
  
      };
  
      if (!data.current_password) {
        errors.current_password = "Current Password is required.";
      }
      if (!data.password) {
        errors.password = "New Password is required.";
      } else if (data.password.length < 8 || data.password.length > 10) {
        errors.password = "Password must be between 6 and 10 characters long.";
      } else if (!hasSpecialCharacter(data.password)) {
        errors.password = "Password must contain at least one special character.";
      }
      if (!data.password_confirmation) {
        errors.password_confirmation = "Confirm Password is required.";
      } else if (data.password !== data.password_confirmation) {
        errors.password_confirmation = "Passwords do not match.";
      }
      setFieldErrors(errors);
      // Check if there are any errors
      return Object.keys(errors).length === 0;
    };
    const handleSubmit = () => {
      if (validateStep()) {
        changePassword(data)
     
        // Proceed with the submission
      }
    };
    const hasSpecialCharacter = (password) => {
      const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
      return specialCharacterRegex.test(password);
    };
    const {changePassword,setResponsestatus, responseStatus}=useAuth()
    useEffect(()=>{
if(responseStatus){
  setData({current_password:"",
    password:"",
    password_confirmation:""})
    setResponsestatus(false)

}
// eslint-disable-next-line
    },[responseStatus])
  return (
    <div style={{padding:"25px 30px",borderRadius:"0px"}} className='change_password table_box'>
        <h3 style={{fontSize:"24px"}}>Password Change</h3>
        {/* {error?.message && <span className="error_text" style={{fontSize:"13px", position: "absolute" }}>{error?.message}</span>} */}
        <div className='row '>
        <div className='col-lg-4 col-md-6 col-12 my-3'>
          <label htmlFor="" className="new_order_label ">Current Password</label>
            <div className='inp_div'>

                <input value={data?.current_password} type={`${show2 ? "password" : "text"}`} onChange={(e)=>setData({...data,current_password:e.target.value})} placeholder='Current Password' />
                <button onClick={() => setshow2(!show2)}>{show2 ? <FaRegEyeSlash style={{ color: "gray" }} /> : <FaRegEye style={{ color: "gray" }} />}</button>
            </div>
            {fieldErrors?.current_password && <span className="error_text" style={{fontSize:"13px", position: "absolute" }}>{fieldErrors?.current_password}</span>}

              </div>
        <div className='col-lg-4 col-md-6 col-12  my-3'>
          <label htmlFor="" className="new_order_label ">New Password</label>
        <div className='inp_div'>
                <input value={data?.password} onChange={(e)=>setData({...data,password:e.target.value})} type={`${show ? "password" : "text"}`} placeholder='New Password' />
                <button onClick={() => setshow(!show)}>{show ? <FaRegEyeSlash style={{ color: "gray" }} /> : <FaRegEye style={{ color: "gray" }} />}</button>
              </div>
              {fieldErrors?.password && <span className="error_text" style={{fontSize:"13px", position: "absolute" }}>{fieldErrors?.password}</span>}
              </div>
              <div className='col-lg-4 col-md-6 col-12 my-2 my-lg-3 mb-3 mt-0'>
              <label htmlFor="" className="new_order_label ">Confrim Password</label>
              <div className='inp_div '>
                <input value={data?.password_confirmation} onChange={(e)=>setData({...data,password_confirmation:e.target.value})} type={`${show1 ? "password" : "text"}`} placeholder='Confirm Password' />
                <button onClick={() => setshow1(!show1)}>{show1 ? <FaRegEyeSlash style={{ color: "gray" }} /> : <FaRegEye style={{ color: "gray" }} />}</button>
              </div>
              {fieldErrors?.password_confirmation && <span className="error_text" style={{fontSize:"13px", position: "absolute" }}>{fieldErrors?.password_confirmation}</span>}
              </div>
        </div>
              <button onClick={handleSubmit} style={{padding:".3rem 2rem",borderRadius:"5px",border:"none",background:"#414040",color:"white",marginTop:"20px" }}>  {buttonLoding ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            ></div>
                          ) : (
                            "Save"
                          )}</button>
    </div>
  )
}

export default ChangePassword