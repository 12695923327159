import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import logo from "../../asset/logo (1).png";
import { useAuth } from "../../Context/ContextProvider";
import { Link } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

function Signin() {
  const { venderLogin, loginData, resendEmail, resendEmailData, setLogindata } =
    useAuth();
  const [data1, setdata1] = React.useState({
    password: "",
    email: "",
    role: "vendor",
  });
  const [open, setOpen] = React.useState(false);
  const [fieldErrors1, setFieldErrors1] = React.useState({});
  const [show1, setshow1] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setLogindata(null);
  };
  const handleSubmit1 = () => {
    if (validateStep1()) {
      venderLogin(data1);
      // nanvigate('/client/dashbord')

      // Proceed with the submission
    }
  };
  const validateStep1 = () => {
    let errors = {};
    if (!data1.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data1.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!data1.password) {
      errors.password = "Password is required.";
    }
    setFieldErrors1(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <>
      <button
        onClick={handleOpen}
        style={{
          fontSize: "14px",
          fontWeight: "400",
          backgroundColor: "#494949",
          boxShadow: "8px 8px 24px 0 rgba(2, 2, 70, 0.15)",
          color: "white",
          padding: ".3rem 2rem",
          borderRadius: "5px",
        }}
        className="signIn_btn"
      >
        Sign In
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex flex-column align-items-center mb-0 ">
            <img src={logo} className="w-50" alt="..." />

            {resendEmailData ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={resendEmailData}
                style={{ textDecoration: "none", color: "#494949" }}
              >
                <h5 className="my-3">Vendor Login</h5>
              </a>
            ) : (
              <h5 className="my-3">Vendor Login</h5>
            )}
          </div>
          {loginData?.message || loginData?.errors ? (
            <span
              className="error_text"
              style={{ fontSize: "13px", position: "absolute", top: "120px" }}
            >
              {loginData?.message
                ? loginData?.message
                : loginData?.errors?.email[0]}
            </span>
          ) : (
            ""
          )}
          {loginData?.message ===
          "User email is not verified. Please check your mail or click on resend button" ? (
            <Link
              onClick={() => resendEmail(data1.email)}
              style={{ fontSize: "13px" }}
            >
              Resend Email
            </Link>
          ) : (
            ""
          )}
          <Box sx={{ width: "100%" }} className="signIn">
            <div style={{ position: "relative", width: "100%" }}>
              <label htmlFor="" className="new_order_label d-flex">Email</label>
              <div className="upload_title mt-1" style={{ width: "100%" }}>
                
                <input
                  onChange={(e) =>
                    setdata1({ ...data1, email: e.target.value })
                  }
                  placeholder="Email"
                  name="email"
                  type="email"
                  required
                />
              </div>
              {fieldErrors1?.email && (
                <span
                  className="error_text"
                  style={{ position: "absolute", left: "0" }}
                >
                  {fieldErrors1?.email}
                </span>
              )}
            </div>
            <div
              style={{
                position: "relative",
                width: "100%",
                marginTop: ".5rem",
              }}
            >
              <label htmlFor="" className="new_order_label d-flex mt-3">Password</label>
              <div className="upload_title mt-1" style={{ width: "100%" }}>
                <input
                  onChange={(e) =>
                    setdata1({ ...data1, password: e.target.value })
                  }
                  placeholder="Password"
                  name="password"
                  type={`${show1 ? "password" : "text"}`}
                  required
                />
                <button
                  style={{ background: "none", border: "none" }}
                  onClick={() => setshow1(!show1)}
                >
                  {show1 ? (
                    <FaRegEyeSlash style={{ color: "gray" }} />
                  ) : (
                    < FaRegEye style={{ color: "gray" }} />
                  )}
                </button>
              </div>
              {fieldErrors1?.password && (
                <span
                  className="error_text"
                  style={{ position: "absolute", left: "0" }}
                >
                  {fieldErrors1?.password}
                </span>
              )}
            </div>

            <button
              onClick={() => handleSubmit1()}
              style={{
                background: "#494949",
                width: "100%",
                color: "white",
                border: "none",
                padding: ".5rem",
                borderRadius: "5px",
                marginTop: "1.5rem",
              }}
            >
              Sign In
            </button>
            {/* <Grid  className='d-flex justify-content-center' container>

          <Grid item>
            <Link  to={'/join-our-team'} variant="body2"  style={{color:"#414040",fontSize:"13px",textDecoration:"none"}}>
              Don't have an account? <span style={{fontWeight:"600",marginLeft:".3rem"}}> Sign Up</span>
            </Link>
          </Grid>
        </Grid> */}
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default Signin;
