import React, { useEffect, useState } from 'react'
import { FaArrowLeft, FaArrowRight, FaCircleDot } from 'react-icons/fa6'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { useAuth } from '../../Context/ContextProvider'
import CustomMultiSelect from './CustomMultiSelect';
import studioWhite from '../../asset/detail/studio-white.svg'
import studioDark from '../../asset/detail/studio-dark.svg'
import photoWhite from '../../asset/detail/talent-white.svg'
import photoDark from '../../asset/detail/talent-dark.svg'
import Discard from './Discard';
function Step2({ onNext, onPrevious, data, setData, fieldErrors }) {
  const { getSubCategry,
    getCategry,
    subCategory,
    category } = useAuth()
    const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    getCategry()
    // eslint-disable-next-line
  }, []);

  const [type,setType]=useState("photography")
  useEffect(() => {
    if(type==="studio"){
      setData({...data,sub_service_ids:[subCategory[0]?.id],type})
      }
      else{
      setData({...data,type})
    }
    // eslint-disable-next-line
  }, [subCategory]);


  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);

  return (
    <div className="container">
      <div style={{ boxShadow: "none",padding:"1rem 0 0" }} className="row main mt-3">
        <div className="col-lg-3 col-12 ps-1">
        <div className="side_basic">
            <div className="basic_info">
              <div className="row p-0">

                <div style={{position:"relative"}} className='col-2 p-0'>
                <div className="check">
                    <span>
                      <IoIosCheckmarkCircle style={{ fontSize: "25px", color: "#53B73A" }} />
                    </span>
                  </div>
                  <div style={{ position: "absolute", height: "32px", left: "15px", border: "1px solid lightgray", top: "50%" }}></div>
                </div>
                <div className='col-10'>

                  <h5>Basic Information</h5>
                  <p>Get Started Here</p>
      
                </div>
                <div style={{position:"relative"}} className='col-2 p-0'>
                <div className="check">
                    <span>
                    <FaCircleDot  style={{fontSize:"25px",color:"#29B3FF"}} />
                    </span>
                  </div>
                  <div style={{ position: "absolute", height: "32px", left: "15px", border: "1px solid lightgray", top: "50%" }}></div>
                </div>
                <div className='col-10'>

                  <h5>Professional Information</h5>
                  <p>Showcase your skills.</p>
      
                </div>
                <div style={{position:"relative"}} className='col-2 p-0'>
                <div className="check">
                    <span>
                      <div>
                        <div></div>
                      </div>
                    </span>
                  </div>
                  <div style={{ position: "absolute", height: "32px", left: "15px", border: "1px solid lightgray", top: "50%" }}></div>
                </div>
                <div className='col-10'>

                  <h5>Terms & Conditions</h5>
                  <p>Read before joining.</p>
      
                </div>
                <div className='col-2 p-0'>
                <div className="check">
                    <span>
                      <div>
                        <div></div>
                      </div>
                    </span>
                  </div>
                </div>
                <div className='col-10'>
                  <h5>HUrrah ! </h5>
                  <p>Welcome aboard!</p>
                </div>
              </div>
             
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-12 mt-lg-0 mt-5 pe-1">
          <div className="side_basic p-3">
            <div className="heading1">
              <h3 style={{fontSize:"18px",fontWeight:"600"}}>
                Fill in this information
              </h3>
            </div>
            <div className="basic_form1">
              <div className="upload">
                <div className="row">
                <div style={{position:"relative"}} className=" col-12 py-3 d-flex new_buttons_parent ">
                      <div onClick={()=>setData({...data,type:"photography"})} className={`type_profison ms-sm-0  me-3 ${data?.type==="photography"?"profession_active":""}`}> 
                      {data?.type==="photography"?<img className='mx-2 me-3' src={photoWhite} alt='...'/>:
                      <img src={photoDark} className='mx-2 me-3' alt='...'/>}
                      <h5>Talent Service</h5>
                      </div>
                      <div onClick={()=>setData({...data,type:"studio"})} className={`type_profison new_type_profison mx-2 ${data?.type==="studio"?"profession_active":""}`}>
                      {data?.type==="studio"?<img className='mx-2 me-3' src={studioWhite} alt='...'/>:
                      <img className='mx-2 me-3' src={studioDark} alt='...'/>}
                      <h5>Studio Owner</h5>

                      </div>
                    {fieldErrors?.sub_service_ids && <span className="error_text" style={{ position: "absolute",top:"100%" }}>{fieldErrors?.sub_service_ids}</span>}
                  </div>
                  <div className=" col-12 py-3">
                  <label htmlFor="" className="new_order_label ">Choose Profession</label>
                    <div className="upload_title mt-1  ps-0">
                      <select value={data?.service_id} onChange={(e) => {
                        getSubCategry(e.target.value)

                        category?.map((item)=>{
                          
                          if(item?.id===parseInt(e.target.value)){

                              setType(item?.category)
                              
                              setData({ ...data, service_id: e.target.value,sub_service_ids:[]})

                              setSelectedOptions([])
                          }
                          return null
                        })
                      }} aria-label="Default select example" style={{padding:".2rem 0.8rem",    marginTop:"0", fontSize: '17px', color: '#545468', fontWeight: 600}}>
                        <option hidden>Who are you?</option>
                        {category?.map((item) => { 
                          if(item?.category===data?.type){
                            return (
                              <option value={item?.id}>{item?.name}</option>
                            )

                          }
                          return null
                        })}
                      </select>
                    </div>
                    {fieldErrors?.service_id && <span className="error_text" style={{ position: "absolute" }}>{fieldErrors?.service_id}</span>}
                  </div>
         {type!=="studio"&&<div className=" col-12 py-3">
                  <label htmlFor="" className="new_order_label ">Choose Skills</label>
                    <div className="upload_title mt-1" style={{padding:".65rem .8rem",overflow:"visible"}}>
                    <CustomMultiSelect setSelectedOptions={setSelectedOptions} selectedOptions={selectedOptions} options={subCategory} setData={setData} data={data} />
   
                    </div>
                    {fieldErrors?.sub_service_ids && <span className="error_text" style={{ position: "absolute" }}>{fieldErrors?.sub_service_ids}</span>}
                  </div>}
                  <div className=" col-12 py-3">
                  <label htmlFor="" className="new_order_label ">Describe yourself</label>
                    <div className="upload_title mt-1">
                      <textarea name="Text" id="text" onChange={(e) => setData({ ...data, description: e.target.value })} cols={30} rows={6} placeholder="Write here" style={{ backgroundColor: 'transparent', border: 'none',resize:"none", color: '#494949', fontSize: '17px', fontWeight: 600, padding: 0 ,width:"100%",outline:"none"}} value={data?.description} />
                    </div>
                    {fieldErrors?.description && <span className="error_text" style={{ position: "absolute" }}>Bio is required</span>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="btn_last">
                    


                      <button className="btn   
                       me-3 discard-btn" onClick={()=>handleOpen1()}
                        style={{ boxShadow: '8px 8px 24px 0 rgba(2, 2, 70, 0.15)' ,backgroundColor: '#BAB4B4', borderColor:"transparent", color:"white", padding:"7px 35px"}} >Discard</button>

                      <button onClick={onPrevious} className="btn btn-light back-btn   mx-2" style={{ backgroundColor: '#e6e6e6', color: '#494949', boxShadow: '0px 0px 12px 0px rgba(78, 78, 78, 0.42)', border: 'transparent' ,padding:"7px 35px"}}><span><FaArrowLeft className='me-2'/></span>Back </button>


                     


                      <button onClick={onNext} className="btn btn-secondary  
                       ms-2 continue-btn" style={{ backgroundColor: '#494949', boxShadow: '8px 8px 24px 0 rgba(2, 2, 70, 0.15)', color:"white" }}>Continue <span><FaArrowRight className='ms-2' /></span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Discard open1={open1} setOpen1={setOpen1}/>
      </div>
    </div>

  )
}

export default Step2