import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../Context/ContextProvider'
function Banner() {
    const {web_url}=useAuth()
    const { 
        getCategry,
    
        category ,loadingPage} = useAuth()
        const [explorData,setExploreData]=useState([])

      useEffect(() => {
        getCategry()
        // eslint-disable-next-line
      }, []);
      useEffect(() => {
               // eslint-disable-next-line
     let array=category?.filter((item)=>{
            if(item?.category!=="studio" && item?.visibility===1){
                return item
            }
        })
        setExploreData(array)
        // eslint-disable-next-line
      }, [category]);
    return (<>{loadingPage?  <div className='row'>
        <div  className='col-12'>
            <p style={{height:"50vh"}} className="placeholder-glow">
                <span className="placeholder col-12 h-100"></span>
            </p>
        </div>
        <div className='col-6'>
            <p style={{height:"20vh"}} className="placeholder-glow">
                <span className="placeholder col-12 h-100"></span>
            </p>
        </div>
        <div className='col-6'>
            <p style={{height:"20vh"}} className="placeholder-glow">
                <span className="placeholder col-12 h-100"></span>
            </p>
        </div>
        <div className='col-12'>
            <p style={{height:"10vh"}} className="placeholder-glow">
                <span className="placeholder col-12 h-100"></span>
            </p>
        </div>
    </div>: <div className='explore_banner'>
                     <div style={{width:"100%"}} className='row m-0 d-flex justify-content-between p-2 mb-4 '>
      {/* <div className=" col-12 p-0">

           <div style={{position:"relative",height:"100%"}}>
            
     
  
                 <div style={{position:"absolute"}} className={`search_list_tal ${views?"comm_opacity":"comm_opacity_zero"}`}>
              <input  type="text" placeholder='Search'/>
                <PiArrowElbowDownLeftBold />
                <RxCross1  onClick={()=>setViews(false)} className='ms-2' style={{cursor:"pointer"}}/>
                </div>
             <IoSearch onClick={()=>setViews(true)} className={`${views?"comm_opacity_zero":"comm_opacity"}`} style={{cursor:"pointer",position:"absolute", top:"12px"}}/>
             </div>

              
             
            
           
           
      </div> */}
      </div>

            <div className='grid_main '>

            {explorData?.map((item, index) => {
                    if (index % 6 === 0 || index % 5 === 0) { // Large items
                        return (
                            <div className='larg_img'>
                                <Link style={{ color: "black", textDecoration: "none" }} to={item?.category==="studio"?`${web_url}studio-list/${item.category}`:`${web_url}list-talent/${item.slug}`}>

                                        <img src={item?.image_url} alt='...'  />
                               
                                    <h4 >{item.name}</h4>
                                </Link>
                            </div>
                        );
                    } else { // Small items
                        return (

                            <div className='small_img'>
                            <Link style={{ color: "black", textDecoration: "none" }} to={item?.category==="studio"?`${web_url}studio-list/${item.slug}`:`${web_url}list-talent/${item.slug}`}>
                             
                            <img src={item?.image_url} alt='...' />
                               
                               <h4>{item.name}</h4>
                            </Link>
                        </div>

                        );
                    }
                })}

            </div>
            <div className='row'>
       
            </div>

            {/* This code will render every sixth item with a different size compared to the others. It will adjust the column classes accordingly based on whether the item is the first/sixth or not. Adjust the CSS styles accordingly to match your desired layout exactly. */}








        </div>}
       
    </>
    )
}
export default Banner