import React, { useEffect, useState } from 'react'
import { FaRegBell, FaRegEye } from 'react-icons/fa6'
import { AiFillNotification } from 'react-icons/ai'
import { GoDotFill } from 'react-icons/go'
import NewOrderEdit from './NewOrderEdit'
import RevisionRequest from './Order/RevisionRequest'
import { useAuth } from '../../Context/ContextProvider'
import { FaStar } from 'react-icons/fa'
import { CiStar } from 'react-icons/ci'
import { Box, Modal } from '@mui/material'
import { IoClose } from 'react-icons/io5'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
};
function Notification() {
  const [open, setOpen] = React.useState(false);
  const [show, setshow] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [page, setPage] = useState({ page: 1, per_page: "10" });
  const { setOrderLinkDatas, buttonLoding, changeOrderStatus, getNotifcation, notificationData, updateNotification } = useAuth()
  const [show1, setshow1] = useState({
    revision_req: false,
  });
  const [orderIds, setOrderIds] = useState({
    order_id: "",
    order_service_id: ""
  })
  useEffect(() => {
    setOrderLinkDatas([])
    getNotifcation(page)
    // eslint-disable-next-line
  }, [page])
  const [rating, setRating] = useState({
    client_comment: "",
    status: "6D",
    rating: ""
  }); // Initialize rating state
  const handleStarClick = (index) => {
    setRating({ ...rating, rating: index + 1 }); // Update rating state when a star is clicked
  };
  function timeAgo(dateString) {
    const date = new Date(dateString); // Parse the ISO 8601 string
    const now = new Date();
    const diffInMs = now - date; // Difference in milliseconds
    const diffInSec = Math.floor(diffInMs / 1000);
    const diffInMin = Math.floor(diffInSec / 60);
    const diffInHours = Math.floor(diffInMin / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInYears > 0) {
      return diffInYears + ' year' + (diffInYears > 1 ? 's' : '') + ' ago';
    } else if (diffInMonths > 0) {
      return diffInMonths + ' month' + (diffInMonths > 1 ? 's' : '') + ' ago';
    } else if (diffInDays > 0) {
      return diffInDays + ' day' + (diffInDays > 1 ? 's' : '') + ' ago';
    } else if (diffInHours > 0) {
      return diffInHours + ' hour' + (diffInHours > 1 ? 's' : '') + ' ago';
    } else if (diffInMin > 0) {
      return diffInMin + ' minute' + (diffInMin > 1 ? 's' : '') + ' ago';
    } else {
      return 'just now';
    }
  }
  const [data, setData] = useState({})
  const handleScroll = () => {
    if (notificationData?.count > notificationData?.notifications?.length) {
      if (
        window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || buttonLoding
      ) return;
      let pages = page?.page
      pages = pages + 1
      setPage({ page: pages, per_page: "10" });

    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, [buttonLoding]);

  return (
    <>
      {!show1?.revision_req &&
        <div className='table_box' style={{ borderRadius: "0px", padding: "1rem" }}>
          <h3 style={{ padding: "5px 19px " }}>Notification</h3>
          {notificationData?.notifications?.length!==0?

          <div className='notication_rowMian ' style={{ padding: "0px 30px " }}>
            {notificationData?.notifications?.map((item) => {
              return (<>

                {item?.is_read === 0 ?
                  <div style={{ background: "#F4F4F4", borderBottom: "1px solid lightgray", cursor: "pointer" }} className='row py-2'>
                    <div onClick={() => {
                      if (item?.orderStatus?.client_label !== "Completed" && item?.orderStatus?.client_label !== "Awaiting Data" && item?.orderStatus?.client_label !== "Confirmed" && item?.orderStatus?.client_label !== "Pending") {
                        if (item?.orderStatus?.client_label === "Ready For Complete" && item?.service_type === "studio") {
                          handleOpen()
                          updateNotification(item?.notification_id)
                          setOrderIds({
                            order_id: item?.order_id,
                            order_service_id: item?.order_service_id,
                          })
                        }
                        else {
                          updateNotification(item?.notification_id)
                          setOrderIds({
                            order_id: item?.order_id,
                            order_service_id: item?.order_service_id,
                          })
                          setshow1({ ...show1, revision_req: true })
                        }
                      }
                      else {
                        updateNotification(item?.notification_id)
                        setData(item?.order)
                        setshow(true)
                      }
                    }} className='col-10 d-flex align-items-center'>
                      <div>
                        <GoDotFill style={{ color: "#0C9ED2" }} />
                        <AiFillNotification style={{ fontSize: "35px", color: "#545468" }} />
                      </div>
                      <div className='mx-2'>

                        <p className='m-0' style={{ fontWeight: "300", fontSize: "14px" }}>{item?.orderStatus?.client_label}</p>
                        <p className='m-0' style={{ fontWeight: "600", fontSize: "14px", color: "#545468" }}>Order ID {item?.order_number} is {item?.orderStatus?.client_label} by “{item?.sender_name}” at {item?.created_at?.split("T")[0]}</p>
                      </div>
                    </div>
                    <div className='col-2  d-flex align-items-end flex-column'>
                      <h5 style={{ fontSize: "13px", fontWeight: "600", color: "#545468" }}>{timeAgo(item?.created_at)}</h5>
                      <button style={{ borderRadius: "5px", fontSize: "10px", fontWeight: "bold", color: "#7C7C7C", background: "#ECECEC" }} className='view_button' onClick={() => {
                        setData(item?.order)
                        setshow(true)
                        }}>View <FaRegEye style={{ fontSize: "15px" }} /></button>
                    </div>
                  </div>
                  :
                  <div onClick={() => {
                    if (item?.orderStatus?.client_label !== "Completed" && item?.orderStatus?.client_label !== "Awaiting Data" && item?.orderStatus?.client_label !== "Confirmed" && item?.orderStatus?.client_label !== "Pending") {
                      setOrderIds({
                        order_id: item?.order_id,
                        order_service_id: item?.order_service_id,
                      })
                      if (item?.orderStatus?.client_label === "Ready For Complete" && item?.type === "studio") {
                        handleOpen()

                      }
                      else {
                        setshow1({ ...show1, revision_req: true })
                      }
                    }
                    else {
                      setData(item?.order)
                      setshow(true)
                    }
                  }} style={{ background: "white", borderBottom: "1px solid lightgray", cursor: "pointer" }} className='row py-2'>
                    <div className='col-10 d-flex align-items-center'>
                      <div className='ps-3'>
                        <AiFillNotification style={{ fontSize: "35px", color: "#545468" }} />
                      </div>
                      <div className='mx-2'>

                        <p className='m-0' style={{ fontWeight: "300", fontSize: "14px" }}>{item?.orderStatus?.client_label}</p>
                        <p className='m-0' style={{ fontWeight: "600", fontSize: "14px", color: "#545468" }}>Order ID {item?.order_number} is {item?.orderStatus?.client_label} by “{item?.sender_name}” at {item?.created_at?.split("T")[0]}</p>
                      </div>
                    </div>
                    <div className='col-2  d-flex align-items-end flex-column'>
                      <h5 style={{ fontSize: "13px", fontWeight: "600", color: "#545468" }}>{timeAgo(item?.created_at)}</h5>
                      <button style={{ borderRadius: "5px", fontSize: "10px", fontWeight: "bold", color: "#7C7C7C", background: "#ECECEC" }} className='view_button' onClick={() => {
                        setshow(true)
                        setData(item?.order)
                      }}>View <FaRegEye style={{ fontSize: "15px" }} /></button>
                    </div>
                  </div>}</>)
            })}

            {buttonLoding && <div className='d-flex justify-content-center align-items-center my-3'><div className="spinner-border text-dark" role="status">

            </div> <p className='m-2'>Loading...</p></div>}
          </div>
            :
            <div className="col-12 noNoti justify-content-start mt-5" >
            <FaRegBell />
               <h4>
                 No Notification Yet
               </h4>
               <p>
                 Stay tuned! Notifications about your activity will <br/> show up here.
               </p>
             </div>
          }
          {/* <OrderDetail show={show} setshow={setshow} /> */}
        </div>}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="order_popup">
            <div className="row">
              <div className="col p-0">
                <div className="heading1">
                  <h1>Order Complete</h1>
                  <div onClick={handleClose} className="closeIcon">
                    <IoClose />
                  </div>
                </div>
                <hr style={{ margin: 0, borderColor: '#00000078' }} />
                <div className="main_content">
                  <p className="main_heading">
                    Are you sure to<br />
                    complete the order
                  </p>
                  <p className="para">
                    Give the Rating to help us to improve our services*
                  </p>
                  <div className="star">
                    {Array.from({ length: 5 }, (_, index) => (
                      <div key={index} onClick={() => handleStarClick(index)} style={{ cursor: 'pointer' }}>
                        {index < rating?.rating ? (
                          <FaStar style={{ fontSize: '30px' }} /> // Render filled star if rating is greater than index
                        ) : (
                          <CiStar style={{ fontSize: '30px' }} />
                        )}
                      </div>
                    ))}
                    <div>{rating?.rating}/5</div>
                  </div>
                  <div className="text">
                    <label htmlFor="textAra">Comment (Optional)</label>
                    <textarea onChange={(e) => setRating({ ...rating, client_comment: e.target.value })} id="textAra" cols={30} rows={4} placeholder="Type here..." defaultValue="" />
                  </div>
                  <div className="text_para">
                    <p>By Clicking on agree, you are asking us to complete the task of vendor<br />
                      (Vendor Name) ordered from you after we are unable to undo this action.</p>
                    <input type="checkbox" id="check" />
                    <label htmlFor="check">Complete</label>
                  </div>
                  <div className="ft_btn">
                    <button className="btn btn-secondary" onClick={() => {
                      handleClose()
                      changeOrderStatus({ ...rating, id_service: orderIds?.order_service_id, id: orderIds?.order_id }, "confirmed")
                    }} style={{ boxShadow: '8px 8px 24px 0 rgba(2, 2, 70, 0.15)' }}> Done</button>
                    <button className="btn btn-light" style={{ color: '#637381' }}> Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <NewOrderEdit show={show} setshow={setshow} data={data} />
      {show1?.revision_req && <RevisionRequest show={show1} setShow={setshow1} orderServiceId={orderIds?.order_service_id} orderId={orderIds?.order_id} />}
    </>
  )
}

export default Notification