import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

function CustomMultiSelect({ options,setData,data,setSelectedOptions,selectedOptions}) {
  
  const [show,setshow]=useState(false)
  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {

        setshow(false);
       
    }
};
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    // Cleanup the event listener on component unmount
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);
  useEffect(()=>{
    // data?.sub_service_ids?.map((item)=>{
      
    let getData = options?.filter((item) => {
      return data?.sub_service_ids?.includes(item.id);
    });
    setSelectedOptions(getData);
    
       // eslint-disable-next-line
  },[])
  const handleSelectOption = (value) => {
    const selectedId = parseInt(value);
    const selectedOption = options.find(option => option.id === selectedId);

    if (!selectedOptions.some(option => option.id === selectedId)) {
      setSelectedOptions([...selectedOptions, selectedOption]);
      setData({ ...data, sub_service_ids: [...selectedOptions, selectedOption].map(option => option.id) });
    }
  };

  const handleRemoveOption = (optionId) => {
    const updatedOptions = selectedOptions.filter(option => option.id !== optionId);
    setSelectedOptions(updatedOptions);
    setData({ ...data, sub_service_ids:updatedOptions.map(option => option.id)})
  };

  return (
    <div ref={wrapperRef}  onClick={()=>{
      setshow(!show)
    }} style={{width:"100%",position:"relative"}}>
      <div className='d-flex flex-wrap'>
        {selectedOptions?.length===0&&<span>Add your skills</span>}
        {selectedOptions?.map((selectedOption) => (
          <span  key={selectedOption.id} style={{background:"#494949",color:"white",padding:".5rem 1rem",borderRadius:"5px",fontSize:"13px",fontWeight:"500",letterSpacing:"1px",margin:".2rem",whiteSpace:"nowrap"}}>
            {selectedOption.name}
            <button style={{background:"none",border:"none",color:"white",marginLeft:"10px"}} onClick={() => handleRemoveOption(selectedOption.id)}>x</button>
          </span>
        ))}
      </div>
      {/* <div>
     
        <select style={{ backgroundColor: 'transparent', border: 'none', outline: 'none', fontSize: '17px', color: '#545468', fontWeight: 600, padding: 0 }} onChange={handleSelectOption}>
          <option hidden>Select an option</option>
          {options.map(option => (
            <option key={option.id} value={option.id}>{option.name}</option>
          ))}
        </select>
       
      </div> */}
      {show&&<ul  style={{position:"absolute",marginTop:"12px",width:"100%",zIndex:"99"}} className='custom_ul step_2_ul'>
          {options.map(option => (
            <li key={option.id} onClick={()=>{
              
              handleSelectOption(option.id)
              setshow(true)
            }}>{option.name}</li>
          ))}
        </ul>}
        <IoIosArrowDown style={{position:"absolute",right:"0%",top:"5px",fontSize:"14px"}}  />
    </div>
  );
}

export default CustomMultiSelect;
