import React from "react";
import img1 from "../../asset/Rectangle 1829.png";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../Context/ContextProvider";
function ServicesCard({data}) {
  const {web_url}=useAuth()
  const modifyText = (text) => {
    const wordToRemove = "photography";
    const lowerCaseText = text?.toLowerCase();
    const lowerCaseWordToRemove = wordToRemove?.toLowerCase();
  
    if (lowerCaseText?.includes(lowerCaseWordToRemove)) {
      const index = lowerCaseText?.indexOf(lowerCaseWordToRemove);
      const beforeWord = text?.slice(0, index);
      const afterWord = text?.slice(index + lowerCaseWordToRemove?.length);
      return beforeWord?.concat(afterWord)?.trim();
    }
    return text;
  };
  const parms=useParams()
  return (
    <div className="row comm_services">
       <div className="Studio_detail_page  col-12 d-flex justify-content-center" >
          <h4 className="studio_head mb-2" style={{marginTop:'2rem'}}>Our other services</h4>
        </div>
      
      <div className="col-12 text-center">
        <p className="our_other_p pb-1">
          Make The World A Better Place With Creation
        </p>
      </div>
      {data?.map((item, i) => {
         return item?.service?.sub_services?.map((items,i)=>{
          if(items?.slug!==parms?.id){
            return (
              <div className="col-lg-3 col-md-6 col-12 my-2 pb-1" style={{padding : '0px 10px'}}>
                <div className="ser_card_main">
                  <h2>
                  {modifyText(items?.name)}
                  
                  </h2>
                  <img style={{height:"200px",objectFit:"cover"}} src={items.image?items.image:img1} alt="" />
                  
                  <Link to={`${web_url}service-detail/${items?.slug}`}>
                  <button>FIND MORE</button>
                  </Link>
                </div>
              </div>
            );
          }
          return null
      })
      })}
      <div className="col-12 mt-3 mb-4 pb-4 text-center">
        <Link to={`${web_url}our-service`}>
        <button className="view_all_btn">
        view all services
        </button>
        </Link>
       
      </div>
    </div>
  );
}

export default ServicesCard;
