import React, { useEffect, useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useAuth } from "../../Context/ContextProvider";
import accounSett from "../../asset/icons/accounSett.png";
import OnboardingWrapper from "./OnboardingWrapper";

function AccountSetting() {
  const {buttonLoding}=useAuth()
  const [show, setshow] = useState(true)
  const [show1, setshow1] = useState(true)
  const [show2, setshow2] = useState(true)
  const [view, setview] = useState(false)
  const vendorData=JSON.parse(localStorage.getItem("vendor_data"))
  const [data, setData] = useState({
    current_password: "",
    password: "",
    password_confirmation: ""
  })
  const [fieldErrors, setFieldErrors] = useState({})
  const validateStep = () => {
    let errors = {

    };
    if (!data.current_password) {
      errors.current_password = "Current Password is required.";
    }
    
    if (!data.password) {
      errors.password = "New Password is required.";
    } else if (data.password.length < 8 || data.password.length > 10) {
      errors.password = "Password must be between 6 and 10 characters long.";
    } else if (!hasSpecialCharacter(data.password)) {
      errors.password = "Password must contain at least one special character.";
    }
    if (!data.password_confirmation) {
      errors.password_confirmation = "Confirm Password is required.";
    } else if (data.password !== data.password_confirmation) {
      errors.password_confirmation = "Passwords do not match.";
    }
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = () => {
    if (validateStep()) {
      changePassword(data)
      // Proceed with the submission
    }
  };
  const hasSpecialCharacter = (password) => {
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    return specialCharacterRegex.test(password);
  };
  const { changePassword, setResponsestatus, responseStatus } = useAuth()
  useEffect(() => {
    if (responseStatus) {
      setData({
        current_password: "",
        password: "",
        password_confirmation: ""
      })
      setResponsestatus(false)
    }
    // eslint-disable-next-line
  }, [responseStatus])
  return (
    <>
      <div className="new_order_nav">
        <div>
          <h4 className="d-flex align-items-center"> <img src={accounSett} alt="" style={{ marginRight: "11px", width: "22px", height: "19px" }} /> Account setting</h4>
        </div>
      </div>
      <div style={{ height: "80vh", background: "white", boxShadow: "0px 0px 4px 0 rgba(0, 0, 0, 0.25)", marginRight: "12px" }}>
        {/* {error?.message && <span className="error_text" style={{fontSize:"13px", position: "absolute" }}>{error?.message}</span>} */}
        <div className="row " style={{ margin: "20px" }}>
          <div className="col-12 my-2 mt-4">
            {vendorData?.data?.role==="vendor"&&<div className="acc_button">
              <button onClick={() => setview(false)} className={`${view ? "common_light" : "common_dark"}`}>
                Change Passward
              </button>
              <button onClick={() => setview(true)} className={`ms-4    ${view ? "common_dark" : "common_light"}`}>
                Bank Account Setting
              </button>
            </div>}
          </div>
          {
            view ?
              <div className="conent_accout_main">
               <OnboardingWrapper/>
              </div>
              :
              <>
                <div className="col-lg-4 col-md-6 col-12 my-1">
                  <label className="new_order_label" htmlFor="">Current Password</label>
                  <div className="passWards">
                    <input value={data?.current_password} onChange={(e) => setData({ ...data, current_password: e.target.value })} type={`${show2 ? "password" : "text"}`} placeholder="Current Password" />
                    <span><button style={{ background: "none", border: "none" }} onClick={() => setshow2(!show2)}>{show2 ? <FaRegEyeSlash  style={{ color: "gray" }} /> : <FaRegEye style={{ color: "gray" }} />}</button></span>
                  </div>
                  {fieldErrors?.current_password && <span className="error_text" style={{ fontSize: "13px", position: "absolute" }}>{fieldErrors?.current_password}</span>}
                </div>
                <div className="col-lg-4 col-md-6 col-12 my-1">
                  <label className="new_order_label" htmlFor="">New Password</label>
                  <div className="passWards" >

                    <input value={data?.password} onChange={(e) => setData({ ...data, password: e.target.value })} type={`${show ? "password" : "text"}`} placeholder="New Password" /><span><button style={{ background: "none", border: "none" }} onClick={() => setshow(!show)}>{show ? <FaRegEyeSlash  style={{ color: "gray" }} /> : <FaRegEye style={{ color: "gray" }} />}</button></span>
                  </div>
                  {fieldErrors?.password && <span className="error_text" style={{ fontSize: "13px", position: "absolute" }}>{fieldErrors?.password}</span>}
                </div>
                <div className="col-lg-4 col-md-6 col-12 my-1">
                  <label className="new_order_label" htmlFor="">Confirm Password</label>
                  <div className="passWards">
                    <input value={data?.password_confirmation} onChange={(e) => setData({ ...data, password_confirmation: e.target.value })} type={`${show1 ? "password" : "text"}`} placeholder="Confirm Password" />

                    <span><button style={{ background: "none", border: "none" }} onClick={() => setshow1(!show1)}>{show1 ? <FaRegEyeSlash  style={{ color: "gray" }} /> : <FaRegEye style={{ color: "gray" }} />}</button></span>
                  </div>
                  {fieldErrors?.password_confirmation && <span className="error_text" style={{ fontSize: "13px", position: "absolute" }}>{fieldErrors?.password_confirmation}</span>}
                </div>
                <div className="col-12">
                  <button onClick={handleSubmit} className="acc_btn mt-3">
                    
                  {buttonLoding ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            ></div>
                          ) : (
                            "Save"
                          )}
                    
                    
                    </button>
                </div>
              </>
          }
        </div>
      </div>
    </>
  );
}


export default AccountSetting;
