import { Modal } from "@mui/material";
import { Box } from "@mui/system";

import React, { useEffect, useState } from "react";
import { FaArrowRight, FaMinus, FaPlus } from "react-icons/fa6";
import { GrAttachment } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { useAuth } from "../../Context/ContextProvider";
import { useNavigate } from "react-router-dom";

function Banner() {
  const { globalFAQs, FAQs, web_url } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    FAQs("photography");
    // eslint-disable-next-line
  }, []);

  const [plus, setPlus] = useState([]);
  function toggleClick(idx) {
    setPlus((items) => {
      const is_isavailable = items.includes(idx);
      if (is_isavailable) {
        return items.filter((i) => i !== idx);
      } else {
        return [...items, idx];
      }
    });
  }
  const [ques, setQues] = useState(0);
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const [fileName, setFileName] = useState("Attach file");

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFileName(event.target.files[0].name);
    } else {
      setFileName("Attach file");
    }
  };
  return (
    <>
      <div className="term_btn text-center mt-4 pt-2 ">
        <button className="faq_btn">frequently asked questions</button>
      </div>
      <div className="text-center mb-3 mt-5">
        <button
          onClick={() => {
            setQues(0)
            FAQs("photography");
          }}

          className={`PhoStMar ${ques === 0 ? "PhoStMar_dark" : "PhoStMar_light"
            }`}
        >
          Photography
        </button>
        <button
          onClick={() => {
            setQues(1)
            FAQs("studio");
          }}

          className={`PhoStMar ${ques === 1 ? "PhoStMar_dark" : "PhoStMar_light"
            } ms-4`}
        >
          studio
        </button>
        <button
          onClick={() => {
            setQues(2)
            FAQs("marketplace");
          }}
          className={`PhoStMar ${ques === 2 ? "PhoStMar_dark" : "PhoStMar_light"
            } ms-4`}
        >
          market Place
        </button>
      </div>
      {
        ques === 0 && (
          <div className="term_content">
            {
              globalFAQs && globalFAQs?.length > 0 ?
                <>
                  {globalFAQs?.map((item, i) => {
                    return (
                      <div className="main_faq">
                        <div onClick={() => toggleClick(i)} className="faq_head">
                          <p className="inner_heading_term">{item?.question}</p>
                          <span>
                            {plus.includes(i) ? (
                              <FaMinus />
                            ) : (
                              <FaPlus />
                            )}
                          </span>
                        </div>
                        {plus.includes(i) ? (
                          <>
                            <div className="term_content_para">{item?.answer}</div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </>
                :
                <h5 className="d-flex justify-content-center align-items-center">


                  No FAQs available!
                </h5>
            }

          </div>
        )}
      {ques === 1 && (
        <div className="term_content">
          {
            globalFAQs && globalFAQs?.length > 0 ?
              <>
                {globalFAQs?.map((item, i) => {
                  return (
                    <div className="main_faq">
                      <div onClick={() => toggleClick(i)} className="faq_head">
                        <p className="inner_heading_term">{item?.question}</p>
                        <span>
                          {plus.includes(i) ? (
                            <FaMinus />
                          ) : (
                            <FaPlus />
                          )}
                        </span>
                      </div>
                      {plus.includes(i) ? (
                        <>
                          <div className="term_content_para">{item?.answer}</div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </>
              :
              <h5 className="d-flex justify-content-center align-items-center">


                No FAQs available!
              </h5>
          }
        </div>
      )}
      {ques === 2 && (
        <div className="term_content">
          {
            globalFAQs && globalFAQs?.length > 0 ?
              <>
                {globalFAQs?.map((item, i) => {
                  return (
                    <div className="main_faq">
                      <div onClick={() => toggleClick(i)} className="faq_head">
                        <p className="inner_heading_term">{item?.question}</p>
                        <span>
                          {plus.includes(i) ? (
                            <FaMinus />
                          ) : (
                            <FaPlus />
                          )}
                        </span>
                      </div>
                      {plus.includes(i) ? (
                        <>
                          <div className="term_content_para">{item?.answer}</div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </>
              :
              <h5 className="d-flex justify-content-center align-items-center">


                No FAQs available!
              </h5>
          }
        </div>
      )}

      {/* ======================== can you find the answer============== */}
      <div className="text-center my-3">
        <p className="can_find">Can't find an answer to your question?</p>
        <button onClick={() => navigate(`${web_url}contact`)} className="submit_faqs">
          Submit your question <FaArrowRight className="ms-2" />
        </button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="order_popup p-2">
            <div className="row m-0 question_popup">
              <div className="col-12 p-0">
                <div className="heading1" style={{ padding: "10px" }}>
                  <h1 className="faqRequest">Submit Request</h1>
                  <div
                    onClick={() => handleClose()}
                    className="closeIcon"
                    style={{ width: "24px", height: "24px", cursor: "pointer" }}
                  >
                    <IoClose />
                  </div>
                </div>
                <hr style={{ margin: 0, borderColor: "#00000078" }} />
              </div>
              <div className="col-12">
                <div className="upload">
                  <label htmlFor="" className="new_order_label mt-3">
                    Reason
                  </label>
                  <div className="upload_title mt-1">
                    <input type="text" name="" id="" placeholder="Reason" />
                  </div>
                  <label htmlFor="" className="new_order_label mt-3">
                    Email
                  </label>
                  <div className="upload_title mt-1">
                    <input
                      type="email"
                      name=""
                      id=""
                      placeholder="Example@mail.com"
                    />
                  </div>

                  <label className="new_order_label mt-3" htmlFor="">
                    Describe your Question
                  </label>
                  <div className="upload_title mt-1">
                    <textarea
                      name=""
                      id=""
                      placeholder="Write here"
                      rows={4}
                      style={{
                        border: "none",
                        resize: "none",
                        outline: "none",
                        width: "100%",
                      }}
                    ></textarea>
                  </div>

                  <label className="new_order_label mt-3" htmlFor="">
                    Attach File
                  </label>
                  <div
                    className="file_attached"
                    style={{ padding: "7px 10px" }}
                  >
                    <div>
                      <GrAttachment className="me-2" />
                      {fileName}
                    </div>
                    <div>
                      <input
                        type="file"
                        name=""
                        id="upload_file"
                        onChange={handleFileChange}
                      />
                    </div>
                    <label
                      htmlFor="upload_file"
                      style={{
                        fontSize: "13px",
                        fontWeight: "400",
                        padding: "5px 15px",
                        borderRadius: "3px",
                      }}
                    >
                      Upload File
                    </label>
                  </div>


                </div>
              </div>
            </div>
            <div className="faq_footer_btn">
              <button >Submit Question</button>
              <button style={{ color: "#637381", border: "1px solid #BCBCBC ", background: "transparent" }}>Cancel</button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Banner;
