import React from "react";

import { GoArrowRight } from "react-icons/go";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/ContextProvider";
import imgs from "../../asset/studio/Rectangle 1587.png";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos";
function AboutTalent() {
    const {web_url}=useAuth()
    AOS.init({
        duration: 800, // Animation duration
        easing: "ease-in-out", // Easing option
        once: false, // Whether animation should only happen once
      });
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="about_grow">
            <p>Let’s grow work with us</p>
          </div>
          <div className="AboutTalent">
            <img src={imgs} alt="" />
            <div className="AboutTalentBlank">

            </div>

          <div className="findTalent" data-aos="fade-down">
            <h4>
              Find talent
              <br />
              your way
            </h4>
            <p className="sub_heading">Pave The Way For Your Photography Needs</p>
            <div className="row">
                <div className="col-lg-4 col-12 my-2">
                    <div className="exploreHere" >
                        <h5>
                        I am Looking for <br />Talent  
                        </h5>
                        <Link  to={`${web_url}explor-telent`}>
                        <p>
                        Explore Here <GoArrowRight className="ms-2" />
                        </p>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-4 col-12 my-2"><div className="exploreHere">
                        <h5>
                        I am Looking for <br />Studio 
                        </h5>
                        <Link  to={`${web_url}our-studio`}>
                        <p>
                        Explore Here <GoArrowRight className="ms-2" />
                        </p>
                        </Link>
                    </div></div>
                <div className="col-lg-4 col-12 my-2"><div className="exploreHere">
                        <h5>
                        I am Looking for <br />Work  
                        </h5>
                        <Link  to={`${web_url}join-our-team`}>
                        <p>
                        Explore Here <GoArrowRight className="ms-2" />
                        </p>
                        </Link>
                    </div></div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutTalent;
