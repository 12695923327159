import React, { useEffect, useState } from "react";
import ServicessettEditor from "./ServicessettEditor";
import Services_sett_Quote from "./Services_sett_Quote";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoEyeOutline, IoSaveOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa";
import { useAuth } from "../../Context/ContextProvider";
import { GoUpload } from "react-icons/go";
import sersetting from "../../asset/icons/services_setting.png";
import { IoMdStar, IoMdStarOutline } from "react-icons/io";
import { FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

function AddServices() {
  const {
    vendorServices,
    adminserviceGelleryDelete,
    handleClick,
    setError,
    adminserviceGellery,
    adminGellery,
    base_url,
    token,
    vedorServiceData,
    adminserviceSetting,
    serviceSetingData,
    serviceSettingAdmin,
    setButtonLoding,
    buttonLoding,
    serviceTogaleStatus,
    app_key,
    serviceLoding,
    setSeriveLoding,
  } = useAuth();
  const [gallery, setGallery] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);

  const [imageError, setImageError] = useState();
  const [data, setData] = useState({
    description: "",
    heading: "",
    sub_service_id: "",
    service_id: "",
    quote: "",
    only_inquiry:false,
  });
  const [fieldErrors, setFieldErrors] = React.useState({});
  const validateStep = () => {
    let errors = {};

    // Check if the heading is provided
    if (!data.heading) {
      errors.title = "Title is required.";
    }

    setFieldErrors(errors);

    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };

  const handleImageChange = (event) => {
    setImageError("");
    const files = event.target.files;
    const selected = [];

    const remainingSlots =
      previewImages.length === 0 ? 6 : 6 - previewImages.length;

    // Check if the number of selected files exceeds the remaining slots
    if (files.length > remainingSlots) {
      setImageError(
        `You can upload only ${remainingSlots} more ${
          remainingSlots === 1 ? "image" : "images"
        }.`
      );

      event.target.value = ""; // Reset file input
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      selected.push(file);
    }
    handleSubmit(selected);
  };

  const handleSubmit = async (selected) => {
    setButtonLoding(true);
    const formData = new FormData();
    formData.append("vendor_sub_service_id", serviceId);
    formData.append("vendor_service_id", mianserviceId);
    selected.forEach((image) => {
      formData.append("media[]", image);
    });
    try {
      const response = await fetch(`${base_url}service-gallery/upload`, {
        method: "POST",
        headers: {
          Authorization: token,
          "app-key": app_key,
        },
        body: formData,
      });
      const jsonData = await response.json();
      setButtonLoding(false);
      if (response.status === 200 || response.status === 201) {
        setPreviewImages(jsonData?.data);

        handleClick();
        setError({ message: "Images Uploaded", color: "success" });
      } else {
        handleClick();
        setError({ message: "Some thing Went Wrong", color: "error" });
      }
    } catch (error) {
      console.log(error);
      setButtonLoding(false);
    }
  };

  const [serviceId, setserviceId] = useState();
  const [mianserviceId, setmianserviceId] = useState();
  const [result, setResult] = useState([]);
  useEffect(() => {
    vendorServices("photography", 0);
  }, []);

  useEffect(() => {
    setPreviewImages(adminGellery);
  }, [adminGellery]);

  useEffect(() => {
    if (vedorServiceData?.vendor_services?.length) {
      setResult(vedorServiceData?.vendor_services[0]?.service?.sub_services);
      const initialServiceId =
        vedorServiceData?.vendor_services[0]?.service?.sub_services[0]
          ?.vendor_sub_service_id;
      const initialServiceId1 =
        vedorServiceData?.vendor_services[0]?.vendor_service_id;
      setserviceId(initialServiceId);
      setmianserviceId(initialServiceId1);
      adminserviceSetting(initialServiceId, initialServiceId1);
      adminserviceGellery(initialServiceId, initialServiceId1);
      setSeriveLoding(true);
      setData((prevData) => ({
        ...prevData,
        vendor_sub_service_id: initialServiceId,
        vendor_service_id: initialServiceId1,
      }));
    }
  }, [vedorServiceData]);
  console.log(data);
  useEffect(() => {
    setData({
      description: serviceSetingData?.description || "",
      heading: serviceSetingData?.heading || "",
      vendor_sub_service_id: serviceId,
      vendor_service_id: mianserviceId,
      quote: serviceSetingData?.quote || "",
      only_inquiry:serviceSetingData?.only_inquiry
    });
  }, [serviceSetingData, serviceId, mianserviceId]);

  function searchBySubServiceName(searchTerm) {
    let results;
    vedorServiceData?.vendor_services?.map((item) => {
      console.log(serviceId);
      if (item?.vendor_service_id === mianserviceId) {
        results = item?.service?.sub_services?.filter(
          (items) =>
            items && items.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setResult(results);
      }
    });
  }
  console.log(serviceId);
  return (
    <>
      <div className="new_order_nav ">
        <div>
          <h4 className="d-flex align-items-center">
            <img
              src={sersetting}
              alt=""
              style={{ marginRight: "11px", width: "22px", height: "19px" }}
            />
            Service Setting{" "}
          </h4>
        </div>
      </div>
      <div className="main_services_sett">
        <div className="row">
          <div className="col-12">
            <p className="comm_para">
              Carefully add an impressive description of the selected services.
              This will be shown on the service detail page on the client side
              and can impact yourorders.
            </p>
            <hr className="mt-2 mb-3" />
          </div>
          <div className="col-lg-3 col-12">
            <h3
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "28px",
                margin: "0",
                color: "#494949",
              }}
            >
              Available Service(s)
            </h3>
          </div>
          <div className="col-lg-9 col-12 d-flex justify-content-between">
            <ul className="Info_gallery">
              <li
                onClick={() => setGallery(false)}
                className={`${gallery ? "Services_light" : "Services_dark"}`}
              >
                INFORMATION
              </li>
              <li
                onClick={() => setGallery(true)}
                className={`${gallery ? "Services_dark" : "Services_light"}`}
              >
                GALLERY
              </li>
            </ul>
            {gallery ? (
              <>
                {previewImages?.length < 6 && (
                  <label
                    htmlFor="gellery_img"
                    className="studio_sett_btn_upl d-flex justify-content-center align-items-center"
                  >
                    {buttonLoding ? (
                      <div
                        className="spinner-border text-light"
                        role="status"
                      ></div>
                    ) : (
                      <>
                        <GoUpload className="mx-1" />
                        Upload
                      </>
                    )}{" "}
                  </label>
                )}
              </>
            ) : (
              <button
                style={{ marginTop: "0px" }}
                onClick={() => {
                  if (validateStep()) {
                    serviceSettingAdmin(data);
                  }
                }}
                className="services_Btn_save"
              >
                {buttonLoding ? (
                  <div
                    className="spinner-border text-light"
                    role="status"
                  ></div>
                ) : (
                  <>
                    <IoSaveOutline className="mx-1" />
                    Save
                  </>
                )}
              </button>
            )}
          </div>
          <div className="col-lg-3 col-12">
            <div className="avail_main" style={{ maxHeight: "67vh" }}>
              <div className="avail_service avail_sel p-0">
                <select
                  onChange={(e) => {
                    let array = [];
                    vedorServiceData?.vendor_services?.filter((item) => {
                      if (item?.service?.id == e.target.value) {
                        array = item?.service?.sub_services;
                        const initialServiceId =
                          item?.service?.sub_services[0].vendor_sub_service_id;
                        const initialServiceId1 = item?.vendor_service_id;
                        setserviceId(initialServiceId);
                        setmianserviceId(initialServiceId1);
                        setSeriveLoding(true);
                        adminserviceSetting(
                          initialServiceId,
                          initialServiceId1
                        );
                        adminserviceGellery(
                          initialServiceId,
                          initialServiceId1
                        );
                      }
                    });
                    setResult(array);
                  }}
                  name=""
                  id=""
                >
                  <option value="Select Services" hidden>
                    Select Services
                  </option>
                  {vedorServiceData?.vendor_services?.map((item) => {
                    return (
                      <option value={item?.service?.id}>
                        {item?.service?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="avail_service pt-0">
                <input
                  type="search"
                  onChange={(e) => {
                    searchBySubServiceName(e.target.value);
                  }}
                  placeholder="Search Service"
                />
                <ul className="m-0 p-0">
                  {result?.map((item) => (
                    <React.Fragment key={item.id}>
                      {item?.vendor_sub_service_id === serviceId ? (
                        <li
                          onClick={() => {
                            setserviceId(item?.vendor_sub_service_id);
                            setSeriveLoding(true);
                            adminserviceSetting(
                              item?.vendor_sub_service_id,
                              mianserviceId
                            );
                            adminserviceGellery(
                              item?.vendor_sub_service_id,
                              mianserviceId
                            );
                          }}
                          className="avail_active"
                        >
                          {item?.name}
                        </li>
                      ) : (
                        <li
                          onClick={() => {
                            setserviceId(item?.vendor_sub_service_id);
                            setSeriveLoding(true);
                            adminserviceSetting(
                              item?.vendor_sub_service_id,
                              mianserviceId
                            );
                            adminserviceGellery(
                              item?.vendor_sub_service_id,
                              mianserviceId
                            );
                          }}
                        >
                          {item?.name}
                        </li>
                      )}
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-12" style={{ position: "relative" }}>
            <p
              className="error_text"
              style={{ position: "absolute", top: "-10px" }}
            >
              {imageError}
            </p>
            <div className="service_page_handling">
              {/* ===============INFORMATION section================ */}
              {gallery ? (
                <>
                  {serviceLoding ? (
                    <div className="row">
                      <div className="col-lg-4 col-12 my-2 placeholder-glow ">
                        <div className="placeholder loader rounded "></div>
                      </div>
                      <div className="col-lg-4 col-12 my-2 placeholder-glow ">
                        <div className="placeholder loader rounded "></div>
                      </div>
                      <div className="col-lg-4 col-12 my-2 placeholder-glow ">
                        <div className="placeholder loader rounded "></div>
                      </div>
                      <div className="col-lg-4 col-12 my-2 placeholder-glow ">
                        <div className="placeholder loader rounded "></div>
                      </div>
                      <div className="col-lg-4 col-12 my-2 placeholder-glow ">
                        <div className="placeholder loader rounded "></div>
                      </div>
                      <div className="col-lg-4 col-12 my-2 placeholder-glow ">
                        <div className="placeholder loader rounded "></div>
                      </div>
                    </div>
                  ) : (
                    <div className="services_gall">
                      <div className="row">
                        <div className="col-12 my-3 d-flex align-items-center">
                          {previewImages.length < 6 && (
                            <>
                              <input
                                disabled={buttonLoding}
                                id="gellery_img"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                multiple
                                onChange={handleImageChange}
                              />
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "400",
                                  color: "#494949",
                                  marginLeft: "20px",
                                }}
                              >
                                {previewImages.length}/6
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        {previewImages.map((item, i) => {
                          return (
                            <div className="col-lg-4 col-md-6 col-12 my-2">
                              <div className="ser_set_card">
                                <img src={item.url} alt="" />
                                <div className="ser_set_content">
                                  <div
                                    onClick={() => {
                                      if (item?.is_featured) {
                                        // eslint-disable-next-line
                                        serviceTogaleStatus(
                                          {
                                            type: "is_featured",
                                            media: item?.id,
                                            status: 0,
                                          },
                                          {
                                            vendor_sub_service_id: serviceId,
                                            vendor_service_id: mianserviceId,
                                          }
                                        );
                                      } else {
                                        serviceTogaleStatus(
                                          {
                                            type: "is_featured",
                                            media: item?.id,
                                            status: 1,
                                          },
                                          {
                                            vendor_sub_service_id: serviceId,
                                            vendor_service_id: mianserviceId,
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    {item?.is_featured ? (
                                      <IoMdStar />
                                    ) : (
                                      <IoMdStarOutline />
                                    )}
                                  </div>
                                  <div>
                                    <RiDeleteBinLine
                                      onClick={() =>
                                        adminserviceGelleryDelete(
                                          item?.id,
                                          serviceId,
                                          mianserviceId
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    onClick={() => {
                                      if (item?.is_active) {
                                        // eslint-disable-next-line
                                        serviceTogaleStatus(
                                          {
                                            type: "is_active",
                                            media: item?.id,
                                            status: 0,
                                          },
                                          {
                                            vendor_sub_service_id: serviceId,
                                            vendor_service_id: mianserviceId,
                                          }
                                        );
                                      } else {
                                        serviceTogaleStatus(
                                          {
                                            type: "is_active",
                                            media: item?.id,
                                            status: 1,
                                          },
                                          {
                                            vendor_sub_service_id: serviceId,
                                            vendor_service_id: mianserviceId,
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    {!item?.is_active ? (
                                      <FaRegEyeSlash />
                                    ) : (
                                      <IoEyeOutline />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {serviceLoding ? (
                    <div className="row">
                      <div className="col-12 my-2 placeholder-glow ">
                        <div
                          style={{ height: "50px" }}
                          className="placeholder loader rounded "
                        ></div>
                      </div>
                      <div className="col-12 my-2 placeholder-glow ">
                        <div className="placeholder loader rounded "></div>
                      </div>
                      <div className="col-12 my-2 placeholder-glow ">
                        <div className="placeholder loader rounded "></div>
                      </div>
                    </div>
                  ) : (
                    <div className="services_infor">
                      <div className="row">
                        <div className="  col-12 col-lg-7 mb-3">
                          <label className="new_order_label" htmlFor="">
                            Service Page Heading
                          </label>
                          <div
                            style={{ position: "relative" }}
                            className="services_sett_info d-flex align-item-center px-3"
                          >
                            <input
                              value={
                                data?.heading === null ? "" : data?.heading
                              }
                              onChange={(e) =>
                                setData({ ...data, heading: e.target.value })
                              }
                              style={{
                                background: "none",
                                border: "none",
                                outline: "none",
                                width: "100%",
                              }}
                              placeholder="Heading"
                            />
                            {fieldErrors?.title && (
                              <span
                                className="error_text"
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "0",
                                }}
                              >
                                {fieldErrors?.title}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className=" col-12 col-lg-5 mb-3 pe-0">
                          <label className="new_order_label" htmlFor=""></label>
                          <p className="generalInquiry">
                            General Inquiry Only
                            <span className="switchEnquiry">
                              <FormControlLabel
                                checked={data?.only_inquiry}
                                onChange={() =>
                                  setData({ ...data, only_inquiry: !data?.only_inquiry })
                                }
                                control={<IOSSwitch sx={{ m: 1 }} />}
                              />
                            </span>
                          </p>
                          <p className="generalInquiryN">
                            No plans or booking details are required, only an
                            inquiry form is available.
                          </p>
                        </div>
                        <div className="col-12 mb-3">
                          <label className="new_order_label" htmlFor="">
                            Service Description
                          </label>
                          <ServicessettEditor
                            value1={
                              data?.description === null
                                ? ""
                                : data?.description
                            }
                            data={data}
                            setData={setData}
                          />
                        </div>
                        <div className="col-12 mb-3">
                          <label className="new_order_label" htmlFor="">
                            Additional Information
                          </label>
                          <Services_sett_Quote
                            value1={data?.quote === null ? "" : data?.quote}
                            data={data}
                            setData={setData}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddServices;
