import React from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import Banner from '../Components/OurStudio/Banner'
import GareSection from '../Components/OurStudio/GareSection'
import StudioList from '../Components/OurStudio/StudioList'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import Footer from '../Components/NavBarFooter/Footer'
import { useAuth } from '../Context/ContextProvider'

function OurStudio() {
  const {adminStudio}=useAuth()


  return (
    <>
    <div className='container'>
        <NavBar/>
        <Banner/>
        <GareSection/>
       <div style={{marginBottom: '100px'}}> <StudioList adminStudio={adminStudio}/></div>
    </div>
        <JoinOurTeam/>
        <Footer/>
    </>
  )
}

export default OurStudio