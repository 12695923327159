import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBarFooter/NavBar";
import Banner from "../Components/StdioDetail/Banner";
import StudioPricing from "../Components/PhotograypyDetailPage/StudioPricing";
import JoinOurTeam from "../Components/HomePage/JoinOurTeam";
import Footer from "../Components/NavBarFooter/Footer";
import Review from "./Review";
import { useParams } from "react-router-dom";
import { useAuth } from "../Context/ContextProvider";
import PhotoVideo from "../Components/StdioDetail/PhotoVideo";
import Enquery from "../Components/PhotograypyDetailPage/Enquery";
import img from "../asset/icons/video production.svg";
import img1 from "../asset/icons/Group 1000014124.svg";
import StudioMore from "./StudioMore";
// import ServiceStudioPricing from '../Components/PhotograypyDetailPage/ServiceStudioPricing'

function StdioDetail() {
  const {
    getAdminStudioDetail,
    adminStudioDetail,
    buttonLoding,
    studioList,
    web_url,
    adminStudio,
  } = useAuth();
  const parms = useParams();

  useEffect(() => {
    getAdminStudioDetail(parms?.id);
    // eslint-disable-next-line
  }, [parms?.id]);
  const [showFullText, setShowFullText] = useState(false);
  return (
    <>
      <div className="container">
        <NavBar />

        {buttonLoding ? (
          <div className="row">
            {/* ======== section 1=============== */}
            <div className=" col-12">
              <div className="row ">
                <div className=" col-12 my-2 placeholder-glow  ">
                  <div className="placeholder loader rounded "></div>
                </div>
                <div className="col-12 ">
                  <div className="row">
                    <div className="col-lg-3 col-12 my-2 placeholder-glow">
                      {" "}
                      <div className="placeholder loader rounded "></div>
                    </div>
                    <div className="col-lg-3 col-12 my-2 placeholder-glow">
                      {" "}
                      <div className="placeholder loader rounded "></div>
                    </div>
                    <div className="col-lg-3 col-12 my-2 placeholder-glow">
                      {" "}
                      <div className="placeholder loader rounded "></div>
                    </div>
                    <div className="col-lg-3 col-12 my-2 placeholder-glow">
                      {" "}
                      <div className="placeholder loader rounded "></div>
                    </div>
                  </div>
                </div>
                <div className=" col-12 my-2 placeholder-glow ">
                  <div
                    className="placeholder loader rounded "
                    style={{ height: "100px" }}
                  ></div>
                </div>

                <div className="col-12 ">
                  <div className="row">
                    <div className="col-lg-3 col-12 my-2 placeholder-glow">
                      {" "}
                      <div className="placeholder loader rounded "></div>
                    </div>
                    <div className="col-lg-3 col-12 my-2 placeholder-glow">
                      {" "}
                      <div className="placeholder loader rounded "></div>
                    </div>
                    <div className="col-lg-3 col-12 my-2 placeholder-glow">
                      {" "}
                      <div className="placeholder loader rounded "></div>
                    </div>
                    <div className="col-lg-3 col-12 my-2 placeholder-glow">
                      {" "}
                      <div className="placeholder loader rounded "></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Banner data={adminStudioDetail} />
            <div className="ourStudioDetail">
              <PhotoVideo data={adminStudioDetail} />
            </div>

            <div className="row">
              <div className="col-12 Studio_detail_page ">
                <div
                  dangerouslySetInnerHTML={{
                    __html: showFullText
                      ? adminStudioDetail?.additional_information
                      : `${adminStudioDetail?.additional_information?.slice(
                          0,
                          650
                        )}`,
                  }}
                  className="Ser_para " style={{ fontSize: '14px', lineHeight: '24px' }}
                ></div>

                {adminStudioDetail?.additional_information?.length > 650 && (
                  <>
                    <hr
                      className="mb-0"
                      style={{ width: "90%", margin: ".5rem auto" }}
                    />
                    <div className="d-flex justify-content-center">
                      <button
                        className="ser_read "
                        onClick={() => setShowFullText(!showFullText)}
                      >
                        {showFullText ? "Read Less" : "Read More"}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            {adminStudioDetail?.only_inquiry === 0 ? (
              <>
                <div className="Studio_detail_page  col-12 d-flex justify-content-center">
                  <h4 className="studio_head mb-0">Packages</h4>
                </div>
              </>
            ) : (
              ""
            )}

            {adminStudioDetail?.only_inquiry === 0 && (
              <StudioPricing
                serviceable_type={"studio"}
                data={adminStudioDetail}
              />
            )}

            {adminStudioDetail?.plans?.filter((plan) => plan.is_active === 1)
              .length > 2 && (
              <Enquery
                service_id={adminStudioDetail?.studio_id}
                service_name={adminStudioDetail?.name}
                vendor_id={adminStudioDetail?.user?.id}
                serviceable_type={"studio"}
                ser_type={adminStudioDetail?.studio_sub_services}
              />
            )}

            <div className="Studio_detail_page mt-5 col-12 d-flex justify-content-center">
              <h4
                className="studio_head new_studio_head mb-0 "
                style={{ marginTop: "2rem" }}
              >
                my reviews
              </h4>
            </div>

            <div id="reviews" className="p-4 mb-5">
              <Review
                data={
                  adminStudioDetail?.reviews ? adminStudioDetail?.reviews : []
                }
              />
            </div>
            <div className="Studio_detail_page mt-5 col-12 d-flex justify-content-center">
              <h4
                className="studio_head new_studio_head mb-0 "
                style={{ marginTop: "2rem" }}
              >
                Similar studio
              </h4>
            </div>
          <StudioMore data={adminStudioDetail}/>
          </>
        )}
      </div>
      <JoinOurTeam />
      <Footer />
    </>
  );
}

export default StdioDetail;
