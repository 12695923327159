import React from 'react'
import WizardForm from '../Components/JoinOurTeam/WizardForm'

function JoinOurTeam() {
  return (
    <div>
        <WizardForm/>
    </div>
  )
}

export default JoinOurTeam