import * as React from 'react';
import dayjs from 'dayjs';
import { FaGlobeEurope } from "react-icons/fa";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../Context/ContextProvider';
import { v4 as uuidv4 } from 'uuid';
import { IoMdArrowDropdown } from 'react-icons/io';
import moment from 'moment-timezone';
import img from "../../asset/icons/video production.svg";
import img1 from "../../asset/icons/Group 1000014124.svg";
export default function Celender({ handleClose, vendor_id, plan_id, venderAvailiblty, data, setData,booking_service_type }) {
  const { slotDataVendor, addCartResp, setCartResp, addToCart, web_url, loadingPage,availableDate,getavailableDate } = useAuth()
  const [vendor, setVendor] = React.useState()
  const [slots, setslots] = React.useState([])
  React.useEffect(() => {
    if(!vendor){
      const userData = JSON.parse(localStorage.getItem("vendor_data"))
      setVendor(userData?.data)

    }
// eslint-disable-next-line
  }, [])
  React.useEffect(() => {
    // eslint-disable-next-line
    slotDataVendor?.filter((item) => {
      if (item?.is_reserved === 0) {
        return item
      }
    })
    setslots(slotDataVendor)
    // eslint-disable-next-line
  }, [slotDataVendor])
  const parms = useParams()
  const [cartData, setcard] = React.useState()
  const [value, setValue] = React.useState();
  const nanvigate = useNavigate()
  React.useEffect(() => {
    if (addCartResp === "ok") {
      localStorage.removeItem('cardData')
      nanvigate(`${web_url}order-summry`)
      setCartResp('')
    }
    // eslint-disable-next-line
  }, [addCartResp])
  // Extracting date only from $d
  // const dateOnly = new Date(value?value?.$d:new Date()).toISOString().split('T')[0];

  const dateOnly = value ? value.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');



  function formatDate(dateString) {
    // Create a Date object from the original date string
    var originalDate = new Date(dateString);
    // Array of weekday names
    var weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    // Array of month names
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // Get the day, month, and year from the Date object
    var day = originalDate.getDate();
    var monthIndex = originalDate.getMonth();
    var year = originalDate.getFullYear();
    var weekdayIndex = originalDate.getDay();
    // setData({...data,day_id:weekdays[weekdayIndex]})
    // Format the date string
    return weekdays[weekdayIndex] + " " + day + ", " + months[monthIndex] + " " + year;
  }
  React.useEffect(() => {
    var originalDate = new Date(dateOnly);
    
    // Array of weekday names
    var weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var weekdayIndex = originalDate.getDay();
    
    // Get start of the month
    const startOfMonth = new Date(originalDate.getFullYear(), originalDate.getMonth(), 1);
    const day = String(startOfMonth.getDate()).padStart(2, '0'); // Ensures 2-digit day
    const month = String(startOfMonth.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = startOfMonth.getFullYear();
    
    // Construct the formatted date string
    const formattedMonth = `${day}-${month}-${year}`;
    // Format date as 'YYYY-MM-DD'
    const formattedDate = originalDate.toISOString().split('T')[0];
    setData({ ...data, day_id: weekdays[weekdayIndex] })
    venderAvailiblty({ ...data, day_id: weekdays[weekdayIndex], date: formattedDate })
    getavailableDate({plan_availability_id:data?.plan_availability_id,date:formattedMonth})
    // eslint-disable-next-line
  }, [dateOnly])
  const generateUUID = () => {
    const newId = uuidv4();
    return newId
  };
  function convertTo12HourFormat(time24) {
    const [hours24, minutes24] = time24.split(':').map(Number);
    let hours12 = hours24 % 12 || 12; // Convert 0 to 12
    const ampm = hours24 < 12 ? 'AM' : 'PM';
    return `${hours12?.toString().padStart(2, '0')}:${minutes24?.toString().padStart(2, '0')} ${ampm}`;
  }
  const addToCartLocal = () => {
    let id = generateUUID()
    let client = JSON.parse(localStorage.getItem("vendor_data"))
    let obj = {
      plan_id: plan_id,
      slot_id: cartData?.id,
      cart_key: id,
      service_date: dateOnly,
      vendor_id: vendor_id,
      booking_service_type:booking_service_type,
      quantity:1
    }
    if (client?.data?.role === "client") {
      addToCart(obj)
    }
    else {
      localStorage.setItem("cardData", JSON.stringify(obj))
      nanvigate(`${web_url}clinet-signin`)
    }
  }



  const [currentTime, setCurrentTime] = React.useState('');

  React.useEffect(() => {
    const updateTime = () => {
      const time = moment().tz('Europe/London').format('hh:mm:ss A');
      setCurrentTime(time);
    };

    // Update time every second
    const intervalId = setInterval(updateTime, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const parsedAvailableDates = availableDate?.map(date => dayjs(date, 'YYYY-MM-DD'));

  const disableUnavailableDates = (date) => {
    // Check if the date exists in the available dates
    return !parsedAvailableDates?.some(availableDate => availableDate.isSame(date, 'day'));
  };
  const handleMonthChange = (newMonth) => {
    const formattedMonth = newMonth.startOf('month').format('DD-MM-YYYY');
    getavailableDate({plan_availability_id:data?.plan_availability_id,date:formattedMonth})
    // Add any additional logic you need to run when the month changes
  };
  return (
    <div className='row w-100'>
    <div className='celender col-6'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ position: 'relative' }}>
          <DemoContainer components={['DateCalendar', 'DateCalendar']}>
            <DemoItem>
              <DateCalendar
            shouldDisableDate={disableUnavailableDates} 
                value={value}
                onChange={(newValue) => setValue(newValue)}
                onMonthChange={handleMonthChange}
              />
            </DemoItem>
            <div style={{ position: 'absolute', bottom: '0' }}>
              <p className='px-3 m-0 ' style={{
                fontSize: "13px", fontWeight: '600', color: "#494949", lineHeight: "24px"
              }}>Time Zone</p>
              <p className='px-3 pt-2' style={{
                fontSize: "13px", fontWeight: '500', color: "#494949", lineHeight: "24px"
              }}>
                <FaGlobeEurope /> UK, Manchester Time ({currentTime})<IoMdArrowDropdown style={{ cursor: "pointer", marginLeft: "1rem" }} />
              </p>
            </div>
          </DemoContainer>
        </div>
      </LocalizationProvider>
    </div>
      <div className='col-6'> 
   
      {value && availableDate?.length >0 ?
      <div className='slots_vender p-3'>
        <h3>{formatDate(dateOnly)}</h3>
        {loadingPage ? <div className='d-flex justify-content-center'>
          <div
            className="spinner-border text-dark"
            role="status"
          ></div>
        </div> : <div className='d-flex justify-content-start flex-wrap slots_vender_box'>
          {slots?.length ? slots?.map((item) => {
            return (
              <>
                {<h5 onClick={() => setcard(item)} style={{ background: `${item === cartData ? "#494949" : ""}`, color: `${item === cartData ? "white" : ""}`, fontSize: "15px",height:"max-content" }} className='me-2'>  {convertTo12HourFormat(item?.start_time)} to {convertTo12HourFormat(item?.end_time)}</h5>}
              </>

            )
          }) : <p className='error_text'>No appointment slots available on this date. Please select another date.</p>}
          {availableDate?.length ===0 &&  <p className='error_text'>No date available for appointment on this month. Please select another month.</p>}

        </div>}

        {vendor?.role === "vendor" || vendor?.role === "vendor-admin" ? "" : <button onClick={() => {
          addToCartLocal()

        }} disabled={cartData ? false : true} style={{ background: `${cartData ? "#494949" : "#b6b6b6"} `, borderColor: "transparent" }}>Countinue</button>}
        {parms?.id === vendor?.user?.id && <button onClick={() => {
          nanvigate(`${web_url}vendor/dashboard`)

        }} style={{ borderColor: "transparent" }}>Dashboard</button>}
        <button onClick={() => handleClose()} className='btn-1'>Cancel</button>

      </div>: <p className='error_text'>No date available for appointment on this month. Please select another month.</p>
      }
      </div>
      <div className='col-12'>
      <p>{
                        (() => {
                          const planAvailability = data;
                          const bookingServiceType = data?.booking_service_type;

                          if (planAvailability?.cancellation_policy === "Partial Refund") {
                            if (bookingServiceType === "hourly_slots") {
                              return `You may cancel your booking up to ${data?.cancellation_time} hours before the reserved slot for a ${data?.refunded_percentage
                                }% refund.${data?.same_day === 1 ? " Cancellations are not allowed on the booking day." : ""}`;
                            }
                            if (bookingServiceType === "item_quantity") {
                              return `You may cancel your booking within ${data?.cancellation_time} hours and receive a ${data?.refunded_percentage
                                }% refund.${data?.same_day === 1 ? " Cancellations are not allowed on the booking day." : ""}`;
                            }
                          } else if (planAvailability?.cancellation_policy === "Fully Refund") {
                            return "You are eligible for a full refund if you cancel within the specified time frame. Please note that refunds are not available on the same day as the booking.";
                          } else if (planAvailability?.cancellation_policy === "No Refund") {
                            return "Please review your booking details carefully before confirming, as this booking is non-refundable.";
                          }
                          return "";
                        })()
                      }</p>
                  <div className="studio_avail_data">
                    <div className="studio_avail_data_inner">
                      <img className="me-1" src={img} alt="" />
                      Service Available in : &nbsp;
                      <span>
                        {/* UK: Leicester, London, Bristol. */}
                        {' '}
                        {data?.states
                          ?.map((state) => state.name)
                          .join(", ")}
                      </span>
                    </div>

                    <div style={{ cursor: "pointer" }} className="studio_avail_data_inner"
                     
                    >
                      <img className="me-1" src={img1} alt="" />
                      Cancellation Policy : &nbsp;
                      <span>
                        {data?.cancellation_policy === "Partial Refund" && "Partially Refundable"}
                        {data?.cancellation_policy === "Fully Refund" && "Refundable"}
                        {data?.cancellation_policy === "No Refund" && "Non-Refundable"}
                      </span>
                
                    </div>
                  </div>
          
           
      </div>
    </div>
  );
}