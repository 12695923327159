import { ArrowUpward } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { FaCalendarAlt, FaRegEye } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { CiSearch, CiStar } from "react-icons/ci";
import { VscSettings } from "react-icons/vsc";
// import Checkbox from '@mui/material/Checkbox';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import NewOrderEdit from "./NewOrderEdit";
import Menu from "@mui/material/Menu";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { CgSortAz } from "react-icons/cg";
import { useAuth } from "../../Context/ContextProvider";
import dayjs from "dayjs";
import { MdDashboard } from "react-icons/md";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
};

function Dashboard() {
  const [open, setOpen] = React.useState(false);
  const { clientDash, clientDashboard, buttonLoding ,web_url } = useAuth()
  useEffect(() => {
    clientDashboard()
    // eslint-disable-next-line
  }, [])
  const handleClose = () => setOpen(false);
  const [show, setshow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick1 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [data, setData] = useState()

  const getStatusClass = (status) => {
    let reStatus = determineOrderStatus(status);
    switch (status?.orderService ? reStatus : status) {
      case "Confirmed":
        return "status-confirmed";
      case "Awaiting Data":
        return "status-awaiting";
      case "Client Reviewing":
        return "status-reviewing";
      case "Partially Reviewing":
        return "status-reviewing";
      case "Revision Requested":
        return "status-revision";
      case "Required Original Data":
        return "status-original-requested";
      case "Original Data Submitted":
        return "status-original-submitted";
      case "Completed":
        return "status-completed";
      case "Partially Completed":
        return "status-completed";
      case "Refund":
        return "status-refund";
      case "Pending":
        return "status-pending";
      case "Cancelled":
        return "status-cancel";
      case "Ready For Complete":
        return "status-completed";
      case "Refund Requested":
        return "status-refund";
      default:
        return "";
    }
  };

  function determineOrderStatus(order) {
    if (!order || !order.orderService || !Array.isArray(order.orderService)) {
      return "Invalid order";
    }
    const serviceStatuses = order?.orderService?.map(
      (service) => service.orderStatus.status_name
    );
    const uniqueStatuses = new Set(serviceStatuses);
    console.log("uniqueStatuses", uniqueStatuses)
    if (uniqueStatuses.size === 1) {
      return serviceStatuses[0];
    }

    if (serviceStatuses.includes("Completed")) {
      return "Partially Completed";
    }
    if (serviceStatuses.includes("Refund")) {
      return "Refund";
    }
    if (serviceStatuses.includes("Pending")) {
      return "Pending";
    }

    if (serviceStatuses.includes("Client Reviewing")) {
      return "Partially Reviewing";
    }

    return "Multiple Different Status";
  }
  const [pagination, setPagination] = useState({


    first_name: "",



    added_date: "",
    status_code: [],

  })
  useEffect(() => {
    clientDashboard(pagination)
    // eslint-disable-next-line
  }, [])
  const handleDateChange = (date) => {
    const adjustedDate = dayjs(date[0]).format('YYYY-MM-DD');
    const adjustedDate1 = dayjs(date[1]).format('YYYY-MM-DD');
    setPagination({ ...pagination, added_date: adjustedDate + " to " + adjustedDate1 })
    clientDashboard({ ...pagination, added_date: adjustedDate + "to" + adjustedDate1 })
  }
  const [filterState, setFilterState] = useState({
    confirmed: false,
    completed: false,
    accepted: false,
    expired: false,
    pending: false,
    rejected: false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFilterState((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleFilter = () => {
    const checkedStatuses = Object.keys(filterState).filter(
      (status) => filterState[status]
    );
    console.log({ ...pagination, status_code: checkedStatuses });
    
    clientDashboard({ ...pagination, status_code: checkedStatuses })
    // You can now store `checkedStatuses` in another state or use it as needed
  };
  return (
    <div className=" dashborad_main">
      <div className="row m-0">
        <div className=" col-lg-4 col-md-6 col-12 px-4">
      <Link to={`${web_url}client/completed-order`} className='link_sidebar'>
          <div style={{ background: "white" }} className="row pt-4 py-3">
          <div className="col-8">
              <h5
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#23272E",
                }}
              >
                Completed Orders
              </h5>
              <span
                style={{
                  fontSize: "14px",
                  color: "#8B909A",
                  fontWeight: "500",
                }}
              >
                Last 7 Days
              </span>
              <div>
                <span style={{ fontSize: "14px", color: "#8B909A", display: "flex", marginTop: "20px" }}>

                  <span style={{ fontSize: "14px", color: "#1EB564", marginRight: ".5rem", fontWeight: "500", display: "flex", alignItems: "center" }}>
                    <ArrowUpward style={{ fontSize: "17px" }} /> 6%
                  </span>
                  vs Last 7 Days
                </span>
              </div>
          </div>
            <div className="col-4">
              <h1
                style={{
                  fontSize: "32px",
                  fontWeight: "700",
                  color: "#23272E",
                }}
              >
                {clientDash?.completedOrders}
              </h1>
            </div>
           
          </div>
        </Link>
        </div>
        <div className=" col-lg-4 col-md-6 col-12 px-4">
        <Link to={`${web_url}client/reject-order`} className='link_sidebar'>
          <div style={{ background: "white" }} className="row pt-4 py-3">
            <div className="col-8">
              <h5
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#23272E",
                }}
              >
                Cancel Orders
              </h5>
              <span
                style={{
                  fontSize: "14px",
                  color: "#8B909A",
                  fontWeight: "500",
                }}
              >
                Last 7 Days
              </span>
              <div>
                <span style={{ fontSize: "14px", color: "#8B909A", display: "flex", marginTop: "20px" }}>

                  <span style={{ fontSize: "14px", color: "#1EB564", marginRight: ".5rem", fontWeight: "500", display: "flex", alignItems: "center" }}>
                    <ArrowUpward style={{ fontSize: "17px" }} /> 0%
                  </span>
                  vs Last 7 Days
                </span>
              </div>
            </div>
            <div className="col-4">
              <h1
                style={{
                  fontSize: "32px",
                  fontWeight: "700",
                  color: "#23272E",
                }}
              >
                {clientDash?.cancelledOrders}
              </h1>
            </div>
          </div>
          </Link>  
        </div>
        <div className=" col-lg-4 col-md-12 col-12 px-4">
        <Link to={`${web_url}client/pending-order`} className='link_sidebar'>
          <div style={{ background: "white" }} className="row pt-4 py-3">
            <div className="col-8">
              <h5
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  color: "#23272E",
                }}
              >
                Pending Orders
              </h5>
              <span
                style={{
                  fontSize: "14px",
                  color: "#8B909A",
                  fontWeight: "500",
                }}
              >
                Last 7 Days
              </span>
              <div>
                <span style={{ fontSize: "14px", color: "#8B909A", display: "flex", marginTop: "20px" }}>

                  <span style={{ fontSize: "14px", color: "#1EB564", marginRight: ".5rem", fontWeight: "500", display: "flex", alignItems: "center" }}>
                    <ArrowUpward style={{ fontSize: "17px" }} /> 6%
                  </span>
                  vs Last 7 Days
                </span>
              </div>
            </div>
            <div className="col-4">
              <h1
                style={{
                  fontSize: "32px",
                  fontWeight: "700",
                  color: "#23272E",
                }}
              >
                {/* {orderData?.length?orderData?.length:'0'} */}
                {clientDash?.pendingOrders}
              </h1>
            </div>
          </div>
          </Link>
        </div>
      </div>
      <div className="m-2">
        <div className="table_main">
          <h4 style={{ fontSize: "24px", margin: "1rem 0", fontWeight: '600', color: "#2E2E30" }}>Recent Order</h4>
          <div className="table_box " style={{ borderRadius: "0" }}>


            <div className="filter_main  ">
              <label>
                <CiSearch className="icons" />
                <input onChange={(e) => {
                  setTimeout(() => {
                    clientDashboard({ ...pagination, first_name: e.target.value })
                  }, 2000);
                }} type="search" placeholder="Search by Name..." />
              </label>
              <span onClick={handleClick}>
                Date <FaCalendarAlt className="icons" />
              </span>
              <span onClick={handleClick1}>
                Filter <VscSettings className="icons" />
              </span>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                style={{ marginLeft: "-180px", marginTop: ".2rem" }}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <div
                  style={{ height: "450px", padding: ".5rem" }}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="row" style={{ margin: "1rem" }}>
                        <div
                          style={{
                            background: "#494949",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            borderRadius: "5px",
                          }}
                          className="col-6 py-2"
                        >
                          <span>From</span>
                        </div>
                        <div
                          style={{
                            background: "#F0F0F0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="col-6 py-2"
                        >
                          <span>To</span>
                        </div>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            "DateRangeCalendar",
                            "DateRangeCalendar",
                          ]}
                        >
                          <DemoItem>
                            <DateRangeCalendar onChange={handleDateChange} calendars={1} />
                          </DemoItem>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    {/* <div className="col-6">
                      <h6 style={{ fontWeight: "600" }}>By Date</h6>
                      <div className="row p-3">
                        <div className="col-6 my-2">
                          <input type="checkbox" className="me-2" />
                          <label>This Week</label>
                        </div>
                        <div className="col-6 my-2">
                          <input type="checkbox" className="me-2" />
                          <label>Next Week</label>
                        </div>
                        <div className="col-6 my-2">
                          <input type="checkbox" className="me-2" />
                          <label>This Month</label>
                        </div>
                        <div className="col-6 my-2">
                          <input type="checkbox" className="me-2" />
                          <label>Next Month</label>
                        </div>
                        <div className="col-6 my-2">
                          <input type="checkbox" className="me-2" />
                          <label>This Year</label>
                        </div>
                        <div className="col-6 my-2">
                          <input type="checkbox" className="me-2" />
                          <label>Next Year</label>
                        </div>
                        <hr />
                        <div className="col-12 my-2">
                          <input type="checkbox" className="me-2" />
                          <label>Available 7 days/week</label>
                        </div>

                        <button
                          style={{
                            padding: ".5rem",
                            border: "none",
                            background: "#494949",
                            color: "white",
                            borderRadius: "5px",
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </Menu>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl2}
                style={{ marginLeft: "-180px", marginTop: ".2rem" }}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <div
                  className="filter_dashbord"
                  style={{ width: "300px", height: "370px", padding: ".5rem" }}
                >
                  <h5>Filter</h5>
                  <p style={{ fontSize: "12px", color: "#53545C" }}>Order Status</p>
                  <ul className="filter_ul">
                    <li>
                      <input
                        type="checkbox"
                        name="confirmed"
                        checked={filterState.confirmed}
                        onChange={handleCheckboxChange}
                        style={{ marginRight: ".5rem" }}
                      />
                      <span
                        style={{ color: "#0C9ED3", background: "#DFF7FF" }}
                        className="expired"
                      >
                        Confirmed
                      </span>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="completed"
                        checked={filterState.completed}
                        onChange={handleCheckboxChange}
                        style={{ marginRight: ".5rem" }}
                      />
                      <span
                        className="expired"
                        style={{ color: "#74B087", background: "#DEEEE8" }}
                      >
                        Completed
                      </span>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="accepted"
                        checked={filterState.accepted}
                        onChange={handleCheckboxChange}
                        style={{ marginRight: ".5rem" }}
                      />
                      <span
                        className="expired"
                        style={{ color: "#5E78F2", background: "#E4E8FD" }}
                      >
                        Accepted
                      </span>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="expired"
                        checked={filterState.expired}
                        onChange={handleCheckboxChange}
                        style={{ marginRight: ".5rem" }}
                      />
                      <span
                        className="expired"
                        style={{ color: "#624BA2", background: "#D8CAFF" }}
                      >
                        Expired
                      </span>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="pending"
                        checked={filterState.pending}
                        onChange={handleCheckboxChange}
                        style={{ marginRight: ".5rem" }}
                      />
                      <span
                        className="expired"
                        style={{ color: "#FEB723", background: "#F9FFB8" }}
                      >
                        Pending
                      </span>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="rejected"
                        checked={filterState.rejected}
                        onChange={handleCheckboxChange}
                        style={{ marginRight: ".5rem" }}
                      />
                      <span
                        className="expired"
                        style={{ color: "#CC5C1D", background: "#FFF2EB" }}
                      >
                        Rejected
                      </span>
                    </li>
                  </ul>
                  <button onClick={handleFilter}>Filter</button>
                </div>
              </Menu>
            </div>



            <div className="dash_tables">
              <table className="table  table-sm ">
                <thead style={{ position: "sticky", top: "0", zIndex: "111" }}>
                  <tr>
                    <th scope="col">
                      {" "}
                      {/* <BpCheckbox /> */}
                    </th>
                    <th scope="col" className="oder_th">Order ID <CgSortAz /></th>
                    <th scope="col" className="oder_th">Order Date <CgSortAz /></th>
                    <th scope="col" className="oder_th">Vendor Name</th>

                    <th scope="col" className="oder_th">Order Amount</th>
                    <th scope="col" className="oder_th"> Status</th>

                    <th scope="col" className="oder_th text-center">Action</th>
                  </tr>
                </thead>
                <tbody>


                  {/* ================================= mapping==============
             */}
                  {/* {orderData?.map((item)=>{
                 return(
                  <tr>
                  <th className="ps-2" scope="row">
                    {" "}
                    <BpCheckbox />
                  </th>
                  <td>{item?.order_number}</td>
                  <td>
                    {item?.created_at?.split("T")[0]}
                  </td>
                  <td>
                   {item?.orderService[0]?.service_date}
                  </td>
                  
                  <td>£{item?.total}</td>
                  <td>
                    <span className="pending">Pending</span>
                  </td>
                  <td>
                    <button
                      style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        color: "#7C7C7C",
                        background: "#ECECEC",
                      }}
                      className="view_button comm_btn"
                      onClick={() => {
                        setshow(true)
                        setData(item)
                      }}
                    >
                      View <FaRegEye style={{ fontSize: "15px" }} />
                    </button>
                   
                    <button
                      style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        color: "#7C7C7C",
                        background: "#ECECEC",
                      }}
                      className="complete_button comm_btn"
                      onClick={handleOpen}
                    >
                      Complete <IoCheckmark style={{ fontSize: "15px" }} />
                    </button>

                  </td>
                </tr>
                 ) 
                })} */}

                  {/* =====================dummy====================== */}

                  {buttonLoding ? (
                    <tr>
                      <td colSpan={10}>
                        <div style={{ height: "400px", width: "100%" }}>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) :
                    <>

                      {

                        clientDash?.orders?.length ?
                          clientDash?.orders?.map((item) => {
                            return (

                              <tr>
                                <th className="ps-2" scope="row">
                                  {" "}
                                  {/* <BpCheckbox /> */}
                                </th>
                                <td>{item?.order_number}</td>
                                <td>
                                  {item?.order_confirmed_date?.split(" ")[0]}
                                  <br />
                                  {item?.date}
                                </td>
                                <td>{item?.orderService?.length ? item?.orderService[0].vendor?.name : "-"}</td>

                                <td>£{item?.total}</td>
                                <td>
                                  <span className={getStatusClass(item)}>
                                    {determineOrderStatus(item)}
                                  </span>
                                </td>
                                <td>
                                  <ul className="New_order_icons">
                                    <li onClick={() => {
                                      setData(item);
                                      setshow(true);
                                    }}>
                                      View <FaRegEye style={{ fontSize: "15px", marginLeft: ".25rem" }} />
                                    </li>
                                  </ul>
                                  {/* <button
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "bold",
                                      color: "#7C7C7C",
                                      background: "#ECECEC",
                                    }}
                                    className="view_button comm_btn"
                                   
                                  >
                                   
                                  </button> */}
                                  {/* <button
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "bold",
                                      color: "#7C7C7C",
                                      background: "#ECECEC",
                                    }}
                                    className="complete_button comm_btn"
                                    onClick={handleOpen}
                                  >
                                    Complete <IoCheckmark style={{ fontSize: "15px" }} />
                                  </button> */}
                                </td>
                              </tr>

                            )
                          })
                          :
                          <tr>
                            <td colSpan={10} style={{ border: "none" }}>
                              <h5 className='text-center'>
                                No Order Found !
                              </h5>
                            </td>
                          </tr>
                      }


                    </>
                  }








                </tbody>
              </table>
            </div>
          </div>
          <NewOrderEdit data={data} show={show} setshow={setshow} />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className=" order_popup">
                <div className="row">
                  <div className="col p-0">
                    <div className="heading1">
                      <h1>Order Complete</h1>
                      <div onClick={() => handleClose()} className="closeIcon">
                        <IoClose />
                      </div>
                    </div>
                    <hr style={{ margin: 0, borderColor: "#00000078" }} />
                    <div className="main_content">
                      <p className="main_heading">
                        Are you sure to
                        <br />
                        complete the order
                      </p>
                      <p className="para">
                        Give the Rating to help us to improve our services*
                      </p>
                      <div className="star">
                        <div>
                          <CiStar style={{ fontSize: "30px" }} />
                        </div>
                        <div>
                          <CiStar style={{ fontSize: "30px" }} />
                        </div>
                        <div>
                          <CiStar style={{ fontSize: "30px" }} />
                        </div>
                        <div>
                          <CiStar style={{ fontSize: "30px" }} />
                        </div>
                        <div>
                          <CiStar style={{ fontSize: "30px" }} />
                        </div>
                        <div>0/5</div>
                      </div>
                      <div className="text">
                        <label htmlFor="textAra">Comment (Optional)</label>
                        <textarea
                          name
                          id="textAra"
                          cols={30}
                          rows={4}
                          placeholder="Type here..."
                          defaultValue={""}
                        />
                      </div>
                      <div className="text_para">
                        <p>
                          By Clicking on agree, you are asking us to complete
                          the task of vendor
                          <br />
                          (Vendor Name) ordered from you after we are unable to
                          undo this action.
                        </p>
                        <input type="checkbox" id="check" />
                        <label htmlFor="check" className="ps-2">Complete</label>
                      </div>
                      <div className="ft_btn">
                        <button
                          className="btn btn-secondary"
                          style={{
                            boxShadow: "8px 8px 24px 0 rgba(2, 2, 70, 0.15)", background: "#b6b6b6", borderRadius: "4px", borderColor: "transparent", outline: "none", width: "122px"
                          }}
                        >
                          {" "}
                          Done
                        </button>
                        <button
                          className="btn  btn-light"
                          style={{ color: "#637381", borderColor: "#BCBCBC", background: "transparent" }}
                        >
                          {" "}
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
