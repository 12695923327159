import React, { useState } from "react";
import { useAuth } from "../../Context/ContextProvider";
import img1 from "../../asset/C2.png";
import img2 from "../../asset/C4.png";
import img3 from "../../asset/C1.png";
import img4 from "../../asset/C3.png";

function Banner() {
  const {
    getCity,
    stateData,
    cityData,
    contactUS,
    buttonLoding,
  } = useAuth();
  const [data, setdata] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    state_id: "",
    city_id: "",
    message: "",
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidUKPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber.replace(/\D/g, "");

    // Regular expression for UK phone number validation
    const ukPhoneRegex = /^(?:0|\+44)?\s?(?:\d\s?){9,10}$/;
    // Check if the phone number is a valid UK phone number or its length is 11 or 13 characters

    return (
      ukPhoneRegex.test(phone) && (phone.length === 10 || phone.length === 13)
    );
  };

  const validateStep = () => {
    let errors = {};

    if (!data.first_name) {
      errors.first_name = "First Name is required.";
    }
    if (!data.last_name) {
      errors.last_name = "Last Name is required.";
    }
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!data.phone_number) {
      errors.phone_number = "Contact is required.";
    } else if (!isValidUKPhoneNumber(data.phone_number)) {
      errors.phone_number = "Please enter a valid UK phone number.";
    }

    if (!data.state_id) {
      errors.state = "State is required.";
    }
    if (!data.city_id) {
      errors.city_id = "City is required.";
    }
    if (!data.message) {
      errors.message = "Message is required.";
    }

    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = () => {
    if (validateStep()) {
      contactUS({
        ...data,
        phone_number: "44" + data?.phone_number?.replace(/\D/g, ""),
      });

      // Proceed with the submission
      setdata({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        state_id: "",
        city_id: "",
        message: "",
      });
    }
  };
  const handleChange = (e) => {
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/\D/g, "");
    // Format the phone number as "7700 900886"
    const firstPart = value.slice(0, 4);
    const secondPart = value.slice(4);
    let formattedValue = `${firstPart}`;
    if (secondPart.length > 0) {
      formattedValue += ` ${secondPart}`;
    }
    // Update the state with the formatted value
    setdata({ ...data, phone_number: formattedValue });
  };
  return (
    <>
      <div className=" upload">
        <div className="d-flex justify-content-center py-3 mt-3 mb-2">
          <button className="contact_btn">contact us</button>
          </div>
        <h1 className="conatact_us_h">Lets Talks!</h1>
        <p className="conatact_us_p ps-1">
          Contact us today for a free consultation to drive change and grow your
          market share.
        </p>
        <div className="row">
          
          <div className="col-xl-7 col-lg-6  col-12 mb-2 mt-1">
            <div className="row">
              <div
                className="col-lg-6 col-md-6 col-12"
                style={{ position: "relative" }}
              >
                <label className="new_order_label" htmlFor="first_name">
                  First Name{" "}
                </label>
                <div className="upload_title mt-1">
                  <input
                    onChange={(e) =>
                      setdata({ ...data, first_name: e.target.value })
                    }
                    type="text"
                    id="first_name"
                    placeholder="First Name"
                    value={data?.first_name}
                  />
                </div>
                {fieldErrors?.first_name && (
                  <span
                    className="error_text"
                    style={{ fontSize: "13px", position: "absolute" }}
                  >
                    {fieldErrors?.first_name}
                  </span>
                )}
              </div>
              <div
                className="col-lg-6 col-md-6 col-12"
                style={{ position: "relative" }}
              >
                <label className="new_order_label " htmlFor="last_name">
                  Last Name{" "}
                </label>
                <div className="upload_title mt-1">
                  <input
                    onChange={(e) =>
                      setdata({ ...data, last_name: e.target.value })
                    }
                    type="text"
                    id="last_name"
                    placeholder="Last Name"
                    value={data?.last_name}
                  />
                </div>
                {fieldErrors?.last_name && (
                  <span
                    className="error_text"
                    style={{ fontSize: "13px", position: "absolute" }}
                  >
                    {fieldErrors?.last_name}
                  </span>
                )}
              </div>
              <div
                className="col-lg-6 col-md-6 col-12 mt-1"
                style={{ position: "relative" }}
              >
                <label className="new_order_label mt-3" htmlFor="email">
                  Email
                </label>
                <div className="upload_title mt-1">
                  <input
                    onChange={(e) =>
                      setdata({ ...data, email: e.target.value })
                    }
                    type="email"
                    id="email"
                    placeholder="Email"
                    value={data?.email}
                  />
                </div>
                {fieldErrors?.email && (
                  <span
                    className="error_text"
                    style={{ fontSize: "13px", position: "absolute" }}
                  >
                    {fieldErrors?.email}
                  </span>
                )}
              </div>
              <div
                className="col-lg-6 col-md-6 col-12 mt-1"
                style={{ position: "relative" }}
              >
                <label className="new_order_label mt-3" htmlFor="contact">
                  Contact
                </label>
                {/* <div className="upload_title mt-1 p-0">
                <div className="d-flex w-100 ">
                  <span className="let_talk">
                    US <MdKeyboardArrowDown />
                  </span>
                  <input
                    className="w-100"
                    type="number"
                    id="talk_contact"
                    placeholder="+1 (555) 000-0000"
                  />
                </div>
              </div> */}
                <div className="upload_title d-flex align-items-center  p-0 mt-1">
                  <div
                    className="profile_contacts ps-0"
                    style={{ padding: "10px" }}
                  >
                    <img
                      style={{ width: "30px", height: "20px" }}
                      src="https://flagcdn.com/w320/gb.png"
                      alt="..."
                    />
                    <span className="mx-2">+44</span>
                  </div>
                  <input
                    type="text"
                    value={data?.phone_number}
                    onChange={handleChange}
                    placeholder="e.g 7700 900886"
                    pattern="[0-9]{4} [0-9]{6}"
                    className="inp"
                    maxLength={11}
                  />
                </div>
                {fieldErrors?.phone_number && (
                  <span
                    className="error_text"
                    style={{ fontSize: "13px", position: "absolute" }}
                  >
                    {fieldErrors?.phone_number}
                  </span>
                )}
              </div>
              <div
                className="col-lg-6  col-12 mt-1"
                style={{ position: "relative" }}
              >
                <label className="new_order_label mt-3" htmlFor="contact">
                  State
                </label>
                <div className="upload_title mt-1">
                  <select
                    value={data?.state_id}
                    onChange={(e) => {
                      setdata({ ...data, state_id: e.target.value });
                      getCity(e.target.value);
                    }}
                    name="state"
                    id=""
                    style={{ padding: "0.2rem 0" }}
                  >
                    <option value="Cancelled Order" hidden>
                      Choose State
                    </option>
                    {stateData?.map((item) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </div>
                {fieldErrors?.state && (
                  <span
                    className="error_text"
                    style={{ fontSize: "13px", position: "absolute" }}
                  >
                    {fieldErrors?.state}
                  </span>
                )}
              </div>
              <div className="col-lg-6 col-12 mt-1" style={{ position: "relative" }}>
                <label className="new_order_label mt-3" htmlFor="contact">
                  City
                </label>
                <div className="upload_title mt-1">
                  <select
                    value={data?.city_id}
                    onChange={(e) =>
                      setdata({ ...data, city_id: e.target.value })
                    }
                    name="city"
                    id=""
                    style={{ padding: "0.2rem 0" }}
                  >
                    <option value="Cancelled Order" hidden>
                      Choose City
                    </option>
                    {cityData?.map((item) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </div>
                {fieldErrors?.city_id && (
                  <span
                    className="error_text"
                    style={{ fontSize: "13px", position: "absolute" }}
                  >
                    {fieldErrors?.city_id}
                  </span>
                )}
              </div>
              <div
                className=" col-12 mt-1"
                style={{ position: "relative" }}
              >
                <label className="new_order_label mt-3" htmlFor="">
                  Message
                </label>
                <div className="upload_title mt-1">
                  <textarea
                    onChange={(e) =>
                      setdata({ ...data, message: e.target.value })
                    }
                    name=""
                    id=""
                    placeholder="Write here"
                    rows={6}
                    value={data?.message}
                    style={{
                      border: "none",
                      resize: "none",
                      outline: "none",
                      width: "100%",
                    }}
                  ></textarea>
                </div>
                {fieldErrors?.message && (
                  <span
                    className="error_text"
                    style={{ fontSize: "13px", position: "absolute" }}
                  >
                    {fieldErrors?.message}
                  </span>
                )}
              </div>
              <div className="col-12">
                <button
                  disabled={buttonLoding}
                  onClick={handleSubmit}
                  className="talk_btn"
                >
                  {buttonLoding ? (
                    <div class="spinner-border text-light" role="status">
                      {" "}
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-5 col-md-6 col-12">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2373.368396132889!2d-2.2459296229955483!3d53.49761426339415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb1cb1e834109%3A0xf11538400d923e81!2s74h%20Sherborne%20St%2C%20Cheetham%20Hill%2C%20Manchester%20M8%208HU%2C%20UK!5e0!3m2!1sen!2s!4v1710587658792!5m2!1sen!2s"
            width="600"
            height="450"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div> */}

          <div className="col-xl-5 col-lg-6 col-md-12 d-none d-lg-block ps-0"style={{marginTop:'-2rem'}}>
            <div className="studio-contact">
              <div className="studio-contact-img d-flex flex-column">
                <div className="con-img d-flex justify-content-between mb-3">
                  <img className="img-fluid img1" src={img1} alt="..." />
                  <img className="img-fluid img2" src={img2} alt="..." />
                </div>
                <div className="con-img1 d-flex justify-content-between">
                  <img className="img-fluid img3" src={img3} alt="..." />
                  <img className="img-fluid img4" src={img4} alt="..." />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="contact_detail text-center">
            <div className="m-auto" style={{ width: "max-content" }}>
            

              <button
                style={{
                  padding: ".7rem 3.5rem",
                  marginTop: "3rem",
                  borderRadius: "8px",
                }}
                className="talk_btn2"
              >
                CONTACT US
              </button>
            </div>
            <div className="detail_box">
              <BiSolidPhoneCall className="my-2" />
              <p>{number?.contact}</p>
            </div>
            <div className="detail_box">
              <MdEmail className="my-2" />
              <p>{number?.system_email}</p>
            </div>
            <div className="detail_box">
              <RiMapPinFill className="my-2" />
              <p style={{ maxWidth: "260px", margin: "auto" }}>
                {number?.address_2}
                
              </p>
            </div>
          </div> */}


          
        </div>
     
      </div>

      <div></div>
    </>
  );
}

export default Banner;
