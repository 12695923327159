import React, { useEffect, useState } from "react";
import { IoCalendarNumberOutline, IoClose } from "react-icons/io5";
import { CiSearch, CiStar } from "react-icons/ci";
import { FaRegEye, FaStar } from "react-icons/fa";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { CgSortAz } from "react-icons/cg";
import NewOrderEdit from "./NewOrderEdit";
import { TiUpload } from "react-icons/ti";
import RevisionRequest from "../../ClientDashbord/Pages/Order/RevisionRequest";
import confirmOrder from "../../asset/icons/confirmOrder.png";
import { useAuth } from "../../Context/ContextProvider";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import { Box, Modal } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import Reschedule from "./Order/Reschedule";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
};
function ConfirmOrder() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { orderData, changeOrderStatus, getOrder, buttonLoding } = useAuth();
  const [pagination, setPagination] = useState({
    entry: 10,
    page: 1,
    id: "confirmed",
    first_name: "",
    order_number: "",
    total: "",
    order_status: "",
    added_date: "",
    update_date: "",
  });
  useEffect(() => {
    getOrder(pagination);
    // eslint-disable-next-line
  }, []);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [selectedOrderService, setSelectedOrderService] = useState(null); // To track selected order/service

  const handleMenuClick = (event, order, service) => {
    setAnchorElMenu(event.currentTarget); // Attach the clicked anchor element
    setSelectedOrderService({ order, service }); // Track which order and service is selected
  };

  const handleMenuClose = () => {
    setAnchorElMenu(null);
    setSelectedOrderService(null); // Reset when closing the menu
  };
  const menuOpen = Boolean(anchorElMenu);
  const handleDateChange = (date) => {
    const adjustedDate = dayjs(date[0]).format("YYYY-MM-DD");
    const adjustedDate1 = dayjs(date[1]).format("YYYY-MM-DD");
    setPagination({
      ...pagination,
      added_date: adjustedDate + "to" + adjustedDate1,
    });
    getOrder({
      ...pagination,
      added_date: adjustedDate + "to" + adjustedDate1,
    });
  };
  const handlePageChange = (event, value) => {
    setPagination({ ...pagination, page: value });
    getOrder({ ...pagination, page: value }); // Use the new page number provided by MUI
    // Perform your data fetching or state update here
  };
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [rating, setRating] = useState({
    client_comment: "",
    order_status_code: "6D",
    rating: "",
  }); // Initialize rating state

  const handleStarClick = (index) => {
    setRating({ ...rating, rating: index + 1 }); // Update rating state when a star is clicked
  };

  function determineOrderStatus(order) {
    if (!order || !order.orderService || !Array.isArray(order.orderService)) {
      return "Invalid order";
    }
    const serviceStatuses = order?.orderService?.map(
      (service) => service?.orderStatus?.status_code
    );
    const serviceStatuses1 = order?.orderService?.map(
      (service) => service?.orderStatus?.client_label
    );
    const uniqueStatuses = new Set(serviceStatuses);
    if (uniqueStatuses.size === 1) {
      return {
        show: serviceStatuses1[0],
        classname: serviceStatuses[0]
      };
    }
    if (serviceStatuses.includes("6D")) {
      return "Partially Completed";
    }
    if (serviceStatuses.includes("NI")) {
      return "Partially Ready For Complete";
    }
    return "Multiple Different Statuses";
  }
  const [open, setOpen] = React.useState(false);
  const [show, setshow] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setRating({
      client_comment: "",
      order_status_code: "6D",
      rating: "",
    });
  };

  const [data, setData] = useState();
  // const [show, setshow] = useState(false);
  const [show1, setshow1] = useState({
    confirm_order: false,
    await_data: false,
    client_review: false,
    revision_req: false,
    orignal_daat_req: false,
    orignal_data_submit: false,
  });
  const getStatusClass = (status) => {
    let reStatus = determineOrderStatus(status)?.classname;
    switch (status?.orderService ? reStatus : status) {
      case "ZB":
        return "status-confirmed";
      case "27":
        return "status-awaiting";
      case "IO":
        return "status-revision";
      case "GJ":
        return "status-revision";
      case "Await for Original Data":
        return "status-original-submitted";
      case "NI":
        return "status-confirmed";
      case "E4":
        return "status-confir";
      case "Partially Ready For Complete":
        return "status-confir";
      case "6D":
        return "status-original-submitted";
      case "Partially Completed":
        return "status-original-submitted";
      default:
        return "";
    }
  };
  const [orderServiceId, setOrderServiceId] = useState();
  const [orderId, setOrderId] = useState();

  const [plus, setPlus] = useState([]);
  const toggleSubtable = (idx) => {
    setPlus((item) => {
      const isVisible = item.includes(idx);
      if (isVisible) {
        return item.filter((i) => i !== idx);
      } else {
        return [...item, idx];
      }
    });
  };

  return (
    <div>
      {!show1.confirm_order &&
        !show1.await_data &&
        !show1.client_review &&
        !show1.orignal_daat_req &&
        !show1.orignal_data_submit &&
        !show1.revision_req ? (
        <>
          <div className="table_box">
            <h3>
              <img
                src={confirmOrder}
                alt=""
                style={{ marginRight: "11px", width: "22px", height: "19px" }}
              />
              Confirmed Order
            </h3>
            <div className="filter_main mb-3">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <label>
                    <CiSearch className="icons" />
                    <input
                      onChange={(e) => {
                        setTimeout(() => {
                          getOrder({
                            ...pagination,
                            first_name: e.target.value,
                          });
                        }, 2000);
                      }}
                      type="search"
                      placeholder="Search"
                    />
                  </label>
                  <span onClick={handleClick}>
                    Date <IoCalendarNumberOutline className="icons" />
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <div>Show entries:</div>
                  <select
                    value={pagination?.entry}
                    style={{
                      width: "200px",
                      border: "1px solid lightgray",
                      padding: ".5rem",
                      borderRadius: "5px",
                      outline: "none",
                    }}
                    onChange={(e) => {
                      if (orderData?.meta?.total < e.target.value) {
                        setPagination({
                          ...pagination,
                          entry: e.target.value,
                          page: 1,
                        });
                        getOrder({
                          ...pagination,
                          entry: e.target.value,
                          page: 1,
                        });
                      } else {
                        setPagination({ ...pagination, entry: e.target.value });
                        getOrder({ ...pagination, entry: e.target.value });
                      }
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </select>
                </div>
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                style={{ marginLeft: "-180px", marginTop: ".2rem" }}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <div style={{ height: "450px", padding: ".5rem" }}>
                  <div className="row">
                    <div className="col-12">
                      <div className="row" style={{ margin: "1rem" }}>
                        <div
                          style={{
                            background: "#494949",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            borderRadius: "5px",
                          }}
                          className="col-6 py-2"
                        >
                          <span>From</span>
                        </div>
                        <div
                          style={{
                            background: "#F0F0F0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="col-6 py-2"
                        >
                          <span>To</span>
                        </div>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            "DateRangeCalendar",
                            "DateRangeCalendar",
                          ]}
                        >
                          <DemoItem>
                            <DateRangeCalendar
                              onChange={handleDateChange}
                              calendars={1}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </Menu>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl2}
                style={{ marginLeft: "-180px", marginTop: ".2rem" }}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <div
                  className="filter_dashbord"
                  style={{ width: "300px", height: "370px", padding: ".5rem" }}
                >
                  <h5>Filter</h5>
                  <p style={{ fontSize: "12px", color: "#53545C" }}>
                    Order Status
                  </p>
                  <ul className="filter_ul">
                    <li>
                      <input type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                      <span
                        style={{ color: "#0C9ED3", background: "#DFF7FF" }}
                        className="expired"
                      >
                        Confirmed
                      </span>
                    </li>
                    <li>
                      <input type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                      <span
                        className="expired"
                        style={{ color: "#74B087", background: "#DEEEE8" }}
                      >
                        Completed
                      </span>
                    </li>
                    <li>
                      <input type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                      <span
                        className="expired"
                        style={{ color: "#5E78F2", background: "#E4E8FD" }}
                      >
                        Accepted
                      </span>
                    </li>
                    <li>
                      <input type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                      <span
                        className="expired"
                        style={{ color: "#624BA2", background: "#D8CAFF" }}
                      >
                        Expired
                      </span>
                    </li>
                    <li>
                      <input type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                      <span
                        className="expired"
                        style={{ color: "#FEB723", background: "#F9FFB8" }}
                      >
                        Pending
                      </span>
                    </li>
                    <li>
                      <input type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                      <span
                        className="expired"
                        style={{ color: "#CC5C1D", background: "#FFF2EB" }}
                      >
                        Rejected
                      </span>
                    </li>
                  </ul>
                  <button>Filter</button>
                </div>
              </Menu>
            </div>

            <div className="height_table">
              <table className="table nestedTable1_c custom-padding_c table-sm mt-0  my-3">
                <thead style={{ position: "sticky", top: "0" }}>
                  <tr className="thead_c">
                    <th scope="col" className="oder_th" style={{ width: "2%" }}>
                      {" "}
                      {/* <BpCheckbox /> */}
                    </th>
                    <th scope="col" className="oder_th">
                      Order ID <CgSortAz />
                    </th>
                    <th scope="col" className="oder_th">
                      Order Date <CgSortAz />
                    </th>
                    <th scope="col" className="oder_th">
                      Vendor Name
                    </th>

                    <th scope="col" className="oder_th">
                      Order Amount
                    </th>
                    <th scope="col" className="oder_th text-center" style={{ width: "17%" }}>
                      Order Status
                    </th>
                    <th scope="col" className="oder_th text-center">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {buttonLoding ? (
                    <tr>
                      <td colSpan={10}>
                        <div style={{ height: "400px", width: "100%" }}>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {orderData?.data?.length ? (
                        orderData?.data?.map((item, i) => {
                          return (
                            <>
                              {item?.orderService?.length === 1 ? (
                                <>
                                  {item?.orderService?.map((items) => {
                                    return (
                                      <tr>
                                        <td colSpan={9} className="p-0 nestTable">
                                          <table className="table nestedTable_c table-sm m-0">
                                            <tbody>
                                              <tr>
                                                <th
                                                  scope="row"
                                                  style={{ width: "3%" }}
                                                >
                                                  {" "}
                                                  {/* <BpCheckbox /> */}
                                                </th>
                                                <td>{item?.order_number}</td>
                                                <td>
                                                  {
                                                    item?.order_confirmed_date?.split(
                                                      " "
                                                    )[0]
                                                  }
                                                  <br />
                                                  {item?.date}
                                                </td>
                                                <td>
                                                  {item?.orderService?.length
                                                    ? item?.orderService[0]
                                                      .vendor?.name
                                                    : "-"}
                                                </td>

                                                <td>£{items?.subtotal}</td>
                                                {/* <td >
<span className="new_confirm">Confirmed</span>
</td> */}
                                                <td
                                                  style={{
                                                    width: "17%",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <span
                                                    className={getStatusClass(
                                                      items?.orderStatus
                                                        ?.status_code
                                                    )}
                                                  >
                                                    {
                                                      items?.orderStatus
                                                        ?.client_label
                                                    }
                                                  </span>
                                                </td>
                                                <td>
                                                  <IconButton
                                                    aria-controls="order-menu"
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleMenuClick(e, item, items)}
                                                  >
                                                    <MoreVertIcon />
                                                  </IconButton>
                                                  <Menu
                                                    id={`order-menu-${item.id}-${items.id}`} // Unique ID per menu
                                                    anchorEl={anchorElMenu}
                                                    keepMounted
                                                    open={menuOpen && selectedOrderService?.order?.id === item.id && selectedOrderService?.service?.id === items.id}
                                                    onClose={handleMenuClose}
                                                  >
                                                    <MenuItem
                                                      onClick={() => {
                                                        setData(item);
                                                        setshow(true);
                                                        handleMenuClose();
                                                      }}
                                                    >
                                                      View <FaRegEye className="ms-1" />
                                                    </MenuItem>
                                                    {items?.data_required ? <MenuItem
                                                      style={{
                                                        background:
                                                          items.orderStatus?.status_code === "ZB" ||
                                                            items.orderStatus?.status_code === "27" ||
                                                            items.orderStatus?.status_code === "6D"
                                                            ? "#0505054a"
                                                            : "",
                                                        cursor:
                                                          items.orderStatus?.status_code === "ZB" ||
                                                            items.orderStatus?.status_code === "27" ||
                                                            items.orderStatus?.status_code === "6D"
                                                            ? "not-allowed"
                                                            : "pointer"
                                                      }}
                                                      onClick={() => {

                                                        if (
                                                          items.orderStatus
                                                            ?.status_code !==
                                                          "ZB" &&
                                                          items.orderStatus
                                                            ?.status_code !==
                                                          "27" &&
                                                          items.orderStatus
                                                            ?.status_code !==
                                                          "6D"
                                                        ) {
                                                          setshow1({
                                                            ...show1,
                                                            revision_req: true,
                                                            status: items?.orderStatus?.status_code
                                                          });
                                                          setOrderServiceId(
                                                            items?.id
                                                          );
                                                          setOrderId(
                                                            item?.id
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      View Data{" "}
                                                      <TiUpload className="ms-1" />
                                                    </MenuItem> : <MenuItem style={{
                                                      background:
                                                        items.orderStatus?.status_code === "ZB" ||
                                                          items.orderStatus?.status_code === "27" ||
                                                          items.orderStatus?.status_code === "6D"
                                                          ? "#0505054a"
                                                          : "",
                                                      cursor:
                                                        items.orderStatus?.status_code === "ZB" ||
                                                          items.orderStatus?.status_code === "27" ||
                                                          items.orderStatus?.status_code === "6D"
                                                          ? "not-allowed"
                                                          : "pointer"
                                                    }}
                                                      onClick={() => {
                                                        if (
                                                          items.orderStatus
                                                            ?.status_code !==
                                                          "ZB" &&
                                                          items.orderStatus
                                                            ?.status_code !==
                                                          "27" &&
                                                          items.orderStatus
                                                            ?.status_code !==
                                                          "6D"
                                                        ) {
                                                          handleOpen();
                                                          setOrderServiceId(
                                                            items?.id
                                                          );
                                                          setOrderId(item?.id);

                                                        }
                                                      }}
                                                    >
                                                      Complete{" "}
                                                      <TiUpload className="ms-1" />
                                                    </MenuItem>}
                                                    {  items.orderStatus
                                                            ?.status_code==="ZB" && items?.rescheduled_data?.rescheduling===1?<Reschedule service={items}/>:""}
                                                  </Menu>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <th scope="row" style={{ width: "3%" }}>
                                      {" "}
                                      {plus.includes(i) ? (
                                        <FaSquareMinus
                                          className="ms-2"
                                          onClick={() => toggleSubtable(i)}
                                        />
                                      ) : (
                                        <FaSquarePlus
                                          className="ms-2"
                                          onClick={() => toggleSubtable(i)}
                                        />
                                      )}
                                    </th>
                                    <td>{item?.order_number}</td>
                                    <td>
                                      {
                                        item?.order_confirmed_date?.split(
                                          " "
                                        )[0]
                                      }
                                      <br />
                                      {item?.date}
                                    </td>
                                    <td>
                                      {item?.orderService?.length
                                        ? item?.orderService[0].vendor?.name
                                        : "-"}
                                    </td>

                                    <td>£{item?.total}</td>
                                    <td
                                      style={{
                                        width: "17%",
                                        textAlign: "center",
                                      }}
                                    >
                                      {" "}
                                      <span className={getStatusClass(item)}>
                                        {determineOrderStatus(item)?.show}
                                      </span>
                                    </td>
                                    {/* <td><span className='icons'><FaRegEye /></span><span className='icons'><BiSolidLike /></span><span className='icons'><BiSolidDislike /></span></td> */}
                            
                                    <td>
                                      <IconButton
                                        aria-controls="order-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => handleMenuClick(e, item, item)}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        id={`order-menu-${item.id}-${item.id}`} // Unique ID per menu
                                        anchorEl={anchorElMenu}
                                        keepMounted
                                        open={menuOpen && selectedOrderService?.order?.id === item.id && selectedOrderService?.service?.id === item.id}
                                        onClose={handleMenuClose}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            setData(item);
                                            setshow(true);
                                            handleMenuClose();
                                          }}
                                        >
                                          View <FaRegEye className="ms-1" />
                                        </MenuItem>
                                        
                                      </Menu>
                                    </td>
                                  </tr>
                                  {plus.includes(i) &&
                                    item?.orderService?.map((items) => {
                                      return (
                                        <tr>
                                          <td colSpan={9} className="p-0">
                                            <table className="table nestedTable_c table-sm m-0">
                                              <tbody>
                                                <tr>
                                                  <th
                                                    scope="row"
                                                    style={{ width: "3%" }}
                                                  >
                                                    {" "}
                                                    {/* <BpCheckbox /> */}
                                                  </th>
                                                  <td>{item?.order_number}</td>
                                                  <td>
                                                    {
                                                      item?.order_confirmed_date?.split(
                                                        " "
                                                      )[0]
                                                    }
                                                    <br />
                                                    {item?.date}
                                                  </td>
                                                  <td>
                                                    {item?.orderService?.length
                                                      ? item?.orderService[0]
                                                        .vendor?.name
                                                      : "-"}
                                                  </td>

                                                  <td>£{items?.subtotal}</td>
                                                  {/* <td >
               <span className="new_confirm">Confirmed</span>
               </td> */}
                                                  <td
                                                    style={{
                                                      width: "17%",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <span
                                                      className={getStatusClass(
                                                        items?.orderStatus
                                                          ?.status_code
                                                      )}
                                                    >
                                                      {
                                                        items?.orderStatus
                                                          ?.client_label
                                                      }
                                                    </span>
                                                  </td>

                                                  <td>
                                                  <IconButton
                                                    aria-controls="order-menu"
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleMenuClick(e, item, items)}
                                                  >
                                                    <MoreVertIcon />
                                                  </IconButton>
                                                  <Menu
                                                    id={`order-menu-${item.id}-${items.id}`} // Unique ID per menu
                                                    anchorEl={anchorElMenu}
                                                    keepMounted
                                                    open={menuOpen && selectedOrderService?.order?.id === item.id && selectedOrderService?.service?.id === items.id}
                                                    onClose={handleMenuClose}
                                                  >
                                                    <MenuItem
                                                      onClick={() => {
                                                        setData(item);
                                                        setshow(true);
                                                        handleMenuClose();
                                                      }}
                                                    >
                                                      View <FaRegEye className="ms-1" />
                                                    </MenuItem>
                                                    {items?.data_required ? <MenuItem
                                                      style={{
                                                        background:
                                                          items.orderStatus?.status_code === "ZB" ||
                                                            items.orderStatus?.status_code === "27" ||
                                                            items.orderStatus?.status_code === "6D"
                                                            ? "#0505054a"
                                                            : "",
                                                        cursor:
                                                          items.orderStatus?.status_code === "ZB" ||
                                                            items.orderStatus?.status_code === "27" ||
                                                            items.orderStatus?.status_code === "6D"
                                                            ? "not-allowed"
                                                            : "pointer"
                                                      }}
                                                      onClick={() => {

                                                        if (
                                                          items.orderStatus
                                                            ?.status_code !==
                                                          "ZB" &&
                                                          items.orderStatus
                                                            ?.status_code !==
                                                          "27" &&
                                                          items.orderStatus
                                                            ?.status_code !==
                                                          "6D"
                                                        ) {
                                                          setshow1({
                                                            ...show1,
                                                            revision_req: true,
                                                            status: items?.orderStatus?.status_code
                                                          });
                                                          setOrderServiceId(
                                                            items?.id
                                                          );
                                                          setOrderId(
                                                            item?.id
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      View Data{" "}
                                                      <TiUpload className="ms-1" />
                                                    </MenuItem> : <MenuItem style={{
                                                      background:
                                                        items.orderStatus?.status_code === "ZB" ||
                                                          items.orderStatus?.status_code === "27" ||
                                                          items.orderStatus?.status_code === "6D"
                                                          ? "#0505054a"
                                                          : "",
                                                      cursor:
                                                        items.orderStatus?.status_code === "ZB" ||
                                                          items.orderStatus?.status_code === "27" ||
                                                          items.orderStatus?.status_code === "6D"
                                                          ? "not-allowed"
                                                          : "pointer"
                                                    }}
                                                      onClick={() => {
                                                        if (
                                                          items.orderStatus
                                                            ?.status_code !==
                                                          "ZB" &&
                                                          items.orderStatus
                                                            ?.status_code !==
                                                          "27" &&
                                                          items.orderStatus
                                                            ?.status_code !==
                                                          "6D"
                                                        ) {
                                                          handleOpen();
                                                          setOrderServiceId(
                                                            items?.id
                                                          );
                                                          setOrderId(item?.id);

                                                        }
                                                      }}
                                                    >
                                                      Complete{" "}
                                                      <TiUpload className="ms-1" />
                                                    </MenuItem>}
                                                      {  items.orderStatus
                                                            ?.status_code==="ZB"?<Reschedule service={items}/>:""}
                                                  </Menu>
                                                </td>

                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} style={{ border: "none" }}>
                            <h5 className="text-center">No Order Found !</h5>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="paginations d-flex justify-content-end my-3">
              <Stack spacing={2}>
                <Pagination
                  count={orderData?.meta?.last_page}
                  onChange={handlePageChange}
                  shape="rounded"
                />
              </Stack>
            </div>
            <NewOrderEdit data={data} show={show} setshow={setshow} />
          </div>
        </>
      ) : (
        ""
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="order_popup">
            <div className="row">
              <div className="col p-0">
                <div className="heading1">
                  <h1>Order Complete</h1>
                  <div onClick={handleClose} className="closeIcon">
                    <IoClose />
                  </div>
                </div>
                <hr style={{ margin: 0, borderColor: "#00000078" }} />
                <div className="main_content">
                  <p className="main_heading">
                    Are you sure to
                    <br />
                    complete the order
                  </p>
                  <p className="para">
                    Give the Rating to help us to improve our services*
                  </p>
                  <div className="star">
                    {Array.from({ length: 5 }, (_, index) => (
                      <div
                        key={index}
                        onClick={() => handleStarClick(index)}
                        style={{ cursor: "pointer" }}
                      >
                        {index < rating?.rating ? (
                          <FaStar style={{ fontSize: "30px" }} /> // Render filled star if rating is greater than index
                        ) : (
                          <CiStar style={{ fontSize: "30px" }} />
                        )}
                      </div>
                    ))}
                    <div>{rating?.rating}/5</div>
                  </div>
                  <div className="text">
                    <label htmlFor="textAra">Comment (Optional)</label>
                    <textarea
                      onChange={(e) =>
                        setRating({ ...rating, client_comment: e.target.value })
                      }
                      id="textAra"
                      cols={30}
                      rows={4}
                      placeholder="Type here..."
                      defaultValue=""
                    />
                  </div>
                  <div className="text_para">
                    <p>
                      By Clicking on agree, you are asking us to complete the
                      task of vendor
                      <br />
                      (Vendor Name) ordered from you after we are unable to undo
                      this action.
                    </p>
                    <input type="checkbox" id="check" />
                    <label htmlFor="check">Complete</label>
                  </div>
                  <div className="ft_btn">
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        handleClose();
                        changeOrderStatus(
                          {
                            ...rating,
                            id_service: orderServiceId,
                            id: orderId,
                          },
                          pagination
                        );
                      }}
                      style={{
                        boxShadow: "8px 8px 24px 0 rgba(2, 2, 70, 0.15)",
                      }}
                    >
                      {" "}
                      Done
                    </button>
                    <button
                      className="btn btn-light"
                      style={{ color: "#637381" }}
                    >
                      {" "}
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {show1?.revision_req && (
        <RevisionRequest
          show={show1}
          setShow={setshow1}
          orderServiceId={orderServiceId}
          orderId={orderId}
          pagination={pagination}

        />
      )}
    </div>
  );
}

export default ConfirmOrder;
