import React, { useEffect, useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import NavBar from '../NavBarFooter/NavBar';
import JoinOurTeam from '../HomePage/JoinOurTeam'
import Footer from '../NavBarFooter/Footer'
import { useAuth } from '../../Context/ContextProvider';
function WizardForm() {
    const { getState,
        register,
        registerData,
        stateData,
        step,
        setStep,
        setCode,
        setVerify
    } = useAuth()
    useEffect(() => {
        getState()
        setStep(1)
        setFieldErrors({})
        // eslint-disable-next-line
    }, [])
    const [data, setData] = useState({
        avatar: "",
        email: "",
        name: "",
        phone_number: "",
        phone_verified: "",
        password: "",
        password_confirmation: "",
        state_id: "",
        city_id: "",
        service_id: "",
        sub_service_ids:[],
        description: "",
        terms_and_conditions: "",
        role: "vendor",
        code: "",
        type:"photography"
   
    });
    const [fieldErrors, setFieldErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const validateStep = () => {
        let errors = {};
        switch (step) {
            case 1:
                if (!data.name) {
                    errors.name = "Name is required.";
                }
                if (!data.email) {
                    errors.email = "Email is required.";
                } else if (!isValidEmail(data.email)) {
                    errors.email = "Please enter a valid email address.";
                }
                if (!data.phone_number) {
                    errors.phone_number = "Phone number is required.";
                } else if (!isValidUKPhoneNumber(data.phone_number)) {
                    errors.phone_number = "Please enter a valid UK phone number.";
                }
                if (!data.password) {
                    errors.password = "Password is required.";
                } else if (data.password.length < 8 || data.password.length > 10) {
                    errors.password = "Password must be between 8 and 10 characters long.";
                } else if (!hasSpecialCharacter(data.password)) {
                    errors.password = "Password must contain at least one special character.";
                }
                if (!data.password_confirmation) {
                    errors.password_confirmation = "Confirm Password is required.";
                } else if (data.password !== data.password_confirmation) {
                    errors.password_confirmation = "Passwords do not match.";
                }
                if (!data.state_id) {
                    errors.state_id = "State is required.";
                }
                if (!data.city_id) {
                    errors.city_id = "City is required.";
                }
                break;
            case 2:
                if (!data.service_id) {
                    errors.service_id = "Talent is required.";
                }
                if (!data.sub_service_ids || data.sub_service_ids.length === 0) {
                    errors.sub_service_ids = "Skills are required.";
                }
                break;
            case 3:
                if (!data.terms_and_conditions) {
                    errors.terms_and_conditions = "Please accept the terms and conditions.";
                }
                break;
            default:
                break;
        }
        setFieldErrors(errors);
        // Check if there are any errors
        return Object.keys(errors).length === 0;
    };
    
    // Function to check if the email is in a valid format
    const isValidEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    // Function to check if the password contains at least one special character
    const hasSpecialCharacter = (password) => {
        // Regular expression for checking special characters
        const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
        return specialCharacterRegex.test(password);
    };
    const isValidUKPhoneNumber  = (phoneNumber) => {
        let phone=phoneNumber.replace(/\D/g, '')

        // Regular expression for UK phone number validation
        const ukPhoneRegex = /^(?:0|\+44)?\s?(?:\d\s?){9,10}$/;
        // Check if the phone number is a valid UK phone number or its length is 11 or 13 characters
       
        return ukPhoneRegex.test(phone) && (phone.length === 10 || phone.length === 13);
    };
    const handleNextStep = () => {
        if (validateStep()) {
            setErrorMessage("");
            if (step === 3) {
                let phone = data?.phone_number
                register({ ...data, phone_number: "44"+phone?.replace(/\D/g, '') })
            }
            else if(step===1){
                setCode("")
                setVerify("")
                setStep(step + 1);
            }
            else {
                setStep(step + 1);
            }
        }
    };
    const handlePreviousStep = () => {
        setErrorMessage("");
        setStep(step - 1);
    };
    return (
        <>
            <div className='container wizer_form'>
                <NavBar />
                <div className='text-center' style={{marginTop:"2.5rem"}}>
                    <h1 style={{fontSize:'34px', fontWeight:"700", lineHeight:"46px", color:"#494949"}}>Be a member</h1>
                    <p style={{fontSize:'18px', fontWeight:"400", lineHeight:"30px", color:"#6F6C90", margin:"0"}} className='pera'>Become a member of Photography Studio Hub and unlock a world of opportunities and photography resources.<br /> Elevate your photography journey with us today.</p>
                </div>
                {step === 1 && <Step1 validateStep={validateStep} state={stateData} onNext={handleNextStep} data={data} setData={setData} errorMessage={errorMessage} fieldErrors={fieldErrors} />}
                {step === 2 && <Step2 onNext={handleNextStep} onPrevious={handlePreviousStep} data={data} setData={setData} fieldErrors={fieldErrors} />}
                {step === 3 && <Step3 onNext={handleNextStep} onPrevious={handlePreviousStep} data={data} setData={setData} fieldErrors={fieldErrors} />}
                {step === 4 && registerData?.success && <Step4 onPrevious={handlePreviousStep}  data={data} />}
            </div>
            <div className='my-5' style={{ marginTop: "5rem" }}>
                <JoinOurTeam />
                <Footer />
            </div>
        </>
    );
}
export default WizardForm;
