import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBarFooter/NavBar";
import Banner from "../Components/PhotograypyDetailPage/Banner";
import StudioPricing from "../Components/PhotograypyDetailPage/StudioPricing";
import JoinOurTeam from "../Components/HomePage/JoinOurTeam";
import Footer from "../Components/NavBarFooter/Footer";
import Review from "./Review";
import { useAuth } from "../Context/ContextProvider";
import { useParams } from "react-router-dom";
import PhotoVideo from "../Components/PhotograypyDetailPage/PhotoVideo";
import ServicesCard from "../Components/PhotograypyDetailPage/ServicesCard";
import Enquery from "../Components/PhotograypyDetailPage/Enquery";


function PhotograypyDetailPage() {
  const {
    getAdminServiceDetail,
    adminserviceDetail,
    adminservice,
    buttonLoding,
  } = useAuth();
  const [showFullText, setShowFullText] = useState(false);
  console.log("adminserviceDetail", adminserviceDetail)
  const parms = useParams();
  useEffect(() => {
    getAdminServiceDetail(parms?.id);
    // eslint-disable-next-line
  }, [parms?.id]);

  const modifyText = (text) => {
    const wordToRemove = "photography";
    const lowerCaseText = text?.toLowerCase();
    const lowerCaseWordToRemove = wordToRemove?.toLowerCase();

    if (lowerCaseText?.includes(lowerCaseWordToRemove)) {
      const index = lowerCaseText?.indexOf(lowerCaseWordToRemove);
      const beforeWord = text?.slice(0, index);
      const afterWord = text?.slice(index + lowerCaseWordToRemove?.length);
      return beforeWord?.concat(afterWord)?.trim();
    }
    return text;
  };
  const services_mod_name = modifyText(adminserviceDetail?.sub_service?.name);
  return (
    <>
      <div className="container ">
        <NavBar />

        {buttonLoding ? (
          <div className="row">
            {/* ======== section 1=============== */}
            <div className="col-lg-5 col-12">
              <div className="row">
                <div className="col-lg-5 col-12 my-2  placeholder-glow  ">
                  <div className="placeholder loader rounded "></div>
                </div>
                <div className="col-lg-7 col-12 my-2 placeholder-glow ">
                  <div className="placeholder loader rounded "></div>
                </div>

                <div className="col-lg-7 col-12 my-2 placeholder-glow ">
                  <div className="placeholder loader rounded "></div>
                </div>
                <div className="col-lg-5 col-12 my-2 placeholder-glow ">
                  <div className="placeholder loader rounded "></div>
                </div>
              </div>
            </div>
            {/* ======== section 2============== */}

            <div className="col-lg-7 col-12">
              <div className="row">
                <div className="col-12 my-2 placeholder-glow">
                  <div
                    className="placeholder loader rounded "
                    style={{ height: "150px" }}
                  ></div>
                </div>
                <div className="col-12 my-2 placeholder-glow">
                  <div
                    className="placeholder loader rounded "
                    style={{ height: "370px" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="text-center">
              <Banner data={adminserviceDetail} />
            </div>
            {/* <StudioPricing/> */}

            <PhotoVideo data={adminserviceDetail} />

            <div
              className={`row ${adminserviceDetail?.quote ? "mt-5" : "mt-0"}`}
            >
              {adminserviceDetail?.quote ? (
                <div className="col-12">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: showFullText
                        ? adminserviceDetail?.quote
                        : `${adminserviceDetail?.quote?.slice(0, 640)}`,
                    }}
                    className="Ser_para"
                  ></div>
                  {adminserviceDetail?.quote?.length > 640 && (
                    <>
                      <hr
                        className="ser_hr mb-0"
                        style={{ width: "90%", margin: "2rem auto" }}
                      />
                      <div className="d-flex justify-content-center">
                        <button
                          className="ser_read "
                          onClick={() => setShowFullText(!showFullText)}
                        >
                          {showFullText ? "Read Less" : "Read More"}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {adminserviceDetail?.only_inquiry === 0 && (
                <div className="Studio_detail_page  col-12 d-flex justify-content-center">
                  <h4
                    className={`studio_head studio_head2  ${adminserviceDetail?.plans[0]?.plan_availability
                        ? "mb-0"
                        : ""
                      }`}
                    style={{ width: "auto", padding: "10px 1rem", marginTop: "5rem" }}
                  >
                    {services_mod_name} Packages
                  </h4>
                </div>
              )}

            </div>

            {adminserviceDetail?.only_inquiry === 0 && (
              <StudioPricing serviceable_type={"service"} data={adminserviceDetail} />
            )}

            {adminserviceDetail?.plans?.filter(plan => plan.is_active === 1).length > 2 && <Enquery
              service_id={adminserviceDetail?.vendor_sub_service_id}
              service_name={adminserviceDetail?.sub_service?.name}
              vendor_id={adminserviceDetail?.user?.id}
              serviceable_type={"vendor_sub_service"}
            />}


            <div className="Studio_detail_page mt-4  col-12 d-flex justify-content-center">
              <h4 className="studio_head mb-0">my reviews</h4>
            </div>

            <div id="reviews" className="px-4 pt-4 pb-0 ">
              <Review
                data={
                  adminserviceDetail?.reviews ? adminserviceDetail?.reviews : []
                }
              />
            </div>

            <ServicesCard data={adminservice} />
          </>
        )}
      </div>

      <JoinOurTeam />
      <Footer />
    </>
  );
}

export default PhotograypyDetailPage;
