import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/ContextProvider";
import { FaStar } from "react-icons/fa6";
import { RiMapPinFill } from "react-icons/ri";
import { IoMdPaperPlane } from "react-icons/io";
function StudioList({ adminStudio }) {
  const { web_url } = useAuth();
  return (
    <div className="studio_list pb-3" style={{ marginTop: "-13px" }}>
      {adminStudio?.map((item, i) => {
        if (i % 2 === 0) {
          return (
            <div className="row mbotom mt-4" style={{ marginBottom: "6rem" }}>
              <div className="col-lg-6 col-md-6 col-12">
                <div
                  className="com_height_studio"
                  style={{ position: "relative" }}
                >
                  <img
                    src={item?.image}
                    style={{ position: "absolute", objectFit:"cover",objectPosition:"top"}}
                    alt="..."
                  />
                  <div
                    className="list_talent_review d-flex justify-content-between w-100 px-2 "
                    style={{
                      position: "absolute",
                      bottom: "0",
                      height: "47px",
                    }}
                  >
                    <div className="d-flex  align-items-center">
                      <div>
                        <FaStar
                          className="d-flex  align-items-center"
                          style={{ fontSize: "16px", color: "#F1A73C" }}
                        />
                      </div>
                      <div
                        className="inco_text"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                        }}
                      >
                        {item?.rating?item?.rating.toFixed(2):"5.0"}
                      </div>
                    </div>

                    <div className="d-flex  align-items-center">
                      <div>
                        <IoMdPaperPlane
                          className="d-flex  align-items-center"
                          style={{ fontSize: "16px", color: "#FFFFFF" }}
                        />
                      </div>
                      <div
                        className="inco_text"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                        }}
                      >
                         {item?.total_jobs?item?.total_jobs:"0"} Jobs
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="com_height_studio com_height_studio_new">
                  <h2
                    style={{
                      margin:'0',
                      fontSize: "32px",
                      fontWeight: "700",
                      color: "#494949",
                    }}
                  >
                    {item?.name}
                  </h2>

                  <div className="our_studio_detail">
                    <p className="location_studio">
                      <RiMapPinFill className="me-1" />
                      {item?.address}

                      {item?.starting_price != null ? (
                        <>
                          <span className="mx-2">|</span>
                          {/* £20 */}
                          <span className="colss">
                            Starting from{" "}
                            {item?.starting_price && (
                              <span style={{ fontWeight: "600" }}>
                                £{item?.starting_price}
                              </span>
                            )}
                          </span>
                        </>
                      ) : null}
                    </p>

                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#494949",
                        lineHeight: "24px",
                        // maxHeight: "283px",
                      
                        overflowY:"auto",
                        flex:"1",
                      
                      }}
                      className="studio_per_list our_studio_p"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></div>

                    <p
                    className="Stud_ament"
                     
                    >
                      Amenities
                    </p>
                    <div className="com_height_studio_ul">
                      <ul className=" ">
                        {item?.studio_amenities?.map((items) => {
                          return (
                            <li>
                              <FiCheckCircle style={{ marginRight: "1rem" }} />
                              {items.amenity}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  <Link to={`${web_url}studio-detail/${item?.studio_id}`}>
                    <button className="book_studio_btn">View Detail</button>
                  </Link>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="row mt-4" style={{ marginBottom: "6rem" }}>
              <div className="col-lg-6 col-md-6 col-12 order-2 order-lg-1">
                <div className="com_height_studio com_height_studio_new">
                  <h2
                    style={{
                      margin:'0',
                      fontSize: "32px",
                      fontWeight: "700",
                      color: "#494949",
                    }}
                  >
                    {item?.name}
                  </h2>
                  <div className="our_studio_detail">
                    <p className="location_studio">
                      <RiMapPinFill className="me-1" />
                      {/* {item?.state_id} ,{item?.city_id} */}
                      {item?.address}

                      {item?.starting_price != null ? (
                        <>
                          <span className="mx-2">|</span>
                          {/* £20 */}
                          <span className="colss">
                            Starting from{" "}
                            {item?.starting_price && (
                              <span style={{ fontWeight: "600" }}>
                                £{item?.starting_price}
                              </span>
                            )}
                          </span>
                        </>
                      ) : null}
                    </p>

                    {/* <p className='studio_per_list' style={{ fontSize: "16px", fontWeight: "400", color: "#494949", lineHeight: "24px",maxHeight:"170px"}} >
                                        {item?.description}
                                        </p> */}

                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#494949",
                        lineHeight: "24px",
                        // maxHeight: "283px",
                      
                        overflowY:"auto",
                        flex:"1",
                      
                      }}
                      className="studio_per_list our_studio_p"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></div>

                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#494949",
                        lineHeight: "24px",
                        padding: "10px 0",
                      }}
                    >
                      Amenities
                    </p>
                    <div className="com_height_studio_ul">
                      <ul className=" ">
                        {item?.studio_amenities?.map((items) => {
                          return (
                            <li>
                              <FiCheckCircle style={{ marginRight: "1rem" }} />
                              {items.amenity}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  <Link to={`${web_url}studio-detail/${item?.studio_id}`}>
                    <button className="book_studio_btn">View Detail</button>
                  </Link>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-12 order-1 order-lg-2">
                <div
                  className="com_height_studio "
                  style={{ position: "relative" }}
                >
                  <img
                    src={item?.image}
                    style={{ position: "absolute", objectFit:"cover",objectPosition:"top"}}
                    alt="..."
                  />
                  <div
                    className="list_talent_review d-flex justify-content-between w-100 px-2 "
                    style={{
                      position: "absolute",
                      bottom: "0",
                      height: "47px",
                    }}
                  >
                    <div className="d-flex  align-items-center">
                      <div>
                        <FaStar
                          className="d-flex  align-items-center"
                          style={{ fontSize: "16px", color: "#F1A73C" }}
                        />
                      </div>
                      <div
                        className="inco_text"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                        }}
                      >
                         {item?.rating?item?.rating.toFixed(2):"5.0"}
                      </div>
                    </div>

                    <div className="d-flex  align-items-center">
                      <div>
                        <IoMdPaperPlane
                          className="d-flex  align-items-center"
                          style={{ fontSize: "16px", color: "#FFFFFF" }}
                        />
                      </div>
                      <div
                        className="inco_text"
                        style={{
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          color: "#FFFFFF",
                        }}
                      >
                        {item?.total_jobs?item?.total_jobs:"100"} Jobs
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

export default StudioList;
