import React, { useEffect, useState } from "react";
import img from "../asset/Group.png";
import img1 from "../asset/icons/google.png";
import img2 from "../asset/icons/facebook.png";
import logo from "../asset/logo (1).png";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useAuth } from "../Context/ContextProvider";
import CustomizedSnackbars from "../Components/NavBarFooter/CustomizedSnackbars";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};
function Login({app_id,client_id}) {
  const navigate = useNavigate();
  const {venderLogin,addToCart,addCartResp,setCartResp,socailLogin,
    loginData,passwordForget,resendEmail,forgetdata,setLogindata,buttonLoding,web_url}=useAuth()
    const handleLoginSuccessF = (user) => {
      let obj = {
      name:user?.name,
      email:user?.email,
      provider_id:user?.id,
      provider:user?.graphDomain,
      avatar:user?.picture?.data?.url
      }
      socailLogin(obj)
    };
    const handleLoginSuccessG = (user) => {
    
      let obj = {
      name:user?.name,
      email:user?.email,
      provider_id:user?.sub,
      provider:"google",
      avatar:user?.picture
      }

      socailLogin(obj)
    };
    
  const handleLoginFailure = (error) => {
    console.log('Login Failed:', error);
  };
    const vendorData=loginData?loginData:JSON.parse(localStorage.getItem("vendor_data"))
    useEffect(()=>{
      if(vendorData?.data?.token ){
        localStorage.setItem("vendor_data",JSON.stringify(vendorData))
        const isCart=JSON.parse(localStorage.getItem("cardData"))
        if(vendorData?.data?.role==="client" && isCart ){
          addToCart(isCart)
        }
        else{
         navigate(`${web_url}${loginData?.data?.role==="vendor" || loginData?.data?.role==="vendor-admin"?"vendor": loginData?.data?.role}/dashboard`)
        }
      }
      // eslint-disable-next-line
    },[loginData])
    React.useEffect(()=>{
      if(addCartResp==="ok"){
        localStorage.removeItem('cardData')
        navigate(`${web_url}order-summry`)
        setCartResp('')
      }
      // eslint-disable-next-line
    },[addCartResp])
  const [vender, setVendor] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [fieldErrors, setFieldErrors] = React.useState({});
  const [fieldErrors1, setFieldErrors1] = React.useState({});
  const [fieldErrors2, setFieldErrors2] = React.useState({});
  const [fieldErrors3, setFieldErrors3] = React.useState({});
  const [data, setdata] = React.useState({
    password: "",
    email: "",
    role: "client",
  });
  const [data2, setdata2] = React.useState({
    password: "",
    email: "",
    role: "vendor",
  });
  const [data1, setdata1] = React.useState({
    email: "",
  });
  const [data3, setdata3] = React.useState({
    email: "",
  });
  React.useEffect(() => {
    if (loginData?.message || loginData?.errors) {
      if (
        loginData?.message !==
        "User email is not verified. Please check your mail or click on resend button"
      ) {
        setTimeout(() => {
          setLogindata(null);
        }, 3000);
      }
    }
    // eslint-disable-next-line
  }, [loginData]);
  const validateStep = () => {
    let errors = {};
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!data.password) {
      errors.password = "Password is required.";
    }
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const validateStep2 = () => {
    let errors = {};
    if (!data2.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data2.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!data2.password) {
      errors.password = "Password is required.";
    }
    setFieldErrors2(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const validateStep1 = () => {
    let errors = {};
    if (!data1.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data1.email)) {
      errors.email = "Please enter a valid email address.";
    }

    setFieldErrors1(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const validateStep3 = () => {
    let errors = {};
    if (!data3.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data3.email)) {
      errors.email = "Please enter a valid email address.";
    }

    setFieldErrors3(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = () => {
    if (validateStep()) {
      // Proceed with the submission
      venderLogin(data);
    }
  };
  const handleSubmit1 = () => {
    if (validateStep2()) {
      // Proceed with the submission
      venderLogin(data2);
    }
  };
  const handleSend = () => {
    if (validateStep1()) {
      passwordForget(data1);
    }
  };
  const handleSend1 = () => {
    if (validateStep3()) {
      passwordForget(data3);
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const vendor_data = JSON.parse(localStorage.getItem("vendor_data"));
  const [show, setshow] = useState(true);
  const [show1, setshow1] = useState(true);
  return (
    <div className="login_main upload  px-3">
      <div className="row">
        <div className="col-lg-5 col-md-6 col-12 p-5">
          <div className="d-flex justify-content-center mb-4">
            <Link to={`${web_url}`}>
              <img style={{ width: "130px" }} src={logo} alt="" />
            </Link>
          </div>
          <div className="d-flex justify-content-center btn_container">
            <button
              onClick={() => setVendor(false)}
              style={{ color: `${vender ? "#9f9f9f" : "#414040"}` }}
            >
              Client Portal
            </button>
            <button
              onClick={() => {
                if (vendor_data?.data?.token) {
                  navigate(`${web_url}vendor/new-order`);
                } else {
                  setVendor(true);
                }
              }}
              style={{ color: `${vender ? "#414040" : "#9f9f9f"}` }}
            >
              Vendor Portal
            </button>
          </div>
          {vender ? (
            <>
              <h4 className="mt-5">Welcome</h4>
              <div style={{position:"relative"}}>
              <h6 className="my-3 mb-4">Sign In To Continue</h6>
              {/* <div className="d-flex justify-content-between"> */}
                <>
                  {loginData?.role === "vendor" && (
                    <>
                     {loginData?.message && loginData?.message!=="User Logged In Successfully"? <>
                      <span className="error_text" style={{fontSize:"13px", position: "absolute" ,top:"120px"}}>{loginData?.message}
                      {loginData?.message ===
                "User email is not verified. Please check your mail or click on resend button" ? (
                  <Link
                    onClick={() => resendEmail(data2.email)}
                    style={{ fontSize: "13px" }}
                  >
                    Resend Email
                  </Link>
                ) : (
                  ""
                )}
                </span>
                     </>:""}
                     {loginData?.errors?.email ? <span className="error_text" style={{fontSize:"13px", position: "absolute" ,top:"120px"}}>{loginData?.errors?.email[0]}</span>:""}
                    </>
                  )}
                </>
               
              {/* </div> */}
              </div>
              
              <div>
              <label htmlFor="" className="new_order_label ">Email</label>
              <div className="upload_title mt-1 ">
                  <input
                    value={data2?.email}
                    onChange={(e) =>
                      setdata2({ ...data2, email: e.target.value })
                    }
                    placeholder="Email"
                    type="email"
                  />
                </div>
                {fieldErrors2?.email && (
                  <span className="error_text" style={{ position: "absolute" }}>
                    {fieldErrors2?.email}
                  </span>
                )}
              </div>
              <div>
              <label htmlFor="" className="new_order_label mt-3 ">Password</label>
              <div className="upload_title mt-1 ">
                  <input
                    value={data2?.password}
                    onChange={(e) =>
                      setdata2({ ...data2, password: e.target.value })
                    }
                    placeholder="Password"
                    type={`${show1 ? "password" : "text"}`}
                  />
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      marginRight: "1rem",
                    }}
                    onClick={() => setshow1(!show1)}
                  >
                    {show1 ? (
                      <FaRegEye style={{ color: "gray" }} />
                    ) : (
                      <FaRegEyeSlash style={{ color: "gray" }} />
                    )}
                  </button>
                </div>
                {fieldErrors2?.password && (
                  <span className="error_text" style={{ position: "absolute" }}>
                    {fieldErrors2?.password}
                  </span>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div>
                  
                  <input type="checkbox" />
                  <label
                    style={{ color: "#B2B2B2", fontSize: "13px" }}
                    className="mx-2"
                  >
                    Remember Me
                  </label>
                </div>
                <span
                  onClick={handleOpen}
                  
                  className="forgotPsd"
                >
                  Forget Password?
                </span>
              </div>
              <button onClick={handleSubmit1} className="sign_btn">
                {buttonLoding ? (
                  <div
                    className="spinner-border text-light"
                    role="status"
                  ></div>
                ) : (
                  "Sign In"
                )}
              </button>
              <div className="my-3">
                <span style={{ color: "#494949", fontSize: "14px" }}>
                  Don't have an account?{" "}
                  <Link
                    style={{ textDecoration: "none", color: "#3665C5" }}
                    to={`${web_url}join-our-team`}
                  >
                    Signup
                  </Link>
                </span>
              </div>
            </>
          ) : (
            <>
              <h4 className="mt-5">Welcome</h4>
              <div style={{position:"relative"}}>
            <h6 className="my-3 mb-4">Sign In To Continue</h6>
                <>
                  {loginData?.role === "client" && (
                    <>
                      {loginData?.message && loginData?.message!=="User Logged In Successfully"? <span className="error_text" style={{fontSize:"13px", position: "absolute" ,top:"120px"}}>{loginData?.message}</span>:""}
                      {loginData?.errors?.email ? <span className="error_text" style={{fontSize:"13px", position: "absolute" ,top:"120px"}}>{loginData?.errors?.email[0]}</span>:""}
                    </>
                  )}
                </>
              </div>
              <div>

                <label htmlFor="" className="new_order_label ">Email</label>
              <div className="upload_title mt-1 ">
                  <input
                    value={data?.email}
                    onChange={(e) =>
                      setdata({ ...data, email: e.target.value })
                    }
                    placeholder="Email"
                    type="email"
                  />
                </div>
                {fieldErrors?.email && (
                  <span className="error_text" style={{ position: "absolute" }}>
                    {fieldErrors?.email}
                  </span>
                )}
              </div>
              <div>
              <label htmlFor="" className="new_order_label mt-3 ">Password</label>
              <div className="upload_title mt-1 ">
                  <input
                    value={data?.password}
                    onChange={(e) =>
                      setdata({ ...data, password: e.target.value })
                    }
                    placeholder="Password"
                    type={`${show ? "password" : "text"}`}
                  />
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      marginRight: "1rem",
                    }}
                    onClick={() => setshow(!show)}
                  >
                    {show ? (
                      <FaRegEye style={{ color: "gray" }} />
                    ) : (
                      <FaRegEyeSlash style={{ color: "gray" }} />
                    )}
                  </button>
                </div>
                {fieldErrors?.password && (
                  <span className="error_text" style={{ position: "absolute" }}>
                    {fieldErrors?.password}
                  </span>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div>
                  <input type="checkbox" />
                  <label
                    style={{ color: "#B2B2B2", fontSize: "13px" }}
                    className="mx-2"
                  >
                    Remember Me
                  </label>
                </div>
                <span
                  onClick={handleOpen1}
                  className="forgotPsd"
                >
                  Forget Password?
                </span>
              </div>
              <button onClick={handleSubmit} className="sign_btn">
                {buttonLoding ? (
                  <div
                    className="spinner-border text-light"
                    role="status"
                  ></div>
                ) : (
                  "Sign In"
                )}
              </button>
              <div className="d-flex justify-content-center my-3">
                <span style={{ color: "#B2B2B2", fontSize: "13px" }}>
                  Or sign in with{" "}
                </span>
              </div>
              <div className="d-flex justify-content-center">
              {client_id&&<LoginSocialGoogle
                    client_id={client_id}
                    onResolve={({ provider, data }) => {
                      handleLoginSuccessG(data);
                    }}
                    onReject={(error) => {
                      console.error('Login Failed:', error);
                      handleLoginFailure(error);
                    }}
                    scope="profile email"
                  >
                    <button style={{ background: "none", border: "none" }}><img className='mx-2' src={img1} alt='' /></button>
                  </LoginSocialGoogle>}

                  {/* Facebook Login Button */}
   {app_id&&<LoginSocialFacebook
                    appId={app_id} // Replace w2ith your actual Facebook App ID
                    onResolve={({ provider, data }) => handleLoginSuccessF(data)}
                    onReject={handleLoginFailure}
                  >
                    <button style={{ background: "none", border: "none" }}>
                      <img className='mx-2' src={img2} alt='' />
                    </button>
                  </LoginSocialFacebook>}
                {/* <img className="mx-2" src={img3} alt="" />
                <img className="mx-2" src={img4} alt="" /> */}
              </div>
              <div className="d-flex justify-content-center my-3">
                <span style={{ color: "#494949", fontSize: "14px" }}>
                  Don't have an account?{" "}
                  <Link
                    style={{ textDecoration: "none", color: "#3665C5" }}
                    to={`${web_url}clinet-sigup`}
                  >
                    Signup
                  </Link>
                </span>
              </div>
            </>
          )}
        </div>
        <div
          style={{ background: "#ECECEC", height: "100%" , position:"relative" , display:"flex", justifyContent:"center", alignItems:"center",   flexDirection:"column", padding:"4rem"}}
          className="col-lg-7 col-md-6 col-12"
        >
          <img
            className="w-100"
            style={{ filter: " grayscale(100%)" }}
            src={img}
            alt=""
            
          />
          <Link style={{width:"80%",marginTop:"40px",marginBottom:"30px"}} to={`${web_url}`}>
                <button style={{border:"1px solid #545468", color:"#545468",background:"none", width:"100%"}} className='sign_btn'>Home Page</button>
                </Link>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ paddingBottom: "2rem" }}>
            <div className="d-flex justify-content-center my-3">
              <img src={logo} style={{ width: "150px" }} alt="..." />
            </div>
            {forgetdata ? (
              <a
                style={{ textDecoration: "none", color: "#494949" }}
                target="_blank"
                rel="noreferrer"
                href={forgetdata}
              >
                <h4 className="text-center my-2">Vendor Portal</h4>
              </a>
            ) : (
              <h4 className="text-center my-2">Vendor Portal</h4>
            )}

            <h5 className="text-center my-2">Forget Password</h5>
            <div style={{ height: "max-content" }} className="login_main upload px-3">
              <div>
              <label htmlFor="" className="new_order_label ">Email</label>
              <div className="upload_title mt-1 ">
                  <input
                    value={data1?.email}
                    onChange={(e) =>
                      setdata1({ ...data1, email: e.target.value })
                    }
                    type="email"
                    placeholder="Email"
                  />
                </div>
                {fieldErrors1?.email && (
                  <span className="error_text" style={{ position: "absolute" }}>
                    {fieldErrors1?.email}
                  </span>
                )}
              </div>
              <div>
                <button onClick={() => handleSend()} className="sign_btn mt-4">
                  {buttonLoding ? (
                    <div
                      className="spinner-border text-light"
                      role="status"
                    ></div>
                  ) : (
                    "Send Email"
                  )}
                </button>
              </div>
              <div>
                <button
                  onClick={() => handleClose()}
                  style={{
                    border: "1px solid #414040",
                    background: "none",
                    color: "#414040",
                  }}
                  className="sign_btn my-3"
                >
                  Back to Sign in
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ paddingBottom: "2rem" }}>
            <div className="d-flex justify-content-center my-3">
              <img src={logo} style={{ width: "150px" }} alt="..." />
            </div>
            {forgetdata ? (
              <a
                style={{ textDecoration: "none", color: "#494949" }}
                target="_blank"
                rel="noreferrer"
                href={forgetdata}
              >
                <h4 className="text-center my-2">Client Portal</h4>
              </a>
            ) : (
              <h4 className="text-center my-2">Client Portal</h4>
            )}
            <h5 className="text-center my-2">Forget Password</h5>
            <div style={{ height: "max-content" }} className="login_main upload px-3">
              <div>
              <label className="new_order_label" htmlFor="">Email</label>
                <div className="upload_title mt-1">
                  <input
                    value={data3?.email}
                    onChange={(e) =>
                      setdata3({ ...data3, email: e.target.value })
                    }
                    type="email"
                    placeholder="Email"
                  />
                </div>
                {fieldErrors3?.email && (
                  <span className="error_text" style={{ position: "absolute" }}>
                    {fieldErrors3?.email}
                  </span>
                )}
              </div>
              <div>
                <button onClick={() => handleSend1()} className="sign_btn mt-4">
                  {buttonLoding ? (
                    <div
                      className="spinner-border text-light"
                      role="status"
                    ></div>
                  ) : (
                    "Send Email"
                  )}
                </button>
              </div>
              <div>
                <button
                  onClick={() => handleClose1()}
                  style={{
                    border: "1px solid #414040",
                    background: "none",
                    color: "#414040",
                  }}
                  className="sign_btn my-3"
                >
                  Back to Sign in
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <CustomizedSnackbars />
    </div>
  );
}

export default Login;
