import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import logo from '../asset/logo (1).png'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import img from '../asset/Group.png'
import { useState } from 'react';
import { useAuth } from '../Context/ContextProvider';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import CustomizedSnackbars from '../Components/NavBarFooter/CustomizedSnackbars';
const defaultTheme = createTheme();
export default function ForgetPassword() {
  const { forgetPasswordNew,respForPass,web_url} = useAuth()
  React.useEffect(()=>{

let urldata=window.location.search.split("?data=")
let token=urldata[1]

// let email=urldata[1].split("email=")[1]
// eslint-disable-next-line
setdata({
    data:token,
    password: "",
    password_confirmation: "",

  })
  },[])
  const navigate=useNavigate()
  React.useEffect(()=>{
      if(respForPass?.success){
        navigate(`${web_url}clinet-signin`)
      }
      // eslint-disable-next-line
  },[respForPass])
  const [data, setdata] = useState({
    token:"",
    email:"",
    password: "",
    password_confirmation: "",

  })
  // eslint-disable-next-line
  const [fieldErrors, setFieldErrors] = useState({})
  const validateStep = () => {
    let errors = {

    };

    if (!data.password) {
      errors.password = "Password is required.";
    } else if (data.password.length < 8 || data.password.length > 10) {
      errors.password = "Password must be between 6 and 10 characters long.";
    } else if (!hasSpecialCharacter(data.password)) {
      errors.password = "Password must contain at least one special character.";
    }
    if (!data.password_confirmation) {
      errors.password_confirmation = "Confirm Password is required.";
    } else if (data.password !== data.password_confirmation) {
      errors.password_confirmation = "Passwords do not match.";
    }
    
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = () => {
    if (validateStep()) {
      forgetPasswordNew({...data,phone_number:data?.phone_number?.replace(/\s/g, '')})
      // Proceed with the submission
    }
  };

  const hasSpecialCharacter = (password) => {
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    return specialCharacterRegex.test(password);
  };

  const [show, setshow] = useState(true)
  const [show1, setshow1] = useState(true)
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid component="main" className='login_main' style={{ background: "#ECECEC", overflowX: "hidden" }} sx={{ height: '100vh' }}>
        <div className='row' style={{ height: "100vh" }} >
          <div style={{ background: "white" }} className='col-lg-5 col-md-6 col-12 px-5 '>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
            <div className='d-flex justify-content-center mb-4'>
            <Link to={`${web_url}`}>
            <img style={{width:"130px"}} src={logo} alt=''/>
            </Link>
            </div>
              <Box  sx={{ width: "100%" }}>
              <div className='d-flex justify-content-between align-items-center'>
              <h4 className='mt-2'>Welcome</h4>
                {/* <span  style={{color:"#494949",fontSize:"14px"}}>Already have an account?<Link style={{textDecoration:"none",color:"#3665C5",marginLeft:".1rem"}} to={'/clinet-signin'}>Sign In</Link></span> */}
            </div>
            <h6 >Set New Password</h6>
                <div className='signin_ip'>
                <div style={{ position: "relative" }}>
                    <div  style={{ marginTop: "1.5rem",background:"#ECECEC"}}>
                      <input type={`${show1?"password":"text"}`} style={{width:"92%",margin:"0"}} onChange={(e) => setdata({ ...data, password: e.target.value })} placeholder='Password' name="password" />
                      <button  style={{ background: "none", border: "none" }} onClick={() => setshow1(!show1)}>{show1 ? <FaRegEye style={{ color: "gray" }} /> : <FaRegEyeSlash style={{ color: "gray" }} />}</button>
                    </div>
                    {fieldErrors?.password && <span className="text-danger" style={{fontSize:"13px", position: "absolute" }}>{fieldErrors?.password}</span>}
                  </div>
                  <div style={{ position: "relative" }}>

                    <div style={{  marginTop: "1.5rem",background:"#ECECEC"}}>
                      <input style={{width:"92%",margin:"0"}} type={`${show?"password":"text"}`} onChange={(e) => setdata({ ...data, password_confirmation: e.target.value })} placeholder='Confirm Password' name="password_confirmationword" />
                      <button  style={{ background: "none", border: "none" }} onClick={() => setshow(!show)}>{show ? <FaRegEye style={{ color: "gray" }} /> : <FaRegEyeSlash style={{ color: "gray" }} />}</button>
                    </div>
                    {fieldErrors?.password_confirmation && <span className="text-danger" style={{fontSize:"13px", position: "absolute" }}>{fieldErrors?.password_confirmation}</span>}
                  </div>
  
                 
                </div>
                <button onClick={handleSubmit} className='sign_btn'>SUBMIT</button>

              </Box>
            </Box>
          </div>
          <div className='col-lg-7 col-md-6 col-12 p-5' style={{  position: "relative" }}>
            <img className='w-75' style={{ filter: " grayscale(100%)", position: "absolute" }} src={img} alt='...' />
   
          </div>
        </div>
        <CssBaseline />
        <CustomizedSnackbars/>
      </Grid>
    </ThemeProvider>
  );
}
