import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState } from 'react';
import { useEffect } from 'react';
import arrow from "../../asset/icons/left.png";
import arrow1 from "../../asset/icons/right.png";
let slidesToShow = 4;
const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <>
        <div style={{left:"-7.5%",background:"none", marginTop:"10px"}} className={className} onClick={onClick}>
            <img  src={arrow} alt='...'/>
        </div>
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick} = props;
  return (
    <>
        <div className={className} style={{right:"-7.5%",background:"none", marginTop:"10px"}} onClick={onClick}>
<img   src={arrow1} alt='...'/>
        </div>

    </>
  );
};

const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: false,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};


const MultiItemCarousel = ({gellery}) => {
  
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

 if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 1;
  } else if (width > 769 && width <= 992) {
    slidesToShow = 2;
  } else if (width > 992 && width <= 1200) {
    slidesToShow = 3;
  } else {
    slidesToShow = 4;
  }
  return (
    <div style={{ margin: '16px 8px 16px 8px' }} className='carousel new_setted_carousel'>

      <Slider {...carouselProperties}>
        {gellery?.map((item) => (
          <Card item={item} />
        ))}
      </Slider>
    </div>
  );
};

const Card = ({ item }) => {

  return (
    <div className='studio_cara' >
      <img
        className='multi__image'
        src={item}
        alt=''
        style={{
          width: '100%',
          height: '171px',
          objectFit: 'cover',
          marginBottm: '10px',
        }}
      />
      
    </div>
  );
};

export default MultiItemCarousel;