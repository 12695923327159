import React from 'react'

function PlaceholderPage() {
    return (
        <>
            <div className='row'>
                <div  className='col-12'>
                    <p style={{height:"50vh"}} className="placeholder-glow">
                        <span className="placeholder col-12 h-100"></span>
                    </p>
                </div>
                <div className='col-6'>
                    <p style={{height:"20vh"}} className="placeholder-glow">
                        <span className="placeholder col-12 h-100"></span>
                    </p>
                </div>
                <div className='col-6'>
                    <p style={{height:"20vh"}} className="placeholder-glow">
                        <span className="placeholder col-12 h-100"></span>
                    </p>
                </div>
                <div className='col-12'>
                    <p style={{height:"10vh"}} className="placeholder-glow">
                        <span className="placeholder col-12 h-100"></span>
                    </p>
                </div>
            </div>

        </>
    )
}

export default PlaceholderPage
