import React, { useEffect, useState } from "react";
import { useAuth } from "../../Context/ContextProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CurrentDate from "../../Pages/CurrentDate";
import { RiDeleteBin4Line, RiDeleteBinLine } from "react-icons/ri";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { MdCompareArrows, MdMedicalServices } from "react-icons/md";

import img7 from "../../asset/icons/compare.png";
function ServiceSetting() {
  const [profleData, setdata] = useState();
  const {
    getState,
    getCity,
    cityData,
    stateData,
    vedorServiceData,
    getCategry,
    category,
    vendorServices,
    subCategory,
    getSubCategry,
    serviceSetting,
    buttonLoding
  } = useAuth();
  const [serviceId, setServiceId] = useState();
  const [remaiSrevice, setRemainSrevice] = useState([]);
  const [venSrevice, setvenSrevice] = useState([]);
  console.log(venSrevice);
  useEffect(() => {
    vendorServices("photography", 1);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (vedorServiceData?.vendor_services?.length) {
      setvenSrevice(vedorServiceData?.vendor_services);
      setServiceId(vedorServiceData?.vendor_services[0]?.service?.id);
    }
    if (vedorServiceData?.all_services?.length) {
      setRemainSrevice(vedorServiceData?.all_services);
    }
    // eslint-disable-next-line
  }, [vedorServiceData]);
  const isHave = (id) => {
    return venSrevice?.some((item) => item?.service?.id === id);
  };
  const isHave1 = (service_id, sub_services_id) => {
    return venSrevice?.some(
      (item) =>
        item?.service?.id === service_id &&
        item?.service?.sub_services?.some(
          (subItem) => subItem?.id === sub_services_id
        )
    );
  };
  const handleItemClick = (serviceId, subServiceId, data) => {
    if(venSrevice?.length>1 && data?.length===1){
      handleItemClick1(serviceId)
    }
    else if (data?.length !== 1) {
      let updatedVenService = venSrevice
        .map((item) => {
          if (item.service.id === serviceId) {
            const updatedSubServices = item.service.sub_services.filter(
              (subItem) => subItem.id !== subServiceId
            );
            return {
              ...item,
              service: {
                ...item.service,
                sub_services: updatedSubServices,
              },
            };
          }
          return item;
        })
        .filter((item) => item.service.sub_services.length > 0);
      setvenSrevice(updatedVenService);
    } else {
      alert("At least category have one sub_catagory");
    }
  };
  const handleItemClick1 = (serviceId) => {
    if (venSrevice?.length > 1) {
      let updatedVenService = venSrevice.filter((item) => {
        if (item.service.id !== serviceId) {
          return item;
        }
      });
      setServiceId(updatedVenService?.length?updatedVenService[0]?.service?.id:"")
      setvenSrevice(updatedVenService);
    } else {
      alert("At least one category is req");
    }
  };
  console.log(serviceId,venSrevice);
  const handleSubmit = () => {
    const transformedData = venSrevice
      ?.map((item) => {
        const sub_service_ids = item.service.sub_services.map((sub) => sub.id);
        return {
          service_id: item.service.id,
          type: item.service.category,
          ...(sub_service_ids.length > 0 && { id: item.vendor_service_id }), // Conditionally include id
          sub_service_ids: sub_service_ids,
        };
      })
      .filter((item) => item.sub_service_ids.length > 0);
    serviceSetting(transformedData);
  };
  return (
    <div>
      {/* <h3>Services</h3>
      <p>Thursday,23 November ,2023</p> */}
      <div className="new_order_nav ">
        <div className="d-flex justify-content-between w-100 align-items-center pe-3">
          <div>
            <h4 className="d-flex align-items-center">
              {" "}
              <MdMedicalServices className="me-2" />
              Add Services{" "}
            </h4>
          </div>
        </div>
      </div>
      <div className="table_box" style={{ height: "auto" }}>
        <div className="row ">
          <div className="col-12 d-flex justify-content-between">
            <p className="comm_para">
              Add your professions (you can list multiple) and the related
              services you intend to provide.
            </p>
            <button
              style={{
                padding: ".5rem 3rem",
                border: "none",
                background: "#494949",
                color: "white",
                borderRadius: "5px",
              }}
              onClick={handleSubmit}
            >
               {buttonLoding ? (
                                <div
                                  className="spinner-border text-white"
                                  role="status"
                                ></div>
                              ) : (
                                "Save"
                              )}
              
            </button>
          </div>
            <hr className="mt-2 mb-3" />
          <div className="col-lg-6 col-12">
            <p className="service_label">Profession</p>
            <div className="ser_div ">
              <div
                className="ser_partition"
                style={{ borderBottom: "1px solid #d0d5dd" }}
              >
                <p className="ser_heading">Selected Profession(s)</p>
                <ul className="profesion">
                  {venSrevice?.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          setServiceId(item?.service?.id);
                        }}
                        className={`${
                          serviceId === item?.service?.id ? "prof_active" : ""
                        }`}
                      >
                        {item?.service?.name}
                      </li>
                    );
                  })}
                  {/* <li className="prof_active">Photograper</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li> */}
                </ul>
              </div>

              {/* <hr /> */}

              <div className="ser_choose_ser">
                <p className="ser_heading p-2">Choose Services</p>
                <div className=" p-2">
               






                  {remaiSrevice?.map((item) => {
                    if (item?.id == serviceId) {
                      return (
                        <>
                          <p className="ser_heading" style={{fontSize:"18px", lineHeight:"15px"}}>{item?.name} </p>

                          <hr style={{ margin: "17px 0px 4px" }} />
                          <ul className="ser_photo">
                            {item?.sub_services?.map((items, i) => {
                              if (!isHave1(item?.id, items?.id)) {
                                return (
                                  <li
                                    onClick={() => {
                                      let serviceUpdated = false; // Flag to track if the service was updated
                                      let updatedServices = venSrevice.map(
                                        (itemss, i) => {
                                          if (
                                            itemss?.service?.id === item?.id
                                          ) {
                                            let updatedSubServices = [
                                              ...itemss.service.sub_services,
                                              items,
                                            ];
                                            serviceUpdated = true;
                                            return {
                                              ...itemss,
                                              service: {
                                                ...itemss.service,
                                                sub_services:
                                                  updatedSubServices,
                                              },
                                            };
                                          }
                                          return itemss;
                                        }
                                      );

                                      if (serviceUpdated) {
                                        setvenSrevice(updatedServices);
                                      }
                                    }}
                                  >
                                    <label
                                      style={{ width: "100%" }}
                                      htmlFor={`sub_services${i}`}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`sub_services${i}`}
                                        style={{ marginRight: "10px" }}
                                      />
                                      {items?.name}
                                    </label>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </>
                      );
                    }
                  })}
                </div>
              </div>

              <div
                className="ser_partition1"
                style={{ borderTop: "1px solid #d0d5dd"
                 }}
              >
                <p className="ser_heading">Suggested Profession(s)</p>
                <ul className="profesion1">
                  {remaiSrevice?.map((item) => {
                    if (!isHave(item?.id)) {
                      return (
                        <li
                          onClick={() => {
                            let obj = {
                              service: {
                                id: item?.id,
                                name: item?.name,
                                slug: item?.name,
                                category: item?.category,
                                image: item?.image,
                                sub_services: [],
                              },
                            };
                            setvenSrevice([...venSrevice, obj]);
                          }}
                        >
                          {item?.name}
                        </li>
                      );
                    }
                  })}
                  {/* <li className="prof_active">Photograper</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li>
                  <li>style</li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-1 d-none d-lg-block col-12">
            {/* <div className="d-flex justify-content-between align-items-center"  >
              <span>
                <label htmlFor="" className="new_order_label">Choose Services</label>
              </span>
              <a href="#">Save</a>
            </div> */}

            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100%", fontSize: "4rem" }}
            >
              <img src={img7} alt="" />
            </div>
          </div>
          <div className="col-lg-5 col-12">
            {/* <label htmlFor="" className="new_order_label">Selected Services</label> */}
            <p className="service_label">Selected Services</p>
            <div className="ser_div p-2">
              {venSrevice?.map((item, i) => {
                if (item?.service?.sub_services?.length) {
                  return (
                    <Accordion
                      style={{
                        background: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      defaultExpanded={i === venSrevice?.length - 1}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <span className="ser_heading">
                            {item?.service?.name}{" "}
                            <span>{item?.service?.sub_services?.length}</span>
                          </span>
                          <RiDeleteBin4Line
                            className="ser_del"
                            title="Delete Service"
                            onClick={() => handleItemClick1(item?.service?.id)}
                          />
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{ margin: "0" }}>
                        <ul style={{ height: "auto" }} className="ser_photo">
                          {item?.service?.sub_services?.map((items) => {
                            return (
                              <li className="d-flex justify-content-between">
                                <span>{items?.name}</span>
                                <RiDeleteBin4Line
                                  className="ser_del"
                                  title="Delete Sub Service"
                                  onClick={() =>
                                    handleItemClick(
                                      item.service.id,
                                      items?.id,
                                      item?.service?.sub_services
                                    )
                                  }
                                />
                              </li>
                            );
                          })}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  );
                }
              })}
            </div>
          </div>
     
          {/* <div className="col-12 mt-3">
     
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ServiceSetting;
