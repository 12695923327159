import React, { useEffect, useRef, useState } from "react";
import { CiStar } from "react-icons/ci";
import {  IoIosCheckmarkCircle } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
function NewOrderEdit({ show, setshow, data, rating }) {
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setActive(true);
      }, 400);
    } else {
      setActive(false);
    }
  }, [show]);
  const sidebarRef = useRef(null);
  useEffect(() => {
    // Function to handle click outside of sidebar
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setshow(false);
          setActive(false);
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setshow, setActive]);

  const getStatusClass = (status) => {
    let reStatus = determineOrderStatus(status)?.classname;
    switch (status?.orderService ? reStatus : status) {
      case "ZB":
        return "status-confirmed";
      case "27":
        return "status-awaiting";
      case "IO":
        return "status-reviewing";
      case "Partially Reviewing":
        return "status-reviewing";
      case "GJ":
        return "status-revision";
      case "E4":
        return "status-original-requested";
      // case "IO":
      //   return "status-original-submitted";
      case 'IO':
        return 'status-completed';
      case "6D":
        return "status-completed";
      case "DI":
        return "status-completed";
      case "Partially Completed":
        return "status-completed";
      case "PE":
        return "status-cancel";
      case "CA":
        return "status-pending";
        case "RR":
          return "status-revision";
      default:
        return "";
    }
  };

  function determineOrderStatus(order) {
    if (!order || !order.orderService || !Array.isArray(order.orderService)) {
      return "Invalid order";
    }

 

    const serviceStatuses = order.orderService.map(
      (service) => service.orderStatus.status_code
    );
    const serviceStatuses1 = order.orderService.map(
      (service) => service.orderStatus.status_name
    );
    const uniqueStatuses = new Set(serviceStatuses);

    if (uniqueStatuses.size === 1) {
      return {show:serviceStatuses1[0],
        classname:serviceStatuses[0]
      };
    }
    if (serviceStatuses.includes("6D")) {
      return "Partially Completed";
    }
    if (serviceStatuses.includes("E4")) {
      return "Partially Ready For Completed";
    }
    if (serviceStatuses.includes("IO")) {
      return "Partially Reviewing";
    }
    if (serviceStatuses.includes("27")) {
      return "Partially Deliverd";
    }
    return "Multiple Different Status";
  }
  const calculateRemainingDays = (date) => {
    const today = new Date();
    const serviceDate = new Date(date);
    const timeDiff = serviceDate - today;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff;
  };

  const getStatusColor = (remainingDays) => {
    if (remainingDays <= 0) return "danger"; // Past due
    if (remainingDays <= 2) return "warning"; // 2 days or less
    return "normal"; // More than 2 days
  };
  const getLatestServiceDate = (orderServices) => {
    if (!orderServices || orderServices.length === 0) return null;

    const today = new Date();

    // Separate past and future dates
    const pastDates = [];
    const futureDates = [];

    orderServices.forEach((service) => {
      const date = new Date(service?.service_date);
      if (date >= today) {
        futureDates.push(date);
      } else {
        pastDates.push(date);
      }
    });

    if (futureDates.length > 0) {
      // Get the nearest upcoming date
      const nearestDate = new Date(Math.min(...futureDates));
      return nearestDate.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
    } else if (pastDates.length > 0) {
      // Get the latest past date
      const latestPastDate = new Date(Math.max(...pastDates));
      return latestPastDate.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
    }

    return null;
  };
  const getLatestSlot = (date) => {
    let slot;
    data?.orderService?.forEach((item) => {
      if (item?.service_date === date) {
        slot = item.slot;
      }
    });
  
    // If a slot is found, format the start and end times
    if (slot) {
      return `${convertTo12HourFormat(slot?.start_time)} to ${convertTo12HourFormat(slot?.end_time)}`;
    } else {
      return 'No slot available';
    }
  };
  
  const latestServiceDate = getLatestServiceDate(
    data?.orderService
  );
  let formattedLatestServiceDate = null;
  const latestSlot=getLatestSlot(latestServiceDate)
  if (latestServiceDate) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    formattedLatestServiceDate = new Date(latestServiceDate).toLocaleDateString('en-US', options);
  }

  const remainingDays = latestServiceDate
    ? calculateRemainingDays(latestServiceDate)
    : null;
  const statusColor = getStatusColor(remainingDays);

  function convertTo12HourFormat(time24) {
    if(time24){
      const [hours24, minutes24] = time24?.split(':')?.map(Number);
      let hours12 = hours24 % 12 || 12; // Convert 0 to 12
      const ampm = hours24 < 12 ? 'AM' : 'PM';
      return `${hours12?.toString().padStart(2, '0')}:${minutes24?.toString().padStart(2, '0')} ${ampm}`;
    }else{
      return null
    }
  }
  const [index,setIndex]=useState("")
  return (
    <>
      <div
        style={{ background: `${active ? "rgba(0, 0, 0, 0.628)" : "none"}` }}
        className={`new_order_editor ${show ? "active_3" : ""}`}
      >
        <div className={`side_bar_example`} ref={sidebarRef}>
          <div className="row d-flex  align-items-center">
            <div className="col-9 d-flex justify-content-start align-items-center">
              <button
                className="btn_close"
                onClick={() => {
                  setshow(false);
                  setActive(false);
                }}
              >
                X
              </button>
              <h4 className="new_order_head p-0 my-0 mx-2">Order Details</h4>
              <span className={getStatusClass(data)}>
                {determineOrderStatus(data)?.show}
              </span>
            </div>
            <div className="col-3">
              <h6 className="order_id m-0">
                Order ID:<span>{data?.order_number}</span>
              </h6>
            </div>
          </div>
          <hr />
          <div className="row d-flex align-items-center ">
            <div className="col-lg-4 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
                Order Date:
                <span>{data?.order_confirmed_date?.split(" ")[0]}</span>
              </h6>
            </div>
         
            <div className="col-lg-4 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
                Client Name:{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {data?.shippingCharges?.name
                    ?.split(" ")
                    .filter((item, index, arr) => arr.indexOf(item) === index)
                    .join(" ")}
                </span>
              </h6>
            </div>
            <div className="col-lg-4 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
                Client Contact:<span>{data?.shippingCharges?.contact}</span>
              </h6>
            </div>
            <div className="col-lg-4 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
                Client City:<span>{data?.shippingCharges?.city?.name ? data?.shippingCharges?.city?.name : "-"}</span>
              </h6>
            </div>
            <div className="col-lg-4 col-md-6 col-12 pe-0  ">
              <h6 className="order_id me-5">
                Location:
                <span>{data?.shippingCharges?.location}</span>
              </h6>
            </div>
    
    
          </div>
          {data?.orderService?.length!==1&&<>
          <hr />
            <div className="row">
            <div className="col-lg-5 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
              Upcoming Booking Date:  <span
                  className={`service-date ${statusColor}`}
                >
                  {latestServiceDate || "N/A"}
                </span>
              </h6>
            </div>
            <div className="col-lg-5 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
              Upcoming Booking Slot:<span>{latestSlot}</span>
              </h6>
            </div>
            </div>
          </>}
          <hr />

          <h5>Selected Services</h5>
          {data?.orderService?.map((item,i) => {
             const remainingDays1 = item?.service_date
             ? calculateRemainingDays(item?.service_date)
             : null;
           const statusColor1 = getStatusColor(remainingDays1);
           const options = { year: 'numeric', month: 'long', day: 'numeric' };
           formattedLatestServiceDate = new Date(item?.service_date).toLocaleDateString('en-US', options);
            return (
              <div className="row service_detail my-2">
                <div className="col-12 mb-2">
                  <div className="select_serv">
                    <div className="d-flex justify-content-start align-items-center">
                    <h6>{item?.plan?.type==="studio"?item?.studio?.name:item?.plan?.vendor_sub_service?.sub_service?.name}</h6>
                    <span style={{fontSize:"12px",marginLeft:"1rem",padding:".1rem .5rem"}} className={getStatusClass(item?.orderStatus?.status_code)}>
                      {item?.orderStatus?.status_name}
                    </span>
                    </div>
                   
                 
                    <div>
                      <p className="sel_time_name">
                        {formattedLatestServiceDate} | Vendor:{" "}
                        <Link to={`/profile/${item?.vendor?.username}/photographer`} style={{ textDecoration: "underline",color:"#494949" }}>
                          {/* Michele John */}
                          {item?.vendor?.name}
                        </Link>
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center ">
                      <ul className="sel_list">
                        
                        {item?.plan?.offers?.map((items) => {
                          if (items !== null) {
                            return (
                              <li>
                                <IoIosCheckmarkCircle />
                                {items}
                              </li>
                            );
                          }
                          return null
                        })}
                      </ul>
                      <p style={{whiteSpace:"nowrap"}} className="sel_price">£ {(item?.plan?.price_set*item?.quantity).toFixed(2)}</p>
                    </div>
                    <hr style={{ marginBottom: "7px" }} />
                    <div className="d-flex justify-content-start align-items-center">
                    
                    <h6 className="order_id mx-3">
                      Booking Date:  <span
                        className={`service-date ${statusColor1}`}
                      >
                        {item?.service_date}
                      </span>
                    </h6>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                      {item?.plan?.booking_service_type==="item_quantity"&&<h6 className="order_id p-0 m-0 me-3">
                      Number Of Items:<span style={{fontSize:"13px",fontWeight:"600"}}>{item?.quantity}X £{item?.plan?.price_set}</span>
                    </h6>}
                    {item?.slot?.start_time && item?.slot?.end_time?<h6 className="order_id p-0 m-0 me-3">
                      Booking Slot:<span>{convertTo12HourFormat(item?.slot?.start_time?item?.slot?.start_time:null)} to {convertTo12HourFormat(item?.slot?.end_time?item?.slot?.end_time:null)}</span>
                    </h6>:null}
                    {item?.previous_booking && <div style={{position:"relative"}}>
                       <button onClick={()=>{
                        if(i===index){
                          setIndex("")
                        }
                        else{
                          setIndex(i)
                        }
                       }} style={{border:"none",background:"#494949",color:"white",borderRadius:"5px",padding:".2rem",fontSize:"12px"}}>Reschedule</button>
                       {index===i&&<div style={{position:"absolute",width:"200px",height:"140px",border:"1px solid lightgray",borderRadius:"5px",bottom:"-150px",left:"-100px",background:"white",zIndex:"99",padding:".5rem"}}>
                        <h6 style={{fontWeight:"bold"}}>Previous Booking Detail</h6>
                        <hr className="m-0"/>
                        <p style={{fontSize:"13px"}} className="m-0 mt-2 d-flex justify-content-between"><span>Service date :</span><span>{new Date(item?.previous_booking?.service_date).toLocaleDateString('en-US', options)}</span></p>
                        <p style={{fontSize:"13px"}} className="m-0 mt-2 d-flex justify-content-between"><span>Start time:</span><span>{convertTo12HourFormat(item?.previous_booking?.start_time)}</span></p>
                        <p style={{fontSize:"13px"}} className="m-0 mt-2 d-flex justify-content-between"><span>End time:</span><span>{convertTo12HourFormat(item?.previous_booking?.end_time)}</span></p>
                       </div>}
                      </div>
                      }
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}


          <hr />
          <div className="row service_detail my-2">
            <div className="col-lg-7 col-12 my-2">
              {determineOrderStatus(data) === "Completed" && (
                <>
                  <h5>Review</h5>

                  <div className="d-flex align-items-center">
                    <span style={{ fontSize: "13px" }}>Your Review</span>
                    <div className="ms-2 d-flex align-items-center">
                     {Array.from({ length: 5 }, (_, index) => (
                        <div key={index} >
                          {index < rating?.rating ? (
                            <FaStar className="star_icons" /> // Render filled star if rating is greater than index
                          ) : (
                            <CiStar className="star_icons" />
                          )}
                        </div>
                      ))}

                    </div>

                    <span style={{ fontSize: "13px", paddingLeft: "7px" }}>
                      {rating?.rating ? rating?.rating : "0"}/5
                    </span>
                  </div>

                  <div>
                    <span style={{ fontSize: "13px" }}>Your Comments</span>
                  </div>
                  <div>
                    <span style={{ fontSize: "13px" }}>{rating?.client_comment ? rating?.client_comment : "Not Comments yet"}</span>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-4 col-12 my-2">
              <div className="row">
                <div className="col-9">
                  <h5>Total Services</h5>
                </div>
                <div className="col-2">
                  <span>{data?.orderService?.length}</span>
                </div>
              </div>
              <div className="row service_detail my-2">
                <div className="col-9">
                  <h5>Subtotal </h5>
                </div>
                <div className="col-2">
                  <h5 className="sub_total">£{parseFloat(data?.subtotal).toFixed(2)}</h5>
                </div>
              </div>
              <div className="row service_detail my-2">
                <div className="col-9">
                  <h5>VAT(20%)</h5>
                </div>
                <div className="col-2">
                  <h5 className="sub_total">£{parseFloat(data?.total - data?.subtotal).toFixed(2)}</h5>
                </div>
              </div>
              {/* <div className="row service_detail my-2">
                <div className="col-9">
                  <h5>Other</h5>
                </div>
                <div className="col-2">
                  <h5 className="sub_total">£12.00</h5>
                </div>
              </div> */}
              <div className="row service_detail my-2">
                <div className="col-9">
                  <h5>Total Amount</h5>
                </div>
                <div className="col-2">
                  <h5 className="sub_total">£{parseFloat(data?.total).toFixed(2)}</h5>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              {
                (determineOrderStatus(data)?.classname === "6D" || determineOrderStatus(data)?.classname === "PE" || determineOrderStatus(data)?.classname === "ZB") &&
                <p className="order_detail_foot">{determineOrderStatus(data)?.show} Date: <span>
                  15 April 2024
                </span> </p>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewOrderEdit;
