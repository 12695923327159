import React from "react";
import img1 from "../../asset/our-services-webp/Mask group.webp";
import img2 from "../../asset/our-services-webp/GF_2.gif";
import img3 from "../../asset/our-services-webp/GF_3.gif";
import img4 from "../../asset/our-services-webp/Gif_1.gif";
import img5 from "../../asset/our-services-webp/GF3.0.gif";
import videos from "../../asset/our-services-webp/3.5.mp4";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos";
function Banner() {
  AOS.init({
    duration: 800, // Animation duration
    easing: "ease-in-out", // Easing option
    once: false, // Whether animation should only happen once
  });
  return (
    <div
      className="our_service_banner our_portfolio mb-3 "
      style={{ marginTop: "2.6rem" }}
    >
      <button className='services_btn'
        style={{ 
          lineHeight: "19.2px",
        }}
      >
        Our photography services
      </button>
      <h1
        className="my-3 mt-2 our_service_banner_h1" 
      >
        Our photographers are keen observers to capture
        <br />a range of creative photography styles.
      </h1>
      <p

        style={{
          fontSize: "17px",
          color: "#494949",
          fontWeight: "400",
          lineHeight: "23.2px",
          marginBottom:'0',
        }}
        className="text-center our_services_des"
      >
        A picture is worth a thousand words. Harness the power of professional
        image creation with Photography Studio Hub. Our team of
        <br />
        experienced artists, photographers, and creative directors can tell your
        story, make your business memorable, and build your
        <br />
        brand online and in the physical world.
      </p>
      <div className="row" data-aos="fade-up">
        <div className="col-lg-3 col-md-12 col-12">
          <div className="row">
            <div className="col-lg-12 col-md-6 col-12 new_img-col">
              <div className="img serImg">
                <img className="error_img" src={img1} alt="" />
              </div>
            </div>
            <div className="col-lg-12 col-md-6 col-12 new_img-col">
              <div className="img serImg">
                <img src={img2} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-md-12 col-12">
          <div className="row">
            <div
              className="col-lg-8 col-md-12 col-12"
              style={{ padding: "0 .34rem 0 .2rem" }}
            >
              <div className="img serImg1">
                {/* <img src={img3} alt="" /> */}
                <video src={videos} loop muted autoPlay></video>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12 ">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-12 new_img-col-1">
                  <div className="img serImg">
                    <img src={img4} alt="" />
                  </div>
                </div>
                <div className="col-lg-12 col-md-6 col-12 new_img-col-1">
                  <div className="img serImg">
                    <img src={img5} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
