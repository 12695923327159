import React from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import Banner from '../Components/HomePage/Banner'
import OurPortfolio from '../Components/HomePage/OurPortfolio'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import Footer from '../Components/NavBarFooter/Footer'
import OurStudio from '../Components/HomePage/OurStudio'
import { useAuth } from '../Context/ContextProvider'

function Home() {
  const { homePgeLoading } = useAuth()
  return (
    <>
      <div className='container'>
        <NavBar />
        {homePgeLoading ?
          <div className='row'>
            <div className='col-6'>

              <p
                style={{ height: "60vh" }}
                className="placeholder-glow row m-0"
              >
                <span className="placeholder col-12 h-100 rounded"></span>
              </p>
            </div>

            <div className='col-6'>

              <p
                style={{ height: "60vh" }}
                className="placeholder-glow row m-0"
              >
                <span className="placeholder col-12 h-100 rounded"></span>
              </p>
            </div>
            <div className='col-12 mt-2'>

              <p
                style={{ height: "50px" }}
                className="placeholder-glow row m-0"
              >
                <span className="placeholder col-12 h-100 rounded"></span>
              </p>
            </div>
            <div className='col-12 mt-2'>

              <p
                style={{ height: "50px" }}
                className="placeholder-glow row m-0"
              >
                <span className="placeholder col-12 h-100 rounded"></span>
              </p>
            </div>
            <div className='col-12 mt-2'>

              <p
                style={{ height: "50px" }}
                className="placeholder-glow row m-0"
              >
                <span className="placeholder col-12 h-100 rounded"></span>
              </p>
            </div>



          </div>
          : <>
            <Banner />
            <OurStudio />


            <OurPortfolio />
          </>}


      </div>
      {!homePgeLoading && <JoinOurTeam />}
      <div className='container'>
        {!homePgeLoading && <Footer />}

      </div>
    </>
  )
}

export default Home