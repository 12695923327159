import React, { useEffect } from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import Banner from '../Components/ListTalent/Banner'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import Footer from '../Components/NavBarFooter/Footer'
import { useParams } from 'react-router-dom'
import { useAuth } from '../Context/ContextProvider'

function ListTalent() {
  const parms=useParams()
  const {getvendorList,
    vendorList}=useAuth()
  useEffect(()=>{
    getvendorList(parms.id)
    // eslint-disable-next-line
  },[])
  return (
    <>
    <div style={{overflowX:"hidden",position:"relative"}}>
    <div className='container'>
        <NavBar/>
        <Banner service_id={vendorList?.service_id} data={vendorList?.vendors}/>
    </div>
    <JoinOurTeam/>
    <div className='container'>
        <Footer/>
    </div>
    </div>
    </>
  )
}

export default ListTalent