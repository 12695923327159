import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import img1 from '../asset/icons/google.png'
import img2 from '../asset/icons/facebook.png'
// import img3 from '../asset/icons/twitter.png'                                                                                                                                                                 
import logo from '../asset/logo (1).png'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import img from '../asset/Group.png'
import { useState } from 'react';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import { useAuth } from '../Context/ContextProvider';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import CustomizedSnackbars from '../Components/NavBarFooter/CustomizedSnackbars';

const defaultTheme = createTheme();
export default function ClinetSignUp({ app_id, client_id }) {
  const { clientRegister,
    clientRegisterData,
    socailLogin,
    web_url
  } = useAuth()
  const [data, setdata] = useState({
    name: "",
    email: "",
    phone_number: "",
    state_id: "",
    city_id: "",
    address: "",
    password: "",
    password_confirmation: "",
    role: "client"
  })
  // eslint-disable-next-line
  const [fieldErrors, setFieldErrors] = useState({})
  const validateStep = () => {
    let errors = {

    };

    if (!data.name) {
      errors.name = "Name is required.";
    }
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!data.phone_number) {
      errors.phone_number = "Contact is required.";
    } else if (!isValidUKPhoneNumber(data.phone_number)) {
      errors.phone_number = "Please enter a valid UK phone number.";
    }
    if (!data.password) {
      errors.password = "Password is required.";
    } else if (data.password.length < 8 || data.password.length > 10) {
      errors.password = "Password must be between 6 and 10 characters long.";
    } else if (!hasSpecialCharacter(data.password)) {
      errors.password = "Password must contain at least one special character.";
    }
    if (!data.password_confirmation) {
      errors.password_confirmation = "Confirm Password is required.";
    } else if (data.password !== data.password_confirmation) {
      errors.password_confirmation = "Passwords do not match.";
    }


    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = () => {
    if (validateStep()) {
      let phone = data?.phone_number
      clientRegister({ ...data, phone_number: "44" + phone?.replace(/\D/g, '') })
      // Proceed with the submission
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidUKPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber.replace(/\D/g, '')

    // Regular expression for UK phone number validation
    const ukPhoneRegex = /^(?:0|\+44)?\s?(?:\d\s?){9,10}$/;
    // Check if the phone number is a valid UK phone number or its length is 11 or 13 characters

    return ukPhoneRegex.test(phone) && (phone.length === 10 || phone.length === 13);
  };
  const hasSpecialCharacter = (password) => {
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    return specialCharacterRegex.test(password);
  };
  const handleChange = (e) => {
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/\D/g, '');
    // Format the phone number as "7700 900886"
    const firstPart = value.slice(0, 4);
    const secondPart = value.slice(4);
    let formattedValue = `${firstPart}`;
    if (secondPart.length > 0) {
      formattedValue += ` ${secondPart}`;
    }
    // Update the state with the formatted value
    setdata({ ...data, phone_number: formattedValue });
  };
  const [show, setshow] = useState(true)
  const [show1, setshow1] = useState(true)
  const navigate = useNavigate()
  React.useEffect(() => {
    if (clientRegisterData?.success) {
      navigate(`${web_url}clinet-signin`)

    }
    // eslint-disable-next-line
  }, [clientRegisterData])


  const handleLoginSuccessF = (user) => {

    let obj = {
      name: user?.name,
      email: user?.email,
      provider_id: user?.id,
      provider: user?.graphDomain,
      avatar: user?.picture?.data?.url
    }
    socailLogin(obj)
    navigate(`${web_url}clinet-signin`)
  };
  const handleLoginSuccessG = (user) => {

    let obj = {
      name: user?.name,
      email: user?.email,
      provider_id: user?.sub,
      provider: "google",
      avatar: user?.picture
    }

    socailLogin(obj)
    navigate(`${web_url}clinet-signin`)
  };


  const handleLoginFailure = (error) => {
    console.log('Login Failed:', error);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid component="main" className='login_main' style={{ background: "#ECECEC", overflowX: "hidden" }}>
        <div className='row' >
          <div style={{ background: "white" }} className='col-lg-5 col-md-6 col-12 px-5 client_signUp '>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div className='d-flex justify-content-center '>
                <Link to={`${web_url}`}>
                  <img style={{ width: "130px" }} src={logo} alt='' />
                </Link>
              </div>
              <Box sx={{ width: "100%" }} className="upload">
                <div className='d-flex justify-content-between align-items-center'>
                  <h4 className='mt-2' style={{ fontSize: "24px", fontWeight: "400", lineHeight: "24.8px", color: "#494949" }}>Welcome</h4>
                  <span style={{ color: "#494949", fontSize: "14px" }}>Already have an account?<Link style={{ textDecoration: "none", color: "#3665C5", marginLeft: ".1rem" }} to={`${web_url}clinet-signin`}>Sign In</Link></span>
                </div>
                <h6 >Sign Up To Continue</h6>
                <div className=' mt-2'>
                  <div style={{ position: "relative" }}>
                    <span className="text-success" style={{ fontSize: "13px", position: "absolute" }}>{clientRegisterData?.message}</span>
                    <label htmlFor="" className="new_order_label ">Full Name</label>
                    <div className='upload_title mt-1' style={{ marginTop: "1.5rem" }}>

                      <input onChange={(e) => setdata({ ...data, name: e.target.value })} placeholder='Full Name' maxlength="40" name="full_name" />
                    </div>
                    {fieldErrors?.name && <span className="error_text" style={{ fontSize: "13px", position: "absolute" }}>{fieldErrors?.name}</span>}
                  </div>
                  <div style={{ position: "relative" }}>
                    <label htmlFor="" className="new_order_label mt-3">Contact</label>
                    <div className='upload_title d-flex align-items-center  p-0 mt-1'  >

                      <div className='profile_contacts ps-0' style={{ padding: "10px" }}>
                        <img style={{ width: "30px", height: "20px" }} src='https://flagcdn.com/w320/gb.png' alt='...' />
                        <span className='mx-2'>+44</span>
                      </div>
                      <input
                        type="text"
                        value={data?.phone_number}
                        onChange={handleChange}
                        maxLength={10}
                        placeholder="e.g 7700 900886"
                        pattern="[0-9]{4} [0-9]{6}"
                        className="inp"

                      />

                    </div>
                    {fieldErrors?.phone_number && <span className="error_text" style={{ fontSize: "13px", position: "absolute" }}>{fieldErrors?.phone_number}</span>}
                    {clientRegisterData?.phone_number && <span className="error_text" style={{ fontSize: "13px", position: "absolute" }}>{clientRegisterData?.phone_number[0]}</span>}
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  <label htmlFor="" className="new_order_label mt-3">Email</label>
                  <div className='upload_title mt-1' style={{ marginTop: "1.5rem" }}>
                    <input type="email" onChange={(e) => setdata({ ...data, email: e.target.value })} placeholder='Email' name="email" />

                  </div>
                  {fieldErrors?.email && <span className="error_text" style={{ fontSize: "13px", position: "absolute" }}>{fieldErrors?.email}</span>}
                  {clientRegisterData?.email && <span className="error_text" style={{ fontSize: "13px", position: "absolute" }}>{clientRegisterData?.email[0]}</span>}
                </div>
                <div style={{ position: "relative" }}>
                  <label htmlFor="" className="new_order_label mt-3">Password</label>
                  <div className='upload_title mt-1' style={{ marginTop: "1.5rem" }}>
                    <input type={`${show1 ? "password" : "text"}`} style={{ width: "92%", margin: "0" }} onChange={(e) => setdata({ ...data, password: e.target.value })} placeholder='Password' name="password" />
                    <button style={{ background: "none", border: "none" }} onClick={() => setshow1(!show1)}>{show1 ? <FaRegEye style={{ color: "gray" }} /> : <FaRegEyeSlash style={{ color: "gray" }} />}</button>
                  </div>
                  {fieldErrors?.password && <span className="error_text" style={{ fontSize: "13px", position: "absolute" }}>{fieldErrors?.password}</span>}
                </div>
                <div style={{ position: "relative" }}>
                  <label htmlFor="" className="new_order_label mt-3">Confirm Password</label>
                  <div className='upload_title mt-1' style={{ marginTop: "1.5rem" }}>
                    <input style={{ width: "92%", margin: "0" }} type={`${show ? "password" : "text"}`} onChange={(e) => setdata({ ...data, password_confirmation: e.target.value })} placeholder='Confirm Password' name="password_confirmationword" />
                    <button style={{ background: "none", border: "none" }} onClick={() => setshow(!show)}>{show ? <FaRegEye style={{ color: "gray" }} /> : <FaRegEyeSlash style={{ color: "gray" }} />}</button>
                  </div>
                  {fieldErrors?.password_confirmation && <span className="error_text" style={{ fontSize: "13px", position: "absolute" }}>{fieldErrors?.password_confirmation}</span>}
                </div>

                <button onClick={handleSubmit} className='sign_btn my-4'>SIGN UP</button>
                <div className='d-flex justify-content-center my-3'>
                  <span style={{ color: "#B2B2B2", fontSize: "13px" }}>Or Signup with </span>
                </div>
                <div className='d-flex justify-content-center'>

                  {client_id && <LoginSocialGoogle
                    client_id={client_id}
                    onResolve={({ provider, data }) => {
                      handleLoginSuccessG(data);
                    }}
                    onReject={(error) => {
                      console.error('Login Failed:', error);
                      handleLoginFailure(error);
                    }}
                    scope="profile email"
                  >
                    <button style={{ background: "none", border: "none" }}><img className='mx-2' src={img1} alt='' /></button>
                  </LoginSocialGoogle>}


                  {app_id && <LoginSocialFacebook
                    appId={app_id} // Replace with your actual Facebook App ID
                    onResolve={({ provider, data }) => handleLoginSuccessF(data)}
                    onReject={handleLoginFailure}
                  >
                    <button style={{ background: "none", border: "none" }}>
                      <img className='mx-2' src={img2} alt='' />
                    </button>
                  </LoginSocialFacebook>}
                </div>
              </Box>
            </Box>
          </div>
          <div
            className='col-lg-7 col-md-6 col-12 p-5'
            style={{
              border: "1px solid",
              position: "relative",
              // background: "#1c1c1ced",
              height: "auto", // Use auto to adjust height based on child content
            }}
          >
            {/* Image should not be positioned absolutely if you want the parent to grow */}
            <img
              className='w-75'
              style={{
                filter: "grayscale(100%)",
                position: "absolute",
                width: "100%", // Adjust width and height for full coverage
                objectFit: "cover",
              }}
              src={img}
              alt='...'
            />
            {/* Ensure this div is not absolute */}
            <div style={{
              position: "absolute", // Relative to parent, not removed from flow
              height: "100%",
              width: "100%",
              left: "0",
              top: "0",
              background: "#1c1c1ced",
            }}></div>
            <div
              style={{
                position: "relative", // Relative to parent, not removed from flow
                height: "100%",
                width: "100%",
                left: "0",
                top: "0",
                // background: "#1c1c1ced",
              }}
            >
              <div style={{ color: "white", height: "100%", padding: "1rem 5rem" }}>
                <h1 style={{ textTransform: "capitalize", fontSize: "48px" }}>
                  let us be your partner <br /> to capture your perfect <br /> moments
                </h1>
                <ul className='sign_up_ul'>
                  <li>E-Commerce Photography</li>
                  <li>Model Portfolio Photography</li>
                  <li>Headshot Photography</li>
                  <li>Ghost Mannequin photography</li>
                  <li>Flat lay Photography</li>
                  <li>Group session Photography</li>
                  <li>Family & Baby session photography</li>
                  <li>Real Estate Photography</li>
                </ul>
                <Link to={`${web_url}`}>
                  <button
                    style={{ border: "1px solid white", background: "none" }}
                    className='sign_btn'
                  >
                    HOME PAGE
                  </button>
                </Link>
              </div>
            </div>
          </div>

        </div>
        <CssBaseline />
        <CustomizedSnackbars />
      </Grid>
    </ThemeProvider>
  );
}
