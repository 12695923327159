import React from "react";
import img from "../../asset/icons/enquiry.png";
import enq_img from "../../asset/studio/Rectangle 1587.png";
import EnquiryModal from "./EnquiryModal";

function Enquery({
  service_id,
  service_name,
  vendor_id,
  serviceable_type,
  ser_type,
}) {


  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  return (
    <>
      <div className="row ">
        <div className="col-12 my-2 d-flex justify-content-center">
          {/* <div className="enquiry_bar">
            <p className="enquiry_text_frst">
              <span>
              <img src={img} alt="" />
              or enquire us to communicate with you
              </span>
              
            </p>
            <p className="enquiry_text">
             
            
            </p>
          </div> */}
          <div class="marquee-w">
            <div class="marquee">
              <span>
                <img src={img} alt="" />
                or enquire us to communicate with you
              </span>
            </div>
            <div class="marquee marquee2 ">
              <span>
                <img src={img} alt="" />
                or enquire us to communicate with you
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row  ">
        <div className=" col-12 ">
          <div className="enquiry_submit " style={{position:"relative"}}>
            <img src={enq_img} alt="" style={{position:"absolute", width:"100%", height:"100%", objectFit:"cover"}}/>
            <div className="enquiry_background">

            </div>

            <div style={{position:"absolute"}} className="px-4 w-100 d-flex align-items-center flex-column">


            <h3 className="text-center">
            Have a Custom Request? <br/> Let’s Talk!
            </h3>
            <p className="text-center">
            Not sure which package suits your needs? Send us your details, and we'll tailor the<br/> perfect solution for you.
            </p>
            {/* =========================== submit button========= */}

            <button  onClick={() => handleOpen3()}  className="view_all_btn EnquiryBtn my-3">
            Submit Your Inquiry
             
            </button>
            </div>
          </div>
        </div>
        <EnquiryModal  service_id={service_id}
  service_name={service_name}vendor_id={vendor_id}serviceable_type={serviceable_type}
  ser_type={ser_type} open3={open3} handleClose3={handleClose3}/>
     
       
      </div>
    </>
  );
}

export default Enquery;
