import * as React from 'react';
import Box from '@mui/material/Box';
import { Routes ,Route, Link} from 'react-router-dom';
import NewOrder from '../Pages/NewOrder';
import CompleteOrder from '../Pages/CompleteOrder';
import ConfirmOrder from '../Pages/ConfirmOrder';
import RejectOrder from '../Pages/RejectOrder';
import VendorPorfolio from '../Pages/VendorPorfolio';
import ProfileSetting from '../Pages/ProfileSetting';
import Priceing from '../Pages/Priceing';
import NavBar from '../../Components/NavBarFooter/NavBar';
import Dashboard from '../Pages/Dashboard';
import ChangePassword from '../Pages/ChangePassword';
import { IoMdNotifications } from 'react-icons/io';
import Notification from '../Pages/Notification';
import { MdDashboard } from 'react-icons/md';
import completeOrder from "../../asset/icons/completeOrder.png";
import cancelOrder from "../../asset/icons/cancelOrder.png";
import profileSetting from "../../asset/icons/ProfileSetting.png";
import changePasward from "../../asset/icons/changePasward.png";
import { useAuth } from '../../Context/ContextProvider';
import CurrentDate from '../../Pages/CurrentDate';
export default function SideBar() {
  const data=JSON.parse(localStorage.getItem("vendor_data"))
  const {web_url}=useAuth()
  return (
<>
    <div className='container' style={{position:"relative"}}>
    <NavBar/>
    </div>
    <div style={{background:"#F6F8F9",overflowX:"hidden",position:"relative",overflowY:"hidden"}}>
      <div className='container'>
        <div className='mx-4 my-3'>
        <h3 style={{fontSize:"24px", fontWeight:"600",color:'#2E2E30' }}>Hi {data?.data?.user?.name}!</h3>
        {/* <p style={{color:"#808080",fontSize:"14px"}}>Thursday, 23 November, 2023</p> */}
        <CurrentDate/>
        </div>
    <div className='row' >
      <div  className='col-9 page_side'>
      <Box
        component="main"
        sx={{ flexGrow: 1}}
      >
        <div style={{paddingLeft:"1rem" }} className='clientDashboard dashbord_main pt-0'>
        <Routes>
          <Route path="/pending-order" element={<NewOrder/>}/>
          <Route path="/completed-order" element={<CompleteOrder/>}/>
          <Route path="/confirmed-order" element={<ConfirmOrder/>}/>
          <Route path="/reject-order" element={<RejectOrder/>}/>
          <Route path="/portfolio-setting" element={<VendorPorfolio/>}/>
          <Route path="/profile-setting" element={<ProfileSetting/>}/>
          <Route path="/pricing-setting" element={<Priceing/>}/>
          <Route path="/dashboard" element={<Dashboard/>}/>
          <Route path="/password-setting" element={<ChangePassword/>}/>
          <Route path="/notification" element={<Notification/>}/>
        </Routes>
           </div>
      </Box>
      </div>
      <div className='col-3 px-3' style={{border:"none", height:"95vh"}}>
      <div  style={{height:"100%",background:"white",border:"1px solid lightgray", boxShadow: "0px 0px 4px 0px #00000040"
}}>
      <ul className='side_ul ' style={{margin:"19px 25px"}}>
              <li><Link to={`${web_url}client/dashboard`} className='link_sidebar'><MdDashboard className='me-3'/>Dashboard</Link>  </li>
              <li><Link to={`${web_url}client/completed-order`} className='link_sidebar'><img src={completeOrder} alt="" className='me-3'/> Completed Order</Link>  </li>
              <li><Link to={`${web_url}client/confirmed-order`} className='link_sidebar'><img src={cancelOrder} alt="" className='me-3'/>Confirm Order</Link>  </li>
              <li><Link to={`${web_url}client/pending-order`} className='link_sidebar'><img src={cancelOrder} alt="" className='me-3'/>Pending Order</Link>  </li>
              <li><Link to={`${web_url}client/reject-order`} className='link_sidebar'><img src={cancelOrder} alt="" className='me-3'/>Cancel Order</Link>  </li>
              <li><Link to={`${web_url}client/profile-setting`} className='link_sidebar'><img src={profileSetting} alt="" className='me-3'/>Profile Setting</Link>  </li>
              <li><Link  to={`${web_url}client/password-setting`} className='link_sidebar'><img src={changePasward} alt="" className='me-3'/>Change Password</Link>  </li>
              <li><Link  to={`${web_url}client/notification`} className='link_sidebar'><IoMdNotifications  className='me-3'/>Notification</Link>  </li>
              
            </ul>
        </div>
      </div>
    </div>
      </div>
    </div>
</>
  );
}