import React from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import Banner from '../Components/ContactUs/Banner'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import Footer from '../Components/NavBarFooter/Footer'
import ContactDetail from '../Components/ContactUs/ContactDetail'

function ContactUs() {
  return (
    <>
    <div className='container'>
        <NavBar/>
        <Banner/>
        </div>
        <ContactDetail/>
        <JoinOurTeam />
    <div className='container'>
        <Footer/>
    </div>
    </>
  )
}

export default ContactUs