import React, { useEffect, useState } from "react";
import photo1 from "../../asset/home-portfolio/photography/Rectangle 1880.png";
import photo2 from "../../asset/home-portfolio/photography/Rectangle 1881.png";
import photo3 from "../../asset/home-portfolio/photography/Rectangle 1882.png";
import photo4 from "../../asset/home-portfolio/photography/Rectangle 1883.png";
import photo5 from "../../asset/home-portfolio/photography/Rectangle 1884.png";
import photo6 from "../../asset/home-portfolio/photography/Rectangle 1885.png";
import photo7 from "../../asset/home-portfolio/photography/Rectangle 1886.png";
import photo8 from "../../asset/home-portfolio/photography/Rectangle 1887.png";
import stud1 from "../../asset/home-portfolio/studio/Rectangle 1888.png";
import stud2 from "../../asset/home-portfolio/studio/Rectangle 1889.png";
import stud3 from "../../asset/home-portfolio/studio/Rectangle 1890.png";
import stud4 from "../../asset/home-portfolio/studio/Rectangle 1891.png";
import stud5 from "../../asset/home-portfolio/studio/Rectangle 1892.png";
import stud6 from "../../asset/home-portfolio/studio/Rectangle 1893.png";
import stud7 from "../../asset/home-portfolio/studio/Rectangle 1894.png";
import stud8 from "../../asset/home-portfolio/studio/Rectangle 1895.png";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos";
import { useAuth } from "../../Context/ContextProvider";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "react-photo-album/rows.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import PortofiloCarsuol from "./PortofiloCarsuol";
function OurPortfolio() {
  const { home } = useAuth();
  const [images, setImages] = useState([
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
  ]);

  const shuffleImages = (arr) => {
    return arr.sort(() => Math.random() - 0.5);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setService((prevImages) => shuffleImages([...prevImages]));
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const [images1, setImages1] = useState([
    stud1,
    stud2,
    stud3,
    stud4,
    stud5,
    stud6,
    stud7,
    stud8,
  ]);

  const shuffleImages1 = (arr) => {
    return arr.sort(() => Math.random() - 0.5);
  };

  useEffect(() => {
    const interval1 = setInterval(() => {
      setStudio((prevImages1) => shuffleImages1([...prevImages1]));
    }, 4000);

    return () => clearInterval(interval1);
  }, []);

  const [tab, setTab] = useState(0);
  AOS.init({
    duration: 800, // Animation duration
    easing: "ease-in-out", // Easing option
    once: false, // Whether animation should only happen once
  });
  const [studio, setStudio] = useState([])
  const [service, setService] = useState([])
  useEffect(() => {
    let array = []
    let array1 = []
    if (home?.length) {
      home[0]?.sections[3]?.section?.map((item) => {
        if (item?.caption === "photography") {
          array.push(item.image)
        }
        else {
          array1.push(item.image)
        }
      })
      setService(array)
      setStudio(array1)
    }

  }, [home])
  const breakpoints = [3840, 1920, 1080, 640, 384, 256, 128];
  const [index, setIndex] = React.useState(-1);
  const [index1, setIndex1] = React.useState(-1);

  function assetLink(url, width) {
    return `${url}?w=${width}&q=75`;
  }
  let imagesArray = [];
  let imagesArray1 = [];

  // Step 2: Filter and transform images based on the selected service_id
  home[0]?.sections[3]?.section?.forEach((item) => {
    if (item?.caption === "photography") {
      imagesArray.push({
        asset: item?.image?.url,
        width: 3840,
        height: 3560,
      })
    }
    else {
      imagesArray1.push({
        asset: item?.image?.url,
        width: 3840,
        height: 3560,
      })
    }
  });

  // Step 3: Create slides array for displaying in the photo album
  const slides = imagesArray?.map(({ asset, width, height }) => ({
    src: assetLink(asset, width),
    width,
    height,
    srcSet: breakpoints?.map((breakpoint) => ({
      src: assetLink(asset, breakpoint),
      width: breakpoint,
      height: Math.round((height / width) * breakpoint),
    })),
  }));
  const slides1 = imagesArray1?.map(({ asset, width, height }) => ({
    src: assetLink(asset, width),
    width,
    height,
    srcSet: breakpoints?.map((breakpoint) => ({
      src: assetLink(asset, breakpoint),
      width: breakpoint,
      height: Math.round((height / width) * breakpoint),
    })),
  }));
  return (
    <div className="our_portfolio home_portfolio mt-4 mb-5 d-block text-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <button className="home_portfio_head mt-lg-5"

        >
          Our Portfolio
        </button>
        {

          home[0]?.sections[3]?.description ?
            <>
              <h1 className="secfour_heading" dangerouslySetInnerHTML={{
                __html:
                  home[0]?.sections[3]?.description.split("</h1>")[0] + "</h1>",
              }}>
              </h1>
              <p
                className="secTwo_para mb-2"
                dangerouslySetInnerHTML={{
                  __html: home[0]?.sections[3]?.description
                    .split("</h1>")[1]
                    ?.trim(),
                }}
              ></p>

            </>
            : ""
        }
        <div className="d-flex justify-content-center mb-1 mt-3">
          <button
            onClick={() => setTab(0)}
            className={`mx-2 homepage_portfolio ${tab === 0 ? "home_dark" : "home_light"
              }`}
          >
            All
          </button>
          <button
            onClick={() => setTab(1)}
            className={`mx-2 homepage_portfolio ${tab === 1 ? "home_dark" : "home_light"
              }`}
          >
            Photogarphy
          </button>
          <button
            onClick={() => setTab(2)}
            className={`mx-2 homepage_portfolio ${tab === 2 ? "home_dark" : "home_light"
              }`}
          >
            Studio Spaces
          </button>
        </div>
      </div>
      <div style={{ marginBottom: '4.5rem' }}>

        {tab === 0 && (
          <>
            <div className="row home_pot">
              {service?.slice(0, 4).map((image, index) => (
                <div
                  onClick={() => { setIndex(index) }}
                  className="col-lg-3 col-md-4  col-12 my-2 "
                  data-aos={index % 2 === 0 ? "fade-up" : "fade-down"}
                  key={index}
                >
                  <div className="image_main">
                    <img
                      style={{ width: "100%" }}
                      src={image?.url}
                      alt={`img-${index}`}
                    />
                  </div>
                </div>
              ))}

            </div>
            <div className="row home_pot my-1">
              {studio?.slice(0, 4).map((image, index) => (
                <div
                  onClick={() => { setIndex1(index) }}
                  className="col-lg-3 col-md-4 col-12 my-2 "
                  data-aos={index % 2 === 0 ? "fade-down" : "fade-up"}
                  key={index}
                >
                  <div className="image_main">
                    <img
                      style={{ width: "100%" }}
                      src={image?.url}
                      alt={`img-${index}`}
                    />
                  </div>
                </div>
              ))}

            </div>
            <PortofiloCarsuol image={home[0]?.sections[3]?.section?.length ? home[0]?.sections[3]?.section : []} />
          </>
        )}
        {tab === 1 && (
          <>
            <div className="row home_pot">
              {service?.map((item, idx) => {
                return (
                  <div className="col-lg-3  col-12 my-2" data-aos="fade-down">
                    <div onClick={() => { setIndex(idx) }} className="image_main">
                      <img style={{ width: "100%" }} src={item?.url} alt="..." />
                    </div>
                  </div>
                )
              })}
            </div>
            <PortofiloCarsuol
              image={
                home[0]?.sections[3]?.section?.length
                  ? home[0]?.sections[3]?.section.filter((item) => item?.caption === "photography")
                  : []
              }
            />

          </>
        )}
        {tab === 2 && (
          <>
            <div className="row home_pot">
              {studio?.map((item) => {
                return (
                  <div className="col-lg-3  col-12 my-2" data-aos="fade-down">
                    <div onClick={() => { setIndex1(index) }} className="image_main">
                      <img style={{ width: "100%" }} src={item?.url} alt="..." />
                    </div>
                  </div>
                )
              })}
              {/* <div className="col-lg-3  col-12 my-2" data-aos="fade-down">
              <div className="image_main">
                <img style={{ width: "100%" }} src={stud1} alt="..." />
              </div>
            </div>
            <div className="col-lg-3  col-12 my-2" data-aos="fade-up">
              <div className="image_main">
                <img style={{ width: "100%" }} src={stud2} alt="..." />
              </div>
            </div>
            <div className="col-lg-3  col-12 my-2" data-aos="fade-down">
              <div className="image_main">
                <img style={{ width: "100%" }} src={stud3} alt="..." />
              </div>
            </div>
            <div className="col-lg-3  col-12 my-2" data-aos="fade-up">
              <div className="image_main">
                <img style={{ width: "100%" }} src={stud4} alt="..." />
              </div>
            </div>
            <div className="col-lg-3  col-12 my-2" data-aos="fade-up">
              <div className="image_main">
                <img style={{ width: "100%" }} src={stud5} alt="..." />
              </div>
            </div>
            <div className="col-lg-3  col-12 my-2" data-aos="fade-down">
              <div className="image_main">
                <img style={{ width: "100%" }} src={stud6} alt="..." />
              </div>
            </div>
            <div className="col-lg-3  col-12 my-2" data-aos="fade-up">
              <div className="image_main">
                <img style={{ width: "100%" }} src={stud7} alt="..." />
              </div>
            </div>
            <div className="col-lg-3  col-12 my-2" data-aos="fade-down">
              <div className="image_main">
                <img style={{ width: "100%" }} src={stud8} alt="..." />
              </div>
            </div> */}
            </div>
            <PortofiloCarsuol
              image={
                home[0]?.sections[3]?.section?.length
                  ? home[0]?.sections[3]?.section.filter((item) => item?.caption === "studio")
                  : []
              }
            />
          </>
        )}
        <Lightbox
          plugins={[Counter, Fullscreen, Slideshow, Zoom, Thumbnails]}
          index={index}
          slides={slides ? slides : []}
          open={index >= 0}
          close={() => setIndex(-1)} // Close the lightbox
        />
        <Lightbox
          plugins={[Counter, Fullscreen, Slideshow, Zoom, Thumbnails]}
          index={index1}
          slides={slides1 ? slides1 : []}
          open={index1 >= 0}
          close={() => setIndex1(-1)} // Close the lightbox
        />
      </div>
    </div>
  );
}

export default OurPortfolio;
