import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import img from '../../../asset/explorTelent/Vector.svg'
import { AiFillCloseCircle } from 'react-icons/ai';
import { useAuth } from '../../../Context/ContextProvider';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
};
function CancelModal({ cancelAble, handleClose, open, orderIds, getOrder, pagination }) {
  const { changeOrderStatus } = useAuth()
  const [reason, setReason] = useState()
  const [fieldErrors, setFieldErrors] = useState();
  const validateStep = () => {
    let errors = {};
    if (!reason) {
      errors = "Reason is required.";
    }
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const handleSendRequest = () => {
    if (validateStep()) {
      changeOrderStatus({
        id: orderIds?.order_id,
        id_service: orderIds?.order_service_id,
        reason: reason,
        order_status_code: "PE"
      }, pagination);
      handleClose();
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {cancelAble ? <div className='cancel_order_Model upload'>
            <div className='d-flex justify-content-between align-items-center'>
              <h5 className='reason_cancel'>Reason for cancel order</h5>
              <button onClick={handleClose} className='close_btn'><AiFillCloseCircle style={{ fontSize: "2rem" }} /></button>
            </div>
            <hr />
            <div className='' style={{ position: "relative" }}>
              <label htmlFor="" className="new_order_label">
                Why you want to cancel the order
              </label>
              <div className="upload_title mt-1" >
                <textarea onChange={(e) => setReason(e.target.value)} style={{ width: "100%", resize: "none", outline: "none", border: "none" }} name="" id="" rows={5} placeholder='Reason'></textarea>
              </div>
              {fieldErrors && (
                <span
                  className="error_text"
                  style={{
                    fontSize: "13px",
                    top: "100%",
                    position: "absolute",
                  }}
                >
                  {fieldErrors}
                </span>
              )}
            </div>
            <div className='model_button mt-4 d-flex justify-content-between'>
              <button onClick={() => { handleSendRequest() }} className='yes_btn'>Send Request</button>
              <button onClick={handleClose} className='no_btn'>Cancel</button>
            </div>
          </div> : <div className='cancel_order_Model'>
            <div className='d-flex justify-content-between'>
              <h5>Order Cancel</h5>
              <button onClick={handleClose} className='close_btn'><AiFillCloseCircle style={{ fontSize: "2rem" }} /></button>
            </div>
            <hr />
            <div className='model_content'>
              <img src={img} alt='...' />
              <h4 className='text-center'>You are not be<br />able to cancel the order !</h4>
            </div>
            <div className='model_button mt-3 d-flex justify-content-end'>
              <button onClick={handleClose} className='no_btn'>Cancel</button>
            </div>
          </div>}
        </Box>
      </Modal>
    </div>
  )
}

export default CancelModal
