import React from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import Banner from '../Components/AboutPage/Banner'
import Counter from '../Components/AboutPage/Counter'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import Footer from '../Components/NavBarFooter/Footer'
import AboutTalent from '../Components/AboutPage/AboutTalent'

function Aboutus() {
  return (
    <>
  <div className='container'>
    <NavBar/>
    <Banner/>
  </div>
  <div className="container-fluid p-0">
  <Counter/>
  </div>
  <div className="container">
    <AboutTalent />
  </div>
   <JoinOurTeam/>
  <div className='container'>
   <Footer/>
  </div>
    </>
  )
}

export default Aboutus