import React from 'react'
import img from '../../asset/detail/03-16-39-160_512.webp'
function Loading() {
  return (
    <div className='loading_section'>
        <img style={{width:"200px"}} src={img} alt='...'/>
    </div>
  )
}

export default Loading