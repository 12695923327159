import React, { useEffect } from 'react'
import SideBar from './SideBar'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../Context/ContextProvider'
function VenderPannel() {
  const {web_url}=useAuth()
  const vendor_data=JSON.parse(localStorage.getItem('vendor_data'))
  const role=vendor_data?.data?.role
  const navigate=useNavigate()
  useEffect(()=>{
    if(!vendor_data?.data?.token || role==="client"){
      navigate(web_url)
    }
  },[])
  return (
    <>
   {vendor_data?.data?.token &&<>
    {role==="vendor" || role==="vendor-admin"?<div><SideBar/></div>:""

    }
    </>}
    </>
    
  )
}

export default VenderPannel