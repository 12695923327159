import React, { useState, useEffect } from 'react';

function getDate() {
    const today = new Date();
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const day = dayNames[today.getDay()];
    const date = today.getDate();
    const month = monthNames[today.getMonth()];
    const year = today.getFullYear();
  
    // Format the date as Day, Date Month, Year
    return `${day}, ${date} ${month}, ${year}`;
  }
function CurrentDate() {

    const [crrntDate, setCrrntDate] = useState('');

  useEffect(() => {
    const date = getDate();
    setCrrntDate(date);
  }, []);
  return (
    <div>
      <div>
      <p className="m-0 new_order_nav_p"> {crrntDate}</p>
    </div>
    </div>
  )
}

export default CurrentDate
