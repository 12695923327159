import React, { useEffect, useState } from "react";
import image2 from "../../asset/icons/week_icon.png";
import AvailabilityMUI from "./AvailabilityMUI";
import { Button, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { AiFillQuestionCircle } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAuth } from "../../Context/ContextProvider";
import VerificationModal from "./VerificationModal";
import availab from "../../asset/icons/availability.png";
import { IoMdArrowDropdown } from "react-icons/io";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { Select, ListSubheader } from "@mui/material";
import moment from "moment-timezone";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

function Availability({ permission }) {
  function MyListSubheader(props) {
    return <ListSubheader {...props} />;
  }

  MyListSubheader.muiSkipListHighlight = true;

  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const handleOpen = (action) => {
    setCurrentAction(() => action);
    setOpen(true);
  };
  const [anchorElUser1, setAnchorElUser1] = React.useState(null);
  const handleOpenUserMenu1 = (event) => {
    setAnchorElUser1(event.currentTarget);
  };
  const handleCloseUserMenu1 = () => {
    setAnchorElUser1(null);
  };
  const [change, setChange] = useState(false);
  const formatDate = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1; // January is 0!
    let year = date.getFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return `${year}-${month}-${day}`;
  };
  const {
    stateData,
    getState,
    setCities,
    cities,
    getStudio,
    studioData,
    vendorServices,
    updateslot,
    vedorServiceData,
    createSlot,
    availDays,
    getCities,
    buttonLoding,
    availbityLoad,

  } = useAuth();
  const [planData, setPlanData] = useState()
  const [result, setResult] = useState([]);
  const [result1, setResult1] = useState([]);
  const validateAvailability = (availability) => {
    const errors = {};
    if (
      !availability.minimum_appointment_time ||
      availability.minimum_appointment_time === "00"
    ) {
      errors.minimum_appointment_time = "Booking before time is required";
    }
    if (availability?.cancellation_policy === "Partial Refund" && (!availability.cancellation_time || availability.cancellation_time === "00")) {
      errors.cancellation_time = "Canellation time is required";
    }
    if (availability?.rescheduling && (!availability.rescheduling_time || availability.rescheduling_time === "00")) {
      errors.rescheduling_time = "Rescheduling time is required";
    }

    if (!availability.plan_duration) {
      errors.plan_duration = "Plan duration is required";
    }
    if (availability.cancellation_policy === "Partial Refund" && !availability?.refunded_percentage) {
      errors.refunded_percentage = "Price Refund is required";
    }
    if (availability.rescheduling && !availability?.rescheduling_charge_percentage) {
      errors.rescheduling_charge_percentage = "Price rescheduling is required";
    }

    if (!availability.state_id?.length) {
      errors.state_id = "States are required";
    }
    if (!availability.staff?.length) {
      errors.staff = "Staff is required";
    }
    if (!availability.maximum_appointment_time) {
      errors.maximum_appointment_time = "Max appointment time is required";
    }
    if (!availability.cancellation_policy) {
      errors.cancellation_policy = "Cancellation policy is required";
    }
    if (bookingServiceType === "hourly_slots") {
      if (availability.rescheduling && !availability?.rescheduling_charge_percentage) {
        errors.rescheduling_charge_percentagec = "Rescheduling price is required";
      }
      if (availability.rescheduling && (!availability?.rescheduling_time || availability?.rescheduling_time === "00")) {
        errors.rescheduling_time = "Rescheduling time is required";
      }
    }

    if (!availability.start_service) {
      errors.start_service = "Start service is required";
    }
    return errors;
  };

  const [serviceId, setServiceId] = useState();
  const [id, setId] = useState();

  const [availbilty, setAvailbilty] = useState({
    plan_id: "",
    plan_template_id: "",
    start_service: formatDate(new Date()),
    end_service: "",
    maximum_appointment_time: "00",
    cancellation_time: "00",
    schedule_gap: "00",
    plan_duration: "",
    refunded_percentage: "",
    same_day: 0,
    rescheduling: 1,
    rescheduling_charge_percentage: "",
    rescheduling_time: "00",
    state_id: [],
    excluded_cities: [],
    staff: ['male'],
    cancellation_policy: [],
    minimum_appointment_time: "00",
    type:
      permission?.length > 1 || permission?.includes("photography")
        ? "service"
        : "studio",
  });

  useEffect(() => {
    getState();
  }, []);
  const [planId, setPlaneId] = useState();
  const [duration, setDuration] = useState();
  useEffect(() => {
    if (availbilty?.type === "service") {
      vendorServices("photography", 0);
    } else {
      vendorServices("studio", 1);

    }
    availDays();
    getStudio();
    // eslint-disable-next-line
  }, [availbilty?.type]);

  const [servicesName, setServicesName] = useState();
  const [bookingServiceType, setBookingServiceType] = useState("")
  function formatSlotsData(slots) {
    const formattedData = [];

    slots.forEach(slot => {
      // Find an existing entry with the same day_id
      let dayEntry = formattedData.find(entry => entry.day_id === slot.day_id);

      if (!dayEntry) {
        // If no entry exists for this day_id, create a new one
        dayEntry = {
          day_id: slot.day_id,
          is_active: slot.is_active,
          slots: []
        };
        formattedData.push(dayEntry);
      }

      // Add the slot time range to the day's slots array
      dayEntry.slots.push({
        start_time: slot.start_time,
        end_time: slot.end_time
      });
    });

    return { slots: formattedData };
  }

  const [day, setData] = useState([]);
  const [studioState,setstateStudio]=useState()
  useEffect(() => {
    if (vedorServiceData?.vendor_services?.length > 0) {
      let validVendorService = null;
      let validSubServices = [];

      // Iterate over vendor services to find the first valid sub-services
      for (let i = 0; i < vedorServiceData?.vendor_services.length; i++) {
        const vendorService = vedorServiceData.vendor_services[i];

        // Filter sub-services where only_inquiry is false and check for plan conditions
        const filteredSubServices = vendorService.service?.sub_services.filter(
          (subService) => {
            const vendorSubService = subService?.vendorSubService;

            // Check if only_inquiry is false
            if (vendorSubService?.only_inquiry === 0) {
              // Check if there are plans and if the booking_service_type is not "manual_reservation"
              if (
                vendorSubService?.plans?.length > 0 &&
                vendorSubService.plans[0]?.booking_service_type !== "manual_reservation" &&
                vendorSubService.plans.some(plan => plan.is_active)
              ) {
                return true;
              }
            }
            return false;
          }
        );
        console.log(filteredSubServices);

        // If valid sub-services are found, store them and break out of the loop
        if (filteredSubServices?.length > 0) {
          validVendorService = vendorService;
          validSubServices = filteredSubServices;
          break;
        }
      }

      if (validVendorService && validSubServices.length > 0 && availbilty?.type === "service") {
        const sub_service_id = validSubServices[0]?.vendor_sub_service_id;
        const service_id = validVendorService?.vendor_service_id;

        setResult(validSubServices);
        setId(service_id);
        setServicesName(validSubServices[0]?.name);
        setServiceId(sub_service_id);
        const activePlans = validSubServices[0]?.vendorSubService?.plans?.filter(plan => plan.is_active);
        setDataInplan(activePlans, sub_service_id);
      }
      else {
        // Handle case where no valid sub-services are found
        if (studioData?.length > 0 && availbilty?.type === "studio") {
          // Filter studio data where only_inquiry is false and at least one plan is active
          const validStudios = studioData.filter(studio =>
            studio?.only_inquiry === 0 &&
            studio?.plans?.some(plan => plan.is_active)
          );

          if (validStudios.length > 0) {
            setResult1(validStudios);
            setServiceId(validStudios[0]?.studio_id || "");
            setServicesName(validStudios[0]?.name || "");
            setstateStudio(validStudios[0]?.state_id )
            setDataInplan(
              validStudios[0]?.plans.filter(plan => plan.is_active),
              validStudios[0]?.studio_id || ""
            );
          }
        }

      }
    }
    // eslint-disable-next-line
  }, [vedorServiceData, studioData]);

  const setDataInplan = (plans, sub_service_id) => {
    setPlanData(plans)
    if (plans?.length > 0) {
      let plan = plans[0]
      setPlaneId(plan?.id)
      setBookingServiceType(plan?.booking_service_type)
    
      setAvailbityInplan(plan, sub_service_id)
      if (plan?.booking_service_type === "hourly_slots") {
        setShow(false)

      }
      else {
        setShow(true)
      }


    }
  }


  const setAvailbityInplan = (plan, sub_service_id) => {
    setDuration(plan?.plan_duration)
    const savedAvailability = JSON.parse(
      localStorage.getItem("availbilty")
    );
    const vendorData = JSON.parse(localStorage.getItem("vendor_data")) || {};
    if (plan?.plan_availability) {
      setAvailbilty({ ...plan?.plan_availability, type: plan?.type, plan_duration: plan?.plan_duration, plan_id: plan?.id })
      if (plan?.plan_availability?.slots?.length > 0) {
        const initialState = [
          { day_id: 1, is_active: true, day_name: "Monday", slots: [] },
          { day_id: 2, is_active: true, day_name: "Tuesday", slots: [] },
          { day_id: 3, is_active: true, day_name: "Wednesday", slots: [] },
          { day_id: 4, is_active: true, day_name: "Thursday", slots: [] },
          { day_id: 5, is_active: true, day_name: "Friday", slots: [] },
          { day_id: 6, is_active: true, day_name: "Saturday", slots: [] },
          { day_id: 7, is_active: true, day_name: "Sunday", slots: [] },
        ];
        const formattedSlots = formatSlotsData(plan?.plan_availability?.slots);

        const backendSlotsMap = new Map();
        formattedSlots?.slots?.forEach((day) => {
          backendSlotsMap.set(day.day_id, {
            slots: day.slots,
            is_active: day.is_active,
          });
        });
        // Merge initial state with backend data based on day_id
        const mergedState = initialState.map((day) => {
          if (backendSlotsMap.has(day.day_id)) {
            const backendDay = backendSlotsMap.get(day.day_id);
            return {
              ...day,
              slots: backendDay.slots,
              is_active: backendDay.is_active,
            };
          }
          return day;
        });

        setData(mergedState);
      }
      else {
        setData([
          { day_id: 1, is_active: true, day_name: "Monday", slots: [] },
          { day_id: 2, is_active: true, day_name: "Tuesday", slots: [] },
          { day_id: 3, is_active: true, day_name: "Wednesday", slots: [] },
          { day_id: 4, is_active: true, day_name: "Thursday", slots: [] },
          { day_id: 5, is_active: true, day_name: "Friday", slots: [] },
          { day_id: 6, is_active: true, day_name: "Saturday", slots: [] },
          { day_id: 7, is_active: true, day_name: "Sunday", slots: [] },
        ])
      }
    }
    else if (savedAvailability) {
      let statess = vendorData?.data?.user?.state;
      let savedAvailability_states = savedAvailability?.states || [];
      savedAvailability_states.push(statess);
      setAvailbilty({
        vendor_service_id: sub_service_id,
        plan_id: plan?.id,
        plan_template_id: plan?.plan_template_id,
        start_service: formatDate(new Date()),
        end_service: savedAvailability?.end_service,
        rescheduling_time: savedAvailability?.rescheduling_time,
        cancellation_time: savedAvailability?.cancellation_time,
        schedule_gap: "00",
        plan_duration: plan?.plan_duration,
        state_id: savedAvailability?.state_id,
        refunded_percentage: savedAvailability?.refunded_percentage ? savedAvailability?.refunded_percentage : "",
        same_day: savedAvailability?.same_day,
        rescheduling: savedAvailability?.rescheduling?savedAvailability?.rescheduling:1,
        rescheduling_charge_percentage: savedAvailability?.rescheduling_charge_percentage,
        states: savedAvailability_states,
        excluded_cities: savedAvailability?.excluded_cities,
        staff: savedAvailability?.staff,
        cancellation_policy: savedAvailability?.cancellation_policy,
        minimum_appointment_time: savedAvailability?.minimum_appointment_time,
        type: availbilty.type,
        maximum_appointment_time: savedAvailability?.maximum_appointment_time,
      });
      setData([
        { day_id: 1, is_active: true, day_name: "Monday", slots: [] },
        { day_id: 2, is_active: true, day_name: "Tuesday", slots: [] },
        { day_id: 3, is_active: true, day_name: "Wednesday", slots: [] },
        { day_id: 4, is_active: true, day_name: "Thursday", slots: [] },
        { day_id: 5, is_active: true, day_name: "Friday", slots: [] },
        { day_id: 6, is_active: true, day_name: "Saturday", slots: [] },
        { day_id: 7, is_active: true, day_name: "Sunday", slots: [] },
      ])
    }
    else {
      setAvailbilty({
        vendor_service_id: sub_service_id,
        plan_id: plan?.id,
        plan_template_id: plan?.plan_template_id,
        start_service: formatDate(new Date()),
        end_service: "",
        rescheduling_time: "00",
        cancellation_time: "00",
        schedule_gap: "00",
        refunded_percentage: "",
        plan_duration: plan?.plan_duration,
        state_id: [],
        excluded_cities: [],
        staff: ['male'],
        cancellation_policy: [],
        minimum_appointment_time: "00",
        type: availbilty.type,
        same_day: 0,
        rescheduling: 1,
        rescheduling_charge_percentage: "",
      });
      setData([
        { day_id: 1, is_active: true, day_name: "Monday", slots: [] },
        { day_id: 2, is_active: true, day_name: "Tuesday", slots: [] },
        { day_id: 3, is_active: true, day_name: "Wednesday", slots: [] },
        { day_id: 4, is_active: true, day_name: "Thursday", slots: [] },
        { day_id: 5, is_active: true, day_name: "Friday", slots: [] },
        { day_id: 6, is_active: true, day_name: "Saturday", slots: [] },
        { day_id: 7, is_active: true, day_name: "Sunday", slots: [] },
      ])
    }
  }

  const [day_id, setDayId] = useState();
  function handledel(dayId, slotIndex) {
    setChange(true);
    setData(
      day.map((item) => {
        if (item.day_id === dayId) {
          return {
            ...item,
            slots: item.slots.filter((_, id) => id !== slotIndex),
          };
        }
        return item;
      })
    );
  }
  const [error, setError] = useState();

  const handleSubmit = () => {
    let obj = { ...availbilty }
    if (bookingServiceType === "item_quantity") {
      obj.rescheduling = false
    }
    const errors = validateAvailability(obj);
    setError(errors);
    if (Object.keys(errors).length === 0) {
      // No errors, proceed with form submission or other logic
      if (obj?.id) {
        if(availbilty?.type==="studio"){
          updateslot({ availability:  {...obj,state_id:[studioState]}, slots: day});

        }else{

          updateslot({ availability: obj, slots: day });
        }
        setChange(false);
        localStorage.setItem("availbilty", JSON.stringify(availbilty));
      } else {
        setChange(false);
        if(availbilty?.type==="studio"){
          createSlot({ availability: {...obj,state_id:[studioState]}, slots: day });

        }else{

          createSlot({ availability: obj, slots: day });
        }
   
        localStorage.setItem("availbilty", JSON.stringify(availbilty));
      }
    } else {
      // Handle errors
    }
  };
  function convertTo12HourFormat(time24) {
    if (time24) {
      const [hours24, minutes24] = time24?.split(":").map(Number);
      let hours12 = hours24 % 12 || 12; // Convert 0 to 12
      const ampm = hours24 < 12 ? "AM" : "PM";
      return `${hours12?.toString().padStart(2, "0")}:${minutes24
        ?.toString()
        .padStart(2, "0")} ${ampm}`;
    }
    return time24;
  }
  const [iD, setID] = useState();
  const copy = (id) => {
    let array = [...day]; // Clone the day array to ensure immutability
    let sourceSlots;

    // Find the item with the given id and store its slots
    array.forEach((item) => {
      if (item.day_id === id) {
        sourceSlots = item.slots;
      }
    });

    // Update the item with the same day_id as iD with the source slots
    array.forEach((item) => {
      if (item.day_id === iD) {
        item.slots = sourceSlots;
      }
    });

    setData(array);
  };

  function searchBySubServiceName(searchTerm) {
    let results;
    if (availbilty?.type === "service") {
      if (searchTerm !== "") {
        vedorServiceData?.vendor_services?.map((item) => {
          if (item?.vendor_service_id === id) {
            results = item?.service?.sub_services?.filter(
              (items) =>
                items &&
                items.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setResult(results);
          }
        });
      } else {
        vedorServiceData?.vendor_services?.map((item) => {
          if (item?.vendor_service_id === id) {
            results = item?.service?.sub_services;
            setResult(results);
          }
        });
      }
    } else {
      results = studioData?.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setResult1(results);
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, value) => {

    let array1 = [];

    if (value?.length) {
      value.filter((item, i) => {
        array1.push(item?.id);
      });

      getCities(array1);
      setAvailbilty({ ...availbilty, state_id: array1, states: value });
    } else {
      setCities([]);
    }
  };

  useEffect(() => {
    if (cities?.length) {
      let array2 = [];
      cities?.filter((items, ids) => {
        if (availbilty?.excluded_cities?.includes(items?.id)) {
          array2.push(items?.id);
        }
      });
      setAvailbilty({ ...availbilty, excluded_cities: array2 });
    }
  }, [cities]);
  const [currentTime, setCurrentTime] = React.useState('');

  React.useEffect(() => {
    const updateTime = () => {
      const time = moment().tz('Europe/London').format('hh:mm:ss A');
      setCurrentTime(time);
    };

    // Update time every second
    const intervalId = setInterval(updateTime, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <div className="new_order_nav">
        <div>
          <h4 className="d-flex align-items-center">
            {" "}
            <img
              src={availab}
              alt=""
              style={{ marginRight: "11px", width: "22px", height: "22px" }}
            />
            Availability{" "}
          </h4>
        </div>
      </div>

      <div className="availabity">
        <div className="row m-0 p-2">
          <div className="col-12">
            <p className="comm_para">
              Set your availability for all selected services you provide,
              making sure it aligns carefully with each plan you have created.
            </p>
            <hr className="my-1" />
          </div>

          <div className="col-12  d-flex justify-content-between">
            {permission?.length === 2 ? (
              <div>
                <button
                  className={`profile_btns p-0 ms-0 ${availbilty.type === "studio"
                    ? "common_light"
                    : "common_dark"
                    }`}
                  onClick={() => {
                    if (change) {
                      handleOpen(() => {
                        setAvailbilty({ ...availbilty, type: "service" });
                        vendorServices();
                        setError();
                      });
                    } else {
                      setAvailbilty({ ...availbilty, type: "service" });
                      vendorServices();
                      setError();
                    }
                  }}
                >
                  Service(s)
                </button>
                <button
                  className={`profile_btns p-0 ${availbilty.type === "service"
                    ? "common_light"
                    : "common_dark"
                    }`}
                  onClick={() => {
                    if (change) {
                      handleOpen(() => {
                        setAvailbilty({ ...availbilty, type: "studio" });
                        getStudio();
                        setError(null);
                      });
                    } else {
                      setAvailbilty({ ...availbilty, type: "studio" });
                      getStudio();
                      setError();
                    }
                  }}
                >
                  Studio(s)
                </button>
              </div>
            ) : (
              <div />
            )}
            <div className="avail">
              <button onClick={handleSubmit}>
                {buttonLoding ? (
                  <div
                    className="spinner-border text-light"
                    role="status"
                  ></div>
                ) : availbilty?.id ? (
                  "Update Setting"
                ) : (
                  "Save Setting"
                )}
              </button>
            </div>
          </div>

          <div className="col-12 py-2">
            <div className="row avail">
              <div className="col-lg-3 col-12  ">
                <h6
                  style={{
                    margin: "0",
                    color: "#494949",
                    textTransform: "capitalize",
                  }}
                >
                  {/* Available Service(s) */}
                  Available {availbilty.type}
                  <h6
                    style={{
                      textTransform: "lowercase",
                      display: "inline",
                      margin: "0",
                      color: "#494949",
                    }}
                  >
                    (s)
                  </h6>
                </h6>
              </div>
              <div
                className="col-lg-7 col-12 "
                style={{ position: "relative" }}
              >
                <h1>{servicesName}</h1>
                {error && Object.keys(error).length > 0 && (
                  <span
                    className="error_text"
                    style={{
                      position: "absolute",
                      right: "0",
                      fontSize: "13px",
                      top: "0",
                    }}
                  >
                    Some fields are missing in Service Scheduling Limits
                  </span>
                )}
              </div>
              <div className="col-lg-2 col-12  d-flex justify-content-end ps-2"></div>
            </div>
          </div>
          <div className="col-lg-3 col-12">
            {/* <div className="comm_select new_select">
            
            </div> */}
            <div className="avail_main">
              <div className="avail_service avail_sel p-0">
                {availbilty?.type === "service" ? (
                  <select
                    onChange={(e) => {
                      let filteredArray = [];
                      vedorServiceData?.vendor_services?.forEach((service) => {
                        if (service?.vendor_service_id == e.target.value) {
                          // Filter sub-services where only_inquiry is false
                          filteredArray = service?.service?.sub_services.filter(
                            (subService) => subService?.vendorSubService?.only_inquiry === 0 &&
                              subService?.vendorSubService?.plans?.some(plan => plan.is_active)
                          );

                          if (filteredArray.length > 0) {
                            // Set initial valid sub-service and service details
                            setServiceId(filteredArray[0]?.vendor_sub_service_id);
                            setId(e.target.value);
                            setServicesName(filteredArray[0]?.name);

                            // Filter active plans and set data in plan
                            const activePlans = filteredArray[0]?.vendorSubService?.plans?.filter(plan => plan.is_active);
                            setDataInplan(activePlans, filteredArray[0]?.vendor_sub_service_id);
                          }
                        }
                      });
                      // Store the filtered sub-services in the result state
                      setResult(filteredArray);
                    }}
                    name="serviceSelect"
                  >
                    <option value="Select Services" hidden>Select Services</option>
                    {vedorServiceData?.vendor_services
                      ?.filter(service =>
                        service?.service?.sub_services.some(
                          (subService) => subService?.vendorSubService?.only_inquiry === 0 &&
                            subService?.vendorSubService?.plans.some(plan => plan.is_active)
                        )
                      )
                      ?.map((service) => (
                        <option key={service?.vendor_service_id} value={service?.vendor_service_id}>
                          {service?.service?.name}
                        </option>
                      ))}
                  </select>
                ) : (
                  <select
                    onChange={(e) => {
                      let selectedStudios = [];
                      studioData?.forEach((studio) => {
                        studio?.sub_services?.forEach((subService) => {
                          if (subService?.id === parseInt(e.target.value)) {
                            selectedStudios.push(studio);
                            // Set studio details
                            setServiceId(studio?.studio_id);
                            setServicesName(studio?.name);

                            // Filter active plans and set data in plan
                            const activePlans = studio?.plans.filter(plan => plan.is_active);
                            setDataInplan(activePlans, studio?.studio_id);
                          }
                        });
                      });
                      // Store filtered studios in the result state
                      setResult1(selectedStudios);
                    }}
                    name="studioSelect"
                  >
                    <option value="Select Services" hidden>Select Services</option>
                    {vedorServiceData?.all_services?.map((service) => (
                      service?.sub_services?.map((subService) => (
                        <option key={subService?.id} value={subService?.id}>{subService?.name}</option>
                      ))
                    ))}
                  </select>
                )}

              </div>
              <div className="avail_service pt-0">
                <input
                  type="search"
                  onChange={(e) => {
                    searchBySubServiceName(e.target.value);
                  }}
                  placeholder="Search Service"
                />
                <ul className="m-0 p-0">
                  {availbilty?.type === "service"
                    ? result?.map((item) => {
                      return (
                        <li
                          onClick={() => {
                            if (change) {
                              handleOpen(() => {
                                setServiceId(item?.vendor_sub_service_id);
                                setDataInplan(item?.vendorSubService?.plans, item?.vendor_sub_service_id)
                                setError();
                                setServicesName(item?.name);

                              });
                            } else {
                              setServiceId(item?.vendor_sub_service_id);
                              setDataInplan(item?.vendorSubService?.plans, item?.vendor_sub_service_id)
                              setError();
                              setServicesName(item?.name);

                            }
                          }}
                          className={`${serviceId === item?.vendor_sub_service_id
                            ? "avail_active"
                            : ""
                            }`}
                        >
                          {item?.name}
                        </li>
                      );
                    })
                    : result1?.map((item) => {
                      return (
                        <li
                          onClick={() => {
                            if (change) {
                              handleOpen(() => {
                                setServiceId(item?.studio_id);
                                setServicesName(item?.name);
                                setError();


                              });
                            } else {
                              setServiceId(item?.studio_id);

                              setServicesName(item?.name);
                              setError();


                            }
                          }}
                          className={`${serviceId === item.studio_id ? "avail_active" : ""
                            }`}
                        >
                          {item?.name}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
          {planData?.length ? (

            <div className="col-lg-9 col-12">
              {availbityLoad ?
                <div className='row'>
                  <div className='col-4'>
                    <p style={{ height: "50px" }} className="placeholder-glow">
                      <span className="placeholder col-12 h-100 rounded"></span>
                    </p>
                  </div>
                  <div className="row">
                    <div className='col-6'>
                      <p style={{ height: "50px" }} className="placeholder-glow">
                        <span className="placeholder col-12 h-100 rounded"></span>
                      </p>
                    </div>
                  </div>
                  <div className='col-2'>
                    <p style={{ height: "30px" }} className="placeholder-glow">
                      <span className="placeholder col-12 h-100 rounded"></span>
                    </p>
                  </div>
                  <div className='col-2'>
                    <p style={{ height: "30px" }} className="placeholder-glow">
                      <span className="placeholder col-12 h-100 rounded"></span>
                    </p>
                  </div>
                  <div className='col-2'>
                    <p style={{ height: "30px" }} className="placeholder-glow">
                      <span className="placeholder col-12 h-100 rounded"></span>
                    </p>
                  </div>
                  <div className='col-12'>
                    <p style={{ height: "10vh" }} className="placeholder-glow">
                      <span className="placeholder col-12 h-100 rounded"></span>
                    </p>
                  </div>
                  <div className='col-12'>
                    <p style={{ height: "10vh" }} className="placeholder-glow">
                      <span className="placeholder col-12 h-100 rounded"></span>
                    </p>
                  </div>
                  <div className='col-12'>
                    <p style={{ height: "10vh" }} className="placeholder-glow">
                      <span className="placeholder col-12 h-100 rounded"></span>
                    </p>
                  </div>
                  <div className='col-12'>
                    <p style={{ height: "10vh" }} className="placeholder-glow">
                      <span className="placeholder col-12 h-100 rounded"></span>
                    </p>
                  </div>
                </div>
                : <div className="avail_main1 avail_main ">
                  <div className="row ">
                    <div className="col-12 ">
                      {bookingServiceType === "hourly_slots" && <div className="avail_hour_availibilty">
                        <div className="hour_schl m-0 p-0 d-flex">
                          <p
                            onClick={() => setShow(false)}
                            className={`${show ? "hour_schl_light" : "hour_schl_dark"
                              }`}
                          >
                            {" "}
                            Set Hours of Availability
                          </p>
                        </div>
                        <div className="hour_schl m-0 p-0 d-flex">
                          <p
                            onClick={() => setShow(true)}
                            className={`${error?.minimum_appointment_time && "blink"
                              } ${show ? "hour_schl_dark" : "hour_schl_light"}`}
                          >
                            Service Scheduling Limits
                          </p>
                        </div>
                      </div>}
                    </div>

                    {show ? (
                      <>
                        {bookingServiceType === "item_quantity" && <div className="row m-0 pt-3">

                          <div className="col-12">
                            <div className="plan_duration">
                              <ul className="starter p-0 m-0 d-flex">
                                {planData?.map((item) => {
                                  return (
                                    <li
                                      onClick={() => {
                                        if (change) {
                                          handleOpen(() => {

                                            setPlaneId(item?.id);
                                            setAvailbityInplan(item, serviceId)
                                            setError();
                                            setDuration(item?.plan_duration);



                                          });
                                        } else {

                                          setPlaneId(item?.id);
                                          setError();
                                          setAvailbityInplan(item, serviceId)
                                          setDuration(item?.plan_duration);


                                        }
                                      }}
                                      className={`${planId === item.id ? "avail_active" : ""
                                        }`}
                                    >
                                      {item?.plan_template?.plan_name}
                                    </li>
                                  );
                                })}
                                {/* <li>Pro</li>
                        <li>Business</li> */}
                              </ul>
                              <div className="plan_dura">
                                UK, Manchester Time ({currentTime})<IoMdArrowDropdown style={{ cursor: "pointer", marginLeft: "1rem" }} />
                              </div>

                            </div>
                          </div>
                        </div>}
                        <div className="row m-0 pt-3">
                          <div className="col-lg-6 col-12 ">
                            <div className="row m-0">
                              <div className="col-lg-6 col-12 px-0 pe-3">
                                <div className="services_limit">
                                  <label htmlFor="">Start Service Plan</label>
                                  <input
                                    value={availbilty?.start_service}
                                    onChange={(e) => {
                                      setChange(true);
                                      setAvailbilty({
                                        ...availbilty,
                                        start_service: e.target.value,
                                      });
                                    }}
                                    type="date"
                                  />
                                </div>
                                {error?.start_service && (
                                  <span
                                    className="error_text"
                                    style={{ position: "absolute" }}
                                  >
                                    {error?.start_service}
                                  </span>
                                )}
                              </div>
                              <div className="col-lg-6 col-12 px-0 ps-3">
                                <div className="services_limit">
                                  <label htmlFor="">End Service Plan</label>
                                  <input
                                    onChange={(e) => {
                                      setAvailbilty({
                                        ...availbilty,
                                        end_service: e.target.value,
                                      });
                                      setChange(true);
                                    }}
                                    type="date"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ============================ Minimum time before the appointment start that it
                        can be booked==================== */}
                          <div
                            className="col-lg-6 col-12"
                            style={{ position: "relative" }}
                          >
                            <div className="services_limit">
                              <label htmlFor="">
                        
                                Minimum time before the appointment start that it
                                can be booked        <Tooltip title="Add" arrow placement="top">
                                  <AiFillQuestionCircle />
                                </Tooltip>
                              </label>
                              <div className="services_limit_time">
                                <input
                                  value={
                                    availbilty?.minimum_appointment_time
                                  }
                                  onChange={(e) => {

                                    setAvailbilty({
                                      ...availbilty,
                                      minimum_appointment_time: e.target.value,
                                    });
                                  }}
                                  type="text"
                                  placeholder="Hour(s):"
                                />

                              </div>
                              {error?.minimum_appointment_time && (
                                <span
                                  className="error_text"
                                  style={{ position: "absolute" }}
                                >
                                  {error?.minimum_appointment_time}
                                </span>
                              )}
                            </div>
                          </div>
                          <div
                            className="col-lg-6 col-12 py-2 "
                            style={{ position: "relative" }}
                          >
                            <div className="services_limit">
                            <Tooltip title="Add" arrow placement="top">
                              <label htmlFor="">
                                Maximum time in advance that an appointment can be
                                booked         
                                  <AiFillQuestionCircle />
                              </label>
                                </Tooltip>
                              <input
                                value={availbilty?.maximum_appointment_time}

                                onChange={(e) => {

                                  setAvailbilty({
                                    ...availbilty,
                                    maximum_appointment_time: e.target.value

                                  });

                                }}
                                type="number" // Changed to text to allow for custom formatting
                                placeholder="Max Appointment time"
                              />
                            </div>
                            {error?.maximum_appointment_time && (
                              <span
                                className="error_text"
                                style={{ position: "absolute" }}
                              >
                                {error?.maximum_appointment_time}
                              </span>
                            )}
                          </div>
                        </div>
                        {bookingServiceType === "hourly_slots" && <div className="row m-0">
                          <h6 style={{ marginTop: "1rem", fontWeight: "600" }}>Rescheduling Policy:</h6>
                          <div
                            className="col-lg-6 col-12 py-2"
                            style={{ position: "relative" }}
                          >
                            <div className="services_limit">
                            <Tooltip  placement="top" title="Add" arrow>
                              <label htmlFor="">Availability Subject to Rescheduling?       
                                  <AiFillQuestionCircle />
                                </label>
                                </Tooltip>
                              <select
                                value={availbilty?.rescheduling?true:false}
                                onChange={(e) => {
                                  setAvailbilty({
                                    ...availbilty,
                                    rescheduling: e.target.value === "true",
                                  });
                                }}
                                name=""
                                id=""
                              >
                                <option value="Choose Cancellation Policy" hidden>
                                  Choose Cancellation Policy
                                </option>
                                <option value={true}>
                                  Yes
                                </option>
                                <option value={false}>No</option>
                              </select>
                            </div>
                            {error?.rescheduling && (
                              <span
                                className="error_text"
                                style={{ position: "absolute" }}
                              >
                                {error?.rescheduling}
                              </span>
                            )}
                          </div>
                          <div
                            className="col-lg-6 col-12 py-2"
                            style={{ position: "relative" }}
                          >
                            <div className="services_limit">
                            <Tooltip title="Add" arrow placement="top">
                              <label htmlFor="">
                                Rescheduling Window (in hours) <AiFillQuestionCircle />
                              </label>
                              </Tooltip>
                              <div style={{ background: `${availbilty?.rescheduling ? "" : "#9a9a99"}` }} className="services_limit_time">
                                <input
                                  value={
                                    availbilty?.rescheduling_time
                                  }
                                  style={{ width: "100%", cursor: `${availbilty?.rescheduling ? "" : "not-allowed"}` }}
                                  disabled={availbilty?.rescheduling ? false : true}
                                  onChange={(e) => {

                                    setAvailbilty({
                                      ...availbilty,
                                      rescheduling_time: e.target.value,
                                    });
                                    setChange(true);
                                  }}
                                  type="number"
                                  placeholder="Hour(s)"
                                />

                              </div>
                              {error?.rescheduling_time && (
                                <span
                                  className="error_text"
                                  style={{ position: "absolute" }}
                                >
                                  {error?.rescheduling_time}
                                </span>
                              )}
                            </div>
                          </div>
                          <div
                            className="col-lg-6 col-12 py-2"
                            style={{ position: "relative" }}
                          >
                            <div className="services_limit">
                            <Tooltip title="Add" arrow placement="top">
                              <label htmlFor="">Rescheduling Fee Percentage (within window) <AiFillQuestionCircle /></label>
                              </Tooltip>
                              <div style={{ background: `${availbilty?.rescheduling ? "#ececeb" : "#9a9a99"}` }} className="multi_state">
                                <input maxLength={2}
                                  value={availbilty?.rescheduling_charge_percentage}
                                  style={{ width: "100%", cursor: `${availbilty?.rescheduling ? "" : "not-allowed"}` }}
                                  disabled={availbilty?.rescheduling ? false : true} onChange={(e) => {
                                    setAvailbilty({
                                      ...availbilty,
                                      rescheduling_charge_percentage: e.target.value,
                                    });
                                  }} type="number" placeholder="Rescheduling Amount in Percentage" />
                              </div>
                            </div>
                            {error?.rescheduling_charge_percentage && (
                              <span
                                className="error_text"
                                style={{ position: "absolute" }}
                              >
                                {error?.rescheduling_charge_percentage}
                              </span>
                            )}
                          </div>
                        </div>}
                        {/* 
                        ========================Min Hours Before Start for
                        Cancellation================ */}
                        <div className="row m-0">
                          <h6 style={{ marginTop: "1rem", fontWeight: "600" }}>Cancellation Policy:</h6>
                          <div
                            className="col-lg-6 col-12 py-2"
                            style={{ position: "relative" }}
                          >
                            <div className="services_limit">
                            <Tooltip title="Add" arrow placement="top">
                              <label htmlFor="">Cancellation/Refund Policy <AiFillQuestionCircle /></label>
                              </Tooltip>
                              <select
                                value={availbilty?.cancellation_policy}

                                onChange={(e) => {
                                  setAvailbilty({
                                    ...availbilty,
                                    cancellation_policy: e.target.value,
                                  });
                                }}
                                name=""
                                id=""
                              >
                                <option value="Choose Cancellation Policy" hidden>
                                  Choose Cancellation Policy
                                </option>
                                <option value="Partial Refund">
                                  Partial Refund
                                </option>
                                <option value="Fully Refund">Fully Refund</option>
                                <option value="No Refund">No Refund</option>
                              </select>
                            </div>
                            {error?.cancellation_policy && (
                              <span
                                className="error_text"
                                style={{ position: "absolute" }}
                              >
                                {error?.cancellation_policy}
                              </span>
                            )}
                          </div>
                          <div
                            className="col-lg-6 col-12 py-2"
                            style={{ position: "relative" }}
                          >
                            <div className="services_limit">
                            <Tooltip title="Add" arrow placement="top">
                              <label htmlFor="">
                                Cancellation Window (in hours) <AiFillQuestionCircle />
                              </label>
                              </Tooltip>
                              <div style={{ background: `${availbilty?.cancellation_policy === "Partial Refund" ? "" : "#9a9a99"}` }} className="services_limit_time">
                                <input
                                  value={
                                    availbilty?.cancellation_time
                                  }
                                  style={{ width: "100%", cursor: `${availbilty?.cancellation_policy === "Partial Refund" ? "" : "not-allowed"}` }}
                                  disabled={availbilty?.cancellation_policy === "Partial Refund" ? false : true}
                                  onChange={(e) => {

                                    setAvailbilty({
                                      ...availbilty,
                                      cancellation_time: e.target.value,
                                    });
                                    setChange(true);
                                  }}
                                  type="number"
                                  placeholder="Hour(s)"
                                />

                              </div>
                              {error?.cancellation_time && (
                                <span
                                  className="error_text"
                                  style={{ position: "absolute" }}
                                >
                                  {error?.cancellation_time}
                                </span>
                              )}
                            </div>

                          </div>

                          <div
                            className="col-lg-6 col-12 py-2"
                            style={{ position: "relative" }}
                          >
                            <div className="services_limit">
                            <Tooltip title="Add" arrow placement="top">
                              <label htmlFor="">Partial Refund Percentage <AiFillQuestionCircle /></label>
                              </Tooltip>
                              <div style={{ background: `${availbilty?.cancellation_policy === "Partial Refund" ? "#ececeb" : "#9a9a99"}` }} className="multi_state">
                                <input maxLength={2} value={availbilty?.refunded_percentage}
                                  disabled={availbilty?.cancellation_policy === "Partial Refund" ? false : true}
                                  style={{ cursor: `${availbilty?.cancellation_policy === "Partial Refund" ? "" : "not-allowed"}` }}
                                  onChange={(e) => {
                                    setAvailbilty({
                                      ...availbilty,
                                      refunded_percentage: e.target.value,
                                    });

                                  }} type="number" placeholder="Refund Amount in Percentage" />
                              </div>
                            </div>
                            {error?.refunded_percentage && (
                              <span
                                className="error_text"
                                style={{ position: "absolute" }}
                              >
                                {error?.refunded_percentage}
                              </span>
                            )}
                          </div>
                          <div
                            className="col-lg-6 col-12 py-2"
                            style={{ position: "relative" }}
                          >
                            <div className="services_limit">
                            <Tooltip title="Add" arrow placement="top">
                              <label htmlFor="">No Cancellation/Refunds for Same Day/No Show</label>
                              </Tooltip>
                              <select
                                value={availbilty?.same_day?true:false}
                                onChange={(e) => {
                                  setAvailbilty({
                                    ...availbilty,
                                    same_day: e.target.value === "true",
                                  });
                                }}
                                name=""
                                id=""
                              >
                                <option value="Choose Cancellation Policy" hidden>
                                  Choose Refunds for Same Day/No Show
                                </option>
                                <option value={true}>
                                  Yes
                                </option>
                                <option value={false}>No</option>
                              </select>
                            </div>

                          </div>
                        </div>

                        {availbilty.type!=="studio"&&<div className="row m-0">
                          <h6 style={{ marginTop: "1rem", fontWeight: "600" }}>Location Availability:</h6>
                          <div
                            className="col-lg-6 col-12 py-2"
                            style={{ position: "relative" }}
                          >
                            <div className="services_limit">
                              <label htmlFor="">Choose States</label>
                              <div className="multi_state">
                                <Stack spacing={3} sx={{ width: 500 }}>
                                  <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    className="px-2"
                                    placeholder="Choose State"
                                    // options={stateData||[]}
                                    options={
                                      Array.isArray(stateData) ? stateData : []
                                    }
                                    onChange={handleChange}
                                    value={
                                      Array.isArray(availbilty?.states)
                                        ? availbilty.states
                                        : []
                                    }
                                    // value={availbilty?.states}
                                    getOptionLabel={(option) => option?.name}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        className="state_hold"
                                        placeholder="Choose State"
                                      />
                                    )}
                                  />
                                </Stack>
                              </div>
                            </div>
                            {error?.state_id && (
                              <span
                                className="error_text"
                                style={{ position: "absolute" }}
                              >
                                {error?.state_id}
                              </span>
                            )}
                          </div>
                          <div className="col-lg-6 col-12 py-2">
                            <div className="services_limit">
                              <label htmlFor="">Excluded Cities</label>
                              <div className="multi_city">
                                <Select
                                  multiple
                                  value={availbilty?.excluded_cities || []}
                                  style={{
                                    border: "none",
                                    maxWidth: "100%",
                                    overflow: "auto",
                                  }}
                                  fullWidth
                                  onChange={(event) => {
                                    const value = event.target.value;

                                    // Otherwise, update with selected values
                                    setAvailbilty({
                                      ...availbilty,
                                      excluded_cities:
                                        typeof value === "string"
                                          ? value?.split(",")
                                          : value,
                                    });
                                  }}
                                  renderValue={(selected) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {selected.map((id, index) => {
                                        const city = cities.find(
                                          (item) => item.id === id
                                        );
                                        return city ? (
                                          <span
                                            key={id}
                                            style={{
                                              backgroundColor: "#494949",
                                              color: "white",
                                              borderRadius: "4px",
                                              padding: "0px 8px",
                                              margin: "2px",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {city.name}
                                          </span>
                                        ) : null;
                                      })}
                                    </div>
                                  )}
                                >
                                  {cities?.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>}

                        {/* <div
                            className="col-lg-6 col-12 py-2"
                            style={{ position: "relative" }}
                          >
                            <div className="services_limit">
                              <label htmlFor="">Staff</label>
                              <div>
                                <MultiSelectStaff
                                  availbilty={availbilty}
                                  setAvailbilty={setAvailbilty}
                                  staffList={availbilty?.staff}
                                />
                              </div>
                            </div>

                            {error?.staff && (
                              <span
                                className="error_text"
                                style={{ position: "absolute" }}
                              >
                                {error?.staff}
                              </span>
                            )}
                          </div> */}

                      </>
                    ) : (
                      <>
                        <div className="col-12 ">
                          <p className="py-2 select_package">
                            Select Your Package
                          </p>
                        </div>
                        {/* ///////////////////////////////////// starter pro Business/////////////////////// */}

                        <div className="col-12">
                          <div className="plan_duration">
                            <ul className="starter p-0 m-0 d-flex">
                              {planData?.map((item) => {
                                return (
                                  <li
                                    onClick={() => {
                                      if (change) {
                                        handleOpen(() => {

                                          setPlaneId(item?.id);
                                          setError();
                                          setAvailbityInplan(item, serviceId)

                                          // setDuration(item?.plan_duration);


                                        });
                                      } else {

                                        setPlaneId(item?.id);
                                        setError();
                                        setAvailbityInplan(item, serviceId)

                                        // setDuration(item?.plan_duration);


                                      }
                                    }}
                                    className={`${planId === item.id ? "avail_active" : ""
                                      }`}
                                  >
                                    {item?.plan_template?.plan_name}
                                  </li>
                                );
                              })}
                              {/* <li>Pro</li>
                        <li>Business</li> */}
                            </ul>
                            <div className="plan_dura">
                              UK, Manchester Time ({currentTime})<IoMdArrowDropdown style={{ cursor: "pointer", marginLeft: "1rem" }} />
                            </div>

                          </div>
                        </div>

                        {/* ///////////////////////////////////// Schedual gap/////////////////////// */}

                        <div className="gap_plan">
                          <div className="row m-0">
                            <div className=" col-12 pe-2 p-0">
                              <p className="gap_sch">Schedule Gap before</p>
                              <p className="gap_time">
                                <select
                                  value={
                                    availbilty?.schedule_gap !== null
                                      ? availbilty?.schedule_gap?.split(":")[0]
                                      : "00"
                                  }
                                  onChange={(e) => {
                                    const hour = e.target.value;
                                    const min =
                                      availbilty?.schedule_gap?.split(":")[1];
                                    setAvailbilty({
                                      ...availbilty,
                                      schedule_gap: `${hour}:${min}`,
                                    });
                                    setChange(true);
                                  }}
                                >
                                  <option value={"00"}>00 Hour</option>
                                  <option value={"01"}>01 Hours</option>
                                  <option value={"02"}>02 Hours</option>
                                  <option value={"03"}>03 Hours</option>
                                  <option value={"04"}>04 Hours</option>
                                  <option value={"05"}>05 Hours</option>
                                  <option value={"06"}>06 Hours</option>
                                  <option value={"07"}>07 Hours</option>
                                  <option value={"08"}>08 Hours</option>
                                  <option value={"09"}>09 Hours</option>
                                  <option value={"10"}>10 Hours</option>
                                  <option value={"11"}>11 Hours</option>
                                  <option value={"12"}>12 Hours</option>
                                </select>

                                <select
                                  value={
                                    availbilty?.schedule_gap !== null
                                      ? availbilty?.schedule_gap?.split(":")[1]
                                      : "00"
                                  }
                                  onChange={(e) => {
                                    const hour =
                                      availbilty?.schedule_gap?.split(":")[0];
                                    const min = e.target.value;
                                    setAvailbilty({
                                      ...availbilty,
                                      schedule_gap: `${hour}:${min}`,
                                    });
                                    setChange(true);
                                  }}
                                >
                                  <option value={"00"}>00 min</option>
                                  <option value={"30"}>30 mins</option>
                                </select>
                              </p>
                            </div>
                          </div>
                          <div className="copyPlan_time">


                            {/* <div className="plan_dura me-4"
                          style={{ cursor: "pointer" }}
                          onClick={handleClick}
                        >     
                          Copy Slots form this Plan to{" "}
                          <IoMdArrowDropdown className="ms-2" />
                        </div> */}

                            <p className="plan_dura ms-2">
                              Selected Plan Duration: {availbilty?.plan_duration} Hour(s)
                            </p>

                          </div>


                        </div>

                        <div className="col-lg-6 col-12 "></div>


                        <Menu
                          id="basic-menu"
                          a
                          anchorEl={anchorEl}
                          style={{ marginLeft: "-40px", marginTop: ".5rem" }}
                          open={open1}
                          onClose={handleClose1}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <div style={{ padding: ".5rem" }}>
                            <div className="row ">
                              <div className="col-12 ">
                                <div className="copy_plan upload">
                                  <label htmlFor="" className="new_order_label">
                                    Select Service
                                  </label>
                                  <div className="upload_title mt-1">
                                    <select
                                      style={{ padding: ".4rem 0" }}
                                      name=""
                                      id=""
                                    >
                                      {availbilty?.type === "service"
                                        ? result?.map((item) => {
                                          return (
                                            <option
                                              onClick={() => {
                                                if (change) {
                                                  handleOpen(() => {
                                                    setServiceId(
                                                      item?.vendor_sub_service_id
                                                    );

                                                    setError();
                                                  });
                                                } else {
                                                  setServiceId(
                                                    item?.vendor_sub_service_id
                                                  );

                                                  setError();
                                                }
                                              }}
                                            >
                                              {item?.name}
                                            </option>
                                          );
                                        })
                                        : result1?.map((item) => {
                                          return (
                                            <option
                                              onClick={() => {
                                                if (change) {
                                                  handleOpen(() => {
                                                    setServiceId(
                                                      item?.studio_id
                                                    );

                                                    setError();
                                                  });
                                                } else {
                                                  setServiceId(item?.studio_id);

                                                  setError();
                                                }
                                              }}
                                            >
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                  <p
                                    style={{
                                      fontWeight: "400",
                                      fontSize: "10px",
                                      lineHeight: "12px",
                                      color: "#545468",
                                      paddingTop: "6px",
                                    }}
                                  >
                                    Current Service selected
                                  </p>
                                  <label htmlFor="" className="new_order_label">
                                    Select Plan
                                  </label>
                                  <div className="select_plan">
                                    <div>
                                      <BpCheckbox /> All
                                    </div>
                                    <div style={{ fontWeight: "700" }}>
                                      <BpCheckbox checked /> Starter (Current
                                      Plan)
                                    </div>
                                    <div>
                                      <BpCheckbox /> Pro
                                    </div>
                                    <div>
                                      <BpCheckbox /> Business
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                              </div>
                              <div className="col-12 px-4">
                                <button className=" apply_copy">
                                  Apply Copy
                                </button>
                              </div>
                            </div>
                          </div>
                        </Menu>
                        {/* /////////////////////////////////////// week days////////////// */}

                        <div className="col-12">
                          <ul className="week_days d-flex m-0 p-0 my-3">
                            {day?.map((item) => {
                              return (
                                <li
                                  className={`${day_id === item.day_id ? "weekly_active" : ""
                                    }`}
                                >
                                  <a
                                    onClick={() => setDayId(item?.day_id)}
                                    href={`#${item.day_name}`}
                                  >
                                    {item.day_name}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        {/* ///////////////////////////////// week base time /////////////////////////// */}
                        <div className="col-12">
                          <div className="weekly_availability">
                            {day.map((item, idx) => {
                              return (
                                <div
                                  id={item.day_name}
                                  className="row m-0 my-3 week_data"
                                >
                                  <div className="col-lg-2 col-12 d-flex align-items-center ">
                                    <input
                                      id="dy_week"
                                      type="checkbox"
                                      checked={item?.is_active}
                                      onChange={(e) => {
                                        const newDay = [...day];
                                        newDay[idx] = {
                                          ...newDay[idx],
                                          is_active: e.target.checked,
                                        };
                                        setData(newDay);
                                        setChange(true);
                                      }}
                                    />
                                    <label htmlFor="dy_week">
                                      {item.day_name}
                                    </label>
                                  </div>
                                  <div className="col-lg-8 col-12">
                                    <ul className="week_time d-flex m-0 p-0 flex-wrap ">
                                      {item?.slots?.length === 0 ? (
                                        <p
                                          style={{
                                            fontSize: "20px",
                                            fontWeight: "500",
                                            color: "#494949",
                                            margin: "0",
                                            marginLeft: "5px",
                                          }}
                                        >
                                          No slot have been created yet
                                        </p>
                                      ) : (
                                        item?.slots?.map((items, idxx) => {
                                          return (
                                            <li>
                                              {convertTo12HourFormat(
                                                items?.start_time
                                              )}{" "}
                                              to{" "}
                                              {convertTo12HourFormat(
                                                items?.end_time
                                              )}{" "}
                                              <span
                                                onClick={() => {
                                                  handledel(item.day_id, idxx);
                                                  setChange(true);
                                                }}
                                                className="icons_week_avail"
                                              >
                                                <RiDeleteBin6Line className="me-3" />{" "}
                                                Delete
                                              </span>
                                            </li>
                                          );
                                        })
                                      )}
                                    </ul>
                                  </div>
                                  <div className="col-lg-2 col-12 d-flex justify-content-end">
                                    <div className="week_icon">
                                      <AvailabilityMUI
                                        setChange={setChange}
                                        gap={
                                          availbilty?.schedule_gap !== null
                                            ? `${availbilty?.schedule_gap?.split(
                                              ":"
                                            )[0]
                                            }.${availbilty?.schedule_gap?.split(
                                              ":"
                                            )[1]
                                            }`
                                            : "0"
                                        }
                                        duration={duration}
                                        day_id={item?.day_id}
                                        data={day}
                                        setData={setData}
                                      />

                                      <img
                                        onClick={(e) => {
                                          handleOpenUserMenu1(e);
                                          setID(item?.day_id);
                                        }}
                                        src={image2}
                                        alt=""
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}

                            <VerificationModal
                              open={open}
                              setOpen={setOpen}
                              handleSubmit={handleSubmit}
                              currentAction={currentAction}
                              setCurrentAction={setCurrentAction}
                              setChange={setChange}
                            />
                            <Menu
                              sx={{ mt: "25px", padding: "0" }}
                              id="menu-appbar"
                              anchorEl={anchorElUser1}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              keepMounted
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              open={Boolean(anchorElUser1)}
                              onClose={handleCloseUserMenu1}
                            >
                              <MenuItem
                                style={{
                                  background: "#494949",
                                  color: "white",
                                  borderBottom: "1px solid #494949",
                                  fontSize: "14px",
                                }}
                              >
                                <Typography textAlign="center">
                                  Copy Slot from
                                </Typography>
                              </MenuItem>

                              {day.map((items) => {
                                return (
                                  <>
                                    <MenuItem
                                      onClick={() => {
                                        copy(items.day_id);
                                        handleCloseUserMenu1();
                                        setChange(true);
                                      }}
                                      style={{
                                        borderBottom: "1px solid #bcbcbc",
                                      }}
                                    >
                                      <Typography textAlign="center">
                                        {
                                          <a
                                            style={{
                                              textDecoration: "none",
                                              color: "#494949",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {items.day_name}
                                          </a>
                                        }
                                      </Typography>
                                    </MenuItem>
                                  </>
                                );
                              })}

                              {/* <MenuItem
                            style={{
                              color: "#494949",
                              borderBottom: "1px solid #bcbcbc",
                              fontSize: "14px",
                            }}
                          >
                            <Typography textAlign="center">Tuesday</Typography>
                          </MenuItem>

                          <MenuItem
                            style={{
                              color: "#494949",
                              borderBottom: "1px solid #bcbcbc",
                              fontSize: "14px",
                            }}
                          >
                            <Typography textAlign="center">
                              Wednesday{" "}
                            </Typography>
                          </MenuItem>

                          <MenuItem
                            style={{
                              color: "#494949",
                              borderBottom: "1px solid #bcbcbc",
                              fontSize: "14px",
                            }}
                          >
                            <Typography textAlign="center">Thursday</Typography>
                          </MenuItem>

                          <MenuItem
                            style={{
                              color: "#494949",
                              borderBottom: "1px solid #bcbcbc",
                              fontSize: "14px",
                            }}
                          >
                            <Typography textAlign="center">Friday</Typography>
                          </MenuItem>

                          <MenuItem
                            style={{ color: "#494949", fontSize: "14px" }}
                          >
                            <Typography textAlign="center">Saturday</Typography>
                          </MenuItem> */}
                            </Menu>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>}
            </div>
          ) : (
            <div className="col-lg-9 col-12">
              <p className="error_text">Please Create Plane Or Service first</p>
            </div>
          )}
        </div>

      </div>
    </>
  );
}

export default Availability;
