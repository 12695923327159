// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
const firebaseConfig = {
  apiKey: "AIzaSyD3VUGbZsKvAuCyWmJws7fLF__NhTkX0h4",
  authDomain: "pstuduiohub.firebaseapp.com",
  projectId: "pstuduiohub",
  storageBucket: "pstuduiohub.appspot.com",
  messagingSenderId: "13006669876",
  appId: "1:13006669876:web:ebb4e9927d7a7540a42261"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
// Register the service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}
export const auth = getAuth(app);
export { messaging, getToken, onMessage };
