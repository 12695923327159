import React, { useEffect, useRef, useState } from "react";
import {IoIosCheckmarkCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius:"10px",
    p: 2,
  };
function PaymentDetail({ show, setshow, data,payment }) {
  const [active, setActive] = useState(false);
  console.log("neworderData", data);
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setActive(true);
      }, 400);
    } else {
      setActive(false);
    }
  }, [show]);
  const sidebarRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // Function to handle click outside of sidebar
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {

          setshow(false);
          setActive(false);
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setshow, setActive]);



  const getStatusClass = (status) => {
    let reStatus = determineOrderStatus(status);
    switch (status?.orderService ? reStatus : status) {
      case "Confirmed":
        return "status-confirmed";
      case "Awaiting Data":
        return "status-awaiting";
      case "Client Reviewing":
        return "status-reviewing";
      case "Partially Reviewing":
        return "status-reviewing";
      case "Revision Requested":
        return "status-revision";
      case "withdraw requested":
        return "status-original-requested";
      case "Original Data Submitted":
        return "status-original-submitted";
      case 'Ready For Complete':
        return 'status-completed';
      case "Completed":
        return "status-completed";
      case "Partially Completed":
        return "status-completed";
      case "Cancelled":
        return "status-cancel";
      case "Refund":
        return "status-refund";
      case "Pending":
        return "status-pending";
      default:
        return "";
    }
  };

  function determineOrderStatus(order) {
    if (!order || !order.orderService || !Array.isArray(order.orderService)) {
      return "Invalid order";
    }

    const serviceStatuses = order.orderService.map(
      (service) => service.orderStatus.status_name
    );
    const uniqueStatuses = new Set(serviceStatuses);

    if (uniqueStatuses.size === 1) {
      return serviceStatuses[0];
    }

    if (serviceStatuses.includes("Completed")) {
      return "Partially Completed";
    }

    if (serviceStatuses.includes("Client Reviewing")) {
      return "Partially Reviewing";
    }

    return "Multiple Different Statuses";
  }
  const calculateRemainingDays = (date) => {
    const today = new Date();
    const serviceDate = new Date(date);
    const timeDiff = serviceDate - today;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff;
  };

  const getStatusColor = (remainingDays) => {
    if (remainingDays <= 0) return "danger"; // Past due
    if (remainingDays <= 2) return "warning"; // 2 days or less
    return "normal"; // More than 2 days
  };
  const getLatestServiceDate = (orderServices) => {
    if (!orderServices || orderServices.length === 0) return null;

    const today = new Date();

    // Separate past and future dates
    const pastDates = [];
    const futureDates = [];

    orderServices.forEach((service) => {
      const date = new Date(service?.service_date);
      if (date >= today) {
        futureDates.push(date);
      } else {
        pastDates.push(date);
      }
    });

    if (futureDates.length > 0) {
      // Get the nearest upcoming date
      const nearestDate = new Date(Math.min(...futureDates));
      return nearestDate.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
    } else if (pastDates.length > 0) {
      // Get the latest past date
      const latestPastDate = new Date(Math.max(...pastDates));
      return latestPastDate.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
    }

    return null;
  };
  const getLatestSlot = (date) => {
    let slot;
    data?.orderService?.forEach((item) => {
      if (item?.service_date === date) {
        slot = item.slot;
      }
    });
  
    // If a slot is found, format the start and end times
    if (slot) {
      return `${convertTo12HourFormat(slot?.start_time)} to ${convertTo12HourFormat(slot?.end_time)}`;
    } else {
      return 'No slot available';
    }
  };
  
  const latestServiceDate = getLatestServiceDate(
    data?.orderService
  );
  let formattedLatestServiceDate = null;
  const latestSlot=getLatestSlot(latestServiceDate)
  if (latestServiceDate) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    formattedLatestServiceDate = new Date(latestServiceDate).toLocaleDateString('en-US', options);
  }

  const remainingDays = latestServiceDate
    ? calculateRemainingDays(latestServiceDate)
    : null;
  const statusColor = getStatusColor(remainingDays);

  function convertTo12HourFormat(time24) {
    const [hours24, minutes24] = time24?.split(':').map(Number);
    let hours12 = hours24 % 12 || 12; // Convert 0 to 12
    const ampm = hours24 < 12 ? 'AM' : 'PM';
    return `${hours12?.toString().padStart(2, '0')}:${minutes24?.toString().padStart(2, '0')} ${ampm}`;
  }
  return (
    <>
      <div
        style={{ background: `${active ? "rgba(0, 0, 0, 0.628)" : "none"}` }}
        className={`new_order_editor ${show ? "active_3" : ""}`}
      >
        <div className={`side_bar_example`} ref={sidebarRef}>
          <div className="row d-flex  align-items-center">
            <div className="col-9 d-flex justify-content-start align-items-center">
              <button
                className="btn_close"
                onClick={() => {
                  setshow(false);
                  setActive(false);
                }}
              >
                X
              </button>
              <h4 className="new_order_head p-0 my-0 mx-2">Payment Details</h4>
              <span className={getStatusClass(payment?.payment_status)}>
                {payment?.payment_status}
              </span>
            </div>
            <div className="col-3">
              <h6 className="order_id m-0">
                Order ID:<span>{data?.order_number}</span>
              </h6>
            </div>
          </div>
          <hr />
          <div className="row d-flex align-items-center ">
            <div className="col-lg-4 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
                Order Date:
                <span>{data?.order_confirmed_date?.split(" ")[0]}</span>
              </h6>
            </div>
         
            <div className="col-lg-4 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
                Client Name:{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {data?.shippingCharges?.name
                    ?.split(" ")
                    .filter((item, index, arr) => arr.indexOf(item) === index)
                    .join(" ")}
                </span>
              </h6>
            </div>
            <div className="col-lg-4 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
                Client Contact:<span>{data?.shippingCharges?.contact}</span>
              </h6>
            </div>
            <div className="col-lg-4 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
                Client City:<span>{data?.shippingCharges?.city?.name ? data?.shippingCharges?.city?.name : "-"}</span>
              </h6>
            </div>
            <div className="col-lg-4 col-md-6 col-12 pe-0  ">
              <h6 className="order_id me-5">
                Location:
                <span>{data?.shippingCharges?.location}</span>
              </h6>
            </div>
    
    
          </div>
          <hr />
  

          <h5>Selected Services</h5>
          {data?.orderService?.map((item) => {
             const remainingDays1 = item?.service_date
             ? calculateRemainingDays(item?.service_date)
             : null;
           const statusColor1 = getStatusColor(remainingDays1);
            return (
              <div className="row service_detail my-2">
                <div className="col-12 mb-2">
                  <div className="select_serv">
                    <div className="d-flex justify-content-start align-items-center">
                        {item?.plan?.type==="studio"?<h6>Studio Booking</h6>:
                            <h6>{item?.plan?.vendor_sub_service?.sub_service?.name}</h6>
                        }

                
                    <span style={{fontSize:"12px",marginLeft:"1rem",padding:".1rem .5rem"}} className={getStatusClass(item?.orderStatus?.status_name)}>
                      {item?.orderStatus?.status_name}
                    </span>
                    </div>
                   
                    <div className="d-flex justify-content-between align-items-center ">
                      <p className="sel_heading">
                        {item?.plan?.vendor_sub_service?.heading}
                      </p>
                    </div>
                    <div>
                      <p className="sel_time_name">
                        {formattedLatestServiceDate} | Vendor:{" "}
                        <Link to={`/profile/${item?.vendor?.username}/photographer`} style={{ textDecoration: "underline",color:"#494949" }}>
                          {/* Michele John */}
                          {item?.vendor?.name}
                        </Link>
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center ">
                      <ul className="sel_list">
                        {item?.plan?.offers?.map((items) => {
                          if (items !== null) {
                            return (
                              <li>
                                <IoIosCheckmarkCircle />
                                {items}
                              </li>
                            );
                          }
                        })}
                      </ul>
                      <p style={{whiteSpace:"nowrap"}} className="sel_price">£ {item?.subtotal}</p>
                    </div>
                    <hr style={{ marginBottom: "7px" }} />
                    {/* <button
                      className="new_order_edit_btn my-2"
                      style={{ borderRadius: "5px" }}
                      onClick={handleOpenUserMenu}
                    >
                      Update Status <IoIosArrowDown className="mx-3 " />
                    </button> */}
                    <div className="d-flex justify-content-start align-items-center">
                    
                    <h6 className="order_id mx-3">
                      Booking Date:  <span
                        className={`service-date ${statusColor1}`}
                      >
                        {item?.service_date}
                      </span>
                    </h6>
                    <h6 className="order_id">
                      Booking Slot:<span>{convertTo12HourFormat(item?.slot?.start_time)} to {convertTo12HourFormat(item?.slot?.end_time)}</span>
                    </h6>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <hr />
          <div className="row service_detail payment_detail my-2">
            <div className="col-lg-4 col-12 my-2">
              <div className="row service_detail my-2">
                <div className="col-9">
                  <h5>Service Amount</h5>
                </div>
                <div className="col-2">
                  <h5 className="sub_total">£{parseFloat(payment?.order_amount).toFixed(2)}</h5>
                </div>
              </div>
              <div className="row service_detail my-2">
                <div className="col-9">
                  <h5>platform ({payment?.commission_value}%)</h5>
                </div>
                <div className="col-2">
                  <h5 className="sub_total">£{parseFloat((payment?.order_amount * payment?.commission_value) /100).toFixed(2)}</h5>
                </div>
              </div>
              <div className="row service_detail my-2">
                <div className="col-9">
                  <h4>Total Payable</h4>
                </div>
                <div className="col-2">
                  <h4 className="sub_total">£{parseFloat(payment?.order_amount-((payment?.order_amount * payment?.commission_value) /100)  ).toFixed(2)}</h4>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row d-flex align-items-center ">
            <div className="col-lg-6 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
                Date and time of Payment:
                <span className="status-completed">{data?.order_confirmed_date?.split(" ")[0]}</span>
              </h6>
            </div>
         
            <div className="col-lg-6 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
              Payment Method:{" "}
                <span style={{ textTransform: "capitalize",color:"#6772E5",background:"#CCD0FF",padding:".3rem .5rem",borderRadius:"5px" }}>
                 Stripe
                </span>
              </h6>
            </div>
            <div className="col-lg-4 col-md-6 col-12 pe-0 mt-3">
              <h6 className="order_id me-5">
                Payment Status:<span className={getStatusClass(payment?.payment_status)}>
                {payment?.payment_status}
              </span>
              </h6>
            </div>
          </div>
          <hr />
          {payment?.orderService?.payoutLogs?<div className="row d-flex align-items-center">
            <div className="col-lg-6 col-md-6 col-12 pe-0 ">
              <h6 className="order_id me-5">
                Payout History:
                <span style={{cursor:"pointer",textDecoration:"underline"}} onClick={()=>handleOpen()}>View</span>
              </h6>
            </div>
            </div>:""}
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className="container-fluid m-0 p-0">
        <div className="ordr_comp_head">
                <h1>Payout Logs</h1>
                <div  onClick={handleClose} >X</div>
              </div>
              <hr/>
                <div className="row m-0">
                    <div className="development-process">
                        <div className=" col-12 p-0">
                         {payment?.orderService?.payoutLogs?.map((item,i)=>{
                            const dateStr = item?.created_at; // Original date string
                            const dateObj = new Date(dateStr); // Convert to Date object
                            
                            const formattedDate = dateObj.toLocaleString('en-US', {
                              month: 'short',  // Short month format (e.g., Sep)
                              day: '2-digit',  // 2-digit day format (e.g., 20)
                              year: 'numeric', // Numeric year format (e.g., 2024)
                              hour: 'numeric', // Numeric hour format (e.g., 3)
                              minute: '2-digit', // 2-digit minute format (e.g., 13)
                              hour12: true // 12-hour format with AM/PM
                            });
                            return(
                                <>{i%2===0?<div style={{overflow:"hidden"}} className="row m-0">
                                <div className="col-lg-5 col-sm-10 pb-5 col-12 p-0 order-lg-1 order-2">
                                    <div className="development-textbox">
                                   {item?.withdraw_status==="pending"&&<>
                                    <h5>Request sent for 
                                      Payout £ {item?.amount_withdrawed}</h5>
                                      <h4>{formattedDate}</h4>
                                   </>}
                                   {item?.withdraw_status==="declined"&&<>
                                    <h5>Request Rejected for 
                                      Payout £ {item?.amount_withdrawed?item?.amount_withdrawed:payment?.order_amount}</h5>
                                      <h4>{formattedDate}</h4>
                                   </>}
                                   {item?.withdraw_status==="completed"&&<>
                                    <h5>Reqest accepted and transferred for
                                      Payout £ {item?.amount_withdrawed?item?.amount_withdrawed:payment?.order_amount}</h5>
                                      <h4>{formattedDate}</h4>
                                   </>}
                                    
                                    </div>
                                </div>
                                <div className="col-2 p-0 text-center order-lg-2 order-1">
                                    <div className="step-box">
                                        <div className="step"><div className="step-num">{i+1}</div></div>
                                    </div>
                                 {i!==payment?.orderService?.payoutLogs?.length-1&&   <div className="line d-lg-flex d-none"><div className="step-line"></div></div>}
                                </div>

                            </div>:<div style={{overflow:"hidden"}} className="row mx-0 p-0">
                             <div className="col-lg-5"></div>
                                <div className="col-2 p-0 text-center order-lg-2 order-1">
                                    <div className="step-box">
                                        <div className="step"><div className="step-num">{i+1}</div></div>
                                    </div>
                                    {i!==payment?.orderService?.payoutLogs?.length-1&&   <div className="line d-lg-flex d-none"><div className="step-line"></div></div>}
                                </div>
                                <div className="col-lg-5 col-10 pb-5 order-lg-3 order-2">
                                <div className="development-textbox">
                                {item?.withdraw_status==="pending"&&<>
                                    <h5>Request sent for 
                                      Payout £ {item?.amount_withdrawed}</h5>
                                      <h4>{formattedDate}</h4>
                                   </>}
                                {item?.withdraw_status==="declined"&&<>
                                    <h5>Request Rejected for 
                                      Payout £ {item?.amount_withdrawed?item?.amount_withdrawed:payment?.order_amount}</h5>
                                      <h4>{formattedDate}</h4>
                                   </>}
                                {item?.withdraw_status==="completed"&&<>
                                    <h5>Reqest accepted and transferred for
                                      Payout £ {item?.amount_withdrawed?item?.amount_withdrawed:payment?.order_amount}</h5>
                                      <h4>{formattedDate}</h4>
                                   </>}
                                </div>
                                </div>
                            </div>}
                                
                                </>
                            )
                         })}
                            
                            {/* Step 2 */}
                            
                            {/* Step 3 */}
                            {/* <div style={{overflow:"hidden"}} className="row m-0">
                                <div className="col-lg-5 col-sm-10 pb-5 col-12 p-0 order-lg-1 order-2">
                                    <div className="development-textbox">
                                      <h5>Request sent for 
                                      Payout Rs 56000</h5>
                                      <h4>Sep 20, 2024, 3.13 PM</h4>
                                    
                                    </div>
                                </div>
                                <div className="col-2 p-0 text-center order-lg-2 order-1">
                                    <div className="step-box">
                                        <div className="step"><div className="step-num">3</div></div>
                                    </div>
                                    <div className="line d-lg-flex d-none"><div className="step-line"></div></div>
                                </div>

                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </Box>
      </Modal>
        </div>
      </div>
    </>
  );
}

export default PaymentDetail;
