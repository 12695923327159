import React, { useEffect, useState } from "react";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { FaRegEye } from "react-icons/fa";
import Menu from "@mui/material/Menu";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { CgSortAz } from "react-icons/cg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useAuth } from "../../Context/ContextProvider";
import NewOrderEdit from "./NewOrderEdit";
import dayjs from "dayjs";
import { IoCalendarNumberOutline } from "react-icons/io5";
import RefungRequestModel from "./RefungRequestModel";
import { IconButton, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
function RejectOrder() {
  const { getOrder, orderData, buttonLoding } = useAuth();

  const [pagination, setPagination] = useState({
    entry: 10,
    page: 1,
    id: "cancelled",
    first_name: "",
    order_number: "",
    total: "",
    order_status: "",
    added_date: "",
    update_date: "",
  });
  useEffect(() => {
    getOrder(pagination);
    // eslint-disable-next-line
  }, []);
  const handleDateChange = (date) => {
    const adjustedDate = dayjs(date[0]).format("YYYY-MM-DD");
    const adjustedDate1 = dayjs(date[1]).format("YYYY-MM-DD");
    setPagination({
      ...pagination,
      added_date: adjustedDate + "to" + adjustedDate1,
    });
    getOrder({
      ...pagination,
      added_date: adjustedDate + "to" + adjustedDate1,
    });
  };

  const handlePageChange = (event, value) => {
    setPagination({ ...pagination, page: value });
    getOrder({ ...pagination, page: value }); // Use the new page number provided by MUI
    // Perform your data fetching or state update here
  };

  const [plus, setPlus] = useState([]);
  const toggleSubtable = (idx) => {
    setPlus((item) => {
      const isVisible = item.includes(idx);
      if (isVisible) {
        return item.filter((i) => i !== idx);
      } else {
        return [...item, idx];
      }
    });
  };
  const [show, setshow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const [refund, setrefund] = useState({
    order_id: "",

    order_service_id: "",

    vendor_id: "",
    customer_id: "",
    plan_id: "",

    price: "",
  });

  const [open2, setOpen2] = React.useState(false);
  const handleOpen1 = () => setOpen2(true);

  const [data, setData] = useState({});
  const getStatusClass = (status) => {
    let reStatus = determineOrderStatus(status)?.classname;


    switch (status?.orderService ? reStatus : status) {
      case "PE":
        return "status-cancel";
      case "RRR ":
        return "status-cancel";
      case "Partially Cancelled":
        return "status-cancel";
      case "RR":
        return "status-revision";
      case "DI":
      default:
        return "";
    }
  };
  function determineOrderStatus(order) {
    if (!order || !order.orderService || !Array.isArray(order.orderService)) {
      return "Invalid order";
    }



    const serviceStatuses = order.orderService.map(
      (service) => service.orderStatus.client_label
    );

    const serviceStatuses1 = order.orderService.map(
      (service) => service.orderStatus.status_code
    );
    const uniqueStatuses = new Set(serviceStatuses);

    if (uniqueStatuses.size === 1) {
      return {
        show: serviceStatuses[0],
        classname: serviceStatuses1[0]
      };
    }

    if (serviceStatuses.includes("PE")) {
      return "Partially Cancelled";
    }

    if (serviceStatuses.includes("RR")) {
      return "Partially Refund Request";
    }

    if (serviceStatuses.includes("DI")) {
      return "Partially Refunded";
    }
    if (serviceStatuses.includes("RRR")) {
      return "Partially Refund Reject";
    }

    return "Multiple Different Statuses";
  }
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [selectedOrderService, setSelectedOrderService] = useState(null); // To track selected order/service

  const handleMenuClick = (event, order, service) => {
    setAnchorElMenu(event.currentTarget); // Attach the clicked anchor element
    setSelectedOrderService({ order, service }); // Track which order and service is selected
  };

  const handleMenuClose = () => {
    setAnchorElMenu(null);
    setSelectedOrderService(null); // Reset when closing the menu
  };


  const menuOpen = Boolean(anchorElMenu);
  return (
    <div>
      <div className="table_box" style={{ borderRadius: "0px" }}>
        <h3>Cancel Order</h3>

        <div className="filter_main">
          <div className="d-flex justify-content-between">
            {/* =========================Search and date============ */}
            <div className="d-flex align-items-center">
              <label>
                <CiSearch className="icons" />
                <input
                  onChange={(e) => {
                    setTimeout(() => {
                      getOrder({ ...pagination, first_name: e.target.value });
                    }, 2000);
                  }}
                  type="search"
                  placeholder="Search"
                />
              </label>
              <span onClick={handleClick}>
                Date <IoCalendarNumberOutline className="icons" />
              </span>
              {/* <span onClick={handleClick1}>
                Filter <VscSettings className="icons" />
              </span> */}
            </div>

            {/* ======================= show entries==================== */}
            <div className="d-flex align-items-center">
              <di>Show entries:</di>
              <select
                value={pagination?.entry}
                style={{
                  width: "200px",
                  border: "1px solid lightgray",
                  padding: ".5rem",
                  borderRadius: "5px",
                  outline: "none",
                }}
                onChange={(e) => {
                  if (orderData?.meta?.total < e.target.value) {
                    setPagination({
                      ...pagination,
                      entry: e.target.value,
                      page: 1,
                    });
                    getOrder({ ...pagination, entry: e.target.value, page: 1 });
                  } else {
                    setPagination({ ...pagination, entry: e.target.value });
                    getOrder({ ...pagination, entry: e.target.value });
                  }
                }}
              >
                <option value={1}>10</option>
                <option value={2}>20</option>
                <option value={3}>30</option>
              </select>
            </div>
            {/* ======================= show entries end==================== */}
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            style={{ marginLeft: "-180px", marginTop: ".2rem" }}
            open={open1}
            onClose={handleClose1}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div style={{ height: "450px", padding: ".5rem" }}>
              <div className="row">
                <div className="col-12">
                  <div className="row" style={{ margin: "1rem" }}>
                    <div
                      style={{
                        background: "#494949",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        borderRadius: "5px",
                      }}
                      className="col-6 py-2"
                    >
                      <span>From</span>
                    </div>
                    <div
                      style={{
                        background: "#F0F0F0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="col-6 py-2"
                    >
                      <span>To</span>
                    </div>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DateRangeCalendar", "DateRangeCalendar"]}
                    >
                      <DemoItem>
                        <DateRangeCalendar
                          onChange={handleDateChange}
                          calendars={1}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </Menu>
        </div>

        <div className="confirm_order_table">
          <table className="table nestedTable1 custom-padding table-sm my-3 ">
            <thead>
              <tr style={{ position: "sticky", top: "0", width: "100%" }}>
                <th scope="col" style={{ width: '1%' }}>
                  {" "}
                  {/* <BpCheckbox /> */}
                </th>
                <th scope="col" className="oder_th">
                  Order ID <CgSortAz />
                </th>
                <th scope="col" className="oder_th px-0">
                  Order Date <CgSortAz />
                </th>
                <th scope="col" className="oder_th">
                  Cancel Date
                </th>
                <th scope="col" className="oder_th">
                  Vendor Name
                </th>
                <th scope="col" className="oder_th px-0">
                  Order Amount
                </th>
                <th scope="col" className="oder_th text-center" style={{ width: '17%' }}>
                  Status
                </th>
                <th scope="col" className="oder_th text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {buttonLoding ? (
                <tr>
                  <td colSpan={10}>
                    <div style={{ height: "400px", width: "100%" }}>
                      <p
                        style={{ height: "50px" }}
                        className="placeholder-glow row"
                      >
                        <span className="placeholder col-12 h-100 rounded"></span>
                      </p>
                      <p
                        style={{ height: "50px" }}
                        className="placeholder-glow row"
                      >
                        <span className="placeholder col-12 h-100 rounded"></span>
                      </p>
                      <p
                        style={{ height: "50px" }}
                        className="placeholder-glow row"
                      >
                        <span className="placeholder col-12 h-100 rounded"></span>
                      </p>
                      <p
                        style={{ height: "50px" }}
                        className="placeholder-glow row"
                      >
                        <span className="placeholder col-12 h-100 rounded"></span>
                      </p>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {
                    orderData?.data?.length ?
                      orderData?.data?.map((item, i) => {
                        return (
                          <>
                            {
                              item?.orderService?.length === 1 ?
                                <>
                                  {

                                    item?.orderService?.map((items) => {

                                      return (
                                        <tr>
                                          <td colSpan={9} className="p-0">
                                            <table className="table nestedTable table-sm m-0 ">
                                              <tbody>
                                                <tr>
                                                  <th
                                                    style={{ width: '3%' }}
                                                    scope="row"
                                                  >
                                                    {" "}
                                                    {/* <BpCheckbox /> */}
                                                  </th>
                                                  <td>{item?.order_number}</td>
                                                  <td>
                                                    {
                                                      item?.order_confirmed_date?.split(
                                                        " "
                                                      )[0]
                                                    }
                                                    <br />
                                                    <td>{items?.date}</td>
                                                  </td>
                                                  <td>
                                                    {items?.order_cancellation_date?.split(
                                                      " "
                                                    )[0]}
                                                  </td>
                                                  <td>{items?.vendor?.name}</td>
                                                  <td>£{items?.subtotal}</td>
                                                  <td
                                                    style={{
                                                      width: "17%",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <span className={getStatusClass(
                                                      items?.orderStatus?.status_code
                                                    )}>
                                                      {items?.orderStatus?.client_label}
                                                    </span>
                                                  </td>
                                                  <td>
                                                    <IconButton
                                                      aria-controls="order-menu"
                                                      aria-haspopup="true"
                                                      onClick={(e) => handleMenuClick(e, item, items)}
                                                    >
                                                      <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                      id={`order-menu-${item.id}-${items.id}`} // Unique ID per menu
                                                      anchorEl={anchorElMenu}
                                                      keepMounted
                                                      open={menuOpen && selectedOrderService?.order?.id === item.id && selectedOrderService?.service?.id === items.id}
                                                      onClose={handleMenuClose}
                                                    >
                                                      <MenuItem
                                                        onClick={() => {
                                                          setData(item);
                                                          setshow(true);
                                                          handleMenuClose();
                                                        }}
                                                      >
                                                        View <FaRegEye className="ms-1" />
                                                      </MenuItem>
                                                      {items?.refunded_type !== "No Refund" && <MenuItem
                                                        style={{
                                                          background: `${items?.orderStatus
                                                            ?.status_code === "DI" || items?.orderStatus
                                                              ?.status_code === "RR"
                                                            ? "#B6B6B6"
                                                            : ""
                                                            }`,
                                                          cursor: `${items?.orderStatus
                                                            ?.status_code === "DI" || items?.orderStatus
                                                              ?.status_code === "RR"
                                                            ? "not-allowed"
                                                            : ""
                                                            }`,
                                                        }}
                                                        disabled={items?.refunded_type === "No Refund" ? true : false}  // Modify this as per your condition
                                                        onClick={() => {
                                                          // setData(item)
                                                          if (
                                                            items?.orderStatus
                                                              ?.status_name !== "DI" || items?.orderStatus
                                                                ?.status_code !== "RR"
                                                          ) {
                                                            handleOpen1();
                                                            setrefund({
                                                              order_id: item?.id,
                                                              order_service_id: items?.id,
                                                              vendor_id: items?.plan?.vendor_id,
                                                              customer_id: item?.customer?.id,
                                                              plan_id: items?.plan?.id,
                                                              price: items?.plan?.price_set,
                                                            });
                                                          }
                                                          handleMenuClose();
                                                        }}
                                                      >
                                                        Refund Reqest{" "}
                                                        <FaRegEye className="ms-1" />
                                                      </MenuItem>}
                                                    </Menu>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      );

                                    })
                                  }</>
                                :
                                <>
                                  <tr>
                                    <th scope="row" style={{ width: '0%' }}>
                                      {" "}
                                      {plus.includes(i) ? (
                                        <FaSquareMinus
                                          onClick={() => toggleSubtable(i)}
                                        />
                                      ) : (
                                        <FaSquarePlus onClick={() => toggleSubtable(i)} />
                                      )}
                                    </th>
                                    <td>{item?.order_number}</td>
                                    <td>
                                      {item?.order_confirmed_date?.split(" ")[0]}
                                      <br />
                                      {item?.date}
                                    </td>
                                    <td>{item?.orderService[0]?.order_cancellation_date?.split(' ')[0]} </td>
                                    <td>{item?.orderService?.length ? item?.orderService[0].vendor?.name : "-"}</td>
                                    <td>£{item?.total}</td>
                                    {/* <td >
              <span className="new_confirm">Confirmed</span>
              </td> */}
                                    <td style={{ width: "17%", textAlign: "center" }}>
                                      {/* <span className="service-date danger">
                              {item?.orderStatus?.status_name}
                            </span> */}
                                      <span className={getStatusClass(item)}>
                                        {determineOrderStatus(item)?.show}
                                      </span>
                                    </td>

                                    <td>
                                      <IconButton
                                        aria-controls="order-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => handleMenuClick(e, item, item)}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        id={`order-menu-${item.id}-${item.id}`} // Unique ID per menu
                                        anchorEl={anchorElMenu}
                                        keepMounted
                                        open={menuOpen && selectedOrderService?.order?.id === item.id && selectedOrderService?.service?.id === item.id}
                                        onClose={handleMenuClose}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            setData(item);
                                            setshow(true);
                                            handleMenuClose();
                                          }}
                                        >
                                          View <FaRegEye className="ms-1" />
                                        </MenuItem>

                                      </Menu>
                                    </td>
                                  </tr>
                                  {plus.includes(i) &&
                                    item?.orderService?.map((items) => {

                                      return (
                                        <tr>
                                          <td colSpan={9} className="p-0">
                                            <table className="table nestedTable table-sm m-0 ">
                                              <tbody>
                                                <tr>
                                                  <th
                                                    style={{ width: '3%' }}
                                                    scope="row"
                                                  >
                                                    {" "}
                                                    {/* <BpCheckbox /> */}
                                                  </th>
                                                  <td>{item?.order_number}</td>
                                                  <td>
                                                    {
                                                      item?.order_confirmed_date?.split(
                                                        " "
                                                      )[0]
                                                    }
                                                    <br />
                                                    <td>{items?.date}</td>
                                                  </td>
                                                  <td>
                                                    {items?.order_cancellation_date?.split(
                                                      " "
                                                    )[0]}
                                                  </td>
                                                  <td>{items?.vendor?.name}</td>
                                                  <td>£{items?.subtotal}</td>
                                                  {/* <td >
                 <span className="new_confirm">Confirmed</span>
                 </td> */}
                                                  <td
                                                    style={{
                                                      width: "17%",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <span className={getStatusClass(
                                                      items?.orderStatus?.status_code
                                                    )}>
                                                      {items?.orderStatus?.client_label}
                                                    </span>
                                                  </td>
                                                  <td style={{ width: "19%" }}>
                                                    <ul className="New_order_icons">
                                                      <li
                                                        onClick={() => {
                                                          setData(item)
                                                          setshow(true);
                                                        }}
                                                      >
                                                        View <FaRegEye className="ms-1" />
                                                      </li>
                                                      <li
                                                        style={{
                                                          background: `${items?.orderStatus
                                                            ?.status_code === "DI" || items?.orderStatus
                                                              ?.status_code === "RR"
                                                            ? "#B6B6B6"
                                                            : ""
                                                            }`,
                                                          cursor: `${items?.orderStatus
                                                            ?.status_code === "DI" || items?.orderStatus
                                                              ?.status_code === "RR"
                                                            ? "not-allowed"
                                                            : ""
                                                            }`,
                                                        }}
                                                        onClick={() => {
                                                          // setData(item)
                                                          if (
                                                            items?.orderStatus
                                                              ?.status_name !== "DI" || items?.orderStatus
                                                                ?.status_code !== "RR"
                                                          ) {
                                                            handleOpen1();
                                                            setrefund({
                                                              order_id: item?.id,
                                                              order_service_id: items?.id,
                                                              vendor_id: items?.plan?.vendor_id,
                                                              customer_id: item?.customer?.id,
                                                              plan_id: items?.plan?.id,
                                                              price: items?.plan?.price_set,
                                                            });
                                                          }
                                                        }}
                                                      >
                                                        Refund Reqest{" "}
                                                        <FaRegEye className="ms-1" />
                                                      </li>
                                                    </ul>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      );

                                    })}
                                </>
                            }
                          </>
                        );
                      })
                      :
                      <tr>
                        <td colSpan={10} style={{ border: "none" }}>
                          <h5 className="text-center">No Order Found !</h5>
                        </td>
                      </tr>
                  }
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="paginations d-flex justify-content-end my-3">
          <Stack spacing={2}>
            <Pagination
              count={orderData?.meta?.last_page}
              onChange={handlePageChange}
              shape="rounded"
            />
          </Stack>
        </div>
      </div>
      <NewOrderEdit show={show} setshow={setshow} data={data} setData={setData} />
      <RefungRequestModel open2={open2} setOpen2={setOpen2} refund={refund} pagination={pagination} />
    </div>
  );
}

export default RejectOrder;
