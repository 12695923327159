import React, { useEffect, useState } from 'react'
import { useAuth } from '../../Context/ContextProvider';

function ContactDetail() {
  const { number,
    ContactNumber,
  } = useAuth();
  useEffect(() => {

    ContactNumber();
    // eslint-disable-next-line
  }, []);
  const [detail,setDetail]=useState(true)
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';  // Return empty string if no number is present
  
    // Manually insert spaces at specific positions
    const formattedNumber = phoneNumber.slice(0, 3) + " " + 
                            phoneNumber.slice(3, 7) + " " + 
                            phoneNumber.slice(7, 17);
  

    return formattedNumber;
  };
  
  const phoneNumber = number?.contact;
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  return (
    <div style={{height:"720px"}}>
    <div style={{ position: "relative" }} className="container-fluid p-0 pt-3 d-flex mt-5">
      <iframe
        title="map"
        className='map_contect'
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2373.368396132889!2d-2.2459296229955483!3d53.49761426339415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb1cb1e834109%3A0xf11538400d923e81!2s74h%20Sherborne%20St%2C%20Cheetham%20Hill%2C%20Manchester%20M8%208HU%2C%20UK!5e0!3m2!1sen!2s!4v1710587658792!5m2!1sen!2s"
        width="100%"
        height="720"
        style={{ border: "0", position: "absolute" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      {!detail?<div style={{ position: "absolute" }} className='d-flex  justify-content-center w-100 mt-4'>
                    <button  onClick={()=>setDetail(true)} style={{ width: "180px", padding: ".5rem", background: "#494949", color: "white", border: "none", borderRadius: "5px", fontWeight: "600" }}>View on Map</button>

                  </div>:""}
      {detail?<div style={{ position: "absolute" }} className="contact_back">

        <div className="container">
          <div className="row">
            <div className="col-12 my-2">
              <div className="contact_detail">
                <h3 className='text-center'>
                  Discover Us
                </h3>
                <p className='py-3 mb-3  text-center'>
                  Our experts are available to answer any questions you might have. We’ve got the answers.
                </p>
                <h3 className='pb-2 text-center'>
                  Visit Us
                </h3>
                <p className=' text-center' style={{ textDecoration: "underline", ineHeight: "30px" }}>
                  {/* 74H Sherborne Street, M8 8HU, Manchester , United Kingdom */}
                  {number?.address_2}

                  <br />
                  <br />

                </p>

                <div className="row">
                  <div className="col-lg-6 col-12 d-flex  justify-content-end">
                    <div>

                      <h3 className='pb-2'>
                        Email Us
                      </h3>
                      <p className="email" style={{ textDecoration: "underline" }}>
                        <a href={`mailto:${number?.system_email}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                          {number?.system_email}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 d-flex  justify-content-start ps-5">
                    <div className='ms-4'>
                      <h3 className='pb-2'>
                        Call Us
                      </h3>
                      <p className="email" style={{ textDecoration: "underline" }}>
                        <a href={`https://wa.me/${number?.contact}`} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', textDecoration: 'none' }}>
                          {formattedPhoneNumber}
                        </a>
                      </p>

                    </div>
                  </div>
                  <div className='d-flex  justify-content-center'>
                    <button  onClick={()=>setDetail(false)} style={{ width: "140px", marginTop:'32px', padding:  ".3rem", background: "", color: "#494949", border: "none", borderRadius: "5px", fontWeight: "600" }}>View on Map</button>

                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>:""}
    </div>
    </div>
  )
}

export default ContactDetail
