import React, { useEffect, useState } from "react";
import { IoCalendarNumberOutline, IoCheckmark} from "react-icons/io5";
import { CiSearch} from "react-icons/ci";
import { FaRegEye } from "react-icons/fa";
import NewOrderEdit from "./NewOrderEdit";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { CgSortAz } from "react-icons/cg";
import { useAuth } from "../../Context/ContextProvider";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import CancelModal from "./Order/CancelModal";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import Reschedule from "./Order/Reschedule";
function NewOrder() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [show, setshow] = useState(false);
  const[cancelMessage,setCancelMessage]=useState()
  const[cancellationFee,setCancellationFee]=useState()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cancelled_data,setcancelled_data]=useState({
    cancellationTime : "",    
    cancellationPolicy :"",    
    cancellationPercentage : "",    
    afterCancelPrice : ""    
})
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const { orderData, getOrder, buttonLoding } = useAuth();
  const [pagination, setPagination] = useState({
    entry: 10,
    page: 1,
    id: "new",
    first_name: "",
    order_number: "",
    total: "",
    order_status: "",
    added_date: "",
    update_date: "",
  });
  useEffect(() => {
    getOrder(pagination);
    // eslint-disable-next-line
  }, []);
  const handleDateChange = (date) => {
    const adjustedDate = dayjs(date[0]).format("YYYY-MM-DD");
    const adjustedDate1 = dayjs(date[1]).format("YYYY-MM-DD");
    setPagination({
      ...pagination,
      added_date: adjustedDate + "to" + adjustedDate1,
    });
    getOrder({
      ...pagination,
      added_date: adjustedDate + "to" + adjustedDate1,
    });
  };
  const handlePageChange = (event, value) => {
    setPagination({ ...pagination, page: value });
    getOrder({ ...pagination, page: value }); // Use the new page number provided by MUI
    // Perform your data fetching or state update here
  };
  const [data, setData] = useState();
  const [cancelAble, setcancelAble] = React.useState(true);
  const [orderId, setOderId] = useState({
    order_id: "",
    order_service_id: "",
  });
  const [plus, setPlus] = useState([]);
  const toggleSubtable = (idx) => {
    setPlus((item) => {
      const isVisible = item.includes(idx);
      if (isVisible) {
        return item.filter((i) => i !== idx);
      } else {
        return [...item, idx];
      }
    });
  };
  function isCancellationTimeRemaining(orderService) {
    const currentTime = new Date();
  
    if (orderService.booking_service_type === "hourly_base") {
      // Calculate remaining time based on service start time for hourly bookings
      const [year, month, day] = orderService.service_date.split("-").map(Number);
      const [startHour, startMinute] = orderService.start_service_time.split(":").map(Number);
      const serviceStartDateTime = new Date(year, month - 1, day, startHour, startMinute);
  
      if (orderService.cancellation_time) {
        const hours = parseInt(orderService.cancellation_time);
        const cancellationDeadline = new Date(serviceStartDateTime);
        cancellationDeadline.setHours(cancellationDeadline.getHours() - hours);
  
        return {
          canCancel: currentTime < cancellationDeadline,
          remainingHours: (cancellationDeadline - currentTime) / (1000 * 60 * 60),
          isSameDay: currentTime.toDateString() === serviceStartDateTime.toDateString(),
        };
      }
    } else if (orderService.booking_service_type === "item_quantity") {
      // Calculate remaining time based on `created_at` for item-based bookings
      const createdAt = new Date(orderService.created_at);
      
      if (orderService.cancellation_time) {
        const hours = parseInt(orderService.cancellation_time);
        const cancellationDeadline = new Date(createdAt);
        cancellationDeadline.setHours(cancellationDeadline.getHours() + hours);
  
        return {
          canCancel: currentTime < cancellationDeadline,
          remainingHours: (cancellationDeadline - currentTime) / (1000 * 60 * 60),
        };
      }
    }
  
    return { canCancel: false, remainingHours: 0 };
  }

  // Example usage:
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [selectedOrderService, setSelectedOrderService] = useState(null); // To track selected order/service

  const handleMenuClick = (event, order, service) => {
    setAnchorElMenu(event.currentTarget); // Attach the clicked anchor element
    setSelectedOrderService({ order, service }); // Track which order and service is selected
  };

  const handleMenuClose = () => {
    setAnchorElMenu(null);
    setSelectedOrderService(null); // Reset when closing the menu
  };
  const menuOpen = Boolean(anchorElMenu);
  return (
    <div>
      <div className="table_box" style={{ borderRadius: "0px" }}>
        <h3>Pending Order</h3>

        <div className="filter_main">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <label>
                <CiSearch className="icons" />
                <input
                  onChange={(e) => {
                    setTimeout(() => {
                      getOrder({ ...pagination, first_name: e.target.value });
                    }, 2000);
                  }}
                  type="search"
                  placeholder="Search"
                />
              </label>
              <span onClick={handleClick}>
                Date <IoCalendarNumberOutline className="icons" />
              </span>
            </div>
            {/* ======================= show entries==================== */}
            <div className="d-flex align-items-center">
              <div>Show entries:</div>
              <select
                value={pagination?.entry}
                style={{
                  width: "200px",
                  border: "1px solid lightgray",
                  padding: ".5rem",
                  borderRadius: "5px",
                  outline: "none",
                }}
                onChange={(e) => {
                  if (orderData?.meta?.total < e.target.value) {
                    setPagination({
                      ...pagination,
                      entry: e.target.value,
                      page: 1,
                    });
                    getOrder({ ...pagination, entry: e.target.value, page: 1 });
                  } else {
                    setPagination({ ...pagination, entry: e.target.value });
                    getOrder({ ...pagination, entry: e.target.value });
                  }
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </select>
            </div>
            {/* ======================= show entries end==================== */}
          </div>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            style={{ marginLeft: "-180px", marginTop: ".2rem" }}
            open={open1}
            onClose={handleClose1}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div style={{ height: "450px", padding: ".5rem" }}>
              <div className="row">
                <div className="col-12">
                  <div className="row" style={{ margin: "1rem" }}>
                    <div
                      style={{
                        background: "#494949",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        borderRadius: "5px",
                      }}
                      className="col-6 py-2"
                    >
                      <span>From</span>
                    </div>
                    <div
                      style={{
                        background: "#F0F0F0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="col-6 py-2"
                    >
                      <span>To</span>
                    </div>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DateRangeCalendar", "DateRangeCalendar"]}
                    >
                      <DemoItem>
                        <DateRangeCalendar
                          onChange={handleDateChange}
                          calendars={1}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </Menu>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl2}
            style={{ marginLeft: "-180px", marginTop: ".2rem" }}
            open={open2}
            onClose={handleClose2}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              className="filter_dashbord"
              style={{ width: "300px", height: "370px", padding: ".5rem" }}
            >
              <h5>Filter</h5>
              <p style={{ fontSize: "12px", color: "#53545C" }}>Order Status</p>
              <ul className="filter_ul">
                <li>
                  <input type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                  <span
                    style={{ color: "#0C9ED3", background: "#DFF7FF" }}
                    className="expired"
                  >
                    Confirmed
                  </span>
                </li>
                <li>
                  <input type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                  <span
                    className="expired"
                    style={{ color: "#74B087", background: "#DEEEE8" }}
                  >
                    Completed
                  </span>
                </li>
                <li>
                  <input type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                  <span
                    className="expired"
                    style={{ color: "#5E78F2", background: "#E4E8FD" }}
                  >
                    Accepted
                  </span>
                </li>
                <li>
                  <input type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                  <span
                    className="expired"
                    style={{ color: "#624BA2", background: "#D8CAFF" }}
                  >
                    Expired
                  </span>
                </li>
                <li>
                  <input type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                  <span
                    className="expired"
                    style={{ color: "#FEB723", background: "#F9FFB8" }}
                  >
                    Pending
                  </span>
                </li>
                <li>
                  <input type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                  <span
                    className="expired"
                    style={{ color: "#CC5C1D", background: "#FFF2EB" }}
                  >
                    Rejected
                  </span>
                </li>
              </ul>
              <button>Filter</button>
            </div>
          </Menu>
        </div>

        <div className="order_tables">
          <table className="table table-sm ">
            <thead style={{ position: "sticky", top: "0" }}>
              <tr>
                <th scope="col" className="oder_th">
                  {" "}
                  {/* <BpCheckbox /> */}
                </th>
                <th scope="col" className="oder_th">
                  Order ID <CgSortAz />
                </th>
                <th scope="col" className="oder_th">
                  Order Date <CgSortAz />
                </th>
                <th scope="col" className="oder_th">
                  Due Date <CgSortAz />
                </th>
                <th scope="col" className="oder_th">
                  Order Amount
                </th>
                <th scope="col" className="oder_th">
                  Order Status
                </th>
                <th className="text-center oder_th" scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {buttonLoding ? (
                <tr>
                  <td colSpan={10}>
                    <div style={{ height: "400px", width: "100%" }}>
                      <p
                        style={{ height: "50px" }}
                        className="placeholder-glow row"
                      >
                        <span className="placeholder col-12 h-100 rounded"></span>
                      </p>
                      <p
                        style={{ height: "50px" }}
                        className="placeholder-glow row"
                      >
                        <span className="placeholder col-12 h-100 rounded"></span>
                      </p>
                      <p
                        style={{ height: "50px" }}
                        className="placeholder-glow row"
                      >
                        <span className="placeholder col-12 h-100 rounded"></span>
                      </p>
                      <p
                        style={{ height: "50px" }}
                        className="placeholder-glow row"
                      >
                        <span className="placeholder col-12 h-100 rounded"></span>
                      </p>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {orderData?.data?.length ? (
                    orderData?.data?.map((item, i) => {
                      return (
                        <>
                        {
                          item?.orderService?.length===1?
                          <>
                          {
                             item?.orderService?.map((items) => {
                              return (
                                <tr>
                                  <th scope="row">
                                    {" "}
                                    {/* <BpCheckbox /> */}
                                  </th>
                                  <td>{item?.order_number}</td>
                                  <td>{item?.created_at?.split("T")[0]}</td>
                                  <td>{item?.orderService[0]?.service_date}</td>
                                  <td>£{items?.total}</td>
                                  <td>
                                    <span className="pending">Pending</span>
                                  </td>
                                  <td>
                                      <IconButton
                                        aria-controls="order-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => handleMenuClick(e, item, items)}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        id={`order-menu-${item.id}-${items.id}`} // Unique ID per menu
                                        anchorEl={anchorElMenu}
                                        keepMounted
                                        open={menuOpen && selectedOrderService?.order?.id === item.id && selectedOrderService?.service?.id === items.id}
                                        onClose={handleMenuClose}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            setData(item);
                                            setshow(true);

                                            handleMenuClose();
                                          }}
                                        >
                                          View <FaRegEye className="ms-1" />
                                        </MenuItem>
                                        <MenuItem
                                       onClick={() => {
                                        const { canCancel, remainingHours, isSameDay } = isCancellationTimeRemaining({
                                          service_date: items?.service_date,
                                          start_service_time: items?.slot?.start_time,
                                          cancellation_time: items?.cancellation_time,
                                          booking_service_type: items?.booking_service_type,
                                          created_at: item?.created_at,
                                        });
                                        let canCanceOrder = true;
                                        let cancelMessage = "";
                                        let chargePercentage = 0;
                                        console.log(remainingHours);
                                        // If `item_quantity` type, the logic for cancellation is based on time passed since creation
                                        if (items?.booking_service_type === "item_quantity") {
                                          if (items?.refunded_type === "Fully Refund") {
                                            cancelMessage = "Cancelling at this time will result in a 0% charge of the booking amount. All amount refunds will be issued.";
                                            chargePercentage = 0;
                                          }
                                          else if (items?.refunded_type === "No Refund") {
                                            cancelMessage = "Cancelling at this time will result in a 100% charge of the booking amount. No refunds will be issued.";
                                            chargePercentage = 100;
                                            let canCanceOrder = true;
                                          }
                                          else if (items?.refunded_type === "Partial Refund") {
                                            if (remainingHours > parseInt(items?.cancellation_time)) {
                                              // More time has passed than allowed by cancellation policy
                                              cancelMessage = `Cancelling your booking now will incur a fee of ${items?.refund_percentage ? items?.refund_percentage : 0}% of the total booking amount, as per the vendor’s policy. Would you like to proceed?`;
                                              chargePercentage = items?.refund_percentage;
                                            }
                                            else if (remainingHours <= parseInt(items?.cancellation_time)) {
                                              cancelMessage = "Cancelling at this time will result in a 0% charge of the booking amount. All amount refunds will be issued.";
                                              chargePercentage = 0;
                                            }
                                          }
                                       
                                        } else {
                                          // Hourly base logic remains the same
                                          if (isSameDay && !items?.is_same_day) {
                                            cancelMessage = "You cannot cancel the order on the same day of service. The vendor doesn’t allow same-day cancellations.";
                                            chargePercentage = 100;
                                            canCanceOrder = false;
                                          } else if (items?.refunded_type === "No Refund") {
                                            cancelMessage = "Cancelling at this time will result in a 100% charge of the booking amount. No refunds will be issued.";
                                            chargePercentage = 100;
                                          } else if (items?.refunded_type === "Fully Refund") {
                                            cancelMessage = "Cancelling at this time will result in a 0% charge of the booking amount. All amount refunds will be issued.";
                                            chargePercentage = 0;
                                          } else if (remainingHours > parseInt(items?.cancellation_time)) {
                                            cancelMessage = "You can cancel this booking without any additional charges. Please confirm your cancellation.";
                                          } else {
                                            cancelMessage = `Cancelling your booking now will incur a fee of ${items?.refund_percentage ? items?.refund_percentage : 0}% of the total booking amount, as per the vendor’s policy. Would you like to proceed?`;
                                            if (items?.refunded_type === "Partial Refund") {
                                              chargePercentage = items?.refund_percentage;
                                            }
                                          }
                                        }
                                        
                                        // Calculate cancellation fee and final price
                                        const totalAmount = items?.subtotal;
                                        const cancellationFee = (chargePercentage / 100) * totalAmount;
                                        const afterCancelPrice = totalAmount - cancellationFee;
                                        
                                        setcancelAble(canCanceOrder);
                                        handleOpen();
                                        setOderId({
                                          order_id: item.id,
                                          order_service_id: items.id,
                                        });
                                        setcancelled_data({
                                          cancellationTime: items?.cancellation_time,
                                          cancellationPolicy: items?.refunded_type,
                                          cancellationPercentage: items?.refunded_percentage,
                                          afterCancelPrice: afterCancelPrice ? afterCancelPrice.toFixed(2) : 0,
                                        });
                                        setCancelMessage(cancelMessage);
                                        setCancellationFee(chargePercentage);
                                      }}
                                        >
                                          Cancel
                                        </MenuItem>
                                        {  items.orderStatus
                                                            ?.status_code==="CA" && items?.rescheduled_data?.rescheduling?<Reschedule service={items}/>:""}
                                      </Menu>
                                    </td>
                
                                </tr>
                              );
                            })
                          }
                          </>
                          :
                          <>
                          
                          <tr>
                            <th scope="row">
                              {" "}
                              {plus.includes(i) ? (
                                <FaSquareMinus
                                  className="ms-2"
                                  onClick={() => toggleSubtable(i)}
                                />
                              ) : (
                                <FaSquarePlus
                                  className="ms-2"
                                  onClick={() => toggleSubtable(i)}
                                />
                              )}
                            </th>
                            <td>{item?.order_number}</td>
                            <td>{item?.created_at?.split("T")[0]}</td>
                            <td>{item?.orderService[0]?.service_date}</td>

                            <td>£{item?.total}</td>
                            <td>
                              <span className="pending">
                                {item?.orderStatus?.client_label}
                              </span>
                            </td>
                            <td>
                            <IconButton
                                        aria-controls="order-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => handleMenuClick(e, item, item)}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        id={`order-menu-${item.id}-${item.id}`} // Unique ID per menu
                                        anchorEl={anchorElMenu}
                                        keepMounted
                                        open={menuOpen && selectedOrderService?.order?.id === item.id && selectedOrderService?.service?.id === item.id}
                                        onClose={handleMenuClose}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            setData(item);
                                            setshow(true);

                                            handleMenuClose();
                                          }}
                                        >
                                          View <FaRegEye className="ms-1" />
                                        </MenuItem>
                                
                                      </Menu>
                              {/* <ul className="New_order_icons">

                              
                              <li
                                
                                onClick={() => {
                                  setshow(true);
                                  setData(item);
                                }}
                              >
                                View <FaRegEye  className="ms-1" />
                              </li>
                              <li
                                disabled={true}
                                style={{
                                  
                                  cursor: "not-allowed",
                                }}
                              
                                onClick={() => {
                                  setshow(true);
                                  setData(item);
                                }}
                              >
                                Cancel{" "}
                                <IoCheckmark  className="ms-1" />
                              </li>
                              </ul> */}
                            </td>
                          </tr>
                          {plus.includes(i) &&
                            item?.orderService?.map((items) => {
                              return (
                                <tr>
                                  <th scope="row">
                                    {" "}
                                    {/* <BpCheckbox /> */}
                                  </th>
                                  <td>{item?.order_number}</td>
                                  <td>{item?.created_at?.split("T")[0]}</td>
                                  <td>{item?.orderService[0]?.service_date}</td>
                                  <td>£{items?.total}</td>
                                  <td>
                                    <span className="pending">Pending</span>
                                  </td>
                                  <td>
                                  <IconButton
                                        aria-controls="order-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => handleMenuClick(e, item, items)}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        id={`order-menu-${item.id}-${items.id}`} // Unique ID per menu
                                        anchorEl={anchorElMenu}
                                        keepMounted
                                        open={menuOpen && selectedOrderService?.order?.id === item.id && selectedOrderService?.service?.id === items.id}
                                        onClose={handleMenuClose}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            setData(item);
                                            setshow(true);

                                            handleMenuClose();
                                          }}
                                        >
                                          View <FaRegEye className="ms-1" />
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            const { canCancel, remainingHours, isSameDay } = isCancellationTimeRemaining({
                                              service_date: items?.service_date,
                                              start_service_time: items?.slot?.start_time,
                                              cancellation_time: items?.cancellation_time,
                                              booking_service_type: items?.booking_service_type,
                                              created_at: item?.created_at,
                                            });
                                            let canCanceOrder = true;
                                            let cancelMessage = "";
                                            let chargePercentage = 0;
                               
                                            
                                            // If `item_quantity` type, the logic for cancellation is based on time passed since creation
                                            if (items?.booking_service_type === "item_quantity") {
                                              if (items?.refunded_type === "Fully Refund") {
                                                cancelMessage = "Cancelling at this time will result in a 0% charge of the booking amount. All amount refunds will be issued.";
                                                chargePercentage = 0;
                                              }
                                              else if (items?.refunded_type === "No Refund") {
                                                cancelMessage = "Cancelling at this time will result in a 100% charge of the booking amount. No refunds will be issued.";
                                                chargePercentage = 100;
                                                 canCanceOrder = true;
                                              }
                                              else if (items?.refunded_type === "Partial Refund") {
                                                if (remainingHours > parseInt(items?.cancellation_time)) {
                                                  // More time has passed than allowed by cancellation policy
                                                  cancelMessage = `Cancelling your booking now will incur a fee of ${items?.refund_percentage ? items?.refund_percentage : 0}% of the total booking amount, as per the vendor’s policy. Would you like to proceed?`;
                                                  chargePercentage = items?.refund_percentage;
                                                }
                                                else if (remainingHours <= parseInt(items?.cancellation_time)) {
                                                  cancelMessage = "Cancelling at this time will result in a 0% charge of the booking amount. All amount refunds will be issued.";
                                                  chargePercentage = 0;
                                                }
                                              }
                                           
                                            } else {
                                              // Hourly base logic remains the same
                                              if (isSameDay && !items?.is_same_day) {
                                                cancelMessage = "You cannot cancel the order on the same day of service. The vendor doesn’t allow same-day cancellations.";
                                                chargePercentage = 100;
                                                canCanceOrder = false;
                                              } else if (items?.refunded_type === "No Refund") {
                                                cancelMessage = "Cancelling at this time will result in a 100% charge of the booking amount. No refunds will be issued.";
                                                chargePercentage = 100;
                                              } else if (items?.refunded_type === "Fully Refund") {
                                                cancelMessage = "Cancelling at this time will result in a 0% charge of the booking amount. All amount refunds will be issued.";
                                                chargePercentage = 0;
                                              } else if (remainingHours > parseInt(items?.cancellation_time)) {
                                                cancelMessage = "You can cancel this booking without any additional charges. Please confirm your cancellation.";
                                              } else {
                                                cancelMessage = `Cancelling your booking now will incur a fee of ${items?.refund_percentage ? items?.refund_percentage : 0}% of the total booking amount, as per the vendor’s policy. Would you like to proceed?`;
                                                if (items?.refunded_type === "Partial Refund") {
                                                  chargePercentage = items?.refund_percentage;
                                                }
                                              }
                                            }
                                            
                                            // Calculate cancellation fee and final price
                                            const totalAmount = items?.subtotal;
                                            const cancellationFee = (chargePercentage / 100) * totalAmount;
                                            const afterCancelPrice = totalAmount - cancellationFee;
                                            
                                            setcancelAble(canCanceOrder);
                                            handleOpen();
                                            setOderId({
                                              order_id: item.id,
                                              order_service_id: items.id,
                                            });
                                            setcancelled_data({
                                              cancellationTime: items?.cancellation_time,
                                              cancellationPolicy: items?.refunded_type,
                                              cancellationPercentage: items?.refunded_percentage,
                                              afterCancelPrice: afterCancelPrice ? afterCancelPrice.toFixed(2) : 0,
                                            });
                                            setCancelMessage(cancelMessage);
                                            setCancellationFee(chargePercentage);
                                          }}
                                        >
                                          Cancel
                                        </MenuItem>
                                        {  items.orderStatus
                                                            ?.status_code==="CA" && items?.rescheduled_data?.rescheduling?<Reschedule service={items}/>:""}
                                      </Menu>
                          
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        }
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} style={{border:"none"}}>
                      <h5 className='text-center'>
                No Order Found !
                </h5>
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="paginations d-flex justify-content-end my-3">
          <Stack spacing={2}>
            <Pagination
              count={orderData?.meta?.last_page}
              onChange={handlePageChange}
              shape="rounded"
            />
          </Stack>
        </div>
      </div>
      <CancelModal
        cancelAble={cancelAble}
        handleClose={handleClose}
        open={open}
        orderIds={orderId}
        pagination={pagination}
        cancelMessage={cancelMessage}
        cancelled_data={cancelled_data}

      />
      <NewOrderEdit data={data} show={show} setshow={setshow} />
    </div>
  );
}

export default NewOrder;
