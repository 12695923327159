import React, { useEffect, useState } from "react";
import dummy from "../../asset/studio/landscape.webp";
const shuffleArray = (array) => {
  let shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};
function Banner({ data }) {
  const predefinedImages = [dummy, dummy, dummy, dummy, dummy];
  const [array, setArray] = useState([dummy, dummy, dummy, dummy, dummy]);
  useEffect(() => {
    const interval = setInterval(() => {
      setTriggerAnimation(true);
      setArray((prevArray) => shuffleArray(prevArray));
    }, 6000); // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    let arrays = [];

    data?.sub_service_gallery?.map((item) => {
      arrays.push(item?.url);
      return null; // To avoid warning for not returning a value from map
    });

    // Check if the array has less than 5 images and add from predefinedImages if needed
    if (arrays.length < 5) {
      const additionalImagesNeeded = 5 - arrays.length;
      arrays = arrays.concat(predefinedImages.slice(0, additionalImagesNeeded));
    }

    setArray(arrays);
    // eslint-disable-next-line
  }, [data]);
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  useEffect(() => {
    if (triggerAnimation) {
      const timeout = setTimeout(() => {
        setTriggerAnimation(false);
      }, 2000); // Duration of the zoom animation
      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, [triggerAnimation]);
  
  const modifiedName =data?.sub_service?.name;
  const [scrollEffect, setScrollEffect] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        // Adjust the scrollY value to trigger the effect at the desired scroll position
        setScrollEffect(true);
      } else {
        setScrollEffect(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container py-4">
      <div className="row">
        <div className="col-12">
          <button className=" btn_start1">{data?.sub_service?.name}</button>
        </div>

        <div className="col-12">
          <div className="services_heading">
            <h2>
              {/* Our E-Commerce photographers are keen observers to<br></br>
            capture a range of creative  photography styles. */}
              {data?.heading}
            </h2>
          </div>
        </div>
        <div className="col-12">
          <div className={`services_del`}>
          
            <div className="row">
              {/* ======== section 1=============== */}
              <div className="col-lg-5 col-12 order-2 order-lg-1 ps-0">
                <div className="row">
                  <div className="col-lg-5 col-12 my-lg-3 mt-3 mb-2 ">
                    <div className="ser_del_img  ">
                      <img
                        className={`zoom ${
                          triggerAnimation ? "zoom-in-out" : ""
                        }`}
                        src={array[0]}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-7 col-12 my-lg-3 my-2 ">
                    <div className="ser_del_img">
                      <img
                        className={`zoom ${
                          triggerAnimation ? "zoom-in-out" : ""
                        }`}
                        src={array[1]}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="col-lg-7 col-12 my-2 ">
                    <div className="ser_del_img">
                      <img
                        className={`zoom ${
                          triggerAnimation ? "zoom-in-out" : ""
                        }`}
                        src={array[2]}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-12 my-2 ">
                    <div className="ser_del_img ">
                      <img
                        className={`zoom ${
                          triggerAnimation ? "zoom-in-out" : ""
                        }`}
                        src={array[3]}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* ======== section 2============== */}

              <div className="col-lg-7 col-12 order-1 order-lg-2 pe-0" style={{position:"relative", }}>
                <div className="services_head1" >
                  {/* <h1 style={{textTransform:"uppercase",fontSize:`${data?.sub_service?.name?.length>19?90-data?.sub_service?.name?.length:110}px`}} className={`e_comm scroll_animation`}>{data?.sub_service?.name}</h1> */}
                  <h1
                    style={{
                      textTransform: "uppercase",
                      // fontSize: fontSize,
                    
                    }}
                    className={`e_comm scroll_animation ${scrollEffect ? "scroll-effect" : ""}`}
                  >
                    {modifiedName}
                  </h1>
                  <h1 className="new_modified_text"> {modifiedName}</h1>
                </div>
                <div className="ser_sec2_img" >
                  <img
                    className={`zoom ${triggerAnimation ? "zoom-in-out" : ""}`}
                    src={array[4]}
                    alt=""
                  />
                </div>
              </div>
            </div>

            
            

          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
