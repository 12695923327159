import React from 'react'
import gif from '../../asset/detail/02-lottie-tick-01-instant-2.gif'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import Signin from './Signin'
import { useAuth } from '../../Context/ContextProvider'
import { useNavigate } from 'react-router-dom'
function Step4({data}) {
  const {resendEmail,buttonLoding, web_url}=useAuth()
  const navigate=useNavigate()
  return (
    <div className="container">
    <div style={{boxShadow:"none"}} className="row main mt-3">
      <div className="col-lg-3 col-12 ps-1">
      <div className="side_basic">
            <div className="basic_info">
              <div className="row p-0">

                <div style={{position:"relative"}} className='col-2 p-0'>
                <div className="check">
                    <span>
                      <IoIosCheckmarkCircle style={{ fontSize: "25px", color: "#53B73A" }} />
                    </span>
                  </div>
                  <div style={{ position: "absolute", height: "32px", left: "15px", border: "1px solid lightgray", top: "50%" }}></div>
                </div>
                <div className='col-10'>

                  <h5>Basic Information</h5>
                  <p>Get Started Here</p>
      
                </div>
                <div style={{position:"relative"}} className='col-2 p-0'>
                <div className="check">
                    <span>
                      <IoIosCheckmarkCircle style={{ fontSize: "25px", color: "#53B73A" }} />
                    </span>
                  </div>
                  <div style={{ position: "absolute", height: "32px", left: "15px", border: "1px solid lightgray", top: "50%" }}></div>
                </div>
                <div className='col-10'>

                  <h5>Professional Information</h5>
                  <p>Showcase your skills.</p>
      
                </div>
                <div style={{position:"relative"}} className='col-2 p-0'>
                <div className="check">
                    <span>
                      <IoIosCheckmarkCircle style={{ fontSize: "25px", color: "#53B73A" }} />
                      {/* <div /> */}
                    </span>
                  </div>
                  <div style={{ position: "absolute", height: "32px", left: "15px", border: "1px solid lightgray", top: "50%" }}></div>
                </div>
                <div className='col-10'>

                  <h5>Terms & Conditions</h5>
                  <p>Read before joining.</p>
      
                </div>
                <div className='col-2 p-0'>
                <div className="check">
                    <span>
                      <IoIosCheckmarkCircle style={{ fontSize: "25px", color: "#53B73A" }} />
                      {/* <div /> */}
                    </span>
                  </div>
                </div>
                <div className='col-10'>

                  <h5>HUrrah ! </h5>
                  <p>Welcome aboard!</p>
      
                </div>
    
              </div>
             
            </div>
          </div>
      </div>
      <div className="col-lg-9 col-12 mt-lg-0 mt-5 pe-1">
        <div className="side_basic signIn seccuess_icon d-flex justify-content-start p-3" style={{height:"70vh"}}>
          <div className="icon" >
          <img src={gif} alt='...'/>
          </div>
          <div className="signIn_content">
            <h4>Completed successfully</h4>
            <p>Email verification sent at mention email address </p>
            <p>Email not received? <span><button disabled={buttonLoding}  onClick={()=>{
              resendEmail(data?.email)
              navigate(`${web_url}clinet-signin`)
            }}  className="signIn_btn">Resend Email {buttonLoding &&<div class="spinner-border text-primary" role="status">  </div>}
           
        </button></span></p>
            <p>Or</p>
            <Signin/>
            {/* <Link to={'/clinet-signin'}>
            <button className="signIn_btn">Sign In</button>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default Step4