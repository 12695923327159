import React from "react";
import img1 from "../../asset/our-studio-webp/Rectangle 1388.png";
import img2 from "../../asset/our-studio-webp/Rectangle 1389.png";
import img3 from "../../asset/our-studio-webp/Rectangle 1386.png";
import img4 from "../../asset/our-studio-webp/Rectangle 1387.png";
import img5 from "../../asset/our-studio-webp/Rectangle 1385.png";
import img6 from "../../asset/our-studio-webp/Rectangle 1377.png";
import img7 from "../../asset/our-studio-webp/Rectangle 1382.png";
import img8 from "../../asset/our-studio-webp/Rectangle 1378.png";
import img9 from "../../asset/our-studio-webp/Rectangle 1380.png";
import img10 from "../../asset/our-studio-webp/Rectangle 1379.png";
function Banner() {
  return (
    <div
      className="our_studio_banner our_portfolio  mb-4"
      style={{ marginTop: "1.9rem" }}
    >
      <button className="new_studio_btn" style={{ padding: ".7rem 1.6rem" }}>Our studio spaces</button>
      <h1 className=" mb-0 our_studio_banner_h1">
        Our Studios for Film, TV, Commercial
        <br />
        and Photo Productions
      </h1>
      <p className="new_para"
        style={{
          fontSize: "16px",
          fontWeight: "400",
          color: "#494949",
          lineHeight: "19.2px",
          marginBottom: "0",
        }}
      >
        Whether you’re doing a full-scale production or a just need to borrow
        some space, we have studio spaces and
        <br />
         equipment available that will fit your budget.
      </p>
      <div className="row " style={{marginTop:"-25px"}}>
        <div className="col-lg-2 col-md-4 col-12">
          <div className="img">
            <img src={img1} alt="..." />
          </div>
          <div className="img">
            <img src={img2} alt="..." />
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-12">
          <div className="img">
            <img src={img3} alt="..." />
          </div>
          <div className="img">
            <img src={img4} alt="..." />
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-12">
          <div className="img">
            <img src={img5} alt="..." />
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-12">
          <div className="img">
            <img src={img6} alt="..." />
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-12">
          <div className="img">
            <img src={img7} alt="..." />
          </div>
          <div className="img">
            <img src={img8} alt="..." />
          </div>
        </div>
        <div className="col-lg-2 col-md-4 col-12">
          <div className="img">
            <img src={img9} alt="..." />
          </div>
          <div className="img">
            <img src={img10} alt="..." />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
