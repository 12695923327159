import React, { useEffect, useRef, useState } from "react";
import Celender from "./Celender";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useAuth } from "../../Context/ContextProvider";
import leftTop from "../../asset/icons/Vector 4.png"
import rightTop from "../../asset/icons/Vector 7.png"
import leftBottom from "../../asset/icons/Vector 7_1.png"
import rightBottom from "../../asset/icons/Vector 6.png"
import MultiItemCarousel from "./MultiItemCarousel";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "react-photo-album/rows.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import StudioPricing from "../VenderProfie/StudioPricing";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};
function PortfolioSection({ packages, setPackage, data }) {
  const { venderAvailiblty,addCartResp, setCartResp, addToCart, web_url } = useAuth();
  const [plan_id, setPlan_id] = useState();
  const [newData, setData] = useState({
    plan_template_id: "",
    vendor_service_id: "",
    day_id: "",
    vendor_id: "",
  });
  const [photo, setphoto] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const videoRef = useRef(null);
  const handleClose = () => setOpen(false);
  const [packageData, setPackageData] = useState([]);
  const [service_id, setserviceId] = useState();
  const nanvigate = useNavigate()
  const [quantity,setquantity]=useState(1)
  const generateUUID = () => {
    const newId = uuidv4();
    return newId
  };
  React.useEffect(() => {
    if (addCartResp === "ok") {
      localStorage.removeItem('cardData')
      nanvigate(`${web_url}order-summry`)
      setCartResp('')
    }
    // eslint-disable-next-line
  }, [addCartResp])
  const addToCartLocal = (obj) => {
    let id = generateUUID()
    let client = JSON.parse(localStorage.getItem("vendor_data"))

    if (client?.data?.role === "client") {
      addToCart({...obj,cart_key:id})
    }
    else {
      localStorage.setItem("cardData", JSON.stringify({...obj,cart_key:id}))
      nanvigate(`${web_url}clinet-signin`)
    }
  }
  useEffect(() => {
    setPackageData(data?.vendor_service?.vendor_sub_services);
    // setserviceId(
    //   data?.vendor_service?.vendor_sub_services[0]?.vendor_sub_service_id
    // );
    if (data?.vendor_service?.vendor_sub_services?.length > 0) {
      setserviceId(data?.vendor_service?.vendor_sub_services[0]?.vendor_sub_service_id);
    } else {
      // Handle the case where vendor_sub_services is undefined or empty
      setserviceId(null); // Or set a default value if needed
    }


    // setData({ ...newData, vendor_service_id: data?.vendor_service?.vendor_sub_services[0]?.vendor_sub_service_id})
  }, [data]);
  useEffect(() => {
    packageData?.map((item) => {
      if (service_id === item?.vendor_sub_service_id) {
        if (item?.portfolio?.video?.length > 0) {
          setCurrentVideo(item?.portfolio?.video[0]?.url)
        }
        else if (item?.portfolio?.video?.links > 0) {
          setCurrentVideo(item?.portfolio?.links[0]?.url)
        }
        else {
          setCurrentVideo("")
        }
      }
      return null
    })
    // eslint-disable-next-line
  }, [service_id]);

  const [currentVideo, setCurrentVideo] = useState(data?.portfolio?.video[0]?.url);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
    // eslint-disable-next-line
  }, [currentVideo]);
  const handleVideoClick = (url) => {
    setCurrentVideo(url);
  }
  const isYouTubeLink = (url) => {
    return url?.includes('youtube.com') || url?.includes('youtu.be');
  };
  const renderVideo = (url) => {
    if (isYouTubeLink(url)) {
      // Handle YouTube link
      const embedUrl = url?.includes('youtube.com')
        ? url?.replace('watch?v=', 'embed/')
        : url?.replace('youtu.be/', 'youtube.com/embed/');
      return (
        <iframe
          width="100%"
          height="550px"
          src={embedUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    } else {
      // Handle other video links (assume MP4 for simplicity)
      return (
        <video ref={videoRef} autoPlay width="100%" height='550px' controls>
          <source src={url} type="video/mp4" />
        </video>
      );
    }
  };
  const breakpoints = [3840, 1920, 1080, 640, 384, 256, 128];
  const [index, setIndex] = React.useState(-1);

  function assetLink(url, width) {
    return `${url}?w=${width}&q=75`;
  }

  const [showFullText, setShowFullText] = useState(false);

  const renderPhotoSection = () => {
    // Step 1: Create an empty array to store images
    let imagesArray = [];
    let imagesArray1 = [];

    // Step 2: Filter and transform images based on the selected service_id
    packageData?.forEach((item) => {
      if (service_id === item?.vendor_sub_service_id) {
        imagesArray = item?.portfolio?.images?.map((img) => ({
          asset: img?.url,
          width: 3840,
          height: 3560,
        }));
        imagesArray1 = item?.portfolio?.images?.map((img) => (img?.preview_url));
      }
    });

    // Step 3: Create slides array for displaying in the photo album
    const slides = imagesArray?.map(({ asset, width, height }) => ({
      src: assetLink(asset, width),
      width,
      height,
      srcSet: breakpoints?.map((breakpoint) => ({
        src: assetLink(asset, breakpoint),
        width: breakpoint,
        height: Math.round((height / width) * breakpoint),
      })),
    }));

    return (
      <div className="row m-0 p-0 d-flex justify-content-center ser_phot">
        {/* RowsPhotoAlbum Component to display photos */}
        <MultiItemCarousel handleVideoClick={setIndex} image={imagesArray1} />
        {/* <RowsPhotoAlbum
          photos={slides ? slides : []}
          spacing={20} // Spacing between images
          padding={0}  // Padding around each image
          targetRowHeight={180}
          rowConstraints={{ minPhotos: 2, maxPhotos: 5, singleRowMaxHeight: 250 }}
          onClick={({ index: current }) => setIndex(current)} // Set the clicked image index
        /> */}

        {/* Lightbox Component to display images in a lightbox gallery */}
        <Lightbox
          plugins={[Counter, Fullscreen, Slideshow, Zoom, Thumbnails]}
          index={index}
          slides={slides ? slides : []}
          open={index >= 0}
          close={() => setIndex(-1)} // Close the lightbox
        />

        {/* Uncomment this section if you want to display images in a grid format */}
        {/* {packageData?.map((item) => {
          if (service_id === item?.vendor_sub_service_id) {
            return item?.portfolio?.images?.map((image, indx) => (
              <div className="col-lg-3 col-md-4 col-6 p-2" key={indx}>
                <div className="img_portfolio">
                  <img className="w-100" src={image?.url} alt="..." />
                </div>
              </div>
            ));
          }
          return null;
        })} */}
      </div>
    );
  };


  const renderVideoSection = () => (
    <>
      {currentVideo ? renderVideo(currentVideo) : <h5 className="text-center">The portfolio videos are still pending upload.</h5>}
      {packageData?.map((item) => {
        if (service_id === item?.vendor_sub_service_id) {
          return (
            <MultiItemCarousel
              handleVideoClick={handleVideoClick}
              image={item?.portfolio?.links}
              key={item?.vendor_sub_service_id}
            />
          );
        }
        return null;
      })}
    </>
  );

  return (
    <>
      <div id="portfolio_section" className="portfolio_section">
        <div className="col-12 text-center mt-lg-2">
          <button style={{ border: "none" }} className=" profile_headings my-4">About me</button>
        </div>

        <div className="col-12">
          {

            data?.description ?
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: showFullText
                      ? data?.description
                      : `${data?.description?.slice(0, 650)}`,
                  }}
                  className="Ser_para "
                ></div>
                {data?.description?.length > 650 && (
                  <>
                    <hr className="mb-0" style={{width:'90%' , margin: '1.3rem auto 0 auto'}} />

                    <div className="d-flex justify-content-center">
                      <button
                        className="ser_read "
                        onClick={() => setShowFullText(!showFullText)}
                      >
                        {showFullText ? "Read Less" : "Read More"}
                      </button>
                    </div>
                  </>
                )}

              </>
              :
              <h5 className="text-center my-3">
               No description has been provided for the vendor's "About" section.
              </h5>

          }
        </div>
        {/* =====================Our services==================== */}
        <div className="col-12 text-center mt-3">
          <button style={{ border: "none" }} className=" profile_headings mb-3 mt-4">Service(S)</button>
        </div>
        <div className="d-flex flex-wrap justify-content-center service_list" style={{ cursor: "pointer" }}>
          {packageData?.map((item) => {
            return (
              <p
                style={{
                  background: `${item?.vendor_sub_service_id === service_id ? "#494949" : ""
                    }`,
                  color: `${item?.vendor_sub_service_id === service_id
                      ? "#ffffff"
                      : "#494949"
                    }`,
                    borderRadius: '2px'
                }}
                onClick={() => {
                  setserviceId(item?.vendor_sub_service_id);
                }}
              >
                {item?.sub_service?.name}
              </p>
            );
          })}
        </div>
        {/* ========================arrow start==================== */}


        {/* ========================arrow end==================== */}
        {packageData?.map((item) => {
          if (service_id === item?.vendor_sub_service_id) {
            return item?.portfolio ? (
              <div key={item.vendor_sub_service_id}>
                <div className="btn_box_portfolio">
                  <button  style={{marginTop:'26px' , marginBottom:'32px'}}
                    onClick={() => setphoto(true)}
                    className={`${photo ? "pro_btn_dark" : "pro_btn_light"}`}
                  >
                    PHOTOS
                  </button>
                  <button style={{marginTop:'26px' , marginBottom:'32px'}}
                    onClick={() => setphoto(false)}
                    className={`${photo ? "pro_btn_light" : "pro_btn_dark"}`}
                  >
                    VIDEOS
                  </button>
                </div>

                <div className="row m-0 mt-2 mb-3">
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center" style={{ width: "80%", margin: "0 auto" }}>
                      <img className="img_top_border" src={leftTop} alt="" />
                      <img className="img_top_border" src={rightTop} alt="" />
                    </div>
                  </div>
                </div>
                {photo ? renderPhotoSection() : renderVideoSection()}
              </div>
            ) : null;
          }
          return null;
        })}
      </div>
      {/* ========================arrow start==================== */}

      <div className="row m-0">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <img className="img_bottom_border" src={leftBottom} alt="" />
            <img className="img_bottom_border" src={rightBottom} alt="" />
          </div>
        </div>
      </div>

      {/* ========================arrow end==================== */}



      <div className="text-center">
        <h1
          className="mt-5 mb-3 pt-4 my_packages_head"
          style={{
            fontSize: "32px",
            fontWeight: "500",
            lineHeight: "55px",
            color: "#494949",
          }}
        >
          My Packages
        </h1>
      </div>
      <div id="package_section" className="package_section">
        <div className="row m-0 p-0 d-flex align-items-center justify-content-center">
          {packageData?.map((item) => {
            if (service_id === item?.vendor_sub_service_id) {
              return item?.plans?.length ? (
                <StudioPricing data={item}/>
              ) : (
                <div>
                  <h4 className="text-center">
                  Plans have not yet been created for the selected service.
                  </h4>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Celender
            vendor_id={newData?.vendor_id}
            venderAvailiblty={venderAvailiblty}
            plan_id={plan_id}
            data={newData}
            setData={setData}
            handleClose={handleClose}
            booking_service_type={newData?.booking_service_type}
            
          />
        </Box>
      </Modal>
    </>
  );
}
export default PortfolioSection;
