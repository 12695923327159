import React from "react";
import img from "../../asset/Rectangle 1352.png";
import img1 from "../../asset/Rectangle 1353.png";
import img3 from "../../asset/1.png";
import img4 from "../../asset/3a.png";
import img5 from "../../asset/4a.png";
import img6 from "../../asset/5a.png";
import img7 from "../../asset/6a.png";
import img8 from "../../asset/7.png";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/ContextProvider";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos";
function Banner() {
  const { web_url, home } = useAuth();
  AOS.init({
    duration: 800, // Animation duration
    easing: "ease-in-out", // Easing option
    once: false, // Whether animation should only happen once
  });
let image=[
  {photo:img,
  caption:"Photography Servicesy"
},
{photo:img1,
  caption:"Studio Spaces"
},
]
let sectionTwo=[
  {photo:img3,
  caption:"Product Photography"
},
{photo:img4,
  caption:"Model Photography"
},
{photo:img5,
  caption:"E-commerce Photography"
},
{photo:img6,
  caption:"Flat Lay Photography"
},
{photo:img7,
  caption:"Group Session Photography"
},
{photo:img8,
  caption:"Ghost Mannequin Photography"
},
]
  return (
    <div className="banner_home my-5" data-aos="fade-up">
      {/* ================================Section 1 strat============================== */}
      <>
        <div className="row ">
          {home?.length
            ? home[0]?.sections[0]?.section?.map((item,i) => {
                return (
                    <div className="col-6 "  style={i % 2 === 0 ? { paddingRight: "15px" } : { paddingLeft: "15px" }}>
                    <Link
                      style={{ textDecoration: "none", color: "black" }}
                      to={`${item?.link}`}
                    >
                      <div className="img_main">
                        <img
                          style={{
                            width: "100%",
                            height: "623px",
                            objectFit: "cover",
                            objectPosition: "top",
                          }}
                          src={item?.image ? item?.image?.url : image[i].photo}
                          alt="..."
                        />
                      </div>
                      <h5
                      className="home1Heading new_heading_1 text-lg-start text-md-start text-center"
                        style={{
                          fontSize: "32px",
                          fontWeight: "700",
                          lineHeight: "38.4px",
                          marginTop: "6px",
                          color:"#494949"
                        }}
                      >
                        {item?.caption ? item?.caption : image[i].caption}
                      </h5>
                    </Link>
                  </div>
                );
              })
            : 
            

          <>
          
          <div className="col-lg-6 col-md-6 col-12"  style={{ paddingRight: "15px" }}>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`${web_url}our-service`}
          >
            <div className="img_main">
              <img
                style={{
                  width: "100%",
                  height: "623px",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
                src={image[0].photo}
                alt="..."
              />
            </div>
            <h5
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "38.4px",
                marginTop: "6px",
              }}
               className="text-lg-start text-md-start text-center"
            >
              {image[0].caption}
            </h5>
          </Link>
        </div>
          <div className="col-lg-6 col-md-6 col-12"  style={{ paddingLeft: "15px" }}>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`${web_url}our-studio`}
          >
            <div className="img_main">
              <img
                style={{
                  width: "100%",
                  height: "623px",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
                src={image[1].photo}
                alt="..."
              />
            </div>
            <h5
              style={{
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "38.4px",
                marginTop: "6px",
              }}
              className="text-lg-start text-md-start text-center"
            >
              {image[1].caption}
            </h5>
          </Link>
        </div>
      
          </>
            }

         
        </div>
      </>
      {/* ================================Section 1 end============================== */}

      {/* ================================Section 2 strat============================== */}
      <>
        <div className="row mt-lg-3 ">
          {
            home[0]?.sections[1]?.description?

          <div className="col-12 text-center my-lg-5 mb-lg-4 my-4 px-2">
            <p className="home_comm_heading m-auto mt-4">Our Photography Services</p>
            <h1
              className="sectwo_heading"
              dangerouslySetInnerHTML={{
                __html:
                  home[0]?.sections[1]?.description.split("</h1>")[0] + "</h1>",
              }}
            ></h1>

            <p
              className="secTwo_para"
              dangerouslySetInnerHTML={{
                __html: home[0]?.sections[1]?.description
                  .split("</h1>")[1]
                  ?.trim(),
              }}
            ></p>
          </div>
          :""
          }
          <div className="col-12">
            <div className=" row mt-3 mt-lg-0">
              {home?.length
                ? home[0]?.sections[1]?.section?.map((item, index) => {
                    if (item?.caption) {
                      if (index % 6 === 0 || index % 5 === 0) {
                        // Large items
                        return (
                          <div className="col-lg-6 col-md-12 col-12  "  style={index % 6 === 0 ? { padding: "0 15px" } : { padding: "0 13px" }}>
                            <Link
                              style={{ textDecoration: "none", color: "black" }}
                              to={item?.link}
                            >
                              <div className="img_main img_main_1 home_content">
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  src={item?.image?item?.image?.url:sectionTwo[index].photo}
                                  alt="..."
                                />
                              </div>

                              <h5 className="text-lg-start text-md-start text-center">{item?.caption?item?.caption:sectionTwo[index].caption}</h5>
                            </Link>
                          </div>
                        );
                      } else {
                        // Small items
                        return (
                          <div className="col-lg-3 col-md-6 col-12  "style={{padding:"0 13px"}}>
                            <Link
                              style={{ textDecoration: "none", color: "black" }}
                              to={item?.link}
                            >
                              <div className="img_main img_main_1 home_content">
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  src={item?.image?item?.image?.url:sectionTwo[index].photo}
                                  alt="..."
                                />
                              </div>

                              <h5 className="text-lg-start text-md-start text-center">{item?.caption?item?.caption:sectionTwo[index].caption}</h5>
                            </Link>
                          </div>
                        );
                      }
                    }
                    return null
                  })
                : 
                sectionTwo.map((item, index) => {
                  if (item?.caption) {
                    if (index % 6 === 0 || index % 5 === 0) {
                      // Large items
                      return (
                        <div className="col-lg-6 col-md-12 col-12  "  style={index % 6 === 0 ? { padding: "0 15px" } : { padding: "0 13px" }}>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={`${web_url}our-service`}
                          >
                            <div className="img_main img_main_1 home_content">
                              <img
                                style={{ width: "100%", height: "100%" }}
                                src={item.photo}
                                alt="..."
                              />
                            </div>

                            <h5 className="text-lg-start text-md-start text-center">{item.caption}</h5>
                          </Link>
                        </div>
                      );
                    } else {
                      // Small items
                      return (
                        <div className="col-lg-3 col-md-6 col-12  "style={{padding:"0 13px"}}>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={`${web_url}our-service`}
                          >
                            <div className="img_main img_main_1 home_content">
                              <img
                                style={{ width: "100%", height: "100%" }}
                                src={item.photo}
                                alt="..."
                              />
                            </div>

                            <h5 className="text-lg-start text-md-start text-center">{item.caption}</h5>
                          </Link>
                        </div>
                      );
                    }
                  }
                  return null
                })
                }
            </div>
          </div>
        </div>
      </>

      {/* ================================Section 2 end============================== */}

      <div className="d-flex justify-content-center">
          <Link style={{textDecoration:"none"}} to={`${web_url}our-service`}>
               <button className="home_view_btn">View All Service</button>   
          </Link>

      </div>
    </div>
  );
}

export default Banner;
