import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import Aboutus from './Pages/Aboutus';
import ContactUs from './Pages/ContactUs';
import ExplorTelent from './Pages/ExplorTelent';
import ListTalent from './Pages/ListTalent';
import OurService from './Pages/OurService';
import OurStudio from './Pages/OurStudio';
import VenderProfie from './Pages/VenderProfie';
import VenderPannel from './VenderDashbord/SideBar/VenderPannel';
import ClientDashbord from './ClientDashbord/SideBar/ClientDashbord';
import ClinetSignUp from './Pages/ClinetSignUp';
import JoinOurTeam from './Pages/JoinOurTeam';
import Cheackout from './Pages/Cheackout';
import OrderSummry from './Pages/OrderSummry';
import StdioDetail from './Pages/StdioDetail';
import PhotograypyDetailPage from './Pages/PhotograypyDetailPage';
import StudioPrfoile from './Pages/StudioPrfoile';
import StudioSpace from './Pages/StudioSpace';
import ClientForgetPassword from './Pages/ClientForgetPassword';
import Login from './Pages/Login';
import PurchaseOrder from './Pages/PurchaseOrder';
import EmailVerificationPage from './Pages/EmailVerificationPage';
import ForgetPassword from './Pages/ForgetPassword';
import { useAuth } from './Context/ContextProvider';
import ScrollToTop from './Components/NavBarFooter/ScrollToTop';
import TandC from './Pages/TandC';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import FAQs from './Pages/FAQs';
import { useEffect, useState } from 'react';
import './ClientDashbord/SideBar/client.css'


function App() {
  const {web_url,  facebookCrdentioal,
    getGoogleClientId,
    googleClientId,
    getstripIdId,
    stripIdId,
    webSetting,
    homePage,
    getAdminService,
    getAdminStudio,
    getSocialLInks,
    ContactNumber,
    getState,
    getAminty,

    getstudioList   ,
     fbAppId,}=useAuth()
  useEffect(()=>{
    getGoogleClientId()
    facebookCrdentioal()
    getstripIdId()
    webSetting()
    homePage()
    getAdminService()
    getAdminStudio()
    getSocialLInks()
    ContactNumber()
    getState()
    getAminty()
    getstudioList()
    // eslint-disable-next-line
  },[])
    console.log(stripIdId);
    
  return (
<BrowserRouter>
<ScrollToTop/>
<Routes>
  <Route path={`${web_url}`} element={<Home/>}/>
  <Route path={`${web_url}about`} element={<Aboutus/>}/>
  <Route path={`${web_url}contact`} element={<ContactUs/>}/>
  <Route path={`${web_url}explor-telent` }element={<ExplorTelent/>}/>
  <Route path={`${web_url}clinet-signin`} element={<Login app_id={fbAppId?.length?fbAppId[0]?.value:""} client_id={googleClientId?.length?googleClientId[0]?.value:""}/>}/>
  <Route path={`${web_url}forget-password`} element={<ClientForgetPassword/>}/>
  <Route path={`${web_url}join-our-team`} element={<JoinOurTeam/>}/>
  <Route path={`${web_url}clinet-sigup`} element={<ClinetSignUp app_id={fbAppId?.length?fbAppId[0]?.value:""} client_id={googleClientId?.length?googleClientId[0]?.value:""}/>}/>
  <Route path={`${web_url}checkout`} element={<Cheackout publicKey={stripIdId?.partnerSetting?.publisher_key} client_id={googleClientId?.length?googleClientId[0]?.value:""}/>}/>
  <Route path={`${web_url}order-summry`} element={<OrderSummry/>}/>
  <Route path={`${web_url}list-talent/:id`} element={<ListTalent/>}/>
  <Route path={`${web_url}studio-list/:id`} element={<StudioSpace/>}/>
  <Route path={`${web_url}our-service`} element={<OurService/>}/>
  <Route path={`${web_url}studio-profile/:id`} element={<StudioPrfoile/>}/>
  <Route path={`${web_url}service-detail/:id` }element={<PhotograypyDetailPage/>}/>
  <Route path={`${web_url}our-studio`} element={<OurStudio/>}/>
  <Route path={`${web_url}studio-detail/:id`} element={<StdioDetail/>}/>
  <Route path={`${web_url}email-verification`} element={<EmailVerificationPage/>}/>
  <Route path={`${web_url}reset_password`} element={<ForgetPassword/>}/>
  <Route path={`${web_url}order-placed`} element={<PurchaseOrder/>}/>
  <Route path={`${web_url}profile/:id/:id`} element={<VenderProfie/>}/>
  <Route path={`${web_url}vendor/*`} element={<VenderPannel />}></Route>
  <Route path={`${web_url}client/*`} element={<ClientDashbord />}></Route>
<Route path={`${web_url}term-condition`} element={<TandC/>}/>
<Route path={`${web_url}privacy-policy`} element={<PrivacyPolicy/>}/>
<Route path={`${web_url}FAQs`} element={<FAQs/>}/>
</Routes>
</BrowserRouter>
  );
}

export default App;
