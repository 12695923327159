import { Box, Modal } from "@mui/material";
import React from "react";
import { useAuth } from "../../Context/ContextProvider";
import { BiSolidPaperPlane } from "react-icons/bi";

function RefungRequestModel({ open2, setOpen2, refund,pagination }) {
  const { requestRefund } = useAuth();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
  };
  const handleClose = () => setOpen2(false);
  const handleSendRequest = () => {
    requestRefund({
      vendor_id: refund?.vendor_id,
      customer_id: refund?.customer_id,
      plan_id: refund?.plan_id,
      order_id: refund?.order_id,
      // client_reason: reason,
      price: refund?.price,
      order_service_id: refund?.order_service_id,
    },pagination);
    handleClose();
  };
  return (
    <Modal
      open={open2}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="row border border-dark">
          <div className="col-12 p-0">
            <div className="refund_request p-3">
              <div className="refun_icon text-center">
                <BiSolidPaperPlane />
              </div>
              <div className="refund_content text-center">
                <h3>
                  Your refunded amount will be transferred
                  <br /> to the bank account that you mentioned
                  <br /> in the request within 6 working days after
                  <br /> approval.
                </h3>
                <p>
                  If you haven't received your refund amount please contact
                  Customer support.
                </p>

                <button
                  onClick={() => {
                    handleSendRequest();
                    handleClose();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default RefungRequestModel;
