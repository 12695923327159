import React from 'react'
import enq_img from "../../asset/studio/Rectangle 1587.png";
import img from "../../asset/icons/enquiry.png";
import EnquiryModal from './EnquiryModal';
function EnquerySec({
    service_id,
    service_name,
    vendor_id,
    serviceable_type,
    ser_type,
  }) {
    
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  return (
    <div className="h-100">
      <div className="row    h-100">
        <div className=" col-12  h-100">
          <div className="enquiry_submit  h-100 enquiry_submit1" style={{position:"relative"}}>
            <img  src={enq_img} alt="" style={{position:"absolute", width:"100%", height:"100%", objectFit:"cover",opacity:".1"}}/>
            <div className="enquiry_background1">

            </div>

            <div style={{position:"absolute"}} className="px-4 w-100">


            <h3 >
            Have a Custom<br/> Request? Let’s Talk!
            </h3>
            <p >
            Not sure which package suits your needs? Send us your details, and we'll tailor the perfect solution for you.
            </p>
            {/* =========================== submit button========= */}

            <button  onClick={() => handleOpen3()}  className="view_all_btn my-3">
            Submit Your Inquiry
             
            </button>
            </div>
          </div>
        </div>
        <EnquiryModal  service_id={service_id}
  service_name={service_name}vendor_id={vendor_id}serviceable_type={serviceable_type}
  ser_type={ser_type} open3={open3} handleClose3={handleClose3}/>
     
       
      </div>
    </div>
  )
}

export default EnquerySec