import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useAuth } from '../Context/ContextProvider';
import { Box, Modal } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius:"5px"
};

const Form = ({ data,cartData,order }) => {
  const {web_url,setCartData}=useAuth()
  const stripe = useStripe();
  const elements = useElements();
  const navigate=useNavigate()
  const handleSubmit = async (event) => {
    event.preventDefault();
    if(order()){
      if (!stripe || !elements) {
        console.error('Stripe or Elements not yet loaded');
        return;
      }
    
      // Confirm the payment without redirecting
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: 'http://localhost:3000/checkout',
           // Provide a valid return URL with https://
        },
        redirect:"if_required"
        
      });
    
      if (error) {
        console.error(error.message);
      } else if (paymentIntent) {
        setCartData("")
       navigate(`${web_url}order-placed`)
      }
    }
  };
  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <PaymentElement />
      <div className="row">
              <div className="col-12 my-3">
                <div className="butn">
                  <button onClick={handleSubmit}  disabled={cartData?.data?.cart?.length?false:true} style={{cursor:`${cartData?.data?.cart?.length?"pointer":"not-allowed"}`    }} className="btn btn-secondary my-2">Continue to Payment</button>
             
                </div>
              </div>
            </div>
    </form>
  );
};

const PaymentForm = ({data,setData,cartData,order,publicKey}) => {
  const stripePromise = loadStripe(publicKey);
  const [clientSecret, setClientSecret] = useState('');
  const { createOrder  ,clientTex,web_url,orderDone,setCartData} = useAuth();
  const [open, setOpen] = React.useState(false);

  
  const handleOpen = async() => {
    const isValid = await order();
    if(isValid){
      console.log("valid");
      
      setOpen(true)
      let amount = parseInt((cartData?.data?.total?.subtotal + (cartData?.data?.total?.subtotal * clientTex?.tax) / 100)*100);


  
      fetchClientSecret(amount)
    }
    else{
      console.log("not valid");
    }
  };
  const handleClose = () =>{
     setOpen(false)
     setCartData("")
    };
  const fetchClientSecret = async (amount) => {
    let array=[]
    // eslint-disable-next-line
    cartData?.data?.cart?.map((item)=>{
      let obj={
        vendor_id:item?.plan?.vendor?.id,
        plan_id:item?.plan?.id
      }
      array.push(obj)
    })
    createOrder({...data,plans:array})
  };
  useEffect(() => {
    if (orderDone?.payment_intent_secret) {
      console.log(orderDone?.paymentDetails?.payment_intent);
      
      setClientSecret(orderDone?.payment_intent_secret)
    }
    // eslint-disable-next-line
  }, [orderDone])
  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
  };
  console.log(clientSecret,options,publicKey);
  return (
<>
<div className='checkout_btn_model'>
<button onClick={handleOpen}  disabled={cartData?.data?.cart?.length?false:true} style={{cursor:`${cartData?.data?.cart?.length?"pointer":"not-allowed"}`    }} className="btn btn-secondary my-2">Continue to Payment</button>
<Link to={`${web_url}order-summry`}>
<button style={{background:"none",color:"#494949"}} className='btn btn-secondary mx-2'>Back</button>
</Link>

</div>
<Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <div style={{borderBottom:"1px solid lightgray",padding:".5rem"}} className='model_header d-flex justify-content-between align-items-center'>
        <h4 className='m-0'>Payment</h4>
        <span onClick={handleClose}> <CloseOutlined/> </span>
      </div>
      <div className='p-3 d-flex justify-content-center align-items-center flex-column modaly_payment_checkout'>
{      clientSecret ? 
    <Elements stripe={stripePromise} options={options}>
      <Form  data={data} order={order} setData={setData} cartData={cartData}  />
    </Elements>
  : 
    <div>Loading...</div>}

       </div>

    </Box>
  </Modal>
</>

  )  
};

export default PaymentForm;
