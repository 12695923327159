import React from 'react'
import { IoIosArrowRoundBack } from "react-icons/io";
import confirmOrder from "../../../asset/icons/confirmOrder.png";
function ClientReviewing({show,setShow}) {
  return (
    <div>
    <div className="new_order_nav">
      <div>
        <h4 className="d-flex align-items-center">
          {" "}
          <img
            src={confirmOrder}
            alt=""
            style={{ marginRight: "11px", width: "22px", height: "19px" }}
          />
          Order ID 235487
        </h4>
      </div>
      <div
        className="new_order_nav_btn"
        onClick={() => setShow({ ...show, client_review: false })}
      >
        <button className="btn_11" style={{ borderRadius: "4px" }}>
          back <IoIosArrowRoundBack />{" "}
        </button>
      </div>
    </div>
    <div className="table_box">
      <div className="row">
        <div className="col-12">
          <div className="water">
            <p className="water_p">Watermarked Sample Data Link</p>
            <p className="water_time" style={{color:"#A9AAAF"}}>Date: <span style={{ color:"#6e7079"}}>12 June 2024</span></p>
          </div>
        </div>
        <div className="col-lg-3 col-12 my-2">
            <p className="new_order_label m-0">
            Sample Data Title
            </p>
            <p className='sample_p'>
            Sample Data Title
            </p>
        </div>
        <div className="col-lg-3 col-12 my-2">
            <p className="new_order_label m-0">
            Watermarked Sample Data Link
            </p>
            <p className='sample_p'>
            www.wetransfer.com
            </p>
        </div>
        <div className="col-12">
            <div className="new_order_label">
            Sample Data Description
            </div>
            <p className='sample_p'>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages 
            </p>
            <hr />
        </div>
   <div className="col-12">
    <div className="d-flex justify-content-between  align-items-center">
        <p className='status-original-submitted'style={{fontWeight:'400', fontSize:'12px'}}>
        Water Mark Data Submitted
        </p>
    <button  className="water_btn m-0">
    Revise & Resend
            </button>
    </div>
   </div>
      </div>
    </div>
  </div>
  )
}

export default ClientReviewing
