import React, { useEffect } from 'react'
import logo from '../asset/logo (1).png'
import mail from '../asset/detail/02-lottie-tick-01-instant-2.gif'
import { ArrowRightAltOutlined } from '@mui/icons-material'
import Footer from '../Components/NavBarFooter/Footer'
import { Link } from 'react-router-dom'
import { useAuth } from '../Context/ContextProvider'
function EmailVerificationPage() {
  const {sendEmailVerification,web_url}=useAuth()
  useEffect(()=>{
    let token=window.location.search.split("?data=")[1]
    sendEmailVerification(token)
    // eslint-disable-next-line
  },[])
  return (
    <div className='varification_main'>
    <div className='container'>
        <div className='d-flex justify-content-center mt-5'>
            <img style={{width:"200px"}} src={logo} alt='...'/>
        </div>
        <div className='varification_card m-auto mt-5'>
        <div className='d-flex justify-content-center '>
            <img style={{width:"200px"}} src={mail} alt='...'/>
        </div>
           <h4 className='text-center'>Email Verification Confirmed! </h4>
           <h6 className='text-center '>Welcome to Photography Studio Hub</h6>
           <p className='text-center p-3'>Lorem Ipsum is simply dummy text of the printig, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop</p>
          <div className='d-flex justify-content-center my-2'>
            <Link to={`${web_url}clinet-signin`}>
           <button className='go_to_site mx-2'>Go to Sign In</button>
            </Link> 
            <Link to={`${web_url}`}>
           <button className='go_to_site'>Go to the Site<ArrowRightAltOutlined/></button>
            </Link> 
          </div>
        </div>
    </div>
    <div className='my-3'>
    <Footer/>
    </div>
    </div>
  )
}

export default EmailVerificationPage