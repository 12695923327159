import React, { useEffect, useState } from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import Banner from '../Components/StudioProfile/Banner'
import StudioPricing from '../Components/PhotograypyDetailPage/StudioPricing'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import Footer from '../Components/NavBarFooter/Footer'
import { useParams } from 'react-router-dom'
import { useAuth } from '../Context/ContextProvider'
import Review from './Review'
import PhotoVideo from '../Components/StdioDetail/PhotoVideo'
import img from "../asset/icons/video production.png"
import img1 from "../asset/icons/documents.png"
import { IoPerson } from 'react-icons/io5'
function StudioPrfoile() {
  const [showFullText, setShowFullText] = useState(false);
  const [packages,setPackage]=useState(false)
  const parms=useParams()
  const {      studioProfileData,
    getStudioProfile}=useAuth()
  useEffect(()=>{
    getStudioProfile(parms.id)
    // eslint-disable-next-line
  },[])
  return (
    <>
    <div className='container'>
        <NavBar/>
        <Banner data={studioProfileData} />

<PhotoVideo  data={studioProfileData} />
        <div className='text-center'>

        {/* <Amenity data={studioProfileData} /> */}
        </div>


        <div className={`row ${studioProfileData?.additional_information ? "mt-5":"mt-0"}`}>
     
     {
      studioProfileData?.additional_information?

        <div className="col-12">
          <div
            dangerouslySetInnerHTML={{
              __html: showFullText
                ? studioProfileData?.additional_information
                : `${studioProfileData?.additional_information?.slice(0, 640)}...`,
            }}
            className="Ser_para"style={{fontSize:'14px', lineHeight:'24px'}}
          ></div>
          {studioProfileData?.additional_information?.length > 640 && (
            <>
          <hr className="mb-0" style={{width:"90%", margin:"2rem auto"}}/>
            <div className="d-flex justify-content-center">
              <button
                className="ser_read "
                onClick={() => setShowFullText(!showFullText)}
              >
                {showFullText ? "Read Less" : "Read More"}
              </button>
            </div>
            </>
          )}
        </div>
        :''
     }
       
     
        </div>


 



        <StudioPricing data={studioProfileData} packages={packages} setPackage={setPackage}/>

<div className="Studio_detail_page mt-5 col-12 d-flex justify-content-center">
          <h4 className="studio_head new_studio_head  " style={{ marginTop:"50px" }}>
            my reviews
          </h4>
        </div>


<div className='px-4 mb-3' id='reviews'>
<Review data={studioProfileData?.reviews}/>
</div>
       
    
    </div>
        <JoinOurTeam/>
        <Footer/>
    </>
  )
}

export default StudioPrfoile