import React from 'react'
import img from './../../asset/dummy/dummy2.webp'
import img3 from './../../asset/frameProfile.png'
import { IoMdPaperPlane } from "react-icons/io";
import { FaStar } from "react-icons/fa6";
import { GoPersonFill } from 'react-icons/go';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoCopy} from 'react-icons/io5';

function Banner({ data }) {
    const [copied, setCopied] = React.useState(false);
    const copyToClipboard = () => {
        navigator.clipboard.writeText(window?.location?.href)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
            })
            .catch(err => {
                console.error('Failed to copy:', err);
            });

    };
    return (
        <div className='pofile_banner new_profile_banner my-5'>
            <div className='d-flex flex_box'>
                <div className='profile_main_imgbox new_profile_main_img' style={{ position: "relative", top: "-50px", height: "300px", width: "300px",}}>
                    <img style={{ height: "300px", width: "300px", position: "absolute", borderRadius: "20px", }} src={img3} alt='...' />
                    <div className='profile_img_vendor_main new_vendor_profile_img border border-danger'>
                        <img style={{ height: "100%", width: "100%", position: "absolute" }} src={data?.avatar ? data?.avatar : img} alt='...' />

                    </div>
                </div>
                <div className='ms-lg-4 me-lg-3 ps-lg-2 mx-3'>
                    <div className="profile_details new_profile_details">
                        <h1 className='profile_name' >{data?.name}</h1>

                        <h3 className=' profile_serv'>{data?.vendor_service?.service?.name}</h3>


                        <div className='d-flex  flex-wrap new_setted_box-1'>
                            <div className='d-flex align-items-center  pe-2 my-1 ' style={{ borderRight: "1px solid lightgray" }}>
                                <svg className='rank_svg' xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                                    <path d="M6.61448 2.68627L4.90176 2.4326L4.14456 0.857042C4.0099 0.581774 3.71845 0.466208 3.46022 0.50849C3.27299 0.539495 3.10697 0.655992 3.01291 0.857042L2.24463 2.4326L0.542986 2.68627C0.0246544 2.75955 -0.183783 3.40499 0.190663 3.77514L1.43577 5.00214L1.1351 6.7468C1.07331 7.13764 1.36291 7.45519 1.69586 7.49747C1.81023 7.5078 1.93474 7.48714 2.04819 7.42325L3.57367 6.60964L5.09915 7.42325C5.56585 7.66564 6.10538 7.26447 6.01224 6.74681L5.7217 5.00215L6.95667 3.77514C7.3302 3.40497 7.12267 2.76046 6.61448 2.68627Z" fill="white" />
                                    <rect x="1.021" y="12.5" width="4" height="7" fill="white" />
                                    <rect x="7.021" y="8.5" width="4" height="11" fill="white" />
                                    <rect x="13.021" y="2.5" width="4" height="17" fill="white" />
                                </svg>
                                <span className='profile_pricing ps-2' >
                                    Starting Price
                                    <span >
                                        £{data?.starting_price}
                                    </span>
                                </span>
                            </div>
                            <div style={{ borderRight: "1px solid lightgray" }} className='d-flex justify-content-center my-1  align-items-center ps-2'>

                                <div>
                                    <FaStar className='star_svg' style={{ fontSize: "18px", color: "#F1A73C" }} />
                                </div>
                                <div className='inco_text' style={{ fontSize: "20px", fontWeight: "600", lineHeight: "24px", color: "white" }}>{data?.rating ? data?.rating.toFixed(2) : "0"}</div>
                            </div>
                            <div style={{ borderRight: "1px solid lightgray" }} className=' my-1 d-flex justify-content-center align-items-center ps-lg-2 ps-0'>

                                <div>
                                    <FaMapMarkerAlt className='pin_svg' style={{ fontSize: "21px", color: "white"}} />
                                </div>
                                <div className='inco_text new_inco_text' style={{ fontSize: "18px", fontWeight: "400", lineHeight: "24px", color: "white" }}>{data?.state}, {data?.city}</div>
                            </div>

                            <div className=' my-1 d-flex justify-content-center align-items-cneter mx-2'>
                                <div>
                                    <IoMdPaperPlane className='plane_svg' style={{ fontSize: "18px", color: "white" }} />
                                </div>
                                <div className='inco_text' style={{ fontSize: "20px", fontWeight: "700", lineHeight: "24px", color: "white" }}>{data?.total_jobs} <span style={{ color: 'white' }}>Jobs</span></div>
                            </div>
                        </div>

                        {/* <div onClick={handleCopyText}>
                            <FaLink style={{fontSize:"20px", color:"#494949"}}/> <Link className='inco_text ms-1' style={{textDecoration:"none" , color:"#1DA1F2", fontSize:'20px', fontWeight:"400"}}>{window.location?.href}</Link>
                            <span>{copy}</span>
                        </div> */}

                        {/* <ul className='d-flex flex-wrap service_list'>
                        {data?.vendor_service?.vendor_sub_services?.map((item)=>{
                            return(

                                <li className='d-flex align-items-center'><FiCheckCircle  className='mx-1'/> {item?.sub_service?.name}</li>
                            )
                        })}
                         
                        </ul> */}
                        <div className='profile_buttons new_profile_button'>
                            <button onClick={copyToClipboard} className='profile-btn d-inline-flex align-items-center justify-content-center' style={{ background: "none", color: "white", border: "1px solid white", fontSize: "14px", fontWeight: '400', width: "151px", height: "37px" }}><IoCopy className='me-2' />{copied ? "Copied" : "Profile link"}</button>
                            <a href='#package_section'>
                                <button className=' hire-btn d-inline-flex align-items-center justify-content-center' style={{ fontSize: '14px', fontWeight: "600", background: "none", width: "151px", height: "37px", border: "1px solid white" }} ><GoPersonFill className='me-2' l />Hire Me</button>
                            </a>
                            <a href='#reviews'>
                                <button className='review-btn d-inline-flex align-items-center justify-content-center' style={{ background: "none", color: "white", border: "1px solid white", fontSize: "14px", fontWeight: '400', width: "151px", height: "37px" }}><FaStar className='me-2' />Reviews</button>
                            </a>
                        </div>


                    </div>
                </div>

            </div>

             {/* for data redency after sm screen */}


            <div className="new_data_box">
            <div className='d-flex flex_box'>
                <div className='mx-3'>
                    <div className="profile_details new_profile_details">
                        <div className='d-flex  flex-wrap new_flex_wrap'>
                            <div className='d-flex align-items-center  pe-2 my-1 ' style={{ borderRight: "1px solid lightgray" }}>
                                <svg className='rank_svg' xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                                    <path d="M6.61448 2.68627L4.90176 2.4326L4.14456 0.857042C4.0099 0.581774 3.71845 0.466208 3.46022 0.50849C3.27299 0.539495 3.10697 0.655992 3.01291 0.857042L2.24463 2.4326L0.542986 2.68627C0.0246544 2.75955 -0.183783 3.40499 0.190663 3.77514L1.43577 5.00214L1.1351 6.7468C1.07331 7.13764 1.36291 7.45519 1.69586 7.49747C1.81023 7.5078 1.93474 7.48714 2.04819 7.42325L3.57367 6.60964L5.09915 7.42325C5.56585 7.66564 6.10538 7.26447 6.01224 6.74681L5.7217 5.00215L6.95667 3.77514C7.3302 3.40497 7.12267 2.76046 6.61448 2.68627Z" fill="white" />
                                    <rect x="1.021" y="12.5" width="4" height="7" fill="white" />
                                    <rect x="7.021" y="8.5" width="4" height="11" fill="white" />
                                    <rect x="13.021" y="2.5" width="4" height="17" fill="white" />
                                </svg>
                                <span className='profile_pricing ps-2' >
                                    Starting Price
                                    <span >
                                        £{data?.starting_price}
                                    </span>
                                </span>
                            </div>
                            <div style={{ borderRight: "1px solid lightgray" }} className='d-flex justify-content-center my-1  align-items-center ps-2'>

                                <div>
                                    <FaStar className='star_svg' style={{ fontSize: "18px", color: "#F1A73C" }} />
                                </div>
                                <div className='inco_text' style={{ fontSize: "20px", fontWeight: "600", lineHeight: "24px", color: "white" }}>{data?.rating ? data?.rating.toFixed(2) : "0"}</div>
                            </div>
                            <div style={{ borderRight: "1px solid lightgray" }} className=' my-1 d-flex justify-content-center align-items-center ps-lg-2 ps-0'>

                                <div>
                                    <FaMapMarkerAlt className='pin_svg' style={{ fontSize: "21px", color: "white"}} />
                                </div>
                                <div className='inco_text new_inco_text' style={{ fontSize: "18px", fontWeight: "400", lineHeight: "24px", color: "white" }}>{data?.state}, {data?.city}</div>
                            </div>

                            <div className=' my-1 d-flex justify-content-center align-items-cneter mx-2'>
                                <div>
                                    <IoMdPaperPlane className='plane_svg' style={{ fontSize: "18px", color: "white" }} />
                                </div>
                                <div className='inco_text' style={{ fontSize: "20px", fontWeight: "700", lineHeight: "24px", color: "white" }}>{data?.total_jobs} <span style={{ color: 'white' }}>Jobs</span></div>
                            </div>
                        </div>

                        {/* <div onClick={handleCopyText}>
                            <FaLink style={{fontSize:"20px", color:"#494949"}}/> <Link className='inco_text ms-1' style={{textDecoration:"none" , color:"#1DA1F2", fontSize:'20px', fontWeight:"400"}}>{window.location?.href}</Link>
                            <span>{copy}</span>
                        </div> */}

                        {/* <ul className='d-flex flex-wrap service_list'>
                        {data?.vendor_service?.vendor_sub_services?.map((item)=>{
                            return(

                                <li className='d-flex align-items-center'><FiCheckCircle  className='mx-1'/> {item?.sub_service?.name}</li>
                            )
                        })}
                         
                        </ul> */}
                        <div className='profile_buttons new_profile_button-1'>
                            <button onClick={copyToClipboard} className='profile-btn d-inline-flex align-items-center justify-content-center' style={{ background: "none", color: "white", border: "1px solid white", fontSize: "14px", fontWeight: '400', width: "151px", height: "37px" }}><IoCopy className='me-2' />{copied ? "Copied" : "Profile link"}</button>
                            <a href='#package_section'>
                                <button className=' hire-btn d-inline-flex align-items-center justify-content-center' style={{ fontSize: '14px', fontWeight: "600", background: "none", width: "151px", height: "37px", border: "1px solid white" }} ><GoPersonFill className='me-2' l />Hire Me</button>
                            </a>
                            <a href='#reviews'>
                                <button className='review-btn d-inline-flex align-items-center justify-content-center' style={{ background: "none", color: "white", border: "1px solid white", fontSize: "14px", fontWeight: '400', width: "151px", height: "37px" }}><FaStar className='me-2' />Reviews</button>
                            </a>
                        </div>


                    </div>
                </div>

            </div>
            </div>
        </div>
    )
}

export default Banner