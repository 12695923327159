import React from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { TiTickOutline } from "react-icons/ti";
import { useAuth } from "../../Context/ContextProvider";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  py: 1,
  outline:"none"
};
function ConfirmOrderMUI({obj,orderServiceId,serviceId,pagination}) {
  console.log(obj);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [comment,setComment]=useState()
  const [image,setImages]=useState([])
  const [previewImage,setPreviewimage]=useState([])
  const {base_url,token,handleClick,setError,getOrder,app_key,buttonLoding,setButtonLoding}=useAuth()
  const handleImageChange = () => {
    // setImageError("")
    const files = image;
    const selected = [];
    // const remainingSlots = previewImages.length === 0 ? 10 : 10 - previewImages.length;
    // Check if the number of selected files exceeds the remaining slots
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      selected.push(file);
    }
    handleSubmit(selected);
  };

  const handleSubmit = async (selected) => {
    // setButtonLoding(true)
    const formData = new FormData();
    // formData.append( type==="service"?'vendor_sub_service_id':'studio_id', subserviceId);
    formData.append('vendor_comment', comment);
    formData.append('customer_id', obj?.customer?.id);
    formData.append('type', serviceId);
    formData.append('order_status_code', 27);
    selected.forEach(image => {
      formData.append('images[]', image);
    });
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}vendor/orders/update-status/${obj?.id}/${orderServiceId}`, 
        
        
        {
        method: "POST",
        headers: {
          'Authorization': token,
          "app-key":app_key
        },
        body: formData
      });
      const jsonData = await response.json();
      // setButtonLoding(false)
      if (response.status === 200 || response.status === 201) {
        // if(type==="service"){
        //   getPortFolioImage(subserviceId, serviceId);
          
        //   }
        //   else{
        //   getStudioPortFolioImage(subserviceId, serviceId);

        // }
        setButtonLoding(false)
        getOrder(pagination)
        handleClick();
        setError({ message: "Data Sent Successfully", color: "success" });
        setComment("")
        setImages([])
        setPreviewimage([])
        handleClose()
      } else {
        setButtonLoding(false)
        handleClick();
        setError({ message: "Some thing Went Wrong", color: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
    
      <li onClick={handleOpen} style={{ border: "none" }}>
      Service Deliver <TiTickOutline />
      </li>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="abc">
          <div className="row m-0">
            <div className="col-12 p-0" >
              <div className="ordr_comp_head px-3">
                <h1>Service Delivered</h1>
                <div  onClick={handleClose} >X</div>
              </div>
              <hr />
              <div className="ordr_comp_content px-3">
                <h1>
                 
                  Do you sure to mark this order <br/>
                  as “Service Delivered”.
                </h1>
                <p>
                Please submit at least 3 images reflecting the order you are marking as delivered<span style={{ color: "#B9444B" }}>*</span>
                </p>
       {image?.length>0?<div>
       {previewImage?.map((item)=>{
        return(
          <img style={{width:"100px",height:"100px",objectFit:"cover",margin:"1rem 1rem 2rem 0"}} src={URL.createObjectURL(item)}/>
        )
       })}
        </div> :         <div className="ordr_comp_file">
                  <div className="ordr_comp_drag">
                    <h1>
                      Drag & Drop images here
                      <br /> or
                      <br />{" "}
                      <span>
                        <input
                          className="d-none"
                          type="file"
                          id="Order_browser"
                          onChange={(event)=>{
                          
                            if (event.target.files?.length!==3) {
                              alert("You must be upload 3 images")
                              // setImageError(`You can upload only ${remainingSlots} more ${remainingSlots === 1 ? 'image' : 'images'}.`)
                              event.target.value = ''; // Reset file input
                              return;
                            }
                            setImages(event.target.files)
                            setPreviewimage(Array.from(event.target.files))
                          }}
                          multiple
                          accept="image/*"
                        />
                        <label htmlFor="Order_browser">Browse</label>
                      </span>
                    </h1>
                  </div>
                  <p
                    style={{
                      fontSize: "8px",
                      fontWeight: "400",
                      color: "#6A6A6A",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    Support: .PNG, .JPG, .GIF, .TIFF, .JPEG. File max size 5MB.{" "}
                  </p>
                </div>}

                <p
                 
                >
                  By Clicking on Service Delivered, you are asking us to Mark the order as Service Delivered, you received from “Client Name”.
                </p>
                <div className="d-flex text-allign-center my-2">
                  <input type="checkbox" />
                  <span
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "#494949",
                      marginLeft: "8px",
                    }}
                  >
                    Service Delivered
                  </span>
                </div>




                <div className="ordr_comp_comment ">
                  <h2>Comment (Optional)</h2>
                  <div className="ordr_comp_inp">
                    <textarea
                    className="confirm_textArea"
                      type="text"
                      name=""
                      id=""
                      onChange={(e)=>setComment(e.target.value)}
                      placeholder="Type here..."
                    />
                  </div>
                </div>
                <div className="ordr_comp_btns">
                    <button onClick={handleImageChange} className="ordr_comp_btn1">
                    {buttonLoding ? (
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                ) : (
                                  "Done"
                                )}
                      
                      
                      </button>
                    <button className="ordr_comp_btn2" onClick={handleClose}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default ConfirmOrderMUI;
