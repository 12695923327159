import React, { useEffect, useState } from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import { MdDeleteOutline } from 'react-icons/md'
import { FaCheckCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Menu, MenuItem, Typography } from '@mui/material'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import Footer from '../Components/NavBarFooter/Footer'
import { FaCaretDown, FaCircleMinus, FaCirclePlus } from 'react-icons/fa6'
import { useAuth } from '../Context/ContextProvider'
import img1 from '../asset/dummy/dummy2.webp'
function OrderSummry() {
  const nanvigate = useNavigate()
  const { getCart, removeCart,handleClick,checkSlot,
    setError,updateCart,
    cartData,setCartData, web_url } = useAuth()
  useEffect(() => {
    const vendor_data = JSON.parse(localStorage.getItem('vendor_data'))
    if (vendor_data?.data?.token && vendor_data?.data.role === "client") {
      getCart()
    }
    // eslint-disable-next-line
  }, [])
  const handleIncreaseQuantity = (cartId) => {
    setCartData(prevData => {
      const updatedCart = prevData?.data?.cart?.map(item => {
        if (item.cart_id === cartId) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
      return { ...prevData, data: { ...prevData.data, cart: updatedCart } };
    });
  };
  
  const handleDecreaseQuantity = (cartId) => {
    setCartData(prevData => {
      const updatedCart = prevData?.data?.cart?.map(item => {
        if (item.cart_id === cartId && item.quantity > 1) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      });
      return { ...prevData, data: { ...prevData.data, cart: updatedCart } };
    });
  };
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [slots, setsots] = useState()
  useEffect(() => {
    let array = []
    // eslint-disable-next-line
    cartData?.data?.cart?.map((item) => {
      array.push({
        id:item?.slot?.id,
        service_date:item?.serviceDate
      })
    })
    setsots(array)
    // eslint-disable-next-line
  }, [cartData])
  const isCheckoutDisabled = (cart) => {
    if (!cart || cart.length === 0) {
      return true;
    }

    const currentTime = new Date();
    const hasExpiredItems = cart.some(item => {
      const expiredAt = item.expired_at ? new Date(item.expired_at) : null;
      return expiredAt && currentTime > expiredAt;
    });

    return hasExpiredItems;
  };
  const handleCheckout = async () => {
    if (!isCheckoutDisabled(cartData?.data?.cart)) {
      try {
        const isAnyReserved = await checkSlot(slots);
        if (!isAnyReserved) {
          updateCart(cartData?.data?.cart)
          navigate(`${web_url}checkout`);

        } else {
          handleClick();
          setError({
            message: "Some slots are reserved. Please remove the reserved slots from cart.",
            color: "error"
          });
        }
      } catch (error) {
        console.error(error);
        setError({
          message: "An error occurred. Please try again later.",
          color: "error"
        });
      }
    } else {
      handleClick();
      setError({
        message: "Please remove the expired item from the cart!",
        color: "error"
      });
    }
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const navigate = useNavigate()
 
  
  // Function to calculate time remaining for an item
  const calculateTimeLeft = (expiryTime) => {
    const currentTime = new Date();
    const expiredAt = new Date(expiryTime);
    const difference = expiredAt - currentTime;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  // State to hold timers for each item
  const [timers, setTimers] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      if (cartData?.data?.cart?.length) {
        // Update the timers for each item
        const newTimers = {};
        cartData.data.cart.forEach((item) => {
          if (item.expired_at) {
            newTimers[item.cart_id] = calculateTimeLeft(item.expired_at);
          }
        });
        setTimers(newTimers);
      }
    }, 1000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [cartData]);


  
  return (
    <>
      <div className='container'>
        <NavBar    />
      
      <div className=" main" style={{marginTop:"2rem"}}>

        <div className="row d-flex align-items-center">
          <div className="col-lg-6 col-12 mb-2">
            <div className="content d-flex  align-items-center">
              <p className='order_summary_heading'>
               Cart Overview
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className='d-flex justify-content-lg-end align-items center mb-1'>
              <button className='ordr_summ_btn ' style={{ fontSize: "15px", padding: "7px 0rem 7px 1rem", height: "max-content", border: "none", background: "#414040", color: "white" }} onClick={handleOpenUserMenu}>Add More Services <FaCaretDown className='mx-3 ' /></button>



              <button disabled={cartData?.data?.cart?.length ? false : true} style={{ cursor: `${cartData?.data?.cart?.length ? "pointer" : "not-allowed"}`, background: `${cartData?.data?.cart?.length ? "#494949" : "lightgray"}`, color: `${cartData?.data?.cart?.length ? "#ffffff" : "#494949"}` }} onClick={handleCheckout} className='ordr_summ_btn_1'>Checkout</button>
            </div>

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}

            >

              <MenuItem style={{ width: "205px" }} onClick={() => {
                handleCloseUserMenu()
                navigate(`${web_url}our-service`)
              }}>
                <Typography textAlign="center">Photography Services</Typography>


              </MenuItem>
              <hr style={{ borderColor: "#d4d7db", margin: "5px 0" }} />
              <MenuItem style={{ width: "205px" }} onClick={() => {
                handleCloseUserMenu()
                navigate(`${web_url}our-studio`)
              }}>
                <Typography textAlign="center">Studio Spaces</Typography>
              </MenuItem>
              <hr style={{ borderColor: "#d4d7db", margin: "5px 0" }} />
              <MenuItem style={{ width: "205px" }} onClick={() => {
                handleCloseUserMenu()
                navigate(`${web_url}explor-telent`)
              }}>
                <Typography textAlign="center">Market Place</Typography>
              </MenuItem>
            </Menu>

          </div>

        </div>

        {cartData?.data?.cart?.length ? cartData?.data?.cart?.map((item) => {
          const currentTime = new Date();
          const expiredAt = item.expired_at ? new Date(item.expired_at) : null;
          const isExpired = expiredAt && currentTime > expiredAt;
          const timeLeft = timers[item.cart_id] || {};
     
          
          return (
            <div key={item?.cart_id} className="row mx-1 " style={{ background: "#ececeb", borderRadius: "5px", padding: ".7rem", marginTop: "10px" }}>
              <div className="col-lg-2 col-12 my-2 d-flex align-items-center">
                <img src={item?.vendor?.avatar ? item?.vendor?.avatar : img1} alt="" style={{ width: "100%", height: "166px" }} />
              </div>
              <div className="col-lg-10 col-12 my-2">
                <div>
                  <div className="product_heading">
                    <h5 style={{padding:"0",margin:"0"}}>{item?.plan?.type==="studio"? item?.plan?.studio?.name:item?.plan?.vendor_sub_service?.sub_service?.name}</h5>
                    <span onClick={() => removeCart(item?.cart_id)} style={{ color: '#B9444B', fontSize: '22px' }}><MdDeleteOutline /></span>
                  </div>
                  <div className="product_content">
                    <div className="product_content_heading">
                      <p style={{padding:"0",margin:"0"}}>{item?.serviceDate} {item?.plan?.booking_service_type!=="item_quantity"&&<span>{item?.slot?.start_time} to {item?.slot?.end_time}</span>}</p>
                      <p style={{padding:"0",margin:"0"}}>Vendor: {item?.vendor?.name}</p>
                    </div>
                    <div className="product_content_hour">
                      <div className="product_content_hour_inner">
                        {item?.plan?.offers?.map((offer, index) => (
                          offer !== null && (
                            <p key={index} className="m-0 mb-2">
                              <span><FaCheckCircle className="i" /></span> {offer}
                            </p>
                          )
                        ))}
                      </div>
                      <hr style={{ margin: "0 0 10px 0" }} />
                      <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center'>
                       {item?.plan?.booking_service_type==="item_quantity"? <div className='d-flex justify-content-between align-items-center p-2 me-3' style={{width:"100px",border:"1px solid #494949",borderRadius:"5px"}}>
                          
                        <FaCircleMinus    onClick={() => handleDecreaseQuantity(item?.cart_id)}  style={{cursor:item?.quantity<=1?"not-allowed":"pointer",fontSize:"20px"}} />
                        {item?.quantity}
                        <FaCirclePlus  onClick={() => handleIncreaseQuantity(item?.cart_id)}  style={{cursor:"pointer",fontSize:"20px"}} />

                        </div>:<div  style={{border:"1px solid #494949",borderRadius:"5px",padding:".5rem 1rem"}}className='me-3'>{item?.plan?.plan_duration}</div>}
                      <p style={{ fontWeight: "600",padding:"0",margin:"0" }}>{item?.plan?.booking_service_type==="item_quantity"?"Items":"hours"} GBP £ {item?.quantity*item?.plan?.price_set}</p>
                        </div>
                      {isExpired &&<p style={{ fontWeight: '600' }}>Your selected slot has expired. Please choose a new one to continue.<span className='expiry'>00:00</span></p>}
                      {!isExpired &&<p style={{ fontWeight: '600' }}>Your selected slot is expiring soon. Please complete checkout.<span className='pending'>{timeLeft?.minutes}:{timeLeft?.seconds}</span></p>}
                      </div>
                      {/* {timeLeft} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }) : (
          <div className="p-2 d-flex justify-content-center py-5">
            <h5 className="my-4">Your cart is empty. Add services to book your next experience!</h5>
          </div>
        )}






        <div className="row mt-3">

          <div className=" col-12 mb-2 ms-1">
            <button disabled={cartData?.data?.cart?.length ? false : true} style={{ cursor: `${cartData?.data?.cart?.length ? "pointer" : "not-allowed"}`, background: `${cartData?.data?.cart?.length ? "#494949" : "lightgray"}`, color: `${cartData?.data?.cart?.length ? "#ffffff" : "#494949"}` }} onClick={handleCheckout} className=" btn1">
              Checkout
            </button>
            <button onClick={() => {
              nanvigate(`${web_url}explor-telent`)

            }} className=" btn2">
              Back
            </button>
          </div>
        </div>
      </div>

      </div>
      <div className='my-5' style={{ marginTop: "5rem" }}>

        <JoinOurTeam />
        <Footer />
      </div>
    </>

  )
}

export default OrderSummry