import React, { useEffect, useState } from "react";
import studi1 from "../../asset/studioSetting/studio_1.png";
import studi2 from "../../asset/studioSetting/studio_2.png";
import studi3 from "../../asset/studioSetting/studio_3.png";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegEyeSlash } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { useAuth } from "../../Context/ContextProvider";
import studiSetting from "../../asset/icons/studio_setting.png";
import { IoMdStar, IoMdStarOutline } from "react-icons/io";



function StudioSetting() {
  let array = [studi1, studi2, studi3, studi2, studi3, studi1];
  const {
    getStudio,
    app_key,
    togalStudioImage,
    studioData,
    deleteStudioGellery,
    getStudioGellery,
    vendorServices,
    vedorServiceData,
    studioGalleryData,
  } = useAuth();
  const [idx, setIdx] = useState([]);
  const [show, setShow] = useState();
  const [result1, setResult1] = useState([]);
  const [imageError, setImageError] = useState();

  useEffect(() => {
    getStudio();
  }, []);

  useEffect(() => {
    if(imageError){
      setTimeout(() => {
        setImageError()
        
      }, 3000);
    }
  }, [imageError]);
  useEffect(() => {
    if (studioData?.length) {
      setShow(studioData?.length ? studioData[0]?.studio_id : "");
      getStudioGellery(studioData?.length ? studioData[0]?.studio_id : "");
      setResult1(studioData);
    }
  }, [studioData]);
  console.log(studioGalleryData);
  const {
    handleClick,
    setError,
    base_url,
    token,
    setButtonLoding,
    buttonLoding,
  } = useAuth();

  const handleImageChange = (event) => {
    setImageError("");
    const files = event.target.files;
    const selected = [];
    const maxImageSize = 10 * 1024 * 1024; // 10 MB in bytes
    const remainingSlots =
      studioGalleryData?.length === 0 ? 6 : 6 - studioGalleryData?.length;

    // Check if the number of selected files exceeds the remaining slots
    if (files.length > remainingSlots) {
      setImageError(
        `You can upload only ${remainingSlots} more ${
          remainingSlots === 1 ? "image" : "images"
        }.`
      );
      event.target.value = ""; // Reset file input
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check if each image is less than or equal to 10 MB
      if (file.size > maxImageSize) {
        setImageError(`Image ${file.name} exceeds 10 MB. Please select a smaller file.`);
        event.target.value = ""; // Reset file input
        return;
      }

      selected.push(file);
    }

    handleSubmit(selected);
    // setPreviewImages(previews);
};

  const handleSubmit = async (selected) => {
    setButtonLoding(true);

    const formData = new FormData();
    formData.append("studio_id", show);
    selected.forEach((image) => {
      formData.append("media[]", image);
    });
    try {
      const response = await fetch(`${base_url}studios/upload-media`, {
        method: "POST",
        headers: {
          Authorization: token,
          "app-key": app_key,
        },
        body: formData,
      });
      const jsonData = await response.json();
      setButtonLoding(false);
      if (response.status === 200 || response.status === 201) {
        getStudioGellery(show);
        handleClick();
        setError({ message: "Images Uploaded", color: "success" });
      } else {
        handleClick();
        setError({ message: "Some thing Went Wrong", color: "error" });
      }
    } catch (error) {
      console.log(error);
      setButtonLoding(false);
    }
    // fetch(`${base_url}service-gallery/upload`, {
    //   method: 'POST',
    //   headers:{
    //     'Authorization': token
    //   },
    //   body: formData,
    // })
    // .then(response => {
    //   console.log(response.data);
    //   if (response.ok) {

    //     // Optionally, reset state after successful upload
    //     setPreviewImages(response?.data);
    //   }
    // })
    // .catch(error => {
    //   console.error('Error:', error);
    //   alert('Error uploading images.');
    // });
  };
  function searchBySubServiceName(searchTerm) {
    let results = studioData?.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setResult1(results);
  }
  useEffect(() => {
    vendorServices("studio", 1);
  }, []);
  const [data, setData]=useState()
  return (
    <>
      <div className="new_order_nav ">
        <div>
          <h4 className="d-flex align-items-center">
            {" "}
            <img
              src={studiSetting}
              alt=""
              style={{ marginRight: "11px", width: "22px", height: "19px" }}
            />
            Studio Setting{" "}
          </h4>
        </div>
      </div>
      <div
        style={{
          minHeight: "82vh",
          background: "white",
          boxShadow: "0px 0px 4px 0 rgba(0, 0, 0, 0.25)",
          marginRight: "12px",
          padding: "13px 11px 0px 18px",
          position: "relative",
          paddingBottom: "1rem",
        }}
      >
        <div className="row">
          <div className="col-12">
            <p className="comm_para">
              Add attractive images of your studio, including its appearance,
              equipment, and other relevant facilities. These images can help
              clients choose your studio.
            </p>
            <hr className="mt-2 mb-3" />
          </div>
          <div className="col-lg-3 col-12">
            <h3
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "28px",
                margin: "0",
                color: "#494949",
              }}
            >
              Available Studio(s)
            </h3>
          </div>
          <div className="col-lg-9 col-12">
            <h1
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "34px",
                margin: "0",
                color: "#494949",
              }}
            >
              STUDIO GALLERY
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-12">
            <div className="avail_main" style={{ maxHeight: "67vh" }}>
              <div className="avail_service avail_sel p-0">
                <select
                  onChange={(e) => {
                    if (e.target.value === "all studio") {
                      setResult1(studioData);
                    } else {
                      let array = [];
                      studioData?.filter((item) => {
                        item?.studio_sub_services?.map((items) => {
                          if (items?.id == e.target.value) {
                            array.push(item);
                            // setServiceId(item?.service?.sub_services[0]?.vendor_sub_service_id)
                            // getPlanByService({ service_id: item?.service?.sub_services[0]?.vendor_sub_service_id,type:availbilty?.type })
                          }
                        });
                      });
                      if (array?.length > 0) {
                        setShow(array[0]?.studio_id);
                        getStudioGellery(array[0]?.studio_id);
                      }
                      setResult1(array);
                    }
                  }}
                  name=""
                  id=""
                >
                  <option value="all studio">All Studio</option>
                  {vedorServiceData?.all_services?.map((item) => {
                    return item?.sub_services?.map((items) => {
                      return <option value={items?.id}>{items?.name}</option>;
                    });
                  })}
                </select>
              </div>
              <div className="avail_service pt-0">
                <input
                  onChange={(e) => {
                    searchBySubServiceName(e.target.value);
                  }}
                  type="search"
                  placeholder="Search Service"
                />
                <ul className="m-0 p-0">
                  {result1?.map((item) => {
                    return (
                      <li
                        onClick={() => {
                          setShow(item.studio_id);
                          getStudioGellery(item.studio_id);
                        }}
                        className={`${
                          show !== item.studio_id ? "" : "avail_active"
                        }`}
                      >
                        {item?.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-12">
         
           
              <>
             
                <div style={{ position: "relative" }}>
                  <p
                    className="error_text"
                    style={{ position: "absolute", top: "-5px" }}
                  >
                    {imageError}
                  </p>
                </div>
                {studioData?.length ? (
                  ""
                ) : (
                  <p className="error_text">Please add Studio first</p>
                )}
                <div className="row">
                  <div className=" col-12 col-lg-7 my-3 d-flex align-items-center">
                    {studioGalleryData?.length < 6 && result1?.length > 0 ? (
                      <label
                        for="gellery_img"
                        className="studio_sett_btn_upl d-flex justify-content-center align-items-center"
                      >
                        {buttonLoding ? (
                          <div
                            className="spinner-border text-light"
                            role="status"
                          ></div>
                        ) : (
                          "Upload"
                        )}{" "}
                      </label>
                    ) : (
                      ""
                    )}
                    <input
                      disabled={buttonLoding}
                      id="gellery_img"
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      multiple
                      onChange={handleImageChange}
                    />
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#494949",
                        marginLeft: "20px",
                      }}
                    >
                      {studioData?.length
                        ? studioGalleryData?.length < 6 && result1?.length > 0
                          ? `${studioGalleryData?.length}/6`
                          : ""
                        : null}
                    </span>
                  </div>
   
                </div>

                {buttonLoding ? 
              <div className="row placeStuido">
                <div className="col-lg-4 col-12 my-2 placeholder-glow ">
                  <div className="placeholder loader rounded "></div>
                </div>
                <div className="col-lg-4 col-12 my-2 placeholder-glow ">
                  <div className="placeholder loader rounded "></div>
                </div>
                <div className="col-lg-4 col-12 my-2 placeholder-glow ">
                  <div className="placeholder loader rounded "></div>
                </div>
                <div className="col-lg-4 col-12 my-2 placeholder-glow ">
                  <div className="placeholder loader rounded "></div>
                </div>
                <div className="col-lg-4 col-12 my-2 placeholder-glow ">
                  <div className="placeholder loader rounded "></div>
                </div>
                <div className="col-lg-4 col-12 my-2 placeholder-glow ">
                  <div className="placeholder loader rounded "></div>
                </div>
              </div>
              :

                <div
                  className="row p-0 m-0 addStudio_main"
                  style={{ maxHeight: "57vh" }}
                >
                  {studioGalleryData?.map((item, i) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-12 my-2 p-0 pe-3">
                        <div className="studio_card">
                          <img
                            style={{ height: "100%", width: "100%" }}
                            src={item.url}
                            alt=""
                          />
                          <div className="studio_sett_content">
                            <div
                              onClick={() => {
                                if (item?.is_featured) {
                                  togalStudioImage({
                                    type: "is_featured",
                                    status: 0,
                                    id: item?.id,
                                    studio_id: show,
                                  });
                                } else {
                                  togalStudioImage({
                                    type: "is_featured",
                                    status: 1,
                                    id: item?.id,
                                    studio_id: show,
                                  });
                                }
                              }}
                              style={{ fontSize: "15px" }}
                            >
                              {item?.is_featured ? (
                                <IoMdStar />
                              ) : (
                                <IoMdStarOutline />
                              )}
                            </div>
                            <div>
                              <RiDeleteBinLine
                                onClick={() =>
                                  deleteStudioGellery(item?.id, show)
                                }
                              />
                            </div>
                            <div
                              onClick={() => {
                                if (item?.is_active) {
                                  togalStudioImage({
                                    type: "is_active",
                                    status: 0,
                                    id: item?.id,
                                    studio_id: show,
                                  });
                                } else {
                                  togalStudioImage({
                                    type: "is_active",
                                    status: 1,
                                    id: item?.id,
                                    studio_id: show,
                                  });
                                }
                              }}
                            >
                              {item?.is_active ? (
                                <IoEyeOutline />
                              ) : (
                                <FaRegEyeSlash />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
}
              </>
           
          </div>
        </div>
      </div>
    </>
  );
}

export default StudioSetting;
