import React, { useEffect, useState } from "react";
import { FaCalendarAlt, FaCheckCircle, FaRegQuestionCircle } from "react-icons/fa";
import { FaRegEye} from "react-icons/fa6";
import { useAuth } from "../../Context/ContextProvider";
import { Box, Checkbox, Menu, Modal, Pagination, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IoClose } from "react-icons/io5";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import dayjs from "dayjs";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

function Enquiry() {
  const { VendorEnquiry, enquiryVendor, buttonLoding ,stateData} = useAuth();
  const [pagination, setPagination] = useState({
    per_page: 10,
    page: 1,
    first_name: "",
    session_type: "",
    email: "",
    contact: "",
    address: "",
    added_date: "",
  });
  console.log("pagination", pagination)

  const handleDateChange = (date) => {
    const adjustedDate = dayjs(date[0]).format("YYYY-MM-DD");
    const adjustedDate1 = dayjs(date[1]).format("YYYY-MM-DD");
    if (adjustedDate !== "Invalid Date" && adjustedDate1 !== "Invalid Date") {
      setPagination({
        ...pagination,
        added_date: adjustedDate + " to " + adjustedDate1,
      });
    } else {
      setPagination({
        ...pagination,
        added_date: adjustedDate,
      });
    }
  };
  useEffect(() => {
    VendorEnquiry(pagination);
  }, []);
  const handlePageChange = (event, value) => {
    setPagination({ ...pagination, page: value });
    VendorEnquiry({ ...pagination, page: value });
    // Perform your data fetching or state update here
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [enqDetail, setEnqDetail] = useState();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="order_page">
        <>
          <div className="new_order_nav">
            <div>
              <h4>
                <FaRegQuestionCircle
                  style={{
                    marginRight: "11px",
                    width: "22px",
                    height: "19px",
                  }}
                />
                Enquiry
              </h4>
            </div>
            <div className="new_order_nav_btn">
              {/* <button className="btn_11">
                  Confrim <TiTickOutline />
                </button>
                <button className="btn_22">
                  Cancel <RxCross2 />
                </button> */}
            </div>
          </div>
          <div className="table_box">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Full Name
                </label>

                <div className="new_order_inps">
                  <input
                    onChange={(e) =>
                      setPagination({
                        ...pagination,
                        first_name: e.target.value,
                      })
                    }
                    value={pagination?.first_name}
                    type="text"
                    name=""
                    id=""
                    placeholder="Full Name"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Email
                </label>
                <div className="new_order_inps">
                  <input
                    onChange={(e) =>
                      setPagination({
                        ...pagination,
                        email: e.target.value,
                      })
                    }
                    value={pagination?.email}
                    type="text"
                    name=""
                    id=""
                    placeholder="Email"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Contact
                </label>
                <div
                  style={{
                    padding: "0",
                    justifyContent: "normal",
                    position: "relative",
                    borderRadius: "10px",
                  }}
                  className="upload_title"
                >
                  <div className="contact_profile">
                    <img
                      style={{ width: "30px", height: "20px" }}
                      src="https://flagcdn.com/w320/gb.png"
                      alt="..."
                    />
                    <span className="mx-2">+44</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      placeholder="e.g 7700 900886"
                      pattern="[0-9]{4} [0-9]{6}"
                      className="inp"
                      onChange={(e) =>
                        setPagination({
                          ...pagination,
                          contact: e.target.value,
                        })
                      }
                      value={pagination?.contact}
                      style={{
                        padding: "1rem",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      maxLength={11}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Location
                </label>
                <div className="new_order_inps">
                  <input
                    onChange={(e) =>
                      setPagination({
                        ...pagination,
                        address: e.target.value,
                      })
                    }
                    value={pagination?.address}
                    type="text"
                    name=""
                    id=""
                    placeholder="Address"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Photo Session Type
                </label>
                <div className="new_order_inps">
                  <input
                    onChange={(e) =>
                      setPagination({
                        ...pagination,
                        session_type: e.target.value,
                      })
                    }
                    value={pagination?.session_type}
                    type="text"
                    name=""
                    id=""
                    placeholder=" Photo Session Type"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Photo Session Date
                </label>
                <div className="new_order_inps">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder=" Photo Session Date"
                    value={pagination?.added_date}
                  />
                  <span onClick={handleClick}>
                    <FaCalendarAlt />
                  </span>
                </div>
              </div>
            </div>
            <Menu
              id="basic-menu"
              a
              anchorEl={anchorEl}
              style={{ marginLeft: "-30px", marginTop: "2rem" }}
              open={open1}
              onClose={handleClose1}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <div style={{ padding: ".5rem" }}>
                <div className="row">
                  <div className="col-12">
                    <div className="row" style={{ margin: "1rem" }}>
                      <div
                        style={{
                          background: "#494949",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          borderRadius: "5px",
                        }}
                        className="col-6 py-2"
                      >
                        <span>From</span>
                      </div>
                      <div
                        style={{
                          background: "#F0F0F0",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="col-6 py-2"
                      >
                        <span>To</span>
                      </div>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DateRangeCalendar", "DateRangeCalendar"]}
                      >
                        <DemoItem>
                          <DateRangeCalendar
                            onChange={handleDateChange}
                            calendars={1}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </Menu>
            <div className="d-flex justify-content-between">
              <div>
                <label>Show entries:</label>
                <select
                  value={pagination?.entry}
                  style={{
                    width: "200px",
                    border: "1px solid lightgray",
                    padding: ".5rem",
                    borderRadius: "5px",
                    outline: "none",
                  }}
                  onChange={(e) => {
                    if (enquiryVendor?.meta?.total < e.target.value) {
                      setPagination({
                        ...pagination,
                        per_page: e.target.value,
                        page: 1,
                      });
                      VendorEnquiry({
                        ...pagination,
                        per_page: e.target.value,
                        page: 1,
                      });
                    } else {
                      setPagination({ ...pagination, entry: e.target.value });
                      VendorEnquiry({
                        ...pagination,
                        per_page: e.target.value,
                      });
                    }
                  }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                </select>
              </div>
              <div>
                <button
                  onClick={() => {
                    setPagination({
                      per_page: 10,
                      page: 1,
                      first_name: "",
                      order_number: "",
                      email: "",
                      contact: "",
                      address: "",
                      added_date: "",
                      session_type: ""
                    });


                    VendorEnquiry({
                      per_page: 10,
                      page: 1,
                      first_name: "",
                      order_number: "",
                      email: "",
                      contact: "",
                      address: "",
                      added_date: "",
                      session_type: ""
                    });
                  }}
                  style={{
                    padding: ".5rem 1rem",
                    background: "#494949",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    marginRight: "1rem",
                  }}
                >
                  Clear
                </button>
                <button
                  style={{
                    padding: ".5rem 1rem",
                    background: "#494949",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                  }}
                  disabled={
                    !(
                      pagination?.first_name ||
                      pagination?.order_number ||
                      pagination?.email ||
                      pagination?.contact ||
                      pagination?.address ||
                      pagination?.added_date ||
                      pagination?.session_type
                    )

                  }
                  onClick={() => {
                    if (
                      pagination?.first_name ||
                      pagination.order_number ||
                      pagination?.email ||
                      pagination?.contact ||

                      pagination?.address ||
                      pagination?.added_date ||
                      pagination?.session_type
                    ) {
                      VendorEnquiry(pagination);
                    }
                  }}
                >
                  Search
                </button>
              </div>
            </div>
            {/* <div class="table-responsive">
  <table className="table">
    ...
  </table>
</div> */}
            <div className="table-responsive mt-4">
              <table className="table">
                <thead>
                  <tr >
                    <th scope="col" style={{ padding: "1rem 0" }}>
                      {" "}
                      {/* <BpCheckbox checked={isMainChecked}
                        onChange={handleMainCheckboxChange}/> */}
                    </th>
                    <th scope="col" style={{ padding: "1rem 0" }} className="oder_th">
                      Full Name
                    </th>
                    <th scope="col" style={{ padding: "1rem 0" }} className="oder_th">
                      Email
                    </th>
                    <th scope="col" style={{ padding: "1rem 0" }} className="oder_th">
                      Contact No.
                    </th>
                    <th scope="col" style={{ padding: "1rem 0" }} className="oder_th">
                      Start Date/Time
                    </th>
            
                    <th scope="col" style={{ padding: "1rem 0" }} className="oder_th">
                      Photo Session Type
                    </th>

                    <th scope="col" style={{ padding: "1rem 0" }} className="oder_th text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {buttonLoding ? (
                    <tr>
                      <td colSpan={10}>
                        <div style={{ height: "400px", width: "100%" }}>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {enquiryVendor?.data?.length? enquiryVendor?.data?.map((item, i) => {
                        function convertTo12HourFormat(time24) {
                          const [hours24, minutes24] = time24?.split(':').map(Number);
                          let hours12 = hours24 % 12 || 12; // Convert 0 to 12
                          const ampm = hours24 < 12 ? 'AM' : 'PM';
                          return `${hours12?.toString().padStart(2, '0')}:${minutes24?.toString().padStart(2, '0')} ${ampm}`;
                        }
                        const options = { year: 'numeric', month: 'short', day: 'numeric' };

                        return (
                          <>
                            <tr>
                              <th scope="row">
                                <BpCheckbox />
                                {/* <MdOutlineCheckBoxOutlineBlank /> */}
                              </th>
                              <td style={{ whiteSpace: "nowrap" }}>{item?.name}</td>
                              <td style={{ whiteSpace: "nowrap" }}>{item?.email}</td>
                              <td style={{ whiteSpace: "nowrap" }}>+{item?.phone_number}</td>
                              <td style={{ whiteSpace: "nowrap" }} className="text-center">
                                {item?.photoshoot_start_date &&
                                  item?.photoshoot_start_time
                                  ? `${new Date(item?.photoshoot_start_date).toLocaleDateString('en-US', options)} / ${convertTo12HourFormat(item?.photoshoot_start_time)}`
                                  : "-"}
                              </td>
                        
                              <td style={{ whiteSpace: "nowrap", textTransform: "capitalize" }}>{item?.service_timeline ? item?.service_timeline : "-"} </td>

                              <td style={{ whiteSpace: "nowrap" }}>
                                <ul className="New_order_icons">
                                  <li
                                    onClick={() => {
                                      handleOpen();
                                      setEnqDetail(item);
                                    }}
                                  >
                                    Details
                                    <FaRegEye className="ms-2" />
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          </>
                        );
                      }):
                      <tr>
                        <td colSpan={7}>
                          <p className="my-5">Enquiry Not Found!</p>
                        </td>
                      </tr>
                      }
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="paginations d-flex justify-content-end my-3">
              <Stack spacing={2}>
                <Pagination
                  count={enquiryVendor?.meta?.last_page}
                  onChange={handlePageChange}
                  shape="rounded"
                />
              </Stack>
            </div>
          </div>
        </>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="order_popup ">
            <div className="row m-0">
              <div className="col p-0">
                <div className="heading1">
                  <h1>Detail Page</h1>
                  <div onClick={handleClose} className="closeIcon">
                    <IoClose />
                  </div>
                </div>
                <hr style={{ margin: 0, borderColor: "#00000078" }} />

                <div className="venEnquiry row upload p-2 enquiry_detail_page">
               {enqDetail?.plan&& <div className="col-12 my-2">
                <div>
                  <div style={{background:"#EDEDED"}}  className="product_content mb-3 p-2 rounded">
                    <div className="product_content_heading d-flex">
                      <p style={{padding:"0",margin:"0"}}>{enqDetail?.plan?.plan_template?.plan_name} </p>
                      <p style={{ fontWeight: "600",padding:"0",margin:"0 1rem" }}> £ {enqDetail?.plan?.price_set}</p>
                    </div>
                    <div className="product_content_hour">
                      <div className="product_content_hour_inner">
                        {enqDetail?.plan?.offers?.map((offer, index) => (
                          offer !== null && (
                            <p key={index} style={{fontSize:"13px",paddingRight:"10px"}} className="m-0 mb-2">
                              <span><FaCheckCircle className="i" style={{fontSize:"20px",marginRight:"0px"}} /></span> {offer}
                            </p>
                          )
                        ))}
                      </div>
                    </div>
                  </div>
                      <hr style={{ margin: "0 0 10px 0" }} />
                </div>
              </div>}
                  <>
                    {/* ==============Name================== */}
                    <div className="col-lg-4 col-md-6 col-12">
                      <label htmlFor="" className="new_order_label mt-1">
                        Full Name
                      </label>
                      <div className="upload_title mt-1">
                        <input type="text" value={enqDetail?.name} readOnly />
                      </div>
                    </div>
                    {/* ==============Email================== */}
                    <div className="col-lg-4 col-md-6 col-12">
                      <label htmlFor="" className="new_order_label mt-1">
                        Email
                      </label>
                      <div className="upload_title mt-1">
                        <input type="text" value={enqDetail?.email} readOnly />
                      </div>
                    </div>
                    {/* ==============Phone numver================== */}
                    <div className="col-lg-4 col-md-6 col-12">
                      <label htmlFor="" className="new_order_label mt-1">
                        Contact
                      </label>
                      <div className="upload_title mt-1">
                        <input
                          type="text"
                          value={enqDetail?.phone_number}
                          readOnly
                        />
                      </div>
                    </div>
                    {/* ==============Location================== */}
                
                    {/* ==============Date================== */}
                    <div className="col-lg-4 col-md-6 col-12">
                      <label htmlFor="" className="new_order_label mt-1">
                        Start Session Date/Time
                      </label>
                      <div className="upload_title mt-1">
                        <input
                          type="text"
                          value={
                            enqDetail?.photoshoot_start_date &&
                              enqDetail?.photoshoot_start_time
                              ? `${enqDetail?.photoshoot_start_date} / ${enqDetail?.photoshoot_start_time}`
                              : "-"
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    {/* ==============Date================== */}
                    <div className="col-lg-4 col-md-6 col-12">
                      <label htmlFor="" className="new_order_label mt-1">
                        End Session Date/Time
                      </label>
                      <div className="upload_title mt-1">
                        <input
                          type="text"
                          value={
                            enqDetail?.photoshoot_end_date &&
                              enqDetail?.photoshoot_end_time
                              ? `${enqDetail?.photoshoot_end_date} / ${enqDetail?.photoshoot_end_time}`
                              : "-"
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <label htmlFor="" className="new_order_label mt-1">
                        Service Time Line
                      </label>
                      <div className="upload_title mt-1">
                        <input
                          type="text"
                          value={
                            enqDetail?.service_timeline
                              ? enqDetail?.service_timeline
                              : "-"
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    {/* ==============Servicea name================== */}
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="" className="new_order_label mt-1">
                        Service Name
                      </label>
                      <div className="upload_title mt-1">
                        <input
                          type="text"
                          value={
                            enqDetail?.serviceable_type.includes("Studio")
                              ? `${enqDetail?.serviceable?.name
                                ? enqDetail?.serviceable?.name
                                : "-"
                              }`
                              : `${enqDetail?.serviceable?.sub_service?.name}`
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="" className="new_order_label mt-1">
                        State
                      </label>
                      <div className="upload_title mt-1">
                          <select value={enqDetail?.state_id} disabled={true}>
                              {stateData?.map((item)=>{
                                return(
                                  <option value={item?.id}>{item?.name}</option>
                                )
                              })}
                          </select>
                      </div>
                    </div>
                    {/* ==============Photo seesiontype================== */}
             
                    {enqDetail?.budget&&<div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="" className="new_order_label mt-1">
                       Budget
                      </label>
                      <div className="upload_title mt-1">
                        <input
                          type="text"
                          value={enqDetail?.budget}
                          readOnly
                        />
                      </div>
                    </div>}
                    <div className={`col-${enqDetail?.budget?"6":"12"}`}>
                      <label htmlFor="" className="new_order_label mt-1">
                        Location
                      </label>
                      <div className="upload_title mt-1">
                        <input
                          type="text"
                          value={enqDetail?.location}
                          readOnly
                        />
                      </div>
                    </div>
                    {/* ==============session detail================== */}
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="" className="new_order_label mt-1">
                        Session Detail
                      </label>
                      <div className="upload_title mt-1">
                        <textarea
                          className="p-0"
                          style={{
                            resize: "none",
                            width: "100%",
                            borderColor: "transparent",
                            outline: "none",
                            minHeight:"150px"
                          }}
                          value={
                            enqDetail?.session_detail !== null
                              ? enqDetail?.session_detail
                              : " Session Deatil not found"
                          }
                          name=""
                          id=""
                          row={4}
                          placeholder="Write Here"
                        ></textarea>
                      </div>
                    </div>
                    {/* ==============expected photoshoot================== */}
                    <div className="col-lg-6 col-md-6 col-12">
                      <label htmlFor="" className="new_order_label mt-1">
                        Expected Photoshoot
                      </label>
                      <div className="upload_title mt-1">
                        <textarea
                          className="p-0"
                          style={{
                            resize: "none",
                            width: "100%",
                            borderColor: "transparent",
                            outline: "none",
                            minHeight:"150px"
                          }}
                          value={
                            enqDetail?.expected_photoshoot !== null
                              ? enqDetail?.expected_photoshoot
                              : "Expected Photoshoot Deatil not found"
                          }
                          name=""
                          id=""
                          row={4}
                          placeholder="Write Here"
                        ></textarea>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Enquiry;
