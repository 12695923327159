import { Box, colors, Modal } from "@mui/material";
import React, { useState } from "react";
import { FaCheck, FaCircleMinus, FaCirclePlus } from "react-icons/fa6";
import Celender from "../VenderProfie/Celender";
import { useAuth } from "../../Context/ContextProvider";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { FiMinus, FiPlus } from "react-icons/fi";
import EnquerySec from "./EnquerySec";
import EnquiryModalPlan from "./EnquiryModalPlan";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 850,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};
function StudioPricing({ data, serviceable_type }) {
  // const data={
  //   plans:[
  //     {booking_service_type:"item_quantity",
  //       offers:["offer1","Offer2","Offer2","Offer2","Offer2"],
  //       quantity:2,
  //       plan_template:{plan_name:"Stander"},
  //       price_set:"500"

  //     },
  //     {booking_service_type:"item_quantity",
  //       offers:["offer1","Offer2","Offer2","Offer2","Offer2"],
  //       quantity:2,
  //       plan_template:{plan_name:"Stander"},
  //       price_set:"500"

  //     }
  //   ]
  // }
  const [planData, setPlanData] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [plan, setPlan] = useState({
    plan_template_id: "",
    vendor_service_id: "",
    day_id: "",
    vendor_id: "",
  });
  const [planId, setPlanId] = useState();
  const [quantity, setquantity] = useState(1);
  const { venderAvailiblty, addCartResp, setCartResp, addToCart, web_url } =
    useAuth();
  const generateUUID = () => {
    const newId = uuidv4();
    return newId;
  };
  const nanvigate = useNavigate();
  React.useEffect(() => {
    if (addCartResp === "ok") {
      localStorage.removeItem("cardData");
      nanvigate(`${web_url}order-summry`);
      setCartResp("");
    }
    // eslint-disable-next-line
  }, [addCartResp]);
  const addToCartLocal = (obj) => {
    let id = generateUUID();
    let client = JSON.parse(localStorage.getItem("vendor_data"));

    if (client?.data?.role === "client") {
      addToCart({ ...obj, cart_key: id });
    } else {
      localStorage.setItem(
        "cardData",
        JSON.stringify({ ...obj, cart_key: id })
      );
      nanvigate(`${web_url}clinet-signin`);
    }
  };
  const calculateFontSize = (offersList) => {
    // Filter out null values from the offers list
    const validOffers = offersList?.filter((offer) => offer !== null);
    
    // Find the length of the longest text in the offers list
    const maxLength = Math.max(...validOffers?.map((offer) => offer.length));
    
    // Set the font size based on the length of the longest text
    if (maxLength > 50) return "16px"; // Small font size for very long text
    if (maxLength > 30) return "18px";  // Medium font size for moderately long text
    return "24px";                       // Default font size for shorter text
  };
  return (
    <div id="pricing_section" className="package_section">
      {
        <div className={`row plan_detail`}>
          {data?.plans?.map((item, idx) => { 
            const fontSize=calculateFontSize(item?.offers)
            return (
              <>
                {item?.is_active === 1 ? (
                  <div className="col-lg-4 col-md-6 col-12 pe-3 ps-2 py-2">
                    <div
                      className="package_main"
                      style={{ position: "relative" }}
                    >
                      <h3>{item?.plan_template?.plan_name}</h3>
                      <div
                        style={{
                          width: "30px",
                          border: "1px solid gray",
                          margin: "1rem 0",
                        }}
                      ></div>
                      <h1 className="my-3">£{item?.price_set}</h1>
                      <h4 className="my-3">Offer Includes</h4>
                      <ul>
                        {item?.booking_service_type === "hourly_slots" && (
                          <li style={{fontSize}} className="mb-3">
                            {" "}
                            <FaCheck className="me-2" />
                            {item?.plan_duration} Hour Duration
                          </li>
                        )}
                        {item?.offers?.map((items) => {
                          return (
                            <>
                              {items !== null && (
                                <li style={{fontSize}} className="mb-3 d-flex">
                                  <FaCheck className="me-2" />
                                  <div>{items}</div>
                                </li>
                              )}
                            </>
                          );
                        })}
                      </ul>
                      {item?.booking_service_type === "item_quantity" && (
                        <div
                          style={{
                            background: "#414040",
                            borderRadius: "5px",
                            width: "90%",
                            // Left: "10%",    
                            position: "absolute",
                            bottom: "20px",
                            padding:"0 3rem"
                          }}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div className="d-flex justify-content-between align-items-center p-2 me-3"
                            style={{
                              width: "100px",
                              borderRadius: "5px",
                              color: "white",
                            }}
                          >
                            <FiMinus
                              onClick={() => {
                                if (quantity > 1) {
                                  setquantity(quantity - 1);
                                }
                              }}
                              style={{
                                cursor:
                                  quantity <= 1 ? "not-allowed" : "pointer",
                                fontSize: "15px",
                              }}
                            />
                            {quantity}
                            <FiPlus
                              onClick={() => setquantity(quantity + 1)}
                              style={{ cursor: "pointer", fontSize: "15px" }}
                            />
                          </div>
                          <div style={{ height: "60%", color: "white" }}>|</div>
                          <button
                            onClick={() =>
                              addToCartLocal({
                                plan_id: item?.id,
                                booking_service_type:
                                  item?.booking_service_type,
                                quantity: quantity,
                                cart_key: "",
                                service_date: dayjs().format("YYYY-MM-DD"),
                                vendor_id: item?.vendor_id,
                              })
                            }
                            style={{whiteSpace:"nowrap",background:"none",border:"none",color:"white"}}
                          >
                            {" "}
                            Book Now
                          </button>
                        </div>
                      )}
                      {item?.booking_service_type === "hourly_slots" && (
                        <button
                          style={{
                            width: "90%",
                            position: "absolute",
                            bottom: "20px",
                          }}
                          onClick={() => {
                            handleOpen();
                            setPlanId(item?.id);
                            setPlan({
                              ...plan,
                              plan_availability_id: item?.plan_availability?.id,
                              vendor_id: item?.vendor_id,
                              booking_service_type: item?.booking_service_type,
                              cancellation_policy:item?.plan_availability?.cancellation_policy,
                              states:item?.plan_availability?.states,
                              same_day:item?.plan_availability?.same_day,
                              cancellation_time:item?.plan_availability?.cancellation_time,
                            });
                          }}
                          className="btn_package"
                        >
                          Book Now
                        </button>
                      )}
                      {item?.booking_service_type === "manual_reservation" && (
                        <button
                          style={{
                            width: "90%",
                            position: "absolute",
                            bottom: "20px",
                          }}
                          onClick={() => {
                            handleOpen1();
                            setPlanData(item);
                          }}
                          className="btn_package"
                        >
                          Check Availability
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            );
          })}

          {data?.plans?.filter((plan) => plan.is_active === 1).length > 0 &&
            data?.plans?.filter((plan) => plan.is_active === 1).length <= 2 && (
              <div
                className={`col-lg-${
                  data?.plans?.filter((plan) => plan.is_active === 1).length ===
                  1
                    ? 8
                    : 4
                } col-md-6 col-10 pe-3 ps-2 py-2`}
              >
                <EnquerySec
                  service_id={data?.vendor_sub_service_id}
                  service_name={data?.sub_service?.name}
                  vendor_id={data?.user?.id}
                  serviceable_type={"vendor_sub_service"}
                />
              </div>
            )}

          <EnquiryModalPlan
            service_id={
              serviceable_type === "studio"
                ? data?.studio_id
                : data?.vendor_sub_service_id
            }
            service_name={
              serviceable_type === "studio"
                ? data?.name
                : data?.sub_service?.name
            }
            vendor_id={
              serviceable_type === "studio" ? data?.user?.id : data?.user?.id
            }
            ser_type={
              data?.studio_sub_services ? data?.studio_sub_services : []
            }
            serviceable_type={
              serviceable_type === "studio" ? "studio" : "vendor_sub_service"
            }
            open3={open1}
            handleClose3={handleClose1}
            planData={planData}
          />
        </div>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* vendor_id,plan_id,venderAvailiblty,data,setData */}
        <Box sx={style}>
          <Celender
            handleClose={handleClose}
            setData={setPlan}
            data={plan}
            venderAvailiblty={venderAvailiblty}
            plan_id={planId}
            vendor_id={plan?.vendor_id}
            booking_service_type={plan?.booking_service_type}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default StudioPricing;
