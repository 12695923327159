import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import img from '../../../asset/explorTelent/Vector.svg'
import { AiFillCloseCircle } from 'react-icons/ai';
import { useAuth } from '../../../Context/ContextProvider';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
};
function CancelModal({ cancelAble,cancelMessage, cancelled_data,handleClose, open, orderIds, getOrder, pagination }) {
  const { changeOrderStatus } = useAuth()
  const [reason, setReason] = useState()
  const [openSecModal,setSecModal]=useState(false)
  const handleOpen1=()=>{
    setSecModal(true)
  }
  const handleClose1=()=>{
    setSecModal(false)
  }
  const [fieldErrors, setFieldErrors] = useState({});

  const validateStep = () => {
    let errors = {};
    if (!reason) {
      errors.reason = "Reason is required.";
    }
    setFieldErrors(errors);
    
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  
  const handleSendRequest = () => {
    if (validateStep()) {
      let obj = {
        id: orderIds?.order_id,
        id_service: orderIds?.order_service_id,
        reason: reason,
        order_status_code: "PE",
        cancelled_data: cancelled_data
      };
      console.log(obj);
      
      changeOrderStatus(obj, pagination);
      handleClose1();
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
           <div className='cancel_order_Model'>
            <div className='d-flex justify-content-between'>
              <h5>Order Cancel</h5>
              <button onClick={handleClose} className='close_btn'><AiFillCloseCircle style={{ fontSize: "2rem" }} /></button>
            </div>
            <hr />
            <div className='model_content'>
              <img src={img} alt='...' />
              <h4 className='text-center'>You are {cancelAble?"":"not"} be<br />able to cancel the order  !</h4>
              <p>{cancelMessage}</p>
              {cancelAble&&<span>Are You Sure you want to cancel You Service</span>}
              
            </div>
            <hr/>
            <div className={`model_button mt-3 d-flex justify-content-${cancelAble?"between":"end"}`}>
              {cancelAble&&<button onClick={handleOpen1} className='no_btn'>Confim</button>}
              <button onClick={handleClose} className='no_btn'>Cancel</button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openSecModal}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='cancel_order_Model upload'>
            <div className='d-flex justify-content-between align-items-center'>
              <h5 className='reason_cancel'>Reason for cancel order</h5>
              <button onClick={handleClose1} className='close_btn'><AiFillCloseCircle style={{ fontSize: "2rem" }} /></button>
            </div>
            <hr />
            <div className='' style={{ position: "relative" }}>
              <label htmlFor="" className="new_order_label">
                Why you want to cancel the order
              </label>
              <div className="upload_title mt-1" >
                <textarea onChange={(e) => setReason(e.target.value)} style={{ width: "100%", resize: "none", outline: "none", border: "none" }} name="" id="" rows={5} placeholder='Reason'></textarea>
              </div>
              {fieldErrors?.reason && (
                <span
                  className="error_text"
                  style={{
                    fontSize: "13px",
                    top: "100%",
                    position: "absolute",
                  }}
                >
                  {fieldErrors?.reason}
                </span>
              )}
            </div>
            <div className='model_button mt-4 d-flex justify-content-between'>
              <button onClick={handleSendRequest} className='yes_btn'>Send Request</button>
              <button onClick={handleClose} className='no_btn'>Cancel</button>
            </div>
          </div> 
        </Box>
      </Modal>
    </div>
  )
}

export default CancelModal
