import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Services_sett_Quote({ data, setData }) {
  const [value, setValue] = useState(data?.quote || '');

  useEffect(() => {
    if (data?.quote !== value) {
      setValue(data?.quote || '');
    }
  }, [data?.quote]);

  useEffect(() => {
    if (data?.quote !== value) {
      setData(prevData => {
        if (prevData.quote !== value) {
          return { ...prevData, quote: value };
        }
        return prevData;
      });
    }
  }, [value, setData]);

  return (
    <div style={{ background: "#ECECEB", height:"320px", borderRadius:"4px" }} className='Service_setting_summer'>
      <ReactQuill
        style={{ width: "920px", height: "270px" }}
        value={value}
        onChange={setValue}
      />
    </div>
  );
}

export default Services_sett_Quote;
