import React, { useEffect, useState } from "react";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import { CiSearch, CiStar } from "react-icons/ci";
import { FaRegEye, FaStar } from "react-icons/fa";
import { CgSortAz } from "react-icons/cg";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import NewOrderEdit from "./NewOrderEdit";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useAuth } from "../../Context/ContextProvider";
import { Box, Modal } from "@mui/material";
import { IoCalendarNumberOutline, IoClose } from "react-icons/io5";
import completeOrder from "../../asset/icons/completeOrder.png";
import dayjs from "dayjs";
import RevisionRequest from "./Order/RevisionRequest";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
};
function CompleteOrder() {
  const [show, setshow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [selectedOrderService, setSelectedOrderService] = useState(null); // To track selected order/service

  const handleMenuClick = (event, order, service) => {
    setAnchorElMenu(event.currentTarget); // Attach the clicked anchor element
    setSelectedOrderService({ order, service }); // Track which order and service is selected
  };

  const handleMenuClose = () => {
    setAnchorElMenu(null);
    setSelectedOrderService(null); // Reset when closing the menu
  };


  const menuOpen = Boolean(anchorElMenu);
  const [orderIds, setOrderIds] = useState({
    order_id: "",
    order_service_id: ""
  })
  const { getOrder, orderData, buttonLoding } = useAuth();
  const [show1, setshow1] = useState({
    revision_req: false,
  });
  const [pagination, setPagination] = useState({
    entry: 10,
    page: 1,
    id: "completed",
    first_name: "",
    order_number: "",
    total: "",
    order_status: "",
    added_date: "",
    update_date: ""
  })
  useEffect(() => {
    getOrder(pagination)
    // eslint-disable-next-line
  }, [])
  const handleDateChange = (date) => {
    const adjustedDate = dayjs(date[0]).format('YYYY-MM-DD');
    const adjustedDate1 = dayjs(date[1]).format('YYYY-MM-DD');
    setPagination({ ...pagination, added_date: adjustedDate + "to" + adjustedDate1 })
    getOrder({ ...pagination, added_date: adjustedDate + "to" + adjustedDate1 })
  }
  const handlePageChange = (event, value) => {
    setPagination({ ...pagination, page: value });
    getOrder({ ...pagination, page: value }); // Use the new page number provided by MUI
    // Perform your data fetching or state update here
  };
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [plus, setPlus] = useState([]);
  const toggleSubtable = (idx) => {
    setPlus((item) => {
      const isVisible = item.includes(idx);
      if (isVisible) {
        return item.filter((i) => i !== idx);
      } else {
        return [...item, idx];
      }
    });
  };
  const [data, setData] = useState({});
  const getStatusClass = (status) => {
    let reStatus = determineOrderStatus(status)?.classname;
    switch (status?.orderService ? reStatus : status) {
      case "6D":
        return "status-completed";
      case "Partially Completed":
        return "status-completed";
      default:
        return "";
    }
  };

  function determineOrderStatus(order) {
    if (!order || !order.orderService || !Array.isArray(order.orderService)) {
      return "Invalid order";
    }



    const serviceStatuses = order?.orderService?.map(
      (service) => service.orderStatus.status_code
    );
    const serviceStatuses1 = order?.orderService?.map(
      (service) => service.orderStatus.status_name
    );
    const uniqueStatuses = new Set(serviceStatuses);

    if (uniqueStatuses.size === 1) {
      return {
        show: serviceStatuses1[0],
        classname: serviceStatuses[0]
      };
    }

    if (serviceStatuses.includes("Completed")) {
      return "Partially Completed";
    }

    if (serviceStatuses.includes("Client Reviewing")) {
      return "Partially Reviewing";
    }

    return "Multiple Different Statuses";
  }
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  // const [show, setshow] = useState(false);
  const [rating, setRating] = useState();
  const AreaDisplay = ({ area }) => {
    // Convert area to a string and truncate it to the first 4 characters, then add "..."
    const areaString = area?.toString() || "";
    const truncatedArea = areaString.length > 12 ? areaString.substring(0, 12) + "..." : areaString;

    return (
      <div>
        <span title={area}>{truncatedArea} </span>
      </div>
    );
  };
  return (
    <div>
      {!show1?.revision_req && <div className="table_box" style={{ borderRadius: "0px" }}>
        <h3><img
          src={completeOrder}
          alt=""
          style={{ marginRight: "11px", width: "22px", height: "22px" }}
        />Completed Order</h3>

        <div className="filter_main">
          <div className="d-flex justify-content-between">
            {/* =========================Search and date============ */}
            <div className="d-flex align-items-center">
              <label>
                <CiSearch className="icons" />
                <input onChange={(e) => {
                  setTimeout(() => {
                    getOrder({ ...pagination, first_name: e.target.value })
                  }, 2000);
                }} type="search" placeholder="Search" />
              </label>
              <span onClick={handleClick}>
                Date <IoCalendarNumberOutline className="icons" />
              </span>
              {/* <span onClick={handleClick1}>
                Filter <VscSettings className="icons" />
              </span> */}
            </div>
            {/* ==============Show Entity=============== */}
            <div className="d-flex align-items-center">
              <div>Show Entries:</div>
              <select
                value={pagination?.entry}
                style={{
                  width: "200px",
                  border: "1px solid lightgray",
                  padding: ".5rem",
                  borderRadius: "5px",
                  outline: "none",
                }}
                onChange={(e) => {
                  if (orderData?.meta?.total < e.target.value) {
                    setPagination({
                      ...pagination,
                      entry: e.target.value,
                      page: 1,
                    });
                    getOrder({ ...pagination, entry: e.target.value, page: 1 });
                  } else {
                    setPagination({ ...pagination, entry: e.target.value });
                    getOrder({ ...pagination, entry: e.target.value });
                  }
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
              </select>
            </div>
            {/* ================= show entity end================ */}
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            style={{ marginLeft: "-180px", marginTop: ".2rem" }}
            open={open1}
            onClose={handleClose1}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div style={{ height: "450px", padding: ".5rem" }}>
              <div className="row">
                <div className="col-12">
                  <div className="row" style={{ margin: "1rem" }}>
                    <div
                      style={{
                        background: "#494949",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        borderRadius: "5px",
                      }}
                      className="col-6 py-2"
                    >
                      <span>From</span>
                    </div>
                    <div
                      style={{
                        background: "#F0F0F0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="col-6 py-2"
                    >
                      <span>To</span>
                    </div>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      components={["DateRangeCalendar", "DateRangeCalendar"]}
                    >
                      <DemoItem>
                        <DateRangeCalendar onChange={handleDateChange} calendars={1} />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </Menu>
        </div>
        <div
          className="confirm_order_table table-responsive"
          style={{ maxHeight: "68%", height: "68%" }}
        >
          <table className="table table-sm nestedTable1  custom-padding my-3 ">
            <thead>
              <tr style={{ position: "sticky", top: "0", width: "100%" }}>
                <th scope="col" style={{ width: "3%" }}>
                  {" "}
                </th>
                <th scope="col" className="oder_th">
                  Order ID <CgSortAz />
                </th>
                <th scope="col" className="oder_th px-0 text-center">
                  Order Date <CgSortAz />
                </th>
                <th scope="col" className="oder_th text-center">
                  Vendor Name
                </th>

                <th scope="col" className="oder_th px-0">
                  Order Amount
                </th>
                <th scope="col" className="oder_th text-center" >
                  Order Status
                </th>
                <th scope="col" className="oder_th text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {
                orderData?.data?.length ?
                  orderData?.data?.map((order, i) => (
                    <>
                      {order?.orderService?.length === 1 ?
                        order?.orderService?.map((service) => (
                          <tr key={service.id}>
                            <td colSpan={9} className="p-0">
                              <table className="table nestedTable table-sm m-0">
                                <tbody>
                                  <tr>
                                    <td>{order?.order_number}</td>
                                    <td>{order?.order_confirmed_date?.split(" ")[0]}</td>
                                    <td>{service?.vendor?.name}</td>
                                    <td>£{service?.total}</td>
                                    <td style={{ textAlign: "center" }}>
                                      <span className={getStatusClass(service?.orderStatus?.status_code)}>
                                        {service?.orderStatus?.status_name}
                                      </span>
                                    </td>
                                    <td>
                                      <IconButton
                                        aria-controls="order-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => handleMenuClick(e, order, service)}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        id={`order-menu-${order.id}-${service.id}`} // Unique ID per menu
                                        anchorEl={anchorElMenu}
                                        keepMounted
                                        open={menuOpen && selectedOrderService?.order?.id === order.id && selectedOrderService?.service?.id === service.id}
                                        onClose={handleMenuClose}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            setData(order);
                                            setshow(true);
                                            setRating(service?.orderReviews);
                                            handleMenuClose();
                                          }}
                                        >
                                          View <FaRegEye className="ms-1" />
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            if (service?.data_required === 1) {
                                              setshow1({ revision_req: true });
                                              setOrderIds({
                                                order_id: order?.id,
                                                order_service_id: service?.id,
                                              });
                                            }
                                            handleMenuClose();
                                          }}
                                          disabled={service?.data_required !== 1}
                                        >
                                          View Received Data <FaRegEye className="ms-1" />
                                        </MenuItem>
                                      </Menu>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        ))
                        :
                        <>
                          <tr key={order.id}>
                            <th scope="row" style={{ width: '0%' }}>
                              {" "}
                              {plus.includes(i) ? (
                                <FaSquareMinus
                                  onClick={() => toggleSubtable(i)}
                                />
                              ) : (
                                <FaSquarePlus onClick={() => toggleSubtable(i)} />
                              )}
                            </th>

                            <td>{order?.order_number}</td>
                            <td>{order?.order_confirmed_date?.split(" ")[0]}</td>
                            <td>{order?.orderService?.[0]?.vendor?.name || '-'}</td>
                            <td>£{order?.total}</td>
                            <td style={{ textAlign: "center" }}>
                              <span className={getStatusClass(order)}>
                                {determineOrderStatus(order)?.show}
                              </span>
                            </td>
                            <td>
                              <IconButton
                                aria-controls="order-menu"
                                aria-haspopup="true"
                                onClick={(e) => handleMenuClick(e, order, order?.orderService?.[0])}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id={`order-menu-${order.id}`}
                                anchorEl={anchorElMenu}
                                keepMounted
                                open={menuOpen && selectedOrderService?.order?.id === order.id}
                                onClose={handleMenuClose}
                              >
                                <MenuItem
                                  onClick={() => {
                                    setData(order);
                                    setshow(true);
                                    handleMenuClose();
                                  }}
                                >
                                  View <FaRegEye className="ms-1" />
                                </MenuItem>
                                <MenuItem
                                  disabled={true}  // Modify this as per your condition
                                  onClick={handleMenuClose}
                                >
                                  View Received Data <FaRegEye className="ms-1" />
                                </MenuItem>
                              </Menu>
                            </td>
                          </tr>
                          {plus.includes(i) &&
                            order?.orderService?.map((service) => (
                              <tr key={service.id}>
                                <td colSpan={9} className="p-0">
                                  <table className="table nestedTable table-sm m-0">
                                    <tbody>
                                      <tr>
                                        <td>{order?.order_number}</td>
                                        <td>{order?.order_confirmed_date?.split(" ")[0]}</td>
                                        <td>{service?.vendor?.name}</td>
                                        <td>£{service?.total}</td>
                                        <td style={{ textAlign: "center" }}>
                                          <span className={getStatusClass(service?.orderStatus?.status_code)}>
                                            {service?.orderStatus?.status_name}
                                          </span>
                                        </td>
                                        <td>
                                          <IconButton
                                            aria-controls="order-menu"
                                            aria-haspopup="true"
                                            onClick={(e) => handleMenuClick(e, order, service)}
                                          >
                                            <MoreVertIcon />
                                          </IconButton>
                                          <Menu
                                            id={`order-menu-${order.id}-${service.id}`} // Unique ID per menu
                                            anchorEl={anchorElMenu}
                                            keepMounted
                                            open={menuOpen && selectedOrderService?.order?.id === order.id && selectedOrderService?.service?.id === service.id}
                                            onClose={handleMenuClose}
                                          >
                                            <MenuItem
                                              onClick={() => {
                                                setData(order);
                                                setshow(true);
                                                setRating(service?.orderReviews);
                                                handleMenuClose();
                                              }}
                                            >
                                              View <FaRegEye className="ms-1" />
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() => {
                                                if (service?.data_required === 1) {
                                                  setshow1({ revision_req: true });
                                                  setOrderIds({
                                                    order_id: order?.id,
                                                    order_service_id: service?.id,
                                                  });
                                                }
                                                handleMenuClose();
                                              }}
                                              disabled={service?.data_required !== 1}
                                            >
                                              View Received Data <FaRegEye className="ms-1" />
                                            </MenuItem>
                                          </Menu>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            ))
                          }
                        </>
                      }
                    </>
                  ))
                  :
                  <tr>
                    <td colSpan={10} style={{ border: "none" }}>
                      <h5 className="text-center">No Order Found!</h5>
                    </td>
                  </tr>
              }
            </tbody>
          </table>
        </div>

        {/* ================pagination=================== */}
        <div className="paginations d-flex justify-content-end my-3">
          <Stack spacing={2}>
            <Pagination
              count={orderData?.meta?.last_page}
              onChange={handlePageChange}
              shape="rounded"
            />
          </Stack>
        </div>

        {/* =========================pagination end================== */}
      </div>}
      {show1?.revision_req && <RevisionRequest show={show1} setShow={setshow1} orderServiceId={orderIds?.order_service_id} orderId={orderIds?.order_id} />}
      <NewOrderEdit
        data={data}
        setData={setData}
        show={show}
        setshow={setshow}
        rating={rating}
        setRating={setRating}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="order_popup">
            <div className="row">
              <div className="col p-0">
                <div className="heading1">
                  <h1>Order Review</h1>
                  <div onClick={handleClose} className="closeIcon">
                    <IoClose />
                  </div>
                </div>
                <hr style={{ margin: 0, borderColor: "#00000078" }} />
                <div className="main_content">
                  <p className="para">Rating from client *</p>
                  <div className="star">
                    {Array.from({ length: 5 }, (_, index) => (
                      <div key={index} style={{ cursor: "pointer" }}>
                        {index < rating?.rating ? (
                          <FaStar style={{ fontSize: "30px" }} /> // Render filled star if rating is greater than index
                        ) : (
                          <CiStar style={{ fontSize: "30px" }} />
                        )}
                      </div>
                    ))}
                    <div>{rating?.rating}/5</div>
                  </div>
                  <div className="text my-2">
                    <label htmlFor="textAra">Client Comment</label>
                    <p className="p-0 m-0">{rating?.client_comment}</p>
                    <hr />
                  </div>
                  {/* <div className="text_para">
                                    <p>By Clicking on agree, you are asking us to complete the task of vendor<br />
                                      (Vendor Name) ordered from you after we are unable to undo this action.</p>
                                    <input type="checkbox" id="check" />
                                    <label htmlFor="check">Complete</label>
                                  </div> */}
                  <div className="ft_btn">
                    {/* <button className="btn btn-secondary" onClick={() => changeOrderStatus({ ...rating, id_service: orderServiceId, id: orderId })} style={{ boxShadow: '8px 8px 24px 0 rgba(2, 2, 70, 0.15)' }}> Done</button> */}
                    <button
                      className="btn btn-light"
                      style={{ color: "#637381" }}
                    >
                      {" "}
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default CompleteOrder;
