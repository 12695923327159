import { Box, FormControl, FormControlLabel, FormLabel, Menu, Modal, Radio, RadioGroup } from '@mui/material';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { FaCalendarAlt } from 'react-icons/fa';
import { useAuth } from '../../Context/ContextProvider';
import { RxCross2 } from 'react-icons/rx';

function EnquiryModalHome({open3 ,handleClose3}) {
        const { Enquiry, buttonLoding,adminservice,adminStudio,social_linkData,stateData} = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleDateChange = (newDate) => {
    const formattedDate = dayjs(newDate).format("YYYY-MM-DD");
    setdata({ ...data, photoshoot_start_date: formattedDate });
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleDateChange2 = (newDate) => {
    const formattedDate = dayjs(newDate).format("YYYY-MM-DD");
    setdata({ ...data, photoshoot_end_date: formattedDate });
  };
  const [data, setdata] = useState({
    name: "",
    service_timeline: "urgent",
    email: "",
    phone_number: "",
    serviceable_type: "vendor_sub_service",
    photoshoot_start_date: "",
    photoshoot_end_date: "",
    photoshoot_start_time: "",
    photoshoot_end_time: "",
    budget:"",
    location: "",
    session_detail: "",
    serviceable_id: "",
    vendor_id: social_linkData[0]?.vendor?.id,
    expected_photoshoot: "",
    photoshoot_session_type: "",
  });
  const [service_name, setServiceName]=useState("")
const [studio_sub_services,setSubservice]=useState([])
  useEffect(() => {
    if (service_name) {
      setdata((prevData) => ({
        ...prevData,
        photoshoot_session_type: service_name,
      }));
    }
  }, [service_name]);
  const [fieldErrors, setFieldErrors] = useState({});
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidUKPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber.replace(/\D/g, "");
    const ukPhoneRegex = /^(?:0|\+44)?\s?(?:\d\s?){9,10}$/;
    // Check if the phone number is a valid UK phone number or its length is 11 or 13 characters
    return (
      ukPhoneRegex.test(phone) && (phone.length === 10 || phone.length === 13)
    );
  };
  const validateStep = () => {
    let errors = {};

    if (!data.name) {
      errors.name = "First Name is required.";
    }

    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email location.";
    }
    if (!data.phone_number) {
      errors.phone_number = "Contact is required.";
    } else if (!isValidUKPhoneNumber(data.phone_number)) {
      errors.phone_number = "Please enter a valid UK phone number.";
    }

    if (!data.service_timeline) {
      errors.service_timeline = "Services Timeline is required.";
    }
    if (!data.photoshoot_start_date || !data.photoshoot_start_time) {
      errors.photoshoot_date = "Start Date and Time are required.";
    }
    if (!data.state_id) {
      errors.state_id = "State is required.";
    }


   
    if (!data.location) {
      errors.location = "Address is required.";
    }
    if (!data.photoshoot_session_type) {
      errors.photoshoot_session_type = "Services is required.";
    }
    if (!data.session_detail) {
      errors.session_detail = "Message is required.";
    }


    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateStep()) {
   let result = await Enquiry({
        ...data,
        phone_number: "44" + data?.phone_number?.replace(/\D/g, ""),
      });
      if(result?.succes){
        handleClose3()
      }
      // Proceed with the submission
    }
  };
  const handleChange = (e) => {
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/\D/g, "");
    // Format the phone number as "7700 900886"
    const firstPart = value.slice(0, 4);
    const secondPart = value.slice(4);
    let formattedValue = `${firstPart}`;
    if (secondPart.length > 0) {
      formattedValue += ` ${secondPart}`;
    }
    // Update the state with the formatted value
    setdata({ ...data, phone_number: formattedValue });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
  
    borderRadius: "5px",
  };

  useEffect(()=>{
    setdata({
      name: "",
      service_timeline: "urgent",
      email: "",
      phone_number: "",
      serviceable_type: "vendor_sub_service",
      photoshoot_start_date: "",
      photoshoot_end_date: "",
      photoshoot_start_time: "",
      photoshoot_end_time: "",
      budget:"",
      location: "",
      session_detail: "",
      serviceable_id: "",
      vendor_id: social_linkData[0]?.vendor?.id,
      expected_photoshoot: "",
      photoshoot_session_type: "",
    })
   },[handleClose3])
  

  return (
    <Modal
    open={open3}
    onClose={handleClose3}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box className="Enqiry_madal" sx={style}>
      <div className="row m-0">
        <div className="col-12 p-3 pb-1">
          <div className="equiry_heading">
            <h4>
            Enquire Us
            </h4>
            <p>
            <RxCross2  onClick={handleClose3} />
            </p>

          </div>
        </div>
      </div>
      <hr className="m-0"/>
      
    <div className="row  enquiry  ">
        <div className="col-12">

        <div className="pricing_check justify-content-start">
        <div className="priceing_content px-3 pt-1 mb-1 w-100">
        <FormControl className="flex-row align-items-center">
                      <FormLabel id="demo-radio-buttons-group-label">
                        {" "}
                       Choose Type:
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={data?.serviceable_type}
                        name="radio-buttons-group"
                        className="flex-row ms-3"
                        onChange={(e) => setdata({...data, serviceable_type:e.target.value, serviceable_id:"",photoshoot_session_type:""})}
                      >
                        <FormControlLabel
                          value="vendor_sub_service"
                        //   checked={booking_state?.inquiry && booking_state?.plan ? true : false}
                          control={<Radio />}
                          label="Photography Services"
                        />
                        <FormControlLabel
                          value="studio"
                        //   checked={booking_state?.inquiry && !booking_state?.plan ? true : false}
                          control={<Radio />}
                          label="Studio Spaces"
                        />
                        
                      </RadioGroup>
                    </FormControl>
                    </div>
                    </div>
                 
                    <div className="row upload_title mt-1" style={{marginRight:"1px" , marginLeft:"1px"}}>
               <div className="col-12">
                {
                    data?.serviceable_type==="vendor_sub_service"?
                    <select
                    style={{ padding: "3px 0" }}
                    //  value={data.ser_type}
                    onChange={(e) => {
                      setdata({...data, serviceable_id:e.target.value})
                      let selected=e.target.options[e.target.selectedIndex].text
                      setServiceName(selected)

                    }}
                    name=""
                    id=""
                  >
                    <option value="Choose Service" hidden>
                      Choose Service
                    </option>
                    {adminservice?.map((item) => {
                      return item?.service?.sub_services?.map((items,i)=>{
                      return (
                        <option  key={i} value={items?.vendor_sub_service_id}>
                          {items?.name}
                        </option>
                      );
                  })
                    })}
                    {/* <option value="12">21</option>
                    <option value="22">21</option> */}
                  </select>
                  :
                  <select
                  style={{ padding: "3px 0" }}
                  //  value={data.ser_type}
                  onChange={(e) =>{
                    setdata({...data, serviceable_id:e.target.value})
                    adminStudio?.map((item)=>{
                      if(item?.studio_id===parseInt(e.target.value)){
                        setSubservice(item?.studio_sub_services)

                      }
                    })
                  }
                }
                  name=""
                  id=""
                >
                  <option value="choose studio" hidden>
                    Choose Studio
                  </option>
                  {adminStudio?.map((item) => {
                   
                    return (
                      <option value={item?.studio_id}>
                        {item?.name}
                      </option>
                    );
                
                  })}
                  {/* <option value="12">21</option>
                  <option value="22">21</option> */}
                </select>
                }
             
               </div>



              

            </div>
        </div>


      {/* =======================First Name=====================? */}
      <div className="col-lg-4 col-md-6 col-12 my-3"
        style={{ position: "relative" }}
      >
        <label htmlFor="" className="new_order_label">
          Full Name <span className="error_text">*</span>{" "}
        </label>
        <div className="upload_title mt-1">
          <input
            type="text"
            onChange={(e) =>
              setdata({ ...data, name: e.target.value })
            }
            name=""
            id="name"
            placeholder="Full Name"
          />
        </div>
        {fieldErrors?.name && (
          <span
            className="error_text"
            style={{ fontSize: "13px", position: "absolute" }}
          >
            {fieldErrors?.name}
          </span>
        )}
      </div>

      {/* =======================Email=====================? */}
      <div className="col-lg-4 col-md-6 col-12 my-3"
        style={{ position: "relative" }}
      >
        <label htmlFor="" className="new_order_label">
          Email <span className="error_text">*</span>{" "}
        </label>
        <div className="upload_title mt-1">
          <input
            onChange={(e) => setdata({ ...data, email: e.target.value })}
            type="email"
            name=""
            id=""
            placeholder="Email"
          />
        </div>
        {fieldErrors?.email && (
          <span
            className="error_text"
            style={{ fontSize: "13px", position: "absolute" }}
          >
            {fieldErrors?.email}
          </span>
        )}
      </div>

      {/* =======================Contact=====================? */}
      <div className="col-lg-4 col-md-6 col-12 my-3"
        style={{ position: "relative" }}
      >
        <label htmlFor="" className="new_order_label">
          Contact <span className="error_text">*</span>{" "}
        </label>
        <div className="upload_title mt-1  d-flex align-items-center  p-0">
          <div
            className="profile_contacts ps-0"
            style={{ padding: "10px" }}
          >
            <img
              style={{ width: "30px", height: "20px" }}
              src="https://flagcdn.com/w320/gb.png"
              alt="..."
            />
            <span className="mx-2">+44</span>
          </div>
          <input
            type="text"
            value={data?.phone_number}
            onChange={handleChange}
            placeholder="e.g 7700 900886"
            pattern="[0-9]{4} [0-9]{6}"
            className="inp"
            maxLength={11}
          />
        </div>
        {fieldErrors?.phone_number && (
          <span
            className="error_text"
            style={{ fontSize: "13px", position: "absolute" }}
          >
            {fieldErrors?.phone_number}
          </span>
        )}
      </div>

      {/* =======================photophoto_session_type=====================? */}

      {data?.serviceable_type !== "studio" ? (
        <div className="col-lg-4 col-md-6 col-12 my-3" style={{position:"relative"}}>
          <label htmlFor="" className="new_order_label">
            Choose Services{" "}
          </label>
          <div className="upload_title mt-1">
            <input
              value={service_name}
              readOnly
              type="text"
              name=""
              id=""
              placeholder="Photoshoot Session"
            />

           
          </div>
       
        </div>
      ) : (
        <div className="col-lg-4 col-md-6 col-12 my-3" style={{position:"relative"}}>
          <label htmlFor="" className="new_order_label">
            Session Type{" "}
          </label>
          <div className="upload_title mt-1">
            <select
              style={{ padding: "3px 0" }}
              onChange={(e) =>
                setdata({ ...data, photoshoot_session_type: e.target.value })
              }
              name=""
              id=""
            >
              <option value="Choose Service" hidden>
                Choose Service
              </option>
              {studio_sub_services?.map((item) => {
                return (
                  <option value={item?.sub_services?.name}>
                    {item?.sub_services?.name}
                  </option>
                );
              })}
            </select>
          </div>
         {fieldErrors?.photoshoot_session_type && (
          <span
            className="error_text"
            style={{ position: "absolute", fontSize: "13px" }}
          >
            {fieldErrors?.photoshoot_session_type}
          </span>
        )}
        </div>
      )}

      {/* =======================Start  Date=====================? */}

      <div className="col-lg-4 col-md-6 col-12 my-3"
        style={{ position: "relative" }}
      >
        <label htmlFor="" className="new_order_label">
          Start Session Date/Time <span className="error_text">*</span>{" "}
        </label>
        <div className="upload_title mt-1">
          <input
            value={
              data?.photoshoot_start_date && data?.photoshoot_start_time
                ? `${data?.photoshoot_start_date} / ${data?.photoshoot_start_time}`
                : ""
            }
            readOnly
            id=""
            placeholder="Select Date/Time"
          />
          <span className="enquiry_calendr" onClick={handleClick}>
            <FaCalendarAlt />
          </span>
        </div>
        {fieldErrors?.photoshoot_date && (
          <span
            className="error_text"
            style={{ position: "absolute", fontSize: "13px" }}
          >
            {fieldErrors?.photoshoot_date}
          </span>
        )}
      </div>

      {/* =======================End Date=====================? */}
      <div className="col-lg-4 col-md-6 col-12 my-3"
        style={{ position: "relative" }}
      >
        <label htmlFor="" className="new_order_label">
          End Session Date/Time {" "}
        </label>
        <div className="upload_title mt-1">
          <input
            type="text"
            name=""
            value={
              data?.photoshoot_end_date && data?.photoshoot_end_time
                ? `${data?.photoshoot_end_date} / ${data?.photoshoot_end_time}`
                : ""
            }
            readOnly
            id=""
            placeholder="Select Date/Time"
          />
          <span className="enquiry_calendr" onClick={handleClick2}>
            <FaCalendarAlt />
          </span>
        </div>
        {fieldErrors?.photoshoot_date1 && (
          <span
            className="error_text"
            style={{ position: "absolute", fontSize: "13px" }}
          >
            {fieldErrors?.photoshoot_date1}
          </span>
        )}
      </div>

      {/* ==================== Budget======================= */}
      <div className="col-lg-4 col-md-6 col-12 my-3">
      <label htmlFor="" className="new_order_label">
          Budget 
        </label>
        <div className="upload_title mt-1">
          <input
            onChange={(e) =>
              setdata({ ...data, budget: e.target.value })
            }
            type="number"
            name=""
            id=""
            placeholder="Your Budget"
          />
        </div>
        
      </div>
{/* =================================Services timeline============== */}
<div className="col-lg-4 col-md-6 col-12 my-3"
        style={{ position: "relative" }}
      >
        <label htmlFor="" className="new_order_label">
          Services Timeline {" "}
        </label>
        <div className="upload_title mt-1">
          <select
            style={{ padding: "3px 0" }}
            value={data.service_timeline}
            onChange={(e) =>
              setdata({ ...data, service_timeline: e.target.value })
            }
            name=""
            id=""
          >
            <option value="urgent">Urgent (1 Week)</option>
            <option value="quick">Quick (2-4 Weeks)</option>
            <option value="normal">Normal (1 Month or greater)</option>
          </select>
        </div>
        {fieldErrors?.service_timeline && (
          <span
            className="error_text"
            style={{ position: "absolute", fontSize: "13px" }}
          >
            {fieldErrors?.service_timeline}
          </span>
        )}
      </div>
      <div className="col-lg-4 col-12 my-3" style={{position:"relative"}}>
          <label htmlFor="" className="new_order_label">
           Select State<span className="error_text">*</span>{" "}
          </label>
          <div className="upload_title mt-1">
         <select 
         value={data?.state_id}
         onChange={(e)=>{
          setdata({...data,state_id:parseInt(e.target.value)})
         }
        }
         >
          <option hidden>Select State</option>
          {stateData?.map((item)=>{
            return(
              <option value={item?.id}>{item?.name}</option>

            )
          })}
         </select>

          </div>
         {fieldErrors?.state_id && (
          <span
            className="error_text"
            style={{ fontSize: "13px", position: "absolute" }}
          >
            {fieldErrors?.state_id}
          </span>
        )}
       
        </div>
      {/* =======================Address=====================? */}
      <div className=" col-12 my-3" style={{ position: "relative" }}>
        <label htmlFor="" className="new_order_label">
          Address <span className="error_text">*</span>{" "}
        </label>
        <div className="upload_title mt-1">
          <input
            onChange={(e) =>
              setdata({ ...data, location: e.target.value })
            }
            type="text"
            name=""
            id=""
            placeholder="Address"
          />
        </div>
        {fieldErrors?.location && (
          <span
            className="error_text"
            style={{ fontSize: "13px", position: "absolute" }}
          >
            {fieldErrors?.location}
          </span>
        )}
      </div>

      {/* =======================session_detail=====================? */}
      <div className="col-6 my-3" style={{ position: "relative" }}>
        <label htmlFor="" className="new_order_label">
          Tell us more about your photoshoot detail{" "}
          <span className="error_text">*</span>{" "}
        </label>
        <div className="upload_title mt-1">
          <textarea
            onChange={(e) =>
              setdata({ ...data, session_detail: e.target.value })
            }
            name=""
            id=""
            rows={3}
            placeholder="Write here"
          ></textarea>
        </div>
        {fieldErrors?.session_detail && (
          <span
            className="error_text"
            style={{ position: "absolute", fontSize: "13pz" }}
          >
            {fieldErrors?.session_detail}
          </span>
        )}
      </div>

      {/* =======================what you expect=====================? */}
      <div className="  col-6 my-3 ">
        <label htmlFor="" className="new_order_label">
          Tell us what you expect from the photoshoot
        </label>
        <div className="upload_title mt-1">
          <textarea
            name=""
            id=""
            onChange={(e) =>
              setdata({ ...data, expected_photoshoot: e.target.value })
            }
            rows={3}
            placeholder="Write here"
          ></textarea>
        </div>
      </div>

      {/* =======================start date MUI=====================? */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        style={{ marginLeft: "-7rem", marginTop: ".7rem" }}
        open={open1}
        onClose={handleClose1}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            width: "370px",
            height: "460px",
            padding: ".5rem",
          }}
        >
          <div className="row enquiry_cale">
            <div className="col-12">
            <div className='d-flex align-items-center justify-content-between'>
            <label htmlFor="" className="new_order_label ">
                Select Date
              </label>
               <p className='mt-1 me-3 p-1 mb-1 d-flex align-items-center' style={{backgroundColor:'#ECECEC' , color:'#80889E', borderRadius:'25px'}}>
            <RxCross2  onClick={()=> setAnchorEl()} />
            </p>
            </div>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateCalendar"]}>
                    <DemoItem>
                      <DateCalendar
                        minDate={dayjs()}
                        onChange={handleDateChange}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            <div className="col-12 upload">
              <label htmlFor="" className="new_order_label">
                Select Time
              </label>
              <div className="Session_time upload_title mt-1">
                <input
                  type="time"
                  name=""
                  id=""
                  onChange={(e) =>
                    setdata({
                      ...data,
                      photoshoot_start_time: e.target.value,
                    })
                  }
                  
                />
              </div>
            </div>
          </div>
        </div>
      </Menu>

      {/* =======================start date MUI end=====================? */}
      {/* =======================end date MUI=====================? */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        style={{ marginLeft: "-7rem", marginTop: ".7rem" }}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div
          style={{
            width: "370px",
            height: "460px",
            padding: ".5rem",
          }}
        >
          <div className="row enquiry_cale">
            <div className="col-12">
            <div className='d-flex align-items-center justify-content-between'>
            <label htmlFor="" className="new_order_label ">
                Select Date
              </label>
               <p className='mt-1 me-3 p-1 mb-1 d-flex align-items-center' style={{backgroundColor:'#ECECEC' , color:'#80889E', borderRadius:'25px'}}>
            <RxCross2  onClick={()=> setAnchorEl2()} />
            </p>
            </div>
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateCalendar"]}>
                    <DemoItem>
                      <DateCalendar
                        minDate={dayjs()}
                        onChange={handleDateChange2}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            <div className="col-12 upload">
              <label htmlFor="" className="new_order_label">
                Select Time
              </label>
              <div className="Session_time upload_title mt-1">
                <input
                  type="time"
                  name=""
                  id=""
                  onChange={(e) =>
                    setdata({
                      ...data,
                      photoshoot_end_time: e.target.value,
                    })
                  }
                  
                />
              </div>
            </div>
          </div>
        </div>
      </Menu>

      {/* =======================end date MUI end=====================? */}
    
    </div>
    <hr className="m-0" />

    <div className="row">
        
    <div className="col-12">
      <div className="enquiry_subm">
      <button style={{border:'1px solid #BCBCBC', color:"#637381", background:"transparent"}} onClick={handleClose3} className="view_all_btn my-3">
    Cancel
       
      </button>
      <button onClick={handleSubmit} className="view_all_btn my-3">
      {buttonLoding ? (
                        <div
                          className="spinner-border text-light"
                          role="status"
                        ></div>
                      ) : (
                        " Submit"
                      )}
       
      </button>

      </div>

      </div>
    </div>
  

     
     
    </Box>
  </Modal>
  )
}

export default EnquiryModalHome
