import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import image2 from "../../asset/icons/week_plus.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  py: 1,
  outline: "none",
};
function convertTo12HourFormat(time24) {
  const [hours24, minutes24] = time24?.split(':').map(Number);
  let hours12 = hours24 % 12 || 12; // Convert 0 to 12
  const ampm = hours24 < 12 ? 'AM' : 'PM';
  return `${hours12?.toString().padStart(2, '0')}:${minutes24?.toString().padStart(2, '0')} ${ampm}`;
}
const timeSlots = [
  "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", 
  "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", 
  "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", 
  "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", 
  "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"
];

// Helper function to convert time string to minutes
const timeToMinutes = (time) => {
  if (!time) return 0; // Return 0 if time is undefined or empty
  const [hours, minutes] = time?.split(':').map(Number);
  return hours * 60 + minutes;
};

// Helper function to convert minutes back to time string in 24-hour format
// const minutesToTime = (minutes) => {
//   const hours = Math.floor(minutes / 60);
//   const mins = minutes % 60;
//   return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
// };

const minutesToTime = (minutes) => {
  const totalMinutes = minutes % (24 * 60); // Ensure it wraps around after 24 hours
  const hours = Math.floor(totalMinutes / 60);
  const mins = totalMinutes % 60;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
};


function AvailabilityMUI({day_id, data,setChange, setData, duration, gap}) {
  const [start_time, setStart_time] = useState('');
  const [end_time, setEnd_time] = useState('');
  const [error,setError]=useState()
  const slotDuration = parseInt(duration) * 60; // Slot duration in minutes
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const scheduleGap = parseInt(gap) * 60; // Schedule gap in minutes
  const createSlot = () => {
    const newSlotStart = timeToMinutes(start_time);
    const newSlotEnd = timeToMinutes(end_time);
    const fullDayMinutes = 1440; // Total minutes in a day
 
    const isValidSlot = (item) => {
      return item.slots.every(({ start_time: existingStart, end_time: existingEnd }) => {
        const existingSlotStart = timeToMinutes(existingStart);
        const existingSlotEnd = timeToMinutes(existingEnd);
  
        // Handle slots that span across midnight
        const adjustedNewSlotEnd = newSlotEnd < newSlotStart ? newSlotEnd + fullDayMinutes : newSlotEnd;
        const adjustedExistingSlotEnd = existingSlotEnd < existingSlotStart ? existingSlotEnd + fullDayMinutes : existingSlotEnd;
  
        // Check for overlap
        const doesOverlap = !(adjustedNewSlotEnd <= existingSlotStart || newSlotStart >= adjustedExistingSlotEnd);
  
        // Check if the slot respects the schedule gap
        const respectsScheduleGap = 
          (adjustedNewSlotEnd <= existingSlotStart - scheduleGap || newSlotStart >= adjustedExistingSlotEnd + scheduleGap);
  
        return !doesOverlap && respectsScheduleGap;
      });
    };
  
    const updatedData = data.map((item) => {
      if (item.day_id === day_id) {
        if (isValidSlot(item)) {
          setChange(true);
          handleClose();
          let array = [...item.slots];
          array.push({ start_time, end_time });
          return { ...item, slots: array };
        } else {
          setError("Slot overlaps with an existing slot or does not respect the schedule gap.");
        }
      }
      return item;
    });
  
    setData(updatedData);
  };
  
  
  const handleStartTimeChange = (e) => {
    const start = e.target.value;
    setStart_time(start);
  
    // Calculate and set the end time
    const startMinutes = timeToMinutes(start);
    const endMinutes = startMinutes + slotDuration;
  
    // Check if the end time is past midnight
    if (endMinutes >= 1440) { // 1440 minutes = 24 hours
      setError("The slot duration extends past midnight. Please choose a different start time.");
      setStart_time(""); // Optionally clear the start time
      setEnd_time(""); // Optionally clear the end time
      return;
    }
    setError("")
    const end = minutesToTime(endMinutes);
    setEnd_time(end);
  };


  

  return (
    <>
      <img
        onClick={handleOpen}
        src={image2}
        alt=""
        style={{ cursor: "pointer", marginRight: "10px" }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="row m-0 py-2">
            <div className="col-12 p-0">
              <div className="ordr_comp_head px-4">
                <h1>Create Time Slot</h1>
                <div onClick={handleClose}>X</div>
              </div>
              <hr />
              <div className="ordr_comp_content avail_context px-3">
                <div className="row m-0">
                  <div className="col-12">
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#494949",
                      }}
                    >
                      To
                    </p>
                  </div>
                  <div className="col-lg-6 col-12">
                    <select onChange={handleStartTimeChange} value={start_time}>
                      <option value="HM" hidden>
                        HH: MM
                      </option>
                      {timeSlots.map((slot, index) => (
                        <option key={index} value={slot}>
                          {convertTo12HourFormat(slot)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <br />
                  <br />

                  <div className="col-12">
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#494949",
                      }}
                    >
                      From
                    </p>
                  </div>
                  <div className="col-lg-6 col-12">
                    <select value={end_time} disabled>
                      <option value={end_time}>{end_time?convertTo12HourFormat(end_time):""}</option>
                    </select>
                  </div>
                  <span
                                className="error_text"
                              
                              >
                                {error}
                              </span>
                  <hr />
                  <div className="week_avail_btn">
                    <button
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        color: "white",
                        background: "#494949",
                        boxShadow: "8px 8px 24px 0px #02024626",
                        borderRadius: "4px",
                        height: "38px",
                        padding: "0 25px",
                        borderColor: "transparent",
                        outline: "none",
                      }}
                      onClick={createSlot}
                    >
                      Create Slot
                    </button>
                    <button
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "21px",
                        color: "#bcbcbc",
                        background: "transparent",
                        borderRadius: "3px",
                        height: "37px",
                        padding: "0 20px",
                        border: "1px solid #bcbcbc",
                        outline: "none",
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AvailabilityMUI;
