import React, { useEffect } from "react";
import { useAuth } from "../../Context/ContextProvider";


function Banner() {
 const {terms,TermsandPrivacy}=useAuth()

 useEffect(()=>{
  TermsandPrivacy("terms-and-conditions")
  // eslint-disable-next-line
 },[])
  return (
    <>
      <div className="term_btn text-center mt-4 pt-2 mb-4 pb-2">
        <button>Terms and Conditions</button>
      </div>
      <div className="term_content">
      {
  terms && terms?.length>0?
  <>
  {
    terms?.map((item)=>{
      return(
        <div  dangerouslySetInnerHTML={{ __html: item?.description }}>
          </div>
      )
    })
  }
  </>
  :
  <h5 className="d-flex justify-content-center align-items-center">


  No Terms and Conditions available!
</h5>
}
      </div>

    </>
  );
}

export default Banner;
