import React from 'react'
import img from '../../asset/Rectangle 1374.png'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../Context/ContextProvider'
function ListingSectoin({data}) {
    const {web_url,setAdminServiceDetail}=useAuth()
    const navigate=useNavigate()
    let count=0
  return (
    <div className='listing_section'>
        {data?.map((item,i)=>{
            return item?.service?.sub_services?.map((items,i)=>{
                count++
                return  (
                    
                    <div onClick={()=>{
                        setAdminServiceDetail()
                        navigate(`${web_url}service-detail/${items?.slug}`)}} className='row'>
                        <div className='col-md-8 col-12'> 
                        <h6>{count<10?`0${count}`:count}</h6>
                        <h1>{items?.name}</h1>
                        </div>
                        <div className='col-md-4 col-12 d-none d-lg-flex'>
                            <div className='img_box'>
                                <img style={{width:"100%",height:"100%",objectFit:"cover"}} src={items?.image?items?.image:img} alt='...'/>
                            </div>
                        </div>
                    </div>
                )
            })
          
        })}
    </div>
  )
}

export default ListingSectoin