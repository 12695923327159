import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import logo from '../../asset/logo (1).png'
import { useAuth } from '../../Context/ContextProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius:"10px"
  };
function ChildModal({open1,handleClose,handleOpen,value}) {
  const {passwordForget,forgetdata,buttonLoding}=useAuth()
    const [fieldErrors1, setFieldErrors1] = React.useState({})
    const [data1,setdata1]=React.useState({
        email:""
      })
      const validateStep1 = () => {
        let errors = {};
        if (!data1.email) {
          errors.email = "Email is required.";
        } else if (!isValidEmail(data1.email)) {
          errors.email = "Please enter a valid email address.";
        }
       
        setFieldErrors1(errors);
        // Check if there are any errors
        return Object.keys(errors).length === 0;
      };
      const handleSend = () => {
        if (validateStep1()) {
         passwordForget(data1)
        }
      };
      const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
  return (
    <>
        <Link className='forgotPsd' onClick={()=>handleOpen()}  variant="body2" style={{textDecoration:"none",fontSize:"13px"}}>
                  Forgot password?
                </Link>
    <Modal
    open={open1}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
                <div style={{paddingBottom:"3rem", paddingTop:"1rem"}}>
                    <div className='d-flex justify-content-center my-3'>
                    <img src={logo} style={{width:"150px"}} alt='...'/>

                    </div>
                   {forgetdata?<a style={{textDecoration:"none",color:"#494949"}} target='_blank'  rel="noreferrer" href={forgetdata}>
                   <h4  className='text-center my-2'>{value}</h4>
                   </a> :<h4  className='text-center my-2'>{value}</h4>}
                    <h5  className='text-center my-4'>Forgot Password</h5>
                    <div style={{height:"max-content"}} className='login_main px-3'>
                    <div className='signIn align-items-start'>
                        <label htmlFor="" className="new_order_label d-flex ">Email</label>
                        <div  className=' upload_title mt-1'>
                            <input onChange={(e)=>setdata1({...data1,email:e.target.value})} type='email' placeholder='Email'/>
                        </div>
                    </div>
                        {fieldErrors1?.email && <span className="error_text" style={{ position: "absolute" }}>{fieldErrors1?.email}</span>}

                    <div>

                    <button onClick={()=>handleSend()} className='sign_btn mt-4'>{buttonLoding?<div className="spinner-border text-light" role="status">

</div>: "Send"}</button>
                    </div>
                    <div>

                    <button   onClick={()=>handleClose()} style={{border:"1px solid #414040",background:"none",color:"#414040"}} className='sign_btn my-3'>Back to Sign in</button>
                    </div>
                    </div>
         
                </div>
    </Box>
  </Modal>
    </>
  )
}

export default ChildModal