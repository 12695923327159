import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../Context/ContextProvider";
import { FaCheck } from "react-icons/fa6";
import { IoPricetags } from "react-icons/io5";
import { Link } from "react-router-dom";
import { BiSolidCopy } from "react-icons/bi";
import { FormControlLabel, Switch } from "@mui/material";
const label = { inputProps: { 'aria-label': 'Size switch demo' } };


function Priceing({ permission }) {
  const [show, setshow] = useState(false);
  const [serviceId, setServiceId] = useState();
  const [studioId, setStudioId] = useState();

  const [type, setType] = useState(
    permission?.includes("photography") ? "service" : "studio"
  );
  const [data, setData] = useState([]);
  const [subServiceId, setsubServiceId] = useState();
  const [errorData, setErrorData] = useState([]);
  const {
    vedorServiceData,
    vendorServices,
    getPlanByService,
    plansName,
    planNameData,
    createPlans,
    editPlans,
    planData,
    getStudio,
    studioData,
    web_url,
    userData,
    buttonLoding,
    planLoad,
    setplanLoad,
  } = useAuth();
console.log("planData",planData[0]?.booking_service_type)
const [selBtn, SetSelBtn] = useState("hourly_slots");

useEffect(()=>{
  if(planData[0]?.booking_service_type){

    SetSelBtn(planData[0]?.booking_service_type)
  }
  else{
    SetSelBtn("hourly_slots")
  }
},[planData])
console.log("selBtn",selBtn)
  useEffect(() => {
    plansName(type);
    if (type === "service") {
      vendorServices("photography", 0);
      setplanLoad(true);
    } else {
      setplanLoad(true);
      vendorServices("studio", 1);
    }
  }, [type]);

  useEffect(() => {
    if (type === "service") {
      if (vedorServiceData?.vendor_services?.length) {
        // Filter sub-services where only_inquiry is false
        const validSubServices =
          vedorServiceData?.vendor_services[0]?.service?.sub_services.filter(
            (subService) => subService?.vendorSubService?.only_inquiry === 0
          );

        if (validSubServices.length > 0) {
          const sub_service_id = validSubServices[0]?.vendor_sub_service_id;
          const service_id =
            vedorServiceData?.vendor_services[0]?.vendor_service_id;

          setServiceId(service_id);
          getPlanByService({ service_id: sub_service_id, type });
          setsubServiceId(sub_service_id);
          setResult(validSubServices); // Set only the filtered sub-services
          setSlug(vedorServiceData?.vendor_services[0]?.service?.slug);
        } else {
          // Handle case where no valid sub-services are found (optional)
          setResult([]); // or any fallback logic
        }
      }
    }
  }, [vedorServiceData, type]);

  console.log(subServiceId, serviceId);
  useEffect(() => {
    if (type === "studio") {
      if (studioData?.length) {
        // Filter studio data where only_inquiry is false
        const validStudios = studioData.filter(
          (studio) => studio?.only_inquiry === 0
        );

        if (validStudios.length > 0) {
          setStudioId(validStudios[0]?.studio_id || "");
          getPlanByService({
            studio_id: validStudios[0]?.studio_id || "",
            type,
          });
          setSlug(validStudios[0]?.slug);
        }

        setResult1(validStudios); // Set only valid studios
      }
    }
  }, [studioData, type]);

  useEffect(() => {
    let array = [];
    if (planData?.length > 0) {
      planNameData?.data?.forEach((items) => {
        let matchFound = false;
        planData.forEach((item) => {
          if (items?.id === item?.plan_template?.id) {
            matchFound = true;
            let obj = {
              id: item.id,
              plan_template_id: item?.plan_template?.id,
              price_set: item?.price_set,
              plan_duration: item?.plan_duration,
              type: item?.type,
              is_active:item?.is_active===0?item?.is_active:true,
              duration_type: item?.duration_type || "hours",
              description: item?.description,
              offers: item.offers
                ? [...item.offers, "", "", "", "", ""].slice(0, 5)
                : ["", "", "", "", ""],
            };

            // Conditionally add vendor_sub_service_id or studio_id
            if (type === "service") {
              obj.vendor_sub_service_id = subServiceId;
            } else if (type === "studio") {
              obj.studio_id = studioId;
            }

            array.push(obj);
          }
        });
        if (!matchFound) {
          let obj = {
            plan_template_id: "",
            price_set: "",
            plan_duration: "",
            type: type,
            is_active:true,
            duration_type: "hours",
            description: "",
            offers: ["", "", "", "", ""],
          };

          // Conditionally add vendor_sub_service_id or studio_id
          if (type === "service") {
            obj.vendor_sub_service_id = subServiceId;
          } else if (type === "studio") {
            obj.studio_id = studioId;
          }

          array.push(obj);
        }
      });

      setData(array);
    } else {
      let array = [];
      planNameData?.data?.forEach((item) => {
        let obj = {
          plan_template_id: "",
          price_set: "",
          is_active:true,
          plan_duration: "",
          type: type,
          duration_type: "hours",
          description: "",
          offers: ["", "", "", "", ""],
        };

        // Conditionally add vendor_sub_service_id or studio_id
        if (type === "service") {
          obj.vendor_sub_service_id = subServiceId;
        } else if (type === "studio") {
          obj.studio_id = studioId;
        }

        array.push(obj);
      });
      setData(array);
    }
  }, [planData]);

  function validateData(data) {
    let errors = {};

    // Check if selBtn is hourly_slots, and validate the plan_duration field
    if (selBtn === "hourly_slots") {
      if (!data.plan_duration) {
        errors.plan_duration = "Plan Duration is required.";
      } else if (data.plan_duration < 1 || data.plan_duration > 23) {
        errors.plan_duration = "Duration must be within 1 to 23 hr";
      }
    }

    // Check if selBtn is item_quantity, and validate the quantity field
    if (selBtn === "item_quantity") {
      if (!data.plan_duration) {
        errors.plan_duration = "Plan quantity is required.";
      } else if (data.plan_duration < 1) {
        errors.plan_duration = "Quantity must be equal or greater than 1";
      }
    }

    // Common validation for price_set and description
    if (!data.price_set) {
      errors.price_set = "Price Set is required.";
    }

    if (!data.description) {
      errors.description = "Description is required.";
    }

    // Validation for offers (assuming offers is an array of strings)
    const validOffers = data.offers.filter(
      (offer) => offer?.trim() !== "" && offer !== null
    );

    if (validOffers.length === 0) {
      errors.offer = "At least 1 valid offer is required.";
    }

    console.log(validOffers);

    return errors;
  }

  function validateArrayData(dataArray) {
    let arrayErrors = [];
    let validPlansCount = 0;

    dataArray?.forEach((data, index) => {
      let errors = {};

      const isAnyFieldFilled =
        data.price_set ||
        data.plan_duration ||
        data.description ||

        data.offers.some((offer) => offer?.trim() !== "");
      if (isAnyFieldFilled) {
        // If any field is filled, validate the entire plan
        errors = validateData(data);
        console.log(errors);
        if (Object.keys(errors).length === 0) {
          // If no errors, increment the validPlansCount
          validPlansCount++;
        } else {
          // If errors exist, store them for the specific plan
          arrayErrors[index] = errors;
        }
      }
    });

    // if (validPlansCount === 0) {
    //   // If no plan is fully valid, return an error for the first plan
    //   arrayErrors[0] = { firstPlan: "At least one plan must be fully valid." };
    // }

    setErrorData(arrayErrors);
    const isValid = arrayErrors.every(
      (errors) => Object.keys(errors).length === 0
    );

    return { isValid, validPlansCount };
  }
  const handleInputChange = (index, field, value, plan_template_id) => {
    setData((prevData) => {
      const newData = [...prevData];
      if (field === "offers") {
        // If the field is 'offers', update the specific offer value
        newData[index].offers = [...newData[index].offers];
        newData[index].offers[value.index] = value.offer;
      } else {
        // Otherwise, update other fields normally
        newData[index][field] = value;
        if (plan_template_id) {
          newData[index].plan_template_id = plan_template_id;
        }
      }
      return newData;
    });
  };
  console.log(errorData);

  const [result, setResult] = useState([]);
  const [result1, setResult1] = useState([]);
  function searchBySubServiceName(searchTerm) {
    let results;
    if (type === "service") {
      if (searchTerm !== "") {
        vedorServiceData?.vendor_services?.map((item) => {
          console.log(serviceId);
          if (item?.vendor_service_id === serviceId) {
            results = item?.service?.sub_services?.filter(
              (items) =>
                items &&
                items.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setResult(results);
          }
        });
      } else {
        vedorServiceData?.vendor_services?.map((item) => {
          console.log(serviceId);
          if (item?.vendor_service_id === serviceId) {
            results = item?.service?.sub_services;
            setResult(results);
          }
        });
      }
    } else {
      results = studioData?.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setResult1(results);
    }
  }
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setActive(true);
      }, 400);
    } else {
      setActive(false);
    }
  }, [show]);
  const sidebarRef = useRef(null);

  useEffect(() => {
    // Function to handle click outside of sidebar
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setshow(false);
        setActive(false);
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setshow, setActive]);
  console.log(result1);

  const [slug, setSlug] = useState();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // =================copy to ALl sectoon-=======================

  const [plansdata, setPlandata] = useState();

  const [dropdown, setDropDown] = useState(false);
  const handleDropdownToggle = (id) => {
    if (dropdown === id) {
      setDropDown(null); // Close dropdown if it's already open
    } else {
      setDropDown(id); // Open dropdown for the clicked item
    }
  };
  

  const copyToAll = (index, plan_template_id) => {
    let copyData = data[index]; // Assuming you want to copy from the first item
    let newData = data?.map((item, idx) => ({
      ...item,
      duration_type: copyData?.duration_type,
      description: copyData?.description,
      offers: copyData?.offers,
      is_active:copyData?.is_active,
      plan_duration: copyData?.plan_duration,
      price_set: copyData?.price_set,
      plan_template_id: planNameData?.data[idx]?.id || item?.plan_template_id,
    }));
    setData(newData);
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropDown(null); // Close dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [dropdown1, setDropDown1] = useState(null);


const handleDropdownToggle1 = (id) => {
  if (dropdown1 === id) {
    setDropDown1(null); // Close dropdown if it's already open
  } else {
    setDropDown1(id); // Open dropdown for the clicked item
  }
};


  return (
    <>
      <div
        style={{ overflowX: "hidden", position: "relative" }}
        className="vendor_pricing"
      >
        <div className="new_order_nav ">
          <div>
            <h4 className="d-flex align-items-center">
              <IoPricetags className="me-2" />
              Price Packages{" "}
            </h4>
          </div>
        </div>
        <div className="table_box priceing">
          <h3
            style={{ color: "#494949", fontSize: "24px", paddingLeft: ".5rem" }}
          >
            Create pricing packages according to your discretion.
          </h3>
          <p
            style={{
              color: "#494949",
              fontSize: "14px",
              paddingLeft: ".5rem",
              marginBottom: "0",
            }}
          >
            Note: Plan prices will be excluding VAT, but platform fees will be
            detected from your price.
          </p>
          <div className="d-flex justify-content-between align-items-center">
            {permission?.length === 2 ? (
              <div className="package_tab_btn d-flex justify-content-between w-100 align-items-center p-0 my-2">
                <div>
                  <button
                    className={`profile_btns p-0 ${
                      type === "service" ? "common_dark" : " common_light"
                    }`}
                    onClick={() => {
                      setData([]);
                      setType("service");
                      vendorServices();
                      plansName("service");
                      setStudioId();
                    }}
                  >
                    Services(s)
                  </button>
                  <button
                    className={`profile_btns p-0 mx-4 ${
                      type === "studio" ? "common_dark " : "common_light"
                    }`}
                    onClick={() => {
                      setData([]);
                      setType("studio");
                      SetSelBtn("hourly_slots");
                      getStudio();
                      plansName("studio");
                      setServiceId();
                    }}
                  >
                    Studio(s)
                  </button>
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>

          <div className="row  px-2">
            <div className="col-lg-3 col-12">
              <h5
                className=" mt-1"
                style={{
                  color: "#494949",
                  textTransform: "capitalize",
                }}
              >
                Available {type}
                <p
                  style={{
                    textTransform: "lowercase",
                    display: "inline",
                    margin: "0",
                  }}
                >
                  (s)
                </p>
              </h5>
            </div>
            

            <div className="col-lg-9 col-12 ">
              <div className="pricing_check px-3">
                {type !== "studio" && (
                  <div className="priceing_content">
                    <button
                      onClick={() => SetSelBtn("hourly_slots")}
                      className={`${
                        selBtn === "hourly_slots" ? "selButton" : ""
                      }`}
                    >
                      Plan with hourly slots
                    </button>
                    <button
                      onClick={() => SetSelBtn("item_quantity")}
                      className={`${
                        selBtn === "item_quantity" ? "selButton" : ""
                      }`}
                    >
                      Plan based on item quantity
                    </button>
                    {userData?.data?.role !== "vendor-admin" ? (
                      ""
                    ) : (
                      <button
                        onClick={() => SetSelBtn("manual_reservation")}
                        className={`${
                          selBtn === "manual_reservation" ? "selButton" : ""
                        }`}
                      >
                        Plan for manual reservations
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row  package_main_vendor p-2 pb-0 mb-3">
            <div style={{ height: "100%" }} className="col-lg-3 col-12 pe-2 ">
              <div className="avail_main" style={{ height: "94%" }}>
                <div className="avail_service avail_sel p-0">
                  {type === "service" ? (
                    <select
                      onChange={(e) => {
                        let array = [];
                        setplanLoad(true);
                        vedorServiceData?.vendor_services?.filter((item) => {
                          if (item?.service?.id == e.target.value) {
                            array = item?.service?.sub_services;
                            setServiceId(item?.vendor_service_id);
                            setSlug(item?.slug);
                            setsubServiceId(
                              item?.service?.sub_services[0]
                                ?.vendor_sub_service_id
                            );
                            getPlanByService({
                              service_id:
                                item?.service?.sub_services[0]
                                  ?.vendor_sub_service_id,
                              type,
                            });
                          }
                        });
                        setResult(array);
                      }}
                      name=""
                      id=""
                    >
                      <option value="Select Services" hidden>
                        Select Services
                      </option>
                      {vedorServiceData?.vendor_services?.map((item) => {
                        return (
                          <option value={item?.service?.id}>
                            {item?.service?.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <select
                      onChange={(e) => {
                        let array = [];
                        setplanLoad(true);

                        studioData?.filter((item) => {
                          item?.studio_sub_services?.map((items) => {
                            if (items?.id === parseInt(e.target.value)) {
                              array.push(item);
                              console.log(item);
                              // setServiceId(item?.service?.sub_services[0]?.vendor_sub_service_id)
                              // getPlanByService({ service_id: item?.service?.sub_services[0]?.vendor_sub_service_id,type:availbilty?.type })
                            }
                          });
                        });
                        setStudioId(array[0]?.studio_id);
                        //   getPlanByService(array[0]?.id)
                        setResult1(array);
                      }}
                      name=""
                      id=""
                    >
                      <option value="Select Services" hidden>
                        Select studio
                      </option>
                      {vedorServiceData?.all_services?.map((item) => {
                        return item?.sub_services?.map((items) => {
                          return (
                            <option value={items?.id}>{items?.name}</option>
                          );
                        });
                      })}
                    </select>
                  )}
                </div>
                <div className="avail_service pt-0">
                  <input
                    type="search"
                    onChange={(e) => {
                      searchBySubServiceName(e.target.value);
                    }}
                    placeholder="Search Service"
                  />
                  <ul className="m-0 p-0">
                    {type === "service"
                      ? result?.map((item) => {
                          return (
                            <li
                              key={item?.id}
                              onClick={() => {
                                setplanLoad(true);

                                let sentData = {
                                  service_id: item?.vendor_sub_service_id,
                                  type,
                                };
                                setData([]);
                                getPlanByService(sentData);
                                setsubServiceId(item?.vendor_sub_service_id);
                                setStudioId();
                                setSlug(item?.sub_service?.slug);
                              }}
                              style={{ cursor: "pointer" }}
                              className={`${
                                subServiceId === item?.vendor_sub_service_id
                                  ? "avail_active"
                                  : ""
                              }`}
                            >
                              {item?.name}
                            </li>
                          );
                        })
                      : result1?.map((item) => {
                          return (
                            <li
                              key={item?.studio_id}
                              onClick={() => {
                                setplanLoad(true);

                                let sentData = {
                                  studio_id: item?.studio_id,
                                  type,
                                };
                                setData([]);
                                getPlanByService(sentData);
                                setStudioId(item?.studio_id);
                                setServiceId();
                                setSlug(item?.slug);
                              }}
                              style={{ cursor: "pointer" }}
                              className={`${
                                studioId === item?.studio_id
                                  ? "avail_active"
                                  : ""
                              }`}
                            >
                              {item?.name}
                            </li>
                          );
                        })}
                  </ul>
                </div>
              </div>
            </div>
            {planLoad ? (
              <div className="col-lg-9 ps-0">
                <div
                  className="package_over "
                  style={{ maxWidth: "100%", height: "90%" }}
                >
                  <div className="row pricing_over m-0">
                    <div className="col-4 " style={{ height: "100%" }}>
                      <div className="border p-3 rounded">
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                      </div>
                    </div>
                    <div className="col-4 " style={{ height: "100%" }}>
                      <div className="border p-3 rounded">
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                      </div>
                    </div>
                    <div className="col-4 " style={{ height: "100%" }}>
                      <div className="border p-3 rounded">
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                        <p
                          style={{ height: "50px" }}
                          className="placeholder-glow"
                        >
                          <span className="placeholder col-12 h-100 rounded"></span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{ height: "100%" }}
                className="col-lg-9 col-12 ps-0   miain_priceing"
              >
                <div className="package_over">
                  <div className="row pricing_over m-0 ">
                    {planNameData?.data?.map((item, i) => {
                      return (
                        <div
                          className="col-4 px-2"
                          style={{ height: "100%"
                          }}
                          key={i}
                        >
                          <div
                            style={{ height: "100%",boxShadow: "0px 0px 4px 0px #00000040" }}
                            className=" border rounded "
                          >
                            <div ref={dropdownRef} className="planCopy">
                              <div className="copy_plan_to">
                                <h5 className="m-0">{item?.plan_name}</h5>
                                <div className="d-flex">
                                  <p
                                    onClick={(event) => {
                                      // handleClick(event); // Pass the event object here
                                      setPlandata(item);
                                      handleDropdownToggle(item?.id);
                                    }}
                                     className="copy_plan_p"
                                  >
                                    <BiSolidCopy />
                                  </p>
                                  <p
                                    className="mx-2"
                                    style={{ cursor: "inherit" , color:"#d9d9d9"}}
                                  >
                                    |
                                  </p>
                                  <p className="generalInquiry priCheck">
                                              <span className="switchEnquiry">
                                              <Switch {...label} checked={data[i]?.is_active}
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      i,
                                                      "is_active",
                                                      e.target.checked,
                                                      item?.id
                                                    )
                                             
                                                  } size="small" />
                                            
                                              </span>
                                            </p>
                                </div>
                              </div>

                              {dropdown === item?.id && (
                                <div
                                  ref={dropdownRef}
                                  className="custom_copyPlan"
                                >
                                  <div style={{ padding: ".5rem" }}>
                                    <div className="row ">
                                      <div className="col-12">
                                        <ul className="plan_list">
                                          <li
                                            onClick={() =>
                                              copyToAll(i, item?.id)
                                            }
                                          >
                                            Copy To All
                                          </li>
                                          {planNameData?.data?.map(
                                            (items, index) => (
                                              <li
                                                key={items?.plan_name}
                                                onClick={() => {
                                                  let copyData = data[i];
                                                  console.log(copyData);
                                                  let newData = [...data];
                                                  console.log(newData);
                                                  newData[index].duration_type =
                                                    copyData?.duration_type;
                                                  newData[index].description =
                                                    copyData?.description;
                                                  newData[index].offers =
                                                    copyData?.offers;
                                                  newData[index].plan_duration =
                                                    copyData?.plan_duration;
                                                  newData[index].price_set =
                                                    copyData?.price_set;
                                                  newData[
                                                    index
                                                  ].plan_template_id =
                                                    items?.id;

                                                  console.log(newData);

                                                  setData(newData);
                                                  handleDropdownToggle(
                                                    item?.id
                                                  );
                                                }}
                                              >
                                                {items?.plan_name}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div
                              className="package_card_vendor"
                              style={{ padding: "1rem", paddingTop: "0" }}
                            >
                              {/* <div className=" my-3 pe-0">
                         
                          <p className="generalInquiry priCheck">
                            General Inquiry Only
                            <span className="switchEnquiry ms-3">
                              <FormControlLabel
                                // checked={data?.only_inquiry}
                                
                                control={<IOSSwitch sx={{ m: 1 }} />}
                              />
                            </span>
                          </p>
                          <p className="generalInquiryN">
                            No plans or booking details are required, only an
                            inquiry form is available.
                          </p>
                    
                           </div> */}
                              <div
                                style={{
                                  position: "relative",
                                  marginBottom: "1rem",
                                }}
                              >
                                <label className="new_order_label" htmlFor="">
                                  Price Set
                                  <span
                                    style={{ border: "none" }}
                                    className="error_text p-0"
                                  >
                                    *
                                  </span>
                                </label>
                                <input
                                  onChange={(e) =>
                                    handleInputChange(
                                      i,
                                      "price_set",
                                      e.target.value,
                                      item?.id
                                    )
                                  }
                                  value={data[i]?.price_set || ""}
                                  placeholder="Enter Price"
                                  type="number"
                                />
                                <span
                                  className="error_text"
                                  style={{
                                    position: "absolute",
                                    border: "none",
                                    left: "-5%",
                                    top: "100%",
                                  }}
                                >
                                  {errorData[i]?.price_set}
                                </span>
                              </div>
                              <div className="row ">
                                {selBtn === "hourly_slots" && (
                                  <div
                                    className="col-lg-12 col-12"
                                    style={{
                                      position: "relative",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <label
                                      className="new_order_label"
                                      htmlFor=""
                                    >
                                      Enter service duration in hour(s)
                                      <span
                                        style={{ border: "none" }}
                                        className="error_text p-0"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <input
                                      style={{
                                        width: "100%",
                                      }}
                                      onChange={(e) =>
                                        handleInputChange(
                                          i,
                                          "plan_duration",
                                          e.target.value
                                        )
                                      }
                                      value={data[i]?.plan_duration || ""}
                                      placeholder="Enter Plan Duration"
                                      // style={{ width: "100%" }}
                                      type="number"
                                    />
                                    <span
                                      className="error_text"
                                      style={{
                                        position: "absolute",
                                        border: "none",
                                        left: "-2%",
                                        top: "100%",
                                      }}
                                    >
                                      {errorData[i]?.plan_duration}
                                    </span>
                                  </div>
                                )}
                                {selBtn === "item_quantity" && (
                                  <div
                                    className="col-lg-12 col-12"
                                    style={{
                                      position: "relative",
                                      marginBottom: "1rem",
                                    }}
                                  >
                                    <label
                                      className="new_order_label"
                                      htmlFor=""
                                    >
                                      Enter the number of items.
                                      <span
                                        style={{ border: "none" }}
                                        className="error_text p-0"
                                      >
                                        *
                                      </span>
                                    </label>
                                    <input
                                      style={{
                                        width: "100%",
                                      }}
                                      onChange={(e) =>
                                        handleInputChange(
                                          i,
                                          "plan_duration",
                                          e.target.value
                                        )
                                      }
                                      value={data[i]?.plan_duration || ""}
                                      placeholder="Item(s)"
                                      // style={{ width: "100%" }}
                                      type="number"
                                    />
                                    <span
                                      className="error_text"
                                      style={{
                                        position: "absolute",
                                        border: "none",
                                        left: "-2%",
                                        top: "100%",
                                      }}
                                    >
                                      {errorData[i]?.plan_duration}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                  marginBottom: "1rem",
                                }}
                              >
                                <label className="new_order_label" htmlFor="">
                                  Enter Description
                                  <span
                                    style={{ border: "none" }}
                                    className="error_text p-0"
                                  >
                                    *
                                  </span>
                                </label>
                                <input
                                  onChange={(e) =>
                                    handleInputChange(
                                      i,
                                      "description",
                                      e.target.value
                                    )
                                  }
                                  value={data[i]?.description || ""}
                                  placeholder="Enter Description"
                                />
                                <span
                                  className="error_text"
                                  style={{
                                    position: "absolute",
                                    border: "none",
                                    left: "-5%",
                                    top: "100%",
                                  }}
                                >
                                  {errorData[i]?.description}
                                </span>
                              </div>

                              {data[i]?.offers?.map((offer, offerIndex) => (
                                <div
                                  style={{
                                    position: "relative",
                                    marginBottom: "1rem",
                                  }}
                                  key={offerIndex}
                                >
                                  <label className="new_order_label" htmlFor="">
                                    {`Enter Offer #${offerIndex + 1}`}
                                    {offerIndex < 1 ? (
                                      <span
                                        style={{ border: "none" }}
                                        className="error_text p-0"
                                      >
                                        *
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                  <input
                                    style={{}}
                                    onChange={(e) =>
                                      handleInputChange(i, "offers", {
                                        index: offerIndex,
                                        offer: e.target.value,
                                      })
                                    }
                                    value={offer || ""}
                                    placeholder={`Enter Offer #${
                                      offerIndex + 1
                                    }${offerIndex < 1 ? " (Required)" : ""}`}
                                  />
                                  <span
                                    className="error_text"
                                    style={{
                                      position: "absolute",
                                      border: "none",
                                      left: "-5%",
                                      top: "100%",
                                    }}
                                  >
                                    {errorData[i]?.offer}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  style={{ position: "relative" }}
                  className="row bottom_bar_bar"
                >
                  <div
                    className="col-12 d-flex justify-content-between align-items-center "
                    style={{ paddingRight: "1.7rem" }}
                  >
                    <div>
                      {(type === "studio" && studioData?.length) ||
                      (type === "service" && vedorServiceData) ? (
                        <button
                          className="m-3 pkg_btn"
                          style={{ background: "#494949" }}
                          onClick={() => {
                            const validation = validateArrayData(data);
                            const { isValid, validPlansCount } = validation;

                            if (isValid) {
                              const validPlans = data.filter((plan) => {
                                // Check based on the value of selBtn
                                if (selBtn === "hourly_slots") {
                                  // Validate for hourly_slots
                                  return (
                                    plan.price_set &&
                                    plan.plan_duration &&
                                    plan.duration_type &&
                                    plan.description &&
                                    plan.offers.filter(
                                      (offer) =>
                                        offer?.trim() !== "" && offer !== null
                                    ).length > 0
                                  );
                                } else if (selBtn === "item_quantity") {
                                  // Validate for item_quantity
                                  return (
                                    plan.price_set &&
                                    plan.plan_duration &&
                                    plan.description &&
                                    plan.offers.filter(
                                      (offer) =>
                                        offer?.trim() !== "" && offer !== null
                                    ).length > 0
                                  );
                                } else if (selBtn === "manual_reservation") {
                                  // Validate for manual_reservation (no plan_duration or quantity required)
                                  return (
                                    plan.price_set &&
                                    plan.description &&
                                    plan.offers.filter(
                                      (offer) =>
                                        offer?.trim() !== "" && offer !== null
                                    ).length > 0
                                  );
                                }
                              });

                              console.log(validPlans);

                              if (validPlans[0]?.id) {
                                editPlans(validPlans, selBtn);
                              } else {
                                createPlans(validPlans, selBtn);
                              }
                            }
                          }}
                        >
                          {buttonLoding ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            ></div>
                          ) : (
                            `${
                              data[0]?.id ? "Update Package" : "Create Package"
                            }`
                          )}
                        </button>
                      ) : (
                        ""
                      )}
                      <Link
                        to={`${web_url}profile/${userData?.data?.user?.username}/${slug}`}
                      >
                        <button
                          className="m-3 pkg_btn"
                          style={{ width: "120px", background: "#494949" }}
                        >
                          View Profile
                        </button>
                      </Link>
                    </div>

                    <button
                      className="example_btn pkg_btn"
                      onClick={() => setshow(true)}
                    >
                      Example
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{ background: `${active ? "rgba(0, 0, 0, 0.628)" : "none"}` }}
        className={`new_order_editor ${show ? "active_3" : ""}`}
      >
        <div
          style={{ width: "80%" }}
          className={`side_bar_example`}
          ref={sidebarRef}
        >
          <div className="d-flex align-items-center">
            <button className="btn_close" onClick={() => setshow(false)}>
              X
            </button>
            <h4 className="new_order_head p-0 my-0 mx-2">
              Example of Price Package
            </h4>
          </div>
          <hr />
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-lg-4 col-md-4 col-12 p-2">
              <div
                className="package_main"
                style={{ padding: "2rem 1rem 3.5rem 1rem" }}
              >
                <h3>Starter</h3>
                <div style={{ width: "30px", border: "1px solid gray" }}></div>
                <h1 className="my-3">£4.99/Hour</h1>
                <h4 className="my-3">Offers Includes</h4>
                <ul>
                  <li>
                    <FaCheck /> 1 hour Duration
                  </li>
                  <li>
                    <FaCheck /> 50+ Edited Photos
                  </li>
                  <li>
                    <FaCheck /> 20 Downloadable Photos
                  </li>
                  <li>
                    <FaCheck /> No Revisions
                  </li>
                </ul>
                <button className="btn_package">Book Now</button>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 p-2">
              <div
                style={{ background: "#414040", color: "white" }}
                className="package_main"
              >
                <h3>pro</h3>
                <div style={{ width: "30px", border: "1px solid gray" }}></div>
                <h1 className="my-3">£4.99/Hour</h1>
                <h4 className="my-3">Offers Includes</h4>
                <ul>
                  <li>
                    <FaCheck /> 1 hour Duration
                  </li>
                  <li>
                    <FaCheck /> 50+ Edited Photos
                  </li>
                  <li>
                    <FaCheck /> 20 Downloadable Photos
                  </li>
                  <li>
                    <FaCheck /> No Revisions
                  </li>
                </ul>
                <button className="btn_package1">Book Now</button>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 p-2">
              <div
                className="package_main"
                style={{ padding: "2rem 1rem 3.5rem 1rem" }}
              >
                <h3>Business</h3>
                <div style={{ width: "30px", border: "1px solid gray" }}></div>
                <h1 className="my-3">£4.99/Hour</h1>
                <h4 className="my-3">Offers Includes</h4>
                <ul>
                  <li>
                    <FaCheck /> 1 hour Duration
                  </li>
                  <li>
                    <FaCheck /> 50+ Edited Photos
                  </li>
                  <li>
                    <FaCheck /> 20 Downloadable Photos
                  </li>
                  <li>
                    <FaCheck /> No Revisions
                  </li>
                </ul>
                <button className="btn_package">Book Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Priceing;
