import React, { useEffect, useState } from "react";
import { FaCalendarAlt, FaRegEye} from "react-icons/fa";
import NewOrderEdit from "./NewOrderEdit";
import Menu from "@mui/material/Menu";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { CgSortAz } from "react-icons/cg";
import completeOrder from "../../asset/icons/completeOrder.png";
import { useAuth } from "../../Context/ContextProvider";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import SubmitDataLink from "./OrderStatus/SubmitDataLink";
// Inspired by blueprintjs
function CompleteOrder() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [show1, setshow1] = useState({
    await_data: false,
    client_review: false,
    revision_req: false,
    orignal_daat_req: false,
    orignal_data_submit: false,
  });
  const { getOrder, orderData, buttonLoding, orderStatus, orderStatusData } =
    useAuth();

  const [pagination, setPagination] = useState({
    entry: 10,
    page: 1,
    id: "completed",
    first_name: "",
    order_number: "",
    total: "",
    order_status: "",
    added_date: "",
    update_date: "",
  });
  useEffect(() => {
    getOrder(pagination);
    orderStatus();
    // eslint-disable-next-line
  }, []);
  const handlePageChange = (event, value) => {
    setPagination({ ...pagination, page: value });
    getOrder({ ...pagination, page: value }); // Use the new page number provided by MUI
    // Perform your data fetching or state update here
  };
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [plus, setPlus] = useState([]);
  const toggleSubtable = (idx) => {
    setPlus((item) => {
      const isVisible = item.includes(idx);
      if (isVisible) {
        return item.filter((i) => i !== idx);
      } else {
        return [...item, idx];
      }
    });
  };
  const [data, setData] = useState({});
  const [orderServiceId, setOrderServiceId] = useState();
  const getStatusClass = (status) => {
    let reStatus = determineOrderStatus(status);
    switch (status?.orderService ? reStatus : status) {
      case "Confirmed":
        return "status-confirmed";
      case "Awaiting Data":
        return "status-awaiting";
      case "Client Reviewing":
        return "status-reviewing";
      case "Partially Reviewing":
        return "status-reviewing";
      case "Revision Requested":
        return "status-revision";
      case "Required Original Data":
        return "status-original-requested";
      case "Original Data Submitted":
        return "status-original-submitted";
      case "Completed":
        return "status-completed";
      case "Partially Completed":
        return "status-completed";
      default:
        return "";
    }
  };
  function determineOrderStatus(order) {
    if (!order || !order.orderService || !Array.isArray(order.orderService)) {
      return "Invalid order";
    }
    const serviceStatuses = order.orderService.map(
      (service) => service.orderStatus.status_name
    );
    const uniqueStatuses = new Set(serviceStatuses);

    if (uniqueStatuses.size === 1) {
      return serviceStatuses[0];
    }

    if (serviceStatuses.includes("Completed")) {
      return "Partially Completed";
    }

    if (serviceStatuses.includes("Client Reviewing")) {
      return "Partially Reviewing";
    }

    return "Multiple Different Statuses";
  }
  const [show, setshow] = useState(false);
  const [rating, setRating] = useState();
  console.log("rating", rating)
  const handleDateChange = (date) => {
    const adjustedDate = dayjs(date[0]).format("YYYY-MM-DD");
    const adjustedDate1 = dayjs(date[1]).format("YYYY-MM-DD");
    if(adjustedDate!=="Invalid Date" && adjustedDate1!=="Invalid Date"){
      setPagination({
        ...pagination,
        added_date: adjustedDate + " to " + adjustedDate1,
      });
    }
    else{
      setPagination({
        ...pagination,
        added_date: adjustedDate ,
      });
    }
  };
  const getLatestServiceDate = (orderServices) => {
    if (!orderServices || orderServices.length === 0) return null;
    const today = new Date();
    // Separate past and future dates
    const pastDates = [];
    const futureDates = [];
    orderServices.forEach((service) => {
      const date = new Date(service.order_delivered_date);
      console.log("newDate", date)

      if (date >= today) {
        futureDates.push(date);
      } else {
        pastDates.push(date);
      }
    });
    if (futureDates.length > 0) {
      // Get the nearest upcoming date
      const nearestDate = new Date(Math.min(...futureDates));
      return nearestDate.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
    } else if (pastDates.length > 0) {
      // Get the latest past date
      const latestPastDate = new Date(Math.max(...pastDates));
      return latestPastDate.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
    }
    return null;
  };
  const getStatusColor = (remainingDays) => {
    // if (remainingDays <= 0) return "danger"; // Past due
    // if (remainingDays <= 2) return "warning"; // 2 days or less
    return "normal"; // More than 2 days
  };
  const calculateRemainingDays = (date) => {
    const today = new Date();
    const serviceDate = new Date(date);
    const timeDiff = serviceDate - today;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff;
  };
  return (
    <>
      <div className="order_page">
        {!show1?.await_data && <>
          <div className="new_order_nav">
            <div>
              <h4 className="d-flex align-items-center">
                {" "}
                <img
                  src={completeOrder}
                  alt=""
                  style={{ marginRight: "11px", width: "22px", height: "24px" }}
                />
                Completed Orders{" "}
              </h4>
            </div>
          </div>
          <div className="table_box">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Order Id
                </label>
                <div className="new_order_inps">
                  <input
                    type="text"
                    value={pagination?.order_number}
                    onChange={(e) =>
                      setPagination({
                        ...pagination,
                        order_number: e.target.value,
                      })
                    }
                    placeholder="Order ID"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Order Status
                </label>
                <div className="new_order_inps">
                  {/* <select
                    value={pagination?.order_status}
                    onChange={(e) =>
                      setPagination({
                        ...pagination,
                        order_status: e.target.value,
                      })
                    }
                    name=""
                    id=""
                  >
                    <option hidden>Select Order Status</option>
                    {orderStatusData?.completed?.map((item) => {
                      return (
                        <option value={item?.code}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select> */}
                   <input
                    type="text"
                    name=""
                    id=""
                    readOnly
                    value="Completed"
                    
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Date Added
                </label>
                <div className="new_order_inps">
                  <input
                    type="text"
                    name=""
                    id=""
                    readOnly
                    value={pagination?.added_date}
                    placeholder="Select date"
                  />
                  <span onClick={handleClick}>
                    <FaCalendarAlt />
                  </span>
                </div>
              </div>
              <Menu
                id="basic-menu"
                a
                anchorEl={anchorEl}
                style={{ marginLeft: "-30px", marginTop: "2rem" }}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <div style={{ padding: ".5rem" }}>
                  <div className="row">
                    <div className="col-12">
                      <div className="row" style={{ margin: "1rem" }}>
                        <div
                          style={{
                            background: "#494949",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            borderRadius: "5px",
                          }}
                          className="col-6 py-2"
                        >
                          <span>From</span>
                        </div>
                        <div
                          style={{
                            background: "#F0F0F0",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="col-6 py-2"
                        >
                          <span>To</span>
                        </div>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={[
                            "DateRangeCalendar",
                            "DateRangeCalendar",
                          ]}
                        >
                          <DemoItem>
                            <DateRangeCalendar
                              onChange={handleDateChange}
                              calendars={1}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
              </Menu>

              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Customer Name
                </label>
                <div className="new_order_inps">
                  <input
                    type="text"
                    value={pagination?.first_name}
                    name=""
                    id=""
                    placeholder="Customer Name"
                    onChange={(e) =>
                      setPagination({
                        ...pagination,
                        first_name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Amount
                </label>
                <div className="new_order_inps">
                  <input
                    type="text"
                    value={pagination?.total}
                    onChange={(e) =>
                      setPagination({ ...pagination, total: e.target.value })
                    }
                    placeholder="Select Amount"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Date Modification
                </label>
                <div className="new_order_inps">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Select date"
                    readOnly
                  />
                  <span onClick={handleClick}>
                    <FaCalendarAlt />
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <label>Show entries:</label>
                <select
                  value={pagination?.entry}
                  style={{
                    width: "200px",
                    border: "1px solid lightgray",
                    padding: ".5rem",
                    borderRadius: "5px",
                    outline: "none",
                  }}
                  onChange={(e) => {
                    if (orderData?.meta?.total < e.target.value) {
                      setPagination({
                        ...pagination,
                        entry: e.target.value,
                        page: 1,
                      });
                      getOrder({
                        ...pagination,
                        entry: e.target.value,
                        page: 1,
                      });
                    } else {
                      setPagination({ ...pagination, entry: e.target.value });
                      getOrder({ ...pagination, entry: e.target.value });
                    }
                  }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                </select>
              </div>
              <div>
                <button
                  onClick={() => {
                    setPagination({
                      entry: 10,
                      page: 1,
                      id: "completed",
                      first_name: "",
                      order_number: "",
                      total: "",
                      order_status: "",
                      added_date: "",
                      update_date: "",
                    });
                    getOrder({
                      entry: 10,
                      page: 1,
                      id: "completed",
                      first_name: "",
                      order_number: "",
                      total: "",
                      order_status: "",
                      added_date: "",
                      update_date: "",
                    });
                  }}
                  style={{
                    padding: ".5rem 1rem",
                    background: "#494949",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    marginRight: "1rem",
                  }}
                >
                  Clear
                </button>
                <button
                  style={{
                    padding: ".5rem 1rem",
                    background: "#494949",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                  }}
                  disabled={
                    pagination?.first_name ||
                      pagination.order_number ||
                      pagination?.order_status ||
                      pagination?.total
                      ? false
                      : true
                  }
                  onClick={() => {
                    if (
                      pagination?.first_name ||
                      pagination.order_number ||
                      pagination?.order_status ||
                      pagination?.total ||
                      pagination?.added_date ||
                      pagination?.update_date
                    ) {
                      getOrder(pagination);
                    }
                  }}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="confirm_order_table">
              <table className="table nestedTable1 custom-padding table-sm my-3 ">
                <thead>
                  <tr style={{ position: "sticky", top: "0", width: "100%" }}>
                    <th scope="col" style={{ width: "2%" }}>
                      {" "}
                    </th>
                    <th scope="col" className="oder_th">
                      Order ID <CgSortAz />
                    </th>
                    <th scope="col" className="oder_th">
                      Order Date <CgSortAz />
                    </th>
                    <th scope="col" className="oder_th" >
                      Completed Date
                    </th>
                    <th scope="col" className="oder_th">
                      Client Name
                    </th>
                    <th scope="col" className="oder_th">
                      Location
                    </th>
                    <th scope="col" className="oder_th">
                      Order Amount
                    </th>
                    <th scope="col" className="oder_th text-center" >
                      Order Status
                    </th>
                    <th scope="col" className="oder_th text-center" >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {buttonLoding ? (
                    <tr>
                      <td colSpan={10}>
                        <div style={{ height: "400px", width: "100%" }}>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                          <p
                            style={{ height: "50px" }}
                            className="placeholder-glow row"
                          >
                            <span className="placeholder col-12 h-100 rounded"></span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {orderData?.data?.length ? (
                        orderData?.data?.map((item, i) => {
                          const latestServiceDate = getLatestServiceDate(item?.orderService);
                          const remainingDays = latestServiceDate ? calculateRemainingDays(latestServiceDate) : null;
                          const statusColor = getStatusColor(remainingDays);
                          return (
                            <>
                              {item?.orderService?.length === 1 ? (
                                <>
                                  {item?.orderService?.map((items) => {
                                    const remainingDays = items?.service_date
                                      ? calculateRemainingDays(
                                        items?.service_date
                                      )
                                      : null;
                                    const statusColor =
                                      getStatusColor(remainingDays);
                                    return (
                                      <tr>
                                        <td colSpan={9} className="p-0">
                                          <table className="table nestedTable table-sm m-0 ">
                                            <tbody>
                                              <tr>
                                                <th
                                                  style={{
                                                    paddingLeft: "1.5rem",
                                                    width: "1%",
                                                  }}
                                                  scope="row"
                                                >
                                                  {" "}
                                                </th>
                                                <td>{item?.order_number}</td>
                                                <td>
                                                  {
                                                    item?.order_confirmed_date?.split(
                                                      " "
                                                    )[0]
                                                  }
                                                  <br />
                                                  {item?.date}
                                                </td>
                                                <td>
                                                  <span
                                                    className={`service-date ${statusColor}`}
                                                  >
                                                    {items?.order_delivered_date?.split(
                                                      " "
                                                    )[0] || "N/A"}
                                                  </span>

                                                </td>
                                                <td>
                                                  {item?.shippingCharges?.name
                                                    ?.split(" ")
                                                    .filter(
                                                      (item, index, arr) =>
                                                        arr.indexOf(item) ===
                                                        index
                                                    )
                                                    .join(" ")}
                                                </td>
                                                <td>
                                                  {
                                                    item?.shippingCharges
                                                      ?.city?.name
                                                  }{" "}
                                                </td>
                                                <td>£{items?.net_total}</td>
                                                {/* <td >
<span className="new_confirm">Confirmed</span>
</td> */}
                                                <td
                                                  style={{

                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <span
                                                    className={getStatusClass(
                                                      items?.orderStatus
                                                        ?.status_name
                                                    )}
                                                  >
                                                    {
                                                      items?.orderStatus
                                                        ?.status_name
                                                    }
                                                  </span>
                                                </td>

                                                <td style={{}}>
                                                  <ul className="New_order_icons">
                                                    <li
                                                      onClick={() => {
                                                        setData(item);
                                                        setshow(true);
                                                        setRating(
                                                          items?.orderReviews
                                                        );
                                                      }}
                                                    >
                                                      View{" "}
                                                      <FaRegEye className="ms-1" />
                                                    </li>
                                                    <li style={{ borderRadius: "0 4px 4px 0", cursor: `${items?.plan?.type === "service" ? "pointer" : "not-allowed"}`, background: `${items?.plan?.type === "service" ? "#efefef" : "#949494"}` }}
                                                      onClick={() => {
                                                        setshow1({
                                                          ...show1,
                                                          await_data: true,
                                                        });
                                                        setData(item);
                                                        setOrderServiceId(
                                                          items?.id
                                                        );

                                                      }}
                                                    >
                                                      View Received Data <FaRegEye className="ms-1" />
                                                    </li>
                                                  </ul>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <th scope="row" style={{ width: "1%" }}>
                                      {" "}
                                      {plus.includes(i) ? (
                                        <FaSquareMinus
                                          onClick={() => toggleSubtable(i)}
                                        />
                                      ) : (
                                        <FaSquarePlus
                                          onClick={() => toggleSubtable(i)}
                                        />
                                      )}
                                    </th>
                                    <td>{item?.order_number}</td>
                                    <td>
                                      {
                                        item?.order_confirmed_date?.split(
                                          " "
                                        )[0]
                                      }
                                      <br />
                                      {item?.date}
                                    </td>
                                    <td><span
                                      className={`service-date ${statusColor}`}
                                    >
                                      {latestServiceDate || "N/A"}
                                    </span></td>
                                    <td>
                                      {item?.shippingCharges?.name
                                        ?.split(" ")
                                        .filter(
                                          (item, index, arr) =>
                                            arr.indexOf(item) === index
                                        )
                                        .join(" ")}
                                    </td>
                                    <td>{item?.shippingCharges?.location} </td>
                                    <td>£{item?.subtotal}</td>
                                    <td
                                      style={{

                                        textAlign: "center",
                                      }}
                                    >
                                      <span className={getStatusClass(item)}>
                                        {determineOrderStatus(item)}
                                      </span>
                                    </td>

                                    <td style={{}}>
                                      <ul className="New_order_icons">
                                        <li
                                          onClick={() => {
                                            setData(item);
                                            setshow(true);
                                          }}
                                        >
                                          View <FaRegEye className="ms-1" />
                                        </li>
                                        <li
                                          style={{ borderRadius: "0 4px 4px 0", cursor: `${"not-allowed"}`, background: `${"#949494"}` }}
                                        >
                                          View Received Data <FaRegEye className="ms-1" />
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                  {plus.includes(i) &&
                                    item?.orderService?.map((items) => {
                                      const remainingDays = items?.service_date
                                        ? calculateRemainingDays(
                                          items?.service_date
                                        )
                                        : null;
                                      const statusColor =
                                        getStatusColor(remainingDays);
                                      return (
                                        <tr>
                                          <td colSpan={9} className="p-0">
                                            <table className="table nestedTable table-sm m-0 ">
                                              <tbody>
                                                <tr>
                                                  <th
                                                    style={{
                                                      paddingLeft: "1.5rem",
                                                      width: "1%",
                                                    }}
                                                    scope="row"
                                                  >
                                                    {" "}
                                                  </th>
                                                  <td>{item?.order_number}</td>
                                                  <td>
                                                    {
                                                      item?.order_confirmed_date?.split(
                                                        " "
                                                      )[0]
                                                    }
                                                    <br />
                                                    {item?.date}
                                                  </td>
                                                  <td>
                                                    <span
                                                      className={`service-date ${statusColor}`}
                                                    >
                                                      {items?.order_delivered_date?.split(
                                                        " "
                                                      )[0] || "N/A"}
                                                    </span>

                                                  </td>
                                                  <td>
                                                    {item?.shippingCharges?.name
                                                      ?.split(" ")
                                                      .filter(
                                                        (item, index, arr) =>
                                                          arr.indexOf(item) ===
                                                          index
                                                      )
                                                      .join(" ")}
                                                  </td>
                                                  <td>
                                                    {
                                                      item?.shippingCharges
                                                        ?.city?.name
                                                    }{" "}
                                                  </td>
                                                  <td>£{items?.net_total}</td>

                                                  <td
                                                    style={{

                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <span
                                                      className={getStatusClass(
                                                        items?.orderStatus
                                                          ?.status_name
                                                      )}
                                                    >
                                                      {
                                                        items?.orderStatus
                                                          ?.status_name
                                                      }
                                                    </span>
                                                  </td>

                                                  <td style={{}}>
                                                    <ul className="New_order_icons">
                                                      <li
                                                        onClick={() => {
                                                          setData(item);
                                                          setshow(true);
                                                          setRating(
                                                            items?.orderReviews
                                                          );
                                                        }}
                                                      >
                                                        View{" "}
                                                        <FaRegEye className="ms-1" />
                                                      </li>
                                                      <li style={{ borderRadius: "0 4px 4px 0", cursor: `${items?.plan?.type === "service" ? "pointer" : "not-allowed"}`, background: `${items?.plan?.type === "service" ? "#efefef" : "#949494"}` }}
                                                        onClick={() => {
                                                          setshow1({
                                                            ...show1,
                                                            await_data: true,
                                                          });
                                                          setData(item);
                                                          setOrderServiceId(
                                                            items?.id
                                                          );

                                                        }}
                                                      >
                                                        View Received Data <FaRegEye className="ms-1" />
                                                      </li>
                                                    </ul>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                </>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} style={{ border: "none" }}>
                            <h5 className="text-center">No Order Found !</h5>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="paginations d-flex justify-content-end my-3">
              <Stack spacing={2}>
                <Pagination
                  count={orderData?.meta?.last_page}
                  onChange={handlePageChange}
                  shape="rounded"
                />
              </Stack>
            </div>
            <NewOrderEdit
              data={data}
              setData={setData}
              show={show}
              setshow={setshow}
              rating={rating}
            />

          </div>
        </>}
        {show1?.await_data && (
          <SubmitDataLink
            order={data}
            show={show1}
            setShow={setshow1}
            orderServiceId={orderServiceId}
          />
        )}
      </div>
    </>
  );
}

export default CompleteOrder;
