import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import {
  FaArrowDown,
  FaArrowUp,
  FaCalendarAlt,
  FaLongArrowAltLeft,
  FaRegCalendarAlt,
  FaRegEye,
} from "react-icons/fa";

import NewOrderEdit from "./NewOrderEdit";

import Menu from "@mui/material/Menu";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { CgSortAz } from "react-icons/cg";
import ConfirmOrderMUI from "./ConfirmOrderMUI";
import { CiSearch } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import CurrentDate from "../../Pages/CurrentDate";
import { LineChart } from "@mui/x-charts/LineChart";
import { MdDashboard } from "react-icons/md";
import { useAuth } from "../../Context/ContextProvider";
import dayjs from "dayjs";
const uData = [15, 30, 20, 27, 18, 23, 34];
const pData = [24, 13, 15, 39, 18, 38, 43];
const xLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"];
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
function DashboardBody() {
  const { vendorDash, vendorDashboard, buttonLoding } = useAuth();
  useEffect(() => {
    vendorDashboard();
  }, []);
  console.log("vendorDash", vendorDash);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const [show, setshow] = useState(false);

  const getStatusClass = (status) => {
    let reStatus = determineOrderStatus(status);
    switch (status?.orderService ? reStatus : status) {
      case "Confirmed":
        return "status-confirmed";
      case "Awaiting Data":
        return "status-awaiting";
      case "Client Reviewing":
        return "status-reviewing";
      case "Partially Reviewing":
        return "status-reviewing";
      case "Revision Requested":
        return "status-revision";
      case "Required Original Data":
        return "status-original-requested";
      case "Original Data Submitted":
        return "status-original-submitted";
      case "Completed":
        return "status-completed";
      case "Partially Completed":
        return "status-completed";
      case "Refund":
        return "status-refund";
      case "Pending":
        return "status-pending";
      case "Cancelled":
        return "status-cancel";
        case 'Ready For Complete':
          return 'status-completed';
          case "Refund Requested":
            return "status-refund";
      default:
        return "";
    }
  };

  function determineOrderStatus(order) {
    console.log("orderss", order);
    if (!order || !order.orderStatus || !Array.isArray(order.orderService)) {
      return "Invalid order";
    }

    const statusPriority = {
      Completed: 1,
      "Client Reviewing": 2,
      Confirmed: 3,
      "Awaiting Data": 4,
      "Ready For Complete": 5,
    };

    const serviceStatuses = order?.orderService?.map(
      (service) => service.orderStatus.status_name
    );

    console.log("serviceStatuses", serviceStatuses);
    const uniqueStatuses = new Set(serviceStatuses);
    console.log("uniqueStatuses", uniqueStatuses);
    if (uniqueStatuses.size === 1) {
      return serviceStatuses[0];
    }

    if (serviceStatuses.includes("Completed")) {
      return "Partially Completed";
    }
    if (serviceStatuses.includes("Refund")) {
      return "Refund";
    }
    if (serviceStatuses.includes("Pending")) {
      return "Pending";
    }

    if (serviceStatuses.includes("Client Reviewing")) {
      return "Partially Reviewing";
    }

    return "Multiple Different Status";
  }
  const calculateRemainingDays = (date) => {
    const today = new Date();
    const serviceDate = new Date(date);
    const timeDiff = serviceDate - today;
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff;
  };

  const getStatusColor = (remainingDays) => {
    if (remainingDays <= 0) return "danger"; // Past due
    if (remainingDays <= 2) return "warning"; // 2 days or less
    return "normal"; // More than 2 days
  };
  const getLatestServiceDate = (orderServices) => {
    if (!orderServices || orderServices.length === 0) return null;

    const today = new Date();

    // Separate past and future dates
    const pastDates = [];
    const futureDates = [];

    orderServices.forEach((service) => {
      const date = new Date(service?.service_date);
      if (date >= today) {
        futureDates.push(date);
      } else {
        pastDates.push(date);
      }
    });

    if (futureDates.length > 0) {
      // Get the nearest upcoming date
      const nearestDate = new Date(Math.min(...futureDates));
      return nearestDate.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
    } else if (pastDates.length > 0) {
      // Get the latest past date
      const latestPastDate = new Date(Math.max(...pastDates));
      return latestPastDate.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
    }

    return null;
  };
  const [data, setData]=useState({})

  const [pagination, setPagination] = useState({
   
   
    first_name: "",
    
   
   
    added_date: "",
    status_code:[],
    
  })
  useEffect(() => {
    vendorDashboard(pagination)
  }, [])
  const handleDateChange = (date) => {
    const adjustedDate = dayjs(date[0]).format('YYYY-MM-DD');
    const adjustedDate1 = dayjs(date[1]).format('YYYY-MM-DD');
    console.log('dates',adjustedDate + " to " + adjustedDate1);
    setPagination({ ...pagination, added_date: adjustedDate + " to " + adjustedDate1 })
   
    vendorDashboard({ ...pagination, added_date: adjustedDate + "to" + adjustedDate1 })
  }
  return (
    <>
      <div style={{ opacity: `${show ? ".7" : "1"}` }}>
        <>
          <div className="new_order_nav">
            <div>
              <h4 className="d-flex align-items-ceter">
                <MdDashboard className="me-2" /> Overviews
              </h4>
              {/* <p className="m-0 new_order_nav_p">Thursday,23 November ,2023</p> */}
              <CurrentDate />
            </div>
          </div>
          <div className="row dashboard_head">
            <div className="col-lg-4 col-md-6 col-12 my2">
              <div className="row">
                <div className="col-lg-5 col-12 my-2">
                  <div className="dash_inps">
                    <CiSearch />
                    <input
                      type="search"
                      name=""
                      id=""
                      onChange={(e)=>{
                        setTimeout(() => {
                          vendorDashboard({ ...pagination, first_name: e.target.value })
                        }, 2000);
                      }} 
                      placeholder="Search..."
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12 my-2">
                  <div className="dash_date ">
                    <h1>Date</h1>
                    <FaCalendarAlt
                      onClick={handleClick}
                      style={{ cursor: "pointer", color: "#494949" }}
                    />
                  </div>
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  style={{ marginLeft: "-30px", marginTop: "2rem" }}
                  open={open1}
                  onClose={handleClose1}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="dashboard_date"
                >
                  <div
                    style={{
                      width: "400px",
                      height: "450px",
                      padding: ".5rem",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <div className="row" style={{ margin: "1rem" }}>
                          <div
                            style={{
                              background: "#494949",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "white",
                              borderRadius: "5px",
                            }}
                            className="col-6 py-2"
                          >
                            <span>From</span>
                          </div>
                          <div
                            style={{
                              background: "#F0F0F0",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="col-6 py-2"
                          >
                            <span>To</span>
                          </div>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={[
                              "DateRangeCalendar",
                              "DateRangeCalendar",
                            ]}
                          >
                            <DemoItem>
                              <DateRangeCalendar onChange={handleDateChange} calendars={1} />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                  
                    </div>
                  </div>
                </Menu>
              </div>
            </div>
          </div>

          <div className="dash">
            <div className="dash_chart">
              <div className="row m-0 ">
                <div className="col-lg-9 col-12 my-2">
                  <div className="dash_report">
                    <LineChart
                      height={300}
                      series={[
                        { data: pData, label: "Orders", color: "#D8CAFF" },
                        { data: uData, label: "Revenue", color: "#C1FFDE" },
                      ]}
                      xAxis={[{ scaleType: "point", data: xLabels }]}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-12 my-2">
                  <div className="dash_total">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="dash_tot">
                          <p>Pending Orders</p>
                          <div className="dash_price">
                            <h3>{vendorDash?.pendingOrders}</h3>
                            <p
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              + 14%
                              <FaArrowDown />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <div className="dash_tot">
                          <p>Complete Orders</p>
                          <div className="dash_price">
                            <h3>{vendorDash?.completedOrders}</h3>
                            <p
                              style={{
                                color: "#22C55E",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              + 36%
                              <FaArrowUp />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 ">
                        <div className="dash_tot">
                          <p>Total Earings</p>
                          <div className="dash_price">
                            <h3>$ {vendorDash?.totalEarnings}</h3>
                            <p
                              style={{
                                color: "#22C55E",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              + 36%
                              <FaArrowUp />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-12 my-2">
                <div className="table_box">
                  <div
                    className="confirm_order_table"
                    style={{ height: "90%" }}
                  >
                    <table className="table table-sm mb-3 ">
                      <thead>
                        <tr
                          style={{
                            position: "sticky",
                            top: "0",
                            width: "100%",
                            borderColor: "transparent",
                          }}
                        >
                          <th scope="col">
                            {" "}
                            {/* <BpCheckbox /> */}
                          </th>
                          <th scope="col" className="oder_th">
                            Order ID <CgSortAz />
                          </th>
                          <th scope="col" className="oder_th">
                            Order Date <CgSortAz />
                          </th>
                          <th scope="col" className="oder_th">
                            Due Order <CgSortAz />
                          </th>
                          <th scope="col" className="oder_th">
                            Client Name
                          </th>
                          <th scope="col" className="oder_th">
                            Location
                          </th>
                          <th scope="col" className="oder_th">
                            Order Amount
                          </th>

                          <th scope="col" className="oder_th ">
                            Order Status
                          </th>
                          <th scope="col" className="oder_th text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {buttonLoding ? (
                          <tr>
                            <td colSpan={10}>
                              <div style={{ height: "400px", width: "100%" }}>
                                <p
                                  style={{ height: "50px" }}
                                  className="placeholder-glow row"
                                >
                                  <span className="placeholder col-12 h-100 rounded"></span>
                                </p>
                                <p
                                  style={{ height: "50px" }}
                                  className="placeholder-glow row"
                                >
                                  <span className="placeholder col-12 h-100 rounded"></span>
                                </p>
                                <p
                                  style={{ height: "50px" }}
                                  className="placeholder-glow row"
                                >
                                  <span className="placeholder col-12 h-100 rounded"></span>
                                </p>
                                <p
                                  style={{ height: "50px" }}
                                  className="placeholder-glow row"
                                >
                                  <span className="placeholder col-12 h-100 rounded"></span>
                                </p>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {vendorDash?.orders?.length ? (
                              vendorDash?.orders?.map((item) => {
                                const latestServiceDate = getLatestServiceDate(
                                  item?.orderService
                                );
                                const remainingDays = latestServiceDate
                                  ? calculateRemainingDays(latestServiceDate)
                                  : null;
                                const statusColor = getStatusColor(remainingDays);
                                return (
                                  <tr>
                                    <th scope="row">
                                      {" "}
                                      {/* <BpCheckbox /> */}
                                    </th>
                                    <td>{item?.order_number}</td>
                                    <td>
                                      {
                                        item?.order_confirmed_date?.split(
                                          " "
                                        )[0]
                                      }
                                      <br />
                                      {item?.date}
                                    </td>
                                    <td>  <span
                                      className={`service-date ${statusColor}`}
                                    >
                                      {latestServiceDate || "N/A"}
                                    </span></td>
                                    <td>
                                      {item?.shippingCharges?.name
                                        ?.split(" ")
                                        .filter(
                                          (item, index, arr) =>
                                            arr.indexOf(item) === index
                                        )
                                        .join(" ")}
                                    </td>
                                    <td>{item?.shippingCharges?.location} </td>
                                    <td>£ {item?.total}</td>
                                    <td>
                                      <span className={getStatusClass(item)}>
                                        {determineOrderStatus(item)}
                                      </span>
                                    </td>

                                    <td>
                                      <ul className="New_order_icons">
                                        <li onClick={() => {setshow(true);
                                          setData(item)
                                        }}>
                                          View <FaRegEye className="ms-1" />
                                        </li>

                                       
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={10} style={{ border: "none" }}>
                                  <h5 className="text-center">
                                    No Order Found !
                                  </h5>
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                    
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      <NewOrderEdit show={show} setshow={setshow} data={data} />
    </>
  );
}

export default DashboardBody;
