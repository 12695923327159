import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
let slidesToShow = 4;

const carouselProperties = {

  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: false,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

const MultiItemCarousel = ({image,handleVideoClick}) => {
  return (
    <div style={{ margin: '16px 0' }} className='carousel_tumbnil'>

      <Slider {...carouselProperties}>
        {image?.map((item) => (
          <Card handleVideoClick={handleVideoClick} item={item} />
        ))}
      </Slider>
    </div>
  );
};

const Card = ({ item,handleVideoClick }) => {
  return (
    <div className='me-2'>
      <img
        className='multi__image'
        src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpafp1s6hK4Cko3BoYMfE-mewRWB7TRZYnQw&s"}
        onClick={()=>{handleVideoClick(item?.url)}}
        alt=''
        style={{
        width: '100%',
          // height: '300px',
          objectFit: 'contain',
          marginBottom: '10px',
          borderRadius:"5px"
        }}
      />
      
    </div>
  );
};

export default MultiItemCarousel;