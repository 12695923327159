import React from 'react'
import img from '../../asset/Rectangle 1464.png'
import img1 from '../../asset/Rectangle 1465.png'
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';
function Banner() {
    AOS.init({
        duration: 800, // Animation duration
        easing: 'ease-in-out', // Easing option
        once: false // Whether animation should only happen once
      });
    return (
        <>
       
        <div className="row sec_about"  data-aos="fade-up">
            <div className="col-12">
            <div className='our_portfolio about_us my-5 mt-3'>
            <button className='about_btns'>ABOUT US</button>
            <h1 className='text-center' style={{fontSize:"24px", fontWeight:"500", lineHeight:"32px", color:"#494949"}}>WE ONLY HAVE ONE GOAL IN MIND to spark new connections<br />
            between ambitious brands and their customers.</h1>
            <p className="aboutDesc">
            In today's visually driven world, Photography Studio Hub is stepping up to reshape the photography landscape. We're more than just a booking platform; we're a vibrant marketplace connecting clients with top-tier photography talent, simplifying the process of finding and booking services, and empowering photographers to thrive.
            </p>
            <div className='row mt-4 mb-0 w-100'>
                <div className='col-lg-6 col-md-12 col-12'>
                    <img src={img} alt='...' />
                </div>
                <div className='col-lg-6 col-12 ps-4 justify-content-start mt-lg-0 mt-5' >
                  <div className="content_heading py-3">
                    <h2>
                    Our Vision
                    </h2>
                    <p>
                    We envision a future where booking a photoshoot is as effortless as ordering your favorite meal online. We're building a user-friendly platform that streamlines the booking process, provides transparency, and fosters a thriving community of photographers and clients.
                    </p>
                    
                    <h2>
                    What We Offer
                    </h2>
                    <p className='mb-4'>
                    <b>For Clients:</b> Say goodbye to endless searching and confusing quotes. Our marketplace offers a curated selection of talented photographers, models, and studios, allowing you to browse portfolios, compare packages, and book services with ease.
                    </p>
                    <p className='mt-2'>
                
                   <b> For Photographers:</b> Showcase your work, manage your availability, and reach a wider audience. Our platform empowers you to focus on what you do best - capturing stunning images - while we handle the administrative side.
                    </p>

                  </div>
                </div>
                

                <div className='col-lg-6 col-12 pe-4 justify-content-start order-lg-1 order-2 ' style={{marginTop:"2.5rem"}}>
                  <div className="content_heading py-3">
                    <h2 className='mb-2'>
                    Our Mission
                    </h2>
                    <p className='about_mission'>
                    At Photography Studio Hub, our mission is to:
                    </p>
                <ul>
                    <li>Facilitate seamless bookings through an intuitive and efficient platform.</li>
                    <li>Empower photographers, models, and studios to grow their businesses.</li>
                    <li>Enhance user experience for both clients and vendors.</li>
                    <li>Ensure transparency throughout the booking process.</li>
                    <li>Generate revenue for all stakeholders through a sustainable model.</li>
                    <li>Expand market reach for photography services.</li>
                    <li>Promote collaboration within the photography community.</li>
                    <li>Drive innovation in the industry through technology.</li>
                </ul>
                    
                    <h2>
                    Join the Hub
                    </h2>
                    <p >
                    We're passionate about transforming the way photography services are booked, managed, and delivered. Whether you're a seasoned professional or just starting out, Photography Studio Hub is your gateway to a vibrant community and endless possibilities. Join us today and be part of the future of photography!
                    </p>
                   

                  </div>
                </div>
                <div className='col-lg-6 col-md-12 col-12 order-lg-2 order-1'style={{marginTop:"2.5rem"}}>
                    <img src={img1} alt='...' />
                </div>
                
            </div>
        </div>
            </div>
        </div>
        </>
    )
}

export default Banner