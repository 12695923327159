import React, { useEffect, useState } from 'react'
import logo from '../../asset/logo (1).png'
import { CgMenuLeft } from "react-icons/cg";
import { Link } from 'react-router-dom';
import img1 from '../../asset/icons/google.png'
import img2 from '../../asset/icons/facebook.png'
import home from '../../asset/icons/home.png'
// import img3 from '../../asset/icons/twitter.png'
// import img4 from '../../asset/icons/apple.png'
import Modal from '@mui/material/Modal';
import Avatar from '@mui/material/Avatar';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { FaAngleDown, FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import { CiUser } from "react-icons/ci";
import img from '../../asset/icons/Frame 1000014326.png'
import dummy from '../../asset/dummy/dummy2.webp'
import { useNavigate } from 'react-router-dom'
import ChildModal from './ChildModal';
import { useAuth } from '../../Context/ContextProvider';
import CustomizedSnackbars from './CustomizedSnackbars';
import Divider from '@mui/material/Divider';
import Loading from './Loading';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
function NavBar() {
  const { venderLogin, socailLogin,
    loginData, loading, setforgetdata, setResendEmail, web_url, dashbord, setLogindata, resendEmail, resendEmailData, buttonLoding,
    webSettingData, cartData, fbAppId, googleClientId } = useAuth()
  const [app_id, setAppId] = useState("")
  const [client_id, setClientId] = useState("")
  useEffect(() => {
    setClientId(googleClientId?.length ? googleClientId[0]?.value : "")
    setAppId(fbAppId?.length ? fbAppId[0]?.value : "")
  }, [fbAppId, googleClientId])

  const navigate = useNavigate()
  const vendor_data = JSON.parse(localStorage.getItem('vendor_data'))
  const [role, setRole] = useState(vendor_data?.data?.role)
  useEffect(() => {
    if (loginData?.data?.token) {
      localStorage.setItem("vendor_data", JSON.stringify(loginData))

      navigate(`${web_url}${loginData?.data?.role}/dashboard`)
      setRole(loginData?.data?.role)
      setLogindata()
      if (dashbord) {
        navigate(`${web_url}${loginData?.data?.role === "vendor" || loginData?.data?.role === "vendor-admin" ? "vendor" : loginData?.data?.role}/dashboard`)
      }
      else {
        navigate(`${web_url}order-summry`)
      }

    }
    // eslint-disable-next-line
  }, [loginData])
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open4 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose4 = () => {
    setAnchorEl(null);
  };
  const [navBar, setNavBar] = useState(false)
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const [anchorElUser1, setAnchorElUser1] = React.useState(null);
  const handleOpenUserMenu1 = (event) => {
    setAnchorElUser1(event.currentTarget);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setResendEmail("")
    setLogindata("")
  };
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    setforgetdata("")
    setLogindata("")
    setOpen2(false)
  }

    ;
  const [fieldErrors, setFieldErrors] = React.useState({})
  const [fieldErrors1, setFieldErrors1] = React.useState({})
  const [data, setdata] = React.useState({
    password: "",
    email: "",
    role: "client"

  })
  const [data1, setdata1] = React.useState({
    password: "",
    email: "",
    role: "vendor"

  })
  const [show, setshow] = useState(true)
  const [show1, setshow1] = useState(true)
  const validateStep = () => {
    let errors = {

    };
    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!data.password) {
      errors.password = "Password is required.";
    }
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  const validateStep1 = () => {
    let errors = {

    };
    if (!data1.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data1.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!data1.password) {
      errors.password = "Password is required.";
    }
    setFieldErrors1(errors);
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = () => {
    if (validateStep()) {
      venderLogin(data)
    }
  };
  const handleSubmit1 = () => {
    if (validateStep1()) {
      venderLogin(data1)

    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseUserMenu1 = () => {
    setAnchorElUser1(null);
  };
  const handleLoginSuccessF = (user) => {
    console.log('Login Success:', user);
    let obj = {
      name: user?.name,
      email: user?.email,
      provider_id: user?.id,
      provider: user?.graphDomain,
      avatar: user?.picture?.data?.url
    }
    socailLogin(obj)
  };
  const handleLoginSuccessG = (user) => {

    let obj = {
      name: user?.name,
      email: user?.email,
      provider_id: user?.sub,
      provider: "google",
      avatar: user?.picture
    }
    console.log('Login Success:', obj);
    socailLogin(obj)
  };


  const handleLoginFailure = (error) => {
    console.log('Login Failed:', error);
  };

  const [navMenu, setNavMenu] = useState(false)
  return (
    <div className='nav_bar my-3'>
      <div className='row'>
        <div className='col-2 col-lg-4  order-2 order-lg-1 d-flex justify-content-start align-items-center'>
          <button className='p-0' onClick={() => setNavBar(true)} >
            <img src={home} alt="" />
          </button>
        </div>
        <div className='home_logo col-lg-4 mb-3 mb-lg-0 col-12 order-1 order-lg-2 d-flex justify-content-center'>
          <Link to={`${web_url}`}>
            <img src={logo} alt='...' />
          </Link>
        </div>
        <div className='col-lg-4 col-10 order-3 d-flex justify-content-end align-items-center'>
          <Box sx={{ flexGrow: 0, padding: "0" }}>
            {webSettingData?.data?.is_marketplace ? <button className='marketPlace' style={{ fontSize: "16px", marginRight: "1rem", padding: "0", color: "#494949", fontWeight: "500" }} onClick={handleOpenUserMenu1}>Marketplace <FaAngleDown /> </button> : ""}
            <Menu
              sx={{ mt: '25px' }}
              id="menu-appbar"
              anchorEl={anchorElUser1}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser1)}
              onClose={handleCloseUserMenu1}
            >

              <MenuItem onClick={() => {
                handleCloseUserMenu1()

              }}>
                <Link style={{ textDecoration: "none" }} to={`${web_url}explor-telent`}>
                  <Typography className='hire_tel' textAlign="center">Hire Talent Services</Typography>
                </Link>
              </MenuItem>
              <hr className='m-0 my-1' style={{ borderColor: "#49494975" }} />
              <MenuItem onClick={() => {
                handleCloseUserMenu1()

              }}>
                <Link style={{ textDecoration: "none" }} to={`${web_url}studio-list/studio`}>
                  <Typography className='hire_tel' textAlign="center">Hire Studio Spaces</Typography>
                </Link>
              </MenuItem>
              <hr className=' my-1 mb-2' style={{ borderColor: "#49494975" }} />
              <Divider />
              <div className="navBar_bar">
                <MenuItem onClick={() => {
                  handleCloseUserMenu1()
                }}
                >
                  <Link style={{ textDecoration: "none", border: "1px solid", padding: "0.35rem 2rem", borderRadius: "4px", background: "#494949", color: "white" }} to={`${web_url}join-our-team`}>
                    <Typography className='JoinHub' style={{ color: "#ececeb", fontSize: "16px ", fontWeight: "700" }} textAlign="center">Join Our Hub</Typography>
                  </Link>
                </MenuItem>
              </div>

            </Menu>
            {vendor_data ? <>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  aria-controls={open4 ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open4 ? 'true' : undefined}
                >
                  <Avatar src={vendor_data?.data?.user?.avatar ? vendor_data?.data?.user?.avatar : dummy} />
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open4}
                onClose={handleClose4}
                onClick={handleClose4}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={handleClose}>
                  <div className='d-flex '>
                    <div >
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        variant="dot"
                        style={{ width: "42px", height: "42px" }}
                      >
                        <Avatar style={{ width: "105%", height: "105%" }} alt="Remy Sharp" src={vendor_data?.data?.user?.avatar ? vendor_data?.data?.user?.avatar : dummy} />
                      </StyledBadge>
                    </div>
                    <div className='mx-2'>
                      <h6 style={{ margin: "0", fontWeight: "600" }}>{vendor_data?.data?.user?.name}</h6>
                      <p style={{ margin: "0" }}>{vendor_data?.data?.user?.email}</p>
                    </div>
                  </div>


                </MenuItem>

                <Divider />
                <MenuItem onClick={() => {
                  navigate(`${web_url}${role === "vendor-admin" || role === "vendor" ? "vendor" : "client"}/dashboard`)
                  handleClose()
                }}>
                  <Link className='comm_a' style={{ textDecoration: "none" }} to={`${web_url}${role === "vendor-admin" || role === "vendor" ? "vendor" : "client"}/dashboard`}>
                    Dashboard
                  </Link>
                </MenuItem>
                <MenuItem onClick={()=>{
                   navigate(`${web_url}${role === "vendor-admin" || role === "vendor" ? "vendor" : "client"}/profile-setting`)
                handleClose()
                }}>
                  <Link className='comm_a' style={{ textDecoration: "none" }} to={`${web_url}${role === "vendor-admin" || role === "vendor" ? "vendor" : "client"}/profile-setting`}>
                    Profile Settings

                  </Link>
                </MenuItem>
                <MenuItem onClick={()=>{
                   navigate(`${web_url}${role === "vendor-admin" || role === "vendor" ? "vendor" : "client"}/password-setting`)
                handleClose()
                }}>
                  <Link className='comm_a' style={{ textDecoration: "none" }} to={`${web_url}${role === "vendor-admin" || role === "vendor" ? "vendor" : "client"}/password-setting`}>
                    Change Password
                  </Link>
                </MenuItem>
                <hr style={{ margin: "0 1rem" }} />
                <MenuItem onClick={() => {
                  localStorage.removeItem("vendor_data")
                  navigate(`${web_url}`)
                  handleClose()
                }} className='comm_a logOut_nav' >

                  Logout
                </MenuItem>
              </Menu>
            </> : <>
              <Tooltip style={{ padding: "0" }} title="Sign In">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <CiUser style={{ fontSize: "25px", fontWeight: "900", color: "#494949" }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '25px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={() => {
                  handleCloseUserMenu()
                  handleOpen()
                }}>
                  {/* <Link style={{color:'gray',textDecoration:"none"}} to={'/vendor/new-order'}> */}
                  <Typography className='comm_a' textAlign="center">Vendor Portal</Typography>
                  {/* </Link> */}
                </MenuItem>
                <MenuItem onClick={() => {
                  handleCloseUserMenu()
                  handleOpen1()
                }}>
                  {/* <Link style={{color:'gray',textDecoration:"none"}} to={'/client/my-order'}> */}
                  <Typography className='comm_a' textAlign="center">Client Portal</Typography>
                  {/* </Link> */}
                </MenuItem>
              </Menu>
            </>

            }

            <Link className='order_count_main' to={`${web_url}order-summry`} >

              <Badge badgeContent={cartData?.data?.cart.length} color="primary">
                {/* <MailIcon color="action" /> */}
                <img style={{ marginLeft: "20px" }} src={img} alt='...' />
              </Badge>

            </Link>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className='d-flex flex-column align-items-center mb-0'>
                <img src={logo} className='w-50' alt='...' />

                {resendEmailData ? <a target='_blank' rel="noreferrer" href={resendEmailData} style={{ textDecoration: "none", color: "#494949" }} ><h5 className='my-3'>Vendor Portal</h5></a> : <h5 className='my-3'>Vendor Portal</h5>}
              </div>
              {loginData?.message && loginData?.message !== "User Logged In Successfully" ? <span className="error_text" style={{ fontSize: "13px", position: "absolute", top: "120px" }}>{loginData?.message}</span> : ""}
              {loginData?.errors?.email ? <span className="error_text" style={{ fontSize: "13px", position: "absolute", top: "120px" }}>{loginData?.errors?.email[0]}</span> : ""}
              {loginData?.message === "User email is not verified. Please check your mail or click on resend button" ? <Link onClick={() => resendEmail(data1.email)} style={{ fontSize: "13px" }}>Resend Email</Link> : ""}
              <Box sx={{ width: "100%" }} className='signIn'>


                <div style={{ position: "relative", width: "100%" }}>
                  <label htmlFor="" className="new_order_label d-flex">Email</label>
                  <div className='upload_title mt-1' style={{ width: "100%" }}>
                    <input onChange={(e) => setdata1({ ...data1, email: e.target.value })} placeholder='Email' name="email" type='email' required />
                  </div>
                  {fieldErrors1?.email && <span className="error_text " style={{ position: "absolute", left: "0" }}>{fieldErrors1?.email}</span>}

                </div>
                <div style={{ position: "relative", width: "100%", marginTop: ".5rem" }}>
                  <label htmlFor="" className="new_order_label d-flex mt-2">Password</label>
                  <div className='upload_title mt-1' style={{ width: "100%" }}>
                    <input onChange={(e) => setdata1({ ...data1, password: e.target.value })} placeholder='Password' name="password" type={`${show1 ? "password" : "text"}`} required />
                    <button style={{ background: "none", border: "none" }} onClick={() => setshow1(!show1)}>{show1 ? <FaRegEye style={{ color: "gray" }} /> : <FaRegEyeSlash style={{ color: "gray" }} />}</button>
                  </div>
                  {fieldErrors1?.password && <span className="error_text" style={{ position: "absolute", left: "0" }}>{fieldErrors1?.password}</span>}
                </div>
                <div style={{ width: "100%", margin: "1rem 0" }} className='d-flex justify-content-between align-items-center'>
                  <FormControlLabel className='rootss'
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"

                  />
                  <ChildModal value={'Vendor Portal'} open1={open2} handleClose={handleClose2} handleOpen={handleOpen2} />
                </div>

                <button
                  onClick={() => handleSubmit1()}

                  style={{ background: "#494949", width: "100%", color: "white", border: "none", padding: ".5rem", borderRadius: "5px" }}
                >
                  {buttonLoding ? <div className="spinner-border text-light" role="status">

                  </div> : "Sign In"}
                </button>
                <Grid className='d-flex justify-content-center' container>

                  <Grid item>
                    <Link to={`${web_url}join-our-team`} variant="body2" style={{ color: "#414040", fontSize: "13px", textDecoration: "none" }}>
                      Don't have an account? <span style={{ fontWeight: "600", marginLeft: ".3rem" }}> Sign Up</span>
                    </Link>
                  </Grid>
                </Grid>

              </Box>
            </Box>

          </Modal>
          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className='d-flex flex-column align-items-center'>
                <img src={logo} className='w-50' alt='...' />
                <h5 className='my-3'>Client Portal</h5>
              </div>
              {loginData?.message && loginData?.message !== "User Logged In Successfully" ? <span className="error_text" style={{ fontSize: "13px", position: "absolute", top: "120px" }}>{loginData?.message}</span> : ""}
              {loginData?.errors?.email ? <span className="error_text" style={{ fontSize: "13px", position: "absolute", top: "120px" }}>{loginData?.errors?.email[0]}</span> : ""}
              <Box sx={{ width: "100%" }} className='signIn'>
                <div style={{ position: "relative", width: "100%" }}>
                  <label htmlFor="" className="new_order_label d-flex">Email</label>
                  <div className='upload_title mt-1' style={{ width: "100%" }}>
                    <input onChange={(e) => setdata({ ...data, email: e.target.value })} placeholder='Email' name="email" type='email' required />
                  </div>
                  {fieldErrors?.email && <span className="error_text" style={{ position: "absolute", left: "0" }}>{fieldErrors?.email}</span>}
                </div>
                <div style={{ position: "relative", width: "100%" }}>
                  <label htmlFor="" className="new_order_label d-flex mt-3">Password</label>
                  <div className='upload_title mt-1' style={{ width: "100%", marginTop: "1.5rem" }}>
                    <input value={data?.password} onChange={(e) => setdata({ ...data, password: e.target.value })} placeholder='Password' name="password" type={`${show ? "password" : "text"}`} required />
                    <button style={{ background: "none", border: "none" }} onClick={() => setshow(!show)}>{show ? <FaRegEye style={{ color: "gray" }} /> : <FaRegEyeSlash style={{ color: "gray" }} />}</button>
                  </div>
                  {fieldErrors?.password && <span className="error_text" style={{ position: "absolute", left: "0" }}>{fieldErrors?.password}</span>}
                </div>
                <div style={{ width: "100%", margin: "1rem 0" }} className='d-flex justify-content-between align-items-center'>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                    style={{ fontSize: "13px" }}
                  />
                  <ChildModal value={'Client Portal'} open1={open2} handleClose={handleClose2} handleOpen={handleOpen2} />

                </div>

                <button
                  onClick={() => handleSubmit()}

                  style={{ background: "#494949", width: "100%", color: "white", border: "none", padding: ".5rem", borderRadius: "5px" }}
                >
                  {buttonLoding ? <div className="spinner-border text-light" role="status">

                  </div> : "Sign In"}
                </button>
                <div className='d-flex justify-content-center my-3'>
                  <span style={{ color: "#B2B2B2", fontSize: "13px" }}>Or sign in with </span>
                </div>
                <div className='d-flex justify-content-center my-2'>
                  {client_id && <LoginSocialGoogle
                    client_id={client_id}
                    onResolve={({ provider, data }) => {
                      console.log('Login Success Data:', data);
                      handleLoginSuccessG(data);
                    }}
                    onReject={(error) => {
                      console.error('Login Failed:', error);
                      handleLoginFailure(error);
                    }}
                    scope="profile email"
                  >
                    <button style={{ background: "none", border: "none" }}><img className='mx-2' src={img1} alt='' /></button>
                  </LoginSocialGoogle>}

                  {/* Facebook Login Button */}
                  {app_id && <LoginSocialFacebook
                    appId={app_id} // Replace with your actual Facebook App ID
                    onResolve={({ provider, data }) => handleLoginSuccessF(data)}
                    onReject={handleLoginFailure}
                  >
                    <button style={{ background: "none", border: "none" }}>
                      <img className='mx-2' src={img2} alt='' />
                    </button>
                  </LoginSocialFacebook>}
                  {/* <img className='mx-2'  src={img3} alt=''/>
            <img className='mx-2'  src={img4} alt=''/> */}
                </div>
                <Grid className='d-flex justify-content-center' container>

                  <Grid item>
                    <Link to={`/clinet-sigup`} variant="body2" style={{ color: "#414040", fontSize: "13px", textDecoration: "none" }}>
                      Don't have an account? <span style={{ fontWeight: "600", marginLeft: ".1rem" }}> Sign Up</span>
                    </Link>
                  </Grid>
                </Grid>

              </Box>
            </Box>
          </Modal>
        </div>
      </div>
      <div style={{ top: `${navBar ? "0" : "-100vh"}` }} className='menu '>
        {navBar && <div className='menu_perant'>
          <ul>
            <li><Link to={`${web_url}our-service`} className='link_nav'>Our Services</Link></li>
            <li><Link to={`${web_url}our-studio`} className='link_nav'>Hire Our Studios</Link></li>
            <li><Link to={`${web_url}about`} className='link_nav'>About Us</Link></li>
            <li><Link to={`${web_url}contact`} className='link_nav'>Contact Us</Link></li>
            {webSettingData?.data?.is_marketplace ? <li><Link onClick={() => setNavMenu(!navMenu)} className='link_nav new_menu_tab'><div className={` ${!navMenu ? 'd-flex ms-5' : 'd-flex ms-4'}`}>Market Place <div className={`${!navMenu ? '' : 'place_arrow'}`}><MdOutlineKeyboardArrowDown /></div></div>
              <div className={` ${navMenu ? 'new_market_menu' : 'market-menu'}`} style={{ display: 'block' }}>
                <Link className='link_nav' to={'/explor-telent'}><div className='market_tab mb-4'>Hire  Talent Services</div></Link>
                <Link className='link_nav' to={'/studio-list/studio'}><div className='market_tab'>Hire Studio Spaces</div> </Link>
              </div>
            </Link>

            </li> : ""}
            <li><Link to={`${web_url}join-our-team`} className='link_nav'>Be A Member</Link></li>
            <li><Link to={`${vendor_data?.data?.token ? `${web_url}${role}/dashboard` : `${web_url}clinet-signin`}`} className='link_nav'>{vendor_data?.data?.token ? "My Account" : "Sign In"}</Link></li>
            {/* <li><hr/></li> */}
            <li><button onClick={() => setNavBar(false)}>Close Menu</button></li>

          </ul>
        </div>}
      </div>
      <CustomizedSnackbars />

    </div>
  )
}

export default NavBar



// to={`${web_url}explor-telent`}