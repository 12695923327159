import React, { useEffect, useRef, useState } from "react";
import img from "../../asset/icons/Vector.png";
import img1 from "../../asset/dummy.webp";
import {
  FaArrowRight,
  FaRegEye,
  FaRegEyeSlash,
  FaSortDown,
} from "react-icons/fa6";
import { IoIosCheckmarkCircle } from "react-icons/io";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { useAuth } from "../../Context/ContextProvider";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { CloseOutlined } from "@mui/icons-material";
import { TiTick } from "react-icons/ti";
import Discard from "./Discard";
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,

  borderRadius: "5px",
};
function Step1({ onNext, data, setData, validateStep, fieldErrors, state }) {
  const [show, setshow] = useState(true);
  const [show1, setshow1] = useState(true);
  const [error, seterror] = useState({ email: "", phone: "" });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose = () => {
    setOpen(false)
    setCode(["","","","","",""])
  };
  const { getCity, sendCode, verifyCode, cityData, code, codeVerify } =
    useAuth();
  useEffect(() => {
    if (codeVerify?.success) {
      setData({ ...data, phone_verified: true });
      handleClose();
      onNext();
      setChangePhone(false);
    }
    // eslint-disable-next-line
  }, [codeVerify]);
  const [changePhone, setChangePhone] = useState(false);
  useEffect(() => {
    if (code?.success) {
      startCountdown();
      handleOpen();
    } else if(code==="success"){
      setData({ ...data, phone_verified: true });
      onNext()
    }
     else {
      if (code?.errors?.email && code?.errors?.phone_number) {
        seterror({
          email: code?.errors?.email[0],
          phone: code?.errors?.phone_number[0],
        });
      } else if (code?.errors?.email) {
        seterror({ ...error, email: code?.errors?.email[0] });
      } else {
        seterror({ ...error, phone: code?.errors?.phone_number[0] });
      }
    }
    // eslint-disable-next-line
  }, [code]);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64String = event.target.result;
        setData({ ...data, avatar: base64String });
      };

      reader.readAsDataURL(file);
    }
  };
  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleChange = (e) => {
    let value = e.target.value;
    setChangePhone(true);
    // Remove any non-digit characters
    value = value.replace(/\D/g, "");

    // Format the phone number as "7700 900886"
    const firstPart = value.slice(0, 4);
    const secondPart = value.slice(4);

    let formattedValue = `${firstPart}`;
    if (secondPart.length > 0) {
      formattedValue += ` ${secondPart}`;
    }

    // Update the state with the formatted value
    setData({ ...data, phone_number: formattedValue });
  };

  const check = () => {
    seterror({ email: "", phone: "" });
    if (validateStep()) {
      if (changePhone) {
        let phone = data?.phone_number;
        sendCode("44" + phone?.replace(/\s/g, ""), data?.email);
      } else {
        onNext();
      }
    }
  };
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  function startCountdown() {
    setSeconds(60); // Change this to the desired countdown duration
    setIsActive(true);
  }

  React.useEffect(() => {
    let intervalId;
    if (isActive) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    if (seconds === 0) {
      clearInterval(intervalId);
      setIsActive(false);
    }

    return () => clearInterval(intervalId);
  }, [isActive, seconds]);
  const [codeinp, setCode] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const handleInputChange1 = (e, index) => {
    const value = e.target.value;
    if (!isNaN(value) && value.length === 1) {
      const newCode = [...codeinp];
      newCode[index] = value;
      setCode(newCode);

      // Auto focus on next input if not the last input
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }

      // If code is complete, trigger verifyCode
      if (newCode.every((digit) => digit !== "")) {
        verifyCode("44" + data?.phone_number?.replace(/\s/g, ""), newCode.join(""));
      }
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && codeinp[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };
  return (
    <div className="container">
      <div style={{ boxShadow: "none", padding:"1rem 0 0" }} className="row main pt-3">
        <div className="col-lg-3 col-12 ps-1">
          <div className="side_basic">
            <div className="basic_info">
              <div className="row p-0">
                <div style={{ position: "relative" }} className="col-2 p-0">
                  <div className="check">
                    <span>
                      <IoIosCheckmarkCircle
                        style={{ fontSize: "25px", color: "#53B73A" }}
                      />
                    </span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      height: "32px",
                      left: "15px",
                      border: "1px solid lightgray",
                      top: "50%",
                    }}
                  ></div>
                </div>
                <div className="col-10">
                  <h5>Basic Information</h5>
                  <p>Get Started Here</p>
                </div>
                <div style={{ position: "relative" }} className="col-2 p-0">
                  <div className="check">
                    <span>
                      <div>
                        <div></div>
                      </div>
                    </span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      height: "32px",
                      left: "15px",
                      border: "1px solid lightgray",
                      top: "50%",
                    }}
                  ></div>
                </div>
                <div className="col-10">
                  <h5>Professional Information</h5>
                  <p>Showcase your skills.</p>
                </div>
                <div style={{ position: "relative" }} className="col-2 p-0">
                  <div className="check">
                    <span>
                       <div>
                        <div></div>
                      </div>
                    </span>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      height: "32px",
                      left: "15px",
                      border: "1px solid lightgray",
                      top: "50%",
                    }}
                  ></div>
                </div>
                <div className="col-10">
                  <h5>Terms & Conditions</h5>
                  <p>Read before joining.</p>
                </div>
                <div className="col-2 p-0">
                  <div className="check">
                    <span>
                       <div>
                        <div></div>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="col-10">
                  <h5>HUrrah ! </h5>
                  <p>Welcome aboard!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-12 mt-lg-0 mt-5 pe-1">
          <div className="side_basic">
            <div className="heading pb-0">
              <h5
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "28px",
                  color: "#101828",
                  textTransform: "none",
                  margin: "0",
                }}
              >
                Fill in this information
              </h5>
            </div>
            <div className="basic_form">
              <div className="upload">
                <div className="row my-3">
                  <div className="col-12">
                    <div className="d-flex justify-content-center">
                      <Stack direction="row" spacing={2}>
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            <label for="profile_image">
                              <SmallAvatar alt="Remy Sharp" src={img} />
                            </label>
                          }
                        >
                          <Avatar
                            style={{
                              width: "100px",
                              height: "100px",
                              border: "1px solid lightgray",
                            }}
                            className="mt-3"
                            alt="Travis Howard"
                            src={data?.avatar ? data?.avatar : img1}
                          />
                        </Badge>
                      </Stack>
                    </div>
                    <div className="d-flex justify-content-center">
                      {fieldErrors.profile && (
                        <span className="error_text text-center">
                          {fieldErrors.profile}
                        </span>
                      )}
                    </div>

                    <input
                      onChange={handleFileInputChange}
                      type="file"
                      className="d-none"
                      id="profile_image"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-12 pb-3">
                    <label htmlFor="" className="new_order_label ">
                      Full Name
                    </label>
                    <div className="upload_title mt-1">
                      <input
                        value={data?.name}
                        type="text"
                        id="title"
                         maxlength="40"
                        name="name"
                        onChange={handleInputChange}
                        placeholder="Full Name"
                        className="inp"
                      />
                    </div>
                    {fieldErrors.name && (
                      <span
                        className="error_text"
                        style={{ position: "absolute" }}
                      >
                        {fieldErrors.name}
                      </span>
                    )}
                  </div>

                  <div className="col-lg-6 col-12 pb-3">
                    <label htmlFor="" className="new_order_label ">
                      Email
                    </label>
                    <div className="upload_title mt-1">
                      <input
                        value={data?.email}
                        type="email"
                        id="title"
                        name="email"
                        onChange={handleInputChange}
                        placeholder="Email"
                        className="inp"
                      />
                    </div>
                    {fieldErrors.email && (
                      <span
                        className="error_text"
                        style={{ position: "absolute" }}
                      >
                        {fieldErrors?.email}
                      </span>
                    )}
                    {error.email && (
                      <span
                        className="error_text"
                        style={{ position: "absolute" }}
                      >
                        {error?.email}
                      </span>
                    )}
                  </div>

                  <div className="col-lg-6 col-12 pb-3">
                    <label htmlFor="" className="new_order_label ">
                      Country
                    </label>
                    <div
                      className="upload_title mt-1"
                      style={{ background: "#E6EAEE", cursor: "not-allowed" }}
                    >
                      <span>United Kingdom</span> <FaSortDown />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 pb-3">
                    <label htmlFor="" className="new_order_label ">
                      Contact
                    </label>
                    <div className="upload_title mt-1 d-flex align-items-center  p-0">
                      <div className="profile_contacts">
                        <img
                          style={{ width: "30px", height: "20px" }}
                          src="https://flagcdn.com/w320/gb.png"
                          alt="..."
                        />
                        <span className="mx-2">+44</span>
                      </div>
                      <input
                        type="text"
                        value={data?.phone_number}
                        onChange={handleChange}
                        placeholder="e.g 7700 900886"
                        maxLength={11}
                        pattern="[0-9]{4} [0-9]{6}"
                      />
                      <span className="profile_inp_span">
                        <span
                          className={`${
                            data?.phone_verified ? "text-success" : ""
                          }`}
                        >
                          <TiTick />
                        </span>{" "}
                      </span>
                    </div>
                    {fieldErrors?.phone_number && (
                      <span
                        className="error_text"
                        style={{ position: "absolute" }}
                      >
                        {fieldErrors?.phone_number}
                      </span>
                    )}
                    {error?.phone && (
                      <span
                        className="error_text"
                        style={{ position: "absolute" }}
                      >
                        {error?.phone}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-6 col-12 pb-3">
                    <label htmlFor="" className="new_order_label ">
                      Password
                    </label>
                    <div className="upload_title mt-1">
                      <input
                        value={data?.password}
                        name="password"
                        onChange={handleInputChange}
                        type={`${show ? "password" : "text"}`}
                        id="title"
                        placeholder="Password"
                        className="inp"
                      />
                      <button
                        style={{ background: "none", border: "none" }}
                        onClick={() => setshow(!show)}
                      >
                        {show ? (
                          <FaRegEyeSlash style={{ color: "gray" }} />
                        ) : (
                          <FaRegEye style={{ color: "gray" }} />
                        )}
                      </button>
                    </div>
                    {fieldErrors?.password && (
                      <span
                        className="error_text"
                        style={{ position: "absolute" }}
                      >
                        {fieldErrors?.password}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-6 col-12 pb-3">
                    <label htmlFor="" className="new_order_label ">
                      Confirm Password
                    </label>
                    <div className="upload_title mt-1">
                      <input
                        name="password_confirmation"
                        value={data?.password_confirmation}
                        type={`${show1 ? "password" : "text"}`}
                        onChange={handleInputChange}
                        id="title"
                        placeholder="Confirm Password"
                        className="inp"
                      />
                      <button
                        value={data?.password_confirmation}
                        style={{ background: "none", border: "none" }}
                        onClick={() => setshow1(!show1)}
                      >
                        {show1 ? (
                          <FaRegEyeSlash style={{ color: "gray" }} />
                        ) : (
                          <FaRegEye style={{ color: "gray" }} />
                        )}
                      </button>
                    </div>
                    {fieldErrors?.password_confirmation && (
                      <span
                        className="error_text"
                        style={{ position: "absolute" }}
                      >
                        {fieldErrors?.password_confirmation}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-6 col-12 pb-3">
                    <label htmlFor="" className="new_order_label ">
                      State
                    </label>
                    <div className="upload_title mt-1 ps-0">
                      <select
                        value={data?.state_id}
                        name="state_id"
                        onChange={(e) => {
                          getCity(e.target.value);
                          setData({ ...data, state_id: e.target.value });
                        }}
                        className=" "
                        aria-label="Default select example"
                        style={{
                          padding: ".2rem 0.8rem",
                          marginTop: "0",
                          fontSize: "17px",
                          color: "#545468",
                          fontWeight: 600,
                        }}
                      >
                        <option hidden>Choose State</option>
                        {state?.map((item) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>
                    {fieldErrors?.state_id && (
                      <span
                        className="error_text"
                        style={{ position: "absolute" }}
                      >
                        {fieldErrors?.state_id}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-6 col-12 pb-3">
                    <label htmlFor="" className="new_order_label ">
                      City
                    </label>
                    <div className="upload_title mt-1 ps-0 ">
                      <select
                        value={data?.city_id}
                        name="city_id"
                        onChange={handleInputChange}
                        className=" "
                        aria-label="Default select example"
                        style={{
                          padding: ".2rem 0.8rem",
                          marginTop: "0",
                          fontSize: "17px",
                          color: "#545468",
                          fontWeight: 600,
                        }}
                      >
                        <option hidden>Choose City</option>
                        {cityData?.map((item) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>
                    {fieldErrors?.city_id && (
                      <span
                        className="error_text"
                        style={{ position: "absolute" }}
                      >
                        {fieldErrors?.city_id}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="btn_last mt-5">
                      <button
                        className="btn   
                       me-3 discard-btn"
                        onClick={() => handleOpen1()}
                        style={{
                          boxShadow: "8px 8px 24px 0 rgba(2, 2, 70, 0.15)",
                          backgroundColor: "#BAB4B4",
                          borderColor: "transparent",
                          color: "white",
                          padding: "7px 35px",
                        }}
                      >
                        Discard
                      </button>

                      <button
                        onClick={check}
                        className="btn btn-secondary  
                       ms-2 continue-btn"
                        style={{
                          backgroundColor: "#494949",
                          boxShadow: "8px 8px 24px 0 rgba(2, 2, 70, 0.15)",
                          color: "white",
                        }}
                      >
                        Continue{" "}
                        <span>
                          <FaArrowRight className="ms-2" />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={{ ...style, width: 450,}}>
        <div style={{ borderBottom: "1px solid lightgray", padding: ".5rem" }} className="model_header d-flex justify-content-between align-items-center ">
          <h4 className="m-0">Verify Your Contact</h4>
          <span onClick={handleClose}>
            <CloseOutlined />
          </span>
        </div>
        <div className="p-3 upload">
          <h5 style={{ fontSize: "20px", color: "#494949", textAlign: "center" }}>
            Verify your phone number to finish setting up your Account
          </h5>
          <p className="text-center" style={{ fontSize: "13px" }}>
            Please enter the verification code sent to +44 {data?.phone_number} or you want to{" "}
            <span className="text-primary" onClick={handleClose} style={{ cursor: "pointer" }}>
              Change
            </span>
            <br /> the contact number
          </p>
          
          <div className="d-flex justify-content-center mt-3">
            {codeinp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleInputChange1(e, index)}
                onKeyDown={(e) => handleBackspace(e, index)}
                ref={(el) => (inputRefs.current[index] = el)}
                style={{
                  width: "2rem",
                  height: "2rem",
                  marginRight: "0.5rem",
                  textAlign: "center",
                  fontSize: "1.5rem",
                }}
              />
            ))}
          </div>
          
          {isActive && (
            <p className="text-center my-2" style={{ fontSize: "13px" }}>
              00:{seconds}
            </p>
          )}
          {!isActive && (
            <p className="text-center my-2" style={{ fontSize: "13px" }}>
              Did not receive a code?{" "}
              <span className="text-primary" onClick={check} style={{ cursor: "pointer" }}>
                Resend
              </span>
            </p>
          )}

          <div className="d-flex justify-content-between align-items-center my-2">
            <button
              disabled={codeinp?.includes("")}
              onClick={() => verifyCode("44" + data?.phone_number?.replace(/\s/g, ""), codeinp.join(""))}
              style={{
                padding: ".2rem 1rem",
                border: "1px solid #494949",
                background: "#494949",
                color: "white",
                borderRadius: "5px",
                cursor: `${codeinp.includes("") ? "not-allowed" : "pointer"}`,
              }}
            >
              Verify
            </button>
            <button
              onClick={handleClose}
              style={{
                padding: ".2rem 1rem",
                border: "1px solid #494949",
                background: "none",
                color: "#494949",
                borderRadius: "5px",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Box>
    </Modal>

        <Discard open1={open1} setOpen1={setOpen1} />
      </div>
    </div>
  );
}

export default Step1;
