import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

function CustomMultiSelect({ options,setData,data,vendorServices}) {
    useEffect(()=>{
        let array=[]
        // eslint-disable-next-line
        vendorServices?.map((item)=>{
        array.push(item)
        })
        setSelectedOptions(array)
      },[vendorServices])
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [show,setshow]=useState(false)
  const handleSelectOption = (value) => {
    const selectedId = parseInt(value);
    const selectedOption = options.find(option => option?.id === selectedId);
    if (!selectedOptions.some(option => option?.id === selectedId)) {
      setSelectedOptions([...selectedOptions, selectedOption]);
      setData({ ...data, sub_service_ids: [...selectedOptions, selectedOption].map(option => option?.id) });
    }
  };
  const handleRemoveOption = (optionId) => {
    const updatedOptions = selectedOptions.filter(option => option?.id !== optionId);
    setSelectedOptions(updatedOptions);
    setData({ ...data, sub_service_ids:updatedOptions.map(option => option?.id)})
  };
  return (
    <div onClick={()=>{
      setshow(!show)
    }} style={{width:"100%",position:"relative"}}>
      <div style={{display:"flex",flexWrap:"wrap"}}>
        {selectedOptions.length===0&&<span>Select Your Services</span>}
        {selectedOptions.map((selectedOption) => (
          <span  key={selectedOption?.id} style={{marginRight: '5px',background:"#494949",color:"white",padding:".5rem 1rem",whiteSpace:"nowrap",borderRadius:"5px",fontSize:"13px",fontWeight:"500",letterSpacing:"1px", marginBottom:"3px"}}>
            {selectedOption?.name}
            <span className='close_btn_seb_sevice' style={{background:"none",border:"none",color:"white",marginLeft:"10px", cursor:"pointer"}} onClick={() => handleRemoveOption(selectedOption?.id)}>x</span>
          </span>
        ))}
      </div>
      {/* <div>
     
        <select style={{ backgroundColor: 'transparent', border: 'none', outline: 'none', fontSize: '17px', color: '#545468', fontWeight: 600, padding: 0 }} onChange={handleSelectOption}>
          <option hidden>Select an option</option>
          {options.map(option => (
            <option key={option?.id} value={option?.id}>{option?.name}</option>
          ))}
        </select>
       
      </div> */}
      {show&&<ul style={{position:"absolute",marginTop:"23px",width:"100%",zIndex:"99"}} className='custom_ul'>
          {options.map(option => (
            <li key={option?.id} onClick={()=>{
              handleSelectOption(option?.id)
              setshow(true)
            }}>{option?.name}</li>
          ))}
        </ul>}
        <IoIosArrowDown style={{position:"absolute",right:"0%",top:"5px",fontSize:"13px"}}  />
    </div>
  );
}

export default CustomMultiSelect;
