import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
let slidesToShow = 4;
const carouselProperties = {
    prevArrow:"",
  nextArrow: "",
  slidesToShow: slidesToShow,
  slidesToScroll: 1,
  infinite: true,
  dots: true,
  autoplay:true,
  autoplaySpeed: 3000, // Autoplay speed in milliseconds
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        centerMode: false,
      },
    },
    {
      breakpoint: 999,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

function PortofiloCarsuol({image}) {
  return (
    <div style={{ margin: '16px 0'}} className='carousel_tumbnil portfolio_slider'>

    <Slider {...carouselProperties}>
      {image?.map((item,i) => (
        <Card  item={item?.image?.url} index={i} />
      ))}
    </Slider>
  </div>
  )
}
const Card = ({ item}) => {
    return (
      <div style={{overflow:"hidden"}}>
        <img
          className='multi__image px-2'
          src={item}
          alt=''
          style={{
          width: '100%',
            height: '206px',
            objectFit: 'cover',
            marginBottom: '10px',
          }}
        />
        
      </div>
    );
  };
  
export default PortofiloCarsuol