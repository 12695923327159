import React from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import Banner from '../Components/ExplorTelent/Banner'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import Footer from '../Components/NavBarFooter/Footer'

function ExplorTelent() {
  return (
    <>
    <div className='container'>
        <NavBar/>
        <Banner/>
    </div>
            <div className='mt-5'><JoinOurTeam/></div>
    <div className='container'>
        <Footer/>
    </div>
    </>
  )
}

export default ExplorTelent