import React from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import Banner from '../Components/OurService/Banner'
import ListingSectoin from '../Components/OurService/ListingSectoin'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import Footer from '../Components/NavBarFooter/Footer'
import { useAuth } from '../Context/ContextProvider'

function OurService() {
  const {adminservice}=useAuth()
  return (
    <>
    <div className='container'>
        <NavBar/>
        <Banner/>
        <ListingSectoin data={adminservice}/>
    </div>
        <JoinOurTeam/>
        <Footer/>
    </>
  )
}

export default OurService