import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { PiWarningThin } from 'react-icons/pi';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
};
function VerificationModal({open,setOpen,currentAction,setCurrentAction,setChange,handleSubmit}) {
  const handleClose = () => {
    setOpen(false);
    setCurrentAction(null); // Clear current action on close
    setChange(false)
  };
    console.log("currentAction",currentAction);
    const handleContinue = () => {
      if (currentAction) {
        currentAction();
      }
      handleClose();
    };
  return (
<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div>
  <div className="alert alert-danger alert-dismissible fade show">
    <h4 className="alert-heading"><PiWarningThin style={{fontSize:"34px"}}/> Unsaved Changes.</h4>
    <p> It looks like you have some unsaved changes. Do you want to save them before leaving this page?</p>
    <hr />
    <button type="button" className="btn-close" onClick={handleClose} />
    <div className='d-flex justify-content-end'>
      <button className='btn btn-success mx-2' onClick={()=>{
        handleSubmit()
        handleContinue()
        }}>Save Changes</button>
      <button className='btn btn-danger' onClick={handleContinue} >Leave Without Saving</button>
    </div>
  </div>
</div>
        </Box>
      </Modal>
  )
}

export default VerificationModal
