import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
function AdditionalEditComp({setData,data,show}) {
    const [value, setValue] = useState(data?.additional_information);
    useEffect(()=>{
      setData({...data,additional_information:value})
      // eslint-disable-next-line
    },[value])
    useEffect(()=>{
    if(show===true){
      setValue(data.additional_information?data.additional_information:"")
    }
    else{
      setValue('')
    }
    // eslint-disable-next-line
    },[show])
  
    return (
      <div className='w-100'>
        
        <ReactQuill 
        style={{height:"270px"}}
        value={value} 
        onChange={setValue} 
        />
  
  
  
  
      </div>
    )
}

export default AdditionalEditComp
