import React, { useEffect } from "react";
import { IoNotificationsOutline } from "react-icons/io5";
import { Badge, Menu } from "@mui/material";
import { AiFillShop } from "react-icons/ai";
import { GoDotFill } from "react-icons/go";
import { useAuth } from "../../Context/ContextProvider";
import { Link } from "react-router-dom";
import { FaRegBell } from "react-icons/fa6";

function Notification() {
 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { getNotifcation, notificationData, updateNotification ,web_url} = useAuth();
  useEffect(() => {
    getNotifcation({ page: 1, per_page: "10" });
    // eslint-disable-next-line
  }, []);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Badge
        id="lock-button"
        aria-haspopup="listbox"
        aria-controls="lock-menu"
        aria-label="when device is locked"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClickListItem}
        badgeContent={notificationData?.un_read_notifications_count}
        color="success"
      >
        <IoNotificationsOutline
          className="vendor_noti"
          color="action"
          style={{ cursor: "pointer" }}
        />
      </Badge>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <div>
          <ul
            className="p-0 px-2"
            style={{ height: "400px", overflowY: "scroll" }}
          >
            {notificationData?.notifications?.map((item) => {
              return (
                <li
                  style={{ listStyle: "none", cursor: "pointer" }}
                  className="mb-1"
                >
                  {item?.is_read ? (
                    <div className="d-flex align-items-center border rounded p-1">
                      <div
                        style={{
                          width: "50px",
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          background: "#D4EBEF",
                        }}
                      >
                        <AiFillShop
                          style={{ fontSize: "30px", color: "gray" }}
                        />
                      </div>
                      <div className="px-2" style={{ width: "300px" }}>
                        <h6 style={{ fontWeight: "600" }} className="p-0 m-0">
                        {item?.orderStatus?.status_name}
                        </h6>
                        <p
                          style={{ color: "gray", fontSize: "13px" }}
                          className="p-0 m-0"
                        >
                          Order ID {item?.order_number} is {item?.orderStatus?.status_name} by “{item?.sender_name}” at {item?.created_at?.split("T")[0]}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => updateNotification(item?.notification_id)}
                      className="d-flex align-items-center border rounded p-1"
                      style={{ background: "#e0f6f1" }}
                    >
                      <div
                        style={{
                          width: "50px",
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                          background: "#D4EBEF",
                        }}
                      >
                        <AiFillShop
                          style={{ fontSize: "30px", color: "gray" }}
                        />
                      </div>
                      <div className="px-2" style={{ width: "300px" }}>
                     
                       
                        <div className="d-flex">
                        <h6 style={{ fontWeight: "600" }} className="p-0 m-0">
                        {item?.orderStatus?.status_name}
                        </h6>
                          <GoDotFill style={{ color: "#ffa700" }} />
                        </div>
                        <p
                          style={{ color: "gray", fontSize: "13px" }}
                          className="p-0 m-0"
                        >
                          Order ID {item?.order_number} is {item?.orderStatus?.status_name} by “{item?.sender_name}” at {item?.created_at?.split("T")[0]}
                        </p>
                      </div>
                    </div>
                  )}

                  {/* <hr className='p-0 m-0 mt-1'/> */}
                </li>
              );
            })}
            <li style={{height:'100%'}}>
            <div className="col-12 noNoti" >
           <FaRegBell />
              <h4>
                No Notification Yet
              </h4>
              <p>
                Stay tuned! Notifications about your activity will <br/> show up here.
              </p>
            </div>
            </li>
          </ul>
          <hr className="m-0 p-0" />
          <Link
            to={`${web_url}vendor/notification`}
            onClick={handleClose}
            className="m-0 p-0 mt-1 text-center view_notification"
           
          >
            View All Notification
          </Link>
        </div>
      </Menu>
    </>
  );
}

export default Notification;
