import React from "react";
import facebook from '../../asset/psh-social-icon/facebook.svg'
import youTube from '../../asset/psh-social-icon/youtube.svg'
import pinterest from '../../asset/psh-social-icon/pintrest.svg'
import twitter from '../../asset/psh-social-icon/Vector.svg'
import dribbble from '../../asset/psh-social-icon/dribbble.svg'
import instagram from '../../asset/psh-social-icon/instagram.svg'
import linkedin from '../../asset/psh-social-icon/linkedin.svg'
import tiktok from '../../asset/psh-social-icon/tiktok.svg'
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/ContextProvider";
import { MdEmail, MdWifiCalling3 } from "react-icons/md";
import { SiTrustpilot } from "react-icons/si";
function Footer() {
  const {webSettingData, web_url,social_linkData , number} = useAuth();
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';  // Return empty string if no number is present
  
    // Manually insert spaces at specific positions
    const formattedNumber = phoneNumber.slice(0, 3) + " " + 
                            phoneNumber.slice(3, 7) + " " + 
                            phoneNumber.slice(7, 17);
  

    return formattedNumber;
  };
  
  const phoneNumber = number?.contact;
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  
  
  
  return (
    <div className="footer">
      <div className="media_link my-3">
        {social_linkData?.map((item)=>{
          return(
        <a
          style={{ textDecoration: "none", color: "#464646" , paddingLefteft:'8px' }}
          target="_blanck"
          href={item?.social_media_link}
        >
        {item?.title==="facebook"&&<img src={facebook} style={{marginRight:"0.7rem",height:"12px"}} alt="..."/>}
        {item?.title==="instagram"&&<img src={instagram} style={{marginRight:"0.7rem",height:"12px"}} alt="..."/>}
        {item?.title==="twitter"&&<img src={twitter} style={{marginRight:"0.7rem",height:"12px"}} alt="..."/>}
        {item?.title==="tiktok"&&<img src={tiktok} style={{marginRight:"0.7rem",height:"12px"}} alt="..."/>}
        {item?.title==="pinterest"&&<img src={pinterest} style={{marginRight:"0.7rem",height:"12px"}} alt="..."/>}
        {item?.title==="linkedIn"&&<img src={linkedin} style={{marginRight:"0.7rem",height:"12px"}} alt="..."/>}
        {item?.title==="dribbble"&&<img src={dribbble} style={{marginRight:"0.7rem",height:"12px"}} alt="..."/>}
        {item?.title==="youtube"&&<img src={youTube} style={{marginRight:"0.7rem",height:"12px"}} alt="..."/>}

        </a>
          )
        })}
      </div>
      {webSettingData?.data?.trustpilot_link?<a href={webSettingData?.data?.trustpilot_link} rel="noreferrer" target="_blank">
        <button className="btn_join_2 trustpilot">
      Review us on <span><SiTrustpilot className="trustPilot_star" /> trustpilot</span>
      </button>
      </a> 
     :""}
      <div className="footer_route mt-2 pt-">
        <h3>
          {" "}
          <Link
            style={{ textDecoration: "none", color: "#464646" }}
            to={`${web_url}our-service`}
          >
            {" "}
            Photography
          </Link>
          <span className="mx-lg-4  mx-2">|</span>
        </h3>
        <h3>
          {" "}
          <Link
            style={{ textDecoration: "none", color: "#464646" }}
            to={`${web_url}our-studio`}
          >
            Studios
          </Link>
          {webSettingData?.data?.is_marketplace?<span className="mx-lg-4 mx-2">|</span>:""}
        </h3>
        <h3>
          {" "}
         {webSettingData?.data?.is_marketplace?<Link
            style={{ textDecoration: "none", color: "#464646" }}
            to={`${web_url}explor-telent`}
          >
            Market Place
          </Link>:""}
        </h3>
      </div>
     
      <div className="footer_route mt-1 mb-3">
        <span className=" mx-3">
          
          
        <MdEmail  className="me-2"/>{number?.system_email?number?.system_email:'photographystudio.gmail.com'}
        </span>
        <span className=" mx-3">
        <MdWifiCalling3  className="me-2"/>{number?.contact?formattedPhoneNumber:'+44 7435 626997'}
        </span>
      
      </div>
      <div className="footer_route mb-2">
        <span>
          <Link to={`${web_url}term-condition`}
            style={{
              textDecoration: "none",
              color: "#464646",
              fontSize: "14px",
            }}
          >
            T & C's
          </Link>
          <span className="mx-3">|</span>
        </span>
        <span>
          {" "}
          <Link to={`${web_url}privacy-policy`}
            style={{
              textDecoration: "none",
              color: "#464646",
              fontSize: "14px",
            }}
          >
            Privacy
          </Link>
          <span className="mx-3">|</span>
        </span>
        <span>
          {" "}
          <Link to={`${web_url}FAQs`}
            style={{
              textDecoration: "none",
              color: "#464646",
              fontSize: "14px",
            }}
          >
            FAQs
          </Link>
          <span className="mx-3">|</span>
        </span>
        <span>
          {" "}
          <Link to={`${web_url}contact`}
            style={{
              textDecoration: "none",
              color: "#464646",
              fontSize: "14px",
            }}
          >
            Contact Us
          </Link>
        </span>
      </div>
      <p className="mt-2 mb-3 pb-2">Copyright © - photography studio hub 2024</p>
    </div>
  );
}

export default Footer;
