import React, { useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { GrAttachment } from "react-icons/gr";
import {
  Box,
  Modal,
} from "@mui/material";
import { useAuth } from "../../../Context/ContextProvider";
import { IoClose } from "react-icons/io5";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa6";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
};
function RevisionRequest({
  show,
  setShow,
  orderServiceId,
  orderId,
  pagination,
}) {
  const [reply, setReply] = useState(false);
  const { changeOrderStatus, buttonLoding } = useAuth();
  const [rating, setRating] = useState({
    client_comment: "",
    order_status_code: "6D",
    rating: "",
  }); // Initialize rating state

  const handleStarClick = (index) => {
    setRating({ ...rating, rating: index + 1 }); // Update rating state when a star is clicked
  };

  const { getOrderLinkData, orderLinkData, setOrderLinkDatas, sendRevision } =
    useAuth();
  useEffect(() => {
    getOrderLinkData(orderServiceId);
    // eslint-disable-next-line
  }, []);
  const [data, setData] = useState({
    status: 0,
    client_comment: "",
    media: "",
  });
  const [fileName, setFileName] = useState("Attach file");

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setFileName(event.target.files[0].name);
      setData({ ...data, media: event.target.files[0] });
    } else {
      setFileName("Attach file");
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {

    setOpen(false)
    setRating({
      client_comment: "",
      order_status_code: "6D",
      rating: "",
    });
  };

  return (
    <div>
      <div className="new_order_nav">
        <div>
          <h4 className="d-flex align-items-center">
            {" "}
            Order ID 235487
          </h4>
        </div>
        <div
          className="new_order_nav_btn"
          onClick={() => {
            setShow({ ...show, revision_req: false });
            setOrderLinkDatas([]);
          }}
        >
          <button className="btn_11" style={{ borderRadius: "4px" }}>
            back <IoIosArrowRoundBack />{" "}
          </button>
        </div>
      </div>

      <div className="table_box">

        {orderLinkData?.data?.map((item, i) => {
          return (
            <div className="row">
              <div className="client_feed" style={{ maxHeight: "80vh" }}>
                <div className="col-12">
                  <div className="feedback">
                    <div className="water">
                      <p className="water_p">
                        {item?.type === "sample"
                          ? "Watermarked Sample Data Link"
                          : "Orginal Data"}
                      </p>
                      <p className="water_time" style={{ color: "#A9AAAF" }}>
                        Date:{" "}
                        <span style={{ color: "#6e7079" }}>12 June 2024</span>
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 col-12 my-2">
                        <p className="new_order_label m-0">
                          {item?.type === "sample"
                            ? "Sample Data Title"
                            : "Orginal Data Tilte"}
                        </p>
                        <p className="sample_p">{item?.title}</p>
                      </div>
                      <div className="col-lg-4 col-12 my-2">
                        <p className="new_order_label m-0">
                          {item?.type === "sample"
                            ? "  Watermarked Sample Data Link"
                            : "Orginal Data Link"}
                        </p>
                        <p className="sample_p">{item?.link}</p>
                      </div>
                    </div>
                    <div className="new_order_label">
                      {item?.type === "sample"
                        ? "Sample Data Description"
                        : "Orginal Data Description"}
                    </div>
                    <p className="sample_p">{item?.description}</p>

                    {item?.client_comment && (
                      <div className="row">
                        <div className="col-12 mb-2">
                          <div className="water">
                            <p className="water_p d-flex align-items-center">
                              Client Feedback{" "}
                              <span
                                className="status-revision ms-3"
                                style={{ fontSize: "12px", fontWeight: "400" }}
                              >
                                Revision Requested{" "}
                              </span>
                            </p>
                            <p className="water_time">
                              13 June 2024. 06:52 A. M.
                            </p>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="feedback">
                            <div className="row">
                              <div className="col-12">
                                <p className="client_rev">
                                  Hello <br />
                                  {item?.client_comment}
                                </p>
                                <hr />
                                <div className="attach_file">
                                  <p className="file_link">
                                    <GrAttachment className="me-2" />
                                    Attach file {item?.attachments?.length}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {i === 0 &&
                      orderLinkData?.orderServiceStatus?.status_code !== "6D" &&
                      orderLinkData?.orderServiceStatus?.status_code !== "E4" ?
                      <>
                        {
                          item.type !== "original" ? (
                            <div className="attach_file justify-content-end mt-3">
                              {reply === false ? (
                                <>
                                  <button
                                    onClick={() => {
                                      sendRevision(
                                        item?.id,
                                        { ...data, status: 1 },
                                        pagination
                                      )
                                      setShow({ ...show, revision_req: false })
                                    }
                                    }
                                    className="water_btn m-0 px-4 py-2"
                                  >
                                    {buttonLoding ? (
                                      <div
                                        className="spinner-border text-light "
                                        role="status"
                                      ></div>
                                    ) : (
                                      " Request for Original Data"
                                    )}
                                  </button>
                                  <button
                                    onClick={() => {
                                      setReply(true)

                                    }}
                                    className="water_btn m-0  ms-3 px-4 py-2"
                                    style={{
                                      border: "1px solid #494949",
                                      background: "white",
                                      color: "#494949",
                                    }}
                                  >
                                    {buttonLoding ? (
                                      <div
                                        className="spinner-border text-dark "
                                        role="status"
                                      ></div>
                                    ) : (
                                      " Request for Revision"
                                    )}

                                  </button>
                                  <hr className="mb-1" />
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <div className="d-flex justify-content-end">
                              <button
                                onClick={() => handleOpen()}
                                className="water_btn mt-0"
                              >
                                Mark Complete
                              </button>
                            </div>
                          )}
                      </> : ""
                    }
                    {i !== orderLinkData?.data?.length - 1 && <hr className="mb-1" />}
                    {reply && i === 0 ? (
                      <div className="row reply_btn">
                        <div className="col-12">
                          <div className="water">
                            <p className="water_p ">Request for Revision</p>
                            {/* <p className="water_time">
                                14 June 2024. 06:52 A. M.
                              </p> */}
                          </div>
                        </div>

                        <div className="col-12 mt-2">
                          <label htmlFor="" className="new_order_label">
                            Description
                          </label>
                          <div
                            className="new_order_inps"
                            style={{ height: "100px", paddingTop: "10px" }}
                          >
                            <textarea
                              name=""
                              id=""
                              placeholder="Write here"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  client_comment: e.target.value,
                                })
                              }
                              style={{
                                resize: "none",
                                background: "transparent",
                                borderColor: "transparent",
                                width: "100%",
                                outline: "none",
                              }}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12 mt-3">
                          <div className="file_attached">
                            <div>
                              <GrAttachment className="me-2" />
                              {fileName}
                            </div>
                            <div>
                              <input
                                type="file"
                                name=""
                                id="upload_file"
                                accept=".zip,.pdf,image"
                                onChange={handleFileChange}
                              />
                            </div>
                            <label htmlFor="upload_file">Upload File</label>
                          </div>
                          <span>You can upload .zip,.pdf,.image only<span className="text-danger"> *</span></span>
                        </div>
                        <div className="col-12">
                          <button
                            onClick={() => {
                              sendRevision(item?.id, data, pagination);
                              setShow({ ...show, revision_req: false })
                            }}
                            className="water_btn py-1"
                          >
                            Submit Revision
                          </button>
                          <button
                            onClick={() => setReply(false)}
                            className="water_btn_discard py-1"
                          >
                            Discard
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div className="order_popup">
                      <div className="row">
                        <div className="col p-0">
                          <div className="heading1">
                            <h1>Order Complete</h1>
                            <div onClick={handleClose} className="closeIcon">
                              <IoClose />
                            </div>
                          </div>
                          <hr style={{ margin: 0, borderColor: "#00000078" }} />
                          <div className="main_content">
                            <p className="main_heading">
                              Are you sure to
                              <br />
                              complete the order
                            </p>
                            <p className="para">
                              Give the Rating to help us to improve our
                              services*
                            </p>
                            <div className="star">
                              {Array.from({ length: 5 }, (_, index) => (
                                <div
                                  key={index}
                                  onClick={() => handleStarClick(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  {index < rating?.rating ? (
                                    <FaStar style={{ fontSize: "30px" }} /> // Render filled star if rating is greater than index
                                  ) : (
                                    <CiStar style={{ fontSize: "30px" }} />
                                  )}
                                </div>
                              ))}
                              <div>{rating?.rating}/5</div>
                            </div>
                            <div className="text">
                              <label htmlFor="textAra">
                                Comment (Optional)
                              </label>
                              <textarea
                                maxlength="80"
                                onChange={(e) =>
                                  setRating({
                                    ...rating,
                                    client_comment: e.target.value,
                                  })
                                }
                                id="textAra"
                                cols={30}
                                rows={4}
                                placeholder="Type here..."
                                defaultValue=""
                              />
                            </div>
                            <div className="text_para">
                              <p>
                                By Clicking on agree, you are asking us to
                                complete the task of vendor
                                <br />
                                (Vendor Name) ordered from you after we are
                                unable to undo this action.
                              </p>
                              <input type="checkbox" id="check" />
                              <label htmlFor="check">Complete</label>
                            </div>
                            <div className="ft_btn">
                              <button
                                className="btn btn-secondary"
                                onClick={() => {
                                  changeOrderStatus(
                                    {
                                      ...rating,
                                      id_service: orderServiceId,
                                      id: orderId,
                                    },
                                    pagination
                                  );
                                  setShow({ ...show, revision_req: false });
                                  handleClose()

                                }
                                }
                                style={{
                                  boxShadow:
                                    "8px 8px 24px 0 rgba(2, 2, 70, 0.15)",
                                }}
                              >
                                {buttonLoding ? (
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                ) : (
                                  "Done"
                                )}
                              </button>
                              <button
                                className="btn btn-light"
                                style={{ color: "#637381" }}
                              >
                                {" "}
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
            </div>
          );
        })}
        {/* </> */}
        {/* } */}
      </div>
    </div>
  );
}

export default RevisionRequest;
