import React, { useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import confirmOrder from "../../../asset/icons/confirmOrder.png";
import { GrAttachment } from "react-icons/gr";
import { useAuth } from "../../../Context/ContextProvider";
function RevisionRequest({ show, setShow,orderServiceId }) {
  const [reply, setReply] = useState(false);
  const {getOrderLinkData,orderLinkData,setOrderLinkData}=useAuth()
  useEffect(()=>{
    getOrderLinkData(orderServiceId)
    // eslint-disable-next-line
  },[])
  const [data,setData]=useState({
    title:"",
    description:"",
    link:"",
    type:"sample",
    status:1,
  })
  const [fieldErrors,setFieldErrors]=useState()
  function validateData(data) {
    let errors = {};

    if (!data.title) {
      errors.title = "Title is required.";
    }
    if (!data.link) {
      errors.link = "Link is required.";
    }
    else if(!validateUrl(data.link)){
      errors.link = "Invalid URL!";
    }
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  }
  const validateUrl = (url) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)' + // protocol (required)
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))' + // domain name or IP address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return urlPattern.test(url);
  };

  return (
    <div>
      <div className="new_order_nav">
        <div>
          <h4 className="d-flex align-items-center">
            {" "}
            <img
              src={confirmOrder}
              alt=""
              style={{ marginRight: "11px", width: "22px", height: "19px" }}
            />
            Order ID 235487
          </h4>
        </div>
        <div
          className="new_order_nav_btn"
          onClick={() => setShow({ ...show, revision_req: false })}
        >
          <button className="btn_11" style={{ borderRadius: "4px" }}>
            back <IoIosArrowRoundBack />{" "}
          </button>
        </div>
      </div>
      <div className="table_box">
        {orderLinkData?.map((item)=>{
          return (
            <div className="row">
            <div className="col-12">
              <div className="water">
                <p className="water_p">Watermarked Sample Data Link</p>
                <p className="water_time">12 June 2024. 06:52 A. M.</p>
              </div>
            </div>
            <div className="col-lg-3 col-12 my-2">
              <p className="new_order_label m-0">Sample Data Title</p>
              <p className="sample_p">{item?.title}</p>
            </div>
            <div className="col-lg-3 col-12 my-2">
              <p className="new_order_label m-0">Watermarked Sample Data Link</p>
              <p className="sample_p">{item?.link}</p>
            </div>
            <div className="col-12">
              <div className="new_order_label">Sample Data Description</div>
              <p className="sample_p">
            {item?.description}
              </p>
              <hr />
            </div>
            <div className="client_feed">
              <div className="col-12 mb-2">
                <div className="water">
                  <p className="water_p d-flex align-items-center">
                    Client Feedback{" "}
                    <span
                      className="status-revision ms-3"
                      style={{ fontSize: "12px", fontWeight: "400" }}
                    >
                      Revision Requested{" "}
                    </span>
                  </p>
                  <p className="water_time">13 June 2024. 06:52 A. M.</p>
                </div>
              </div>
              <div className="col-12">
                <div className="feedback">
                  <div className="row">
                    <div className="col-12">
                      <p className="client_rev">
                        Hello <br />
                       {item?.client_comment}
                      </p>
                      <hr />
                      <div className="attach_file">
                        <p className="file_link">
                          <GrAttachment className="me-2" />
                          Attach file {item?.attachments?.length}
                        </p>
                        {reply === false ? (
                          <button
                            onClick={() => setReply(true)}
                            className="water_btn m-0 px-4 py-1"
                          >
                            Reply
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {reply ? (
                      <div className="row reply_btn">
                        <div className="col-12">
                          <hr />
                          <div className="water">
                            <p className="water_p">Revision # 1</p>
                            <p className="water_time">
                              14 June 2024. 06:52 A. M.
                            </p>
                          </div>
                        </div>
  
                        <div className="col-lg-6 col-12 mt-2">
                          <label htmlFor="" className="new_order_label">
                            Revise Data Title
                          </label>
                          <div className="new_order_inps">
                            <input
                              type="text"
                              name=""
                              id=""
                              onChange={(e)=>{setData({...data,title:e.target.value})}}
                              placeholder="Revise Data Title"
                            />
                             {fieldErrors?.title && <span className="error_text" style={{top:"100%", position: "absolute" }}>{fieldErrors?.title}</span>}
                          </div>
                        </div>
                        <div className="col-lg-6 col-12   mt-2 ">
                          <label htmlFor="" className="new_order_label">
                            Revise Data Link
                          </label>
                          <div className="new_order_inps">
                            <input
                              type="text"
                              name=""
                              onChange={(e)=>{setData({...data,link:e.target.value})}}

                              id=""
                              placeholder="e.g. Dropbox, Google Drive"
                            />
                              {fieldErrors?.link && <span className="error_text" style={{top:"100%", position: "absolute" }}>{fieldErrors?.link}</span>}
                          </div>
                        </div>
                        <div className="col-12 mt-3">
                          <label htmlFor="" className="new_order_label">
                            Description
                          </label>
                          <div
                            className="new_order_inps"
                            style={{ height: "150px" }}
                          >
                            <textarea
                            onChange={(e)=>{setData({...data,description:e.target.value})}}
                              name=""
                              id=""
                              placeholder="Write here"
                              style={{
                                resize: "none",
                                background: "transparent",
                                borderColor: "transparent",
                                width: "100%",
                                outline: "none",
                              }}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <button 
                           onClick={() => {
                            if(validateData(data)){
                              setOrderLinkData({...data,order_id:10,order_service_id:orderServiceId})
                              setShow({ ...show, await_data: false })
                            }
                            }} className="water_btn">
                            Submit Revise Data
                          </button>
                          <button
                            onClick={() => setReply(false)}
                            className="water_btn_discard"
                          >
                            Discard
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
        })}
      
      </div>
    </div>
  );
}

export default RevisionRequest;
