import React from 'react'
import img1 from '../../asset/Frame 6.svg'
import img2 from '../../asset/Frame 7.svg'
import img3 from '../../asset/Frame 8.svg'
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos";
function Counter() {
    AOS.init({
        duration: 800, // Animation duration
        easing: "ease-in-out", // Easing option
        once: false, // Whether animation should only happen once
      });
  return (
    <div className='counter '>
        <div className="container">

       
        <div className='row  d-flex justify-content-between'style={{margin:"2px"}}>

        <div className='col-lg-3  col-12 ' data-aos="fade-up">
            <div className='icons_counter new-icons_counter-1'>
                <img src={img3} alt='...'/>
            </div>
            <div className='count count-1'>
                <h4 style={{margin:"0"}}>24 +</h4>
                <h4>Experience</h4>
            </div>
        </div>
        <div className='col-lg-3 col-12 ' data-aos="fade-down">
        <div className='icons_counter'>
                <img src={img1} alt='...'/>
            </div>
            <div className='count'>
                <h4 style={{margin:"0"}}>100 +</h4>
                <h4>Professionals</h4>
            </div>
        </div>
        <div className='col-lg-3 col-12 justify-content-lg-end' data-aos="fade-up">
        <div className='icons_counter new-icons_counter-2'>
                <img src={img2} alt='...'/>
            </div>
            <div className='count'>
                <h4 style={{margin:"0"}}>24 +</h4>
                <h4>Projects</h4>
            </div>
        </div>
        </div>
    </div>
    </div>
  )
}

export default Counter