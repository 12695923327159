import React, { useEffect, useState } from "react";
import image from "../../asset/icons/py_1.png";
import image1 from "../../asset/icons/py_2.png";
import image2 from "../../asset/icons/py_3.png";
import pendPay from "../../asset/icons/pendingPayment.png";
import { FaRegEye } from "react-icons/fa";
import { IoCalendarNumberOutline, IoClose } from "react-icons/io5";
import { CiSaveDown2, CiSearch, CiWarning } from "react-icons/ci";
import { VscSettings } from "react-icons/vsc";
import { Checkbox, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Menu from "@mui/material/Menu";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import payme from "../../asset/icons/payment.png";
import { useAuth } from "../../Context/ContextProvider";
import Pagination from "@mui/material/Pagination";
import PaymentDetail from "./PaymentDetail";
import { BiSolidPaperPlane } from "react-icons/bi";
import { Link } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
};
function Payment() {
  const [open, setOpen] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen3(true);
  const handleClose = () => setOpen(false);
  const handleClose3 = () => setOpen3(false);
  const { getVendorPayment, paymentData, requestPayment,buttonLoding } = useAuth()
  const [show, setshow] = useState(false);
  const [pagination, setPagination] = useState({
    payment_status: [

    ],
    name: "",
    created_at: "",
    page: 1,
    per_page: 10
  })
  useEffect(() => {
    getVendorPayment(pagination)
    // eslint-disable-next-line
  }, [])
  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&::before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  });
  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{
          "&:hover": { bgcolor: "transparent" },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  }
  const vendorData = JSON.parse(localStorage.getItem("vendor_data"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [payment,setPayment]=useState({})
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick1 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [data, setData] = useState()
  const handlePageChange = (event, value) => {
    setPagination({ ...pagination, page: value }); // Use the new page number provided by MUI
    // Perform your data fetching or state update here
    getVendorPayment({ ...pagination, page: value })
  };
  const getStatusClass = (status) => {

    switch (status) {
      case "withdraw requested":
        return "status-original-requested";
      case "Partially Confirmed":
        return "status-confirmed";
      case "pending":
        return "pending";
      case "completed":
        return "status-completed";
      case "declined":
        return "status-cancel";
      default:
        return "";
    }
  };
  const handleDateChange = (date) => {
    const adjustedDate = dayjs(date[0]).format("YYYY-MM-DD");
    const adjustedDate1 = dayjs(date[1]).format("YYYY-MM-DD");
    console.log(adjustedDate + "to" + adjustedDate1);
    setPagination({
      ...pagination,
      created_at: adjustedDate + " to " + adjustedDate1,
    });
    if(adjustedDate &&adjustedDate1){
      getVendorPayment({
        ...pagination,
        created_at: adjustedDate + " to " + adjustedDate1,
      })

    }
  };

  return (
    <>
      <div className="order_page">
        <div className="new_order_nav px-2">
          <div>
            <h4 className="d-flex align-items-center">  
               <img src={payme} alt="" style={{ marginRight: "11px", width: "22px", height: "19px" }} />Payment </h4>

          </div>



        </div>
        <div >
          <div className="py_main_card">
            <div className="row m-auto mb-3" style={{ width: "99%" }}>
              <div className="col-lg-3 col-12 ps-0">
                <div className="py_card">
                  <h5
                    style={{
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "#545468",
                    }}
                  >
                    TOTAL EARNING
                  </h5>
                  <div className="py_inner_head">
                    <h3>£{paymentData?.totalEarnings ? parseFloat(paymentData?.totalEarnings)?.toFixed(2) : 0.00}</h3>

                    <img
                      src={image}
                      alt=""
                      style={{ width: "35px", height: "32px" }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "#545468",
                    }}
                  >
                    Includes amount of earning, withdraw and transaction in process
                    till today
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-12 ">
                <div className="py_card">
                  <h5
                    style={{
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "#545468",
                    }}
                  >
                    Current Balance
                  </h5>
                  <div className="py_inner_head">
                    <h3>£{paymentData?.currentBalance ? paymentData?.currentBalance : 0.00}</h3>

                    <img
                      src={image1}
                      alt=""
                      style={{ width: "35px", height: "32px" }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "#545468",
                    }}
                  >
                    Amount is approved, in a wallet and Ready for withdraw
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-12 ">
                <div className="py_card">
                  <h5
                    style={{
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "#545468",
                    }}
                  >
                    Balance in Process
                  </h5>
                  <div className="py_inner_head">
                    <h3>£{paymentData?.balanceInProcess ? paymentData?.balanceInProcess : 0.00}</h3>

                    <img
                      src={image2}
                      alt=""
                      style={{ width: "35px", height: "32px" }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "#545468",
                    }}
                  >
                    Amount is under review’s process for approval and soon be
                    available for withdraw.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-12 ">
                <div className="py_card">
                  <h5
                    style={{
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "#545468",
                    }}
                  >
                    Pending Balance
                  </h5>
                  <div className="py_inner_head">
                    <h3>£{paymentData?.pendingEarnings ? paymentData?.pendingEarnings : 0.00}</h3>

                    <img
                      src={pendPay}
                      alt=""
                      style={{ width: "35px", height: "32px" }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: "11px",
                      fontWeight: "500",
                      color: "#545468",
                    }}
                  >
                   Total amount not yet requested for payout.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div  style={{ margin:'2rem .5rem .5rem'}}>
            <div className="table_main">
              <div className="table_box" style={{ height: "78vh" }}>
                <div className="filter_main d-flex justify-content-between" style={{ marginBottom: "15px" }}>
                  <div>
                    <label>
                      <CiSearch className="icons" />
                      <input type="search" onChange={(e)=>{
                        setPagination({...pagination,name:e.target.value})
                        setTimeout(() => {
                          getVendorPayment({...pagination,name:e.target.value})
                        }, 1500);
                        }} placeholder="Search" />
                    </label>
                    <span onClick={handleClick}>
                      Date <IoCalendarNumberOutline className="icons" />
                    </span>
                    <span onClick={handleClick1}>
                      Filter <VscSettings className="icons" />
                    </span>
                  {pagination?.created_at!=="" || pagination?.name!==""||pagination?.payment_status?.length!==0?  <button style={{color:"white",background:"#494949",border:"none",padding:".3rem 1rem",borderRadius:"5px"}} onClick={()=>{
                    setPagination({
                      payment_status: [],
                      name: "",
                      created_at: "",
                      page: 1,
                      per_page: 10
                    })
                    getVendorPayment({
                      payment_status: [],
                      name: "",
                      created_at: "",
                      page: 1,
                      per_page: 10
                    })
                  }}>Clear</button>:""}
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      style={{ marginLeft: "-180px", marginTop: ".2rem" }}
                      open={open1}
                      onClose={handleClose1}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <div
                        style={{ width: "400px", height: "450px", padding: ".5rem" }}
                      >
                        <div className="row">
                          <div className="col-12">
                            <div className="row" style={{ margin: "1rem" }}>
                              <div
                                style={{
                                  background: "#494949",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "white",
                                  borderRadius: "5px",
                                }}
                                className="col-6 py-2"
                              >
                                <span>From</span>
                              </div>
                              <div
                                style={{
                                  background: "#F0F0F0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                className="col-6 py-2"
                              >
                                <span>To</span>
                              </div>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={[
                                  "DateRangeCalendar",
                                  "DateRangeCalendar",
                                ]}
                              >
                                <DemoItem>
                                  <DateRangeCalendar onChange={handleDateChange} calendars={1} />
                                </DemoItem>
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                  
                        </div>
                      </div>
                    </Menu>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl2}
                      style={{ marginLeft: "-130px", marginTop: ".2rem" }}
                      open={open2}
                      onClose={handleClose2}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <div
                        className="filter_dashbord"
                        style={{ width: "220px", height: "270px", padding: ".5rem" }}
                      >
                        <h5>Filter</h5>
                        <p style={{ fontSize: "12px", color: "#53545C" }}>
                          Order Status
                        </p>
                        <ul className="filter_ul">
                     
                          <li>
                            <input type="checkbox"
                             checked={pagination?.payment_status?.includes("pending")}
                             onChange={(e)=>{
                              if(e.target.checked){
                                let array=[...pagination?.payment_status]
                                array.push("pending")
                                setPagination({...pagination,payment_status:array})
                              }
                              else{
                                let array = [...pagination?.payment_status];
                                array = array.filter(status => status !== "pending");
                                setPagination({ ...pagination, payment_status: array });
                              }
                             }}
                            style={{ marginRight: ".5rem" }} />{" "}
                            <span
                              className="expired"
                              style={{ color: "#FEB723", background: "#F9FFB8" }}
                            >
                              Pending
                            </span>
                           
                          </li>
        

                          <li>
                            <input 
                             checked={pagination?.payment_status?.includes("completed")}
                             onChange={(e)=>{
                              if(e.target.checked){
                                let array=[...pagination?.payment_status]
                                array.push("completed")
                                setPagination({...pagination,payment_status:array})
                              }
                              else{
                                let array = [...pagination?.payment_status];
                                array = array.filter(status => status !== "completed");
                                setPagination({ ...pagination, payment_status: array });
                              }
                             }}
                            type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                           <span
                              className="expired"
                              style={{ color: "#74B087", background: "#DEEEE8" }}
                            >
                              Completed
                            </span>
                          </li>
                          <li>
                            <input 
                             checked={pagination?.payment_status?.includes("declined")}
                             onChange={(e)=>{
                              if(e.target.checked){
                                let array=[...pagination?.payment_status]
                                array.push("declined")
                                setPagination({...pagination,payment_status:array})
                              }
                              else{
                                let array = [...pagination?.payment_status];
                                array = array.filter(status => status !== "declined");
                                setPagination({ ...pagination, payment_status: array });
                              }
                             }}
                              type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                            <span
                              className="expired"
                              style={{ color: "#fb1e1e", background: "#fc9a9a55" }}
                            >
                              Declined
                            </span>
                          </li>
                          <li>
                            <input 
                             checked={pagination?.payment_status?.includes("withdraw requested")}
                             onChange={(e)=>{
                              if(e.target.checked){
                                let array=[...pagination?.payment_status]
                                array.push("withdraw requested")
                                setPagination({...pagination,payment_status:array})
                              }
                              else{
                                let array = [...pagination?.payment_status];
                                array = array.filter(status => status !== "withdraw requested");
                                setPagination({ ...pagination, payment_status: array });
                              }
                             }}
                              type="checkbox" style={{ marginRight: ".5rem" }} />{" "}
                            <span
                              className="expired"
                              style={{ color: "#CC5C1D", background: "#FFF2EB" }}
                            >
                             withdraw requested
                            </span>
                          </li>
                        </ul>
                        <button onClick={()=>{
                          getVendorPayment(pagination)
                        }}>Filter</button>
                      </div>
                    </Menu>
                  </div>

                  <div>
                    <label style={{ border: "none" }}>Show entries:</label>
                    <select
                      value={pagination?.per_page}
                      style={{
                        width: "200px",
                        border: "1px solid lightgray",
                        padding: ".5rem",
                        borderRadius: "5px",
                        outline: "none",
                      }}
                      onChange={(e) => {
                        console.log(paymentData?.pagination?.total_items);
                        
                        if (paymentData?.pagination?.total_items < e.target.value) {
                          setPagination({
                            ...pagination,
                            per_page: e.target.value,
                            page: 1,
                          });
                          getVendorPayment({ ...pagination,
                            per_page: e.target.value,
                            page: 1,})
                        } else {
                          setPagination({ ...pagination, per_page: e.target.value });
                            getVendorPayment({ ...pagination, per_page: e.target.value })
                        }
                      }}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                    </select>
                  </div>
                </div>
                <div className="table_payment">
                  <table className="table table-sm ">
                    <thead style={{ position: "sticky", top: "0" }}>
                      <tr>
                        <th scope="col">
                          {" "}
                          {/* <BpCheckbox /> */}
                        </th>
                        <th scope="col"  className="oder_th">Order ID</th>
                        <th scope="col"  className="oder_th">Order Date</th>
                        <th scope="col"  className="oder_th">Client Name</th>
                        <th  className="oder_th" style={{width:"200px"}} scope="col">Location</th>
                        <th scope="col"  className="oder_th">Order Amount</th>
                        <th scope="col"  className="oder_th"> Status</th>
                        <th scope="col"  className="oder_th">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {buttonLoding?(
                      <tr>
                        <td colSpan={9}>
                          <div style={{ height: "400px", width: "100%" }}>
                            <p
                              style={{ height: "50px" }}
                              className="placeholder-glow row"
                            >
                              <span className="placeholder col-12 h-100 rounded"></span>
                            </p>
                            <p
                              style={{ height: "50px" }}
                              className="placeholder-glow row"
                            >
                              <span className="placeholder col-12 h-100 rounded"></span>
                            </p>
                            <p
                              style={{ height: "50px" }}
                              className="placeholder-glow row"
                            >
                              <span className="placeholder col-12 h-100 rounded"></span>
                            </p>
                            <p
                              style={{ height: "50px" }}
                              className="placeholder-glow row"
                            >
                              <span className="placeholder col-12 h-100 rounded"></span>
                            </p>
                          </div>
                        </td>
                      </tr>
                    ):(
                      <>
                                            {paymentData?.vendorPayments?.length? paymentData?.vendorPayments?.map((item) => {
                        return (
                          <tr>
                            <th scope="row">
                              {" "}
                              {/* <BpCheckbox /> */}
                            </th>
                            <td>{item?.order?.order_number}</td>
                            <td>
                              {item?.order_date}
                            </td>
                            <td>{item?.order?.shippingCharges?.name ? item?.order?.shippingCharges?.name : item?.name}</td>
                            <td>{item?.location}</td>
                            <td>£{item?.order_amount}</td>
                            <td>
                              <span style={{textTransform:"capitalize"}} className={getStatusClass(item?.payment_status)} >{item?.payment_status}</span>
                            </td>
                            <td>
                              <button
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  color: "#7C7C7C",
                                  background: "#ECECEC",
                                }}
                                className="view_button"
                                onClick={() => {
                                  setshow(true)
                                  setData(item?.order)
                                  setPayment(item)
                                }}
                              >
                                View <FaRegEye style={{ fontSize: "15px" }} />
                              </button>
                              {item?.payment_status === "pending" || item?.payment_status === "declined" ? <button
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  color: "#7C7C7C",
                                  background: "#ECECEC",
                                }}
                                className="complete_button"
                                onClick={async () => {
                                  if(vendorData?.data?.user?.is_account_connected){
                                    let result = await requestPayment({
                                      order_service_id: item?.orderService?.id
                                    })
                                    if (result?.success) {
                                      handleOpen()
                                    }
                                  }
                                  else{
                                    handleOpen1()
                                  }
                                  
                                }}
                              >
                                Request Withdraw{" "}
                                <CiSaveDown2 style={{ fontSize: "15px" }} />
                              </button> : ""}
                              {item?.payment_status === "completed" && <button
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  color: "#D1D1D1",
                                  background: "#888888",
                                  cursor: "not-allowed"
                                }}
                                className="complete_button"
                              // onClick={async()=>{
                              //  let result=await requestPayment({
                              //     order_service_id:item?.orderService?.id
                              //   })
                              //   if(result?.success){
                              //     handleOpen()
                              //   }
                              // }}
                              >
                                Request Withdraw{" "}
                                <CiSaveDown2 style={{ fontSize: "15px" }} />
                              </button>}
                              {item?.payment_status === "withdraw requested" && <button
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  color: "#D1D1D1",
                                  background: "#888888",
                                  cursor: "not-allowed"
                                }}
                                className="complete_button"
                              // onClick={async()=>{
                              //  let result=await requestPayment({
                              //     order_service_id:item?.orderService?.id
                              //   })
                              //   if(result?.success){
                              //     handleOpen()
                              //   }
                              // }}
                              >
                                Request Withdraw{" "}
                                <CiSaveDown2 style={{ fontSize: "15px" }} />
                              </button>}

                            </td>
                          </tr>
                        )
                      }): 
                      <tr>
                      <td colSpan={8} style={{ border: "none" }}>
                        <h5 className="text-center">No Data Found !</h5>
                      </td>
                    </tr>}
                      </>
                    )}

                      {/* {
                        paymentData?.vendorPayments?.map((item) => {
                        return (
                          <tr>
                            <th scope="row">
                              {" "}
                              <BpCheckbox />
                            </th>
                            <td>{item?.order?.order_number}</td>
                            <td>
                              {item?.order_date}
                            </td>
                            <td>{item?.order?.shippingCharges?.name ? item?.order?.shippingCharges?.name : item?.name}</td>
                            <td>{item?.location}</td>
                            <td>£{item?.order_amount}</td>
                            <td>
                              <span style={{textTransform:"capitalize"}} className={getStatusClass(item?.payment_status)} >{item?.payment_status}</span>
                            </td>
                            <td>
                              <button
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  color: "#7C7C7C",
                                  background: "#ECECEC",
                                }}
                                className="view_button"
                                onClick={() => {
                                  setshow(true)
                                  setData(item?.order)
                                  setPayment(item)
                                }}
                              >
                                View <FaRegEye style={{ fontSize: "15px" }} />
                              </button>
                              {item?.payment_status === "pending" || item?.payment_status === "declined" ? <button
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  color: "#7C7C7C",
                                  background: "#ECECEC",
                                }}
                                className="complete_button"
                                onClick={async () => {
                                  if(vendorData?.data?.user?.is_account_connected){
                                    let result = await requestPayment({
                                      order_service_id: item?.orderService?.id
                                    })
                                    if (result?.success) {
                                      handleOpen()
                                    }
                                  }
                                  else{
                                    handleOpen1()
                                  }
                                  
                                }}
                              >
                                Request Withdraw{" "}
                                <CiSaveDown2 style={{ fontSize: "15px" }} />
                              </button> : ""}
                              {item?.payment_status === "completed" && <button
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  color: "#D1D1D1",
                                  background: "#888888",
                                  cursor: "not-allowed"
                                }}
                                className="complete_button"
                              // onClick={async()=>{
                              //  let result=await requestPayment({
                              //     order_service_id:item?.orderService?.id
                              //   })
                              //   if(result?.success){
                              //     handleOpen()
                              //   }
                              // }}
                              >
                                Request Withdraw{" "}
                                <CiSaveDown2 style={{ fontSize: "15px" }} />
                              </button>}
                              {item?.payment_status === "withdraw requested" && <button
                                style={{
                                  fontSize: "10px",
                                  fontWeight: "bold",
                                  color: "#D1D1D1",
                                  background: "#888888",
                                  cursor: "not-allowed"
                                }}
                                className="complete_button"
                              // onClick={async()=>{
                              //  let result=await requestPayment({
                              //     order_service_id:item?.orderService?.id
                              //   })
                              //   if(result?.success){
                              //     handleOpen()
                              //   }
                              // }}
                              >
                                Request Withdraw{" "}
                                <CiSaveDown2 style={{ fontSize: "15px" }} />
                              </button>}

                            </td>
                          </tr>
                        )
                      })
                    } */}




                    </tbody>
                  </table>
                </div>

              </div>
              <div className="paginations d-flex justify-content-end my-3">
                <Stack spacing={2}>
                  <Pagination
                    count={paymentData?.pagination?.total_pages}
                    onChange={handlePageChange}
                  
                    shape="rounded"
                  />
                </Stack>
              </div>
              <PaymentDetail payment={payment} data={data} setData={setData} show={show} setshow={setshow} />
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className=" order_popup">
                    <div className="row p-0 m-0">
                      <div className="col p-0">
                        <div className="heading1" style={{ padding: "10px 20px" }}>
                          <h1>Withdraw amount</h1>
                          <div onClick={() => handleClose()} className="closeIcon">
                            <IoClose />
                          </div>
                        </div>
                        <hr style={{ margin: 0, borderColor: "#00000078" }} />
                        <div className="row">
          <div className="col-12 p-0">
            <div style={{background:"none"}} className="refund_request px-2">
              <div className="refun_icon text-center">
                <BiSolidPaperPlane style={{margin:"2rem 0"}} />
              </div>
              <div className="refund_content text-center">
                <h3>
                  Your Withdraw amount will be transferred
                  <br /> to the bank account that you mentioned
                  <br /> in the request within 6 working days after
                  <br /> approval.
                </h3>
                <p>
                  If you haven't received your Withdraw amount please contact
                  Customer support.
                </p>

                <button
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>
              <Modal
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className=" order_popup text-center rounded">
                  <CiWarning style={{color:"#b9444b",fontSize:"90px"}} />
                            <h5>Add information to start accepting money</h5>
                            <p className="mx-1">Photography Studio Hub partners with Stripe to help you receive payments and keep your personal bank and details secure.</p>
                            <hr/>
                            <div className="d-flex justify-content-between">
                              <button onClick={handleClose3} style={{border:"none",padding:".5rem 1rem",color:"#494949",fontSize:"16px",fontWeight:"500",borderRadius:"5px"}}>Cancel</button>
                              <Link to={'/vendor/password-setting'}>
                              <button style={{border:"none",padding:".5rem 1rem",background:"#494949",color:"white",fontSize:"16px",fontWeight:"500",borderRadius:"5px"}}>Countinue</button>
                              </Link>
                            </div>
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
