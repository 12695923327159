import React, { useEffect, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaPlus, FaRegEyeSlash } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { useAuth } from "../../Context/ContextProvider";
import PortfolioModal from "./PortfolioModal";
import portfol from "../../asset/icons/portfolio.png";
import { IoMdStarOutline } from "react-icons/io";
import CircularProgressWithLabel from './CircularWithValueLabel'
function VendorPorfolio({ permission }) {
  const {
    vendorServices,
    getStudio,
    studioData,
    getStudioPortFolioImage,
    delPortFolioImage,
    handleClick,
    setError,
    base_url,
    token,
    vedorServiceData,
    portfolioData,
    getPortFolioImage,
    setPortFolioLink,
    togalPortFolioImage,
    setButtonLoding,
    buttonLoding,
    app_key,
    userData,
  } = useAuth();
  // let arrray = [add_img1, add_img2, add_img3, add_img4, add_img5, add_img6];
  const [idx, setIdx] = useState([]);
  const [gallery, setGallery] = useState(0);
  const [result1, setResult1] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [linkData, setLinkData] = useState(["asd"]);
  const [previewVideo, setPreviewVideo] = useState();
  const [type, setType] = useState(
    permission?.includes("photography") ? "service" : "studio"
  );
  const [imageError, setImageError] = useState();
  const handleImageChange = (event) => {
    setImageError(""); // Clear any previous errors
    const files = event.target.files;
    const selected = [];
    const remainingSlots = previewImages.length === 0 ? 10 : 10 - previewImages.length;
    const maxImageSize = 10 * 1024 * 1024; // 10 MB in bytes

    if (files.length > remainingSlots && userData?.data?.role !== "vendor-admin") {
      setImageError(
        `You can upload only ${remainingSlots} more ${remainingSlots === 1 ? "image" : "images"}.`
      );
      event.target.value = ""; // Reset file input
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check if each image is less than or equal to 10 MB
      if (file.size > maxImageSize) {
        setImageError(`Image ${file.name} exceeds 10 MB. Please select a smaller file.`);
        event.target.value = ""; // Reset file input
        return;
      }

      selected.push(file);
    }

    // If all images pass validation, proceed with the submission
    handleSubmit(selected);
  };

  const [progress, setProgress] = useState(0);
  const handleSubmit = (selected) => {
    setButtonLoding(true);
    setProgress(0); // Reset progress to 0 when a new upload starts

    const formData = new FormData();
    formData.append(
      type === "service" ? "vendor_sub_service_id" : "studio_id",
      subserviceId
    );
    formData.append("vendor_service_id", serviceId);
    formData.append("is_active", 1);

    selected.forEach((image) => {
      formData.append("portfolio_images[]", image);
    });

    // Create a new XMLHttpRequest object to track progress
    const xhr = new XMLHttpRequest();

    // Configure it: POST request to the appropriate endpoint based on `type`
    xhr.open(
      "POST",
      `${base_url}vendor/${type === "service" ? "services" : "studios"}/portfolios/upload-image`
    );

    // Set the authorization and app-key headers
    xhr.setRequestHeader("Authorization", token);
    xhr.setRequestHeader("app-key", app_key);

    // Track the upload progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const uploadPercentage = Math.round((event.loaded / event.total) * 100);
        setProgress(uploadPercentage); // Update the progress state
      }
    };

    // Handle response when upload completes
    xhr.onload = () => {
      setButtonLoding(false);
      if (xhr.status === 200 || xhr.status === 201) {
        // Parse the response JSON
        const jsonData = JSON.parse(xhr.responseText);

        if (type === "service") {
          getPortFolioImage(subserviceId, serviceId);
        } else {
          getStudioPortFolioImage(subserviceId, serviceId);
        }
        setProgress(0); // Reset progress after completion
        handleClick();
        setError({ message: "Images Uploaded Successfully", color: "success" });
      } else {
        handleClick()
        setError({ message: "Image exceeds 10 MB. Please select a smaller file.", color: "error" });
        setProgress(0);
      }
    };

    // Handle network errors
    xhr.onerror = () => {
      setButtonLoding(false);
      setError({ message: "Network error. Please try again.", color: "error" });
    };

    // Send the request with form data
    xhr.send(formData);
  };

  const handleSubmit1 = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setButtonLoding(true);
    setProgress(0); // Reset progress to 0 when a new upload starts

    // Prepare the form data
    const formData = new FormData();
    formData.append(
      type === 'service' ? 'vendor_sub_service_id' : 'studio_id',
      subserviceId
    );
    formData.append('vendor_service_id', serviceId);
    formData.append('is_active', 1);
    formData.append('video', event.target.files[0]);
    const maxVideoSize = 50 * 1024 * 1024; // 50 MB in bytes
    if (event.target.files[0].size > maxVideoSize) {
      setButtonLoding(false);
      handleClick()
      setError({ message: 'Video file size should not exceed 50 MB.', color: 'error' });
      return;
    }
    // Create a new XMLHttpRequest object
    const xhr = new XMLHttpRequest();

    // Configure it: POST request to the appropriate endpoint based on `type`
    xhr.open(
      'POST',
      `${base_url}vendor/${type === 'service' ? 'services' : 'studios'}/portfolios/upload-video`
    );

    // Set the authorization and app-key headers
    xhr.setRequestHeader('Authorization', token);
    xhr.setRequestHeader('app-key', app_key);

    // Track the upload progress
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const uploadPercentage = Math.round((event.loaded / event.total) * 100);
        setProgress(uploadPercentage); // Update the progress state
      }
    };

    // Handle response when upload completes
    xhr.onload = () => {
      setButtonLoding(false);
      if (xhr.status === 200 || xhr.status === 201) {
        // Parse the response JSON
        const jsonData = JSON.parse(xhr.responseText);

        // Call the appropriate function based on `type`
        if (type === 'service') {
          getPortFolioImage(subserviceId, serviceId);
        } else {
          getStudioPortFolioImage(subserviceId, serviceId);
        }
        setProgress(0);
        handleClick(); // Display success alert/snackbar
        setError({ message: 'Video Uploaded Successfully', color: 'success' });
      } else {
        setError({ message: 'Something went wrong. Please try again.', color: 'error' });
      }
    };

    // Handle network errors
    xhr.onerror = () => {
      setButtonLoding(false);
      setError({ message: 'Network error. Please try again.', color: 'error' });
    };

    // Send the request with form data
    xhr.send(formData);
  };
  const [serviceId, setserviceId] = useState();
  const [subserviceId, setsubserviceId] = useState();
  const [result, setResult] = useState([]);
  useEffect(() => {
    if (type === "service") {
      vendorServices("photography", 0);
    } else {
      vendorServices("studio", 1);
      getStudio();
    }
  }, [type]);
  useEffect(() => {
    setPreviewImages(portfolioData?.images);
    setPreviewVideo(portfolioData?.video);
    setLinkData(
      portfolioData?.links?.length === 0
        ? [{ url: "", title: "" }]
        : portfolioData?.links
    );
  }, [portfolioData]);
  useEffect(() => {
    if (vedorServiceData?.vendor_services?.length > 0) {
      if (type === "service") {
        const initialServiceId =
          vedorServiceData?.vendor_services[0]?.vendor_service_id;
        const initialServiceId1 =
          vedorServiceData?.vendor_services[0]?.service?.sub_services[0]
            ?.vendor_sub_service_id;
        setserviceId(initialServiceId);
        setsubserviceId(initialServiceId1);
        getPortFolioImage(initialServiceId1, initialServiceId);
        setResult(vedorServiceData?.vendor_services[0]?.service?.sub_services);
      } else {
        const initialServiceId =
          vedorServiceData?.vendor_services[0]?.vendor_service_id;
        const initialServiceId1 = studioData[0]?.studio_id;
        setserviceId(initialServiceId);
        setsubserviceId(initialServiceId1);
        getStudioPortFolioImage(initialServiceId1, initialServiceId);
        setResult1(studioData);
      }
    }
  }, [vedorServiceData]);
  function searchBySubServiceName(searchTerm) {
    let results;
    if (type === "service") {
      if (searchTerm !== "") {
        vedorServiceData?.vendor_services?.map((item) => {
          if (item?.vendor_service_id === serviceId) {
            results = item?.service?.sub_services?.filter(
              (items) =>
                items &&
                items.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setResult(results);
          }
        });
      } else {
        vedorServiceData?.vendor_services?.map((item) => {
          if (item?.vendor_service_id === serviceId) {
            results = item?.service?.sub_services;
            setResult(results);
          }
        });
      }
    } else {
      results = studioData?.filter(
        (item) =>
          item.name &&
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setResult1(results);
    }
  }
  const validateUrl = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)" + // protocol (required)
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))" + // domain name or IP address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );
    return urlPattern.test(url);
  };
  const [errors, setErrors] = useState([]);
  const getErrorMessage = (idx, field) => {
    const error = errors.find(
      (error) => error.idx === idx && error.field === field
    );
    return error ? error.message : "";
  };
  const handleAddLink = (item) => {
    if (item?.url !== "" && item?.title !== "") {
      if (validateUrl(item?.url)) {
        let newData = [...linkData];
        newData.push({ url: "", title: "" });
        setLinkData(newData);
        setErrors([]); // Clear errors when adding a new link
      } else {
        setErrors([...errors, { idx, field: "url", message: "Invalid URL!" }]);
      }
    } else {
      if (item?.url === "") {
        setErrors([
          ...errors,
          { idx, field: "url", message: "URL is required!" },
        ]);
      }
      if (item?.title === "") {
        setErrors([
          ...errors,
          { idx, field: "title", message: "Title is required!" },
        ]);
      }
    }
  };
  const handleSubmit2 = () => {
    let validationErrors = [];
    linkData.forEach((item, idx) => {
      if (item.title === "") {
        validationErrors.push({
          idx,
          field: "title",
          message: "Title is required!",
        });
      }
      if (item.url === "") {
        validationErrors.push({
          idx,
          field: "url",
          message: "URL is required!",
        });
      } else if (!validateUrl(item.url)) {
        validationErrors.push({ idx, field: "url", message: "Invalid URL!" });
      }
    });

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
    } else {
      // Send data to the backend
      setErrors([]);
      if (type === "studio") {
        setPortFolioLink(
          {
            vendor_service_id: serviceId,
            studio_id: subserviceId,
            video_links: linkData,
          },
          type
        );
      } else {
        setPortFolioLink(
          {
            vendor_service_id: serviceId,
            vendor_sub_service_id: subserviceId,
            video_links: linkData,
          },
          type
        );
      }

      // Add your backend API call here
    }
  };
  return (
    <div>
      <div className="new_order_nav">
        <div>
          <h4 className="d-flex align-items-center">
            {" "}
            <img
              src={portfol}
              alt=""
              style={{ marginRight: "11px", width: "22px", height: "19px" }}
            />
            Portfolio
          </h4>
        </div>
      </div>
      <div className="table_box row">
        <div className="col-12">
          <p className="comm_para">
            Add portfolio images and videos related to specific services,
            including past work samples or your best projects. These media will
            be showcased to clients.
          </p>
          <hr className="mt-2 mb-3" />
        </div>

        {permission?.length === 2 ? (
          <div className="package_tab_btn">
            <button
              className={`profile_btns p-0 ${type === "service" ? "common_dark" : " common_light"
                }`}
              onClick={() => {
                // setData([]);
                setType("service");
                vendorServices("photography", 0);
                // plansName("service");
                // setStudioId();
              }}
            >
              Services(s)
            </button>
            <button
              className={`profile_btns mx-4 p-0 ${type === "studio" ? "common_dark " : "common_light"
                }`}
              onClick={() => {
                // setData([]);
                setType("studio");
                vendorServices("studio", 1);
                getStudio();
                // plansName("studio");
                // setServiceId();
              }}
            >
              Studio(s)
            </button>
          </div>
        ) : (
          <div />
        )}

        <div className="col-lg-3 col-12">
          <h5
            style={{
              margin: "0",
              color: "#494949",
              textTransform: "capitalize",
            }}
          >
            {/* Available Service(s) */}
            Available {type}
            <p
              style={{
                textTransform: "lowercase",
                display: "inline",
                margin: "0",
              }}
            >
              (s)
            </p>
          </h5>
        </div>
        <div className="col-lg-9 col-12">
          {vedorServiceData?.vendor_services?.length ? (
            <ul className="Info_gallery">
              <li
                onClick={() => setGallery(0)}
                className={`${gallery === 0 ? "Services_dark" : "Services_light"
                  }`}
              >
                Portfolio Photos
              </li>
              <li
                onClick={() => setGallery(1)}
                className={`${gallery === 1 ? "Services_dark" : "Services_light"
                  }`}
              >
                Introduction Video
              </li>
              <li
                onClick={() => setGallery(2)}
                className={`${gallery === 2 ? "Services_dark" : "Services_light"
                  }`}
              >
                External Video links
              </li>
            </ul>
          ) : (
            <p className="error_text">Please add some {type}</p>
          )}
        </div>
        <div className="col-lg-3 col-12">
          <div
            className="avail_main"
            style={{ maxHeight: "77vh", height: "77vh" }}
          >
            <div className="d-flex justify-content-between align-items-center"></div>
            <div className="avail_service avail_sel p-0">
              {type === "service" ? (
                <select
                  onChange={(e) => {
                    let array = [];
                    vedorServiceData?.vendor_services?.filter((item) => {
                      if (item?.service?.id == e.target.value) {
                        array = item?.service?.sub_services;
                        const initialServiceId = item?.vendor_service_id;
                        const initialServiceId1 =
                          item?.service?.sub_services[0]?.vendor_sub_service_id;
                        setserviceId(initialServiceId);
                        setsubserviceId(initialServiceId1);
                        getPortFolioImage(initialServiceId1, initialServiceId);
                      }
                    });
                    setResult(array);
                    // setResult1(array)
                  }}
                  name=""
                  id=""
                >
                  <option value="Select Services" hidden>
                    Select Services
                  </option>
                  {vedorServiceData?.vendor_services?.map((item) => {
                    return (
                      <option value={item?.service?.id}>
                        {item?.service?.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select
                  onChange={(e) => {
                    if (e.target.value === "all studio") {
                      setResult1(studioData);
                    } else {
                      let array = [];
                      studioData?.filter((item) => {
                        item?.studio_sub_services?.map((items) => {
                          if (items?.id === parseInt(e.target.value)) {
                            array.push(item);
                            // getPlanByService({ service_id: item?.service?.sub_services[0]?.vendor_sub_service_id,type:availbilty?.type })
                          }
                        });
                      });
                      setResult1(array);
                      setsubserviceId(array[0]?.studio_id);
                    }

                    //   setStudioId(array[0]?.id)
                    // //   getPlanByService(array[0]?.id)
                  }}
                  name=""
                  id=""
                >
                  <option value="all studio">All studio</option>
                  {vedorServiceData?.all_services?.map((item) => {
                    return item?.sub_services?.map((items) => {
                      return <option value={items?.id}>{items?.name}</option>;
                    });
                  })}
                </select>
              )}
            </div>
            <div className="avail_service pt-0">
              <input
                type="search"
                onChange={(e) => {
                  searchBySubServiceName(e.target.value);
                }}
                placeholder="Search Service"
              />
              <ul className="m-0 p-0">
                {type === "service"
                  ? result?.map((item) => (
                    <React.Fragment key={item.id}>
                      {item?.vendor_sub_service_id === subserviceId ? (
                        <li
                          onClick={() => {
                            if (!buttonLoding) {

                              setsubserviceId(item?.vendor_sub_service_id);
                              getPortFolioImage(
                                item?.vendor_sub_service_id,
                                serviceId
                              );
                              setGallery(0);
                            }
                          }}
                          className="avail_active"
                        >
                          {item?.name}
                        </li>
                      ) : (
                        <li
                          onClick={() => {
                            if (!buttonLoding) {
                              setsubserviceId(item?.vendor_sub_service_id);
                              getPortFolioImage(
                                item?.vendor_sub_service_id,
                                serviceId
                              );
                              setGallery(0);
                            }
                          }}
                        >
                          {item?.name}
                        </li>
                      )}
                    </React.Fragment>
                  ))
                  : result1?.map((item) => {
                    return (
                      <li
                        key={item?.studio_id}
                        onClick={() => {
                          setsubserviceId(item?.studio_id);
                          setserviceId(item?.vendor_service_id)
                          getStudioPortFolioImage(item?.studio_id, item?.vendor_service_id);
                        }}
                        style={{ cursor: "pointer" }}
                        className={`${subserviceId === item?.studio_id
                          ? "avail_active"
                          : ""
                          }`}
                      >
                        {item?.name}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-12" style={{ position: "relative" }}>
          <p
            className="error_text"
            style={{ position: "absolute", top: "-10px" }}
          >
            {imageError}
          </p>
          {vedorServiceData?.vendor_services?.length ? (
            <div
              className="service_page_handling"
              style={{ minHeight: "77vh", maxHeight: "77vh" }}
            >
              {/* ===============INFORMATION section================ */}

              {gallery === 0 && (
                <div className="services_gall">
                  <div className="row m-0">
                    <div className="col-12 mb-3 mt-0 ps-0 d-flex align-items-center">
                      {userData?.data?.role !== "vendor-admin" ? (
                        <>
                          {previewImages?.length < 10 && (
                            <>
                              <label
                                htmlFor="gellery_img"
                                style={{
                                  color: "white",
                                  background: "#494949",
                                }}
                                className="studio_sett_btn_upl d-flex justify-content-center align-items-center"
                              >
                                {buttonLoding ? (
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                ) : (
                                  "Upload"
                                )}
                              </label>
                              <input
                                disabled={buttonLoding}
                                id="gellery_img"
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                multiple
                                onChange={handleImageChange}
                              />
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "400",
                                  color: "#494949",
                                  marginLeft: "20px",
                                }}
                              >
                                {previewImages?.length}/10
                              </span>
                              {progress > 0 && <CircularProgressWithLabel value={progress} />}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <label
                            htmlFor="gellery_img"
                            style={{ color: "white", background: "#494949", cursor: "pointer" }}
                            className="studio_sett_btn_upl d-flex justify-content-center align-items-center"
                          >
                            {buttonLoding ? (
                              <div
                                className="spinner-border text-light"
                                role="status"
                              ></div>
                            ) : (
                              "Upload"
                            )}
                          </label>
                          <input
                            disabled={buttonLoding}
                            id="gellery_img"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                            multiple
                            onChange={handleImageChange}
                          />
                          {progress > 0 && <CircularProgressWithLabel value={progress} />}
                        </>
                      )}
                    </div>
                  </div>
                  {
                    buttonLoding ?
                      <div className="row">
                        <div className="col-lg-3 col-12 my-2 placeholder-glow ">
                          <div className="placeholder loader rounded "></div>
                        </div>
                        <div className="col-lg-3 col-12 my-2 placeholder-glow ">
                          <div className="placeholder loader rounded "></div>
                        </div>
                        <div className="col-lg-3 col-12 my-2 placeholder-glow ">
                          <div className="placeholder loader rounded "></div>
                        </div>
                        <div className="col-lg-3 col-12 my-2 placeholder-glow ">
                          <div className="placeholder loader rounded "></div>
                        </div>
                        <div className="col-lg-3 col-12 my-2 placeholder-glow ">
                          <div className="placeholder loader rounded "></div>
                        </div>
                        <div className="col-lg-3 col-12 my-2 placeholder-glow ">
                          <div className="placeholder loader rounded "></div>
                        </div>
                        <div className="col-lg-3 col-12 my-2 placeholder-glow ">
                          <div className="placeholder loader rounded "></div>
                        </div>
                        <div className="col-lg-3 col-12 my-2 placeholder-glow ">
                          <div className="placeholder loader rounded "></div>
                        </div>
                      </div>
                      :


                      <div className="row ">
                        {previewImages?.map((item, i) => {
                          return (
                            <div className="col-lg-3 pe-2  col-md-6 col-12 my-2">
                              <div className="ser_set_card">
                                <img
                                  style={{ height: "100%", width: "100%" }}
                                  src={item.url}
                                  alt=""
                                />
                                <div className="ser_set_content">
                                  <div>
                                    <IoMdStarOutline />
                                  </div>
                                  <div>
                                    <RiDeleteBinLine
                                      onClick={() =>
                                        delPortFolioImage(
                                          item?.id,
                                          serviceId,
                                          subserviceId,
                                          type
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    onClick={() => {
                                      togalPortFolioImage(
                                        item?.id,
                                        serviceId,
                                        subserviceId,
                                        type
                                      );
                                    }}
                                  >
                                    {item?.is_active === false ? (
                                      <FaRegEyeSlash />
                                    ) : (
                                      <IoEyeOutline />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                  }
                </div>
              )}
              {gallery === 1 && (
                <div className="services_gall">
                  <div className="row">
                    <div className="col-12 mb-3 mt-0 ps-0 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "400",
                            color: "#494949",
                            marginLeft: "20px",
                            marginRight: "1rem"
                          }}
                        >
                          {previewVideo?.length}/1
                        </span>
                        {progress > 0 && <CircularProgressWithLabel value={progress} />}
                      </div>
                      {previewVideo?.length > 0 && (
                        <label
                          for="uploade_video"
                          style={{
                            color: "white",
                            background: "#494949",
                            border: "none",
                            borderRadius: "5px",
                            padding: ".5rem 1.5rem",
                            cursor: "pointer",
                          }}
                        >
                          {buttonLoding ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            ></div>
                          ) : (
                            "Upload"
                          )}
                        </label>

                      )}
                      <input
                        disabled={buttonLoding}
                        onChange={handleSubmit1}
                        type="file"
                        accept="video/*"
                        id="uploade_video"
                        hidden
                      />
                    </div>
                    <div className="col-12 my-3 d-flex align-items-center">
                      {previewVideo?.length === 0 ? (
                        <div className="upload_video_main">
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "400",
                              color: "#494949",
                            }}
                            className="upload_video"
                          >
                            <span>
                              Drag & Drop Video here
                              <br />
                              or
                            </span>
                            <br />
                            <label for="uploade_video">
                              {buttonLoding ? (
                                <div
                                  className="spinner-border text-dark"
                                  role="status"
                                ></div>
                              ) : (
                                "Browse"
                              )}
                            </label>
                          </div>
                        </div>
                      ) : (
                        <div  style={{ width: "100%" }}>
                          <div className="portfoloiVideo">

                          <video
                            style={{ width: "100%" }}
                            autoPlay
                            loop={true}
                            src={previewVideo[0]?.url}
                          />
                          </div>
                          <div className="d-flex justify-content-center">
                            <button
                              style={{
                                background: "#494949",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                marginRight: "1rem",
                              }}
                              onClick={() =>
                                togalPortFolioImage(
                                  previewVideo[0]?.id,
                                  serviceId,
                                  subserviceId,
                                  type
                                )
                              }
                            >
                              {previewVideo[0]?.is_active == "1" ? (
                                <IoEyeOutline />
                              ) : (
                                <FaRegEyeSlash />
                              )}
                            </button>
                            <button
                              style={{
                                background: "#494949",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                              }}
                              onClick={() =>
                                delPortFolioImage(
                                  previewVideo[0]?.id,
                                  serviceId,
                                  subserviceId,
                                  type
                                )
                              }
                            >
                              <RiDeleteBinLine />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {gallery === 2 && (
                <div className="services_gall">
                  <div className="row">
                    <div
                      style={{ position: "relative" }}
                      className="col-12 mb-3 mt-0 ps-0 d-flex align-items-center justify-content-between"
                    >
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "400",
                          color: "#494949",
                          marginLeft: "20px",
                        }}
                      >
                        {linkData?.length}/10
                      </span>
                      {linkData?.length < 10 && (
                        <button
                          style={{
                            color: "white",
                            background: "#494949",
                            border: "none",
                            borderRadius: "5px",
                            padding: ".5rem 1.5rem",
                          }}
                          onClick={() => {
                            handleSubmit2();
                          }}
                        >
                          Save
                        </button>
                      )}

                      {errors.length > 0 && (
                        <span
                          className="error_text"
                          style={{
                            position: "absolute",
                            fontSize: "13px",
                            top: "100%",
                          }}
                        >
                          Title or link is empty or invalid!{" "}
                        </span>
                      )}
                    </div>
                    {linkData?.map((item, idx) => {
                      return (
                        <div className="row link_inp my-2">
                          <div
                            style={{ position: "relative" }}
                            className="col-5"
                          >
                            <label className="new_order_label" htmlFor="">
                              Title Link
                            </label>

                            <input
                              value={item?.title}
                              onChange={(e) => {
                                let newLinkData = [...linkData];
                                newLinkData[idx].title = e.target.value;
                                setLinkData(newLinkData);
                              }}
                              type="text"
                              placeholder="Tilte"
                            />
                            {getErrorMessage(idx, "title") && (
                              <span
                                className="error_text"
                                style={{
                                  position: "absolute",
                                  left: "1rem",
                                  fontSize: "13px",
                                  top: "100%",
                                }}
                              >
                                {getErrorMessage(idx, "title")}
                              </span>
                            )}
                          </div>
                          <div
                            style={{ position: "relative" }}
                            className="col-5"
                          >
                            <label className="new_order_label" htmlFor="">
                              URL Link{" "}
                            </label>

                            <input
                              value={item?.url}
                              onChange={(e) => {
                                let newLinkData = [...linkData];
                                newLinkData[idx].url = e.target.value;
                                setLinkData(newLinkData);
                              }}
                              type="text"
                              placeholder="www.youtube.com/abc"
                            />
                            {getErrorMessage(idx, "url") && (
                              <span
                                className="error_text"
                                style={{
                                  position: "absolute",
                                  left: "1rem",
                                  fontSize: "13px",
                                  top: "100%",
                                }}
                              >
                                {getErrorMessage(idx, "url")}
                              </span>
                            )}
                          </div>
                          <div className="col-2">
                            <PortfolioModal url={item?.url} />
                            <button
                              disabled={
                                linkData?.length === 1 && idx === 0
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                let newData = linkData?.filter((items, i) => {
                                  if (i !== idx) {
                                    return items;
                                  }
                                });
                                setLinkData(newData);
                              }}
                              style={{ background: "#B9444B" }}
                            >
                              <RiDeleteBinLine />
                            </button>
                            {linkData?.length - 1 === idx && (
                              <button
                                onClick={() => {
                                  handleAddLink(item);
                                }}
                                style={{ background: "#494949" }}
                              >
                                <FaPlus />
                              </button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default VendorPorfolio;
