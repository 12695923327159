import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function ServicessettEditor({ data, setData }) {
  const [value, setValue] = useState(data?.description || '');

  useEffect(() => {
    if (data?.description !== value) {
      setValue(data?.description || '');
    }
  }, [data?.description]);

  useEffect(() => {
    if (data?.description !== value) {
      setData(prevData => {
        if (prevData.description !== value) {
          return { ...prevData, description: value };
        }
        return prevData;
      });
    }
  }, [value, setData]);

  return (
    <div style={{ background: "#ECECEB", height:"320px", borderRadius:"4px"}} className='Service_setting_summer'>
      <ReactQuill
        style={{ width: "920px", height: "270px" }}
        value={value}
        onChange={setValue}
      />
    </div>
  );
}

export default ServicessettEditor;
