import React from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import Banner from '../Components/FAQs/Banner'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import Footer from '../Components/NavBarFooter/Footer'

function FAQs() {
  return (
    <>
    <div className='container'>
      <NavBar/>
      <Banner/>
      </div>
      <JoinOurTeam/>
      <div className="container">
        <Footer/>
    </div>
    </>
  )
}

export default FAQs
