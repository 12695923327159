import React, { useState } from 'react';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
    ConnectComponentsProvider,
    ConnectAccountOnboarding,
} from "@stripe/react-connect-js";
import { useAuth } from '../../Context/ContextProvider';




const OnboardingWrapper = () => {
    const { base_url, app_key, token,stripIdId } = useAuth()
    let vendorData = JSON.parse(localStorage.getItem("vendor_data"));
    const [stripeConnectInstance] = useState(() => {
        const fetchClientSecret = async () => {
            try {
                // Fetch client secret from your server
                const response = await fetch(`${base_url}account_session`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        "app-key": app_key,
                        'Authorization': token
                    },
                });
                if (!response.ok) {
                    const { error } = await response.json();
                    console.error('Error fetching client secret:', error);
                    return undefined;
                }
                const { client_secret: clientSecret } = await response.json();
                console.log(clientSecret);

                return clientSecret;
            } catch (error) {
                console.error('Error:', error);
                return undefined;
            }
        };

        return loadConnectAndInitialize({
            publishableKey: stripIdId?.partnerSetting?.publisher_key,
            fetchClientSecret,
        });
    });

    const handleOnboardingComplete = async (stripeResponse) => {
        console.log(stripeResponse);
        
        try {
            const response = await fetch(`${base_url}payments/connected-account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "app-key": app_key,
                    'Authorization': token
                },
                body: JSON.stringify({ is_account_connected: 1 }), // Send the account ID to your backend
            });
    
            if (response.ok) {
                const data = await response.json();
                const newUserData = { ...vendorData.data?.user };
    
                // Update the necessary field
                newUserData.is_account_connected = 1;
    
                // Ensure vendorData structure is correct
                vendorData.data.user = newUserData;
    
                // Save updated data to localStorage
                localStorage.setItem("vendor_data", JSON.stringify(vendorData));
                console.log('LocalStorage updated:', JSON.parse(localStorage.getItem("vendor_data")));
    
            } else {
                console.log('Server responded with an error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    

    return (<ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <ConnectAccountOnboarding
            onExit={handleOnboardingComplete}
        />
    </ConnectComponentsProvider>)
};

export default OnboardingWrapper;
