import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import img from '../../asset/icons/Vector.png'
import { useAuth } from '../../Context/ContextProvider';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));
function ProfileSetting() {
  const [profleData, setdata] = useState()
  const [phoneVerifcation, setPhoneVerificaton] = useState(false)
  const { getState,
    getCity,
    cityData,
    stateData,
    changeProfile,
    profileSetting,
    profileCode,
    sendCodeProfile,
    verifyCode,
    codeVerify,
    code,
    setVerify
  } = useAuth()
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("vendor_data"))
    if (data.data.user) {

      let newData = {
        name: data?.data?.user?.name,
        email: data?.data?.user?.email,
        city_id: data?.data?.user?.city_id,
        country_id: data?.data?.user?.country_id,
        state_id: data?.data?.user?.state_id,
        address: data?.data?.user?.address,
        phone_number: data?.data?.user?.phone_number,
        avatar: data?.data?.user?.avatar,
        roles: "client"
      }
      if (newData?.phone_number?.startsWith("44")) {
        newData.phone_number = newData?.phone_number.slice(2);
      }
      // newData.phone_number = newData?.phone_number?.split('44')[1]
      setdata({ ...newData, is_verified: true })
      getState()
      getCity(newData?.state_id)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (codeVerify?.success) {
      setPhoneVerificaton(false)
      setdata({ ...profleData, is_verified: true })
      setIsActive(false)
    }
    // eslint-disable-next-line
  }, [codeVerify])
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        changeProfile({ avatar: base64String })
        setdata({ ...profleData, avatar: base64String })
        const data = JSON.parse(localStorage.getItem("vendor_data"))
        // Check if data is valid
        if (data && data.data && data.data.user) {
          // Assuming base64String is defined elsewhere
          if (base64String) {
            data.data.user.avatar = base64String;
            localStorage.setItem("vendor_data", JSON.stringify(data));
          } else {
            console.error("base64String is undefined or null");
          }
        } else {
          console.error("Data retrieved from localStorage is invalid");
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const [fieldErrors, setFieldErrors] = React.useState({})
  const validateStep = () => {
    let errors = {
    };


    if (!profleData.name) {
      errors.name = "Name is required.";
    }
    if (!profleData.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(profleData.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!profleData.phone_number) {
      errors.phone_number = "Phone number is required.";
    } else if (!isValidUKPhoneNumber(profleData.phone_number)) {
      errors.phone_number = "Please enter a valid UK phone number.";
    }

    if (!profleData.state_id) {
      errors.state_id = "State is required.";
    }
    if (!profleData.city_id) {
      errors.city_id = "City is required.";
    }
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  // Function to check if the email is in a valid format
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidUKPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber.replace(/\D/g, '')
    // Regular expression for UK phone number validation
    const ukPhoneRegex = /^(?:0|\+44)?\s?(?:\d\s?){9,10}$/;
    // Check if the phone number is a valid UK phone number or its length is 11 or 13 characters
    return ukPhoneRegex.test(phone) && (phone.length === 10 || phone.length === 13);
  };
  const handleSubmit = () => {
    if (validateStep() && !phoneVerifcation) {
      profileSetting({ ...profleData, phone_number: "44" + profleData?.phone_number?.replace(/\s/g, '') })
    }
  };
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState();
  const check = () => {
    setFieldErrors({ ...fieldErrors, phone_number: "" })
    if (validateStep()) {
      sendCodeProfile("44" + profleData?.phone_number?.replace(/\s/g, ''))
    }
  }
  function startCountdown() {
    setSeconds(60); // Change this to the desired countdown duration
    setIsActive(true);
  }
  React.useEffect(() => {
    let intervalId;
    if (isActive) {
      intervalId = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      clearInterval(intervalId);
      setIsActive(false);
    }
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [isActive, seconds]);
  const handleChange = (e) => {
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/\D/g, '');
    // Format the phone number as "7700 900886"
    const firstPart = value.slice(0, 4);
    const secondPart = value.slice(4);
    let formattedValue = `${firstPart}`;
    if (secondPart.length > 0) {
      formattedValue += ` ${secondPart}`;
    }
    // Update the state with the formatted value
    setdata({ ...profleData, phone_number: formattedValue, is_verified: false })
    setVerify("")
  };
  
  return (
    <div>
      <div style={{ borderRadius: "0px" }} className='table_box profile_seting client_profile_setting'>
        <h3>Profile Setting</h3>
        <div className='d-flex justify-content-center'>
          <Stack direction="row" spacing={2}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
              badgeContent={
                <label for="profile_image">
                  <SmallAvatar alt="Remy Sharp" src={img} />
                </label>
              }
            >
              <Avatar style={{ width: "100px", height: "100px" }} className='mt-3' alt="Travis Howard" src={profleData?.avatar} />

            </Badge>
          </Stack>
        </div>
        <input onChange={handleFileInputChange} type='file' className='d-none' id='profile_image' />
        <div style={{ width: "80%", margin: "auto" }} className='row inp_section'>
          <div style={{ position: "relative" }} className='col-lg-6 col-md-6 col-12 my-3'>
            <label htmlFor="" className="new_order_label ">Full Name</label>
            {code?.success && <p className='text-center' style={{ fontSize: "13px" }}>Please enter the code here<span style={{ fontWeight: "bold" }}> : {code?.data?.code}</span> </p>}
            <input type='text' className="profile_input-1" onChange={(e) => setdata({ ...profleData, name: e.target.value })} value={profleData?.name} placeholder='Full Name' />
            {fieldErrors?.name && <span className="error_text" style={{ position: "absolute", left: "5%", top: "100%" }}>{fieldErrors?.name}</span>}
          </div>
          <div style={{ position: "relative" }} className='col-lg-6 col-md-6 col-12 my-3'>
            <label htmlFor="" className="new_order_label ">Email</label>
            <input className="profile_input-1" value={profleData?.email} readOnly onChange={(e) => setdata({ ...profleData, email: e.target.value })} type='text' placeholder='Email' />
            {fieldErrors?.email && <span className="error_text" style={{ position: "absolute", left: "5%", top: "100%" }}>{fieldErrors?.email}</span>}
          </div>
          <div className="col-lg-6 col-12 py-3">
            <label htmlFor="" className="new_order_label ">Contact</label>
            <div style={{ padding: "0", position: "relative", borderRadius: "5px" }} className="upload_title">
              <div className='client_contactss'>
                <img style={{ width: "30px", height: "20px" }} src='https://flagcdn.com/w320/gb.png' alt='...' />
                <span className='mx-2'>+44</span>
              </div>
              <input
                type="text"
                value={profleData?.phone_number}
                onChange={handleChange}
                placeholder="e.g 7700 900886"
                pattern="[0-9]{4} [0-9]{6}"
                className="inp"
                style={{ padding: "1rem" }}
              />
              <button onClick={() => {
                if (!isValidUKPhoneNumber(profleData.phone_number)) {
                  setFieldErrors({ ...fieldErrors, phone_number: "Please enter a valid UK phone number." })
                  // errors.phone_number = "Please enter a valid UK phone number.";
                } else {
                  setFieldErrors({ ...fieldErrors, phone_number: "" })
                  sendCodeProfile("44" + profleData?.phone_number?.replace(/\s/g, ''))
                  startCountdown()
                }
              }} style={{ color: "#0D99FF", right: "0", top: "0", height: "100%" }}>Send code</button>
            </div>
            {fieldErrors?.phone_number && <span className="error_text" style={{ position: "absolute" }}>{fieldErrors?.phone_number}</span>}
            {profileCode?.code && <span className="error_text" style={{ position: "absolute" }}>{profileCode?.code}</span>}
            {/* {error?.phone&& <span className="error_text" style={{ position: "absolute" }}>{error?.phone}</span>} */}
          </div>
          <div style={{ position: "relative" }} className='col-lg-6 col-md-6 col-12 my-3'>
            <label htmlFor="" className="new_order_label">Code</label>
            <div style={{ position: "relative" }}>

              <input className='profile_input-1' type='text' onChange={(e) => {
                if (e.target.value?.length === 6) {
                  // onNext()
                  verifyCode("44" + profleData?.phone_number?.replace(/\s/g, ''), e.target.value)

                }


              }} placeholder='Code' />

              {isActive && <span className='text-success' style={{ cursor: "pointer", position: "absolute", right: "20px", height: "70%", top: "15px" }}>00:{seconds}</span>}
            </div>
            {isActive === false && profleData?.is_verified === false ? <p className='text-center my-2' style={{ fontSize: "13px", position: "absolute" }}>Did not receive a code?  <span className='text-primary' onClick={check} style={{ cursor: "pointer" }}>Resend</span><br /></p> : ""}
            {codeVerify?.success && <span style={{ position: "absolute", left: "90%", top: "50%" }}><IoMdCheckmarkCircleOutline className='text-success' /></span>}
            {fieldErrors?.code && <span className="error_text" style={{ position: "absolute", left: "5%", top: "100%" }}>{fieldErrors?.code}</span>}
            {phoneVerifcation && <span className="error_text" style={{ position: "absolute", left: "5%", top: "100%" }}>Phone verification is required</span>}
          </div>


          <div style={{ position: "relative" }} className='col-lg-6 col-md-6 col-12 my-3'>
            <label htmlFor="" className="new_order_label"> State</label>
            <div className='pe-3' style={{ border:'1px solid #D0D5DD' , borderRadius:'8px' }}>
              <select className="profile_input-1" onChange={(e) =>  {
                getCity(e.target.value)
                setdata({ ...profleData, state_id: e.target.value });
              }} value={profleData?.state_id} style={{ color: "gray" }} >
                <option value="Choose State" style={{ color: "gray" }} hidden>Choose State</option>
                {stateData?.map((item) => {
                  return (
                    <option value={item?.id}>{item?.name}</option>
                  )
                })}
              </select>
            </div>
            {fieldErrors?.state_id && <span className="error_text" style={{ position: "absolute", left: "5%", top: "100%" }}>{fieldErrors?.state_id}</span>}
          </div>
          <div style={{ position: "relative" }} className='col-lg-6 col-md-6 col-12 my-3'>
            <label htmlFor="" className="new_order_label"> City</label>
            <div className='pe-3' style={{  border:'1px solid #D0D5DD' , borderRadius:'8px' }}>

              <select value={profleData?.city_id} onChange={(e) => setdata({ ...profleData, city_id: e.target.value })} style={{ color: "gray" }} >
                <option style={{ color: "gray" }} hidden value="Choose City">Choose City</option>
                {cityData?.map((item) => {
                  return (
                    <option value={item?.id}>{item?.name}</option>
                  )
                })}
              </select>
            </div>
            {fieldErrors?.state_id && <span className="error_text" style={{ position: "absolute", left: "5%", top: "100%" }}>{fieldErrors?.state_id}</span>}
          </div>
          <div style={{ position: "relative" }} className='col-12 my-3'>
            <label htmlFor="" className="new_order_label"> Address</label>
            <input className='profile_input-1' onChange={(e) => setdata({ ...profleData, address: e.target.value })} value={profleData?.address} type='text' placeholder='Address' />
            {fieldErrors?.address && <span className="error_text" style={{ position: "absolute", left: "5%", top: "100%" }}>{fieldErrors?.address}</span>}
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <button style={{ padding: ".5rem 3rem" }} onClick={handleSubmit} disabled={profleData?.is_verified ? false : true} className='btn_save'>Save</button>
        </div>
      </div>
    </div>
  )
}
export default ProfileSetting