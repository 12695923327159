import { MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react'
import { FaGlobeEurope, FaRegEye } from 'react-icons/fa';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { FcLeave } from "react-icons/fc";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AiFillCloseCircle } from 'react-icons/ai';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { useAuth } from '../../../Context/ContextProvider';
import { IoMdArrowDropdown } from 'react-icons/io';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CloseOutlined } from '@mui/icons-material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
  };
function Reschedule({service}) {

    const [cartData, setcard] = React.useState()
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [value, setValue] = React.useState();
    const [clientSecret, setClientSecret] = useState('');
    const [payAble,setpayAble]=useState(false)
    const [slots, setslots] = React.useState([])
    const [comment,setConmment]=useState()
    function convertTo12HourFormat(time24) {
        const [hours24, minutes24] = time24.split(':').map(Number);
        let hours12 = hours24 % 12 || 12; // Convert 0 to 12
        const ampm = hours24 < 12 ? 'AM' : 'PM';
        return `${hours12?.toString().padStart(2, '0')}:${minutes24?.toString().padStart(2, '0')} ${ampm}`;
      }
    const {availableDate,getavailableDate,venderAvailiblty,slotDataVendor,loading,rescheduleOrder,token,base_url , app_key,stripIdId}=useAuth()
    const dateOnly = value ? value.format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
    const handleMonthChange = (newMonth) => {
        const formattedMonth = newMonth.startOf('month').format('DD-MM-YYYY');
        getavailableDate({plan_availability_id:service?.plan?.plan_availability?.id,date:formattedMonth})
        // Add any additional logic you need to run when the month changes
      };
      React.useEffect(() => {
        var originalDate = new Date(dateOnly);
        
        // Array of weekday names
        var weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        var weekdayIndex = originalDate.getDay();
        
        // Get start of the month
        const startOfMonth = new Date(originalDate.getFullYear(), originalDate.getMonth(), 1);
        const day = String(startOfMonth.getDate()).padStart(2, '0'); // Ensures 2-digit day
        const month = String(startOfMonth.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = startOfMonth.getFullYear();
        
        // Construct the formatted date string
        const formattedMonth = `${day}-${month}-${year}`;
        // Format date as 'YYYY-MM-DD'
        const formattedDate = originalDate.toISOString().split('T')[0];
        venderAvailiblty({day_id: weekdays[weekdayIndex], date: formattedDate,plan_availability_id:service?.plan?.plan_availability?.id })
        getavailableDate({plan_availability_id:service?.plan?.plan_availability?.id,date:formattedMonth})
        // eslint-disable-next-line
      }, [dateOnly])
      
  function formatDate(dateString) {
    // Create a Date object from the original date string
    var originalDate = new Date(dateString);
    // Array of weekday names
    var weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    // Array of month names
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    // Get the day, month, and year from the Date object
    var day = originalDate.getDate();
    var monthIndex = originalDate.getMonth();
    var year = originalDate.getFullYear();
    var weekdayIndex = originalDate.getDay();
    // setData({...data,day_id:weekdays[weekdayIndex]})
    // Format the date string
    return weekdays[weekdayIndex] + " " + day + ", " + months[monthIndex] + " " + year;
  }
      React.useEffect(() => {
        // eslint-disable-next-line
        slotDataVendor?.filter((item) => {
          if (item?.is_reserved === 0) {
            return item
          }
        })
        setslots(slotDataVendor)
        // eslint-disable-next-line
      }, [slotDataVendor])
    const handleOpen1 = () =>{
         setOpen1(true)
         setValue()
        
        };
    const handleOpen = () => setOpen(true);
    const handleOpen3 = () => setOpen3(true);
    const [rescheduleFee1,setrescheduleFee]=useState()
    const handleOpen2 = () => {
        setOpen2(true);
        
        // Assuming rescheduling_charge_percentage is an integer like 10 for 10%
        const percentage = service?.rescheduled_data?.rescheduling_charge_percentage / 100;
    
        // Ensure service?.subtotal is a valid number
        const rescheduleFee = (percentage * parseFloat(service?.subtotal)).toFixed(2);
        setrescheduleFee(rescheduleFee)
        fetchClientSecret(rescheduleFee * 100)
    };
    const handleClose1 = () => {
        setOpen1(false)
    };
    const handleClose2 = () => {
        setOpen2(false)
    };
    const handleClose3 = () => {
        setOpen3(false)
    };
    const handleClose = () => setOpen(false);
    const parsedAvailableDates = availableDate?.map(date => dayjs(date, 'YYYY-MM-DD'));
    const disableUnavailableDates = (date) => {
        // Check if the date exists in the available dates
        return !parsedAvailableDates?.some(availableDate => availableDate.isSame(date, 'day'));
      };
      const options = {
        clientSecret,
        appearance: {
          theme: 'stripe',
        },
      };
    function checkRescheduleFee() {
        const currentTime = new Date();
        
        // Create a Date object for the service date and time
        const serviceDateTime = new Date(`${service?.service_date}T${service?.slot.start_time}`);
    
        // Calculate the time difference in hours
        const timeDifference = (serviceDateTime - currentTime) / (1000 * 60 * 60); // Convert to hours
    
        // Check if less than 48 hours
        if (timeDifference < service?.plan?.plan_availability?.rescheduling_time && timeDifference > 0) {
            // const rescheduleFee = (0.10 * parseFloat(service?.subtotal)).toFixed(2); // Calculate 10% of total
            setpayAble(true)
            handleOpen()
            setConmment(`Rescheduling at this time will incur a fee of ${service?.rescheduled_data?.rescheduling_charge_percentage}% of the booking amount. Would you like to proceed?`)
            
        } else {
            handleOpen()
            setpayAble(false)
            setConmment(`You can reschedule your booking at no additional cost. Select a new date and time to proceed.`)

        }
    }
    const [currentTime, setCurrentTime] = React.useState('');



    const stripePromise = loadStripe(stripIdId?.partnerSetting?.publisher_key);
  
    const fetchClientSecret = async (amount) => {
        const response = await fetch(`${base_url}payments/payment-intent?debug=1`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': token,
            'app-key':app_key
          },
          body: JSON.stringify({ amount: amount, currency: stripIdId?.partnerSetting?.currency_code}), // The amount in cents
        });
        const data = await response.json();
        setClientSecret(data.client_secret);
      };
      const Form = ({cartData }) => {

        const stripe = useStripe();
        const elements = useElements();
        const handleSubmit = async (event) => {
          event.preventDefault();
          if(cartData){
            if (!stripe || !elements) {
              console.error('Stripe or Elements not yet loaded');
              return;
            }
          
            // Confirm the payment without redirecting
            const { error, paymentIntent } = await stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: 'http://localhost:3000/checkout',
                 // Provide a valid return URL with https://
              },
              redirect:"if_required"
              
            });
          
            if (error) {
              console.error(error.message);
            } else if (paymentIntent) {
              rescheduleOrder( service?.id, {
                slot_id: cartData?.id,
                service_date: dateOnly,
                rescheduled_payment_intent:paymentIntent?.id,
                booking_service_type:service?.booking_service_type
              })
              handleClose2()
              handleClose1()
              handleClose()
            }
          }
        };
        return (
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <PaymentElement />
            <div className="row">
                    <div className="col-12 my-3">
                      <div className="butn">
                        <button onClick={handleSubmit}  disabled={cartData?false:true} style={{cursor:`${cartData?"pointer":"not-allowed"}`    }} className="btn btn-secondary my-2">Continue to Payment</button>
                   
                      </div>
                    </div>
                  </div>
          </form>
        );
      };
      
  return (
    <>
    <MenuItem
    onClick={() => {
      checkRescheduleFee();
    }}
  >
    Service Reschedule <FaRegEye className="ms-1" />
  </MenuItem>
  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className='cancel_order_Model'>
            <div className='d-flex justify-content-between'>
              <h5>Order Reschedule</h5>
              <button onClick={handleClose} className='close_btn'><AiFillCloseCircle style={{ fontSize: "2rem" }} /></button>
            </div>
            <hr />
            <div className='model_content'>
            <FcLeave style={{fontSize:"80px"}}/>
              <h4 className='text-center'>Are you sure you want reschedule your service!</h4>
              <p>{comment}</p>
            </div>
            <hr/>
            <div className='model_button mt-3 d-flex justify-content-between'>
              <button onClick={handleOpen1} style={{background:"#494949",color:"white"}} className='no_btn'>Countinue</button>
              <button onClick={handleClose} className='no_btn'>Cancel</button>
            </div>
          </div>
        </Box>
      </Modal>

  <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className='cancel_order_Model celender'>
            <div className='d-flex justify-content-between'>
              <h5>Order Reschedule</h5>
              <button onClick={handleClose1} className='close_btn'><AiFillCloseCircle style={{ fontSize: "2rem" }} /></button>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ position: 'relative'}}>
          <DemoContainer components={['DateCalendar', 'DateCalendar']}>
            <DemoItem >
              <DateCalendar
               
            shouldDisableDate={disableUnavailableDates} 
                value={value}
                onChange={(newValue) => setValue(newValue)}
                onMonthChange={handleMonthChange}
              />
            </DemoItem>
            <div style={{ position: 'absolute', bottom: '0' }}>
              <p className='px-3 m-0 ' style={{
                fontSize: "13px", fontWeight: '600', color: "#494949", lineHeight: "24px"
              }}>Time Zone</p>
              <p className='px-3 pt-2' style={{
                fontSize: "13px", fontWeight: '500', color: "#494949", lineHeight: "24px"
              }}>
                <FaGlobeEurope /> UK, Manchester Time ({currentTime})<IoMdArrowDropdown style={{ cursor: "pointer", marginLeft: "1rem" }} />
              </p>
            </div>
          </DemoContainer>
        </div>
      </LocalizationProvider>
      
            {value && <div className='slots_vender p-3'>
        <h3>{formatDate(dateOnly)}</h3>
        {loading ? <div className='d-flex justify-content-center'>
          <div
            className="spinner-border text-dark"
            role="status"
          ></div>
        </div> : <div className='d-flex justify-content-start flex-wrap'>
          {slots?.length ? slots?.map((item) => {
            return (
              <>
                {<h5 onClick={() => setcard(item)} style={{ background: `${item === cartData ? "#494949" : ""}`, color: `${item === cartData ? "white" : ""}`, fontSize: "15px" }} className='me-2'>  {convertTo12HourFormat(item?.start_time)} to {convertTo12HourFormat(item?.end_time)}</h5>}
              </>

            )
          }) : <p className='error_text'>No appointment slots available on this date. Please select another date.</p>}

        </div>}

        
       

      </div>
      }
            <div className='model_button mt-3 d-flex justify-content-between'>
              <button disabled={cartData?false:true} onClick={()=>{
                handleOpen2()
                handleClose1()
                handleClose()
              }} style={{background:"#494949",color:"white",cursor:`${cartData?"pointer":"not-allowed"}`}} className='no_btn'>Countinue</button>
              <button onClick={handleClose1} className='no_btn'>Cancel</button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div className='cancel_order_Model'>
            <div className='d-flex justify-content-between'>
              <h5>Order Reschedule</h5>
              <button onClick={handleClose2} className='close_btn'><AiFillCloseCircle style={{ fontSize: "2rem" }} /></button>
            </div>
            <hr />
           <div className='row'>
            <div className='col-12'>
                
                <div>New Booking:{dateOnly}{cartData?.start_time+" "+cartData?.end_time}</div>
                <div>Old Booking:{service?.service_date}{service?.slot?.start_time+" "+service?.slot?.end_time}</div>
                <div>Order Service Price:{service?.subtotal}</div>
                <div>Reschedule Amount:{rescheduleFee1}</div>
                <div>Reschedule %:{service?.rescheduled_data?.rescheduling_charge_percentage}</div>
            </div>
           </div>
            <hr/>
            <div className='model_button mt-3 d-flex justify-content-between'>
              <button onClick={()=>{
                if(payAble){
                        handleOpen3()
                }
                else{
                  // cartData?.id,service?.id,dateOnly,service?.booking_service_type
                    rescheduleOrder( service?.id, {
                      slot_id: cartData?.id,
                      service_date: dateOnly,
                      booking_service_type:service?.booking_service_type
                    })
                }
              }} style={{background:"#494949",color:"white"}} className='no_btn'>{payAble?"Pay Now":"Countinue"}</button>
              <button onClick={handleClose2} className='no_btn'>Cancel</button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
    open={open3}
    onClose={handleClose3}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
    <div className='cancel_order_Model celender'>
      <div style={{borderBottom:"1px solid lightgray",padding:".5rem"}} className='model_header d-flex justify-content-between align-items-center'>
        <h4 className='m-0'>Payment</h4>
        <span onClick={handleClose3}> <CloseOutlined/> </span>
      </div>
      <div className='p-3 d-flex justify-content-center align-items-center flex-column modaly_payment_checkout'>
{      clientSecret ? 
    <Elements stripe={stripePromise} options={options}>
   <Form  cartData={cartData}  />
    </Elements>
  : 
    <div>Loading...</div>}

       </div>
       </div>

    </Box>
  </Modal>
    </>
  
  )
}

export default Reschedule