import React from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from '../Context/ContextProvider';
import { FaStar } from 'react-icons/fa6';
import { ImLocation2 } from 'react-icons/im';
import { FiCheckCircle } from 'react-icons/fi';
import img1 from '../asset/studioLiast/Rectangle 1515.png'

function StudioMore({data}) {
    const {adminStudio,studioList,web_url}=useAuth()
    
  return (
    <div className="w-100 my-5">
    <div className="grid">
      {adminStudio?.map((item) => {
         if (item?.studio_id !== data?.studio_id) {
        return (
          <div className="card p-0" key={item?.studio_id}>
            <Link
              style={{
                textDecoration: "none",
                color: "#494949",
                width: "100%",
              }}
              to={`${web_url}studio-detail/${item?.studio_id}`}
            >
              <div
                style={{ height: "231px", position: "relative" }}
                className="img_vendor"
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={item?.image ? item?.image : img1}
                  alt="..."
                />
                <span
                  style={{
                    position: "absolute",
                    right: "0",
                    background: "#F1A73C",
                    color: "#FFFFFF",
                    top: "0",
                    padding: ".1rem 1rem",
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  £{" "}
                  {item?.starting_price != null
                    ? item?.starting_price
                    : "0"}
                </span>
                <div
                  className="list_talent_review d-flex justify-content-between w-100 px-2 "
                  style={{ position: "absolute", bottom: "0" }}
                >
                  <div className="d-flex  align-items-center">
                    <div>
                      <FaStar
                        className="d-flex  align-items-center"
                        style={{ fontSize: "14px", color: "#F1A73C" }}
                      />
                    </div>
                    <div
                      className="inco_text"
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                      }}
                    >
                      {item?.rating ? item?.rating.toFixed(2) : "5.0"}
                    </div>
                  </div>

                  <div
                    className="d-flex  align-items-center"
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      Width: "60%",
                    }}
                  >
                    <div>
                      <ImLocation2
                        className="d-flex  align-items-center"
                        style={{ fontSize: "12px", color: "#FFFFFF" }}
                      />
                    </div>
                    <div
                      className="inco_text"
                      style={{
                        fontSize: "12px",
                        fontWeight: "700",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                        whiteSpace: "nowrap", // Ensure no wrapping in text content
                        overflow: "hidden", // Hide overflow text
                        textOverflow: "ellipsis", // Apply ellipsis to overflowed text
                        width: "100%", // Inherit the fixed width from the parent
                      }}
                    >
                      {item?.address}
                    </div>
                  </div>
                </div>
                <div className="over_lay">
                  <div className="list_data">
                    <ul>
                      {item?.studio_amenities?.map((itemss) => {
                        return (
                          <li>
                            <FiCheckCircle
                              style={{ marginRight: ".5rem" }}
                            />
                            {itemss?.amenity}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "22%",

                      background: "#000000c8",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button style={{ fontSize: "10px" }}>
                      View Detail
                    </button>
                  </div>
                </div>
              </div>
              <h5
                style={{
                  textTransform: "uppercase",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                className="my-2"
              >
                {item?.name}
              </h5>
            </Link>
          </div>
        );
    }
      })}
      {studioList?.studios?.map((item) => {
        return (
          <div className="card p-0">
            <Link
              style={{
                textDecoration: "none",
                color: "#494949",
                width: "100%",
              }}
              to={`${web_url}studio-profile/${item.slug}`}
            >
              <div
                style={{ height: "231px", position: "relative" }}
                className="img_vendor"
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={item?.studio_image ? item?.studio_image : img1}
                  alt="..."
                />
                <span
                  style={{
                    position: "absolute",
                    right: "0",
                    background: "#F1A73C",
                    color: "#FFFFFF",
                    top: "0",
                    padding: ".1rem 1rem",
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  £ {item?.price}
                </span>
                <div
                  className="list_talent_review d-flex justify-content-between w-100 px-2 "
                  style={{ position: "absolute", bottom: "0" }}
                >
                  <div className="d-flex  align-items-center">
                    <div>
                      <FaStar
                        className="d-flex  align-items-center"
                        style={{ fontSize: "14px", color: "#F1A73C" }}
                      />
                    </div>
                    <div
                      className="inco_text"
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                      }}
                    >
                      {item?.rating ? item?.rating : "5.0"}
                    </div>
                  </div>

                  <div className="d-flex  align-items-center">
                    <div>
                      <ImLocation2
                        className="d-flex  align-items-center"
                        style={{ fontSize: "12px", color: "#FFFFFF" }}
                      />
                    </div>
                    <div
                      className="inco_text"
                      style={{
                        fontSize: "12px",
                        fontWeight: "700",
                        lineHeight: "24px",
                        color: "#FFFFFF",
                      }}
                    >
                      Calif,Manchester
                    </div>
                  </div>
                </div>
                <div className="over_lay">
                  <div className="list_data">
                    <ul>
                      {item?.amenities?.map((itemss) => {
                        return (
                          <li>
                            <FiCheckCircle
                              style={{ marginRight: ".5rem" }}
                            />
                            {itemss}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "22%",

                      background: "#000000c8",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button style={{ fontSize: "10px" }}>
                      View Detail
                    </button>
                  </div>
                </div>
              </div>
              <h5
                style={{
                  textTransform: "uppercase",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
                className="my-2"
              >
                {item?.name}
              </h5>
            </Link>
          </div>
        );
      })}
    </div>
  </div>
  )
}

export default StudioMore
