import React, { useEffect, useState } from 'react'
import NavBar from '../Components/NavBarFooter/NavBar'
import Banner from '../Components/VenderProfie/Banner'
import PortfolioSection from '../Components/VenderProfie/PortfolioSection'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import Footer from '../Components/NavBarFooter/Footer'
import { useAuth } from '../Context/ContextProvider'
import Review from './Review'
import PlaceholderPage from './PlaceholderPage'

function VenderProfie() {
  const [packages, setPackage] = useState(false)
  const location = window.location?.pathname?.split("/")
 const { getVendorProfile, profileData,loadingPage, setProfileData } = useAuth()
  useEffect(() => {
    setProfileData()
    getVendorProfile(location[2],location[3])
    // eslint-disable-next-line
  }, [])

  return (
    <>
    <div className='container'>
        <NavBar />
      {loadingPage?<PlaceholderPage/>:  <>
       {profileData?.id? 
     <>
       <Banner data={profileData} setPackage={setPackage} packages={packages} />
        <PortfolioSection data={profileData} packages={packages} setPackage={setPackage} />
        <div className='my_review_btn'>
          <button>
            my reviews
          </button>
        </div>

<div id="reviews">

        <Review data={profileData?.reviews} />
</div>
     </>:<div className='py-5'>
      <h1 style={{textAlign:"center"}}>User Not Found !</h1>
     </div>
      
      }
        </>}
      </div>

      {!loadingPage&&<>
        <JoinOurTeam />
        <Footer />
      </>}
    </>
  )
}

export default VenderProfie