import React, { useEffect, useState } from "react";
import MultiItemCarousel from "../StudioProfile/MultiItemCarousel";
import { IoMdCheckmarkCircleOutline, IoMdPaperPlane } from "react-icons/io";
import img2 from "./../../asset/icons/Group 1000014113.png";
import img from "./../../asset/Rectangle_1857.png";
import { FaStar } from "react-icons/fa6";

import { FaMapMarkerAlt } from "react-icons/fa";
import { IoCopy } from "react-icons/io5";
import { GoPersonFill } from "react-icons/go";
// import bgImg from "../../asset/Rectangle 1857.png"
function Banner({ data }) {
  const [gallery, setgallery] = useState([]);
  const [coverImage, setCoverImage] = useState([]);
  useEffect(() => {
    let array = [];
    let array1 = [];
    // eslint-disable-next-line
    data?.gallery?.map((item) => {
      array.push(item?.url);
    });
    setgallery(array);
    // eslint-disable-next-line
    data?.gallery?.map((item) => {
      if (item?.is_featured) {
        array1.push(item?.url);
      }
    });
    setCoverImage(array1);
  }, [data]);
  const [copied, setCopied] = React.useState(false);
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(window?.location?.href)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };
  return (
    <div className="text-center pt-4  pb-2">
      <div className="row">
        <div className="col-12">
          <div
            style={{ marginBottom: `${gallery&& gallery?.length>0 ? "7rem" : "8px"} `, marginTop:"1.8rem" }}
            className="main_card_studio"
          >
            <div
              style={{
                height: "532px",
                backgroundImage: `url(${
                  coverImage?.length ? coverImage[0] : img
                })`,
              }}
              className="studio_banner new_studio_banner-1"
            >
              <div className="studio_banner_inner">
                <div className="studio_banner_jobs">
                  {data?.starting_price&&<p className="start_p">
                    <img className="me-2" src={img2} alt="" />
                    Starting Price{" "}
                    <span className="ms-2">£{data?.starting_price}</span>
                  </p>}

                  <p className="start_job" style={{ fontWeight: "600" }}>
                    <FaStar
                      className="me-2"
                      style={{ fontSize: "18px", color: "#F1A73C" }}
                    />
                    {data?.rating?data?.rating.toFixed(2):"5"}
                  </p>

                  <p
                    className="start_job"
                    style={{ border: "none", fontWeight: "600" }}
                  >
                    {" "}
                    <IoMdPaperPlane
                      className="me-2"
                      style={{ fontSize: "18px" }}
                    />
                    {data?.total_jobs?data?.total_jobs :'100'} Jobs
                  </p>
                </div>
                <h1 className="studio_name">{data?.name}</h1>
                <p className="stud_location">
                  <FaMapMarkerAlt
                    className="me-2"
                    style={{ fontSize: "21px" }}
                  />
                  {data?.address}
                </p>
                <ul className="stud_amenities studServices">
                  {

                    data?.studio_sub_services.map((item)=>{
                      return(

                        <li>
                          
                    <IoMdCheckmarkCircleOutline /> {
                      item?.sub_services?.name
                    }
                  </li>
                      )
                    })
                  }
                 
                  
                </ul>

                <div className="mt-2">
                  <button onClick={copyToClipboard} className="stud_buttons">
                    <IoCopy />
                    {copied ? "Copied" : "Profile link"}
                  </button>
                  <button className="stud_buttons stud_buttons1">
                    {" "}
                    <a
                      style={{ color: "#494949", textDecoration: "none" }}
                      href="#pricing_section"
                    >
                      <GoPersonFill />
                      Hire Us
                    </a>
                  </button>
                  <button className="stud_buttons">
                    <a
                      style={{ color: "white", textDecoration: "none" , display:"flex", alignItems:"center"}}
                      href="#reviews"
                    >
                      <FaStar /> Review
                    </a>
                  </button>
                </div>
              </div>
             
              {
                gallery&& gallery?.length>0?
                
                <div style={{ background:"white", margin:"3.5rem 0 90px",padding:"1px"}} className="carousel_parent">
       

            <MultiItemCarousel gellery={gallery}/>
           
            </div>
            :""
           

          }






            </div>
          </div>
          {/* <p className="main_card_studio_p">
     
        </p> */}
        </div>
        
      </div>
    </div>
  );
}

export default Banner;
