import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import { useEffect } from "react";
import img from "../asset/dummy/dummy2.webp";
import arrow from "../asset/icons/left.png";
import arrow1 from "../asset/icons/right.png";
import { FaStar } from "react-icons/fa6";
import { IoMdArrowDropright } from "react-icons/io";
import { CiStar } from "react-icons/ci";
let slidesToShow = 4;
const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <>
      <div
        style={{ left: "-7%", background: "none" }}
        className={className}
        onClick={onClick}
      >
        <img src={arrow} alt="..." />
      </div>
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <>
      <div
        className={className}
        style={{ right: "-7%", background: "none" }}
        onClick={onClick}
      >
        <IoMdArrowDropright />
        <img src={arrow1} alt="..." />
      </div>
    </>
  );
};

const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: false,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

function Review({ data }) {
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 1;
  } else if (width > 769 && width <= 992) {
    slidesToShow = 2;
  } else if (width > 992 && width <= 1200) {
    slidesToShow = 3;
  } else {
    slidesToShow = 4;
  }

  return (
    <>
      {data?.length ? (
        <>
          {data?.length <= 4 ? (
            <div className="d-flex  reviewFlex ">
              {" "}
              {data.map((item) => {
                const timeAgo = (dateString) => {
                  const now = new Date();
                  const past = new Date(dateString);
                  // const diffInSeconds = Math.floor((now - past) / 1000);
                  const diffInSeconds = Math.floor(
                    (now.getTime() - past.getTime()) / 1000
                  );

                  const minutes = Math.floor(diffInSeconds / 60);
                  const hours = Math.floor(diffInSeconds / 3600);
                  const days = Math.floor(diffInSeconds / 86400); // 86400 seconds in a day

                  if (minutes < 1) return "just now";
                  if (minutes < 60) return `${minutes}m `;
                  if (hours < 24) return `${hours}h `;
                  return `${days} day `;
                };

                return (
                  <div
                    className="profile_card me-4 mb-5"
                    style={{ width: "300px" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        objectFit: "contain",
                      }}
                    >
                      <div className="profile_star justify-content-between align-items-start">
                      <div className="home_review_profile">
                        <img
                          src={
                            item?.customer?.avatar
                              ? item?.customer?.avatar
                              : img
                          }
                          alt=""
                        />
                        <div>
                        <h3 className="mt-1">{item?.customer?.name}</h3>
                        <div className="new_review_starbox profile_star d-flex ms-2">
                        {Array.from({ length: 5 }, (_, index) => (
                            <div key={index} style={{ cursor: "pointer" }}>
                              {index < item?.rating ? (
                                <FaStar style={{ fontSize: "14.5px" }} /> // Render filled star if rating is greater than index
                              ) : (
                                <CiStar style={{ fontSize: "14.5px" }} />
                              )}
                            </div>
                          ))}
                       </div>

                        </div>
                     
                      </div>
                        <div>
                          <p>{timeAgo(item?.created_at) + "ago"}</p>
                        </div>
                      </div>
                   

                      
                      <hr style={{ width: "30%", marginTop:"10px" }} />
                     {item?.client_comment? <h1 className="mt-3">{item?.client_comment}</h1>:<h1 className="mt-3">Comment Not Found</h1>}
                      
                    </div>
                  </div>
                );
              })}{" "}
            </div>
          ) : (
            <div className="carousel car_card p-0">
              <Slider {...carouselProperties}>
                {data.map((item) => (
                  <Card item={item} />
                ))}
              </Slider>
            </div>
          )}
        </>
      ) : (
        <div className="row" style={{ position: "relative" }}>
          <div className="col-lg-3 col-12">
            <div className="profile_card">
              <div
                style={{
                  width: "100%",
                  objectFit: "contain",
                  filter: "blur(5px)",
                }}
              >
                <div className="profile_star">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <CiStar />
                  <CiStar />
                  <div>
                    <p>2 days ago</p>
                  </div>
                </div>
                <div className="home_review_profile">
                  <img src={img} alt="" />
                  <h3>Jhon Leo</h3>
                </div>
                <hr style={{ width: "30%" }} />
                <h1>Tetskasjdlkasjdasjdkljasdlkjasd</h1>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-12 d-none d-md-flex">
            <div className="profile_card">
              <div
                style={{
                  width: "100%",
                  objectFit: "contain",
                  filter: "blur(5px)",
                }}
              >
                <div className="profile_star">
                  <FaStar style={{ fontSize: "30px" }} />
                  <FaStar style={{ fontSize: "30px" }} />
                  <FaStar style={{ fontSize: "30px" }} />
                  <CiStar style={{ fontSize: "30px" }} />
                  <CiStar style={{ fontSize: "30px" }} />
                  <div>
                    <p>2 days ago</p>
                  </div>
                </div>

                <h1>Tetskasjdlkasjdasjdkljasdlkjasd</h1>
                <hr style={{ width: "30%" }} />

                <div className="home_review_profile">
                  <img src={img} alt="" />
                  <h3>Jhon Leo</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-12 d-none d-md-flex">
            <div className="profile_card">
              <div
                style={{
                  width: "100%",
                  objectFit: "contain",
                  filter: "blur(5px)",
                }}
              >
                <div className="profile_star">
                  <FaStar style={{ fontSize: "30px" }} />
                  <FaStar style={{ fontSize: "30px" }} />
                  <FaStar style={{ fontSize: "30px" }} />
                  <CiStar style={{ fontSize: "30px" }} />
                  <CiStar style={{ fontSize: "30px" }} />
                  <div>
                    <p>2 days ago</p>
                  </div>
                </div>

                <h1>Tetskasjdlkasjdasjdkljasdlkjasd</h1>
                <hr style={{ width: "30%" }} />

                <div className="home_review_profile">
                  <img src={img} alt="" />
                  <h3>Jhon Leo</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-12 d-none d-md-flex">
            <div className="profile_card">
              <div
                style={{
                  width: "100%",
                  objectFit: "contain",
                  filter: "blur(5px)",
                }}
              >
                <div className="profile_star">
                  <FaStar style={{ fontSize: "30px" }} />
                  <FaStar style={{ fontSize: "30px" }} />
                  <FaStar style={{ fontSize: "30px" }} />
                  <CiStar style={{ fontSize: "30px" }} />
                  <CiStar style={{ fontSize: "30px" }} />
                  <div>
                    <p>2 days ago</p>
                  </div>
                </div>

                <h1>Tetskasjdlkasjdasjdkljasdlkjasd</h1>
                <hr style={{ width: "30%" }} />

                <div className="home_review_profile">
                  <img src={img} alt="" />
                  <h3>Jhon Leo</h3>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "#000000ab",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 className="no_review_tag" style={{ color: "white" }}>Reviews Not Found !</h3>
          </div>
        </div>
      )}
    </>
  );
}

const Card = ({ item }) => {
  const timeAgo = (dateString) => {
    const now = new Date();
    const past = new Date(dateString);
    // const diffInSeconds = Math.floor((now - past) / 1000);
    const diffInSeconds = Math.floor((now.getTime() - past.getTime()) / 1000);

    const minutes = Math.floor(diffInSeconds / 60);
    const hours = Math.floor(diffInSeconds / 3600);
    const days = Math.floor(diffInSeconds / 86400); // 86400 seconds in a day

    if (minutes < 1) return "just now";
    if (minutes < 60) return `${minutes}m `;
    if (hours < 24) return `${hours}h `;
    return `${days}day `;
  };
  return (
    <div className="profile_card">
      <div
        style={{
          width: "100%",
          objectFit: "contain",
        }}
      >
        <div className="profile_star">
          {Array.from({ length: 5 }, (_, index) => (
            <div key={index} style={{ cursor: "pointer" }}>
              {index < item?.rating ? (
                <FaStar style={{ fontSize: "14.5px" }} /> // Render filled star if rating is greater than index
              ) : (
                <CiStar style={{ fontSize: "14.5px" }} />
              )}
            </div>
          ))}

          <div>
            <p>{timeAgo(item?.created_at) + " ago"}</p>
          </div>
        </div>

        <h1>{item?.client_comment}</h1>
        <hr style={{ width: "30%" }} />

        <div className="home_review_profile">
          <img
            src={item?.customer?.avatar ? item?.customer?.avatar : img}
            alt=""
          />
          <h3>{item?.customer?.name}</h3>
        </div>
      </div>
    </div>
  );
};

export default Review;
