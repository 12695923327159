import React, { useState } from 'react'
import img1 from '../../asset/portfiolio/Rectangle 1529.png'
import img2 from '../../asset/portfiolio/Rectangle 1530.png'
import img3 from '../../asset/portfiolio/Rectangle 1531.png'
import img4 from '../../asset/portfiolio/Rectangle 1532.png'
import img5 from '../../asset/portfiolio/Rectangle 1533.png'
import img6 from '../../asset/portfiolio/Rectangle 1534.png'
import img7 from '../../asset/portfiolio/Rectangle 1535.png'
import img8 from '../../asset/portfiolio/Rectangle 1536.png'
import img9 from '../../asset/portfiolio/Rectangle 1537.png'
import img10 from '../../asset/portfiolio/Rectangle 1538.png'
import video from '../../asset/video.mp4'
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
function VendorPorfolio() {
    const [photo, setphoto] = useState(true)
    return (
        <div>
            <h3>Portfolio</h3>
            <p>Thursday,23 November ,2023</p>
            <div className='table_box'>
                <div className='btn_box_portfolio'>
                    <button onClick={() => setphoto(true)}>PHOTOS</button>
                    <button onClick={() => setphoto(false)}>VIDEO</button>
                </div>
                <div className='porfolio_container'>
                    {photo ? <div className='row'>
                        <div className='col-lg-3 col-md-4 col-6 p-1'>
                            <div className='img_box'>
                                <img className='w-100' src={img1} alt='...' />
                                <div className='action w-100'>
                                    <button><MdEdit /></button>
                                    <button><MdDelete /></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 p-1'>
                            <div className='img_box'>
                                <img className='w-100' src={img2} alt='...' />
                                <div className='action w-100'>
                                    <button><MdEdit /></button>
                                    <button><MdDelete /></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 p-1'>
                            <div className='img_box'>
                                <img className='w-100' src={img3} alt='...' />
                                <div className='action w-100'>
                                    <button><MdEdit /></button>
                                    <button><MdDelete /></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 p-1'>
                            <div className='img_box'>
                                <img className='w-100' src={img4} alt='...' />
                                <div className='action w-100'>
                                    <button><MdEdit /></button>
                                    <button><MdDelete /></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 p-1'>
                            <div className='img_box'>
                                <img className='w-100' src={img5} alt='...' />
                                <div className='action w-100'>
                                    <button><MdEdit /></button>
                                    <button><MdDelete /></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 p-1'>
                            <div className='img_box'>
                                <img className='w-100' src={img6} alt='...' />
                                <div className='action w-100'>
                                    <button><MdEdit /></button>
                                    <button><MdDelete /></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 p-1'>
                            <div className='img_box'>
                                <img className='w-100' src={img7} alt='...' />
                                <div className='action w-100'>
                                    <button><MdEdit /></button>
                                    <button><MdDelete /></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 p-1'>
                            <div className='img_box'>
                                <img className='w-100' src={img8} alt='...' />
                                <div className='action w-100'>
                                    <button><MdEdit /></button>
                                    <button><MdDelete /></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 p-1'>
                            <div className='img_box'>
                                <img className='w-100' src={img9} alt='...' />
                                <div className='action w-100'>
                                    <button><MdEdit /></button>
                                    <button><MdDelete /></button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-6 p-1'>
                            <div className='img_box'>
                                <img className='w-100' src={img10} alt='...' />
                                <div className='action w-100'>
                                    <button><MdEdit /></button>
                                    <button><MdDelete /></button>
                                </div>
                            </div>
                        </div>
                    </div> : <div className='d-flex justify-content-center flex-column align-items-center'>
                        <video autoPlay={true} controls style={{ width: "70%" }} src={video} alt="..." />
                        <div className='d-flex justify-content-center my-4 video_actioin'>
                            <button><MdEdit /></button>
                            <button><MdDelete /></button>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default VendorPorfolio