import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import img from "../../asset/icons/Vector.png";
import fb from "../../asset/Facebook.png";
import insta from "../../asset/Instagram.png";
import twitter from "../../asset/Twitter.png";
import tiktok from "../../asset/TikTok.png";
import pin from "../../asset/Pinterest.png";
import beh from "../../asset/linkedin.png";
import drib from "../../asset/Dribbble.png";
import youtube from "../../asset/YouTube.png";
import { useAuth } from "../../Context/ContextProvider";
import { TiTick } from "react-icons/ti";
import profilesett from "../../asset/icons/profilesett.png";
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));
function ProfileSetting() {
  const [profleData, setdata] = useState();
  const [phoneVerifcation, setPhoneVerificaton] = useState(false);
  const {
    getState,
    getCity,
    cityData,
    stateData,
    changeProfile,
    profileSetting,
    profileCode,
    sendCodeProfile,
    verifyCode,
    codeVerify,
    code,
    setVerify,
    socialLinkStore,
    buttonLoding,
    getSocialLInks,
    social_linkData
  } = useAuth();
  const data = JSON.parse(localStorage.getItem("vendor_data"));
  useEffect(() => {

    if (data.data.user) {
      let newData = {
        name: data?.data?.user?.name,
        email: data?.data?.user?.email,
        city_id: data?.data?.user?.city_id,
        country_id: data?.data?.user?.country_id,
        state_id: data?.data?.user?.state_id,
        address: data?.data?.user?.address,
        phone_number: data?.data?.user?.phone_number,
        username: data?.data?.user?.username,
        avatar: data?.data?.user?.avatar,
        description: data?.data?.user?.description,
      };
      newData.phone_number = newData.phone_number.split("44")[1];
      setdata({ ...newData, is_verified: true });
      getState();
      getCity(newData?.state_id);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {

    if (codeVerify?.success) {
      setPhoneVerificaton(false);
      setdata({ ...profleData, is_verified: true });
      setIsActive(false);
    }
    // eslint-disable-next-line
  }, [codeVerify]);
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        changeProfile({ avatar: base64String });
        setdata({ ...profleData, avatar: base64String });
        const data = JSON.parse(localStorage.getItem("vendor_data"));
        // Check if data is valid
        if (data && data.data && data.data.user) {
          // Assuming base64String is defined elsewhere
          if (base64String) {
            data.data.user.avatar = base64String;
            localStorage.setItem("vendor_data", JSON.stringify(data));
          } else {
            console.error("base64String is undefined or null");
          }
        } else {
          console.error("Data retrieved from localStorage is invalid");
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const [fieldErrors, setFieldErrors] = React.useState({});
  const validateStep = () => {
    let errors = {};
    if (!profleData.name) {
      errors.name = "Name is required.";
    }
    if (!profleData.username) {
      errors.username = "User name is required.";
    }
    if (!profleData.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(profleData.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!profleData.phone_number) {
      errors.phone_number = "Phone number is required.";
    } else if (!isValidUKPhoneNumber(profleData.phone_number)) {
      errors.phone_number = "Please enter a valid UK phone number.";
    }
    if (!profleData.state_id) {
      errors.state_id = "State is required.";
    }
    if (!profleData.city_id) {
      errors.city_id = "City is required.";
    }
    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  // Function to check if the email is in a valid format
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidUKPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber.replace(/\D/g, "");
    // Regular expression for UK phone number validation
    const ukPhoneRegex = /^(?:0|\+44)?\s?(?:\d\s?){9,10}$/;
    // Check if the phone number is a valid UK phone number or its length is 11 or 13 characters

    return (
      ukPhoneRegex.test(phone) && (phone.length === 10 || phone.length === 13)
    );
  };
  const handleSubmit = () => {
    if (validateStep() && !phoneVerifcation) {
      profileSetting({
        ...profleData,
        phone_number: "44" + profleData?.phone_number?.replace(/\s/g, ""),
      });
    }
  };
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState();
  const check = () => {
    setFieldErrors({ ...fieldErrors, phone_number: "" });
    if (validateStep()) {
      sendCodeProfile("44" + profleData?.phone_number?.replace(/\s/g, ""));
    }
  };
  function startCountdown() {
    setSeconds(60); // Change this to the desired countdown duration
    setIsActive(true);
  }

  React.useEffect(() => {
    let intervalId;
    if (isActive) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    if (seconds === 0) {
      clearInterval(intervalId);
      setIsActive(false);
    }

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [isActive, seconds]);
  const handleChange = (e) => {
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/\D/g, "");

    // Format the phone number as "7700 900886"
    const firstPart = value.slice(0, 4);
    const secondPart = value.slice(4);
    let formattedValue = `${firstPart}`;
    if (secondPart.length > 0) {
      formattedValue += ` ${secondPart}`;
    }
    // Update the state with the formatted value
    setdata({
      ...profleData,
      phone_number: formattedValue,
      is_verified: false,
    });
    setVerify("");
  };
  useEffect(() => {
    if (profileCode?.errors) {
      setSeconds(0);
      setIsActive(false);
    }
    // eslint-disable-next-line
  }, [profileCode]);

  const [link, setLink] = useState(false);
  const [linkData, setLinkData] = useState([]);
  const [errors, setErrors] = useState({});

  const updateLinkData = (title, social_media_link) => {
    setLinkData(prevData => {
      const index = prevData.findIndex(item => item.title === title);
      if (index !== -1) {
        const updatedData = [...prevData];
        updatedData[index].social_media_link = social_media_link;
        return updatedData;
      } else {
        return [...prevData, { title, social_media_link }];
      }
    });
  };
  const isValidURL = (url) => {
    // If URL is not provided (null or empty), return true without validation
    if (!url) return true;

    // Regular expression to validate the URL format
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*' + // port and path including @ symbol
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i' // fragment locator
    );

    // Test the URL against the pattern
    return pattern.test(url);
  };


  const handleSave = () => {
    let newErrors = {};
    linkData.forEach(item => {
      if (!isValidURL(item.social_media_link)) {
        newErrors[item.title] = `${item.title} URL is invalid`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      let array = []
      // eslint-disable-next-line
      linkData?.map((item) => {
        if (item?.social_media_link) {
          array.push(item)
        }
      })
      // Perform save operation
      socialLinkStore(array)
    }
  };
  useEffect(() => {
    getSocialLInks()
    // eslint-disable-next-line
  }, [])
  const staticLinks = [
    { title: 'facebook', src: fb, placeholder: 'https://www.facebook.com' },
    { title: 'instagram', src: insta, placeholder: 'https://www.instagram.com' },
    { title: 'twitter', src: twitter, placeholder: 'https://www.twitter.com' },
    { title: 'tiktok', src: tiktok, placeholder: 'https://www.tiktok.com' },
    { title: 'pinterest', src: pin, placeholder: 'https://www.pinterest.com' },
    { title: 'linkedIn', src: beh, placeholder: 'https://www.linkedin.com' },
    { title: 'dribbble', src: drib, placeholder: 'https://www.dribbble.com' },
    { title: 'youtube', src: youtube, placeholder: 'https://www.youtube.com' }
  ];
  // eslint-disable-next-line
  const [buttonLoading, setButtonLoading] = useState(false);
  useEffect(() => {
    // Combine API data with static links
    const apiLinkMap = social_linkData?.reduce((map, link) => {
      map[link.title.toLowerCase()] = link;
      return map;
    }, {});

    // Merge static links with API data and initialize linkData state
    const merged = staticLinks.map((item) => ({
      title: item.title,
      src: item.src,
      placeholder: item.placeholder,
      social_media_link: apiLinkMap[item.title.toLowerCase()]?.social_media_link || ""
    }));

    setLinkData(merged);
    // eslint-disable-next-line
  }, [social_linkData]);
  return (
    <>
      <div className="new_order_nav ">
        <div>
          <h4 className="d-flex align-items-center">
            <img
              src={profilesett}
              alt=""
              style={{ marginRight: "11px", width: "22px", height: "19px" }}
            />
            Profile Setting{" "}
          </h4>
        </div>
      </div>

      <div

        className="table_box profile_seting client_profile_setting row"
      >
        {data?.data?.role!=="vendor" && <div className="col-12 d-flex justify-content-center align-items-center mb-4">
          <button
            onClick={() => setLink(false)}
            className={`profile_btns ${link ? "common_light" : "common_dark"}`}
          >
            Profile
          </button>
          <button
            onClick={() => setLink(true)}
            className={`profile_btns ${link ? "common_dark" : " common_light"}`}
          >
            Social Links
          </button>
        </div>}

        {link ? (
          <>
            <div style={{ width: "80%", margin: "0 auto", marginBottom: "20vh" }}>
              <div className="row">
                {linkData?.map((item, index) => (
                  <div key={index} className="col-lg-6 col-12 my-2">
                    <div className="upload_title my-1" style={{ padding: 0, justifyContent: 'normal', borderRadius: '10px',overflow:"hidden" }}>
                      <div className="social_link">
                        <img style={{ width: '25px', height: '25px' }} src={item.src} alt={item.title} />
                      </div>
                      <div className="d-flex flex-column align-items-center" style={{ position: 'relative', width: "90%" }}>
                        <input
                          type="text"
                          
                          style={{ width: "100%",border:"none" }}
                          placeholder={item.placeholder}
                          value={item.social_media_link} // Display the value from linkData or the merged link
                          onChange={(e) => {
                            updateLinkData(item.title, e.target.value);
                            if (errors[item.title]) {
                              setErrors((prevErrors) => {
                                const { [item.title]: removedError, ...rest } = prevErrors;
                                return rest;
                              });
                            }
                          }}
                        />
                        {errors[item.title] && (
                          <div className="error_text" style={{ top: '100%', left: '0%', position: 'absolute' }}>
                            {errors[item.title]}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-12 d-flex align-items-center justify-content-center">
                  <button className="btn_save mt-3" style={{ padding: '0.5rem 3rem' }} onClick={handleSave}>
                    {buttonLoading ? (
                      <div className="spinner-border text-light" role="status"></div>
                    ) : (
                      'Save'
                    )}
                  </button>
                </div>
              </div>
            </div>

          </>
        ) : (
          <>
            <div className="d-flex justify-content-center">
              <Stack direction="row" spacing={2}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <label for="profile_image">
                      <SmallAvatar alt="Remy Sharp" src={img} />
                    </label>
                  }
                >
                  <Avatar
                    style={{ width: "100px", height: "100px" }}
                    className="mt-3"
                    alt="Travis Howard"
                    src={profleData?.avatar}
                  />
                </Badge>
              </Stack>
            </div>
            <input
              onChange={handleFileInputChange}
              type="file"
              className="d-none"
              id="profile_image"
            />
            <div
              style={{ width: "80%", margin: "auto" }}
              className="row inp_section profile_sett_inp"
            >
              {/* =====================Full Name=============== */}
              <div
                style={{ position: "relative" }}
                className="col-lg-6 col-md-6 col-12 my-2"
              >
                {code?.success && (
                  <p className="text-center" style={{ fontSize: "13px" }}>
                    Please enter the code here
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      : {code?.data?.code}
                    </span>{" "}
                  </p>
                )}
                <label className="new_order_label" htmlFor="">
                  Full Name
                </label>
                <input className="profile_input"
                  type="text"
                  onChange={(e) =>
                    setdata({ ...profleData, name: e.target.value })
                  }
                  value={profleData?.name}
                  placeholder="Full Name"
                />
                {fieldErrors?.name && (
                  <span
                    className="error_text"
                    style={{ position: "absolute", left: "5%", top: "100%" }}
                  >
                    {fieldErrors?.name}
                  </span>
                )}
              </div>

              {/* =====================User Name=============== */}

              <div
                style={{ position: "relative" }}
                className="col-lg-6 col-md-6 col-12 my-2"
              >
                {code?.success && (
                  <p className="text-center" style={{ fontSize: "13px" }}>
                    Please enter the code here
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      : {code?.data?.code}
                    </span>{" "}
                  </p>
                )}
                <label className="new_order_label" htmlFor="">
                  User Name
                </label>
                <input className="profile_input"
                  type="text"
                  onChange={(e) =>
                    setdata({ ...profleData, username: e.target.value })
                  }
                  value={profleData?.username}
                  placeholder="user name"
                />
                {fieldErrors?.username && (
                  <span
                    className="error_text"
                    style={{ position: "absolute", left: "5%", top: "100%" }}
                  >
                    {fieldErrors?.username}
                  </span>
                )}
              </div>

              {/* =====================Email=============== */}
              <div style={{ position: "relative" }} className=" col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Email
                </label>
                <input className="profile_input"
                  value={profleData?.email} readOnly
                  onChange={(e) =>
                    setdata({ ...profleData, email: e.target.value })
                  }
                  type="email"
                  placeholder="Email"
                />
                {fieldErrors?.email && (
                  <span
                    className="error_text"
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: "0",
                    }}
                  >
                    {fieldErrors?.email}
                  </span>
                )}
              </div>

              {/* =====================Contact=============== */}
              <div className="col-lg-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Contact
                </label>
                <div
                  style={{
                    padding: "0",
                    justifyContent: "normal",
                    position: "relative",
                    borderRadius: "10px",
                  }}
                  className="upload_title"
                >
                  <div className="contact_profile">
                    <img
                      style={{ width: "30px", height: "20px" }}
                      src="https://flagcdn.com/w320/gb.png"
                      alt="..."
                    />
                    <span className="mx-2">+44</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      value={profleData?.phone_number}
                      onChange={handleChange}
                      placeholder="e.g 7700 900886"
                      pattern="[0-9]{4} [0-9]{6}"
                      
                      className="inp"
                      style={{ padding: ".8rem", border: 'none' }}
                      maxLength={11}
                    />
                    <button
                      onClick={() => {
                        if (!isValidUKPhoneNumber(profleData.phone_number)) {
                          setFieldErrors({
                            ...fieldErrors,
                            phone_number:
                              "Please enter a valid UK phone number.",
                          });
                          // errors.phone_number = "Please enter a valid UK phone number.";
                        } else {
                          setFieldErrors({ ...fieldErrors, phone_number: "" });
                          sendCodeProfile(
                            "44" + profleData?.phone_number?.replace(/\s/g, "")
                          );
                          startCountdown();
                        }
                      }}
                      style={{ color: "#0D99FF", top: "auto" }}
                    >
                      Send code
                    </button>
                  </div>
                </div>
                {fieldErrors?.phone_number && (
                  <span className="error_text" style={{ position: "absolute" }}>
                    {fieldErrors?.phone_number}
                  </span>
                )}
                {profileCode?.code && (
                  <span className="error_text" style={{ position: "absolute" }}>
                    {profileCode?.code}
                  </span>
                )}
                {/* {error?.phone&& <span className="error_text" style={{ position: "absolute" }}>{error?.phone}</span>} */}
              </div>

              <div className="col-lg-6 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Code
                </label>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "10px",
                    border: '1px solid #D0D5DD',
                  }}
                >
                  <input
                    style={{ width: "100%", border: "none" }}
                    type="text"
                    onChange={(e) => {
                      if (e.target.value?.length === 6) {
                        // onNext()
                        verifyCode(
                          "44" + profleData?.phone_number?.replace(/\s/g, ""),
                          e.target.value
                        );
                      }
                    }}
                    placeholder="Code"
                  />
                  {isActive && (
                    <div
                      style={{
                        color: "#00A76F",
                        cursor: "pointer",
                        right: "20px",
                        top: "auto",
                      }}
                    >
                      00:{seconds}
                    </div>
                  )}
                  <span
                    className="profile_inp_span"
                    style={{ borderRadius: "10px" }}
                  >
                    <span>
                      <TiTick
                        className={`${codeVerify?.success ? "text-success" : ""
                          }`}
                      />
                    </span>{" "}
                  </span>
                </div>
                {isActive === false && profleData?.is_verified === false ? (
                  <p
                    className="text-center my-2"
                    style={{ fontSize: "13px", position: "absolute" }}
                  >
                    Did not receive a code?{" "}
                    <span
                      className="text-primary"
                      onClick={check}
                      style={{ cursor: "pointer" }}
                    >
                      Resend
                    </span>
                    <br />
                  </p>
                ) : (
                  ""
                )}
                {/* {codeVerify?.success && <span style={{ position: "absolute", left: "90%", top: "30%" }}><IoMdCheckmarkCircleOutline className='text-success' /></span>} */}
                {fieldErrors?.code && (
                  <span
                    className="error_text"
                    style={{ position: "absolute", left: "5%", top: "100%" }}
                  >
                    {fieldErrors?.code}
                  </span>
                )}
                {phoneVerifcation && (
                  <span
                    className="error_text"
                    style={{ position: "absolute", left: "5%", top: "100%" }}
                  >
                    Phone verification is required
                  </span>
                )}
              </div>
              {/* =====================State and city=============== */}
              <div
                style={{ position: "relative" }}
                className="col-lg-6 col-md-6 col-12 my-2"
              >
                <label className="new_order_label" htmlFor="">
                  Choose State
                </label>
                <div className="comm_select" style={{ borderRadius: "10px" }}>
                  <select
                    onChange={(e) => {
                      getCity(e.target.value);
                      setdata({ ...profleData, state_id: e.target.value });
                    }}
                    value={profleData?.state_id}
                    style={{ color: "gray" }}
                  >
                    <option style={{ color: "gray" }} hidden>
                      Select
                    </option>
                    {stateData?.map((item) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </div>
                {fieldErrors?.state_id && (
                  <span
                    className="error_text"
                    style={{ position: "absolute", left: "5%", top: "100%" }}
                  >
                    {fieldErrors?.state_id}
                  </span>
                )}
              </div>
              <div
                style={{ position: "relative" }}
                className="col-lg-6 col-md-6 col-12 my-2"
              >
                <label className="new_order_label" htmlFor="">
                  Choose City
                </label>
                <div className="comm_select" style={{ borderRadius: "10px" }}>
                  <select
                    value={profleData?.city_id}
                    onChange={(e) =>
                      setdata({ ...profleData, city_id: e.target.value })
                    }
                    style={{ color: "gray" }}
                  >
                    <option style={{ color: "gray" }} hidden>
                      Select
                    </option>
                    {cityData?.map((item) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                  </select>
                </div>
                {fieldErrors?.city_id_id && (
                  <span
                    className="error_text"
                    style={{ position: "absolute", left: "5%", top: "100%" }}
                  >
                    {fieldErrors?.city_id}
                  </span>
                )}
              </div>
              {/* =====================Address=============== */}
              <div style={{ position: "relative" }} className="col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Address
                </label>
                <input className="profile_input"
                  onChange={(e) =>
                    setdata({ ...profleData, address: e.target.value })
                  }
                  value={profleData?.address}
                  type="text"
                  placeholder="Building No, Floor, landmark e.g"
                />
                {fieldErrors?.address && (
                  <span
                    className="error_text"
                    style={{ position: "absolute", left: "5%", top: "100%" }}
                  >
                    {fieldErrors?.address}
                  </span>
                )}
              </div>


              {/* =====================Descriptiomn=============== */}
              {data?.data?.role === "vendor" && <div style={{ position: "relative" }} className="col-12 my-2">
                <label className="new_order_label" htmlFor="">
                  Description
                </label>
                <textarea
                  onChange={(e) =>
                    setdata({ ...profleData, description: e.target.value })
                  }
                  value={profleData?.description}
                  style={{ width: "100%", border: "1px solid lightgray", padding: "1rem", minHeight: "120px", borderRadius: "5px", outline: "none", fontSize: '16px', fontWeight: "400", lineHeight: "19.2px", color: "#667085" }}
                  placeholder="Description"
                ></textarea>
                {fieldErrors?.address && (
                  <span
                    className="error_text"
                    style={{ position: "absolute", left: "5%", top: "100%" }}
                  >
                    {fieldErrors?.description}
                  </span>
                )}
              </div>}
            </div>
            <div className="d-flex justify-content-center">
              <button
                style={{ padding: ".4rem 3rem" }}
                onClick={handleSubmit}
                disabled={profleData?.is_verified ? false : true}
                className="btn_save mt-3"
              >
                {buttonLoding ? (
                  <div
                    className="spinner-border text-light"
                    role="status"
                  ></div>
                ) : (
                  " Save"
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default ProfileSetting;
