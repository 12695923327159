import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
let slidesToShow = 4;


const carouselProperties = {

  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: true,
  autoplay:true,
  autoplaySpeed: 3000, // Autoplay speed in milliseconds
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: true,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

const MultiItemCarousel = ({image,handleVideoClick}) => {


  return (
    <div style={{ margin: '16px 0' }} className='carousel_tumbnil'>

{
  image?.length?
  <>
      <Slider {...carouselProperties}>
        {image?.map((item,i) => (
          <Card handleVideoClick={handleVideoClick} item={item} index={i} />
        ))}
      </Slider>
  </>
      :
      <h5 className="text-center">The portfolio photos have not been uploaded yet.</h5>
}
    </div>
  );
};

const Card = ({ item,handleVideoClick ,index}) => {
  return (
    <div className='me-2'>
      <img
        className='multi__image new_multi__image'
        src={item}
        onClick={()=>{handleVideoClick(index)}}
        alt=''
        style={{
        width: '300px',
          height: '376px',
          objectFit: 'cover',
          marginBottom: '10px',
        }}
      />
      
    </div>
  );
};

export default MultiItemCarousel;