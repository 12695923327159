import React, { useState } from 'react'
import { FaCheck } from 'react-icons/fa'

function Priceing() {
    const [show,setshow]=useState(false)
  return (
    <div style={{overflowX:"hidden",position:"relative"}}>
    <h3>Price Packages</h3>
    <p>Thursday,23 November ,2023</p>
    <div className='table_box priceing'>
    <h3>Create Pricing Packages as you ease</h3>
    <p>Select your service</p>
    <div className='d-flex justify-content-between'>

    <div>

    <span>E-commerce shoot</span>
    <span>Product shoot</span>
    <span>Baby Session shoot</span>
    <span>Wedding shoot</span>
    </div>
    <div>
        <button className='example_btn' onClick={()=>setshow(true)}>Example</button>
    </div>
    </div>
    <div className='row package_main_vendor p-2 my-3'>
        <div className='col-2'>
            <h5 className='mt-3'>Plan Name</h5>
            <h5 className='my-5'>Price Set<span style={{border:"none"}} className='text-danger p-0'>*</span></h5>
            <h5 className='my-5'>Hour(s)/Day(s)<span style={{border:"none"}} className='text-danger p-0'>*</span></h5>
            <h5 className='my-5'>Description<span style={{border:"none"}} className='text-danger p-0'>*</span></h5>
            <h5 className='my-5'>Other 1<span style={{border:"none"}} className='text-danger p-0'>*</span></h5>
            <h5 className='my-5'>Other 2<span style={{border:"none"}} className='text-danger p-0'>*</span></h5>
            <h5 className='my-5'>Other 3<span style={{border:"none"}} className='text-danger p-0'>*</span></h5>
            <h5 className='my-5'>Other 4<span style={{border:"none"}} className='text-danger p-0'>*</span></h5>
            <h5 className='my-5'>Other 5<span style={{border:"none"}} className='text-danger p-0'>*</span></h5>

        </div>
        <div className='col-10'>
            <div className='row'>
        <div className='col-4 px-3'>
            <div className='package_card_vendor p-3 border rounded'>
                <h5>Starter</h5>
                <input placeholder='Enter Price'/>
                <input style={{width:"70%",marginRight:"5%"}} placeholder='Enter Hour/Day'/>
                <select  style={{width:"25%"}}>
                    <option hidden>HH/DD</option>
                </select>
                <input placeholder='Enter Direction'/>
                <input placeholder='Enter Other #1'/>
                <input placeholder='Enter Other #2'/>
                <input placeholder='Enter Other #3'/>
                <input placeholder='Enter Other #4'/>
                <input placeholder='Enter Other #5'/>

            </div>
        </div>
        <div className='col-4 px-3'>
            <div className='package_card_vendor p-3 border rounded'>
                <h5>Pro</h5>
                <input placeholder='Enter Price'/>
                <input style={{width:"70%",marginRight:"5%"}} placeholder='Enter Hour/Day'/>
                <select  style={{width:"25%"}}>
                    <option hidden>HH/DD</option>
                </select>
                <input placeholder='Enter Direction'/>
                <input placeholder='Enter Other #1'/>
                <input placeholder='Enter Other #2'/>
                <input placeholder='Enter Other #3'/>
                <input placeholder='Enter Other #4'/>
                <input placeholder='Enter Other #5'/>

            </div>
        </div>
        <div className='col-4 px-3'>
            <div className='package_card_vendor p-3 border rounded'>
                <h5>Business</h5>
                <input placeholder='Enter Price'/>
                <input style={{width:"70%",marginRight:"5%"}} placeholder='Enter Hour/Day'/>
                <select  style={{width:"25%"}}>
                    <option hidden>HH/DD</option>
                </select>
                <input placeholder='Enter Direction'/>
                <input placeholder='Enter Other #1'/>
                <input placeholder='Enter Other #2'/>
                <input placeholder='Enter Other #3'/>
                <input placeholder='Enter Other #4'/>
                <input placeholder='Enter Other #5'/>

            </div>
        </div>

            </div>
        </div>
  
    </div>
    <div className={`side_bar_example ${show?"active_3":""}`}>
       <div className='d-flex align-items-center'> <button className='btn_close' onClick={()=>setshow(false)}>X</button> <h3>Example of Price Package</h3></div>
       <hr/>
        <div className='row'>
        <div className='col-lg-4 col-md-4 col-12 p-2'>
            <div className='package_main'>
                <h3>Starter</h3>
                <div style={{width:"30px",border:"1px solid gray"}}></div>
                <h1 className='my-3'>£4.99/Hour</h1>
                <h4 className='my-3'>Offers Includes</h4>
                <ul>

                <li><FaCheck/> 1 hour Duration</li>
                <li><FaCheck/> 50+ Edited Photos</li>
                <li><FaCheck/> 20 Downloadable Photos</li>
                <li><FaCheck/> No Revisions</li>
                </ul>
                <button  className='btn_package'>Let Us Be Your Partner</button>
            </div>
        </div>
        <div className='col-lg-4 col-md-4 col-12 p-2'>
            <div style={{background:"#414040",color:"white"}} className='package_main'>
                <h3 >pro</h3>
                <div style={{width:"30px",border:"1px solid gray"}}></div>
                <h1 className='my-3'>£4.99/Hour</h1>
                <h4 className='my-3'>Offers Includes</h4>
                <ul>

                <li><FaCheck/> 1 hour Duration</li>
                <li><FaCheck/> 50+ Edited Photos</li>
                <li><FaCheck/> 20 Downloadable Photos</li>
                <li><FaCheck/> No Revisions</li>
                </ul>
                <button  className='btn_package1'>Let Us Be Your Partner</button>
            </div>
        </div>
        <div className='col-lg-4 col-md-4 col-12 p-2'>
            <div className='package_main'>
                <h3>Business</h3>
                <div style={{width:"30px",border:"1px solid gray"}}></div>
                <h1 className='my-3'>£4.99/Hour</h1>
                <h4 className='my-3'>Offers Includes</h4>
                <ul>

                <li><FaCheck/> 1 hour Duration</li>
                <li><FaCheck/> 50+ Edited Photos</li>
                <li><FaCheck/> 20 Downloadable Photos</li>
                <li><FaCheck/> No Revisions</li>
                </ul>
                <button  className='btn_package'>Let Us Be Your Partner</button>
            </div>
        </div>
    </div>
    </div>
</div>
</div>
  )
}

export default Priceing