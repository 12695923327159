import React, { useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import EditorComponent from "./EditorComponent";
import { FiCheckCircle } from "react-icons/fi";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { useAuth } from "../../Context/ContextProvider";
import CustomMultiSelect from "./CustomMultiSelect";
import AdditionalEditComp from "./AdditionalEditComp";
import { FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));
function StudioDetail({ show, setShow, data, setData, image, setImage }) {
  const { updateStudio, addStudio, vedorServiceData,
    vendorServices, getCity, cityData, stateData,buttonLoding } = useAuth();
  const [amenitie, setAmmenitie] = useState();
  const [idx, setIdx] = useState("")
  useEffect(() => {
    if (data?.state_id) {
      getCity(data?.state_id)
    }
    // eslint-disable-next-line
  }, [show])
  const remove = (idx) => {
    // eslint-disable-next-line
    let array = data?.amenities?.filter((item, i) => {
      if (idx !== i) {
        return item;
      }
    });
    setData({ ...data, amenities: array });
  };
  const [fileName, setFileName] = useState("No File Selected.");
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64String = event.target.result;
        setData({ ...data, image: base64String });
      };

      reader.readAsDataURL(file);
    }

    const files = event.target.files[0];
    setFileName(files ? files.name : "No File Selected.");
  };
  const [fieldErrors, setFieldErrors] = useState({});
  const validateStep = () => {
    let errors = {};

    if (!data.name) {
      errors.name = "Name is required.";
    }
    if (!data.city_id) {
      errors.city_id = "city is required.";
    }
    if (!data.address) {
      errors.address = "address is required.";
    }
    if (!data.state_id) {
      errors.state_id = "state is required.";
    }
    if (!data.sub_service_ids) {
      errors.type = "Studio Type is required.";
    }
    if (!data.description) {
      errors.description = "Description is required.";
    }

    if (data.amenities?.length === 0) {
      errors.amenities = "Amenities is required.";
    }
    if (!data?.id) {
      if (!data.image) {
        errors.image = "Image is required.";
      }
    }

    setFieldErrors(errors);
    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };
  useEffect(() => {
    vendorServices("studio", 1);
    // eslint-disable-next-line
  }, []);
  const [option, setOption] = useState()
  const vendorData=JSON.parse(localStorage.getItem("vendor_data"))
  useEffect(() => {
    if (data?.sub_service_ids) {
      let array = []
      // eslint-disable-next-line
      vedorServiceData?.all_services?.map((item) => {
        // eslint-disable-next-line
        item?.sub_services?.filter((items) => {
          if (data?.sub_service_ids?.includes(items?.id)) {
            array.push(items)
          }
        })
      })


      setOption(array)
    } else {
      setOption(vedorServiceData?.vendor_services?.length ? vedorServiceData?.vendor_services[0]?.service?.sub_services : [])

    }
    // eslint-disable-next-line
  }, [show])
  useEffect(() => {
    if (vedorServiceData?.all_services?.length) {
      setData({ ...data, vendor_service_id: vedorServiceData?.vendor_services?.length ? vedorServiceData?.vendor_services[0]?.vendor_service_id : "" })
    }
    // eslint-disable-next-line 
  }, [vedorServiceData]);
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setActive(true);
      }, 400);
    } else {
      setActive(false);
    }
  }, [show]);
  const sidebarRef = useRef(null);
  return (
    <>
      <div
        style={{ background: `${active ? "rgba(0, 0, 0, 0.628)" : "none"}` }}
        className={`new_order_editor ${show ? "active_3" : ""}`}
      >
        <div className={`side_bar_example`} ref={sidebarRef}>
          <div className="row d-flex  align-items-center">
            <div className="col-12 d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex justify-content-start align-items-center">

                <button
                  className="studio_butn"
                  onClick={() => {
                    setShow(false);
                    setImage("");
                    setFileName("No File Selected.")
                  }}
                >
                  <IoMdClose />
                </button>
                <h3 className="ms-3">   {data?.id ? "Edit Studio" : "Add Studio"}</h3>
       
              </div>

              <button
                onClick={async() => {
                  if (validateStep()) {
                    if (data?.id) {
                    let result= await updateStudio(data);
                    if(result?.success){
                      setShow(false);
                      setImage("");
                      setFileName("No File Selected.")
                    }
                    } else {
                      let result= await  addStudio(data);
                      if(result?.success){
                        setShow(false);
                        setImage("");
                        setFileName("No File Selected.")
                      }
                    }
                  }
                }}
                className="studio_save_btn"
              >
                {buttonLoding?
                  <div
                  className="spinner-border text-white"
                  role="status"
                ></div>
                :data?.id ? "Save Studio" : "Create Studio"}
          
              </button>
            </div>
            <hr style={{ margin: "0", border: "1px solid #E1E2E9" }} />
            <div className="row" style={{ padding: " 15px 0px 15px 20px" }}>
          {vendorData?.data?.role!=="vendor" && <div className=" col-12mb-3 pe-0">
                  <p className="generalInquiry m-0">
                    General Inquiry Only
                    <span className="switchEnquiry">
                      <FormControlLabel
                        checked={data?.only_inquiry === 1}
                        onChange={() =>
                          setData({ ...data, only_inquiry: data?.only_inquiry === 1 ? 0 : 1 })
                        }
                        control={<IOSSwitch sx={{ m: 1 }} />}
                      />
                    </span>
                  </p>
                  <p className="generalInquiryN">
                    No plans or booking details are required, only an
                    inquiry form is available.
                  </p>
                </div>}
              <div className="studio_main_cont pe-0">
                <div className=" col-12 mb-3">
                  <label className="new_order_label " htmlFor="">
                    Studio Profile Image
                  </label>
                  <div className="studio_profile p-0" style={{ position: "relative" }}>
                    {/* <h4>Upload Images</h4> */}

                    <label htmlFor="studioImage" className="upload-label">
                      Studio Image
                    </label>
                    <input
                      onChange={handleFileInputChange}
                      type="file"
                      id="studioImage"
                      style={{ display: "none" }}
                    />
                    <span
                      className="file-name ps-2 d-flex align-items-center"
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#667085",
                      }}
                    >
                      {fileName !== "No File Selected." || image === "" ? fileName : image}
                    </span>
                    {fieldErrors?.image && (
                      <span
                        className="error_text"
                        style={{
                          fontSize: "13px",
                          top: "100%",
                          position: "absolute",
                        }}
                      >
                        {fieldErrors?.image}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <label className="new_order_label" htmlFor="">
                    Studio Name
                  </label>
                  <div className="studio_profile" style={{ position: "relative" }}>
                    <input
                      type="text"
                      value={data?.name}
                      onChange={(e) => setData({ ...data, name: e.target.value })}
                      placeholder="Studio Name"
                    />
                    {fieldErrors?.name && (
                      <span
                        className="error_text"
                        style={{
                          fontSize: "13px",
                          left: "0",
                          top: "100%",
                          position: "absolute",
                        }}
                      >
                        {fieldErrors?.name}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <label className="new_order_label" htmlFor="">
                    Choose Studio Type
                  </label>
                  <div className="studio_profile" style={{ position: "relative" }}>
                    <CustomMultiSelect options={vedorServiceData?.all_services?.length ? vedorServiceData?.all_services[0]?.sub_services : []} setData={setData} data={data} vendorServices={option} />
                    {fieldErrors?.type && (
                      <span
                        className="error_text"
                        style={{
                          fontSize: "13px",
                          left: "0",
                          top: "100%",
                          position: "absolute",
                        }}
                      >
                        {fieldErrors?.type}
                      </span>
                    )}
                  </div>
                </div>
                {/* ===================== Studio Description=============== */}
                <div className="col-12 mb-3">
                  <label className="new_order_label" htmlFor="">
                    {" "}
                    Studio Description
                  </label>
                  <div
                    className="studio_profile "
                    style={{ height: "320px", padding: "0", position: "relative" }}
                  >
                    <EditorComponent data={data} setData={setData} show={show} />
                    {fieldErrors?.description && (
                      <span
                        className="error_text"
                        style={{
                          fontSize: "13px",
                          top: "100%",
                          position: "absolute",
                        }}
                      >
                        {fieldErrors?.description}
                      </span>
                    )}
                  </div>
                </div>
                {/* ===================== Additional Information =============== */}
                <div className="col-12 mb-3">
                  <label className="new_order_label" htmlFor="">
                    {" "}
                    Additional Information
                  </label>
                  <div
                    className="studio_profile "
                    style={{ height: "320px", padding: "0", position: "relative" }}
                  >
                    <AdditionalEditComp data={data} setData={setData} show={show} />

                  </div>
                </div>

                {/* ============================State and City================ */}
                <div className="row">
                  <div className="col-lg-6 col-12 pb-3" style={{ position: "relative" }}>
                    <label className="new_order_label" htmlFor="">Choose State</label>
                    <div className="upload_title comm_select studio_profile  p-0">
                      <select
                        value={data?.state_id}
                        name="state_id"
                        onChange={(e) => {
                          getCity(e.target.value);
                          setData({ ...data, state_id: e.target.value });
                        }}
                        className=" "
                        aria-label="Default select example"
                        style={{
                          padding: ".6rem ",
                          marginTop: "0",
                          // fontSize: "17px",
                          // color: "#545468",
                          // fontWeight: 600,
                        }}
                      >
                        <option hidden>State</option>
                        {stateData?.map((item) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>
                    {fieldErrors?.state_id && (
                      <span className="error_text" style={{ position: "absolute" }}>
                        {fieldErrors?.state_id}
                      </span>
                    )}
                  </div>
                  <div className="col-lg-6 col-12 pb-3" style={{ position: "relative" }}>
                    <label className="new_order_label" htmlFor="">Choose City</label>
                    <div className="upload_title comm_select studio_profile p-0">
                      <select
                        value={data?.city_id}
                        name="city_id"
                        onChange={(e) => {
                          setData({ ...data, city_id: e.target.value });
                        }}
                        className=" "
                        aria-label="Default select example"
                        style={{
                          padding: ".6rem ",
                          marginTop: "0",
                          // fontSize: "17px",
                          // color: "#545468",
                          // fontWeight: 600,
                        }}
                      >
                        <option hidden>City</option>
                        {cityData?.map((item) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>
                    {fieldErrors?.city_id && <span className="error_text" style={{ position: "absolute" }}>{fieldErrors?.city_id}</span>}
                  </div>
                </div>

                {/* ==================== Address============================ */}
                <div className="col-12 mb-3">
                  <label className="new_order_label" htmlFor="">
                    Address
                  </label>
                  <div className="studio_profile" style={{ position: "relative" }}>
                    <input
                      type="text"
                      value={data?.address}
                      onChange={(e) => setData({ ...data, address: e.target.value })}
                      placeholder="Building No, Floor, landmark e.g"
                    />
                    {fieldErrors?.address && <span className="error_text" style={{ fontSize: "13px", left: " 0px", top: " 100%", position: "absolute" }}>{fieldErrors?.address}</span>}
                  </div>
                </div>



                {/* ============amenity======================== */}

                <div className="row">
                  <div className=" col-12 mb-2">
                    <label className="new_order_label" htmlFor="">
                      {" "}
                      Amenity
                    </label>
                    <div
                      style={{ position: "relative", padding: "0px 0 0px .6rem" }}
                      className="studio_profile "
                    >
                      <input
                        type="text"
                        onChange={(e) => setAmmenitie(e.target.value)}
                        value={amenitie}
                        placeholder="Light, backdrop e.g."
                      />
                      {fieldErrors?.amenities && (
                        <span
                          className="error_text"
                          style={{
                            fontSize: "13px",
                            top: "100%",
                            left: "0",
                            position: "absolute",
                          }}
                        >
                          {fieldErrors?.amenities}
                        </span>
                      )}

                      <button
                        onClick={() => {
                          if (amenitie) {
                            if (idx !== "") {
                              let array = [...data?.amenities]
                              array[idx] = amenitie
                              setData({
                                ...data,
                                amenities: array,
                              });
                              setAmmenitie("");
                              setIdx("")
                            }
                            else {
                              setData({
                                ...data,
                                amenities: [...data?.amenities, amenitie],
                              });
                              setAmmenitie("");

                            }
                          }
                        }}
                        className="studio_add_btn"
                      >
                        {idx !== "" ? "Edit" : "Add"}
                      </button>
                    </div>
                  </div>

                  <div className=" col-12">
                    <ul className="studio_detail_list">
                      {data?.amenities?.map((item, i) => {
                        return (
                          <li>
                            <FiCheckCircle className="me-2" />
                            <div className="me-4">
                              {item?.amenity ? item?.amenity : item}
                            </div>
                            <div
                              onClick={() => {
                                setAmmenitie(data?.amenities[i])
                                setIdx(i)
                              }}
                              className="detail_edit"
                            >
                              <MdOutlineModeEdit />
                            </div>
                            <div onClick={() => remove(i)} className="detail_del">
                              <RiDeleteBinLine />
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                </div>
              </div>

              <div className="col-12">

              </div>
            </div>
          </div>


        </div>
      </div>
    </>


  );
}

export default StudioDetail;
