
import React, { useEffect, useState } from "react";
import { FaCalendarAlt, FaRegEye } from "react-icons/fa";
import NewOrderEdit from "./NewOrderEdit";
import Menu from '@mui/material/Menu';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { CgSortAz } from "react-icons/cg";
import cancelOrder from "../../asset/icons/cancelOrder.png";
import { useAuth } from "../../Context/ContextProvider";
import { FaSquareMinus, FaSquarePlus } from "react-icons/fa6";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import dayjs from "dayjs";


function RejectOrder() {
  const {
    getOrder,
    orderStatusData,
    orderData
    ,buttonLoding} = useAuth()
    const [pagination,setPagination]=useState({
      entry:10,
      page:1,
      id:"cancelled",
      first_name: "",
      order_number: "",
      total: "",
      order_status: "",
      added_date: "",
      update_date:""
    })
    useEffect(() => {
      getOrder(pagination)
      // eslint-disable-next-line
    }, [])
  
    const handlePageChange = (event, value) => {
      setPagination({...pagination,page:value})
      getOrder({...pagination,page:value}); // Use the new page number provided by MUI
      // Perform your data fetching or state update here
    };

  const [plus, setPlus] = useState([]);
  const toggleSubtable = (idx) => {
    setPlus((item) => {
      const isVisible = item.includes(idx);
      if (isVisible) {
        return item.filter((i) => i !== idx);

      } else {
        return [...item, idx];
      }
    });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleDateChange = (date) => {
    const adjustedDate = dayjs(date[0]).format("YYYY-MM-DD");
    const adjustedDate1 = dayjs(date[1]).format("YYYY-MM-DD");
    if(adjustedDate!=="Invalid Date" && adjustedDate1!=="Invalid Date"){
      setPagination({
        ...pagination,
        added_date: adjustedDate + " to " + adjustedDate1,
      });
    }
    else{
      setPagination({
        ...pagination,
        added_date: adjustedDate ,
      });
    }
  };
const [show, setshow] = useState(false);
const [data, setData] = useState({});



const getStatusClass = (status) => {
  let reStatus = determineOrderStatus(status)?.classname;
  switch (status?.orderService ? reStatus : status) {
    case "PE":
      return "status-cancel";
    case "RRR ":
      return "status-cancel";
    case "Partially Cancelled":
      return "status-cancel";
    case "RR":
      return "status-revision";
    case "DI":
      return "status-completed";
    default:
      return "";
  }
};
function determineOrderStatus(order) {
  if (!order || !order.orderService || !Array.isArray(order.orderService)) {
    return "Invalid order";
  }



  const serviceStatuses1 = order.orderService.map(
    (service) => service.orderStatus.client_label
  );

  const serviceStatuses = order.orderService.map(
    (service) => service.orderStatus.status_code
  );
  const uniqueStatuses = new Set(serviceStatuses);

  if (uniqueStatuses.size === 1) {
    return {show:serviceStatuses1[0],
      classname:serviceStatuses[0]
    };
  }

  if (serviceStatuses.includes("PE")) {
    return "Partially Cancelled";
  }

  if (serviceStatuses.includes("RR")) {
    return "Partially Refund Request";
  }

  if (serviceStatuses.includes("DI")) {
    return "Partially Refunded";
  }
  if (serviceStatuses.includes("RRR")) {
    return "Partially Refund Reject";
  }

  return "Multiple Different Statuses";
}
  return (
    <>
    <div  className="order_page">
      <>
       <div className="new_order_nav">
        <div>
        <h4 className="d-flex align-items-center"><img src={cancelOrder} alt="" style={{marginRight:"11px", width:"22px", height:"22px"}} />Cancelled Orders </h4>
        </div>
      </div>
      <div className="table_box" > 
      <div className="row">
              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">Order Id</label>
                <div className="new_order_inps">
                  <input type="text" value={pagination?.order_number} onChange={(e) => setPagination({ ...pagination, order_number: e.target.value })} placeholder="Order ID" />
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">Order Status</label>
                <div className="new_order_inps">
                  <select value={pagination?.order_status} onChange={(e) => setPagination({ ...pagination, order_status: e.target.value })} name="" id="">
                    <option hidden>
                      Select Order Status
                    </option>
                    {orderStatusData?.cancelled?.map((item) => {
                      return (
                        <option value={item?.status_code}>
                          {item?.status_name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">Date Added</label>
                <div className="new_order_inps">
                  <input type="text" name="" id="" readOnly value={pagination?.added_date} placeholder="Select date" />
                  <span onClick={handleClick}>
                    <FaCalendarAlt />
                  </span>
                </div>
              </div>
              <Menu
                id="basic-menu" a
                anchorEl={anchorEl}
                style={{ marginLeft: "-30px", marginTop: "2rem" }}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <div style={{ padding: ".5rem" }}>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='row' style={{ margin: "1rem" }}>
                        <div style={{ background: "#494949", display: "flex", justifyContent: "center", alignItems: "center", color: "white", borderRadius: "5px" }} className='col-6 py-2'>
                          <span>From</span>
                        </div>
                        <div style={{ background: "#F0F0F0", display: "flex", justifyContent: "center", alignItems: "center" }} className='col-6 py-2'>
                          <span>To</span>
                        </div>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateRangeCalendar', 'DateRangeCalendar']}>
                          <DemoItem >
                            <DateRangeCalendar onChange={handleDateChange} calendars={1} />
                          </DemoItem>

                        </DemoContainer>
                      </LocalizationProvider>
                    </div>

                  </div>

                </div>
              </Menu>

              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">Customer Name</label>
                <div className="new_order_inps">
                  <input type="text" value={pagination?.first_name} name="" id="" placeholder="Customer Name" onChange={(e) => setPagination({ ...pagination, first_name: e.target.value })} />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">Amount</label>
                <div className="new_order_inps">
                  <input type="text" value={pagination?.total} onChange={(e) => setPagination({ ...pagination, total: e.target.value })} placeholder="Select Amount" />
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 my-2">
                <label className="new_order_label" htmlFor="">Date Modification</label>
                <div className="new_order_inps">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Select date"
                  />
                  <span onClick={handleClick}>
                    <FaCalendarAlt />
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <label>Show entries:</label>
                <select value={pagination?.entry} style={{ width: "200px", border: "1px solid lightgray", padding: ".5rem", borderRadius: "5px", outline: "none" }} onChange={(e) => {
                  if (orderData?.meta?.total < e.target.value) {
                    setPagination({ ...pagination, entry: e.target.value, page: 1 })
                    getOrder({ ...pagination, entry: e.target.value, page: 1 })
                  }
                  else {
                    setPagination({ ...pagination, entry: e.target.value })
                    getOrder({ ...pagination, entry: e.target.value })
                  }
                }}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                </select>
              </div>
              <div>
                <button onClick={() => {
                  setPagination({
                    entry: 10,
                    page: 1,
                    id: "cancelled",
                    first_name: "",
                    order_number: "",
                    total: "",
                    order_status: "",
                    update_date: "",
                    added_date:"",
                  })
                  getOrder({
                    entry: 10,
                    page: 1,
                    id: "cancelled",
                    first_name: "",
                    order_number: "",
                    total: "",
                    order_status: "",
                    update_date: "",
                    added_date:"",
                  })
                }} style={{ padding: ".5rem 1rem", background: "#494949", color: "white", border: "none", borderRadius: "5px", marginRight: "1rem" }}>Clear</button>
                <button style={{ padding: ".5rem 1rem", background: "#494949", color: "white", border: "none", borderRadius: "5px" }} disabled={pagination?.first_name || pagination.order_number || pagination?.order_status || pagination?.total ? false : true} onClick={() => {
                  if (pagination?.first_name || pagination.order_number || pagination?.order_status || pagination?.total|| pagination?.added_date || pagination?.update_date) {
                    getOrder(pagination)
                  }
                }}>Search</button>
              </div>
            </div>
        <div className="confirm_order_table">
        <table className="table nestedTable1 custom-padding table-sm my-3 ">
          <thead >
            <tr style={{position:"sticky",top:"0", width:"100%", zIndex:'11'}}>
              <th scope="col" style={{width:"1%"}}>
                {" "}
                {/* <BpCheckbox /> */}
              </th>
              <th scope="col" className="oder_th">Order ID <CgSortAz /></th>
              <th scope="col" className="oder_th">Order Date <CgSortAz /></th>
              <th scope="col" className="oder_th">Due Order <CgSortAz /></th>
              <th scope="col" className="oder_th">Client Name</th>
              <th scope="col" className="oder_th">Location</th>
              <th scope="col" className="oder_th">Order Amount</th>
              <th scope="col" className="oder_th text-center">Status</th>
              <th scope="col" className="oder_th text-center">Action</th>
            </tr>
          </thead>
          <tbody>
          { buttonLoding?<tr>
              <td colSpan={10}>
            <div style={{height:"400px",width:"100%"}}>
              <p style={{ height: "50px"}} className="placeholder-glow row">
                <span className="placeholder col-12 h-100 rounded"></span>
              </p>
              <p style={{ height: "50px"}} className="placeholder-glow row">
                <span className="placeholder col-12 h-100 rounded"></span>
              </p>
              <p style={{ height: "50px"}} className="placeholder-glow row">
                <span className="placeholder col-12 h-100 rounded"></span>
              </p>
              <p style={{ height: "50px"}} className="placeholder-glow row">
                <span className="placeholder col-12 h-100 rounded"></span>
              </p>
            </div>

            </td>
             </tr>:<>
             {
              orderData?.data?.length ?
                
                      orderData?.data?.map((item, i) => {
        
           
                      return (
                        <>
                        {

                          item?.orderService?.length===1?
                          <>
                    
                          {
                    item?.orderService?.map((items) => {
                   
                      return (
                        <tr>
                          <td colSpan={9} className="p-0">
                            <table className="table nestedTable table-sm m-0 ">
                              <tbody>
                                <tr>
                                  <th style={{width:"2%"}} scope="row">
                                    {" "}
                                    {/* <BpCheckbox /> */}
                                  </th>
                                  <td>{item?.order_number}</td>
                                  <td>
                                    {item?.order_confirmed_date?.split(" ")[0]}
                                    <br />
                                    {item?.date}
                                  </td>
                                  <td>
                                 {items?.order_cancellation_date?.split(" ")[0]}
                                  </td>
                                  <td>{item?.shippingCharges?.name
                                       ?.split(" ")
                                       .filter(
                                         (item, index, arr) =>
                                           arr.indexOf(item) === index
                                       )
                                       .join(" ")}</td>
                                  <td>{item?.shippingCharges?.city?.name} </td>
                                  <td>£{items?.net_total}</td>
                                  {/* <td >
       <span className="new_confirm">Confirmed</span>
       </td> */}
                                  <td style={{ width: "17%", textAlign: "center" }}>
                                    <span className={getStatusClass(items?.orderStatus?.status_code)}>

                                      {items?.orderStatus?.status_name}
                                    </span>
                                  </td>


                                  <td >
                                    <ul className="New_order_icons">
                                      <li onClick={() => {
                                        setData(item)
                                        setshow(true)
                                      }}>
                                        View  <FaRegEye className="ms-1" />
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )
                    })
                    }
                    
                    </>
                          :
                          <>
                          <tr>
                            <th scope="row" style={{width:"1%"}}>
                              {" "}
                              {
                                plus.includes(i) ?
                                  < FaSquareMinus onClick={() => toggleSubtable(i)} /> : < FaSquarePlus onClick={() => toggleSubtable(i)} />
                              }
                            </th>
                            <td>{item?.order_number}</td>
                            <td>
                              {item?.order_confirmed_date?.split(" ")[0]}
                              <br />
                              {item?.date}
                            </td>
                            <td>
                            {item?.order_cancellation_date?.split(" ")[0]}
                            </td>
                            <td>{item?.shippingCharges?.name
                                      ?.split(" ")
                                      .filter(
                                        (item, index, arr) =>
                                          arr.indexOf(item) === index
                                      )
                                      .join(" ")}</td>
                            <td>{item?.shippingCharges?.city?.name} </td>
                            <td>£{item?.subtotal}</td>
                            {/* <td >
              <span className="new_confirm">Confirmed</span>
              </td> */}
                            <td style={{ width: "17%", textAlign: "center" }} >
                              <span  className={getStatusClass(item)}>
                                {determineOrderStatus(item)?.show}
                              </span>
                            </td>
                            <td >
                              <ul className="New_order_icons">
                                <li onClick={() => {
                                  setData(item)
                                  setshow(true)
                                }}>
                                  View  <FaRegEye className="ms-1" />
                                </li>
                              </ul>
                            </td>
                          </tr>
                          {
                            plus.includes(i) &&
                            item?.orderService?.map((items) => {
                              return (
                                <tr>
                                  <td colSpan={9} className="p-0">
                                    <table className="table nestedTable table-sm m-0 ">
                                      <tbody>
                                        <tr>
                                          <th style={{width:"2%"}} scope="row">
                                            {" "}
                                            {/* <BpCheckbox /> */}
                                          </th>
                                          <td>{item?.order_number}</td>
                                          <td>
                                            {item?.order_confirmed_date?.split(" ")[0]}
                                            <br />
                                            {item?.date}
                                          </td>
                                          <td>
                                         {items?.order_cancellation_date?.split(" ")[0]}
                                          </td>
                                          <td>{item?.shippingCharges?.name
                                      ?.split(" ")
                                      .filter(
                                        (item, index, arr) =>
                                          arr.indexOf(item) === index
                                      )
                                      .join(" ")}</td>
                                          <td>{item?.shippingCharges?.city?.name} </td>
                                          <td>£{items?.net_total}</td>
                                          {/* <td >
               <span className="new_confirm">Confirmed</span>
               </td> */}
                                          <td style={{ width: "17%", textAlign: "center" }}>
                                            <span className={getStatusClass(items?.orderStatus?.status_code)}>

                                              {items?.orderStatus?.status_name}
                                            </span>
                                          </td>


                                          <td >
                                            <ul className="New_order_icons">
                                              <li onClick={() => {
                                                setData(item)
                                                setshow(true)
                                              }}>
                                                View  <FaRegEye className="ms-1" />
                                              </li>
                                

                                              {/* <ConfirmOrderMUI  /> */}


                                            </ul>
                                          </td>
                                        </tr>
                                      </tbody>

                                    </table>
                                  </td>

                                </tr>
                              )
                            })}
                          </>
                        }

                        </>

                      )
                    })
                    :
<tr>
<td colSpan={10} style={{border:"none"}}>
<h5 className='text-center'>
No Order Found !
</h5>
</td>
</tr>
                  }
             </>}
        
          </tbody>
        </table>
        </div>
        <div className="paginations d-flex justify-content-end my-3">
      <Stack spacing={2}>
      <Pagination  count={orderData?.meta?.last_page}  onChange={handlePageChange} shape="rounded" />
    </Stack>
      </div>
        <NewOrderEdit show={show} setshow={setshow} data={data}/>
      </div>
      </> 
    </div>
      </>
  );
}

export default RejectOrder









