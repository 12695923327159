import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
function EditorComponent({setData,data,show}) {
  const [value, setValue] = useState(data?.description);
  useEffect(()=>{
    setData({...data,description:value})
  },[value])
  useEffect(()=>{
  if(show===true){
    setValue(data.description?data.description:"")
  }
  else{
    setValue('')
  }
  },[show])

  return (
    <div className='w-100'>
      
      <ReactQuill 
      style={{height:"270px"}}
      value={value} 
      onChange={setValue} 
      />




    </div>
  )
}

export default EditorComponent

