import React, { useEffect, useState } from 'react'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import NavBar from '../Components/NavBarFooter/NavBar'
import Footer from '../Components/NavBarFooter/Footer'
import check from "../asset/detail/02-lottie-tick-01-instant-2.gif";
import { useAuth } from '../Context/ContextProvider';
import { Link } from 'react-router-dom';
function PurchaseOrder() {
  const {setOrderDone,web_url,orderDone}=useAuth()
  const [orderId,setOrderId]=useState()
  useEffect(()=>{
    setOrderId(orderDone?.order_number)
    setOrderDone("")
    // eslint-disable-next-line
  },[])
  return (
    <>
<div className='container'>
      <NavBar/>
   <div className="row">
    <div className="col-12 text-center">
        <p style={{fontSize:"32px", fontWeight:"400", color:"#494949", margin:"0", marginTop:"2.5rem"}}>Thank You For Your Purchase</p>
        <img src={check} alt=""  />
        <h1 className='purchase_id'>Order #{orderId} Confirmed</h1>
        <Link to={`${web_url}client/dashboard`}>
        <button className='purchase_id_btn'>Go To Dashboard</button>
        </Link>
    </div>
   </div>
    </div>
      <JoinOurTeam/>
    <div className='container'>
      <Footer/>

    </div>
    </>
  )
}

export default PurchaseOrder
