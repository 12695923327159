import React from "react";
import img1 from "../../asset/hm-studio-webp/Rectangle 1374.webp";
import img2 from "../../asset/hm-studio-webp/Rectangle 1384.webp";
import img3 from "../../asset/hm-studio-webp/Rectangle 1383.webp";
import img4 from "../../asset/hm-studio-webp/Rectangle 1376.webp";
import img5 from "../../asset/hm-studio-webp/Rectangle 1377.webp";
import img6 from "../../asset/hm-studio-webp/Rectangle 1382.webp";
import img7 from "../../asset/hm-studio-webp/Rectangle 1378.webp";
import img8 from "../../asset/hm-studio-webp/Rectangle 1379.webp";
import videos from "../../asset/our-studio-webp/main-video.mp4";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/ContextProvider";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos";

import PortofiloCarsuol from "./PortofiloCarsuol";
function OurStudio() {
  const { web_url, home } = useAuth();

  AOS.init({
    duration: 800, // Animation duration
    easing: "ease-in-out", // Easing option
    once: false, // Whether animation should only happen once
  });

  return (
    <div className="our_studio_homePage">
      <div className="our_portfolio_home our_studio mt-5 text-center" style={{marginBottom:'1.2rem'}}>
        <button className=" mt-5 mb-0 portfolio_studio_btn">
          Our studio spaces
        </button>
        {home[0]?.sections[2]?.description ? (
          <>
            <h1
              className="sectwo_heading"
              dangerouslySetInnerHTML={{
                __html:
                  home[0]?.sections[2]?.description.split("</h1>")[0] + "</h1>",
              }}
            ></h1>
            <p
              className="secTwo_para  new_secTwo_para"
              dangerouslySetInnerHTML={{
                __html: home[0]?.sections[2]?.description
                  .split("</h1>")[1]
                  ?.trim(),
              }}
            ></p>
          </>
        ) : (
          ""
        )}
        <div className="row home_our_studio align-items-center">
          <div className="col-lg-2 col-md-6 col-12 my-0 my-md-2 my-lg-0 py-lg-0"
            data-aos="fade-up"
          >
            <div className="imgFirstLast">

            <img
              src={
                home[0]?.sections[2]?.section?.length === 1
                  ? home[0]?.sections[2]?.section[0]?.image?.url
                  : img1
              }
              alt="..."
            />
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-12 my-0 my-md-2 my-lg-0 py-lg-0  "
            data-aos="fade-down"
          >
            <div className=" mb-lg-3 mb-3 imgTwoSix">
              
            <img
           
              src={
                home[0]?.sections[2]?.section?.length === 2
                  ? home[0]?.sections[2]?.section[1]?.image?.url
                  : img2
              }
              alt="..."
            />
            </div>
            <div className="imgThreeFive">

            <img
              src={
                home[0]?.sections[2]?.section?.length === 3
                  ? home[0]?.sections[2]?.section[2]?.image?.url
                  : img3
              }
              alt="..."
            />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-12 my-0 my-md-2 my-lg-0 py-lg-0 "
            data-aos="fade-up"
          >
            <div className="home_video">
             {
              home[0]?.sections[2]?.section[3]?.video?
              home[0]?.sections[2]?.section[3]?.video 
              :
                 <video  autoPlay muted  loop  src={videos}></video> 
             }

               

            {/* <img
              src={
                home[0]?.sections[2]?.section?.length === 4
                  ? home[0]?.sections[2]?.section[3]?.image?.url
                  : img4
              }
              alt="..."
            /> */}
              

            </div>
          </div>
          {/* <div className="col-lg-2 col-md-6 col-12 my-0 my-md-2 my-lg-0 py-lg-0 "
            data-aos="fade-up"
          >
            <img
              src={
                home[0]?.sections[2]?.section?.length === 5
                  ? home[0]?.sections[2]?.section[4]?.image?.url
                  : img5
              }
              alt="..."
            />
          </div> */}
          <div className="col-lg-2 col-md-6 col-12 my-0 my-md-2 my-lg-0 py-lg-0 "
            data-aos="fade-down"
          >
            <div className="mb-lg-3 mb-3 imgThreeFive">

            <img
         
              src={
                home[0]?.sections[2]?.section?.length === 7
                  ? home[0]?.sections[2]?.section[6]?.image?.url
                  : img7
              }
              alt="..."
            />
            </div>
            <div className="imgTwoSix">

            <img
             
              src={
                home[0]?.sections[2]?.section?.length === 6
                  ? home[0]?.sections[2]?.section[5]?.image?.url
                  : img6
              }
              alt="..."
            />
            </div>
          </div>

          <div className="col-lg-2 col-md-6 col-12 my-0 my-md-2 my-lg-0 py-lg-0 "
            data-aos="fade-up"
          >
          <div className="imgFirstLast">

            <img
              src={
                home[0]?.sections[2]?.section?.length === 8
                  ? home[0]?.sections[2]?.section[7]?.image?.url
                  : img8
              }
              alt="..."
            />
          </div>
          </div>
        </div>
      </div>
      <PortofiloCarsuol
        image={
          home[0]?.sections[2]?.section?.length
            ? home[0]?.sections[2]?.section
            : []
        }
      />
        <div className="d-flex justify-content-center">
          <Link style={{textDecoration:"none"}} to={`${web_url}our-studio`}>
               <button className="home_view_btn"> View All Studio Spaces</button>   
          </Link>
      </div>
    </div>
  );
}

export default OurStudio;
