import React, { useEffect } from 'react'
import STudioBanner from '../Components/ListTalent/STudioBanner'
import Footer from '../Components/NavBarFooter/Footer'
import JoinOurTeam from '../Components/HomePage/JoinOurTeam'
import NavBar from '../Components/NavBarFooter/NavBar'
import { useParams } from 'react-router-dom'
import { useAuth } from '../Context/ContextProvider'

function StudioSpace() {
  // const parms=useParams()
  const {studioList}=useAuth()

  return (
    <>
    <div className='container'>
        <NavBar/>
        <STudioBanner service_id={studioList?.service?.id} data={studioList?.studios}/>
    </div>
    <JoinOurTeam/>
    <Footer/>
    </>
  )
}

export default StudioSpace