import React, { useEffect, useState } from 'react'
import { IoMdCheckmarkCircleOutline, IoMdPaperPlane } from 'react-icons/io'
import img2 from './../../asset/icons/Group 1000014113.png'
import { FaStar } from 'react-icons/fa6';

import { FaMapMarkerAlt, FaUserAlt } from 'react-icons/fa';
import { IoCopy } from 'react-icons/io5';
import { GoPersonFill } from 'react-icons/go';
// import bgImg from "../../asset/Rectangle 1857.png"
function Banner({data}) {
// eslint-disable-next-line
  const [gallery,setgallery]=useState([])
    
  useEffect(()=>{
    let array=[]
    // eslint-disable-next-line
    data?.gallery?.map((item)=>{
      array.push(item?.url)
    })
    setgallery(array)
    // eslint-disable-next-line
  },[data])
  const [copied, setCopied] = React.useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(window?.location?.href)
    .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    })
    .catch(err => {
        console.error('Failed to copy:', err);
    });
  
};
  return (
<div className="text-center p-4">
  <div className="row">
    {/* <div className="col-12">
      <button className="btn_studio">
        {data?.name}
      </button>
    </div> */}
    <div className="col-12">
      <div  className="main_card_studio new_main_card_studio">

        <div style={{padding:"0",borderRadius:"200px",overflow:"hidden"}} className="studio_banner">

          <div style={{ background: "#3A3E3CE5"}} className="studio_banner_inner">
  
            <h1 className="studio_name mt-4 pt-1">
            {data?.name}
            </h1>
            <p className='stud_location my-2'>
            <FaUserAlt  className=' me-2'   style={{fontSize:"21px"}}/>
            {data?.user?.name}
            </p>
            <div className="studio_banner_jobs mb-2">
              <p className='start_p new_start_p'>
              <img className='me-2' src={img2} alt="" />
              Starting Price <span className='ms-2'>£{data?.starting_price}</span>
              </p>
       
              <p className='start_job new_start_job'style={{fontWeight:"600"}}>
              <FaStar className='me-2' style={{fontSize:"18px", color:"#F1A73C"}}/>{data?.rating}
              </p>
        
              <p className='start_job' style={{border:"none",fontWeight:"600"}}> <IoMdPaperPlane className='me-2' style={{fontSize:"18px"}}/>
              {data?.total_jobs} Jobs
              </p>
            </div>
            <p className='stud_location mb-2'>
            <FaMapMarkerAlt className='me-2'   style={{fontSize:"21px"}}/>
            {data?.location}
            </p>
            <ul className="stud_amenities ">
                  {

                    data?.studio_sub_services?.map((item)=>{
                      return(

                        <li className='py-0'>
                    <IoMdCheckmarkCircleOutline /> {
                      item?.sub_services?.name
                    }
                  </li>
                      )
                    })
                  }
                 
                  
                </ul>

            <div className="mt-2">
              <button onClick={copyToClipboard} className="stud_buttons"><IoCopy />{copied?"Copied":"Profile link"}</button>
              <button className="stud_buttons stud_buttons1" > <a style={{color:"#494949",textDecoration:"none"}}href="#pricing_section"><GoPersonFill />Hire Us</a></button>
              <button className="stud_buttons"><a style={{color:"white",textDecoration:"none"}} href='#reviews'><FaStar/> Review</a></button>
            </div>
          </div>
     

         
        
        </div>

  
      </div>
   
    </div>

  </div>
</div>

  )
}

export default Banner