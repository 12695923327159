import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IoEyeOutline } from 'react-icons/io5';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  p:2,
  borderRadius:"10px"
};
function PortfolioModal({url}) {
    const isYouTubeLink = (url) => {
        return url.includes('youtube.com') || url.includes('youtu.be');
      };
    
      const renderVideo = (url) => {
        if (isYouTubeLink(url)) {
          // Handle YouTube link
          const embedUrl = url.includes('youtube.com') 
            ? url.replace('watch?v=', 'embed/')
            : url.replace('youtu.be/', 'youtube.com/embed/');
          return (
            <iframe
              width="100%"
              height="400px"
              src={embedUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          );
        } else {
          // Handle other video links (assume MP4 for simplicity)
          return (
            <video width="100%"  controls>
              <source src={url} type="video/mp4" />
            </video>
          );
        }
      };
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <>
        <button onClick={handleOpen} style={{ background: "#1877F2" }}><IoEyeOutline /></button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        {renderVideo(url)}
        </Box>
      </Modal>
    </>
  )
}

export default PortfolioModal
