import React from 'react'
import img1 from '../../asset/icons/1.png'
import img2 from '../../asset/icons/2.png'
import img3 from '../../asset/icons/3.png'
import img4 from '../../asset/icons/4.png'
import img5 from '../../asset/icons/5.png'
import img6 from '../../asset/icons/6.png'
import "aos/dist/aos.css"; // Import AOS styles
import AOS from "aos";
function GareSection() {
    AOS.init({
        duration: 800, // Animation duration
        easing: "ease-in-out", // Easing option
        once: false, // Whether animation should only happen once
      });
  return (
    <div className='gare_section'>
        <h1 style={{
            fontSize:"32px",
            fontWeight:"500",
            lineHeight:'38.4px',
            color:"#494949",
            textTransform:"uppercase"
        }}>Get your All Gear in One Place</h1>
        <p style={{fontSize:"16px", fontWeight:"400", color:"#494949"}}>Camera. Lighting. Sound. Strobes. Expendables.</p>
        <div className='row'>
            <div className='col-lg-4 col-md-4 col-6'data-aos="fade-up">
                <div className='main_icons '>
                    <div className='roundeds'></div>
                    <div className='icon_section '>
                        <img src={img1} alt='...'/>
                        <h6>12 SETS WITH<br/>
                        PHOTO & VIDEO
                        </h6>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-4 col-6'data-aos="fade-down">
                <div className='main_icons '>
                    <div className='roundeds'></div>
                    <div className='icon_section '>
                        <img src={img2} alt='...'/>
                        <h6>5 BACKGROUND<br/>
                        & INFINITY COVE
                        </h6>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-4 col-6'data-aos="fade-up">
                <div className='main_icons '>
                    <div className='roundeds'></div>
                    <div className='icon_section '>
                        <img src={img3} alt='...'/>
                        <h6>2 MAKEUP<br/>
                       SETSUP
                        </h6>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-4 col-6'data-aos="fade-down">
                <div className='main_icons '>
                    <div className='roundeds'></div>
                    <div className='icon_section '>
                        <img src={img4} alt='...'/>
                        <h6>CHANGING<br/>
                        ROOM
                        </h6>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-4 col-6'data-aos="fade-up">
                <div className='main_icons '>
                    <div className='roundeds'></div>
                    <div className='icon_section '>
                        <img src={img5} alt='...'/>
                        <h6>BATHROOM<br/>
                        AVAILABLE
                        </h6>
                    </div>
                </div>
            </div>
            <div className='col-lg-4 col-md-4 col-6'data-aos="fade-down">
                <div className='main_icons '>
                    <div className='roundeds'></div>
                    <div className='icon_section '>
                        <img src={img6} alt='...'/>
                        <h6>PARKING<br/>
                        AREA
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default GareSection