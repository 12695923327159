import React, { useEffect } from 'react'
import { useAuth } from '../../Context/ContextProvider'

function Banner() {
  const {terms,TermsandPrivacy}=useAuth()

 useEffect(()=>{
  TermsandPrivacy("privacy-and-policy")
  // eslint-disable-next-line
 },[])
  return (
    <>
    <div className="term_btn text-center mt-4 pt-2 mb-4">
        <button>Privacy Policy</button>
      </div>

      <div className="term_content">
{
  terms && terms?.length>0?
  <>
  {
    terms?.map((item)=>{
      return(
        <div  dangerouslySetInnerHTML={{ __html: item?.description }}>
          </div>
      )
    })
  }
  </>
  :
  <h5 className="d-flex justify-content-center align-items-center">


  No Privacy and Policy available!
</h5>
}
     
     
      </div>
    </>
  )
}

export default Banner
