import { Box, Modal } from '@mui/material';
import React from 'react'
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

function Discard({open1, setOpen1}) {

    const handleClose = () => setOpen1(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 560,
        bgcolor: 'background.paper',
        boxShadow: 24,
      
        borderRadius:"10px"
      };
  return (
    <Modal
    open={open1}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
    <div className="row p-4">
        <div className="col-12 mb-3">
            <div className="dis_del">
            <RiDeleteBinLine />

            </div>
        </div>
        <div className="col-12 text-center">
            <div className="dis_content">
                <h3>
                Are your sure to leave this page?
                </h3>
                <p>Information will not be saved, Do you want to proceed?</p>

            </div>
            <div className="dis_btn">
                <Link to={"/"}>
                
                <button style={{background:"#494949", color:"white"}}>
                    Leave
                </button>
                </Link>
                <button onClick={()=>setOpen1(false)}>
                    Cancel
                </button>
            </div>
        </div>

    </div>
    </Box>
  </Modal>
  )
}

export default Discard
